import { useSelector } from "react-redux";
import "./AllEntityCatg.scss";
import ConfirmDialog from "../../../components/confirmDialog/confirmDialog";
import { CustomButton } from "../../../components/ui/CustomButton";
import { showToast } from "../../../toastService.js";
import newRequest from "../../../utils/newRequest";
import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { IoClose } from "react-icons/io5";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ScaleLoader } from "react-spinners";

const AllEntityCatg = () => {
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [categoryData, setCategoryData] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
  const [notifyDialogTitle, setNotifyDialogTitle] = useState("Confirm");
  const [notifyDialogText, setNotifyDialogText] = useState("Text");
  const [currentAction, setCurrentAction] = useState("");
  const [objectToAction, setObjectToAction] = useState({
    object_id: "",
    action_text: "",
    file_path: "",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const [isCodeDialogOpened, setIsCodeDialogOpened] = useState(false);
  const [selectedCatg, setSelectedCatg] = useState({});
  const [newCode, setNewCode] = useState(null);
  const [newName, setNewName] = useState(null);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogAction, setConfirmDialogAction] = useState("None");
  // columns to export
  const exportColumns = [
    "insured_name_xx",
    "pl_quote_no",
    "product_name_xx",
    "pl_status",
    "quote_stage_xx",
    "total_fc_si",
    "total_fc_premium",
    "commission_fc_amount",
    "commission_tax_fc_amount",
    "bizunit_name_xx",
  ];

  const fetchEntityCatg = async () => {
    try {
      setIsLoading(true);
      const res = await newRequest.post(
        "/allEntityCatg/findAll",
        {
          aent_org_code: currentUser.usr_org_code,
        },
        config
      );
      setCategoryData(res.data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEntityCatg();
  }, []);

  useEffect(() => {
    const filteredResults = categoryData?.filter(
      (item) =>
        item?.aent_code.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item?.aent_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filteredResults);
  }, [categoryData, searchTerm]);

  const handleConfirm = async () => {
    try {
      if (confirmDialogAction === "deleteCatg") {
        setIsConfirmDialogOpen(false);
        setIsProcessing(true);
        const res = await newRequest.delete(
          `/allEntityCatg/del/${objectToAction.object_id}`,
          {},
          config
        );
        // console.log(`/allEntityCatg/del ${JSON.stringify(res.data, null, 2)}`);
        if (res.data === "Document Deleted") {
          const filteredArray = categoryData.filter(
            (obj) => obj._id !== objectToAction.object_id
          );
          setCategoryData(filteredArray);
        } else {
          console.log("showing...");
          setNotifyDialogTitle("Error!");
          setNotifyDialogText(res.data);
          setIsNotifyDialogOpen(true);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsProcessing(false);
      // Close the dialog
      setIsConfirmDialogOpen(false);
    }
  };

  const handleCancel = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleCloseNotifyDialog = () => {
    setIsNotifyDialogOpen(false);
  };

  const handleCatgAction = async () => {
    try {
      if (!newCode) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter code!`);
        setIsNotifyDialogOpen(true);
        return;
      }
      if (!newName) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter name!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      setIsProcessing(true);
      let msg = "";
      if (currentAction === "add") {
        const res = await newRequest.post(
          "/allEntityCatg/add",
          {
            aent_org_code: currentUser.usr_org_code,
            aent_code: newCode,
            aent_name: newName,
            userCode: currentUser.usr_id,
          },
          config
        );
        msg = "Category Added!";
      } else {
        const res = await newRequest.put(
          `/allEntityCatg/upd/${selectedCatg._id}`,
          {
            aent_org_code: currentUser.usr_org_code,
            aent_code: newCode,
            aent_name: newName,
            updatedBy: currentUser.usr_id,
          },
          config
        );
        msg = "Category Updated!";
      }

      fetchEntityCatg();
      showToast(msg, "success");
    } catch (error) {
      console.log(error);
    } finally {
      setNewCode(null);
      setNewName(null);
      setIsCodeDialogOpened(false);
      setIsLoading(false);
    }
  };

  return (
    <motion.div
      className="all-entity-catg-list"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      <div className="navbar">
        <div className="navbar-left">
          <div className="search-input-wrapper">
            <SearchIcon className="search-input-icon" />
            <input
              className="search-input"
              type="text"
              placeholder="Search..."
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <span>{`Records Found ${categoryData.length}`}</span>
        </div>
        <div className="navbar-right">
          <CustomButton startIcon={<RefreshIcon />} onClick={fetchEntityCatg}>
            Refresh
          </CustomButton>
          <CustomButton
            startIcon={<AddIcon />}
            onClick={() => {
              try {
                setNewCode(null);
                setNewName(null);
                setIsCodeDialogOpened(true);
                setCurrentAction("add");
              } catch (error) {
                console.log(error);
              }
            }}
          >
            New Category
          </CustomButton>
        </div>
      </div>
      <div className="content">
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "200px",
              width: "100%",
              height: "100%",
            }}
          >
            <ScaleLoader
              color="#F44336"
              height={50}
              radius={8}
              width={4}
            />
          </div>
        ) : (
          <div className="grand-summary">

            <div className="all-entity-catg-wrapper">
              {filteredData?.map((catg) => (
                <div
                  className="single-catg"
                  key={catg._id}
                  onClick={(e) => {
                    try {
                      console.log(`catg  ${JSON.stringify(catg, null, 2)}`);
                      setSelectedCatg(catg);
                      setNewCode(catg.aent_code);
                      setNewName(catg.aent_name);
                      setIsCodeDialogOpened(true);
                      setCurrentAction("edit");
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                >
                  <div className="single-catg-title">
                    <h2 className="catg-name">{catg.aent_name}</h2>
                  </div>
                  <div className="catg-code">
                    <span className="title">{catg.aent_code}</span>
                    <Tooltip
                      title={`Delete ${catg.aent_name}`}
                      placement="left"
                      style={{
                        backgroundColor: "blue",
                        color: "white",
                      }}
                    >
                      <DeleteIcon
                        size={50}
                        style={{
                          borderRadius: "5px",
                          padding: "3px",
                          alignSelf: "center",
                          cursor: "pointer",
                          color: "rgba(244, 67, 54)",
                          backgroundColor: "rgba(244, 67, 54, 0.5)",
                        }}
                        onClick={(e) => {
                          try {
                            e.stopPropagation();
                            let action_text = `Delete ${catg.aent_name}?`;
                            setObjectToAction({
                              object_id: catg._id,
                              action_text: action_text,
                              file_path: "",
                            });
                            setConfirmDialogAction("deleteCatg");
                            setIsConfirmDialogOpen(true);
                          } catch (error) {
                            console.log(error);
                          }
                        }}
                      />
                    </Tooltip>
                  </div>
                  <div className="user-wrapper">
                    <div className="user">
                      <span>{`Created By ${catg.created_by_name_xx}`}</span>
                      <img
                        src={
                          catg?.created_by_photo_url_xx || "/img/noavatar.jpg"
                        }
                        alt=""
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>{catg.time_ago_xx}</span>
                      <span>{catg.file_size}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {/* Confirm Dialog */}
      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        dialogTitle="Confirmation"
        dialogContent={<p>{objectToAction.action_text}</p>}
      />
      {/* Dialog to notify user about changes being saved */}
      <Dialog open={isNotifyDialogOpen} onClose={handleCloseNotifyDialog}>
        <DialogTitle>{notifyDialogTitle}</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: notifyDialogText }} />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseNotifyDialog}>Close</CustomButton>
        </DialogActions>
      </Dialog>
      {isCodeDialogOpened && (
        <motion.div
          className="catg-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="catg-wrapper">
            <div className="catg-navbar">
              <span>Category</span>
              <span
                onClick={() => {
                  try {
                    setNewCode(null);
                    setNewName(null);
                    setIsCodeDialogOpened(false);
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                <IoClose
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    borderRadius: "50%",
                  }}
                />
              </span>
            </div>
            <div className="catg-content">
              <div className="input-item-with-label">
                <label
                  htmlFor="newName"
                  className="form-dropdown-label"
                  style={{ width: "100px" }}
                >
                  NAME
                </label>
                <input
                  id="newName"
                  name="newName"
                  type="text"
                  placeholder="NAME"
                  value={newName}
                  className="form-input-item"
                  style={{ width: "300px" }}
                  onChange={(e) => setNewName(e.target.value)}
                />
              </div>
              <div className="input-item-with-label">
                <label
                  htmlFor="newCode"
                  className="form-dropdown-label"
                  style={{ width: "100px" }}
                >
                  CODE
                </label>
                <input
                  id="newCode"
                  name="newCode"
                  type="text"
                  placeholder="CODE"
                  value={newCode}
                  disabled={currentAction === "edit"}
                  className="form-input-item"
                  style={{ width: "150px" }}
                  onChange={(e) => {
                    try {
                      setNewCode(e.target.value);
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                />
              </div>
            </div>
            <div className="catg-action">
              <CustomButton onClick={handleCatgAction}>
                {currentAction === "edit" ? "SAVE" : "ADD"}
              </CustomButton>
            </div>
          </div>
        </motion.div>
      )}
    </motion.div>
  );
};

export default AllEntityCatg;
