import "./UwRegister.scss";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { motion } from "framer-motion";
import moment from "moment";
import numeral from "numeral";
import {
  CustomLoadingButton,
  CustomButton,
} from "../../../components/ui/CustomButton";
import ExportToExcelButton from "../../../components/exportToExcelButton/ExportToExcelButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import { customSelectStyles } from "../../../components/ui/uiStyleElements";
import newRequest from "../../../utils/newRequest";
import Select from "react-select";
import {
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
} from "@mui/material";

const UwRegister = () => {
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // columns to export
  const exportColumns = [
    "insured_name_xx",
    "pl_quote_no",
    "product_name_xx",
    "pl_status",
    "quote_stage_xx",
    "total_fc_si",
    "total_fc_premium",
    "commission_fc_amount",
    "commission_tax_fc_amount",
    "bizunit_name_xx",
  ];

  const [registerData, setRegisterData] = useState({});
  const [buttonTitle, setButtonTitle] = useState("GET REGISTER");
  const [fmDate, setFmDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  // products
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const fetchProducts = async () => {
    try {
      const params = {
        pr_org_code: currentUser.usr_org_code,
      };
      const res = await newRequest.post("/uwProduct/findAll", params, config);
      // Transform the data to match the format expected by react-select
      const options = res.data.map((product) => ({
        value: product.pr_code,
        label: product.pr_name,
      }));

      options.unshift({ value: "All", label: "All" });

      setProducts(options);
      // set default product
      setSelectedProduct({ value: "All", label: "All" });
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };

  // biz unit
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [branches, setBranches] = useState([]);
  const fetchBranches = async () => {
    try {

      const res = await newRequest.post("/orgUnit/findAll", {
        unit_org_code: currentUser.usr_org_code,
      }, config);

      const options = res.data.map((branch) => ({
        value: branch.unit_code,
        label: branch.unit_name,
      }));

      options.unshift({ value: "All", label: "All" });
      setBranches(options);
      setSelectedBranch({ value: "All", label: "All" });
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };
  // insurance company
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [carriers, setCarriers] = useState([]);
  const fetchCarriers = async () => {
    try {

      const res = await newRequest.post("/allEntity/findAll", {
        ent_org_code: currentUser.usr_org_code,
      }, config);
      // Transform the data to match the format expected by react-select
      const options = res.data.map((entity) => ({
        aent_code: entity.ent_aent_code,
        value: entity.ent_code,
        label: entity.ent_name,
      }));
      options.unshift({ aent_code: "All", label: "All", label: "All" });
      setCarriers(options);
      setSelectedCarrier({ aent_code: "All", value: "All", label: "All" });
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };

  const setDates = () => {
    try {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();

      // Beginning of the year
      const beginningOfYear = new Date(currentYear, 0, 1);
      beginningOfYear.setFullYear(currentYear); // Set full year again to handle leap years
      const formattedBeginningOfYear = beginningOfYear
        .toISOString()
        .split("T")[0];

      // End of the year
      const endOfYear = new Date(currentYear + 1, 0, 0);
      const formattedEndOfYear = endOfYear.toISOString().split("T")[0];

      // Beginning of the month
      const beginningOfMonth = new Date(currentYear, currentMonth, 1);
      const formattedBeginningOfMonth = beginningOfMonth
        .toISOString()
        .split("T")[0];

      // End of the month
      const endOfMonth = new Date(currentYear, currentMonth + 1, 0);
      endOfMonth.setDate(endOfMonth.getDate() + 1); // Add one day to get the actual end of the month
      const formattedEndOfMonth = endOfMonth.toISOString().split("T")[0];

      // Formatting current date and one year later
      const formattedCurrentDate = currentDate.toISOString().split("T")[0];
      const oneYearLater = new Date(currentDate);
      oneYearLater.setFullYear(currentYear + 1);
      const formattedOneYearLater = oneYearLater.toISOString().split("T")[0];

      // console.log("Current Date:", formattedCurrentDate);
      // console.log("Beginning of the Year:", formattedBeginningOfYear);
      // console.log("End of the Year:", formattedEndOfYear);
      // console.log("Beginning of the Month:", formattedBeginningOfMonth);
      // console.log("End of the Month:", formattedEndOfMonth);
      // console.log("One Year Later:", formattedOneYearLater);

      // Set these values to your state or do whatever you want with them
      setFmDate(formattedBeginningOfMonth);
      setToDate(formattedEndOfMonth);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setDates();
    fetchProducts();
    fetchBranches();
    fetchCarriers();
  }, []);

  const handleFromDateChange = (e) => {
    try {
      if (e.target.value) {
        const formattedFromDate = moment(e.target.value).format("YYYY-MM-DD");
        setFmDate(formattedFromDate);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleToDateChange = (e) => {
    try {
      if (e.target.value) {
        const formattedToDate = moment(e.target.value).format("YYYY-MM-DD");
        setToDate(formattedToDate);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetRegister = async () => {
    try {
      // console.log("fmDate:", fmDate);
      // console.log("toDate:", toDate);
      // console.log("product value:", selectedProduct.value);
      // console.log("product label:", selectedProduct.label);
      // console.log("branch value:", selectedBranch.value);
      // console.log("branch label:", selectedBranch.label);
      // console.log("selectedCarrier aent_code:", selectedCarrier.aent_code);
      // console.log("selectedCarrier label:", selectedCarrier.label);
      // console.log("selectedCarrier value:", selectedCarrier.value);
      setButtonTitle("FETCHING...");
      const params = {
        pl_org_code: currentUser.usr_org_code,
        gl_fm_date: fmDate,
        gl_to_date: toDate,
        pl_product_code:
          selectedProduct.value === "All" ? "" : selectedProduct.value,
        pl_biz_unit: selectedBranch.value === "All" ? "" : selectedBranch.value,
        pl_ins_ent_code:
          selectedCarrier.value === "All" ? "" : selectedCarrier.value,
      };

      const res = await newRequest.post("/uwPolicy/register", params, config);

      setRegisterData(res.data);
      setButtonTitle("GET REGISTER");
    } catch (error) {
      console.log(error);
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    const filteredResults = registerData.data?.filter(
      (item) =>
        item.pl_quote_no?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.pl_no?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.pl_risk_note_no
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        item.pl_end_no
          ?.toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        item.product_name_xx
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        item.insured_name_xx
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        item.created_by_name_xx
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase())
    );
    setFilteredData(filteredResults);
  }, [registerData.data, searchTerm]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const slicedData = filteredData?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <motion.div
      className="uw-register"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      <div className="content">
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "200px",
            }}
          >
            <CircularProgress
              style={{
                height: "50px",
                width: "50px",
              }}
            />
          </div>
        ) : (
          <div className="content-wrapper">
            <div className="content-wrapper-selection">
              <h1>Premium Register</h1>
              <h3>Enter filtering criterial and click get register to view</h3>
              <div className="filter">
                <div className="dates">
                  <div className="input-item-with-label">
                    <label
                      htmlFor="fm_date"
                      className="form-dropdown-label"
                      style={{ width: "130px" }}
                    >
                      FROM
                    </label>
                    <input
                      id="fm_date"
                      name="fm_date"
                      type="date"
                      placeholder="FROM"
                      value={fmDate}
                      className="form-input-item"
                      style={{ width: "200px" }}
                      onChange={handleFromDateChange}
                    />
                  </div>
                  <div className="input-item-with-label">
                    <label
                      htmlFor="to_date"
                      className="form-dropdown-label"
                      style={{ width: "130px" }}
                    >
                      TO
                    </label>
                    <input
                      id="to_date"
                      name="to_date"
                      type="date"
                      placeholder="TO"
                      value={toDate}
                      className="form-input-item"
                      style={{ width: "200px" }}
                      onChange={handleToDateChange}
                    />
                  </div>
                </div>
                <div className="biz-unit">
                  <div className="input-item-with-label biz_unit">
                    <label htmlFor="biz_unit" style={{ width: "130px" }}>
                      BIZ UNIT
                    </label>
                    <Select
                      id="biz_unit"
                      name="biz_unit"
                      styles={customSelectStyles}
                      width="250px"
                      value={selectedBranch}
                      onChange={(selectedOption) => {
                        try {
                          setSelectedBranch(selectedOption);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                      options={branches}
                      isSearchable={true}
                      placeholder="BIZ UNIT"
                    />
                  </div>
                  <div className="input-item-with-label">
                    <label htmlFor="product_code" style={{ width: "130px" }}>
                      PRODUCT
                    </label>
                    <Select
                      id="product_code"
                      name="product_code"
                      styles={customSelectStyles}
                      width="250px"
                      value={selectedProduct}
                      onChange={(selectedOption) => {
                        try {
                          setSelectedProduct(selectedOption);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                      options={products}
                      isSearchable={true}
                      placeholder="PRODUCT"
                    />
                  </div>
                </div>
                <div className="carrier">
                  <div className="input-item-with-label">
                    <label htmlFor="carrier" style={{ width: "130px" }}>
                      CARRIER
                    </label>
                    <Select
                      id="carrier"
                      name="carrier"
                      styles={customSelectStyles}
                      width="300px"
                      value={selectedCarrier}
                      onChange={(selectedOption) => {
                        try {
                          setSelectedCarrier(selectedOption);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                      options={carriers}
                      isSearchable={true}
                      placeholder="Carrier"
                    />
                  </div>
                  <div style={{ marginLeft: "135px" }}>
                    <CustomLoadingButton
                      height={"36px"}
                      width={"200px"}
                      startIcon={<SearchIcon />}
                      onClick={handleGetRegister}
                    >
                      {buttonTitle}
                    </CustomLoadingButton>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-wrapper-summary">
              <span>Overall Totals</span>
              <div className="summary-wrapper">
                <div className="count-view">
                  <h1>{registerData.summary?.totalCount || "0"}</h1>
                  <h3>Count</h3>
                </div>
                <div className="sum-insured-view">
                  <h1>{registerData.summary?.totalFcLiShort || "0.00"}</h1>
                  <h3>Sum Insured</h3>
                </div>
                <div className="premium-view">
                  <h1>{registerData.summary?.totalLcPremiumShort || "0.00"}</h1>
                  <h3>Premium</h3>
                </div>
              </div>
            </div>
            <div className="content-wrapper-products">
              <span>Breakdown By Product</span>
              <div className="product-wrapper">
                {registerData.byProduct?.map((product, index) => (
                  <div className="product-card" key={product.pl_product_code}>
                    <div className="title-row">
                      <h1>{product?.product_name_xx}</h1>
                      <span>{product?.percentage}%</span>
                    </div>
                    <hr />
                    <div className="details-row">
                      <div className="count-view">
                        <h1>{product?.count || "0"}</h1>
                        <h3>Count</h3>
                      </div>
                      <div className="sum-insured-view">
                        <h1>{product?.total_lc_si_short || "0.00"}</h1>
                        <h3>Sum Insured</h3>
                      </div>
                      <div className="premium-view">
                        <h1>{product?.policy_lc_premium_short || "0.00"}</h1>
                        <h3>Premium</h3>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="content-wrapper-result">
              <span className="title">Results</span>
              <div className="results-wrapper">
                <div className="results-wrapper-navbar">
                  <div className="left">
                    <div className="search-input-wrapper">
                      <SearchIcon className="search-input-icon" />
                      <input
                        className="search-input"
                        type="text"
                        placeholder="Search..."
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="right">
                    <CustomButton
                      startIcon={<RefreshIcon />}
                      onClick={handleGetRegister}
                    >
                      Refresh
                    </CustomButton>
                    <ExportToExcelButton
                      data={slicedData}
                      exportColumns={exportColumns}
                      fileName="Documents.xlsx"
                    />
                  </div>
                </div>
                <div className="table-content">
                  <TableContainer component={Paper} className="table-container">
                    <Table sx={{ width: "max-content" }}>
                      <TableBody className="tablebody">
                        {slicedData?.map((policy) => (
                          <TableRow
                            key={policy._id}
                            className="tablerow"
                            onClick={() => {
                              try {
                                navigate(`/uw/rn/${policy._id}`, {
                                  state: policy,
                                });
                              } catch (error) {
                                console.log(error);
                              }
                            }}
                          >
                            <TableCell
                              className="table-cell"
                              style={{
                                padding: "8px",
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div className="top-row">
                                <div className="left">
                                  <div className="entity">
                                    <img
                                      src={
                                        policy?.insured_photo_url_xx ||
                                        "/img/noimage.png"
                                      }
                                      alt=""
                                    />
                                    <span>{policy.insured_name_xx}</span>
                                  </div>
                                  {policy.carrier_name_xx && (
                                    <div className="entity">
                                      <img
                                        src={
                                          policy?.carrier_photo_url_xx ||
                                          "/img/noimage.png"
                                        }
                                        alt=""
                                      />
                                      <span>{policy.carrier_name_xx}</span>
                                    </div>
                                  )}
                                </div>
                                <div className="right">
                                  <span>{policy.pl_cur_code}</span>{" "}
                                  <span>
                                    {moment(policy?.pl_gl_date).format(
                                      "DD-MMM-YYYY"
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="second-row">
                                <div className="second-row-col1">
                                  <span>{`Risk Note ${policy.pl_risk_note_no}`}</span>
                                  <span>{`Original ${policy.pl_primary_doc}`}</span>
                                  <span>{`Policy No ${policy.pl_no}`}</span>
                                </div>

                                <div
                                  className="second-row-col2"
                                  style={{ flexGrow: 1 }}
                                >
                                  <span>{`Product ${policy.product_name_xx}`}</span>
                                  <span>{`Type ${policy.end_name_xx}`}</span>
                                  <span>{`Biz Unit ${policy.bizunit_name_xx}`}</span>
                                </div>

                                <div className="second-row-col3">
                                  <span>{`Sum Insured ${numeral(
                                    policy.total_fc_si
                                  ).format("0,0.00")}`}</span>
                                  <span>{`Premium ${numeral(
                                    policy.policy_fc_premium
                                  ).format("0,0.00")}`}</span>
                                  <span>{`Charges ${numeral(
                                    policy.total_charges_fc_amount
                                  ).format("0,0.00")}`}</span>
                                </div>

                                <div className="second-row-col4">
                                  <span>{`Total Prem ${numeral(
                                    policy.total_fc_premium
                                  ).format("0,0.00")}`}</span>
                                  <span>{`Paid ${numeral(
                                    policy.total_paid_fc_amount
                                  ).format("0,0.00")}`}</span>
                                  <span>{`OS Prem ${numeral(
                                    policy.total_os_fc_amount
                                  ).format("0,0.00")}`}</span>
                                </div>

                                <div className="second-row-col5">
                                  <span>{`Commission ${numeral(
                                    policy.commission_fc_amount
                                  ).format("0,0.00")}`}</span>
                                  <span>{`Tax ${numeral(
                                    policy.commission_tax_fc_amount
                                  ).format("0,0.00")}`}</span>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[5]}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      component="div"
                      count={registerData.data?.length}
                      rows={filteredData}
                      page={page}
                      onPageChange={handleChangePage}
                    />
                  </TableContainer>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default UwRegister;
