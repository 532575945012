import "./AdProfile.scss"
import { GridLoader } from "react-spinners";
import { motion } from "framer-motion";
import {  useState } from "react";
import { useSelector } from "react-redux";
import { FaSave } from "react-icons/fa";
import app from "../../../utils/firebase";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    LinearProgress,
} from "@mui/material";
import {
    getStorage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
} from "firebase/storage";
import newRequest from "../../../utils/newRequest";
import { CustomLoadingButton } from "../../../components/ui/CustomButton";
import { useDispatch } from 'react-redux';
import { setAvator } from "../../../redux/userSlice";

const AdProfile = () => {
    const dispatch = useDispatch();
    const [isProcessing, setIsProcessing] = useState(false);
    const { currentUser, currentUserAvator } = useSelector((state) => state.user);
    const [img, setImg] = useState(null);
    const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const handleUploadDoalogClose = () => {
        setIsUploadDialogOpen(false);
    };



    const uploadToFirebase = async (file, fileFolder) => {
        setIsUploadDialogOpen(true);
        const storage = getStorage(app);
        const fileName = new Date().getTime() + file.name;
        const fileExtension = file.name.split(".").pop(); // Get file extension
        const fileType = file.type; // Get file type
        const fileSizeKB = file.size / 1024; // Get file size in KB
        const storageRef = ref(
            storage,
            `qc_${currentUser.usr_org_code}/${fileFolder}/${fileName}`
        );
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadProgress(Math.round(progress));
                switch (snapshot.state) {
                    case "paused":
                        console.log("Upload is paused");
                        break;
                    case "running":
                        console.log("Upload is running");
                        break;
                    default:
                        break;
                }
            },
            (error) => {
                setIsUploadDialogOpen(false);
                console.log(error);
            },
            async () => {
                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                if (fileFolder === "profiles") {
                    // update user profile image
                    const res = await newRequest.post(
                        "/adUser/upd",
                        {
                            user_docid: currentUser.usr_id,
                            usr_photo_url: downloadURL,
                            userCode: currentUser.usr_id,
                        }
                    );
                    // setUsrPhotoUrl(downloadURL)
                    setImg("");
                    setIsUploadDialogOpen(false);
                    dispatch(setAvator(downloadURL));
                } else {
                    await saveFileToDb(
                        file.name,
                        fileName,
                        fileExtension,
                        downloadURL,
                        "kyc",
                        fileSizeKB,
                        currentUser.usr_id,
                    );
                    // setImg("");
                    setIsUploadDialogOpen(false);
                }
            }
        );
    };

    const saveFileToDb = async (
        fl_name,
        fl_internal_name,
        fl_type,
        fl_path,
        fl_category,
        fl_size_kb,
        fl_parent_doc_id
    ) => {
        try {
            const params = {
                fl_org_code: currentUser.usr_org_code,
                fl_name: fl_name,
                fl_internal_name: fl_internal_name,
                fl_type: fl_type,
                fl_path: fl_path,
                fl_category: fl_category,
                fl_doc_type: "",
                fl_doc_no: "",
                fl_doc_index: "",
                fl_doc_sub_index: "",
                fl_ent_code: "",
                fl_aent_code: "",
                fl_user_id: currentUser.usr_id,
                fl_size_kb,
                fl_parent_doc_id,
                userCode: currentUser.usr_id,
            };

            const res = await newRequest.post("/files/add", params);
        } catch (error) {
            console.error("Error fetching entity types:", error);
        }
    };

    const saveRecord = async () => {
        try {
            if (img) {
                await uploadToFirebase(img, "profiles");
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <motion.div className="ad-profile"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 1 } }}
            exit={{ opacity: 0, transition: { duration: 1 } }}
        >
            <div className="main-wrapper">
                <div className="profile-navbar">
                    <div className="left">
                        <h1>{`${currentUser.usr_full_name} PROFILE`}</h1>
                    </div>
                    <div className="right">
                        <CustomLoadingButton
                            startIcon={<FaSave />}
                            onClick={saveRecord}
                        >
                            SAVE
                        </CustomLoadingButton>
                    </div>
                </div>
                <div className="profile-content">
                    <div className="logo">
                        {!img && (
                            <img
                                src={currentUserAvator || "/img/noavatar.jpg"}
                                alt=""
                                className="img"
                            />
                        )}
                        {img && (
                            <img
                                src={
                                    img ? URL.createObjectURL(img) : "/img/noavatar.jpg"
                                }
                                alt=""
                                className="img"
                            />
                        )}
                        <input
                            type="file"
                            id="file"
                            style={{ display: "none" }}
                            accept="image/*"
                            onChange={(e) => setImg(e.target.files[0])}
                        />
                        <label htmlFor="file" style={{ margin: "10px 0px", cursor: "pointer" }}>
                            <div className="item">
                                <span>Change Profile</span>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
            {/* progress dialog for file upload  */}
            <UploadDialog
                open={isUploadDialogOpen}
                handleClose={handleUploadDoalogClose}
                uploadProgress={uploadProgress}
            />

            {isProcessing && (
                <motion.div
                    className="processing-dialog"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 1 } }}
                    exit={{ opacity: 0, transition: { duration: 1 } }}
                >
                    <div className="processing-wrapper">
                        <GridLoader
                            color="#F44336"
                            style={{
                                height: "50px",
                                width: "50px",
                            }}
                        />
                    </div>
                </motion.div>
            )}
        </motion.div>
    )
}


const UploadDialog = ({ open, handleClose, uploadProgress }) => {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Progress</DialogTitle>
            <DialogContent>
                <LinearProgress variant="determinate" value={uploadProgress} />
                {uploadProgress && <p>{uploadProgress}% Complete</p>}
            </DialogContent>
        </Dialog>
    );
};

export default AdProfile