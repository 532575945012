import { useSelector } from "react-redux";
import "./AdSubscription.scss";
import ConfirmDialog from "../../../components/confirmDialog/confirmDialog.jsx";
import {
  CustomButton,
  CustomLoadingButton,
} from "../../../components/ui/CustomButton.jsx";
import { showToast } from "../../../toastService.js";
import newRequest from "../../../utils/newRequest.js";
import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { motion } from "framer-motion";
import moment from "moment";
import { useEffect, useState } from "react";
import { FaSave } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { GridLoader, ScaleLoader } from "react-spinners";

const AdSubscription = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogAction, setConfirmDialogAction] = useState("None");
  const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
  const [notifyDialogTitle, setNotifyDialogTitle] = useState("Confirm");
  const [notifyDialogText, setNotifyDialogText] = useState("Text");
  const [isLoading, setIsLoading] = useState(false);
  const [savingTitle, setSavingTitle] = useState("Save");
  const [systemTypes, setSystemTypes] = useState([]);
  const [selectedSystemType, setSelectedSystemType] = useState({});
  const [systemCodes, setSystemCodes] = useState([]);
  const [selectedSystemCode, setSelectedSystemCode] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const [currencyAction, setCurrencyAction] = useState("");
  const [isCodeDialogOpened, setIsCodeDialogOpened] = useState(false);

  const [objectToAction, setObjectToAction] = useState({
    object_id: "",
    action_text: "",
    action_invoked: "",
  });

  const handleConfirm = async () => {
    try {
    } catch (err) {
      console.log(err);
    }
    // Close the dialog
    setIsConfirmDialogOpen(false);
  };

  const handleCancel = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleCloseNotifyDialog = () => {
    setIsNotifyDialogOpen(false);
  };

  const saveRecord = async (e) => {
    // e.preventDefault();
    try {
      setSavingTitle("saving...");
      setIsLoading(true);
    } catch (err) {
      console.log(err);
    } finally {
      setSavingTitle("Save");
      setIsLoading(false);
    }
  };

  return (
    <div className="ad-subscription">
      {isLoading ? (
        <div className="no-content"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <ScaleLoader
            color="#F44336"
            height={50}
            radius={8}
            width={4}
          />
        </div>
      ) : (
        <div className="ad-subscription-main-wrapper">
          <div className="center-wrapper">
            <div className="center-details">
              <div className="center-navbar">
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h1>SUBSCRIPTION DETAILS</h1>
                </div>
              </div>
              <hr />
              <div className="org-details-wrapper">
              </div>
            </div>
          </div>
          <div className="right-wrapper">
            <div className="right-content">
              <h2 className="titles">SUMMARY</h2>
            </div>
          </div>
        </div>
      )}

      {/* Dialog to notify user about changes being saved */}
      <Dialog open={isNotifyDialogOpen} onClose={handleCloseNotifyDialog}>
        <DialogTitle>{notifyDialogTitle}</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: notifyDialogText }} />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseNotifyDialog}>Close</CustomButton>
        </DialogActions>
      </Dialog>
      {/* Confirm Dialog */}
      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        dialogTitle="Confirmation"
        dialogContent={<p>{objectToAction.action_text}</p>}
      />

      {isCodeDialogOpened && (
        <motion.div
          className="new-rate-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="new-rate-wrapper">
            <div className="new-rate-navbar">
              <span>{`${currencyAction === "addSysCode" ? "ADD CODE" : "ADD TYPE"
                }`}</span>
              <span
                onClick={() => {
                  try {
                    setIsCodeDialogOpened(false);
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                <IoClose
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    borderRadius: "50%",
                  }}
                />
              </span>
            </div>

            <div className="new-rate-action">
              {currencyAction === "addSysCode" && (
                <CustomButton>ADD</CustomButton>
              )}
              {currencyAction === "addSysType" && (
                <CustomButton>ADD</CustomButton>
              )}
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default AdSubscription;
