import { useSelector } from "react-redux";
import "./AdUser.scss";
import ConfirmDialog from "../../../components/confirmDialog/confirmDialog.jsx";
import { ToggleSwitch } from "../../../components/toogle/ToggleSwitch.jsx";
import {
  CustomButton,
  CustomLoadingButton,
  SearchButton,
} from "../../../components/ui/CustomButton.jsx";
import { customSelectStyles } from "../../../components/ui/uiStyleElements.jsx";
import { showToast } from "../../../toastService.js";
import newRequest from "../../../utils/newRequest.js";
import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Tooltip,
  LinearProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import QueueIcon from "@mui/icons-material/Queue";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from '@mui/icons-material/Menu';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import app from "../../../utils/firebase";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { FaSave } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import Select from "react-select";
import { GridLoader, ScaleLoader } from "react-spinners";
import VideoPlayer from "../../../components/videoPlayer/VideoPlayer.jsx";
import { FaUsers } from "react-icons/fa";

const AdUser = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogAction, setConfirmDialogAction] = useState("None");
  const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
  const [notifyDialogTitle, setNotifyDialogTitle] = useState("Confirm");
  const [notifyDialogText, setNotifyDialogText] = useState("Text");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [savingTitle, setSavingTitle] = useState("Save");
  const [users, setUsers] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedUserIndex, setSelectedUserIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [profiles, setProfiles] = useState([]);
  const [branches, setBranches] = useState([]);
  const [currencyAction, setCurrencyAction] = useState("");
  const [isCodeDialogOpened, setIsCodeDialogOpened] = useState(false);
  const [templateView, setTemplateView] = useState("list");
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  const [userPw, setUserPw] = useState(null);
  const [userConfirmPw, setUserConfirmPw] = useState(null);

  // user fields
  const [usrUn, setUsrUn] = useState(null);
  const [usrFullName, setUsrFullName] = useState(null);
  const [usrEmail, setUsrEmail] = useState(null);
  const [usrPhotoUrl, setUsrPhotoUrl] = useState(null);
  const [img, setImg] = useState(null);
  const [usrPhoneNo, setUsrPhoneNo] = useState(null);
  const [usrUnitCode, setUsrUnitCode] = useState(null);
  const [usrProfileCode, setUsrProfileCode] = useState(null);
  const [usrEnabled, setUsrEnabled] = useState(null);

  const [showAddRoles, setShowAddRoles] = useState(false);
  const [systemRoles, setSystemRoles] = useState([]);

  // notes on user creation
  const [isErrorsDialogOpen, setIsErrorsDialogOpen] = useState(false);
  const [errorDialogTitle, setErrorDialogTitle] = useState("Note");
  const [createUserErrors, setCreateUserErrors] = useState([]);

  const handleErrorsCloseDialog = () => {
    setIsErrorsDialogOpen(false);
  };

  const handleUploadDoalogClose = () => {
    setIsUploadDialogOpen(false);
  };


  const handleSearchUsers = async () => {
    try {
      setIsLoading(true);
      const res = await newRequest.post("/adUser/search", { search_input: searchTerm });
      setUsers(res.data);
    } catch (error) {
      console.error("Error searching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const [userTraceData, setUserTraceData] = useState([]);
  const fetchUserTrace = async (userDocid) => {
    try {
      const res = await newRequest.post(
        "/trace/findUser",
        {
          user_docid: userDocid,
        }
      );
      setUserTraceData(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchRoles = async () => {
    try {
      const res = await newRequest.post(
        "/adRole/findAll",
        {
          role_org_code: currentUser.usr_org_code,
        }
      );
      setSystemRoles(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [objectToAction, setObjectToAction] = useState({
    object_id: "",
    action_text: "",
    action_invoked: "",
    param1: "",
  });

  const handleClearSearch = () => {
    try {
      setSearchTerm("");
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      const res = await newRequest.post(
        "/adUser/findAll",
        {
          usr_org_code: currentUser.usr_org_code,
          result_limit: 4
        }
      );
      setUsers(res.data);
      setSelectedUser(res.data[0]);
      setCurrencyAction("viewUser");
      setSelectedUserIndex(0);
      setUsrUn(res.data[0].usr_un);
      setImg(null);
      setUsrPhotoUrl(res.data[0].usr_photo_url);
      setUsrFullName(res.data[0].usr_full_name);
      setUsrEmail(res.data[0].usr_email);
      setUsrPhoneNo(res.data[0].usr_phone_no);
      setUsrEnabled(res.data[0].usr_enabled);
      setUsrUnitCode({
        value: res.data[0].usr_unit_code,
        label: res.data[0].bizunit_name_xx,
      });
      setUsrProfileCode({
        value: res.data[0].usr_profile_code,
        label: res.data[0].profile_name_xx,
      });
      fetchUserTrace(res.data[0]._id);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProfiles = async () => {
    try {
      setIsLoading(true);
      const res = await newRequest.post(
        "/adProfile/findAll",
        {
          pf_org_code: currentUser.usr_org_code,
        }
      );

      const options = res.data.map((pf) => ({
        value: pf.pf_code,
        label: pf.pf_name,
      }));

      setProfiles(options);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchBranches = async () => {
    try {
      const res = await newRequest.post(
        "/orgUnit/findAll",
        {
          unit_org_code: currentUser.usr_org_code,
          unit_status: "Active"
        }
      );
      const options = res.data.map((branch) => ({
        value: branch.unit_code,
        label: branch.unit_name,
      }));
      setBranches(options);
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchProfiles();
    fetchBranches();
  }, []);

  const filteredUsers = users.filter(
    (item) =>
      item.usr_un?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.usr_full_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.usr_email?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    try {
      fetchUserRoles();
    } catch (error) {
      console.log(error);
    }
  }, [selectedUser]);

  const fetchUserRoles = async () => {
    try {
      if (selectedUser?._id) {
        const res = await newRequest.post(
          "/adUserRole/findAll",
          {
            ur_org_code: currentUser.usr_org_code,
            ur_user_docid: selectedUser._id,
          }
        );
        setUserRoles(res.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleConfirm = async () => {
    try {
      if (confirmDialogAction === "removeRole") {
        const res = await newRequest.delete(
          `/adUserRole/del/${objectToAction.object_id}`
        );

        const trace_res = await newRequest.post(
          "/trace/add",
          {
            trc_org_code: currentUser.usr_org_code,
            trc_action_on: "User",
            trc_doc_type: "User",
            trc_doc_no: usrUn,
            trc_aent_code: "",
            trc_ent_code: "",
            trc_action: "amended",
            trc_remarks: `Removed role ${objectToAction.param1}`,
            trc_tags: [`Removed role ${objectToAction.param1}`],
            trc_doc_docid: selectedUser._id,
            userCode: currentUser.usr_id,
          }
        );
        fetchUserTrace(selectedUser._id);
        fetchUserRoles();
      }
    } catch (err) {
      console.log(err);
    }
    // Close the dialog
    setIsConfirmDialogOpen(false);
  };

  const handleCancel = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleCloseNotifyDialog = () => {
    setIsNotifyDialogOpen(false);
  };

  const handleOpenOverlay = () => {
    setIsOverlayOpen(true);
  };

  const saveRecord = async (e) => {
    // e.preventDefault();
    try {
      if (!usrFullName) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter full name!`);
        setIsNotifyDialogOpen(true);
        return;
      }
      if (!usrEmail) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter email!`);
        setIsNotifyDialogOpen(true);
        return;
      }
      if (!usrPhoneNo) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter phone number!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      if (!usrUnitCode?.value) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please assign a business unit!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      if (!usrProfileCode?.value) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please assign a profile!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      // validate user data
      if (currencyAction === "addUser") {
        if (!userPw) {
          setNotifyDialogTitle("Note!");
          setNotifyDialogText(`Please enter password!`);
          setIsNotifyDialogOpen(true);
          return;
        }

        if (!userConfirmPw) {
          setNotifyDialogTitle("Note!");
          setNotifyDialogText(`Please confirm password!`);
          setIsNotifyDialogOpen(true);
          return;
        }

        const res = await newRequest.post(
          "/adUser/validate",
          {
            usr_org_code: currentUser.usr_org_code,
            usr_un: usrUn,
            usr_full_name: usrFullName,
            usr_email: usrEmail,
            usr_phone_no: usrPhoneNo,
            usr_unit_code: usrUnitCode?.value,
            usr_profile_code: usrProfileCode?.value,
          }
        );

        // console.log(`validate  ${JSON.stringify(res.data, null, 2)}`);

        if (res.data.length > 0) {
          setCreateUserErrors(res.data);
          setErrorDialogTitle("Oops. a few issues occured!");
          setIsErrorsDialogOpen(true);
          return;
        } else {
          // validate passwords
          if (userPw !== userConfirmPw) {
            setNotifyDialogText("Provided passwords do not match");
            setIsNotifyDialogOpen(true);
            return;
          }
        }
        // if validation has passed register the user
        const regUser = await newRequest.post(
          "/auth/register",
          {
            usr_org_code: currentUser.usr_org_code,
            usr_un: usrUn,
            usr_pw: userConfirmPw,
            usr_full_name: usrFullName,
            usr_email: usrEmail,
            usr_phone_no: usrPhoneNo,
            usr_unit_code: usrUnitCode?.value,
            usr_profile_code: usrProfileCode?.value,
            usr_enabled: usrEnabled,
            createdBy: currentUser.usr_id,
            updatedBy: currentUser.usr_id,
          }
        );

        // console.log(`regUser  ${JSON.stringify(regUser.data, null, 2)}`);
        setSelectedUser(regUser.data);
        // fetchUsers();
        fetchUserTrace(regUser.data._id);
        showToast(`Great. ${usrFullName} created!`, "success");
      } else {
        setSavingTitle("saving...");
        setIsProcessing(true);

        // if image is selected, upload it and get URL
        if (img) {
          await uploadToFirebase(img, "profiles");
        }

        // update user details
        const res = await newRequest.post(
          "/adUser/upd",
          {
            user_docid: selectedUser._id,
            usr_full_name: usrFullName,
            usr_email: usrEmail,
            usr_phone_no: usrPhoneNo,
            usr_unit_code: usrUnitCode?.value,
            usr_profile_code: usrProfileCode?.value,
            usr_enabled: usrEnabled,
            userCode: currentUser.usr_id,
          }
        );
        const updatedData = [...users];
        const currentRow = updatedData[selectedUserIndex];
        currentRow["usr_email"] = usrEmail;
        currentRow["usr_phone_no"] = usrPhoneNo;
        currentRow["usr_unit_code"] = usrUnitCode?.value;
        currentRow["usr_profile_code"] = usrProfileCode?.value;
        currentRow["usr_enabled"] = usrEnabled;
        setUsers(updatedData);
        fetchUserTrace(selectedUser._id);
        showToast(`Saved!`, "success");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSavingTitle("Save");
      setIsProcessing(false);
    }
  };

  const uploadToFirebase = async (file, fileFolder) => {
    setIsUploadDialogOpen(true);
    const storage = getStorage(app);
    const fileName = new Date().getTime() + file.name;
    const fileExtension = file.name.split(".").pop(); // Get file extension
    const fileType = file.type; // Get file type
    const fileSizeKB = file.size / 1024; // Get file size in KB
    const storageRef = ref(
      storage,
      `qc_${currentUser.usr_org_code}/${fileFolder}/${fileName}`
    );
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(Math.round(progress));
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        setIsUploadDialogOpen(false);
        console.log(error);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        if (fileFolder === "profiles") {
          // update user profile image
          const res = await newRequest.post(
            "/adUser/upd",
            {
              user_docid: selectedUser._id,
              usr_photo_url: downloadURL,
              userCode: currentUser.usr_id,
            }
          );

          const updatedData = [...users];
          const currentRow = updatedData[selectedUserIndex];
          currentRow["usr_photo_url"] = downloadURL;
          setUsers(updatedData);
          setUsrPhotoUrl(downloadURL)
          setImg("");
          setIsUploadDialogOpen(false);
        } else {
          await saveFileToDb(
            file.name,
            fileName,
            fileExtension,
            downloadURL,
            "kyc",
            fileSizeKB,
            selectedUser._id
          );
          // setImg("");
          setIsUploadDialogOpen(false);
        }
      }
    );
  };

  const saveFileToDb = async (
    fl_name,
    fl_internal_name,
    fl_type,
    fl_path,
    fl_category,
    fl_size_kb,
    fl_parent_doc_id
  ) => {
    try {
      const params = {
        fl_org_code: currentUser.usr_org_code,
        fl_name: fl_name,
        fl_internal_name: fl_internal_name,
        fl_type: fl_type,
        fl_path: fl_path,
        fl_category: fl_category,
        fl_doc_type: "",
        fl_doc_no: "",
        fl_doc_index: "",
        fl_doc_sub_index: "",
        fl_ent_code: "",
        fl_aent_code: "",
        fl_user_id: selectedUser._id,
        fl_size_kb,
        fl_parent_doc_id,
        userCode: currentUser.usr_id,
      };

      const res = await newRequest.post("/files/add", params);

      const trace_res = await newRequest.post(
        "/trace/add",
        {
          trc_org_code: currentUser.usr_org_code,
          trc_action_on: "User",
          trc_doc_type: "User",
          trc_doc_no: usrUn,
          trc_aent_code: "",
          trc_ent_code: "",
          trc_action: "amended",
          trc_remarks: `Added attachment ${fl_name}`,
          trc_tags: [`Added attachment ${fl_name}`],
          trc_doc_docid: selectedUser._id,
          userCode: currentUser.usr_id,
        }
      );

    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };

  return (
    <div className="ad-user">
      {isLoading ? (
        <div className="no-content"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <ScaleLoader
            color="#F44336"
            height={50}
            radius={8}
            width={4}
          />
        </div>
      ) : (
        <div className="ad-user-main-wrapper">
          {templateView === "list" &&
            <motion.div className="list-wrapper"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 1 } }}
              exit={{ opacity: 0, transition: { duration: 1 } }}>
              <div className="list-navbar">
                <span className="main-title">Users</span>
                <CustomButton
                  startIcon={<AddIcon />}
                  onClick={() => {
                    try {
                      setCurrencyAction("addUser");
                      setUserRoles(null);
                      setSelectedUser(null);
                      setUsrUn("");
                      setImg(null);
                      setUsrPhotoUrl("");
                      setUsrFullName("");
                      setUsrEmail("");
                      setUsrPhoneNo("");
                      setUsrEnabled("N");
                      setUsrUnitCode({
                        value: "",
                        label: "",
                      });
                      setUsrProfileCode({
                        value: "",
                        label: "",
                      });
                      setTemplateView("single")
                    } catch (error) {
                      console.log(error)
                    }
                  }}
                >
                  New User
                </CustomButton>
              </div>
              <div className="list-content">
                <FaUsers className="icon" />
                <span className="title">Application Users</span>
                <p>Create templates that will be used for customer engagement. From Renewal notifications, birthdays, events and notices, let Qillo engage your clients and business partners</p>
                <div className="search-input-wrapper">
                  <SearchIcon />
                  <input
                    className="search-input"
                    type="text"
                    placeholder="Search template..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') handleSearchUsers();
                    }}
                  />
                  {searchTerm.length > 0 && (
                    <CloseIcon
                      onClick={(e) => {
                        try {
                          fetchUsers();
                          setSearchTerm("");
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                      className="search-input-icon"
                    />
                  )}
                  {searchTerm.length === 0 && (
                    <span className="search-input-icon" />
                  )}
                  <SearchButton
                    width={"200px"}
                    themeColor={"#9c27b0"}
                    onClick={handleSearchUsers}>
                    Search
                  </SearchButton>
                </div>
                <div className="cards-wrapper">
                  {filteredUsers.map((row) => (
                    <div className="single-card"
                      key={row._id}
                      onClick={() => {
                        try {
                          setCurrencyAction("viewUser");
                          setImg(null);
                          setUsrUn(row.usr_un);
                          setUsrFullName(row.usr_full_name);
                          setUsrPhotoUrl(row.usr_photo_url);
                          setUsrEmail(row.usr_email);
                          setUsrPhoneNo(row.usr_phone_no);
                          setUsrEnabled(row.usr_enabled);
                          setUsrUnitCode({
                            value: row.usr_unit_code,
                            label: row.bizunit_name_xx,
                          });
                          setUsrProfileCode({
                            value: row.usr_profile_code,
                            label: row.profile_name_xx,
                          });
                          setSelectedUser(row);
                          fetchUserTrace(row._id);
                          setTemplateView("single");
                        } catch (error) {
                          console.log(error)
                        }
                      }}
                    >
                      <div className="single-card-top">
                        <div className="single-card-top-left">
                          {row?.usr_photo_url && <img
                            src={row?.usr_photo_url}
                            alt=""
                            className="img"
                          />}
                          {!row?.usr_photo_url && <BrokenImageOutlinedIcon style={{ width: "60px", height: "60px" }} />}
                        </div>
                        <div className="single-card-top-right">
                          <span className="user-full-name">{row?.usr_full_name}</span>
                          <span className="user-type">{`${row.user_type_xx} user`}</span>
                        </div>
                      </div>
                      <div className="single-card-center">
                        <div className="single-card-center-row">
                          <span className="user-title">Biz Unit</span>
                          <span className="user-title">Status</span>
                        </div>
                        <div className="single-card-center-row">
                          <span className="user-title-value">{row.bizunit_name_xx}</span>
                          <span className="user-title-value">{row.usr_enabled === "Y" ? "Active" : "Inactive"}</span>
                        </div>

                        <div className="single-card-center-col">
                          <div className="email-phone-row">
                            <LocalPhoneIcon /> <span>{row.usr_phone_no}</span>
                          </div>
                          <div className="email-phone-row">
                            <EmailIcon /> <span>{row.usr_email}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="list-footer">
                  <button className="overlay-button" onClick={handleOpenOverlay}>
                    How it works
                  </button>
                </div>
              </div>
            </motion.div>}
          {templateView === "single" &&
            <div className="single-user-wrapper">
              <div className="user-center-wrapper">
                <div className="user-center-details">
                  <div className="user-center-navbar">
                    <div className="user-navbar-left">
                      <span className="main-title">
                        {currencyAction === "viewUser" ? usrFullName : "New User"}
                      </span>
                    </div>
                    <div className="user-navbar-right">
                      <CustomLoadingButton
                        startIcon={<FaSave />}
                        onClick={saveRecord}
                      >
                        {savingTitle}
                      </CustomLoadingButton>
                      {["admin", "superadmin"].includes(currentUser.user_type_xx) && (
                        <CustomLoadingButton
                          startIcon={<AddIcon />}
                          onClick={() => {
                            try {
                              setCurrencyAction("addUser");
                              setUserRoles(null);
                              setSelectedUser(null);
                              setUsrUn("");
                              setImg(null);
                              setUsrPhotoUrl("");
                              setUsrFullName("");
                              setUsrEmail("");
                              setUsrPhoneNo("");
                              setUsrEnabled("N");
                              setUsrUnitCode({
                                value: "",
                                label: "",
                              });
                              setUsrProfileCode({
                                value: "",
                                label: "",
                              });
                            } catch (error) {
                              console.log(error);
                            }
                          }}
                        >
                          New User
                        </CustomLoadingButton>
                      )}
                      <CustomLoadingButton
                        startIcon={<MenuIcon />}
                        onClick={() => {
                          try {
                            if (!searchTerm) {
                              fetchUsers();
                            }
                            setTemplateView("list")
                          } catch (error) {
                            console.log(error)
                          }
                        }}
                      >
                        View All
                      </CustomLoadingButton>
                    </div>
                  </div>
                  <hr />
                  <div className="form-details-wrapper">
                    <div className="form-details-left">
                      <div className="input-item-with-label">
                        <label
                          htmlFor="usr_full_name"
                          style={{
                            width: "130px",
                          }}
                        >
                          NAME
                        </label>
                        <input
                          id="usr_full_name"
                          name="usr_full_name"
                          type="text"
                          placeholder="NAME"
                          value={usrFullName}
                          onChange={(e) => {
                            try {
                              const updatedData = [...users];
                              const currentRow = updatedData[selectedUserIndex];
                              currentRow["usr_full_name"] = e.target.value;
                              setUsers(updatedData);
                              setUsrFullName(e.target.value);
                            } catch (error) { }
                          }}
                          className="form-input-item"
                          style={{ width: "400px" }}
                          disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                        />
                      </div>
                      <div className="input-item-with-label">
                        <label
                          htmlFor="usr_profile_code"
                          style={{
                            width: "130px",
                          }}
                        >
                          PROFILE
                        </label>
                        <Select
                          id="usr_profile_code"
                          name="usr_profile_code"
                          styles={customSelectStyles}
                          width="400px"
                          value={usrProfileCode}
                          onChange={(selectedOption) => {
                            try {
                              setUsrProfileCode(selectedOption);
                            } catch (error) {
                              console.log(error);
                            }
                          }}
                          options={profiles}
                          isSearchable={true}
                          placeholder="PROFILE"
                          isDisabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                        />
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                      >
                        <div className="input-item-with-label">
                          <label
                            htmlFor="usr_phone_no"
                            style={{
                              width: "130px",
                            }}
                          >
                            PHONE NO
                          </label>
                          <input
                            id="usr_phone_no"
                            name="usr_phone_no"
                            type="text"
                            placeholder="PHONE NO"
                            value={usrPhoneNo}
                            onChange={(e) => setUsrPhoneNo(e.target.value)}
                            className="form-input-item"
                            style={{ width: "150px" }}
                            disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                          />
                        </div>
                        <div
                          className="input-item-with-label"
                          style={{ justifyContent: "flex-end" }}
                        >
                          <label
                            htmlFor="usr_unit_code"
                            style={{
                              width: "75px",
                            }}
                          >
                            BIZ UNIT
                          </label>
                          <Select
                            id="usr_unit_code"
                            name="usr_unit_code"
                            styles={customSelectStyles}
                            width="170px"
                            value={usrUnitCode}
                            onChange={(selectedOption) => {
                              try {
                                setUsrUnitCode(selectedOption);
                              } catch (error) {
                                console.log(error);
                              }
                            }}
                            options={branches}
                            isSearchable={true}
                            placeholder="BIZ UNIT"
                            isDisabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                          />
                        </div>
                      </div>
                      <div className="input-item-with-label">
                        <label
                          htmlFor="usr_email"
                          style={{
                            width: "130px",
                          }}
                        >
                          EMAIL
                        </label>
                        <input
                          id="usr_email"
                          name="usr_email"
                          type="text"
                          placeholder="EMAIL"
                          value={usrEmail}
                          onChange={(e) => setUsrEmail(e.target.value)}
                          className="form-input-item"
                          style={{ width: "400px" }}
                          onBlur={(e) => {
                            try {
                              if (currencyAction === "addUser" && !usrUn) {
                                setUsrUn(e.target.value);
                              }
                            } catch (error) {
                              console.log(error);
                            }
                          }}
                          disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                        />
                      </div>
                      <div className="input-item-with-label">
                        <label
                          htmlFor="usr_un"
                          style={{
                            width: "130px",
                          }}
                        >
                          USERNAME
                        </label>
                        <input
                          id="usr_un"
                          name="usr_un"
                          type="text"
                          placeholder="USERNAME"
                          value={usrUn}
                          onChange={(e) => setUsrUn(e.target.value)}
                          className="form-input-item"
                          style={{ width: "400px" }}
                          disabled={selectedUser}
                        />
                      </div>
                      {currencyAction === "addUser" && (
                        <div
                          style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                          <div className="input-item-with-label">
                            <label
                              htmlFor="user_pw"
                              style={{
                                width: "130px",
                              }}
                            >
                              PASSWORD
                            </label>
                            <input
                              id="user_pw"
                              name="user_pw"
                              type="text"
                              placeholder="PASSWORD"
                              value={userPw}
                              onChange={(e) => setUserPw(e.target.value)}
                              className="form-input-item"
                              style={{ width: "160px" }}
                            />
                          </div>
                          <div
                            className="input-item-with-label"
                            style={{ justifyContent: "flex-end" }}
                          >
                            <label
                              htmlFor="user_confirm_pw"
                              style={{
                                width: "max-content",
                              }}
                            >
                              CONFIRM
                            </label>
                            <input
                              id="user_confirm_pw"
                              name="user_confirm_pw"
                              type="text"
                              placeholder="CONFIRM PW"
                              value={userConfirmPw}
                              onChange={(e) => setUserConfirmPw(e.target.value)}
                              className="form-input-item"
                              style={{ width: "160px" }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="form-details-right">
                      <div className="logo">
                        {!img && (
                          <img
                            src={usrPhotoUrl || <BrokenImageOutlinedIcon style={{ width: "120px", height: "120px" }} />}
                            alt=""
                            className="img"
                          />
                        )}
                        {img && (
                          <img
                            src={
                              img ? URL.createObjectURL(img) : <BrokenImageOutlinedIcon style={{ width: "120px", height: "120px" }} />
                            }
                            alt=""
                            className="img"
                          />
                        )}
                        <input
                          type="file"
                          id="file"
                          style={{ display: "none" }}
                          accept="image/*"
                          onChange={(e) => setImg(e.target.files[0])}
                        />
                        {selectedUser && ["admin", "superadmin"].includes(currentUser.user_type_xx) &&
                          <label htmlFor="file">
                            <div className="item">
                              <span>Select Image</span>
                            </div>
                          </label>}
                      </div>
                      <ToggleSwitch
                        id="usr_enabled"
                        value={usrEnabled}
                        onChange={setUsrEnabled}
                        label={"USER ENABLED"}
                        labelWidth="max-content"
                        disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                        border="N"
                        style={{
                          width: "max-content",
                        }}
                      />
                    </div>
                  </div>
                  <hr />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      justifyContent: "flex-start",
                    }}
                  >
                    <h2>ASSIGNED ROLES</h2>
                    {["admin", "superadmin"].includes(currentUser.user_type_xx) &&
                      <>{currencyAction === "viewUser" && (
                        <Tooltip
                          title={`Assign roles to ${usrFullName}`}
                          placement="right"
                        >
                          <AddIcon
                            size={50}
                            style={{
                              borderRadius: "5px",
                              padding: "3px",
                              alignSelf: "center",
                              cursor: "pointer",
                              backgroundColor: "rgba(0, 128, 0, 0.5)",
                            }}
                            onClick={async () => {
                              try {
                                await fetchRoles();
                                setShowAddRoles(true);
                              } catch (error) {
                                console.log(error);
                              }
                            }}
                          />
                        </Tooltip>
                      )}</>
                    }
                  </div>
                  <div className="user-roles-wrapper">
                    {userRoles?.map((row, index) => (
                      <div key={row._id} className="single-item">
                        <span
                          style={{
                            alignContent: "flex-start",
                            fontWeight: "bold",
                            fontSize: "20px",
                          }}
                        >
                          {index + 1}.
                        </span>
                        <span
                          style={{
                            fontWeight: "500",
                            fontSize: "20px",
                          }}
                        >{`${row.role_name_xx}`}</span>
                        {["admin", "superadmin"].includes(currentUser.user_type_xx) &&
                          <Tooltip
                            title={`Remove ${row.role_name_xx} from ${usrFullName}`}
                            placement="right"
                          >
                            <IoClose
                              size={24}
                              style={{
                                borderRadius: "5px",
                                padding: "3px",
                                cursor: "pointer",
                                color: "rgba(172, 33, 9)",
                                backgroundColor: "rgba(172, 33, 9, 0.5)",
                              }}
                              onClick={() => {
                                try {
                                  const action_text = `Do remove ${row.role_name_xx} from ${usrFullName}?`;
                                  setObjectToAction({
                                    object_id: row._id,
                                    action_text: action_text,
                                    param1: row.role_name_xx
                                  });
                                  setConfirmDialogAction("removeRole");
                                  setIsConfirmDialogOpen(true);
                                } catch (error) {
                                  console.log(error);
                                }
                              }}
                            />
                          </Tooltip>}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="right-wrapper">
                <div className="right-content">
                  <h1 className="main-title">TIMELINE</h1>
                  <hr />
                  <div className="timeline-root">
                    {userTraceData.map((item) => (
                      <div key={item?._id} className="timeline-item">
                        <div className="timeline-separator">
                          <div className="timeline-dot"></div>
                          <div className="timeline-connector"></div>
                        </div>
                        <div className="timeline-content">
                          <div className="timeline-navbar">
                            <div className="avatar-container">
                              {item?.updated_by_photo_url_xx ?
                                <img src={item?.updated_by_photo_url_xx} alt="" className="avatar" /> : <BrokenImageOutlinedIcon style={{ width: "30px", height: "30px" }} />}
                              <span className="username">{item?.updated_by_name_xx}</span>
                            </div>
                            <span className="time-ago">{item?.time_ago_xx}</span>
                          </div>
                          <div className="remarks">
                            {item?.trc_remarks}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>}
        </div>
      )}
      {/* errors on user creation*/}
      <Dialog open={isErrorsDialogOpen} onClose={handleErrorsCloseDialog}>
        <DialogTitle>{errorDialogTitle}</DialogTitle>
        <DialogContent>
          {createUserErrors.map((row, index) => (
            <div
              key={row._id}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    marginRight: "5px",
                  }}
                >
                  {index + 1}.
                </span>
                <span dangerouslySetInnerHTML={{ __html: row.text }} />
              </div>
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleErrorsCloseDialog}>Close</CustomButton>
        </DialogActions>
      </Dialog>

      {/* Dialog to notify user about changes being saved */}
      <Dialog open={isNotifyDialogOpen} onClose={handleCloseNotifyDialog}>
        <DialogTitle>{notifyDialogTitle}</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: notifyDialogText }} />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseNotifyDialog}>Close</CustomButton>
        </DialogActions>
      </Dialog>
      {/* Confirm Dialog */}
      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        dialogTitle="Confirmation"
        dialogContent={<p>{objectToAction.action_text}</p>}
      />
      {isProcessing && (
        <motion.div
          className="processing-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="processing-wrapper">
            <GridLoader
              color="#F44336"
              style={{
                height: "50px",
                width: "50px",
              }}
            />
          </div>
        </motion.div>
      )}
      {showAddRoles && (
        <motion.div
          className="syscode-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.5 } }}
          exit={{ opacity: 0, transition: { duration: 0.5 } }}
        >
          <div className="syscode-wrapper">
            <div className="syscode-navbar">
              <div className="syscode-navbar-left">
                <span>ROLES</span>
              </div>
              <div className="syscode-navbar-right">
                <span onClick={() => setShowAddRoles(false)}>
                  <IoClose
                    style={{
                      width: "30px",
                      height: "30px",
                      cursor: "pointer",
                      borderRadius: "50%",
                    }}
                  />
                </span>
              </div>
            </div>
            <div className="syscode-content">
              <TableContainer
                component={Paper}
                className="table-container"
                sx={{ minWidth: 300, minHeight: 300 }}
              >
                <Table>
                  <TableHead className="tablehead">
                    <TableRow>
                      <TableCell
                        className="table-cell"
                        align="right"
                        style={{
                          fontWeight: "bold",
                          width: "50px",
                          textAlign: "center",
                          padding: "3px",
                        }}
                      >
                        #
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        style={{
                          fontWeight: "bold",
                          width: "auto",
                          padding: "3px",
                        }}
                      >
                        Role
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        style={{
                          fontWeight: "bold",
                          width: "50px",
                          alignItems: "center",
                          textAlign: "center",
                          padding: "3px",
                          color: "#146cbe",
                        }}
                      >
                        Add
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {systemRoles.map((row, index) => (
                      <TableRow
                        key={row._id}
                        className="tablerow"
                        style={{ padding: "12px" }}
                      >
                        <TableCell
                          className="table-cell"
                          align="right"
                          style={{
                            fontWeight: "bold",
                            width: "50px",
                            textAlign: "center",
                            padding: "3px",
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          className="table-cell"
                          style={{
                            fontWeight: "bold",
                            width: "auto",
                            padding: "3px",
                          }}
                        >
                          {row.role_name}
                        </TableCell>
                        <TableCell
                          className="table-cell"
                          style={{
                            alignItems: "center",
                            textAlign: "center",
                            padding: "3px",
                          }}
                        >
                          {/* Action buttons */}
                          <Tooltip title="Add" placement="left">
                            <QueueIcon
                              style={{
                                height: "30px",
                                width: "30px",
                                alignItems: "center",
                                textAlign: "center",
                                padding: "3px",
                                cursor: "pointer",
                                color: "#146cbe",
                              }}
                              onClick={async (e) => {
                                try {
                                  const res = await newRequest.post(
                                    "/adUserRole/add",
                                    {
                                      ur_org_code: currentUser.usr_org_code,
                                      ur_user_docid: selectedUser._id,
                                      ur_role_code: row.role_code,
                                      ur_display_order: 0,
                                      updatedBy: currentUser.usr_id,
                                      createdBy: currentUser.usr_id,
                                    }
                                  );

                                  if (res.data === "exists") {
                                    showToast(
                                      `Role ${row.role_name} already assigned`,
                                      "error"
                                    );
                                  } else {

                                    const trace_res = await newRequest.post(
                                      "/trace/add",
                                      {
                                        trc_org_code: currentUser.usr_org_code,
                                        trc_action_on: "User",
                                        trc_doc_type: "User",
                                        trc_doc_no: usrUn,
                                        trc_aent_code: "",
                                        trc_ent_code: "",
                                        trc_action: "amended",
                                        trc_remarks: `Assigned role ${row.role_name}`,
                                        trc_tags: [`Assigned role ${row.role_name}`],
                                        trc_doc_docid: selectedUser._id,
                                        userCode: currentUser.usr_id,
                                      }
                                    );

                                    showToast(
                                      `Role ${row.role_name} assigned`,
                                      "success"
                                    );
                                  }
                                  fetchUserTrace(selectedUser._id);
                                  fetchUserRoles();
                                } catch (error) {
                                  console.log(error);
                                }
                              }}
                            />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {/* <div className="syscode-action">
              <CustomButton>proceed</CustomButton>
            </div> */}
          </div>
        </motion.div>
      )}
      {isOverlayOpen && (
        <motion.div
          className="overlay-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="overlay-wrapper">
            <div className="overlay-navbar">
              <span>HELP</span>
              <span onClick={() => setIsOverlayOpen(false)}>
                <IoClose
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    borderRadius: "50%",
                  }}
                />
              </span>
            </div>
            <div className="overlay-content">
              <VideoPlayer videoUrl="https://www.youtube.com/embed/PPCfDe8TfJQ" />
            </div>
            <div className="overlay-action">
            </div>
          </div>
        </motion.div>
      )}
      {/* progress dialog for file upload  */}
      <UploadDialog
        open={isUploadDialogOpen}
        handleClose={handleUploadDoalogClose}
        uploadProgress={uploadProgress}
      />
    </div>
  );
};

const UploadDialog = ({ open, handleClose, uploadProgress }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Progress</DialogTitle>
      <DialogContent>
        <LinearProgress variant="determinate" value={uploadProgress} />
        {uploadProgress && <p>{uploadProgress}% Complete</p>}
      </DialogContent>
    </Dialog>
  );
};

export default AdUser;
