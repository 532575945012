import "./ArReceiptHome.scss";
import CircularButton, {
  CustomButton,
  CustomLoadingButton,
} from "../../../components/ui/CustomButton";
import newRequest from "../../../utils/newRequest";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
} from "@mui/material";
import { motion } from "framer-motion";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {  ScaleLoader } from "react-spinners";

const ArReceiptHome = () => {
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [buttonTitle, setButtonTitle] = useState("REFRESH");
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [receipts, setReceipts] = useState([]);
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const fetchReceipts = async () => {
    try {
      setIsLoading(true);
      setButtonTitle("WORKING...");
      // Simulate delay for 5 seconds
      // await new Promise((resolve) => setTimeout(resolve, 5000));
      const res = await newRequest.post(
        "/arReceiptHdr/findAll",
        {
          hd_org_code: currentUser.usr_org_code,
        },
        config
      );
      setReceipts(res.data);
      setButtonTitle("REFRESH");
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setButtonTitle("REFRESH");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchReceipts();
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 6));
    setPage(0);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const filteredReceiptsData = receipts.filter(
    (item) =>
      item?.hd_no?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item?.bank_ac_no_xx?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item?.client_name_xx?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item?.hd_chq_no?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item?.hd_fc_amount
        ?.toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      item?.hd_ref_no?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const slicedReceiptsData = filteredReceiptsData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <motion.div
      className="ar-receipt-home"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      {isLoading ? (
        <div className="no-content"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <ScaleLoader
            color="#F44336"
            height={50}
            radius={8}
            width={4}
          />
        </div>
      ) : (
        <>
          {slicedReceiptsData?.length === 0 &&
            <div className="no-content">
              <h1>Welcome to Receipts home</h1>
              <p>
                Keep your transactions organized and stay on top of your finances. Start by adding your first receipt.
              </p>
              <CircularButton onClick={() => {
                try {
                  navigate(`/receipt/new`, {
                    state: null,
                  });
                } catch (error) {
                  console.log(error)
                }
              }} />
            </div>}
          {slicedReceiptsData?.length > 0 && <div className="navbar">
            <div className="navbar-left">
              <div className="search-input-wrapper">
                <SearchIcon className="search-input-icon" />
                <input
                  className="search-input"
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                {searchTerm.length > 0 && (
                  <CloseIcon
                    onClick={handleClearSearch}
                    className="search-input-icon"
                  />
                )}
              </div>
            </div>
            <div className="navbar-right">
              <CustomLoadingButton
                startIcon={<RefreshIcon />}
                onClick={fetchReceipts}
              >
                {buttonTitle}
              </CustomLoadingButton>
              <CustomButton
                startIcon={<AddIcon />}
                onClick={() => {
                  try {
                    navigate(`/receipt/new`, {
                      state: null,
                    });
                  } catch (error) {
                    console.log(error)
                  }
                }}
              >
                New Receipt
              </CustomButton>
            </div>
          </div>}
          {slicedReceiptsData?.length > 0 &&
            <div className="table-content">
              <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none' }}
                className="table-container">
                <Table sx={{ width: "max-content", border: 'none' }}>
                  <TableHead className="tablehead">
                    <TableRow
                      className="tablerow"
                    >
                      <TableCell className="table-cell" style={{
                        display: "flex",
                        maxWidth: "50px",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: "bold",
                      }}>
                        <span>#</span>
                      </TableCell>
                      <TableCell className="table-cell" style={{
                        display: "flex",
                        maxWidth: "150px"
                      }}>
                        <span>Receipt No</span>
                      </TableCell>
                      <TableCell className="table-cell" style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "250px"
                      }}>
                        <span>From</span>
                      </TableCell>
                      <TableCell className="table-cell" style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "150px"
                      }}>
                        <span>Received Towards/Branch</span>
                      </TableCell>
                      <TableCell className="table-cell" style={{
                        display: "flex",
                        maxWidth: "150px"
                      }}>
                        <span>Date</span>
                      </TableCell>
                      <TableCell className="table-cell" style={{
                        alignItems: "right", display: "flex",
                      }}>
                        <span style={{
                          textAlign: "right"
                        }}>Amounts</span>
                      </TableCell>
                      <TableCell className="table-cell" style={{
                        display: "flex",
                        maxWidth: "100px"
                      }}>
                        <span>Status</span>
                      </TableCell>
                      <TableCell className="table-cell">
                        <span>Info</span>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody className="tablebody">
                    {slicedReceiptsData?.map((receipt, index) => (
                      <TableRow
                        key={receipt._id}
                        className="tablerow"
                        onClick={() => {
                          try {
                            navigate(`/receipt/${receipt._id}`, {
                              state: { shouldFetchClients: false },
                            });
                          } catch (error) {
                            console.log(error)
                          }
                        }}
                      >
                        <TableCell className="table-cell"
                          style={{
                            display: "flex",
                            maxWidth: "50px",
                            alignItems: "center",
                            justifyContent: "center",
                            fontWeight: "bold",
                          }}>
                          <span>{page * rowsPerPage + index + 1}</span>
                        </TableCell>
                        <TableCell className="table-cell" style={{
                          display: "flex",
                          maxWidth: "150px"
                        }}>
                          <span style={{
                            fontWeight: "bold"
                          }}>  {receipt.hd_no} </span>
                          <span>{`Ref: ${receipt?.hd_ref_no}`}</span>
                        </TableCell>
                        <TableCell className="table-cell" style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "250px"
                        }}>
                          <span>{receipt.client_name_xx}</span>
                          <span style={{
                            fontWeight: "bold",
                            fontSize: "10px",
                            color: "#9c27b0"
                          }}> {receipt.hd_mode}</span>
                        </TableCell>

                        <TableCell className="table-cell" style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "150px"
                        }}>
                          <span>{receipt.hd_paying_for}</span>
                          <span style={{
                            fontWeight: "bold",
                            fontSize: "10px",
                            color: "#9c27b0"
                          }}> {receipt.bizunit_name_xx}</span>
                        </TableCell>

                        <TableCell className="table-cell" style={{
                          display: "flex",
                          flexDirection: "column",
                          maxWidth: "150px"
                        }}>
                          <span>
                            {moment(receipt?.hd_gl_date).format("DD-MMM-YYYY")}
                          </span>
                        </TableCell>
                        <TableCell className="table-cell" style={{
                          alignItems: "right",
                          display: "flex",
                          flexDirection: "column"
                        }}>
                          <span style={{
                            textAlign: "right",
                            fontWeight: "bold",
                            fontSize: "10px",
                            color: "#f44336"
                          }}>{receipt.hd_cur_code}</span>
                          <span style={{
                            textAlign: "right",
                            fontWeight: "bold"
                          }}>{`Received ${numeral(
                            receipt.hd_fc_amount
                          ).format("0,0.00")}`}</span>
                          <span
                            style={{
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >{`Allocated ${numeral(
                            receipt.allocated_fc_amount_xx
                          ).format("0,0.00")}`}</span>
                        </TableCell>

                        <TableCell className="table-cell" style={{
                          display: "flex",
                          maxWidth: "100px"
                        }}>
                          <span className={`doc-status ${receipt.hd_status}`}>
                            {receipt.hd_status}
                          </span>
                          <span style={{
                            fontWeight: "bold",
                            fontSize: "10px",
                            color: "#9c27b0"
                          }}> {receipt.hd_chq_no}</span>
                        </TableCell>
                        <TableCell className="table-cell" style={{
                          alignItems: "right", display: "flex",
                          flexDirection: "column",
                          fontSize: "12px",
                          fontWeight: "bold"
                        }}>
                          <span>{`Received By : ${receipt.created_by_name_xx}`}</span>
                          <span> {receipt?.time_ago_xx}</span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[7]}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  component="div"
                  count={filteredReceiptsData.length}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </TableContainer>
            </div>}
        </>
      )}
    </motion.div>
  );
};

export default ArReceiptHome;
