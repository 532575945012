
import { useNavigate } from "react-router-dom";
import "./EmailTemplate.scss"
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { ScaleLoader } from "react-spinners";
import { showToast } from "../../../toastService.js";
import newRequest from "../../../utils/newRequest";
import { CustomButton, CustomLoadingButton, SearchButton } from "../../../components/ui/CustomButton";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { IoClose } from "react-icons/io5";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from "@mui/icons-material/Close";
import { MdEmail } from "react-icons/md";
import VideoPlayer from "../../../components/videoPlayer/VideoPlayer.jsx";
import {
    DialogActions,
    Dialog,
    DialogTitle,
    DialogContent,
    Chip,
    Tooltip,
} from "@mui/material";
import Select from "react-select";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill's CSS
// Import Quill's font color and indent modules
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.bubble.css';
import ConfirmDialog from "../../../components/confirmDialog/confirmDialog.jsx";
import { replaceTags, sanitizeText } from "../../../utils/genericFunctions.js";
import { customSelectStyles } from "../../../components/ui/uiStyleElements.jsx";

const EmailTemplate = () => {
    const { currentUser } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const [templates, setTemplates] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [templateView, setTemplateView] = useState("list");
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [commTags, setCommTags] = useState([]);

    const [tptId, setTptId] = useState(null);
    const [tptRef, setTptRef] = useState(null);
    const [tptName, setTptName] = useState("");
    const [tptDesc, setTptDesc] = useState("");
    const [tptModule, setTptModule] = useState("");
    const [tptCategory, setTptCategory] = useState(null);
    const [tptSubject, setTptSubject] = useState("");
    const [tptEmailContent, setTptEmailContent] = useState("");
    const [tptSmsContent, setTptSmsContent] = useState("");
    const [emailCursorPosition, setEmailCursorPosition] = useState(0);
    const [smsCursorPosition, setSmsCursorPosition] = useState(0);
    const quillEmailRef = useRef(null);
    const smsRef = useRef(null);
    const [isEmailFocused, setIsEmailFocused] = useState(false);
    const [isSmsFocused, setIsSmsFocused] = useState(false);
    const [fieldToUpdate, setFieldToUpdate] = useState(null);

    const [previewEmailContent, setPreviewEmailContent] = useState("");
    const [previewSmsContent, setPreviewSmsContent] = useState("");


    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
    const [notifyDialogTitle, setNotifyDialogTitle] = useState("Confirm");
    const [notifyDialogText, setNotifyDialogText] = useState("Text");
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [confirmDialogAction, setConfirmDialogAction] = useState("None");
    const [objectToAction, setObjectToAction] = useState({
        object_id: "",
        action_text: "",
        new_doc_status: "",
    });


    // Extend Quill with font color and indent modules
    const modules = {
        toolbar: [
            // [{ 'font': [] }],
            // [{ 'size': ['small', 'medium', 'large', 'huge'] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            // ['link', 'image'],
            ['link'],
            ['clean']
        ],
    };

    // Handle cursor position change
    const handleSelectionChange = (range, source, editor) => {
        if (range) {
            setEmailCursorPosition(range.index);
        }
    };

    // entity types
    const [templateCatgOptions, setTemplateCatgOptions] = useState([]);
    const fetchTemplateCatg = async () => {
        try {
            const res = await newRequest.post(
                "/sysCode/findAll", { sys_type: "Comm Template Catg" }
            );

            const options = res.data.map((catg) => ({
                value: catg.sys_code,
                label: catg.sys_name,
            }));

            setTemplateCatgOptions(options);
        } catch (error) {
            console.error("Error fetching Comm Template Catg :", error);
        }
    };

    const fetchCommTags = async () => {
        try {
            const res = await newRequest.post("/sysCode/findAll", { sys_type: "Comm Tags", });
            setCommTags(res.data);
        } catch (error) {
            console.error("Error fetching entity types:", error);
        }
    };

    const handleCloseNotifyDialog = () => {
        setIsNotifyDialogOpen(false);
    };

    const handleOpenOverlay = () => {
        setIsOverlayOpen(true);
    };


    const fetchTemplates = async () => {
        try {
            setIsLoading(true);
            // Simulate delay for 5 seconds
            // await new Promise((resolve) => setTimeout(resolve, 5000));
            const res = await newRequest.post(
                "/emailTemplate/findAll",
                { tpt_module: "", result_limit: 4 }
            );

            // console.log(`setTemplates  ${JSON.stringify(res.data, null, 2)}`);

            setTemplates(res.data);
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchTemplates();
        fetchTemplateCatg();
        fetchCommTags();
    }, []);


    const handleSearchTemplates = async () => {
        try {
            setIsLoading(true);
            const res = await newRequest.post("/emailTemplate/search", { search_input: searchTerm });
            setTemplates(res.data);
        } catch (error) {
            console.error("Error searching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const saveRecord = async () => {
        try {

            if (!tptName) {
                setNotifyDialogTitle("Note!");
                setNotifyDialogText(`Enter template name!`);
                setIsNotifyDialogOpen(true);
                return;
            }

            // if (!tptRef) {
            //     setNotifyDialogTitle("Note!");
            //     setNotifyDialogText(`Enter unique reference!`);
            //     setIsNotifyDialogOpen(true);
            //     return;
            // }

            if (!tptDesc) {
                setNotifyDialogTitle("Note!");
                setNotifyDialogText(`Enter template description!`);
                setIsNotifyDialogOpen(true);
                return;
            }
            if (!tptSubject) {
                setNotifyDialogTitle("Note!");
                setNotifyDialogText(`Enter email subject!`);
                setIsNotifyDialogOpen(true);
                return;
            }

            if (tptId === "new") {
                // save template
                try {
                    const res = await newRequest.post(
                        "/emailTemplate/add",
                        {
                            tpt_ref: tptRef,
                            tpt_name: tptName,
                            tpt_desc: tptDesc,
                            tpt_module: tptModule,
                            tpt_category: tptCategory?.value,
                            tpt_subject: tptSubject,
                            tpt_email_content: tptEmailContent,
                            tpt_sms_content: tptSmsContent,
                        }
                    );
                    setTptId(res.data._id);
                    showToast(`${tptName} successfully added!`, "success");
                } catch (error) {
                    showToast(`Error adding ${tptName}!`, "error");
                }
            } else if (tptId !== "new") {
                // save template
                try {
                    const res = await newRequest.put(
                        `/emailTemplate/upd/${tptId}`,
                        {
                            tpt_name: tptName,
                            tpt_desc: tptDesc,
                            tpt_module: tptModule,
                            tpt_category: tptCategory?.value,
                            tpt_subject: tptSubject,
                            tpt_email_content: tptEmailContent,
                            tpt_sms_content: tptSmsContent,
                        }
                    );
                    showToast(`${tptName} successfully updated!`, "success");
                } catch (error) {
                    showToast(`Error updating ${tptName}!`, "error");
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleResetFields = () => {
        try {
            setTptRef("");
            setTptName("");
            setTptDesc("");
            setTptModule("");
            setTptCategory(null);
            setTptSubject("");
            setTptEmailContent("");
            setTptSmsContent("");
            setFieldToUpdate(null)
        } catch (error) {
            console.log(error)
        }
    };

    const handleChipClickSms = (chipValue) => {
        try {
            const newText =
                tptSmsContent.slice(0, smsCursorPosition) +
                `{{${chipValue}}}` +
                tptSmsContent.slice(smsCursorPosition);

            setTptSmsContent(newText);
            // Move cursor to the position right after the inserted text
            setSmsCursorPosition(smsCursorPosition + chipValue.length + 4); // 4 for the "{{}}"
        } catch (error) {
            console.log(error);
        }
    };


    const handleChipClickEmail = (chipValue) => {
        try {
            const quill = quillEmailRef.current.getEditor();
            quill.focus();
            quill.insertText(emailCursorPosition, `{{${chipValue}}}`);
            quill.setSelection(emailCursorPosition + chipValue.length + 4);
        } catch (error) {
            console.log(error);
        }
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const handleConfirm = async () => {
        try {
            if (confirmDialogAction === "underReview") {

            }
        } catch (err) {
            console.log(err);
        }
        // Close the dialog
        setIsConfirmDialogOpen(false);
    };

    const handleCancel = () => {
        setIsConfirmDialogOpen(false);
    };

    return (
        <motion.div
            className="email-template"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 1 } }}
            exit={{ opacity: 0, transition: { duration: 1 } }}
        >
            {isLoading ? (
                <div className="no-content">
                    <ScaleLoader
                        color="#F44336"
                        height={50}
                        radius={8}
                        width={4}
                    />
                </div>
            ) : (
                <div className="template-main-wrapper">
                    {templateView === "list" &&
                        <motion.div className="list-wrapper"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1, transition: { duration: 1 } }}
                            exit={{ opacity: 0, transition: { duration: 1 } }}>
                            <div className="list-navbar">
                                <span className="main-title">Templates</span>
                                <CustomButton
                                    startIcon={<AddIcon />}
                                    onClick={() => {
                                        try {
                                            handleResetFields();
                                            setTptId("new")
                                            setTemplateView("single")
                                        } catch (error) {
                                            console.log(error)
                                        }
                                    }}
                                >
                                    New Template
                                </CustomButton>
                            </div>
                            <div className="list-content">
                                <MdEmail className="icon" />
                                <span className="title">Email & SMS Templates</span>
                                <p>Create templates that will be used for customer engagement. From Renewal notifications, birthdays, events and notices, let Qillo engage your clients and business partners</p>
                                <div className="search-input-wrapper">
                                    <SearchIcon className="button-icon" />
                                    <input
                                        className="search-input"
                                        type="text"
                                        placeholder="Search template..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') handleSearchTemplates();
                                        }}
                                    />
                                    {searchTerm.length > 0 && (
                                        <CloseIcon
                                            onClick={(e) => {
                                                try {
                                                    fetchTemplates();
                                                    setSearchTerm("");
                                                } catch (error) {
                                                    console.log(error);
                                                }
                                            }}
                                            className="search-input-icon"
                                        />
                                    )}
                                    {searchTerm.length === 0 && (
                                        <span className="search-input-icon" />
                                    )}
                                    <SearchButton
                                        width={"200px"}
                                         onClick={handleSearchTemplates}>
                                        Search
                                    </SearchButton>
                                </div>
                                <div className="cards-wrapper">
                                    {templates.map((row) => (
                                        <div className="single-card"
                                            key={row._id}
                                            onClick={() => {
                                                try {
                                                    setTptId(row._id);
                                                    setTptRef(row.tpt_ref);
                                                    setTptName(row.tpt_name);
                                                    setTptDesc(row.tpt_desc);
                                                    setTptModule(row.tpt_module);
                                                    setTptCategory({
                                                        value: row.tpt_category,
                                                        label: row.tpt_category_xx,
                                                    });
                                                    setTptSubject(row.tpt_subject);
                                                    setTptEmailContent(row.tpt_email_content);
                                                    setTptSmsContent(row.tpt_sms_content);
                                                    // setSearchTerm("");
                                                    setTemplateView("single");
                                                } catch (error) {
                                                    console.log(error)
                                                }
                                            }}
                                        >
                                            <div className="single-card-top">
                                                <span className="category" style={{ backgroundColor: row.tpt_color_xx }}>{row.tpt_category_xx}</span>
                                            </div>
                                            <div className="single-card-center">
                                                <span className="main-title">{row.tpt_name}</span>
                                            </div>
                                            <div className="single-card-bottom">
                                                <div className="avatar-container">
                                                    <div className="left">
                                                        <img src={row.created_by_photo_url_xx} alt="avatar" className="avatar" />
                                                    </div>
                                                    <div className="right">
                                                        <span className="username">{row.created_by_name_xx}</span>
                                                        <span className="time-ago">{row.time_ago_xx}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="list-footer">
                                    <button className="overlay-button" onClick={handleOpenOverlay}>
                                        How it works
                                    </button>
                                </div>
                            </div>
                        </motion.div>
                    }
                    {templateView === "single" &&
                        <motion.div className="single-wrapper"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1, transition: { duration: 1 } }}
                            exit={{ opacity: 0, transition: { duration: 1 } }}>
                            <div className="single-navbar">
                                <div className="single-navbar-left">
                                    <span className="main-title">{tptName || 'New Template'}</span>
                                </div>
                                <div className="single-navbar-right">
                                    <CustomLoadingButton startIcon={<SaveIcon />} onClick={saveRecord}>
                                        Save
                                    </CustomLoadingButton>

                                    <CustomLoadingButton
                                        startIcon={<MenuIcon />}
                                        onClick={() => {
                                            try {
                                                setPreviewEmailContent(replaceTags(tptEmailContent, currentUser));
                                                setPreviewSmsContent(replaceTags(tptSmsContent, currentUser));
                                                setIsPreviewOpen(true);
                                            } catch (error) {
                                                console.log(error)
                                            }
                                        }}
                                    >
                                        Preview
                                    </CustomLoadingButton>
                                    <CustomLoadingButton
                                        startIcon={<MenuIcon />}
                                        onClick={() => {
                                            try {
                                                if (!searchTerm) {
                                                    fetchTemplates();
                                                }
                                                setTemplateView("list")
                                            } catch (error) {
                                                console.log(error)
                                            }
                                        }}
                                    >
                                        View All
                                    </CustomLoadingButton>
                                </div>
                            </div>
                            <div className="single-content">
                                <div className="single-content-left">
                                    <div className="input-item-with-label">
                                        <label htmlFor="tpt_name"
                                            className="form-input-label"
                                            style={{ width: "130px" }}>
                                            NAME
                                        </label>
                                        <input
                                            name="tpt_name"
                                            type="text"
                                            placeholder="NAME"
                                            className="form-input-item"
                                            style={{ width: "70%" }}
                                            value={tptName}
                                            onChange={(e) => setTptName(e.target.value)}
                                            onFocus={() => setFieldToUpdate(null)}
                                        />
                                    </div>
                                    <div className="input-item-with-label">
                                        <label htmlFor="tpt_desc"
                                            className="form-input-label"
                                            style={{ width: "130px", alignSelf: "flex-start" }}>
                                            DESCRIPTION
                                        </label>
                                        <textarea
                                            name="tpt_desc"
                                            type="text"
                                            placeholder="DESCRIPTION"
                                            className="form-input-item"
                                            style={{ width: "70%" }}
                                            value={tptDesc}
                                            rows={3}
                                            onChange={(e) => setTptDesc(e.target.value)}
                                            onFocus={() => setFieldToUpdate(null)}
                                        />
                                    </div>
                                    <div className="input-item-with-label">
                                        <label htmlFor="tpt_category" style={{ width: "130px" }}>
                                            CATEGORY
                                        </label>
                                        <Select
                                            id="tpt_category"
                                            name="tpt_category"
                                            styles={customSelectStyles}
                                            style={{ width: "70%" }}
                                            value={tptCategory}
                                            onChange={(selectedOption) => setTptCategory(selectedOption)}
                                            options={templateCatgOptions}
                                            isSearchable={true}
                                            placeholder="CATEGORY"
                                        />

                                        <div className="input-item-with-label">
                                            <label htmlFor="tpt_ref"
                                                className="form-input-label"
                                                style={{ width: "185px" }}>
                                                UNIQUE REF
                                            </label>
                                            <input
                                                name="tpt_ref"
                                                type="text"
                                                placeholder="UNIQUE REF"
                                                className="form-input-item"
                                                style={{ width: "70%" }}
                                                value={tptRef}
                                                onChange={(e) => setTptRef(e.target.value)}
                                                onFocus={() => setFieldToUpdate(null)}
                                                disabled={true}
                                            />
                                        </div>

                                    </div>
                                    <div className="input-item-with-label">
                                        <label htmlFor="tpt_subject"
                                            className="form-input-label"
                                            style={{ width: "130px" }}>
                                            SUBJECT
                                        </label>
                                        <input
                                            name="tpt_subject"
                                            type="text"
                                            placeholder="SUBJECT"
                                            className="form-input-item"
                                            style={{ width: "70%" }}
                                            value={tptSubject}
                                            onChange={(e) => setTptSubject(e.target.value)}
                                            onFocus={() => setFieldToUpdate(null)}
                                        />
                                    </div>
                                    <div className="input-item-with-label">
                                        <label htmlFor="tpt_sms_content"
                                            className="form-input-label"
                                            style={{ width: "130px", alignSelf: "flex-start" }}>
                                            EMAIL
                                        </label>
                                        <ReactQuill
                                            value={tptEmailContent}
                                            ref={quillEmailRef}
                                            onChange={setTptEmailContent}
                                            modules={modules}
                                            style={{ width: "70%" }}
                                            className="rich-text-editor"
                                            onChangeSelection={handleSelectionChange}
                                            onFocus={() => setFieldToUpdate('email')}

                                        />
                                    </div>
                                    <div className="input-item-with-label">
                                        <label htmlFor="tpt_sms_content"
                                            className="form-input-label"
                                            style={{ width: "130px", alignSelf: "flex-start" }}>
                                            SMS
                                        </label>
                                        <textarea
                                            ref={smsRef}
                                            name="tpt_sms_content"
                                            type="text"
                                            placeholder="SMS"
                                            className="form-input-item"
                                            style={{ width: "70%" }}
                                            rows={3}
                                            value={tptSmsContent}
                                            onChange={(e) => setTptSmsContent(e.target.value)}
                                            onSelect={(e) => setSmsCursorPosition(e.target.selectionStart)}
                                            onFocus={() => setFieldToUpdate('sms')}
                                        />
                                    </div>
                                </div>
                                <div className="single-content-right">
                                    <span className="main-title">Select Tags</span>
                                    <div className="chip-wrapper">
                                        {commTags.map((row) => (
                                            <Tooltip
                                                title={row.sys_name}
                                                placement="left"
                                            >
                                                <Chip
                                                    key={row._id}
                                                    label={row.sys_code}
                                                    className="chip"
                                                    onClick={() => {
                                                        try {
                                                            console.log(`field to update ${fieldToUpdate}`)
                                                            if (fieldToUpdate === "email") {
                                                                handleChipClickEmail(row.sys_code);
                                                            } else if (fieldToUpdate === "sms") {
                                                                handleChipClickSms(row.sys_code);
                                                            }
                                                        } catch (error) {
                                                            console.log(error)
                                                        }
                                                    }}
                                                />
                                            </Tooltip>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    }
                    {isOverlayOpen && (
                        <motion.div
                            className="overlay-dialog"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1, transition: { duration: 1 } }}
                            exit={{ opacity: 0, transition: { duration: 1 } }}
                        >
                            <div className="overlay-wrapper">
                                <div className="overlay-navbar">
                                    <span>HELP</span>
                                    <span onClick={() => setIsOverlayOpen(false)}>
                                        <IoClose
                                            style={{
                                                width: "30px",
                                                height: "30px",
                                                cursor: "pointer",
                                                borderRadius: "50%",
                                            }}
                                        />
                                    </span>
                                </div>
                                <div className="overlay-content">
                                    <VideoPlayer videoUrl="https://www.youtube.com/embed/PPCfDe8TfJQ" />
                                </div>
                                <div className="overlay-action">
                                </div>
                            </div>
                        </motion.div>
                    )}
                    {isPreviewOpen && (
                        <motion.div
                            className="overlay-dialog"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1, transition: { duration: 1 } }}
                            exit={{ opacity: 0, transition: { duration: 1 } }}
                        >
                            <div className="overlay-wrapper" style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "50%"
                            }}>
                                <div className="overlay-navbar">
                                    <span>PREVIEW</span>
                                    <span onClick={() => setIsPreviewOpen(false)}>
                                        <IoClose
                                            style={{
                                                width: "30px",
                                                height: "30px",
                                                cursor: "pointer",
                                                borderRadius: "50%",
                                            }}
                                        />
                                    </span>
                                </div>
                                <div className="overlay-content">
                                    <span className="main-title">EMAIL Preview</span>
                                    <div className="preview-email">
                                        <div dangerouslySetInnerHTML={{ __html: previewEmailContent }} />
                                    </div>
                                    <span className="main-title">SMS Preview</span>
                                    <div className="preview-sms">
                                        <div dangerouslySetInnerHTML={{ __html: previewSmsContent }} />
                                    </div>
                                    <span className="note-text">Please NOTE: All placeholders will be replaced with actual values when sending the email and/or SMS. Shown values are for display only</span>
                                </div>
                                <div className="overlay-action">
                                </div>
                            </div>
                        </motion.div>
                    )}
                </div>
            )}
            {/* Dialog to notify user about changes being saved */}
            <Dialog open={isNotifyDialogOpen} onClose={handleCloseNotifyDialog}>
                <DialogTitle>{notifyDialogTitle}</DialogTitle>
                <DialogContent>
                    <div dangerouslySetInnerHTML={{ __html: sanitizeText(notifyDialogText) }} />
                </DialogContent>
                <DialogActions>
                    <CustomButton onClick={handleCloseNotifyDialog}>Close</CustomButton>
                </DialogActions>
            </Dialog>
            {/* Confirm Dialog */}
            <ConfirmDialog
                isOpen={isConfirmDialogOpen}
                onClose={handleCancel}
                onConfirm={handleConfirm}
                dialogTitle="Confirmation"
                dialogContent={<p>{objectToAction.action_text}</p>}
            />
        </motion.div>
    )
}

export default EmailTemplate