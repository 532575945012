import { useSelector } from "react-redux";
import "./GlCurrency.scss";
import ConfirmDialog from "../../../components/confirmDialog/confirmDialog.jsx";
import {
  CustomButton,
  CustomLoadingButton,
} from "../../../components/ui/CustomButton.jsx";
import { showToast } from "../../../toastService.js";
import newRequest from "../../../utils/newRequest.js";
import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  LinearProgress,
  Tooltip,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  Select,
  MenuItem
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { motion } from "framer-motion";
import moment from "moment";
import { useEffect, useState } from "react";
import { FaSave } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { GridLoader, ScaleLoader } from "react-spinners";

const GlCurrency = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogAction, setConfirmDialogAction] = useState("None");
  const [isProcessing, setIsProcessing] = useState(false);
  const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
  const [notifyDialogTitle, setNotifyDialogTitle] = useState("Confirm");
  const [notifyDialogText, setNotifyDialogText] = useState("Text");
  const [isLoading, setIsLoading] = useState(true);
  const [savingTitle, setSavingTitle] = useState("Save");
  const [currencyPairs, setCurrencyPairs] = useState([]);
  const [selectedCurrencyPair, setSelectedCurrencyPair] = useState({});
  const [currencyPairRates, setCurrencyPairRates] = useState([]);
  const [selectedCurrencyPairRate, setSelectedCurrencyPairRate] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const [currencyAction, setCurrencyAction] = useState("");



  const [isNewRateDialogOpened, setIsNewRateDialogOpened] = useState(false);
  const [newRateFmDate, setNewRateFmDate] = useState(null);
  const [newRateToDate, setNewRateToDate] = useState(null);
  const [newRate, setNewRate] = useState(null);
  const handleFmDateChange = async (e) => {
    try {
      if (e.target.value) {
        const formattedGlDate = moment(e.target.value).format("YYYY-MM-DD");
        setNewRateFmDate(formattedGlDate);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleToDateChange = async (e) => {
    try {
      if (e.target.value) {
        const formattedGlDate = moment(e.target.value).format("YYYY-MM-DD");
        setNewRateToDate(formattedGlDate);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddRate = async () => {
    try {
      if (!newRateFmDate) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter from date!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      if (!newRateToDate) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter to date!`);
        setIsNotifyDialogOpen(true);
        return;
      }
      // TODO
      // Check Overlapping Dates

      if (!newRate) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter rate!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      const formattedFmDate = new Date(
        moment(newRateFmDate).format("YYYY-MM-DD")
      );
      formattedFmDate.setHours(0, 0, 0, 0);
      const formattedToDate = new Date(
        moment(newRateToDate).format("YYYY-MM-DD")
      );
      formattedToDate.setHours(0, 0, 0, 0);
      // Compare the dates
      if (formattedFmDate <= formattedToDate) {
        console.log("from Date is equal to or less than To Date");
      } else {
        console.log("from is greater than to date");
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(
          `From date ${moment(newRateFmDate).format(
            "DD-MMM-YYYY"
          )} cannot be greater than to date ${moment(newRateToDate).format(
            "DD-MMM-YYYY"
          )}!`
        );
        setIsNotifyDialogOpen(true);
        return;
      }

      setIsProcessing(true);
      const res = await newRequest.post(
        "/glCurrencyRate/add/",
        {
          rate_org_code: currentUser.usr_org_code,
          rate_cur_code_fm: selectedCurrencyPair.baseCurrency,
          rate_cur_code_to: selectedCurrencyPair.quoteCurrency,
          rate_date_fm: newRateFmDate,
          rate_date_to: newRateToDate,
          rate: newRate,
          userCode: currentUser.usr_id,
        },
        config
      );
      fetchCurrencyPairRates(
        selectedCurrencyPair.baseCurrency,
        selectedCurrencyPair.quoteCurrency
      );
      setNewRateFmDate(null);
      setNewRateToDate(null);
      setNewRate(null);
      setIsNewRateDialogOpened(false);
      setIsProcessing(false);
      showToast("Rate Added!", "success");
    } catch (error) {
      console.log(error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleAddCurrency = async () => {
    try {
      if (!newCurrencyName) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter name!`);
        setIsNotifyDialogOpen(true);
        return;
      }
      if (!newCurrencyCode) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter code!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      setIsProcessing(true);
      const res = await newRequest.post(
        "/glCurrency/add/",
        {
          cur_org_code: currentUser.usr_org_code,
          cur_code: newCurrencyCode,
          cur_name: newCurrencyName,
          cur_symbol: newCurrencySymbol || newCurrencyCode,
          cur_enabled: "Y",
          userCode: currentUser.usr_id,
        },
        config
      );
      fetchCurrencyPairs();
      setNewCurrencyCode(null);
      setNewCurrencyName(null);
      setNewCurrencySymbol(null);

      setIsNewRateDialogOpened(false);

      showToast("Currency Added!", "success");
    } catch (error) {
      console.log(error);
    } finally {
      setIsProcessing(false);
    }
  };

  const [newCurrencyCode, setNewCurrencyCode] = useState(null);
  const [newCurrencyName, setNewCurrencyName] = useState(null);
  const [newCurrencySymbol, setNewCurrencySymbol] = useState(null);

  const [objectToAction, setObjectToAction] = useState({
    object_id: "",
    action_text: "",
    action_invoked: "",
  });

  const handleClearSearch = () => {
    try {
      setSearchTerm("");
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCurrencyPairs = async () => {
    try {
      setIsLoading(true);
      const res = await newRequest.post(
        "/glCurrency/pairs",
        {
          cur_org_code: currentUser.usr_org_code,
          base_currency: currentUser.currency_code_xx,
        },
        config
      );
      setCurrencyPairs(res.data);
      setSelectedCurrencyPair(res.data[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCurrencyPairRates = async (fromCurrency, toCurrency) => {
    try {
      // console.log("Firing fetchCalendarPeriods....");
      const res = await newRequest.post(
        "/glCurrencyRate/findAll",
        {
          cur_org_code: currentUser.usr_org_code,
          rate_cur_code_fm: fromCurrency,
          rate_cur_code_to: toCurrency,
        },
        config
      );
 
      setCurrencyPairRates(res.data);
      setSelectedCurrencyPairRate(res.data[0]);
      setIsProcessing(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    fetchCurrencyPairs();
  }, []);

  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [currentPage, setCurrentPage] = useState(0);
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };
  const slicedCurrencyPairRates = currencyPairRates.slice(
    currentPage * rowsPerPage,
    currentPage * rowsPerPage + rowsPerPage
  );

  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 12));
    setCurrentPage(0);
  };


  const filteredCurrencyPairs = currencyPairs.filter(
    (item) =>
      item.baseCurrency?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.quoteCurrency?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.pair?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    try {
      fetchCurrencyPairRates(
        selectedCurrencyPair.baseCurrency,
        selectedCurrencyPair.quoteCurrency
      );
    } catch (error) {
      console.log(error);
    }
  }, [selectedCurrencyPair]);


  const handleRateChange = (e, rowId) => {
    try {
      const { name, value } = e.target;

      // console.log(`name ${name} and value ${value}`);
      const updatedData = [...currencyPairRates];
      const rowIndex = updatedData.findIndex(item => item._id === rowId);

      if (rowIndex === -1) {
        console.log('Row not found');
      }

      updatedData[rowIndex][name] = value;
      updatedData[rowIndex]["changed_xx"] = "Y";

      setCurrencyPairRates(updatedData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirm = async () => {
    try {
      if (confirmDialogAction === "deleteRate") {
        const res = await newRequest.delete(
          `/glCurrencyRate/del/${objectToAction.object_id}`,
          {},
          config
        );
        fetchCurrencyPairRates(
          selectedCurrencyPair.baseCurrency,
          selectedCurrencyPair.quoteCurrency
        );
      }
    } catch (err) {
      console.log(err);
    }
    // Close the dialog
    setIsConfirmDialogOpen(false);
  };

  const handleCancel = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleCloseNotifyDialog = () => {
    setIsNotifyDialogOpen(false);
  };

  const saveRecord = async (e) => {
    // e.preventDefault();
    try {

      // Simulate delay for 5 seconds
      // await new Promise((resolve) => setTimeout(resolve, 5000));


      const changedRates = currencyPairRates.filter(
        (obj) => obj.changed_xx === "Y"
      );

      // console.log(`changedRates  ${JSON.stringify(changedRates, null, 2)}`);

      for (let i = 0; i < changedRates.length; i++) {
        const res = await newRequest.put(
          `/glCurrencyRate/upd/${changedRates[i]._id}`,
          {
            rate: changedRates[i].rate,
            rate_date_fm: changedRates[i].rate_date_fm,
            rate_date_to: changedRates[i].rate_date_to,
            updatedBy: currentUser.usr_id,
          }
        );
      }
      showToast("Saved!", "success");
    } catch (err) {
      console.log(err);
    } finally {


    }
  };

  return (
    <div className="gl-currency">
      {isLoading ? (
        <div className="no-content"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <ScaleLoader
            color="#F44336"
            height={50}
            radius={8}
            width={4}
          />
        </div>
      ) : (
        <div className="gl-currency-main-wrapper">
          <div className="gl-currency-center-wrapper">
            <div className="gl-currency-center-details">
              <div className="gl-currency-center-navbar">
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h1>{`CONVERSION FROM ${selectedCurrencyPair.baseCurrency} TO ${selectedCurrencyPair.quoteCurrency}`}</h1>
                  <span
                    style={{ display: "flex", flexDirection: "row", gap: "5px" }}
                  >
                    <span
                      className="selected-type"
                      style={{ width: "max-content" }}
                    >
                      {selectedCurrencyPair.pair}
                    </span>

                    <CustomLoadingButton
                      startIcon={<FaSave />}
                      onClick={saveRecord}
                    >
                      {savingTitle}
                    </CustomLoadingButton>
                    <CustomButton
                      startIcon={<AddIcon />}
                      onClick={() => {
                        try {
                          setCurrencyAction("addRate");
                          setIsNewRateDialogOpened(true);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                    >
                      Add Rate
                    </CustomButton>
                  </span>
                </div>
              </div>
              <hr />
              <div className="table-content">
                <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none' }}
                  className="table-container">
                  <Table sx={{ width: "max-content", border: 'none' }}>
                    <TableHead className="tablehead">
                      <TableRow className="tablerow">
                        <TableCell className="table-cell" style={{ maxWidth: "50px", display: "flex", textAlign: "center" }}>
                          #
                        </TableCell>
                        <TableCell className="table-cell" style={{ maxWidth: "100px" }}>
                          <span>FROM</span>
                        </TableCell>
                        <TableCell className="table-cell" style={{ maxWidth: "100px" }}>
                          <span>TO</span>
                        </TableCell>
                        <TableCell className="table-cell" style={{ maxWidth: "170px" }}>
                          <span>PERIOD FROM</span>
                        </TableCell>
                        <TableCell className="table-cell" style={{ maxWidth: "170px" }}>
                          <span>PERIOD TO</span>
                        </TableCell>
                        <TableCell className="table-cell" style={{ width: "auto", textAlign: "right" }}>
                          <span>RATE</span>
                        </TableCell>
                        {["admin", "superadmin"].includes(currentUser.user_type_xx) &&
                          <TableCell className="table-cell" style={{ maxWidth: "100px", alignItems: "center" }}>
                            <span>ACTION</span>
                          </TableCell>}
                      </TableRow>
                    </TableHead>
                    <TableBody className="tablebody">
                      {slicedCurrencyPairRates?.map((row, index) => (
                        <TableRow
                          key={row._id}
                          className={`${selectedCurrencyPairRate?._id === row._id ? "tablerow current" : "tablerow"}`}
                          onClick={() => {
                            try {
                              setSelectedCurrencyPairRate(row);
                            } catch (error) {
                              console.log(error);
                            }
                          }}
                        >
                          <TableCell className="table-cell" style={{ maxWidth: "50px", display: "flex", textAlign: "center" }}>
                            {currentPage * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell className="table-cell" style={{ maxWidth: "100px" }}>
                            {row.rate_cur_code_fm}
                          </TableCell>
                          <TableCell className="table-cell" style={{ maxWidth: "100px" }}>
                            {row.rate_cur_code_to}
                          </TableCell>
                          <TableCell className="table-cell" style={{ maxWidth: "170px" }}>
                            <input
                              id="rate_date_fm"
                              name="rate_date_fm"
                              type="date"
                              value={moment(row.rate_date_fm).format("YYYY-MM-DD")}
                              onChange={(e) => handleRateChange(e, row._id)}
                              style={{ width: "100%" }}
                              className="form-input-item"

                            />
                          </TableCell>
                          <TableCell className="table-cell" style={{ maxWidth: "170px" }}>
                            <input
                              id="rate_date_to"
                              name="rate_date_to"
                              type="date"
                              value={moment(row.rate_date_to).format("YYYY-MM-DD")}
                              onChange={(e) => handleRateChange(e, row._id)}
                              style={{ width: "100%" }}
                              className="form-input-item"

                            />
                          </TableCell>
                          <TableCell className="table-cell" style={{ width: "auto" }}>
                            <input
                              id="rate"
                              name="rate"
                              type="number"
                              value={row.rate}
                              onChange={(e) => handleRateChange(e, row._id)}
                              style={{ width: "100%", textAlign: "right" }}
                              className="form-input-item"
                            />
                          </TableCell>
                          {["admin", "superadmin"].includes(currentUser.user_type_xx) &&
                            <TableCell className="table-cell" style={{ maxWidth: "100px", alignItems: "center" }}>
                              <Tooltip
                                title={`Remove rate ${row.rate}?`}
                                placement="right"
                              >
                                <DeleteIcon
                                  size={50}
                                  style={{
                                    borderRadius: "5px",
                                    padding: "3px",
                                    alignSelf: "center",
                                    cursor: "pointer",
                                    color: "rgba(244, 67, 54)",
                                    backgroundColor: "rgba(244, 67, 54, 0.5)",
                                  }}
                                  onClick={() => {
                                    try {
                                      let action_text = `Remove rate ${row.rate}?`;
                                      setObjectToAction({
                                        object_id: row._id,
                                        action_text: action_text,
                                        new_doc_status: "deleteRate",
                                      });

                                      setConfirmDialogAction("deleteRate");
                                      setIsConfirmDialogOpen(true);
                                    } catch (error) {
                                      console.log(error);
                                    }
                                  }}
                                />
                              </Tooltip>
                            </TableCell>}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[12]}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleRowsPerPage}
                    component="div"
                    count={currencyPairRates?.length}
                    rows={currencyPairRates}
                    page={currentPage}
                    onPageChange={handlePageChange}
                  />
                </TableContainer>
              </div>
            </div>
          </div>
          <div className="gl-currency-right-wrapper">
            <div className="gl-currency-right-content">
              <div className="gl-currency-right-navbar" >
                <div className="search-input-wrapper">
                  <SearchIcon className="search-input-icon" />
                  <input
                    className="search-input"
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  {searchTerm.length > 0 && (
                    <CloseIcon
                      onClick={handleClearSearch}
                      className="search-input-icon"
                    />
                  )}
                </div>
              </div>
              <div className="options-list">
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "10px",
                  }}
                >
                  {["admin", "superadmin"].includes(currentUser.user_type_xx) &&
                    <CustomButton
                      startIcon={<AddIcon />}
                      onClick={() => {
                        try {
                          setCurrencyAction("addCurrency");
                          setIsNewRateDialogOpened(true);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                      width={"95%"}
                    >
                      Add Currency
                    </CustomButton>}
                </span>
                {filteredCurrencyPairs?.map((row) => (
                  <>
                    <span
                      key={row._id}
                      className={`${selectedCurrencyPair._id === row._id
                        ? "single-option active"
                        : "single-option"
                        }`}
                      onClick={async () => {
                        try {
                          setSelectedCurrencyPair(row);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                    >
                      <div className="title-row">
                        <span className="cy-title">{row.pair}</span>
                      </div>
                    </span>
                    <hr style={{ margin: "0px 10px" }} />
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Dialog to notify user about changes being saved */}
      <Dialog open={isNotifyDialogOpen} onClose={handleCloseNotifyDialog}>
        <DialogTitle>{notifyDialogTitle}</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: notifyDialogText }} />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseNotifyDialog}>Close</CustomButton>
        </DialogActions>
      </Dialog>
      {/* Confirm Dialog */}
      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        dialogTitle="Confirmation"
        dialogContent={<p>{objectToAction.action_text}</p>}
      />

      {isNewRateDialogOpened && (
        <motion.div
          className="new-rate-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="new-rate-wrapper">
            <div className="new-rate-navbar">
              <span>{`${currencyAction === "addRate" ? "ADD RATE" : "ADD CURRENCY"
                }`}</span>
              <span
                onClick={() => {
                  try {
                    setNewRateFmDate(null);
                    setNewRateToDate(null);
                    setNewRate(null);

                    setNewCurrencyCode(null);
                    setNewCurrencyName(null);
                    setNewCurrencySymbol(null);

                    setIsNewRateDialogOpened(false);
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                <IoClose
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    borderRadius: "50%",
                  }}
                />
              </span>
            </div>
            <div className="new-rate-content">
              {/* fields for rate */}
              {currencyAction === "addRate" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <div className="input-item-with-label">
                    <label
                      htmlFor="newRateFmDate"
                      className="form-dropdown-label"
                      style={{ width: "130px" }}
                    >
                      FROM
                    </label>
                    <input
                      id="newRateFmDate"
                      name="newRateFmDate"
                      type="date"
                      placeholder="FROM"
                      value={newRateFmDate}
                      className="form-input-item"
                      style={{ width: "170px" }}
                      onChange={handleFmDateChange}
                    />
                  </div>
                  <div className="input-item-with-label">
                    <label
                      htmlFor="newRateToDate"
                      className="form-dropdown-label"
                      style={{ width: "130px" }}
                    >
                      TO
                    </label>
                    <input
                      id="newRateToDate"
                      name="newRateToDate"
                      type="date"
                      placeholder="TO"
                      value={newRateToDate}
                      className="form-input-item"
                      style={{ width: "170px" }}
                      onChange={handleToDateChange}
                    />
                  </div>

                  <div className="input-item-with-label">
                    <label
                      htmlFor="newRate"
                      className="form-dropdown-label"
                      style={{ width: "130px" }}
                    >
                      RATE
                    </label>
                    <input
                      id="newRate"
                      name="newRate"
                      type="number"
                      placeholder="0.00"
                      value={newRate}
                      className="form-input-item"
                      style={{ width: "170px", textAlign: "right" }}
                      onChange={(e) => setNewRate(e.target.value)}
                    />
                  </div>
                </div>
              )}
              {/* field for currency */}
              {currencyAction === "addCurrency" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <div className="input-item-with-label">
                    <label
                      htmlFor="newCurrencyName"
                      className="form-dropdown-label"
                      style={{ width: "130px" }}
                    >
                      NAME
                    </label>
                    <input
                      id="newCurrencyName"
                      name="newCurrencyName"
                      type="text"
                      placeholder="NAME"
                      value={newRate}
                      className="form-input-item"
                      style={{ width: "250px" }}
                      onChange={(e) => setNewCurrencyName(e.target.value)}
                    />
                  </div>
                  <div className="input-item-with-label">
                    <label
                      htmlFor="newCurrencyCode"
                      className="form-dropdown-label"
                      style={{ width: "130px" }}
                    >
                      CODE
                    </label>
                    <input
                      id="newCurrencyCode"
                      name="newCurrencyCode"
                      type="text"
                      placeholder="CODE"
                      value={newRate}
                      className="form-input-item"
                      style={{ width: "150px" }}
                      onChange={(e) => {
                        try {
                          setNewCurrencyCode(e.target.value);
                          setNewCurrencySymbol(e.target.value);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                    />
                  </div>
                  <div className="input-item-with-label">
                    <label
                      htmlFor="newCurrencySymbol"
                      className="form-dropdown-label"
                      style={{ width: "130px" }}
                    >
                      SYMBOL
                    </label>
                    <input
                      id="newCurrencySymbol"
                      name="newCurrencySymbol"
                      type="text"
                      placeholder="SYMBOL"
                      value={newRate}
                      className="form-input-item"
                      style={{ width: "150px" }}
                      onChange={(e) => setNewCurrencySymbol(e.target.value)}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="new-rate-action">
              {currencyAction === "addRate" && (
                <CustomButton onClick={handleAddRate}>ADD</CustomButton>
              )}
              {currencyAction === "addCurrency" && (
                <CustomButton onClick={handleAddCurrency}>ADD</CustomButton>
              )}
            </div>
          </div>
        </motion.div>
      )}
      {isProcessing && (
        <motion.div
          className="processing-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="processing-wrapper">
            <GridLoader
              color="#F44336"
              style={{
                height: "50px",
                width: "50px",
              }}
            />
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default GlCurrency;
