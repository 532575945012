import React, { useEffect, useState } from 'react';
import './SmsComponent.scss';
import newRequest from '../../utils/newRequest.js';
import { showToast } from "../../toastService.js";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    CircularProgress,
} from "@mui/material";
import { useSelector } from 'react-redux';
import Select from "react-select";
import { useNavigate } from 'react-router-dom';
import { customSelectStyles } from '../ui/uiStyleElements.jsx';
import { replacePlaceholders } from '../../utils/genericFunctions.js';
import { CustomLoadingButton } from '../ui/CustomButton.jsx';

const SmsComponent = ({ setSmsDialogOpen, smsData, fetchSms }) => {
    const { currentUser } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const [to, setTo] = useState(smsData?.to);
    const [subject, setSubject] = useState(smsData?.subject);
    const [content, setContent] = useState('');
    const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
    const [notifyDialogTitle, setNotifyDialogTitle] = useState("Confirm");
    const [notifyDialogText, setNotifyDialogText] = useState("Text");
    const [charCount, setCharCount] = useState(0);
    // close notify dialog
    const handleCloseNotifyDialog = () => {
        setIsNotifyDialogOpen(false);
    };

    const handleContentChange = (e) => {
        const value = e.target.value;
        setContent(value);
        setCharCount(value.length); // Update character count
    };
    const handleSubmit = async () => {
        try {
            // TODO
            // Save Sms to database
            const correspondence = await newRequest.post(
                "/correspondence/addSms",
                {
                    sms_doc_no: smsData?.doc_no,
                    sms_doc_type: smsData?.doc_type,
                    sms_doc_id: smsData?.doc_id,
                    sms_entity_codes: smsData?.entity_codes,
                    sms_to: to,
                    sms_subject: subject,
                    sms_content: content,
                    sms_status: "sent",
                    sms_search_string: smsData?.search_string,
                }
            );
            // console.log(`corresponce  ${JSON.stringify(corresponce.data, null, 2)}`);
            // console.log(`callFirebaseUpload is ${callFirebaseUpload}`)
            if (correspondence.data) {
                setNotifyDialogTitle("Sending sms!");
                setNotifyDialogText(`Please wait....`);
                setIsNotifyDialogOpen(true);
                // call send email
                // const res = await newRequest.post(
                //     "/sms/send",
                //     {
                //         to,
                //         subject,
                //         message: content,
                //         doc_id: emailId,
                //         cc,
                //         bcc,
                //         attached_urls: uploadedFiles,
                //         binary_docs: emailData?.binary_docs
                //     }
                // );

                setIsNotifyDialogOpen(false);
                setTo('');
                setSubject('');
                setContent('');
                setCharCount(0);
                // Close the email dialog in the parent component
                setSmsDialogOpen(false);
                fetchSms();
                showToast("sms sent", "success");
            } else {
                showToast("error sending sms", "error");
            }

        } catch (error) {
            console.log(error)
        } finally {
            setIsNotifyDialogOpen(false);
        }
    };

    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [templates, setTemplates] = useState([]);
    const fetchTemplates = async () => {
        try {
            const res = await newRequest.post(
                "/emailTemplate/findAll",
                { tpt_module: "", result_limit: 100, tpt_category: smsData?.tpt_category }
            );
            // console.log(`res.data  ${JSON.stringify(res.data, null, 2)}`);
            const options = res.data.map((template) => ({
                value: template.tpt_ref,
                label: template.tpt_name,
                sms_content: template.tpt_sms_content
            }));

            setTemplates(options);
            // console.log(`fetchTemplates  ${JSON.stringify(options, null, 2)}`);
            // if a template reference is passed , assign it
            if (smsData?.template_ref) {
                const passedTemplate = res.data.find(l => l.tpt_ref === smsData?.template_ref);
                if (passedTemplate) {
                    // console.log(`selectedTemplate  ${JSON.stringify(passedTemplate, null, 2)}`);
                    setSelectedTemplate({
                        value: passedTemplate.tpt_ref,
                        label: passedTemplate.tpt_name,
                        sms_content: passedTemplate.tpt_sms_content
                    })
                    const placeholders = {
                        ...smsData,
                        ourCompanyName: currentUser?.usr_org_name,
                        ourContactEmail: currentUser?.org_email,
                        ourContactPhone: currentUser?.org_phone,
                        ourPhysicalAddress: currentUser?.org_address1,
                        ourPostalAddress: currentUser?.org_address2,
                        ourWebsite: currentUser?.org_website,
                        policyCurrency: currentUser?.currency_name_xx,
                    }
                    const filledTemplate = replacePlaceholders(passedTemplate.tpt_sms_content, placeholders);
                    console.log(`characters count is ${filledTemplate?.length}`)
                    setCharCount(filledTemplate?.length);
                    setContent(filledTemplate);
                }
            }
        } catch (error) {
            console.log(error)
        } finally {

        }
    };

    useEffect(() => {
        fetchTemplates();
    }, []);

    return (
        <div className="sms">
            <div className="show-wrapper">
                <Select
                    id="template"
                    name="template"
                    styles={{
                        ...customSelectStyles,
                        menuPortal: base => ({
                            ...base,
                            zIndex: 500,
                        })
                    }}
                    style={{ width: "100%" }}
                    value={selectedTemplate}
                    onChange={(selectedOption) => {
                        try {
                            const placeholders = {
                                ...smsData,
                                ourCompanyName: currentUser?.usr_org_name,
                                ourContactEmail: currentUser?.org_email,
                                ourContactPhone: currentUser?.org_phone,
                                ourPhysicalAddress: currentUser?.org_address1,
                                ourPostalAddress: currentUser?.org_address2,
                                ourWebsite: currentUser?.org_website,
                                policyCurrency: currentUser?.currency_name_xx,
                            }
                            const filledTemplate = replacePlaceholders(selectedOption?.sms_content, placeholders);
                            console.log(`characters count is ${filledTemplate?.length}`)
                            setCharCount(filledTemplate?.length);
                            setContent(filledTemplate);
                            setSelectedTemplate(selectedOption)
                        } catch (error) {
                            console.log(error)
                        }
                    }}
                    options={templates}
                    isSearchable={true}
                    placeholder="SELECT TEMPLATE"
                    noOptionsMessage={() => "NO TEMPLATE SELECTED"}
                />
            </div>
            <div className="show-wrapper">
                <input
                    id="to"
                    name="to"
                    type="text"
                    placeholder="TO"
                    value={to}
                    className="form-input-item"
                    style={{ width: "100%" }}
                    onChange={(e) => setTo(e.target.value)}
                />
            </div>
            <textarea
                id="sms_content"
                name="sms_content"
                type="text"
                placeholder="CONTENT"
                value={content}
                rows={5}
                className="form-input-item"
                style={{ width: "100%" }}
                onChange={handleContentChange}
            />
            <div className="sms-footer">
                <span>{charCount} characters</span>
                <CustomLoadingButton onClick={handleSubmit}
                    disabled={!to}
                >
                    Send SMS
                </CustomLoadingButton>
            </div>
            {/* Dialog to user about changes being saved */}
            < Dialog open={isNotifyDialogOpen} onClose={handleCloseNotifyDialog} >
                <DialogTitle>{notifyDialogTitle}</DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                        <span dangerouslySetInnerHTML={{ __html: notifyDialogText }} />
                        {notifyDialogText === "Please wait...." && <CircularProgress size={24} color="inherit" />}
                    </div>
                </DialogContent>
            </Dialog >
        </div>
    );
};



export default SmsComponent;
