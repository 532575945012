import { useSelector } from "react-redux";
import "./AdControl.scss";
import ConfirmDialog from "../../../components/confirmDialog/confirmDialog.jsx";
import { ToggleSwitch } from "../../../components/toogle/ToggleSwitch.jsx";
import {
  CustomButton,
  CustomLoadingButton,
} from "../../../components/ui/CustomButton.jsx";
import { showToast } from "../../../toastService.js";
import newRequest from "../../../utils/newRequest.js";
import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  Select,
  MenuItem,
  Popover,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import SyncIcon from '@mui/icons-material/Sync';
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { FaSave } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { GridLoader, ScaleLoader } from "react-spinners";
import { formatStringToNumberV2 } from "../../../utils/genericFunctions.js";

const AdControl = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogAction, setConfirmDialogAction] = useState("None");
  const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
  const [notifyDialogTitle, setNotifyDialogTitle] = useState("Confirm");
  const [notifyDialogText, setNotifyDialogText] = useState("Text");
  const [isLoading, setIsLoading] = useState(true);
  const [savingTitle, setSavingTitle] = useState("Save");
  const [profiles, setProfiles] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState({});
  const [profileLimits, setProfileLimits] = useState([]);
  const [selectedProfileLimit, setSelectedProfileLimit] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermLimits, setSearchTermLimits] = useState("");
  const [selectedProfileIndex, setSelectedProfileIndex] = useState(0);
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const [currencyAction, setCurrencyAction] = useState("");
  const [isCodeDialogOpened, setIsCodeDialogOpened] = useState(false);
  const [pfCode, setPfCode] = useState(null);
  const [pfName, setPfName] = useState(null);
  const [pfEnabled, setPfEnabled] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [currentPage, setCurrentPage] = useState(0);
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };
  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 8));
    setCurrentPage(0);
  };
  const [objectToAction, setObjectToAction] = useState({
    object_id: "",
    action_text: "",
    action_invoked: "",
  });

  const handleClearSearch = () => {
    try {
      setSearchTerm("");
    } catch (error) {
      console.log(error);
    }
  };

  const handleClearSearchLimits = () => {
    try {
      setSearchTermLimits("");
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProfiles = async () => {
    try {
      setIsLoading(true);
      const res = await newRequest.post("/adProfile/findAll");
      setProfiles(res.data);
      setSelectedProfile(res.data[0]);
      setSelectedProfileIndex(0);
      setIsLoading(false);
      fetchProfileLimits(res.data[0]._id);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {

    }
  };

  const fetchProfileLimits = async (profileDocId) => {
    try {
      setIsProcessing(true);
      const res = await newRequest.post(
        "/adProfile/getLimits",
        {
          profile_docid: profileDocId,
        }
      );
      // console.log(`fetchProfileLimits  ${JSON.stringify(res.data, null, 2)}`);
      const formattedData = res.data?.map(item => ({
        ...item,
        limit_value: formatNumber(item.limit_value)
      }));
      setProfileLimits(formattedData);
      setSelectedProfileLimit(res.data[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  const slicedProfileLimits = profileLimits.slice(
    currentPage * rowsPerPage,
    currentPage * rowsPerPage + rowsPerPage
  );

  const formatNumber = (value) => {
    // console.log(`formatNumber passed value is ${value} and is is a number ${isNaN(value)}`)
    if (isNaN(value)) return value;
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  };


  useEffect(() => {
    try {
      setPfCode(selectedProfile.pf_code);
      setPfName(selectedProfile.pf_name);
      setPfEnabled(selectedProfile.pf_enabled);
      fetchProfileLimits(selectedProfile._id);
    } catch (error) {
      console.log(error);
    }
  }, [selectedProfile]);

  useEffect(() => {
    fetchProfiles();
  }, []);

  const filteredProfiles = profiles.filter(
    (item) =>
      item.pf_code?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.pf_name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredLimits = profileLimits.filter(
    (item) =>
      item.limit_code?.toLowerCase().includes(searchTermLimits.toLowerCase()) ||
      item.limit_name_xx?.toLowerCase().includes(searchTermLimits.toLowerCase()) ||
      item.module_name_xx?.toLowerCase().includes(searchTermLimits.toLowerCase())
  );

  const slicedDataLimits = filteredLimits.slice(
    currentPage * rowsPerPage,
    currentPage * rowsPerPage + rowsPerPage
  );

  const handleLimitValueChange = (e, index) => {
    try {
      const { name, value } = e.target;
      const updatedData = [...profileLimits];
      const currentRow = updatedData[index];
      currentRow[name] = value;
      currentRow.changed_xx = "Y";
      setProfileLimits(updatedData);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePfNameChange = (e, index) => {
    try {
      const updatedData = [...profiles];
      const currentRow = updatedData[selectedProfileIndex];
      currentRow["pf_name"] = e.target.value;
      setProfiles(updatedData);
      setPfName(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const syncProfiles = async () => {
    try {
      setIsConfirmDialogOpen(false);
      setIsProcessing(true);
      setIsLoading(false);
      const res = await newRequest.post(
        "/adProfile/sync",
        {
          org_code: currentUser.usr_org_code,
          userCode: currentUser.usr_id,
        },
        config
      );
      fetchProfileLimits(selectedProfile._id);
      showToast("Synchronizion completed!", "success");
    } catch (error) {
      console.log(error)
    } finally {
      setIsProcessing(false);
    }
  }

  const handleConfirm = async () => {
    try {
      if (confirmDialogAction === "deleteLimit") {
        const res = await newRequest.post(
          "/adProfile/delLimit",
          {
            profile_docid: selectedProfile._id,
            profile_limit_docid: objectToAction.object_id,
          },
          config
        );
        fetchProfileLimits(selectedProfile._id);
      } else if (confirmDialogAction === "syncProfile") {
        try {
          syncProfiles();
        } catch (error) {
          console.log(error)
        } finally {
          setIsProcessing(false)
        }
      } else if (confirmDialogAction === "deleteProfile") {
        const delProfile = await newRequest.delete(
          `/adProfile/del/${objectToAction.object_id}`,
          {},
          config
        );
        console.log(`${delProfile.data} and status ${delProfile.status}`)
        if (delProfile.data === "deleted") {
          // Remove the deleted profile from the state
          //  setProfiles(profiles.filter(profile => objectToAction.object_id !== id));
          const updatedProfiles = profiles.filter(profile => profile._id !== id);
          setProfiles(updatedProfiles);
          if (updatedProfiles.length > 0) {
            setSelectedProfile(updatedProfiles[0]);
          } else {
            setSelectedProfile(null);
          }

          showToast("Deleted!", "success");
        } else {
          setNotifyDialogTitle("Note!");
          setNotifyDialogText(delProfile.data);
          setIsNotifyDialogOpen(true);
        }
      }
    } catch (err) {
      console.log(err);
    }
    // Close the dialog
    setIsConfirmDialogOpen(false);
  };

  const handleCancel = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleCloseNotifyDialog = () => {
    setIsNotifyDialogOpen(false);
  };

  const saveRecord = async (e) => {
    // e.preventDefault();
    try {
      setSavingTitle("saving...");
      setIsProcessing(true);

      // update profile details
      const res = await newRequest.post(
        "/adProfile/upd",
        {
          profile_docid: selectedProfile._id,
          pf_code: pfCode,
          pf_name: pfName,
          pf_enabled: pfEnabled,
          userCode: currentUser.usr_id,
        },
        config
      );

      const updatedData = [...profiles];
      const currentRow = updatedData[selectedProfileIndex];
      currentRow["pf_enabled"] = pfEnabled;
      setProfiles(updatedData);

      // Simulate delay for 5 seconds
      // await new Promise((resolve) => setTimeout(resolve, 5000));
      const changedLimits = profileLimits?.filter(
        (obj) => obj.changed_xx === "Y"
      );
      for (let i = 0; i < changedLimits.length; i++) {
        const res = await newRequest.post(
          "/adProfile/updLimit",
          {
            profile_docid: selectedProfile._id,
            profile_limit_docid: changedLimits[i]._id,
            limit_value: formatStringToNumberV2(changedLimits[i].limit_value),
            limit_yn: changedLimits[i].limit_yn,
            userCode: currentUser.usr_id,
          },
          config
        );
      }
      fetchProfileLimits(selectedProfile._id);
      showToast("Updated!", "success");
    } catch (err) {
      console.log(err);
    } finally {
      setSavingTitle("Save");
      setIsProcessing(false);
    }
  };


  const handleLimitChange = (e, rowId) => {
    try {
      const { name, value } = e.target;
      const updatedData = [...profileLimits];
      const rowIndex = updatedData.findIndex(item => item._id === rowId);

      if (rowIndex === -1) {
        throw new Error('Row not found');
      }

      updatedData[rowIndex][name] = value;
      updatedData[rowIndex]["changed_xx"] = "Y";

      console.log(`rowId is ${rowId} handleLimitChange updatedData  \n${JSON.stringify(updatedData, null, 2)}`);
      setProfileLimits(updatedData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLimitBlur = (rowId, field) => {
    try {
      const updatedData = [...profileLimits];
      const rowIndex = updatedData.findIndex(item => item._id === rowId);

      if (rowIndex === -1) {
        throw new Error('Row not found');
      }

      let value = updatedData[rowIndex][field];
      const numberFields = ["limit_value"];

      if (numberFields.includes(field)) {
        if (value) {
          value = formatNumber(formatStringToNumberV2(value));
        } else {
          value = updatedData[rowIndex][field];
        }
      }

      updatedData[rowIndex][field] = value;
      setProfileLimits(updatedData);
    } catch (error) {
      console.log(error);
    }
  };

  const [newProfileName, setNewProfileName] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? 'simple-popover' : undefined;

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setNewProfileName("");
    setAnchorEl(null);
  };

  const handleAddProfile = async () => {
    try {

      if (!newProfileName) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter profile name!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      // generate profile code 
      const profileCode = await newRequest.post(
        "/orgNumgen/gen",
        {
          gen_org_code: currentUser.usr_org_code,
          gen_type: "Profile",
        },
        config
      );

      if (profileCode.data) {
        const res = await newRequest.post(
          "/adProfile/add",
          {
            pf_org_code: currentUser.usr_org_code,
            pf_code: profileCode.data,
            pf_name: newProfileName,
            userCode: currentUser.usr_id,
          },
          config
        );
        const addedName = newProfileName;
        handlePopoverClose();
        syncProfiles();
        showToast(`${addedName} added!`, "success");
        fetchProfiles();
      } else {
        setNotifyDialogTitle("Error!");
        setNotifyDialogText(`Unable to generate profile code!`);
        setIsNotifyDialogOpen(true);
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="ad-control">
      {isLoading ? (
        <div className="no-content"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <ScaleLoader
            color="#F44336"
            height={50}
            radius={8}
            width={4}
          />
        </div>
      ) : (
        <div className="ad-control-main-wrapper">
          <div className="center-wrapper">
            <div className="center-details">
              <div className="center-navbar">
                <span className="selected-title">
                  {selectedProfile?.pf_name}
                </span>
                <span
                  style={{ display: "flex", flexDirection: "row", gap: "5px" }}
                >
                  {/* <span
                    className="selected-type"
                    style={{ width: "max-content" }}
                  >
                    {selectedProfile.pf_enabled}
                  </span> */}
                  {["admin", "superadmin"].includes(currentUser.user_type_xx) &&
                    <CustomLoadingButton
                      startIcon={<FaSave />}
                      onClick={saveRecord}
                    >
                      {savingTitle}
                    </CustomLoadingButton>}
                  {["admin", "superadmin"].includes(currentUser.user_type_xx) &&
                    <CustomButton
                      startIcon={<AddIcon />}
                      onClick={handlePopoverClick}
                    >
                      New Profile
                    </CustomButton>}
                  <Popover
                    id={id}
                    open={popoverOpen}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'bottom',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <div style={{ padding: "20px" }}>
                      <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: "max-content",
                        marginBottom: "5px"
                      }}>
                        <span>ADD PROFILE</span>
                        <span
                          onClick={handlePopoverClose}
                        >
                          <IoClose
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                              borderRadius: "50%",
                            }}
                          />
                        </span>
                      </div>
                      <div className="input-item-with-label">
                        <label
                          htmlFor="new_profile_name"
                          style={{ width: "50px" }}
                        >
                          NAME
                        </label>
                        <input
                          id="new_profile_name"
                          name="new_profile_name"
                          type="text"
                          placeholder="NAME"
                          value={newProfileName}
                          onChange={(e) => setNewProfileName(e.target.value)}
                          className="form-input-item"
                          style={{ width: "160px" }}
                        />
                        <span style={{ display: "flex", margin: "0px 10px" }}>
                          <CustomButton
                            startIcon={<AddIcon />}
                            onClick={handleAddProfile}
                          >
                            Add
                          </CustomButton>
                        </span>
                      </div>
                    </div>
                  </Popover>

                  {["superadmin"].includes(currentUser.user_type_xx) &&
                    <CustomButton
                      startIcon={<SyncIcon />}
                      onClick={() => {
                        try {
                          let action_text = `Sync all profiles?`;
                          setObjectToAction({
                            object_id: selectedProfile._id,
                            action_text: action_text,
                            new_doc_status: "syncProfile",
                          });
                          setConfirmDialogAction("syncProfile");
                          setIsConfirmDialogOpen(true);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                    >
                      sync Profile
                    </CustomButton>}

                  {["superadmin"].includes(currentUser.user_type_xx) &&
                    <CustomButton
                      startIcon={<DeleteIcon />}
                      onClick={() => {
                        try {
                          let action_text = `Delete ${selectedProfile.pf_name}?`;
                          setObjectToAction({
                            object_id: selectedProfile._id,
                            action_text: action_text,
                            new_doc_status: "deleteProfile",
                          });
                          setConfirmDialogAction("deleteProfile");
                          setIsConfirmDialogOpen(true);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                    >
                      delete
                    </CustomButton>}
                </span>
              </div>
              <hr />
              <div className="form-details-wrapper">
                <div className="form-details-left">
                  <div className="input-item-with-label">
                    <label
                      htmlFor="pf_name"
                      style={{
                        width: "150px",
                      }}
                    >
                      NAME
                    </label>
                    <input
                      id="pf_name"
                      name="pf_name"
                      type="text"
                      placeholder="NAME"
                      value={pfName}
                      onChange={(e) => {
                        try {
                          const updatedData = [...profiles];
                          const currentRow = updatedData[selectedProfileIndex];
                          currentRow["pf_name"] = e.target.value;
                          setProfiles(updatedData);
                          setPfName(e.target.value);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                      className="form-input-item"
                      style={{ width: "400px" }}
                    />
                  </div>
                  <div className="input-item-with-label">
                    <label
                      htmlFor="pf_code"
                      style={{
                        width: "150px",
                      }}
                    >
                      CODE
                    </label>
                    <input
                      id="pf_code"
                      name="pf_code"
                      type="text"
                      placeholder="CODE"
                      value={pfCode}
                      className="form-input-item"
                      style={{ width: "100px" }}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-details-right">
                  <ToggleSwitch
                    id="pf_enabled"
                    value={pfEnabled}
                    onChange={setPfEnabled}
                    label={"ENABLED"}
                    labelWidth="max-content"
                    disabled={false}
                    border="N"
                    style={{
                      width: "250px",
                    }}
                  />
                </div>
              </div>
              <hr />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  margin: "10px 0px"
                }}
              >
                <h2 className="sub-titles">LIMITS</h2>
                <div className="search-input-wrapper">
                  <SearchIcon className="search-input-icon" />
                  <input
                    className="search-input"
                    type="text"
                    placeholder="Search Limits..."
                    value={searchTermLimits}
                    onChange={(e) => setSearchTermLimits(e.target.value)}
                  />
                  {searchTermLimits.length > 0 && (
                    <CloseIcon
                      onClick={handleClearSearchLimits}
                      className="search-input-icon"
                    />
                  )}
                </div>

                {/* {["admin", "superadmin"].includes(currentUser.user_type_xx) && <Tooltip title={`Add Limit`} placement="right">
                  <AddIcon
                    size={50}
                    style={{
                      borderRadius: "5px",
                      padding: "3px",
                      alignSelf: "center",
                      cursor: "pointer",
                      backgroundColor: "rgba(0, 128, 0, 0.5)",
                    }}
                    onClick={async () => {
                      try {

                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  />
                </Tooltip>} */}
              </div>
              {profileLimits?.length > 0 && <div className="table-content">
                <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none' }} className="table-container">
                  <Table sx={{ width: "max-content", border: 'none' }}>
                    <TableHead className="tablehead">
                      <TableRow className="tablerow">
                        <TableCell className="table-cell" style={{ maxWidth: "50px", fontWeight: "bold", display: "flex", alignItems: "center" }}>
                          #
                        </TableCell>
                        <TableCell className="table-cell" style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", maxWidth: "100px", flexDirection: "row" }}>
                          <span>CODE</span>
                        </TableCell>
                        <TableCell className="table-cell" style={{ fontWeight: "bold", width: "auto", display: 'flex' }}>
                          <span>NAME</span>
                        </TableCell>
                        <TableCell className="table-cell" style={{ fontWeight: "bold", width: "300px", display: 'flex' }}>
                          <span>MODULE</span>
                        </TableCell>
                        <TableCell className="table-cell" style={{ fontWeight: "bold", alignItems: "center", display: 'flex', maxWidth: "200px", justifyContent: "center" }}>
                          <span>VALUE</span>
                        </TableCell>
                        {/* {["admin", "superadmin"].includes(currentUser.user_type_xx) &&
                          <TableCell className="table-cell" style={{ fontWeight: "bold", maxWidth: "100px", alignItems: "center", textAlign: "center" }}>
                            <span>ACTION</span>
                          </TableCell>} */}
                      </TableRow>
                    </TableHead>
                    <TableBody className="tablebody">
                      {slicedDataLimits?.map((row, index) => (
                        <TableRow
                          key={row._id}
                          className={`${selectedProfileLimit?._id === row._id ? "tablerow current" : "tablerow"}`}
                          onClick={() => {
                            try {
                              setSelectedProfileLimit(row);
                            } catch (error) {
                              console.log(error);
                            }
                          }}
                        >
                          <TableCell className="table-cell" style={{ maxWidth: "50px", fontWeight: "bold", display: "flex", alignItems: "center" }}>
                            {currentPage * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell className="table-cell" style={{ display: "flex", fontWeight: "bold", maxWidth: "100px", flexDirection: "column" }}>
                            <span style={{ fontWeight: "400", fontSize: "18px" }}>{row.limit_code}</span>
                          </TableCell>
                          <TableCell className="table-cell" style={{ display: "flex", fontWeight: "bold", width: "auto", flexDirection: "column" }}>
                            <span>{row.limit_name_xx}</span>
                          </TableCell>
                          <TableCell className="table-cell" style={{ display: "flex", fontWeight: "bold", maxWidth: "300px", flexDirection: "column" }}>
                            <span>{row.module_name_xx}</span>
                          </TableCell>
                          <TableCell className="table-cell" style={{ display: "flex", fontWeight: "bold", maxWidth: "200px", justifyContent: "center" }}>
                            {row.limit_type_xx !== "YN" &&
                              <input
                                id="limit_value"
                                name="limit_value"
                                type="text"
                                value={row.limit_value}
                                onChange={(e) => handleLimitChange(e, row._id)}
                                style={{ width: "100%", textAlign: "right" }}
                                className="form-input-item"
                                onBlur={() => {
                                  handleLimitBlur(row._id, "limit_value");
                                }}
                                disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                              />}
                            {row.limit_type_xx === "YN" &&
                              <Select
                                id="limit_yn"
                                name="limit_yn"
                                value={row.limit_yn}
                                onChange={(e) => handleLimitChange(e, row._id)}
                                variant="outlined"
                                size="small"
                                disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                              >
                                <MenuItem value="Y">Yes</MenuItem>
                                <MenuItem value="N">No</MenuItem>
                              </Select>}
                          </TableCell>
                          {/* {["admin", "superadmin"].includes(currentUser.user_type_xx) &&
                            <TableCell className="table-cell" style={{ display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: 'space-around', padding: "3px", maxWidth: "100px", }}>
                              <Tooltip
                                title={`Remove ${row.limit_name_xx} from this profile?`}
                                placement="right"
                              >
                                <DeleteIcon
                                  size={50}
                                  style={{
                                    borderRadius: "5px",
                                    padding: "3px",
                                    alignSelf: "center",
                                    cursor: "pointer",
                                    color: "rgba(244, 67, 54)",
                                    backgroundColor: "rgba(244, 67, 54, 0.5)",
                                  }}
                                  onClick={() => {
                                    try {
                                      let action_text = `Remove ${row.limit_name_xx} from this profile?`;
                                      setObjectToAction({
                                        object_id: row._id,
                                        action_text: action_text,
                                        new_doc_status: "deleteLimit",
                                      });
                                      setConfirmDialogAction("deleteLimit");
                                      setIsConfirmDialogOpen(true);
                                    } catch (error) {
                                      console.log(error);
                                    }
                                  }}
                                />
                              </Tooltip>
                            </TableCell>} */}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[8]}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleRowsPerPage}
                    component="div"
                    count={filteredLimits?.length}
                    rows={filteredLimits}
                    page={currentPage}
                    onPageChange={handlePageChange}
                  />
                </TableContainer>
              </div>}
            </div>
          </div>
          <div className="right-wrapper">
            <div className="right-content">
              <div className="right-navbar">
                <div className="search-input-wrapper">
                  <SearchIcon className="search-input-icon" />
                  <input
                    className="search-input"
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  {searchTerm.length > 0 && (
                    <CloseIcon
                      onClick={handleClearSearch}
                      className="search-input-icon"
                    />
                  )}
                </div>
              </div>
              <div className="options-list">
                {filteredProfiles?.map((profile, index) => (
                  <>
                    <span
                      key={profile._id}
                      className={`${selectedProfile._id === profile._id
                        ? "single-option active"
                        : "single-option"
                        }`}
                      onClick={async () => {
                        try {
                          setSelectedProfileIndex(index);
                          setSelectedProfile(profile);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                    >
                      <div className="title-row">
                        <span className="cy-title">{profile.pf_name}</span>
                      </div>
                    </span>
                    <hr style={{ margin: "0px 10px" }} />
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Dialog to notify user about changes being saved */}
      <Dialog open={isNotifyDialogOpen} onClose={handleCloseNotifyDialog}>
        <DialogTitle>{notifyDialogTitle}</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: notifyDialogText }} />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseNotifyDialog}>Close</CustomButton>
        </DialogActions>
      </Dialog>
      {/* Confirm Dialog */}
      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        dialogTitle="Confirmation"
        dialogContent={<p>{objectToAction.action_text}</p>}
      />
      {isProcessing && (
        <motion.div
          className="processing-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="processing-wrapper">
            <GridLoader
              color="#F44336"
              style={{
                height: "50px",
                width: "50px",
              }}
            />
          </div>
        </motion.div>
      )}
      {/* {isCodeDialogOpened && (
        <motion.div
          className="new-rate-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="new-rate-wrapper">
            <div className="new-rate-navbar">
              <span>{`${
                currencyAction === "addSysCode" ? "ADD CODE" : "ADD TYPE"
              }`}</span>
              <span
                onClick={() => {
                  try {
                    setNewCode(null);
                    setNewName(null);
                    setNewGrouping(null);
                    setIsCodeDialogOpened(false);
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                <IoClose
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    borderRadius: "50%",
                  }}
                />
              </span>
            </div>
            <div className="new-rate-content">
              <div className="input-item-with-label">
                <label
                  htmlFor="newName"
                  className="form-dropdown-label"
                  style={{ width: "130px" }}
                >
                  NAME
                </label>
                <input
                  id="newName"
                  name="newName"
                  type="text"
                  placeholder="NAME"
                  value={newName}
                  className="form-input-item"
                  style={{ width: "250px" }}
                  onChange={(e) => setNewName(e.target.value)}
                />
              </div>
              <div className="input-item-with-label">
                <label
                  htmlFor="newCode"
                  className="form-dropdown-label"
                  style={{ width: "130px" }}
                >
                  {currencyAction === "addSysCode" ? "CODE" : "TYPE"}
                </label>
                <input
                  id="newCode"
                  name="newCode"
                  type="text"
                  placeholder={
                    currencyAction === "addSysCode" ? "CODE" : "TYPE"
                  }
                  value={newCode}
                  className="form-input-item"
                  style={{ width: "150px" }}
                  onChange={(e) => {
                    try {
                      setNewCode(e.target.value);
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                />
              </div>
              {currencyAction === "addSysCode" && (
                <div className="input-item-with-label">
                  <label
                    htmlFor="newGrouping"
                    className="form-dropdown-label"
                    style={{ width: "130px" }}
                  >
                    GROUPING
                  </label>
                  <input
                    id="newGrouping"
                    name="newGrouping"
                    type="text"
                    placeholder="GROUPING"
                    value={newGrouping}
                    className="form-input-item"
                    style={{ width: "150px" }}
                    onChange={(e) => setNewGrouping(e.target.value)}
                  />
                </div>
              )}
            </div>
            <div className="new-rate-action">
              {currencyAction === "addSysCode" && (
                <CustomButton onClick={handleAddSysCode}>ADD</CustomButton>
              )}
              {currencyAction === "addSysType" && (
                <CustomButton onClick={handleAddSysType}>ADD</CustomButton>
              )}
            </div>
          </div>
        </motion.div>
      )} */}
    </div>
  );
};

export default AdControl;
