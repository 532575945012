import React from 'react';
import './Notifications.scss';
import CircularButton from '../ui/CustomButton';
import CloseIcon from '@mui/icons-material/Close';
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';
import newRequest from '../../utils/newRequest';

const Notifications = ({ notifications, onClose }) => {
    return (
        <div className="notifications-overlay">
            <div className="notifications-container">
                <div className="notifications-navbar">
                    <h4>Notifications</h4>
                    <CircularButton onClick={onClose} icon={<CloseIcon />} width={30} height={30} />
                </div>
                <hr />
                {notifications.map((row) => (
                    <div key={row._id} className={`${row.note_opened === "N" ? 'single-note unread' : 'single-note'}`}
                        onClick={async () => {
                            try {
                                if (row.note_binary_docid) {
                                    const response = await newRequest.get(`/viewdocs/view/${row.note_binary_docid}`, {
                                        responseType: 'arraybuffer', // Ensure response is treated as binary data
                                    });
                                    // Create a blob from the binary data received
                                    const blob = new Blob([response.data], { type: response.headers['content-type'] });
                                    //   Create a blob URL from the response
                                    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                                    //   Open the PDF in a new tab
                                    const newTab = window.open();
                                    newTab.location.href = url;
                                } else {
                                    console.log("Not a report")
                                }
                            } catch (error) {
                                console.log(error)
                            }
                        }}
                    >
                        <div className="left">
                            {row.from_photo_url_xx ?
                                <img src={row.from_photo_url_xx} alt="" /> : <BrokenImageOutlinedIcon style={{
                                    display: "flex",
                                    width: "30px",
                                    height: "30px",
                                    color: "#ccc",
                                    borderRadius: "50%",
                                    objectFit: "cover"
                                }} />}
                        </div>
                        <div className="right">
                            <span className='title'>{row.note_text}</span>
                            <span className='time'>{row.time_ago_xx}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Notifications;