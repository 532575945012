import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import "./Login.scss";
import {
  CustomLoadingGradient,
} from "../../components/ui/CustomButton";
import { loginStart, loginSuccess, loginFailure, showBanner } from "../../redux/userSlice";
import { countToDo } from "../../redux/todoSlice";
import newRequest from "../../utils/newRequest";
import { motion } from "framer-motion";

// Function to get browser from userAgent string
const getBrowser = (userAgent) => {
  if (userAgent.includes("Firefox")) {
    return "Firefox";
  } else if (userAgent.includes("Chrome")) {
    return "Chrome";
  } else if (userAgent.includes("Safari")) {
    return "Safari";
  } else if (userAgent.includes("MSIE") || userAgent.includes("Trident")) {
    return "Internet Explorer";
  } else {
    return "Unknown";
  }
};

// Function to get browser version from userAgent string
const getBrowserVersion = (userAgent) => {
  const match = userAgent.match(/(Chrome|Firefox|Safari|MSIE|Trident)\/(\d+)/);
  return match ? match[2] : "Unknown";
};

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // socket
  const socket = useSelector((state) => state.socket.socket);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [buttonTitle, setButtonTitle] = useState("LOGIN");

  // Obtain browser and operating system information
  const userAgent = navigator.userAgent;
  const browserInfo = {
    userAgent: userAgent,
    browser: getBrowser(userAgent),
    browserVersion: getBrowserVersion(userAgent),
    platform: navigator.platform,
  };


  const handleLogin = async (e) => {
    // e.preventDefault();
    setButtonTitle("LOGGING IN...")
    dispatch(loginStart());
    try {

      const res = await newRequest.post("/auth/login", {
        usr_un: username,
        usr_pw: password,
      });

      // console.log(`loginResult  ${JSON.stringify(res.data, null, 2)}`);

      if (res.data.result === "0") {
        setError("");
        dispatch(loginSuccess(res.data));
        await fetchTodoCount(res.data.usr_org_code, res.data.usr_id);
        if (socket) {
          socket.emit("newUser", res.data);
        }

        // console.log("Now about to navigate...")
        // navigate("/home");
        navigate("/");
        setButtonTitle("LOGIN");
      } else {
        setError("Invalid Login details. Please try again...");
        dispatch(loginFailure());
      }
    } catch (err) {
      console.log(err);
      dispatch(loginFailure());
    } finally {
      setButtonTitle("LOGIN");
    }
  };

  const fetchTodoCount = async (org_code, usr_id) => {
    try {
      const res = await newRequest.post(
        "/todo/count",
        {
          todo_org_code: org_code,
          todo_userid: usr_id,
          todo_completed: "N",
        }
      );
      dispatch(countToDo(res.data));
    } catch (err) {
      console.log(err);
    }
  };



  return (
    <div className="login">
      <motion.div
        className="wrapper"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 1 } }}
        exit={{ opacity: 0, transition: { duration: 1 } }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1 className="title">SIGN IN</h1>
          <span>
            <h1
              onClick={() => {
                try {
                  navigate("/qillo");
                } catch (error) {
                  console.log(error)
                }
              }}
              style={{ cursor: "pointer" }}
            >
              Qillo
            </h1>
          </span>
        </div>
        <form>
          <div className="input-item-with-label">
            <input
              id="username"
              name="username"
              type="text"
              placeholder="USERNAME"
              value={username}
              className="form-input-item"
              style={{ width: "350px", height: "40px" }}
              disabled={buttonTitle !== "LOGIN"}
              onChange={(e) => {
                try {
                  setError("");
                  setUsername(e.target.value);
                } catch (error) {
                  console.log(error);
                }
              }}
            />
          </div>
          <div className="input-item-with-label">
            <input
              id="password"
              name="password"
              type="password"
              placeholder="PASSWORD"
              value={password}
              className="form-input-item"
              style={{ width: "350px", height: "40px" }}
              disabled={buttonTitle !== "LOGIN"}
              onChange={(e) => {
                try {
                  setPassword(e.target.value);
                } catch (error) {
                  console.log(error);
                }
              }}
            />
          </div>
          {error && <div className="error"> {error} </div>}
          <CustomLoadingGradient
            height={"45px"}
            width={"350px"}
            onClick={handleLogin}
          >
            {buttonTitle}
          </CustomLoadingGradient>
          <Link className="forgotPassword">FORGOT PASSWORD?</Link>
          <Link
            onClick={() => {
              try {
                setError("");
                navigate("/register");
              } catch (error) {
                console.log(error);
              }
            }}
          >
            REGISTER
          </Link>
        </form>
      </motion.div>
    </div>
  );
};



export default Login;
