import "./HorizontalNavbar.scss";
import { setDarkMode, setLightMode } from "../../redux/themeSlice";
import { logOut } from "../../redux/userSlice";
import newRequest from "../../utils/newRequest";
import { SearchButton, CustomButtonGradient, CustomButtonMenu, CustomButton, CustomLoadingButton } from "../ui/CustomButton";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Badge from '@mui/material/Badge';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { motion } from "framer-motion";
import { Select } from 'antd';
import { IoClose } from "react-icons/io5";
import { activeStatuses } from "../../utils/core";
import MailIcon from '@mui/icons-material/Mail';
import Notifications from "../notifications/Notifications";

const HorizontalNavbar = () => {
  // socket
  const socket = useSelector((state) => state.socket.socket);
  const { currentUser, currentUserAvator } = useSelector((state) => state.user);
  const { currentTheme } = useSelector((state) => state.theme);
  const { todoCount } = useSelector((state) => state.todo);
  const [searchInput, setSearchInput] = useState("");
  const [open, setOpen] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [todoOpen, setTodoOpen] = useState(false);
  const [currentMenuIndex, setCurrentMenuIndex] = useState(0);
  const [currentMenu, setCurrentMenu] = useState("home");
  const [notifications, setNotifications] = useState([]);
  const [openNotifications, setOpenNotifications] = useState(false);
  const todoRef = useRef(null);
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      setTodoOpen(false);
    }
  };
  const [selectedSearchFilter, setSelectedSearchFilter] = useState("everything");

  useEffect(() => {
    function handleClickOutside(event) {
      // for hiding todo
      if (todoRef.current && !todoRef.current.contains(event.target)) {
        setTodoOpen(false);
      }
    }

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const fetchUserRoles = async () => {
    try {
      const res = await newRequest.post(
        "/adUserRole/findAll",
        {
          ur_org_code: currentUser.usr_org_code,
          ur_user_docid: currentUser.usr_id,
        }
      );
      setUserRoles(res.data);
      setCurrentMenuIndex(100)
    } catch (err) {
      console.log(err);
    }
  };

  const fetchNotifications = async () => {
    try {
      const res = await newRequest.post(
        "/note/findAll", { note_type: "" }
      );
      setNotifications(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {

    // Listen for new notifications from the server
    socket.on("getNotification", (data) => {
      console.log("New notification received:", data);
      // Update the state with the new notification
      setNotifications((prevNotifications) => [
        ...prevNotifications,
        data
      ]);
      console.log(`Notifications in navbar ${JSON.stringify(notifications, null, 2)}`);
    });

    // Cleanup on component unmount
    return () => {
      socket.off("getNotification");
    };

  }, []);



  useEffect(() => {
    if (activeStatuses.includes(currentUser?.subscription_status_xx)) {
      fetchUserRoles();
    }
    fetchNotifications();
  }, [currentUser.usr_id]);

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      dispatch(logOut());
      if (socket) {
        socket.emit("logoutUser", { socketId: socket.id });
      }
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };



  const toggleTheme = async (e) => {
    e.preventDefault();
    try {
      if (currentTheme === "light") {
        dispatch(setDarkMode());
      } else {
        dispatch(setLightMode());
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleMenuClick = (roleCode, roleComponent, roleMenus, index) => {
    try {
      setCurrentMenu(roleComponent.replace("/", ""));
      navigate(roleComponent, {
        state: { scroll: true, roleMenus },
        replace: true,
      });
      setCurrentMenuIndex(index)
    } catch (error) {
      console.log(error);
    }
  };


  const handleOptionsChange = (value) => {
    setSelectedSearchFilter(value)
    console.log(`selected ${value}`);
  };



  const handleSearchSubmit = (e) => {
    try {
      if (e) { e.preventDefault(); }
      if (searchInput) {
        navigate('/searchResults', { state: { searchInput: searchInput, searchFilter: selectedSearchFilter } });
      }
    } catch (error) {
      console.log(error)
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (searchInput) {
        navigate('/searchResults', { state: { searchInput: searchInput, searchFilter: selectedSearchFilter } });
      }
    }
  };

  const handleNotificationClick = () => {
    setOpenNotifications(true);
  };

  const handleCloseNotifications = () => {
    setOpenNotifications(false);
  };

  return (
    <div className={activeStatuses.includes(currentUser?.subscription_status_xx) ? "horizontal-navbar" : "horizontal-navbar inactive"}>
      <div className="top-part">
        <div className="left">
          <div className="user" onClick={() => {
            try {
              setCurrentMenu("/profile");
              navigate("/profile");
              setCurrentMenuIndex(200);
            } catch (error) {
              console.log(error)
            }
          }}>
            {currentUserAvator ?
              <img src={currentUserAvator} alt="" className="image-cell-img" /> : <BrokenImageOutlinedIcon style={{
                display: "flex",
                width: "30px",
                height: "30px",
                color: "#ccc",
                borderRadius: "50%",
                objectFit: "cover"
              }} />}
            <div className="user-org-view">
              <span className="user-full-name">
                {currentUser.usr_full_name}
              </span>
              <span className="user-org-name">
                {currentUser.usr_org_name}
              </span>
            </div>
          </div>
        </div>
        {activeStatuses.includes(currentUser?.subscription_status_xx) &&
          <div className="center">
            {/* <SearchBar /> */}
            <div className="search-input-wrapper" style={{border:"0.3px solid #ffca28"}}>
              <Select
                defaultValue="everything"
                style={{
                  width: 200,
                  margin: "0px 2px",
                }}
                onChange={handleOptionsChange}
                options={[
                  {
                    value: 'everything',
                    label: 'Everything',
                  },
                  {
                    value: 'entities',
                    label: 'Entities',
                  },
                  {
                    value: 'receipts',
                    label: 'Receipts',
                  },
                  {
                    value: 'payments',
                    label: 'Payments',
                  },
                  {
                    value: 'risk notes',
                    label: 'Risk Notes',
                  },
                  {
                    value: 'vehicles',
                    label: 'Vehicles',
                  },
                  {
                    value: 'claims',
                    label: 'Claims',
                  },
                ]}
              />
              <input
                className="search-input"
                type="text"
                placeholder={`Search ${selectedSearchFilter}`}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') handleSearchSubmit();
                }}
              />
              {searchInput.length > 0 && (
                <IoClose
                  onClick={(e) => setSearchInput("")}
                  className="search-input-icon"
                />
              )}
              <SearchButton
                width={"200px"}
                onClick={handleSearchSubmit}
                themeColor={"#ffca28"}>
                Search
              </SearchButton>
            </div>
          </div>}
        <div className="right">
          {activeStatuses.includes(currentUser?.subscription_status_xx) &&
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 1.5 } }}
              exit={{ opacity: 0, transition: { duration: 1.5 } }}
              className={`${notifications?.length > 0 ? 'todo active' : 'todo'}`}
              onClick={() => {
                try {
                  setOpenNotifications(!openNotifications);
                } catch (error) {
                  console.log(error)
                }
              }}
            >
              <span className={`${notifications?.length > 0 ? 'todo-icon active' : 'todo-icon'}`}
              >
                <MailIcon />
              </span>
              <Badge badgeContent={notifications?.length} showZero sx={{ '& .MuiBadge-badge': { backgroundColor: notifications?.length > 0 ? '#ffca28' : '#c3c3c3', color: 'white' } }}
                className="todo-badge" />
              {openNotifications &&
                (
                  <Notifications notifications={notifications} onClose={handleCloseNotifications} />
                )}
            </motion.div>
          }
          {activeStatuses.includes(currentUser?.subscription_status_xx) &&
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 1.5 } }}
              exit={{ opacity: 0, transition: { duration: 1.5 } }}
              className={`${todoCount > 0 ? 'todo active' : 'todo'}`}
              onClick={() => {
                try {
                  handleMenuClick(
                    "/",
                    "/",
                    []
                  )
                  setCurrentMenuIndex(100);
                } catch (error) {
                  console.log(error)
                }
              }}
            >
              <span className={`${todoCount > 0 ? 'todo-icon active' : 'todo-icon'}`}
              >
                <ListAltIcon />
              </span>
              <span className={`${todoCount > 0 ? 'todo-title active' : 'todo-title'}`}>TODO</span>
              <Badge badgeContent={todoCount} showZero sx={{ '& .MuiBadge-badge': { backgroundColor: todoCount > 0 ? '#ffca28' : '#FFB300', color: 'white' } }}
                className="todo-badge" />
            </motion.div>}
          <SearchButton onClick={handleLogout} height={"36px"} width={"100px"} borderRadius={"5px 5px 5px 5px"} themeColor={"#ffca28"} >
            Logout
          </SearchButton>
        </div>
      </div>
      {/* <hr
        style={{
          border: "none",
          height: "0.3px",
          backgroundColor: "red",
        }}
      /> */}
      {activeStatuses.includes(currentUser?.subscription_status_xx) &&
        <div className="bottom-part">
          <div className="menus-wrapper">
            <CustomButtonMenu
              currentButton={currentMenuIndex === 100}
              className="single-menu"
              onClick={() => {
                try {
                  handleMenuClick(
                    "/",
                    "/",
                    []
                  )
                  setCurrentMenuIndex(100);
                } catch (error) {
                  console.log(error)
                }
              }}
            >{`Home`}</CustomButtonMenu>
            {userRoles.map((role, index) => (
              <div key={role.ur_role_code}>
                <CustomButtonMenu
                  className="single-menu"
                  currentButton={index === currentMenuIndex}
                  onClick={() => {
                    try {
                      handleMenuClick(
                        role.ur_role_code,
                        role.role_component_xx,
                        role.role_menus_xx
                      )
                      setCurrentMenuIndex(index);
                    } catch (error) {
                      console.log(error)
                    }
                  }
                  }
                >
                  {role.role_name_xx}
                </CustomButtonMenu>
              </div>
            ))}
            <CustomButtonMenu
              currentButton={currentMenuIndex === 200}
              className="single-menu"
              onClick={() => {
                try {
                  setCurrentMenu("/profile");
                  navigate("/profile");
                  setCurrentMenuIndex(200);
                } catch (error) {
                  console.log(error);
                }
              }}
            >{`PROFILE`}</CustomButtonMenu>
          </div>
        </div>}
      {/* <hr
        style={{
          border: "none",
          height: "0.3px",
          backgroundColor: "red",
        }}
      /> */}
    </div>
  );
};

export default HorizontalNavbar;
