import "./ToggleSwitch.scss";
import Switch from "@mui/material/Switch";
import ClearIcon from "@mui/icons-material/Clear";

export const ToggleSwitch = ({
  id,
  value,
  onChange,
  label,
  labelPosition = "start",
  labelWidth = "150px",
  labelStyle = {},
  delete_yn = false,
  disabled = false,
  border = "N",
  onClickClear,
}) => {
  const handleToggle = () => {
    if (disabled) return;
    const newValue = value === "N" ? "Y" : "N";
    onChange(newValue);
  };

  const handleClearClick = (event) => {
    event.stopPropagation();
    if (onClickClear) {
      onClickClear(id);
    }
  };

  return (
    <div
      className={`toggle-switch ${border === "Y" ? "border" : ""} ${value === "Y" ? "active" : ""} ${disabled ? "disabled" : ""}`}
      onClick={handleToggle}
    >
      {labelPosition === "start" && (
        <div className="labels" style={{ width: labelWidth }}>
          <div className="label" style={labelStyle}>
            {label}
          </div>
        </div>
      )}
      <div className="switch"></div>
      {labelPosition === "end" && (
        <div className="labels" style={{ width: labelWidth }}>
          <div className="label" style={labelStyle}>
            {label}
          </div>
        </div>
      )}
      {delete_yn && value && <ClearIcon onClick={handleClearClick} />}
    </div>
  );
};

export const ToggleSwitchInLoop = ({ id, value, onChange }) => {
  const handleToggleChange = (event) => {
    const newValue = event.target.checked ? "Y" : "N";
    onChange(newValue);
  };

  return (
    <Switch
      checked={value === "Y"}
      onChange={handleToggleChange}
      inputProps={{ "aria-label": "Toggle switch" }}
      sx={{
        "& .MuiSwitch-thumb": {
          width: 24,
          height: 24,
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
          backgroundColor: "#fff",
        },
        "& .MuiSwitch-track": {
          borderRadius: 26 / 2,
          backgroundColor: value === "Y" ? "#4caf50" : "#ccc", // Green when checked, gray when unchecked
        },
      }}
    />
  );
};


export const ToggleSwitchRadio = ({
  id,
  value,
  onChange,
  label,
  labelPosition = "start",
  labelWidth = "150px",
  labelStyle = {},
  delete_yn = false,
  disabled = false,
  border = "N",
  onClickClear,
}) => {
  const handleToggle = () => {
    if (disabled) return;
    const newValue = value === "N" ? "Y" : "N";
    onChange(newValue);
  };

  const handleClearClick = (event) => {
    event.stopPropagation();
    if (onClickClear) {
      onClickClear(id);
    }
  };

  return (
    <div
      className={`toggle-switch ${border === "Y" ? "border" : ""} ${value === "Y" ? "active" : ""
        }`}
      onClick={handleToggle}
    >
      {labelPosition === "start" && (
        <div className="labels" style={{ width: labelWidth }}>
          <div className="label" style={labelStyle}>
            {label}
          </div>
        </div>
      )}
      <div className="switch"></div>
      {labelPosition === "end" && (
        <div className="labels" style={{ width: labelWidth }}>
          <div className="label" style={labelStyle}>
            {label}
          </div>
        </div>
      )}
      {delete_yn && value === "Y" && <ClearIcon onClick={handleClearClick} />}
    </div>
  );
};