import { useSelector } from "react-redux";
import "./Correspondence.scss"
import { useEffect, useState } from "react";
import newRequest from "../../utils/newRequest";
import { motion } from "framer-motion";
import { IoClose } from "react-icons/io5";
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';
import EmailComponent from "../emailComponent/EmailComponent";
import { CustomLoadingButton } from "../ui/CustomButton";
import {
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    TablePagination,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Chip
} from "@mui/material";
import { SlArrowDown } from "react-icons/sl";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import SmsComponent from "../smsComponent/SmsComponent";

const Correspondence = ({ correspondenceParams, passedEmailData }) => {
    const { currentUser } = useSelector((state) => state.user);

    // console.log(
    //     `Correspondence passedEmailData  ${JSON.stringify(
    //         passedEmailData,
    //         null,
    //         2
    //     )}`
    // );

    // email component 
    const [emails, setEmails] = useState([]);
    const [emailDialogOpen, setEmailDialogOpen] = useState(false);
    // const [emailData, setEmailData] = useState(passedEmailData || null);
    const [searchTermEmail, setSearchTermEmail] = useState("");
    const [emailPage, setEmailPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [expanded, setExpanded] = useState(false);
    const [selectedEmail, setSelectedEmail] = useState(null);


    // sms component 
    const [rowsPerPageSms, setRowsPerPageSms] = useState(4);
    const [smss, setSmss] = useState([]);
    const [smsDialogOpen, setSmsDialogOpen] = useState(false);
    const [smsData, setSmsData] = useState(null);
    const [searchTermSms, setSearchTermSms] = useState("");
    const [smsPage, setSmsPage] = useState(0);
    const [selectedSms, setSelectedSms] = useState(null);

    // EMAILS
    const handleEmailsChangePage = (event, newPage) => {
        setEmailPage(newPage);
    };
    const handleEmailsChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setEmailPage(0);
    };
    const filteredEmailsData = emails.filter(
        (item) =>
            item.em_subject?.toLowerCase().includes(searchTermEmail.toLowerCase()) ||
            item.em_content?.toLowerCase().includes(searchTermEmail.toLowerCase()) ||
            item.em_search_string?.toLowerCase().includes(searchTermEmail.toLowerCase())
    );
    const slicedEmailsData = filteredEmailsData.slice(
        emailPage * rowsPerPage,
        emailPage * rowsPerPage + rowsPerPage
    );


    // SMS
    const handleSmssChangePage = (event, newPage) => {
        setSmsPage(newPage);
    };
    const handleSmssChangeRowsPerPage = (event) => {
        setRowsPerPageSms(parseInt(event.target.value, 4));
        setSmsPage(0);
    };
    const filteredSmssData = smss.filter(
        (item) =>
            item.sms_subject?.toLowerCase().includes(searchTermSms.toLowerCase()) ||
            item.sms_content?.toLowerCase().includes(searchTermSms.toLowerCase()) ||
            item.sms_search_string?.toLowerCase().includes(searchTermSms.toLowerCase())
    );
    const slicedSmssData = filteredSmssData.slice(
        smsPage * rowsPerPageSms,
        smsPage * rowsPerPageSms + rowsPerPageSms
    );

    const fetchEmails = async () => {
        try {
            const res = await newRequest.post(
                "/correspondence/findAllEmail", correspondenceParams
            );
            setEmails(res.data);
        } catch (err) {
            console.log(err);
        }
    };

    const fetchSms = async () => {
        try {
            const res = await newRequest.post(
                "/correspondence/findAllSms", correspondenceParams
            );
            setSmss(res.data);
        } catch (err) {
            console.log(err);
        }
    };

    const handleAccodionChange = (panel, email) => (event, isExpanded) => {
        try {
            setExpanded(isExpanded ? panel : false);
            setSelectedEmail(isExpanded ? email : null);
            // console.log(`email  ${JSON.stringify(email, null, 2)}`);
        } catch (error) {
            console.log(error)
        }
    };

    const handleClearSearchEmail = () => {
        setSearchTermEmail("");
    };
    const handleClearSearchSms = () => {
        setSearchTermSms("");
    };

    useEffect(() => {
        fetchEmails();
        fetchSms();
    }, [correspondenceParams]);

    return (
        <div className='correspondence'>
            <motion.div className="corr-wrapper"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 1 } }}
                exit={{ opacity: 0, transition: { duration: 1 } }}
            >
                <div className="corr-wrapper-email">
                    <div className="corr-navbar-email">
                        <div className="corr-email-navbar-left">
                            <div className="search-input-wrapper">
                                <SearchIcon className="search-input-icon" />
                                <input
                                    className="search-input"
                                    type="text"
                                    placeholder="Search..."
                                    value={searchTermEmail}
                                    onChange={(e) => setSearchTermEmail(e.target.value)}
                                />
                                {searchTermEmail.length > 0 && (
                                    <CloseIcon
                                        onClick={handleClearSearchEmail}
                                        className="search-input-icon"
                                    />
                                )}
                            </div>
                        </div>
                        <div className="corr-email-navbar-right">
                            <CustomLoadingButton startIcon={<AddIcon />} onClick={() => {
                                try {
                                    setEmailDialogOpen(true);
                                } catch (error) {
                                    console.log(error)
                                }
                            }}>
                                EMAIL
                            </CustomLoadingButton>
                        </div>
                    </div>
                    <hr />
                    <div className="corr-content-email">
                        {slicedEmailsData?.length > 0 &&
                            <div className="table-content">
                                <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none' }}
                                    className="table-container">
                                    <Table sx={{ width: "max-content", border: 'none' }}>
                                        <TableBody className="tablebody-accordion">
                                            {slicedEmailsData?.map((row, index) => (
                                                <TableRow
                                                    key={row._id}
                                                    className="tablerow-accordion">
                                                    <TableCell className="table-cell-accordion"
                                                        style={{
                                                            display: "flex",
                                                            width: "100%",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            fontWeight: "bold",
                                                        }}>
                                                        <Accordion className="accordion" expanded={expanded === row._id} onChange={handleAccodionChange(row._id, row)}>
                                                            <AccordionSummary
                                                                className="accordion-summary"
                                                                expandIcon={<SlArrowDown className="content-accordion-expand-icon" style={{ color: "#f44336", fontWeight: "bold" }} />}
                                                                id={`panel-${row._id}-header`}
                                                            >
                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%", marginRight: "10px" }}>
                                                                    <Typography>
                                                                        <span className="sr-no">{emailPage * rowsPerPage + index + 1} </span> <span className="summary-subject">{row.em_subject}</span>
                                                                    </Typography>
                                                                    <Typography>
                                                                        <span className="summary-time">
                                                                            {row.time_ago_xx}
                                                                        </span>
                                                                    </Typography>
                                                                </div>
                                                            </AccordionSummary>
                                                            <AccordionDetails className="accordion-details">
                                                                <div key={row?._id} className="single-email"
                                                                >
                                                                    <div className="single-email-row1">
                                                                        <span className="to">{`TO: ${row?.em_to.join()}`}</span>
                                                                        <span>{row?.time_ago_xx}</span>
                                                                    </div>
                                                                    <div className="single-email-row1">
                                                                        <span className="from">{`SENT By: ${row.updated_by_name_xx}`}</span>
                                                                        {row?.updated_by_photo_url_xx ? <img
                                                                            src={row?.updated_by_photo_url_xx}
                                                                            alt=""
                                                                            className="avatar"
                                                                        /> : <BrokenImageOutlinedIcon style={{ width: "30px", height: "30px" }} />}
                                                                    </div>
                                                                    <div className="single-email-row1">
                                                                        <h1 className="subject">{`SUBJECT: ${row?.em_subject}`}</h1>
                                                                    </div>
                                                                    <div className="single-email-row1">
                                                                        <p dangerouslySetInnerHTML={{ __html: row.em_content }} />
                                                                    </div>
                                                                    {/* Attachements from firebase */}
                                                                    {row?.em_fb_urls?.length > 0 &&
                                                                        <div className="single-email-row1">
                                                                            <h1 className="sub-title">Attachments</h1>
                                                                        </div>}
                                                                    <div className="attachments-wrapper">
                                                                        <div className="attachments">
                                                                            {row?.em_fb_urls.map((attachment) => (
                                                                                <Chip
                                                                                    key={attachment._id}
                                                                                    label={attachment.doc_name}
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        e.preventDefault(); // Prevent default anchor behavior

                                                                                        try {
                                                                                            // Create a hidden link element for downloading
                                                                                            const link = document.createElement('a');
                                                                                            link.href = attachment.doc_url; // Use the URL directly

                                                                                            // Set the download attribute with the correct filename
                                                                                            link.setAttribute('download', `${attachment.doc_name}.${attachment.doc_type}`);

                                                                                            // Prevent navigation and ensure security best practices
                                                                                            link.setAttribute('rel', 'noopener noreferrer');

                                                                                            // Hide the link
                                                                                            link.style.display = 'none';

                                                                                            // Append to the document body
                                                                                            document.body.appendChild(link);

                                                                                            // Programmatically click the link to trigger download
                                                                                            link.click();

                                                                                            // Remove the link from the DOM
                                                                                            document.body.removeChild(link);

                                                                                        } catch (error) {
                                                                                            console.error('Error downloading the file:', error);
                                                                                        }
                                                                                    }}
                                                                                    className="attachment-chip"
                                                                                />
                                                                            ))}
                                                                        </div>


                                                                    </div>
                                                                    {/* Attachments saved in binary table */}
                                                                    {row?.em_binary_docs?.length > 0 &&
                                                                        <div className="single-email-row1">
                                                                            <h1 className="sub-title">Attachments</h1>
                                                                        </div>}
                                                                    <div className="attachments">
                                                                        {row?.em_binary_docs.map((attachment) => (
                                                                            <Chip
                                                                                key={attachment._id}
                                                                                label={attachment.doc_name}
                                                                                onClick={async (e) => {
                                                                                    e.preventDefault();
                                                                                    e.stopPropagation();
                                                                                    try {
                                                                                        const response = await newRequest.get(`/viewdocs/view/${attachment.doc_id}`, {
                                                                                            responseType: 'arraybuffer', // Ensure response is treated as binary data
                                                                                        });

                                                                                        // Create a blob from the binary data received
                                                                                        const blob = new Blob([response.data], { type: response.headers['content-type'] });
                                                                                        const url = URL.createObjectURL(blob);

                                                                                        // Create a blob URL from the response
                                                                                        // const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
                                                                                        // Open the PDF in a new tab
                                                                                        const newTab = window.open();
                                                                                        newTab.location.href = url;
                                                                                    } catch (error) {
                                                                                        console.log(error)
                                                                                    }
                                                                                }}
                                                                                className="attachment-chip"
                                                                            />
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <TablePagination
                                        rowsPerPageOptions={[5]}
                                        rowsPerPage={rowsPerPage}
                                        onRowsPerPageChange={handleEmailsChangeRowsPerPage}
                                        component="div"
                                        count={filteredEmailsData.length}
                                        page={emailPage}
                                        onPageChange={handleEmailsChangePage}
                                    />
                                </TableContainer>
                            </div>
                        }
                    </div>
                </div>
                <div className="corr-wrapper-separator" />
                <div className="corr-wrapper-sms">
                    <div className="corr-navbar-sms">
                        <div className="corr-navbar-sms-left">
                            <div className="search-input-wrapper" style={{ width: "250px" }}>
                                <SearchIcon className="search-input-icon" />
                                <input
                                    className="search-input"
                                    type="text"
                                    placeholder="Search..."
                                    value={searchTermSms}
                                    onChange={(e) => setSearchTermSms(e.target.value)}
                                />
                                {searchTermSms.length > 0 && (
                                    <CloseIcon
                                        onClick={handleClearSearchSms}
                                        className="search-input-icon"
                                    />
                                )}
                            </div></div>
                        <div className="corr-navbar-sms-right">
                            <CustomLoadingButton startIcon={<AddIcon />} onClick={() => {
                                try {
                                    setSmsData({
                                        to: "",
                                        subject: "",
                                        doc_no: correspondenceParams?.doc_no,
                                        doc_type: correspondenceParams?.doc_type,
                                        doc_id: correspondenceParams?.doc_id,
                                        entity_codes: correspondenceParams?.entity_codes,
                                        search_string: correspondenceParams?.doc_no,
                                    });
                                    setSmsDialogOpen(true);
                                } catch (error) {
                                    console.log(error)
                                }
                            }}>
                                SMS
                            </CustomLoadingButton>
                        </div>
                    </div>
                    <hr />
                    <div className="corr-content-sms">
                        <div className="table-content">
                            <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none' }}
                                className="table-container" style={{ width: "100%" }}>
                                <Table sx={{ width: "max-content", border: 'none' }}>
                                    <TableBody className="tablebody">
                                        {slicedSmssData?.map((row, index) => (
                                            <TableRow
                                                key={row._id}
                                                className={`${selectedSms?._id === row._id ? "tablerow current" : "tablerow"}`}
                                                onClick={() => {
                                                    try {
                                                        setSelectedSms(row);
                                                    } catch (error) {
                                                        console.log(error)
                                                    }
                                                }}
                                            >
                                                <TableCell className="table-cell" style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    width: "100%"
                                                }}>
                                                    <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", justifyContent: "space-between" }}>
                                                        <span style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                            <span style={{ display: "flex", width: "20px", fontWeight: "bold", alignItems: "center", justifyContent: "center" }}>{smsPage * rowsPerPage + index + 1}</span>
                                                            <span className="sms-no">{row.sms_to.join()}</span>
                                                        </span>
                                                        <span className="sms-time">
                                                            {`sent ${row.time_ago_xx}`}
                                                        </span>
                                                    </div>
                                                    <span className="sms-text">
                                                        {row.sms_content}
                                                    </span>

                                                    <div className="sms-from">
                                                        <span className="from">{`SENT By: ${row.updated_by_name_xx}`}</span>
                                                        {row?.updated_by_photo_url_xx ? <img
                                                            src={row?.updated_by_photo_url_xx}
                                                            alt=""
                                                            className="avatar"
                                                        /> : <BrokenImageOutlinedIcon style={{ width: "30px", height: "30px" }} />}
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[4]}
                                    rowsPerPage={rowsPerPageSms}
                                    onRowsPerPageChange={handleSmssChangeRowsPerPage}
                                    component="div"
                                    count={filteredSmssData?.length}
                                    rows={filteredSmssData}
                                    page={smsPage}
                                    onPageChange={handleSmssChangePage}
                                />
                            </TableContainer>
                        </div>
                    </div>
                </div>
            </motion.div>
            {/* email component */}
            {emailDialogOpen && (
                <motion.div
                    className="overlay-dialog"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 1 } }}
                    exit={{ opacity: 0, transition: { duration: 1 } }}
                >
                    <div className="overlay-wrapper">
                        <div className="overlay-navbar">
                            <span>EMAIL</span>
                            <span onClick={() => setEmailDialogOpen(false)}>
                                <IoClose
                                    style={{
                                        width: "30px",
                                        height: "30px",
                                        cursor: "pointer",
                                        borderRadius: "50%",
                                    }}
                                />
                            </span>
                        </div>
                        <div className="overlay-content">
                            <EmailComponent setEmailDialogOpen={setEmailDialogOpen} emailData={{ ...passedEmailData }} fetchEmails={fetchEmails} />
                        </div>
                    </div>
                </motion.div>
            )}
            {/* sms component */}
            {smsDialogOpen && (
                <motion.div
                    className="overlay-dialog"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 1 } }}
                    exit={{ opacity: 0, transition: { duration: 1 } }}
                >
                    <div className="overlay-wrapper">
                        <div className="overlay-navbar">
                            <span>SMS</span>
                            <span onClick={() => setSmsDialogOpen(false)}>
                                <IoClose
                                    style={{
                                        width: "30px",
                                        height: "30px",
                                        cursor: "pointer",
                                        borderRadius: "50%",
                                    }}
                                />
                            </span>
                        </div>
                        <div className="overlay-content">
                            <SmsComponent setSmsDialogOpen={setSmsDialogOpen} smsData={{ ...passedEmailData }} fetchSms={fetchSms} />
                        </div>
                    </div>
                </motion.div>
            )}

        </div>
    )
}

export default Correspondence