import "./SearchResults.scss";
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoClose } from "react-icons/io5";
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';
import { CustomButtonSmall } from "../../../components/ui/CustomButton";
import newRequest from "../../../utils/newRequest";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { GridLoader } from "react-spinners";
import PeopleIcon from '@mui/icons-material/People';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PaymentsIcon from '@mui/icons-material/Payments';
import ArticleIcon from '@mui/icons-material/Article';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import Badge from '@mui/material/Badge';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
} from "@mui/material";
import moment from "moment";
import numeral from "numeral";


const SearchResults = () => {
  const { currentUser } = useSelector((state) => state.user);
  const location = useLocation();
  const [searchInput, setSearchInput] = useState(location.state?.searchInput || '');
  const [searchFilter, setSearchFilter] = useState(location.state?.searchFilter || '');
  const navigate = useNavigate();
  const [searchedData, setSearchedData] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(6);
   const [entitiesPage, setEntitiesPage] = useState(0);
  const [receiptsPage, setReceiptsPage] = useState(0);
  const [paymentsPage, setPaymentsPage] = useState(0);
  const [riskNotesPage, setRiskNotesPage] = useState(0);
  const [vehiclesPage, setVehiclesPage] = useState(0);
  const [claimsPage, setClaimsPage] = useState(0);
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    if (location.state?.searchInput) {
      setSearchInput(location.state.searchInput);
    }
    if (location.state?.searchFilter) {
      setSearchFilter(location.state.searchFilter);
    }
  }, [location.state]);

  const goBack = () => {
    sessionStorage.setItem("searchActiveTab", JSON.stringify(0));
    navigate(-1);
  };

  const fetchDataBySearch = async (inputValue, searchFilter) => {
    try {
      setIsSearching(true)
      const res = await newRequest.post(
        "/gsearch/find",
        {
          org_code: currentUser.usr_org_code,
          search_input: inputValue,
          search_filter: searchFilter
        },
        config
      );
      // console.log(`setSearchedData  ${JSON.stringify(res.data, null, 2)}`);
      setSearchedData(res.data);
      if (searchFilter === "everything") {
        handleTabClick(0);
      } else if (searchFilter === "entities") {
        handleTabClick(0);
      } else if (searchFilter === "receipts") {
        handleTabClick(1);
      } else if (searchFilter === "payments") {
        handleTabClick(2);
      } else if (searchFilter === "risk notes") {
        handleTabClick(3);
      } else if (searchFilter === "vehicles") {
        handleTabClick(4);
      } else if (searchFilter === "claims") {
        handleTabClick(5);
      } else {
        handleTabClick(0);
      }


    } catch (error) {
      console.error("Error fetching entity types:", error);
    } finally {
      setIsSearching(false);
    }
  };

  useEffect(() => {
    if (location.state?.searchInput) {
      fetchDataBySearch(location.state.searchInput, location.state.searchFilter);
    }
  }, [location.state?.searchInput, location.state?.searchFilter]);

  const entitiesChangePage = (event, newPage) => {
    setEntitiesPage(newPage);
  };

  const entitiesRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 6));
    setEntitiesPage(0);
  };

  const slicedEntitiesData = searchedData.entities?.slice(
    entitiesPage * rowsPerPage,
    entitiesPage * rowsPerPage + rowsPerPage
  );

  // receipts
  const receiptsChangePage = (event, newPage) => {
    setReceiptsPage(newPage);
  };

  const receiptsRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 6));
    setReceiptsPage(0);
  };

  const slicedReceiptsData = searchedData.receipts?.slice(
    receiptsPage * rowsPerPage,
    receiptsPage * rowsPerPage + rowsPerPage
  );

  // payments
  const paymentsChangePage = (event, newPage) => {
    setPaymentsPage(newPage);
  };

  const paymentsRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 6));
    setPaymentsPage(0);
  };

  const slicedPaymentsData = searchedData.payments?.slice(
    paymentsPage * rowsPerPage,
    paymentsPage * rowsPerPage + rowsPerPage
  );


  // risk notes
  const riskNotesChangePage = (event, newPage) => {
    setRiskNotesPage(newPage);
  };

  const riskNotesRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 6));
    setRiskNotesPage(0);
  };

  const slicedRiskNotesData = searchedData.risk_notes?.slice(
    riskNotesPage * rowsPerPage,
    riskNotesPage * rowsPerPage + rowsPerPage
  );


  // vehicles
  const vehiclesChangePage = (event, newPage) => {
    setVehiclesPage(newPage);
  };

  const vehiclesRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 6));
    setVehiclesPage(0);
  };

  const slicedVehiclesData = searchedData.vehicles?.slice(
    vehiclesPage * rowsPerPage,
    vehiclesPage * rowsPerPage + rowsPerPage
  );

  // claims
  const claimsChangePage = (event, newPage) => {
    setClaimsPage(newPage);
  };

  const claimsRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 6));
    setClaimsPage(0);
  };

  const slicedClaimsData = searchedData.claims?.slice(
    claimsPage * rowsPerPage,
    claimsPage * rowsPerPage + rowsPerPage
  );

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    sessionStorage.setItem("searchActiveTab", JSON.stringify(index));
    setActiveTab(index);
  };

  useEffect(() => {
    const storedTab = JSON.parse(sessionStorage.getItem("searchActiveTab"));
    setActiveTab(storedTab !== null ? storedTab : 0);
  }, []);

  const iconMap = {
    "entities": <PeopleIcon />,
    "receipts": <ReceiptIcon />,
    "payments": <PaymentsIcon />,
    "risk_notes": <ArticleIcon />,
    "vehicles": <DirectionsCarIcon />,
    "claims": <LocalFireDepartmentIcon />
  };

  return (
    <div className="search-results">
      {isSearching ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "200px",
            width: "100%",
            height: "100%",
          }}
        >
          <GridLoader
            color="#F44336"
            style={{
              height: "50px",
              width: "50px",
            }}
          />
        </div>
      ) : (
        <div className="main-wrapper">
          <div className="content-wrapper">
            <span className="search-title">{searchInput ? `Search results for ${searchInput}` : 'No search query provided.'}</span>
            <div className="tabs-container">
              <div className="tab-header">
                {searchedData.tabs?.map((tab, index) => (
                  <div
                    key={index}
                    className={`${activeTab === index ? 'tab active' : 'tab'}`}
                    onClick={() => handleTabClick(index)}
                  >
                    <span className={`${activeTab === index ? 'tab-icon active' : 'tab-icon'}`}
                    >
                      {iconMap[tab.key]}
                    </span>
                    <span className={`${activeTab === index ? 'tab-title active' : 'tab-title'}`}>{tab.title}</span>
                    <Badge badgeContent={tab.count} showZero sx={{ '& .MuiBadge-badge': { backgroundColor: activeTab === index ? '#9c27b0' : '#c3c3c3', color: 'white' } }}
                      className="tab-badge" />
                  </div>
                ))}
              </div>
              {/* Entities */}
              {activeTab === 0 && <motion.div
                className="table-content"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 1 } }}
                exit={{ opacity: 0, transition: { duration: 1 } }}
              >
                {slicedEntitiesData?.length === 0 ? <div className="nothing-found"><h1>No Entities Found</h1></div> :
                  <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none' }}
                    className="table-container">
                    <Table sx={{ width: "max-content", border: 'none' }}>
                      <TableBody className="tablebody">
                        {slicedEntitiesData?.map((entity, index) => (
                          <TableRow
                            key={entity._id}
                            className="tablerow"
                            onClick={() => {
                              try {
                                navigate(`/entity/${entity._id}`, {
                                  state: { shouldFetchClients: false },
                                });
                              } catch (error) {
                                console.log(error)
                              }
                            }}
                          >
                            <TableCell className="table-cell"
                              style={{
                                display: "flex",
                                maxWidth: "50px",
                                alignItems: "center",
                                justifyContent: "center",
                                fontWeight: "bold",
                              }}>
                              <span>{entitiesPage * rowsPerPage + index + 1}</span>
                            </TableCell>
                            <TableCell className="table-cell image-cell" style={{
                              display: "flex",
                              maxWidth: "60px",
                              position: "relative",
                              padding: "0px",
                              overflow: "hidden"
                            }}>
                              {entity.ent_photo_url ? <img src={entity.ent_photo_url} alt="" className="image-cell-img" /> : <BrokenImageOutlinedIcon style={{
                                display: "flex",
                                width: "100%",
                                height: "100%",
                                color: "#ccc"
                              }} />}
                            </TableCell>
                            <TableCell className="table-cell" style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "250px"
                            }}>
                              <span>{entity.ent_name}</span>
                              <span style={{
                                fontWeight: "bold"
                              }}>  {entity.ent_code} </span>
                            </TableCell>
                            <TableCell className="table-cell" style={{
                              display: "flex",
                              flexDirection: "column",
                              maxWidth: "250px"
                            }}>
                              <span>{entity.aent_name_xx}</span>
                              <span style={{
                                fontWeight: "bold",
                                fontSize: "10px",
                                color: "#9c27b0"
                              }}> {entity.ent_type}</span>
                            </TableCell>
                            <TableCell className="table-cell" style={{
                              display: "flex",
                              flexDirection: "column",
                              maxWidth: "250px"
                            }}>
                              <span>
                                {entity.ent_phone_no}
                              </span>
                              <span>
                                {entity.ent_email}
                              </span>
                            </TableCell>
                            <TableCell className="table-cell" style={{
                              display: "flex",
                              maxWidth: "100px"
                            }}>
                              <span className={`status ${entity.ent_status}`}>
                                {entity.ent_status}
                              </span>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[6]}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={entitiesRowsPerPage}
                      component="div"
                      count={searchedData.entities?.length}
                      page={entitiesPage}
                      onPageChange={entitiesChangePage}
                    />
                  </TableContainer>}
              </motion.div>}
              {/* Receipts */}
              {activeTab === 1 && <motion.div
                className="table-content"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 1 } }}
                exit={{ opacity: 0, transition: { duration: 1 } }}
              >
                {slicedReceiptsData?.length === 0 ? <div className="nothing-found"><h1>No Receipts Found</h1></div> :
                  <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none' }}
                    className="table-container">
                    <Table sx={{ width: "max-content", border: 'none' }}>
                      <TableHead className="tablehead">
                        <TableRow
                          className="tablerow"
                        >
                          <TableCell className="table-cell" style={{
                            display: "flex",
                            maxWidth: "50px",
                            alignItems: "center",
                            justifyContent: "center",
                            fontWeight: "bold",
                          }}>
                            <span>#</span>
                          </TableCell>
                          <TableCell className="table-cell" style={{
                            display: "flex",
                            maxWidth: "150px"
                          }}>
                            <span>Receipt No</span>
                          </TableCell>
                          <TableCell className="table-cell" style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "250px"
                          }}>
                            <span>From</span>
                          </TableCell>
                          <TableCell className="table-cell" style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "150px"
                          }}>
                            <span>Received Towards/Branch</span>
                          </TableCell>
                          <TableCell className="table-cell" style={{
                            display: "flex",
                            maxWidth: "150px"
                          }}>
                            <span>Date</span>
                          </TableCell>
                          <TableCell className="table-cell" style={{
                            alignItems: "right", display: "flex",
                          }}>
                            <span style={{
                              textAlign: "right"
                            }}>Amounts</span>
                          </TableCell>
                          <TableCell className="table-cell" style={{
                            display: "flex",
                            maxWidth: "100px"
                          }}>
                            <span>Status</span>
                          </TableCell>
                          <TableCell className="table-cell">
                            <span>Info</span>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className="tablebody">
                        {slicedReceiptsData?.map((receipt, index) => (
                          <TableRow
                            key={receipt._id}
                            className="tablerow"
                            onClick={() => {
                              try {
                                navigate(`/receipt/${receipt._id}`, {
                                  state: { shouldFetchClients: false },
                                });
                              } catch (error) {
                                console.log(error)
                              }
                            }}
                          >
                            <TableCell className="table-cell"
                              style={{
                                display: "flex",
                                maxWidth: "50px",
                                alignItems: "center",
                                justifyContent: "center",
                                fontWeight: "bold",
                              }}>
                              <span>{receiptsPage * rowsPerPage + index + 1}</span>
                            </TableCell>
                            <TableCell className="table-cell" style={{
                              display: "flex",
                              maxWidth: "150px"
                            }}>
                              <span style={{
                                fontWeight: "bold"
                              }}>  {receipt.hd_no} </span>
                              <span>{`Ref: ${receipt?.hd_ref_no}`}</span>
                            </TableCell>
                            <TableCell className="table-cell" style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "250px"
                            }}>
                              <span>{receipt.client_name_xx}</span>
                              <span style={{
                                fontWeight: "bold",
                                fontSize: "10px",
                                color: "#9c27b0"
                              }}> {receipt.hd_mode}</span>
                            </TableCell>

                            <TableCell className="table-cell" style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "150px"
                            }}>
                              <span>{receipt.hd_paying_for}</span>
                              <span style={{
                                fontWeight: "bold",
                                fontSize: "10px",
                                color: "#9c27b0"
                              }}> {receipt.bizunit_name_xx}</span>
                            </TableCell>

                            <TableCell className="table-cell" style={{
                              display: "flex",
                              flexDirection: "column",
                              maxWidth: "150px"
                            }}>
                              <span>
                                {moment(receipt?.hd_gl_date).format("DD-MMM-YYYY")}
                              </span>
                            </TableCell>
                            <TableCell className="table-cell" style={{
                              alignItems: "right",
                              display: "flex",
                              flexDirection: "column"
                            }}>
                              <span style={{
                                textAlign: "right",
                                fontWeight: "bold",
                                fontSize: "10px",
                                color: "#f44336"
                              }}>{receipt.hd_cur_code}</span>
                              <span style={{
                                textAlign: "right",
                                fontWeight: "bold"
                              }}>{`Received ${numeral(
                                receipt.hd_fc_amount
                              ).format("0,0.00")}`}</span>
                              <span
                                style={{
                                  textAlign: "right",
                                  fontWeight: "bold",
                                }}
                              >{`Allocated ${numeral(
                                receipt.allocated_fc_amount_xx
                              ).format("0,0.00")}`}</span>
                            </TableCell>

                            <TableCell className="table-cell" style={{
                              display: "flex",
                              maxWidth: "100px"
                            }}>
                              <span className={`status ${receipt.hd_status}`}>
                                {receipt.hd_status}
                              </span>
                              <span style={{
                                fontWeight: "bold",
                                fontSize: "10px",
                                color: "#9c27b0"
                              }}> {receipt.hd_chq_no}</span>
                            </TableCell>
                            <TableCell className="table-cell" style={{
                              alignItems: "right", display: "flex",
                              flexDirection: "column",
                              fontSize: "12px",
                              fontWeight: "bold"
                            }}>
                              <span>{`Received By : ${receipt.created_by_name_xx}`}</span>
                              <span> {receipt?.time_ago_xx}</span>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[6]}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={receiptsRowsPerPage}
                      component="div"
                      count={searchedData.receipts?.length}
                      page={receiptsPage}
                      onPageChange={receiptsChangePage}
                    />
                  </TableContainer>
                }
              </motion.div>}
              {/* Payments */}
              {activeTab === 2 && <motion.div
                className="table-content"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 1 } }}
                exit={{ opacity: 0, transition: { duration: 1 } }}
              >
                {slicedReceiptsData?.length === 0 ? <div className="nothing-found"><h1>No Payments Found</h1></div> :
                  <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none' }}
                    className="table-container">
                    <Table sx={{ width: "max-content", border: 'none' }}>
                      <TableHead className="tablehead">
                        <TableRow
                          className="tablerow"
                        >
                          <TableCell className="table-cell" style={{
                            display: "flex",
                            maxWidth: "50px",
                            alignItems: "center",
                            justifyContent: "center",
                            fontWeight: "bold",
                          }}>
                            <span>#</span>
                          </TableCell>
                          <TableCell className="table-cell" style={{
                            display: "flex",
                            maxWidth: "150px"
                          }}>
                            <span>Payment No</span>
                          </TableCell>
                          <TableCell className="table-cell" style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "250px"
                          }}>
                            <span>Client</span>
                          </TableCell>
                          <TableCell className="table-cell" style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "150px"
                          }}>
                            <span>Paying For/Branch</span>
                          </TableCell>
                          <TableCell className="table-cell" style={{
                            display: "flex",
                            maxWidth: "150px"
                          }}>
                            <span>Date</span>
                          </TableCell>
                          <TableCell className="table-cell" style={{
                            alignItems: "right", display: "flex",
                          }}>
                            <span style={{
                              textAlign: "right"
                            }}>Amounts</span>
                          </TableCell>
                          <TableCell className="table-cell" style={{
                            display: "flex",
                            maxWidth: "100px"
                          }}>
                            <span>Status</span>
                          </TableCell>
                          <TableCell className="table-cell">
                            <span>Info</span>
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody className="tablebody">
                        {slicedPaymentsData?.map((payment, index) => (
                          <TableRow
                            key={payment._id}
                            className="tablerow"
                            onClick={() => {
                              try {
                                navigate(`/payment/${payment._id}`, {
                                  state: { shouldFetchClients: false },
                                });
                              } catch (error) {
                                console.log(error)
                              }
                            }}
                          >
                            <TableCell className="table-cell"
                              style={{
                                display: "flex",
                                maxWidth: "50px",
                                alignItems: "center",
                                justifyContent: "center",
                                fontWeight: "bold",
                              }}>
                              <span>{receiptsPage * rowsPerPage + index + 1}</span>
                            </TableCell>
                            <TableCell className="table-cell" style={{
                              display: "flex",
                              maxWidth: "150px"
                            }}>
                              <span style={{
                                fontWeight: "bold"
                              }}>  {payment.hd_no} </span>
                              <span>{`Ref: ${payment?.hd_ref_no}`}</span>
                            </TableCell>
                            <TableCell className="table-cell" style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "250px"
                            }}>
                              <span>{payment.client_name_xx}</span>
                              <span style={{
                                fontWeight: "bold",
                                fontSize: "10px",
                                color: "#9c27b0"
                              }}> {payment.hd_mode}</span>
                            </TableCell>

                            <TableCell className="table-cell" style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "150px"
                            }}>
                              <span>{payment.hd_paying_for}</span>
                              <span style={{
                                fontWeight: "bold",
                                fontSize: "10px",
                                color: "#9c27b0"
                              }}> {payment.bizunit_name_xx}</span>
                            </TableCell>

                            <TableCell className="table-cell" style={{
                              display: "flex",
                              flexDirection: "column",
                              maxWidth: "150px"
                            }}>
                              <span>
                                {moment(payment?.hd_gl_date).format("DD-MMM-YYYY")}
                              </span>
                            </TableCell>
                            <TableCell className="table-cell" style={{
                              alignItems: "right",
                              display: "flex",
                              flexDirection: "column"
                            }}>
                              <span style={{
                                textAlign: "right",
                                fontWeight: "bold",
                                fontSize: "10px",
                                color: "#f44336"
                              }}>{payment.hd_cur_code}</span>
                              <span style={{
                                textAlign: "right",
                                fontWeight: "bold"
                              }}>{`Received ${numeral(
                                payment.hd_fc_amount
                              ).format("0,0.00")}`}</span>
                              <span
                                style={{
                                  textAlign: "right",
                                  fontWeight: "bold",
                                }}
                              >{`Allocated ${numeral(
                                payment.allocated_fc_amount_xx
                              ).format("0,0.00")}`}</span>
                            </TableCell>

                            <TableCell className="table-cell" style={{
                              display: "flex",
                              maxWidth: "100px"
                            }}>
                              <span className={`status ${payment.hd_status}`}>
                                {payment.hd_status}
                              </span>
                              <span style={{
                                fontWeight: "bold",
                                fontSize: "10px",
                                color: "#9c27b0"
                              }}> {payment.hd_chq_no}</span>
                            </TableCell>
                            <TableCell className="table-cell" style={{
                              alignItems: "right", display: "flex",
                              flexDirection: "column",
                              fontSize: "12px",
                              fontWeight: "bold"
                            }}>
                              <span>{`Paid By : ${payment.created_by_name_xx}`}</span>
                              <span> {payment?.time_ago_xx}</span>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[6]}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={paymentsRowsPerPage}
                      component="div"
                      count={searchedData.payments?.length}
                      page={paymentsPage}
                      onPageChange={paymentsChangePage}
                    />
                  </TableContainer>
                }
              </motion.div>}
              {/* Risk Notes */}
              {activeTab === 3 && <motion.div
                className="table-content"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 1 } }}
                exit={{ opacity: 0, transition: { duration: 1 } }}
              >
                {slicedRiskNotesData?.length === 0 ? <div className="nothing-found"><h1>No Risk Notes Found</h1></div> :
                  <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none' }}
                    className="table-container">
                    <Table sx={{ width: "max-content", border: 'none' }}>
                      <TableHead className="tablehead">
                        <TableRow
                          className="tablerow"
                        >
                          <TableCell className="table-cell" style={{
                            display: "flex",
                            maxWidth: "50px",
                            alignItems: "center",
                            justifyContent: "center",
                            fontWeight: "bold",
                          }}>
                            <span>#</span>
                          </TableCell>
                          <TableCell className="table-cell" style={{
                            display: "flex",
                            maxWidth: "200px"
                          }}>
                            <span>Risk Note</span>
                          </TableCell>
                          <TableCell className="table-cell">
                            <span>Insured</span>
                          </TableCell>
                          <TableCell className="table-cell">
                            <span>Carrier</span>
                          </TableCell>
                          <TableCell className="table-cell" style={{
                            display: "flex",
                            maxWidth: "150px"
                          }}>
                            <span>Period</span>
                          </TableCell>
                          <TableCell className="table-cell" style={{
                            alignItems: "right", display: "flex",
                          }}>
                            <span style={{
                              textAlign: "right"
                            }}>Amounts</span>
                          </TableCell>
                          <TableCell className="table-cell" style={{
                            display: "flex",
                            maxWidth: "100px"
                          }}>
                            <span>Status</span>
                          </TableCell>
                          <TableCell className="table-cell">
                            <span>Info</span>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className="tablebody">
                        {slicedRiskNotesData?.map((policy, index) => (
                          <TableRow
                            key={policy._id}
                            className="tablerow"
                            onClick={() => {
                              try {
                                navigate(`/uw/rn/${policy._id}`, {
                                  state: policy,
                                });
                              } catch (error) {
                                console.log(error)
                              }
                            }}
                          >
                            <TableCell className="table-cell"
                              style={{
                                display: "flex",
                                maxWidth: "50px",
                                alignItems: "center",
                                justifyContent: "center",
                                fontWeight: "bold",
                              }}>
                              <span>{riskNotesPage * rowsPerPage + index + 1}</span>
                            </TableCell>
                            <TableCell className="table-cell" style={{
                              display: "flex",
                              maxWidth: "200px"
                            }}>
                              <span style={{
                                fontWeight: "bold"
                              }}>  {policy.pl_risk_note_no} </span>
                              <span>  {policy.pl_no} </span>
                            </TableCell>

                            <TableCell className="table-cell" style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "250px"
                            }}>
                              <span>{policy.insured_name_xx}</span>
                            </TableCell>

                            <TableCell className="table-cell" style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "250px"
                            }}>
                              <span>{policy.carrier_name_xx}</span>
                            </TableCell>

                            <TableCell className="table-cell" style={{
                              display: "flex",
                              flexDirection: "column",
                              maxWidth: "150px"
                            }}>
                              <span>
                                {moment(policy?.pl_fm_date).format("DD-MMM-YYYY")}
                              </span>
                              <span>
                                {moment(policy?.pl_to_date).format("DD-MMM-YYYY")}
                              </span>

                              <span style={{
                                fontWeight: "bold",
                                fontSize: "10px",
                                color: "#9c27b0"
                              }}>{`${policy.difference_in_days > 1 ? `${policy.difference_in_days} days` : `${policy.difference_in_days} day`}`}</span>

                            </TableCell>

                            <TableCell className="table-cell" style={{
                              alignItems: "right",
                              display: "flex",
                              flexDirection: "column"
                            }}>
                              <span style={{
                                textAlign: "right",
                                fontWeight: "bold",
                                fontSize: "10px",
                                color: "#f44336"
                              }}>{policy.pl_cur_code}</span>
                              <span style={{
                                textAlign: "right",
                                fontWeight: "bold"
                              }}>{`Premium ${numeral(
                                policy.total_fc_premium
                              ).format("0,0.00")}`}</span>
                              <span
                                style={{
                                  textAlign: "right",
                                  fontWeight: "bold",
                                }}
                              >{`Commission ${numeral(
                                policy.commission_fc_amount -
                                policy.commission_tax_fc_amount
                              ).format("0,0.00")}`}</span>
                            </TableCell>
                            <TableCell className="table-cell" style={{
                              display: "flex",
                              maxWidth: "100px"
                            }}>
                              <span className={`status ${policy.actual_status}`}>
                                {policy.actual_status}
                              </span>
                              <span style={{
                                fontWeight: "bold",
                                fontSize: "10px",
                                color: "#9c27b0"
                              }}> {policy.expiring_text}</span>
                            </TableCell>
                            <TableCell className="table-cell" style={{
                              alignItems: "right", display: "flex",
                              flexDirection: "column",
                              fontSize: "12px",
                              fontWeight: "bold"
                            }}>
                              <span> {policy.product_name_xx} </span>
                              <span
                                style={{ fontWeight: "500" }}
                              >{policy.end_name_xx}</span>
                              <span>{`Issued ${policy?.time_ago_xx}`}</span>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[6]}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={riskNotesRowsPerPage}
                      component="div"
                      count={searchedData.risk_notes?.length}
                      page={riskNotesPage}
                      onPageChange={riskNotesChangePage}
                    />
                  </TableContainer>
                }
              </motion.div>}
              {/* Vehicles */}
              {activeTab === 4 && <motion.div
                className="table-content"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 1 } }}
                exit={{ opacity: 0, transition: { duration: 1 } }}
              >
                {slicedVehiclesData?.length === 0 ? <div className="nothing-found"><h1>No Vehicle Found</h1></div> :
                  <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none' }}
                    className="table-container">
                    <Table sx={{ width: "max-content", border: 'none' }}>
                      <TableHead className="tablehead">
                        <TableRow
                          className="tablerow"
                        >
                          <TableCell className="table-cell" style={{
                            display: "flex",
                            maxWidth: "50px",
                            alignItems: "center",
                            justifyContent: "center",
                            fontWeight: "bold",
                          }}>
                            <span>#</span>
                          </TableCell>
                          <TableCell className="table-cell" style={{
                            display: "flex",
                            maxWidth: "200px"
                          }}>
                            <span>Risk Note</span>
                          </TableCell>
                          <TableCell className="table-cell">
                            <span>Insured</span>
                          </TableCell>
                          <TableCell className="table-cell">
                            <span>Carrier</span>
                          </TableCell>
                          <TableCell className="table-cell" style={{
                            display: "flex",
                            maxWidth: "150px"
                          }}>
                            <span>Period</span>
                          </TableCell>
                          <TableCell className="table-cell" style={{
                            alignItems: "right", display: "flex",
                          }}>
                            <span style={{
                              textAlign: "right"
                            }}>Amounts</span>
                          </TableCell>
                          <TableCell className="table-cell" style={{
                            display: "flex",
                            maxWidth: "100px"
                          }}>
                            <span>Status</span>
                          </TableCell>
                          <TableCell className="table-cell">
                            <span>Info</span>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className="tablebody">
                        {slicedVehiclesData?.map((policy, index) => (
                          <TableRow
                            key={policy._id}
                            className="tablerow"
                            onClick={() => {
                              try {
                                navigate(`/uw/rn/${policy._id}`, {
                                  state: policy,
                                });
                              } catch (error) {
                                console.log(error)
                              }
                            }}
                          >
                            <TableCell className="table-cell"
                              style={{
                                display: "flex",
                                maxWidth: "50px",
                                alignItems: "center",
                                justifyContent: "center",
                                fontWeight: "bold",
                              }}>
                              <span>{vehiclesPage * rowsPerPage + index + 1}</span>
                            </TableCell>
                            <TableCell className="table-cell" style={{
                              display: "flex",
                              maxWidth: "200px"
                            }}>
                              <span style={{
                                fontWeight: "bold"
                              }}>  {policy.pl_risk_note_no} </span>
                              <span>  {policy.pl_no} </span>
                            </TableCell>
                            <TableCell className="table-cell" style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "250px"
                            }}>
                              <span>{policy.insured_name_xx}</span>
                            </TableCell>
                            <TableCell className="table-cell" style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "250px"
                            }}>
                              <span>{policy.carrier_name_xx}</span>
                            </TableCell>
                            <TableCell className="table-cell" style={{
                              display: "flex",
                              flexDirection: "column",
                              maxWidth: "150px"
                            }}>
                              <span>
                                {moment(policy?.pl_fm_date).format("DD-MMM-YYYY")}
                              </span>
                              <span>
                                {moment(policy?.pl_to_date).format("DD-MMM-YYYY")}
                              </span>

                              <span style={{
                                fontWeight: "bold",
                                fontSize: "10px",
                                color: "#9c27b0"
                              }}>{`${policy.difference_in_days > 1 ? `${policy.difference_in_days} days` : `${policy.difference_in_days} day`}`}</span>

                            </TableCell>

                            <TableCell className="table-cell" style={{
                              alignItems: "right",
                              display: "flex",
                              flexDirection: "column"
                            }}>
                              <span style={{
                                textAlign: "right",
                                fontWeight: "bold",
                                fontSize: "10px",
                                color: "#f44336"
                              }}>{policy.pl_cur_code}</span>
                              <span style={{
                                textAlign: "right",
                                fontWeight: "bold"
                              }}>{`Premium ${numeral(
                                policy.total_fc_premium
                              ).format("0,0.00")}`}</span>
                              <span
                                style={{
                                  textAlign: "right",
                                  fontWeight: "bold",
                                }}
                              >{`Commission ${numeral(
                                policy.commission_fc_amount -
                                policy.commission_tax_fc_amount
                              ).format("0,0.00")}`}</span>
                            </TableCell>
                            <TableCell className="table-cell" style={{
                              display: "flex",
                              maxWidth: "100px"
                            }}>
                              <span className={`status ${policy.actual_status}`}>
                                {policy.actual_status}
                              </span>
                              <span style={{
                                fontWeight: "bold",
                                fontSize: "10px",
                                color: "#9c27b0"
                              }}> {policy.expiring_text}</span>
                            </TableCell>
                            <TableCell className="table-cell" style={{
                              alignItems: "right", display: "flex",
                              flexDirection: "column",
                              fontSize: "12px",
                              fontWeight: "bold"
                            }}>
                              <span> {policy.product_name_xx} </span>
                              <span
                                style={{ fontWeight: "500" }}
                              >{policy.end_name_xx}</span>
                              <span>{`Issued ${policy?.time_ago_xx}`}</span>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[6]}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={vehiclesRowsPerPage}
                      component="div"
                      count={searchedData.vehicles?.length}
                      page={vehiclesPage}
                      onPageChange={vehiclesChangePage}
                    />
                  </TableContainer>
                }
              </motion.div>}
              {/* Claims */}
              {activeTab === 5 && <motion.div
                className="table-content"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 1 } }}
                exit={{ opacity: 0, transition: { duration: 1 } }}
              >
                {slicedVehiclesData?.length === 0 ? <div className="nothing-found"><h1>No Claim Found</h1></div> :
                  <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none' }}
                    className="table-container">
                    <Table sx={{ width: "max-content", border: 'none' }}>
                      <TableHead className="tablehead">
                        <TableRow
                          className="tablerow"
                        >
                          <TableCell className="table-cell" style={{
                            display: "flex",
                            maxWidth: "50px",
                            alignItems: "center",
                            justifyContent: "center",
                            fontWeight: "bold",
                          }}>
                            <span>#</span>
                          </TableCell>
                          <TableCell className="table-cell" style={{
                            display: "flex",
                            maxWidth: "200px"
                          }}>
                            <span>Claim/Policy No</span>
                          </TableCell>
                          <TableCell className="table-cell">
                            <span>Insured</span>
                          </TableCell>
                          <TableCell className="table-cell">
                            <span>Carrier</span>
                          </TableCell>
                          <TableCell className="table-cell" style={{
                            display: "flex",
                            maxWidth: "150px"
                          }}>
                            <span>Intimation & Loss Date</span>
                          </TableCell>
                          <TableCell className="table-cell" style={{
                            alignItems: "right", display: "flex",
                          }}>
                            <span style={{
                              textAlign: "right"
                            }}>Premium</span>
                          </TableCell>
                          <TableCell className="table-cell" style={{
                            display: "flex",
                            maxWidth: "100px"
                          }}>
                            <span>Status</span>
                          </TableCell>
                          <TableCell className="table-cell">
                            <span>Info</span>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className="tablebody">
                        {slicedClaimsData?.map((claim, index) => (
                          <TableRow
                            key={claim._id}
                            className="tablerow"
                            onClick={() => {
                              try {
                                navigate(`/cm/${claim._id}`, {
                                  state: claim,
                                });
                              } catch (error) {
                                console.log(error)
                              }
                            }}
                          >
                            <TableCell className="table-cell"
                              style={{
                                display: "flex",
                                maxWidth: "50px",
                                alignItems: "center",
                                justifyContent: "center",
                                fontWeight: "bold",
                              }}>
                              <span>{claimsPage * rowsPerPage + index + 1}</span>
                            </TableCell>
                            <TableCell className="table-cell" style={{
                              display: "flex",
                              maxWidth: "200px"
                            }}>
                              <span style={{
                                fontWeight: "bold"
                              }}>  {claim.cm_claim_no} </span>
                              <span>  {claim.cm_policy_no} </span>
                            </TableCell>

                            <TableCell className="table-cell" style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "250px"
                            }}>
                              <span>{claim.insured_name_xx}</span>
                              <span style={{
                                alignItems: "right", display: "flex",
                                flexDirection: "column",
                                fontSize: "12px",
                                fontWeight: "bold"
                              }}>{claim?.cm_risk_code}</span>
                            </TableCell>
                            <TableCell className="table-cell" style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "250px"
                            }}>
                              <span>{claim.carrier_name_xx}</span>
                            </TableCell>
                            <TableCell className="table-cell" style={{
                              display: "flex",
                              flexDirection: "column",
                              maxWidth: "150px"
                            }}>
                              <span>
                                {moment(claim?.cm_intimation_date).format("DD-MMM-YYYY")}
                              </span>
                              <span>
                                {moment(claim?.cm_loss_date).format("DD-MMM-YYYY")}
                              </span>
                            </TableCell>
                            <TableCell className="table-cell" style={{
                              alignItems: "right",
                              display: "flex",
                              flexDirection: "column"
                            }}>
                              <span style={{
                                textAlign: "right",
                                fontWeight: "bold",
                                fontSize: "10px",
                                color: "#f44336"
                              }}>{claim.currency_xx}</span>
                              <span style={{
                                textAlign: "right",
                                fontWeight: "bold"
                              }}>{`Premium ${numeral(
                                claim.policy_fc_premium
                              ).format("0,0.00")}`}</span>
                              <span
                                style={{
                                  textAlign: "right",
                                  fontWeight: "bold",
                                }}
                              >{`Paid ${numeral(
                                claim.total_paid_fc_amount
                              ).format("0,0.00")}`}</span>
                            </TableCell>
                            <TableCell className="table-cell" style={{
                              display: "flex",
                              maxWidth: "100px"
                            }}>
                              <span className={`status ${claim.cm_status}`}>
                                {claim.cm_status}
                              </span>
                              <span style={{
                                fontWeight: "bold",
                                fontSize: "10px",
                                color: "#9c27b0"
                              }}> {claim.bizunit_name_xx}</span>
                            </TableCell>
                            <TableCell className="table-cell" style={{
                              alignItems: "right", display: "flex",
                              flexDirection: "column",
                              fontSize: "12px",
                              fontWeight: "bold"
                            }}>
                              <span> {claim.product_name_xx} </span>
                              <span
                                style={{ fontWeight: "500" }}
                              >{claim.end_name_xx}</span>
                              <span>{`Created ${claim?.time_ago_xx}`}</span>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[6]}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={claimsRowsPerPage}
                      component="div"
                      count={searchedData.claims?.length}
                      page={claimsPage}
                      onPageChange={claimsChangePage}
                    />
                  </TableContainer>
                }
              </motion.div>}
            </div>
          </div>
          <div className="summary-wrapper">
            <div className="title">
              <h1>SUMMARY</h1>
              <CustomButtonSmall startIcon={<IoClose />} onClick={goBack}>
                Close
              </CustomButtonSmall>
            </div>
            <div className="summary">
              <h1>{`Found ${searchedData?.total_results} results`}</h1>
              <div className="summary-breakdown">
                {searchedData.tabs?.map((tab, index) => (
                  <div
                    key={index}
                    onClick={() => handleTabClick(index)}
                  >
                    <span className={`${activeTab === index ? 'single-title active' : 'single-title'}`}>{`${tab.title} ${tab.count}`}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchResults;
