import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { CustomButton } from "../ui/CustomButton";

function ErrorDialog({ open, handleClose, errorMessage }) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Error</DialogTitle>
      <DialogContent>
        <DialogContentText>{errorMessage}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={handleClose} color="primary">
          Close
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}

export default ErrorDialog;
