import "./TermsAndConditions.scss";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

const TermsAndConditions = () => {
  return (
    <div className="tac">
      <Container maxWidth="md">
        <Typography variant="h4" gutterBottom>
          Terms and Conditions
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to [Your Company Name]! These terms and conditions outline the
          rules and regulations for the use of [Your Company Name]'s Website,
          located at [Your Website URL].
        </Typography>

        <Typography variant="h5" gutterBottom>
          Interpretation and Definitions
        </Typography>
        <Typography variant="body1" paragraph>
          Interpretation
        </Typography>
        <Typography variant="body1" paragraph>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </Typography>

        <Typography variant="body1" paragraph>
          Definitions
        </Typography>
        <Typography variant="body1" paragraph>
          For the purposes of these Terms and Conditions:
        </Typography>
        <Typography variant="body1" paragraph>
          - Company (referred to as either "the Company", "We", "Us" or "Our" in
          this Agreement) refers to [Your Company Name], [Your Address].
        </Typography>
        <Typography variant="body1" paragraph>
          - Service refers to the Website.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Accounts
        </Typography>
        <Typography variant="body1" paragraph>
          When you create an account with us, you must provide us information
          that is accurate, complete, and current at all times. Failure to do so
          constitutes a breach of the Terms, which may result in immediate
          termination of your account on our Service.
        </Typography>

        {/* More terms and conditions content can be added here */}
      </Container>
    </div>
  );
};

export default TermsAndConditions;
