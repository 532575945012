

const VideoPlayer = ({ videoUrl }) => (
    <div className="video-wrapper">
        <iframe
            width="520"
            height="380"
            src={videoUrl}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Instructional Video"
        />
    </div>
);

export default VideoPlayer;
