import { useSelector } from "react-redux";
import "./UpcomingEvents.scss"
import { useEffect, useState } from "react";
import newRequest from "../../utils/newRequest";
import { useNavigate } from "react-router-dom";


const UpcomingEvents = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();
  const fetchEvents = async () => {
    try {
      const res = await newRequest.get("/allEntityManager/acc");
      setEvents(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, [currentUser]);

  return (
    <div className="upcoming-events">
      <div className="upcoming-events-navbar">
        <span className="upcoming-events-title">Upcoming Events</span>
        <span className="upcoming-events-view-all">View All</span>
      </div>
      {events?.length === 0 &&
        <div className="upcoming-events-content">
          <span style={{ fontWeight: "300", fontSize: "18px" }}>{`No events found`}</span>
        </div>
      }
      {events?.length > 0 &&
        <div className="upcoming-events-content">
          {events?.map((row) => (
            <div className="upcoming-events-card" key={row._id}>
              <div className="upcoming-events-card-navbar">
                <span className="upcoming-events-card-title">{row.event_type}</span>
                <span className="upcoming-events-card-time">{row.event_ent_in}</span>
              </div>
              <div className="upcoming-events-card-desc">
                <span dangerouslySetInnerHTML={{ __html: row.event_ent_msg }} />
              </div>
              <div className="upcoming-events-card-footer">
                <span className="more" onClick={() => {
                  try {
                    navigate(`/central/${row.event_ent_doc_id}`, {
                      state: { shouldFetchClients: false },
                    });
                  } catch (error) {
                    console.log(error)
                  }
                }}>More...</span>
              </div>
            </div>
          ))}
        </div>
      }
    </div>
  )
}

export default UpcomingEvents