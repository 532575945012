import "./MyCalendar.scss";
import newRequest from "../../utils/newRequest.js";
import { IoClose } from "react-icons/io5";
import { motion } from "framer-motion";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { GridLoader } from "react-spinners";
import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import CircularButton, { CustomButton } from "../../components/ui/CustomButton.jsx";
import MyTemplateThree from "../myTemplateThree/MyTemplateThree.jsx";
import DocumentViewer from "../../components/documentViewer/DocumentViewer.jsx";
import DocumentList from "../../components/documentViewer/DocumentList.jsx";



const MyCalendar = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const [isSearching, setIsSearching] = useState(false);
  const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
  const [notifyDialogTitle, setNotifyDialogTitle] = useState("Confirm");
  const [notifyDialogText, setNotifyDialogText] = useState("Text");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };


  const goBack = () => {
    navigate(-1);
  };


  const handleCloseNotifyDialog = () => {
    setIsNotifyDialogOpen(false);
  };


  return (
    // <MyTe÷mplateThree />
    <div style={{ display: "flex", flexDirection: "column" }} >
      <DocumentList />
    </div>
  );
};

export default MyCalendar;
