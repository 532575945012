import { useSelector } from "react-redux";
import "./AllFilesList.scss";
import ExportToExcelButton from "../../components/exportToExcelButton/ExportToExcelButton";
import { CustomButton } from "../../components/ui/CustomButton";
import newRequest from "../../utils/newRequest";
import ConfirmDialog from "../confirmDialog/confirmDialog";
import { Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import { motion } from "framer-motion";
import numeral from "numeral";
import { useEffect, useState } from "react";
import { BiSolidFileTxt } from "react-icons/bi";
import { BiSolidFileJpg } from "react-icons/bi";
import { BiSolidFilePng } from "react-icons/bi";
import { BsFiletypeMp4 } from "react-icons/bs";
import { FaFileWord } from "react-icons/fa";
import { BsFileEarmarkExcelFill } from "react-icons/bs";

import { FaFilePdf } from "react-icons/fa6";
import { MdFolderZip } from "react-icons/md";
import { PiFilePptFill } from "react-icons/pi";
import { useLocation, useNavigate } from "react-router-dom";
import { GridLoader, ScaleLoader } from "react-spinners";

const AllFilesList = () => {
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filesData, setFilesData] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [objectToAction, setObjectToAction] = useState({
    object_id: "",
    action_text: "",
    file_path: "",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogAction, setConfirmDialogAction] = useState("None");
  // columns to export
  const exportColumns = [
    "insured_name_xx",
    "pl_quote_no",
    "product_name_xx",
    "pl_status",
    "quote_stage_xx",
    "total_fc_si",
    "total_fc_premium",
    "commission_fc_amount",
    "commission_tax_fc_amount",
    "bizunit_name_xx",
  ];

  const fetchFiles = async () => {
    try {
      setIsLoading(true);
      const res = await newRequest.post(
        "/files/findAll",
        {
          fl_org_code: currentUser.usr_org_code,
        },
        config
      );
      setFilesData(res.data);
      console.log(`${JSON.stringify(res.data, null, 2)}`);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  useEffect(() => {
    const filteredResults = filesData.data?.filter(
      (item) =>
        item.fl_doc_type?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.uploaded_by_name_xx
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        item.fl_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.fl_category
          ?.toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        item.fl_doc_no?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.entity_name_xx?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.fl_doc_type?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filteredResults);
  }, [filesData.data, searchTerm]);

  const handleConfirm = async () => {
    try {
      if (confirmDialogAction === "deleteFile") {
        setIsConfirmDialogOpen(false);
        setIsProcessing(true);
        // delete file from db
        // this function will also delete from firebase
        const res = await newRequest.delete(
          `/files/del/${objectToAction.object_id}`,
          {},
          config
        );
        const filteredArray = filesData.filter(
          (obj) => obj._id !== objectToAction.object_id
        );
        setFilesData(filteredArray);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsProcessing(false);
      // Close the dialog
      setIsConfirmDialogOpen(false);
    }
  };

  const handleCancel = () => {
    setIsConfirmDialogOpen(false);
  };

  return (
    <motion.div
      className="all-files-list"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      <div className="all-files-list-navbar">
        <div className="navbar-left">
          <div className="search-input-wrapper">
            <SearchIcon className="search-input-icon" />
            <input
              className="search-input"
              type="text"
              placeholder="Search..."
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className="navbar-right">
          <CustomButton startIcon={<RefreshIcon />} onClick={fetchFiles}>
            Refresh
          </CustomButton>
          <ExportToExcelButton
            data={filteredData?.data}
            exportColumns={exportColumns}
            fileName="Files.xlsx"
          />
        </div>
      </div>
      <div className="content">
        {isLoading ? (
          <div className="no-content"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <ScaleLoader
              color="#F44336"
              height={50}
              radius={8}
              width={4}
            />
          </div>
        ) : (
          <>
            <div className="grand-summary">
              <div className="summary-row">
                <span>{`Total Files Found ${filesData.grandSummary.totalCount
                  } Totaling ${numeral(filesData.grandSummary.totalSizeMb).format(
                    "0,0.00"
                  )} MB`}</span>
              </div>
              {/* <div className="grouped-by-catg">Grouped by category</div>
            <div className="grouped-by-type">Grouped by type</div> */}
            </div>
            <div className="all-files-wrapper">
              {filteredData?.map((singleFile) => (
                <div
                  className="single-file"
                  key={singleFile._id}
                  onClick={() => {
                    try {
                      window.open(singleFile.fl_path, "_blank");
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                >
                  <div className="single-file-title">
                    <h2 className="file-name">{singleFile.fl_name}</h2>
                    {singleFile.fl_type === "pdf" && <FaFilePdf className="logo" color="red" />}
                    {singleFile.fl_type === "docx" && <FaFileWord className="logo" color="#3f51b5" />}
                    {singleFile.fl_type === "doc" && <FaFileWord className="logo" color="#3f51b5" />}
                    {singleFile.fl_type === "xlsx" && <BsFileEarmarkExcelFill className="logo" color="#1b5e20" />}
                    {singleFile.fl_type === "xls" && <BsFileEarmarkExcelFill className="logo" color="#1b5e20" />}
                    {singleFile.fl_type === "txt" && (
                      <BiSolidFileTxt className="logo" />
                    )}
                    {singleFile.fl_type === "jpg" && (
                      <BiSolidFileJpg className="logo" color="#d84315" />
                    )}
                    {singleFile.fl_type === "png" && (
                      <BiSolidFilePng className="logo" color="#1b5e20" />
                    )}
                    {singleFile.fl_type === "mp4" && (
                      <BsFiletypeMp4 className="logo" color="#ffff00" />
                    )}

                    {singleFile.fl_type === "zip" && <MdFolderZip className="logo" color="#3e2723" />}

                    {singleFile.fl_type === "rar" && <MdFolderZip className="logo" color="#3e2723" />}
                    {singleFile.fl_type === "pptx" && (
                      <PiFilePptFill className="logo" color="#9c27b0" />
                    )}
                  </div>
                  <div className="file-category">
                    <span className="title">{singleFile.fl_category}</span>
                    {singleFile.createdBy === currentUser.usr_id && (
                      <Tooltip
                        title={`Delete ${singleFile.fl_name}`}
                        placement="left"
                        style={{
                          backgroundColor: "blue",
                          color: "white",
                        }}
                      >
                        <DeleteIcon
                          size={50}
                          style={{
                            borderRadius: "5px",
                            padding: "3px",
                            alignSelf: "center",
                            cursor: "pointer",
                            color: "rgba(244, 67, 54)",
                            backgroundColor: "rgba(244, 67, 54, 0.5)",
                          }}
                          onClick={(e) => {
                            try {
                              e.stopPropagation();
                              let action_text = `Delete ${singleFile.fl_name}?`;
                              setObjectToAction({
                                object_id: singleFile._id,
                                action_text: action_text,
                                file_path: singleFile.fl_path,
                              });
                              setConfirmDialogAction("deleteFile");
                              setIsConfirmDialogOpen(true);
                            } catch (error) {
                              console.log(error);
                            }
                          }}
                        />
                      </Tooltip>
                    )}
                  </div>

                  <div className="uploaded-by-wrapper">
                    <div className="uploaded-by-user">
                      <span>{`Uploaded By ${singleFile.uploaded_by_name_xx}`}</span>
                      <img
                        src={
                          singleFile?.uploaded_by_photo_url_xx || "/img/noavatar.jpg"
                        }
                        alt=""
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>{singleFile.time_ago_xx}</span>
                      <span>{singleFile.file_size}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>

        )}
      </div>
      {/* Confirm Dialog */}
      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        dialogTitle="Confirmation"
        dialogContent={<p>{objectToAction.action_text}</p>}
      />
    </motion.div>
  );
};

export default AllFilesList;
