import "./Announcements.scss"
const Announcements = () => {
  return (
    <div className="announcements">
      <div className="announcements-navbar">
        <span className="announcements-title">Announcements</span>
        <span className="announcements-view-all">View All</span>
      </div>
      <div className="announcements-content">
        <div className="announcements-card">
          <div className="announcements-card-navbar">
            <span className="announcements-card-title"> Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel nam quidem tempora,</span>
            <span className="announcements-card-time">4 hours ago</span>
          </div>
          <div className="announcements-card-desc">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel nam quidem tempora, quibusdam qui quos dolorem doloremque quas, soluta suscipit delectus magni possimus sed quam optio reprehenderit nostrum id consectetur.
          </div>
          <div className="announcements-card-footer">
            <span className="more">More...</span>
          </div>
        </div>

        <div className="announcements-card">
          <div className="announcements-card-navbar">
            <span className="announcements-card-title">Second</span>
            <span className="announcements-card-time">4 hours ago</span>
          </div>
          <div className="announcements-card-desc">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel nam quidem tempora, quibusdam qui quos dolorem doloremque quas, soluta suscipit delectus magni possimus sed quam optio reprehenderit nostrum id consectetur.
          </div>
          <div className="announcements-card-footer">
            <span className="more">More...</span>
          </div>
        </div>

        <div className="announcements-card">
          <div className="announcements-card-navbar">
            <span className="announcements-card-title">Third</span>
            <span className="announcements-card-time">4 hours ago</span>
          </div>
          <div className="announcements-card-desc">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel nam quidem tempora, quibusdam qui quos dolorem doloremque quas, soluta suscipit delectus magni possimus sed quam optio reprehenderit nostrum id consectetur.
          </div>
          <div className="announcements-card-footer">
            <span className="more">More...</span>
          </div>
        </div>

        <div className="announcements-card">
          <div className="announcements-card-navbar">
            <span className="announcements-card-title">Fourth</span>
            <span className="announcements-card-time">4 hours ago</span>
          </div>
          <div className="announcements-card-desc">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel nam quidem tempora, quibusdam qui quos dolorem doloremque quas, soluta suscipit delectus magni possimus sed quam optio reprehenderit nostrum id consectetur.
          </div>
          <div className="announcements-card-footer">
            <span className="more">More...</span>
          </div>
        </div>


      </div>
    </div>
  )
}

export default Announcements