import "./UwCommRates.scss";
import { CustomLoadingButton } from "../../../components/ui/CustomButton";
import app from "../../../utils/firebase";
import newRequest from "../../../utils/newRequest";
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from "@mui/icons-material/Close";
import { FaSave } from "react-icons/fa";
import SearchIcon from "@mui/icons-material/Search";
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary
} from "@mui/material";
import { showToast } from "../../../toastService.js";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GridLoader } from "react-spinners";
import { SlArrowDown } from "react-icons/sl";
import { formatStringToNumberV2 } from "../../../utils/genericFunctions";
import { customSelectStyles } from "../../../components/ui/uiStyleElements.jsx";
import PublishIcon from "@mui/icons-material/Publish";
import { CustomButton } from "../../../components/ui/CustomButton";
import Select from "react-select";


const UwCommRates = () => {
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const [searchTermEntities, setSearchTermEntities] = useState("");
  const [entitiesPage, setEntitiesPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [commissionRates, setCommissionRates] = useState([]);
  const [selectedEntityCatg, setSelectedEntityCatg] = useState({});
  const [entityCatgOptions, setEntityCatgOptions] = useState([]);

  const fetchEntityCatg = async () => {
    try {
      const res = await newRequest.post(
        "/allEntityCatg/findAll",
        {
          aent_org_code: currentUser.usr_org_code,
        }
      );

      const options = res.data.map((entityCatg) => ({
        value: entityCatg.aent_code,
        label: entityCatg.aent_name,
      }));

      setEntityCatgOptions(options);
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };

  useEffect(() => {
    fetchEntityCatg();
  }, []);

  const fetchCommissionRates = async () => {
    try {
      setIsProcessing(true);
      const res = await newRequest.post(
        "/uwPolicy/commRates",
        {
          ent_aent_code: selectedEntityCatg?.value
        }
      );
      setCommissionRates(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleEntitiesChangePage = (event, newPage) => {
    setEntitiesPage(newPage);
  };

  const handleEntitiesChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setEntitiesPage(0);
  };

  const handleClearSearchEntities = () => {
    setSearchTermEntities("");
  };

  const filteredEntitiesData = commissionRates.filter(
    (item) =>
      item.ent_name?.toLowerCase().includes(searchTermEntities.toLowerCase()) ||
      item.ent_id_no?.toLowerCase().includes(searchTermEntities.toLowerCase()) ||
      item.ent_tax_no?.toLowerCase().includes(searchTermEntities.toLowerCase()) ||
      item.ent_phone_no?.toLowerCase().includes(searchTermEntities.toLowerCase()) ||
      item.ent_email?.toLowerCase().includes(searchTermEntities.toLowerCase())
  );

  const slicedEntitiesData = filteredEntitiesData.slice(
    entitiesPage * rowsPerPage,
    entitiesPage * rowsPerPage + rowsPerPage
  );
  // commission rates table
  const [searchTermRates, setSearchTermRates] = useState("");
  const [savingTitle, setSavingTitle] = useState("SAVE");
  const [ratesPage, setRatesPage] = useState(0);
  const [rowsPerPageRates, setRowsPerPageRates] = useState(10);
  const [entityCommissionRates, setEntityCommissionRates] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [selectedRatesRow, setSelectedRatesRow] = useState(null);
  const [selectedEntity, setSelectedEntity] = useState(null);

  const handleAccodionChange = (panel, entity) => (event, isExpanded) => {
    try {
      setExpanded(isExpanded ? panel : false);
      setSelectedEntity(isExpanded ? entity : null);
      setEntityCommissionRates(entity.comm_rates);
      console.log(`entity  ${JSON.stringify(entity, null, 2)}`);
    } catch (error) {
      console.log(error)
    }
  };

  const handleRatesChangePage = (event, newPage) => {
    setRatesPage(newPage);
  };

  const handleRatesChangeRowsPerPage = (event) => {
    setRowsPerPageRates(parseInt(event.target.value, 8));
    setRatesPage(0);
  };

  const handleClearSearchRates = () => {
    setSearchTermRates("");
  };

  const filteredRatesData = entityCommissionRates.filter(
    (item) =>
      item.cc_code?.toLowerCase().includes(searchTermRates.toLowerCase()) ||
      item.cc_name?.toLowerCase().includes(searchTermRates.toLowerCase()) ||
      item.class_name_xx?.toLowerCase().includes(searchTermRates.toLowerCase()) ||
      item.sub_class_name_xx?.toLowerCase().includes(searchTermRates.toLowerCase())
  );

  const slicedRatesData = filteredRatesData.slice(
    ratesPage * rowsPerPage,
    ratesPage * rowsPerPage + rowsPerPage
  );

  const handleRatesChange = (e, rowId) => {
    try {
      const { name, value } = e.target;
      const updatedData = [...entityCommissionRates];
      const rowIndex = updatedData.findIndex(item => item._id === rowId);

      if (rowIndex === -1) {
        throw new Error('Row not found');
      }
      updatedData[rowIndex][name] = value;
      updatedData[rowIndex]["changed_xx"] = "Y";

      setEntityCommissionRates(updatedData);
    } catch (error) {
      console.log(error);
    }
  };

  const formatNumber = (value, decimals = 2) => {
    try {
      if (isNaN(formatStringToNumberV2(value))) return value;
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: decimals
      }).format(value);
    } catch (error) {
      console.log(error)
    }
  };


  const handleRatesBlur = (rowId, field) => {
    try {
      const updatedData = [...entityCommissionRates];
      const rowIndex = updatedData.findIndex(item => item._id === rowId);

      if (rowIndex === -1) {
        throw new Error('Row not found');
      }
      let value = updatedData[rowIndex][field];
      value = formatNumber(formatStringToNumberV2(value));
      updatedData[rowIndex][field] = value;
      setEntityCommissionRates(updatedData);
    } catch (error) {
      console.log(error);
    }
  };

  const saveRecord = async () => {
    try {
      const changedRates = entityCommissionRates?.filter(
        (obj) => obj.changed_xx === "Y"
      );
      for (let i = 0; i < changedRates.length; i++) {
        // console.log(`changedRates[i]/inject  ${JSON.stringify(changedRates[i], null, 2)}`);
        const res = await newRequest.post("/uwCoverCommRate/inject", {
          cr_mc_code: changedRates[i].cc_mc_code,
          cr_sc_code: changedRates[i].cc_sc_code,
          cr_cc_code: changedRates[i].cc_code,
          cr_ent_code: selectedEntity.ent_code,
          cr_aent_code: selectedEntity.ent_aent_code,
          cr_comm_rate: formatStringToNumberV2(changedRates[i].comm_rate_xx),
          cr_comm_tax_rate: formatStringToNumberV2(changedRates[i].comm_tax_rate_xx),
          cr_prem_rate: formatStringToNumberV2(changedRates[i].prem_rate_xx)
        });
      }
      showToast("Saved!", "success");
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <motion.div
      className="comm-rates"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      <div className="navbar">
        <div className="navbar-left">
          <div className="search-input-wrapper">
            <SearchIcon className="search-input-icon" />
            <input
              className="search-input"
              type="text"
              placeholder="Search..."
              value={searchTermEntities}
              onChange={(e) => setSearchTermEntities(e.target.value)}
            />
            {searchTermEntities.length > 0 && (
              <CloseIcon
                onClick={handleClearSearchEntities}
                className="search-input-icon"
              />
            )}
          </div>
          <Select
            id="ent_catg"
            name="ent_catg"
            styles={customSelectStyles}
            width="300px"
            value={selectedEntityCatg}
            onChange={(selectedOption) => {
              try {
                setSelectedEntityCatg(selectedOption);
              } catch (error) {
                console.log(error);
              }
            }}
            options={entityCatgOptions}
            isSearchable={true}
            placeholder="Category"
          />
          <CustomButton
            startIcon={<SendIcon />}
            onClick={fetchCommissionRates}
          >
            Search
          </CustomButton>
        </div>
        <div className="navbar-right">
          <span className="save-note">If you change any rate, ensure you click SAVE</span>
        </div>
      </div>
      {slicedEntitiesData?.length > 0 &&
        <div className="table-content">
          <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none' }}
            className="table-container">
            <Table sx={{ width: "max-content", border: 'none' }}>
              <TableBody className="tablebody-accordion">
                {slicedEntitiesData?.map((entity, index) => (
                  <TableRow
                    key={entity._id}
                    className="tablerow-accordion">
                    <TableCell className="table-cell-accordion"
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: "bold",
                      }}>
                      <Accordion className="accordion" expanded={expanded === entity._id} onChange={handleAccodionChange(entity._id, entity)}>
                        <AccordionSummary
                          className="accordion-summary"
                          expandIcon={<SlArrowDown className="content-accordion-expand-icon" style={{ color: "#f44336", fontWeight: "bold" }} />}
                          id={`panel-${entity._id}-header`}
                        >
                          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%", marginRight: "50px" }}>
                            <Typography>
                              <span>
                                <span className="sr-no">{entitiesPage * rowsPerPage + index + 1}.</span> <span>{entity.ent_name}</span>
                              </span>
                            </Typography>
                            <Typography>
                              <span>
                                <span className="account-no">{`Account No : ${entity.ent_code}`}</span>
                                <span className={`ent-status ${entity.ent_status}`}>
                                  {entity.ent_status}
                                </span>
                              </span>
                            </Typography>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails className="accordion-details">
                          <div className="search">
                            <div className="navbar">
                              <div className="navbar-left">
                                <div className="search-input-wrapper">
                                  <SearchIcon className="search-input-icon" />
                                  <input
                                    className="search-input"
                                    type="text"
                                    placeholder="Search..."
                                    value={searchTermRates}
                                    onChange={(e) => setSearchTermRates(e.target.value)}
                                  />
                                  {searchTermRates.length > 0 && (
                                    <CloseIcon
                                      onClick={handleClearSearchRates}
                                      className="search-input-icon"
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="navbar-right">
                                <CustomLoadingButton startIcon={<FaSave />} onClick={saveRecord}>
                                  {savingTitle}
                                </CustomLoadingButton>
                              </div>
                            </div>
                          </div>
                          <div className="table-content">
                            <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none' }} className="table-container">
                              <Table sx={{ width: "max-content", border: 'none' }}>
                                <TableHead className="tablehead">
                                  <TableRow className="tablerow">
                                    <TableCell className="table-cell" style={{ fontWeight: "bold", display: "flex", maxWidth: "50px" }}>
                                      <span>#</span>
                                    </TableCell>
                                    <TableCell className="table-cell" style={{ fontWeight: "bold", display: "flex", alignItems: "flex-start", justifyContent: "flex-start", maxWidth: "150px" }}>
                                      <span>CLASS</span>
                                    </TableCell>
                                    <TableCell className="table-cell" style={{ fontWeight: "bold", maxWidth: "250px" }}>
                                      <span>SUB CLASS</span>
                                    </TableCell>
                                    <TableCell className="table-cell" style={{ fontWeight: "bold", width: "auto", display: 'flex', }}>
                                      <span>COVER</span>
                                    </TableCell>
                                    <TableCell className="table-cell" style={{ fontWeight: "bold", alignItems: "center", maxWidth: "150px", display: 'flex', justifyContent: 'flex-end' }}>
                                      <span style={{ alignSelf: "flex-end" }}>COMMISSION [%]</span>
                                    </TableCell>
                                    <TableCell className="table-cell" style={{ fontWeight: "bold", alignItems: "center", maxWidth: "150px", display: 'flex', justifyContent: 'flex-end' }}>
                                      <span style={{ alignSelf: "flex-end" }}>COMM TAX [%]</span>
                                    </TableCell>
                                    <TableCell className="table-cell" style={{ fontWeight: "bold", alignItems: "center", maxWidth: "150px", display: 'flex', justifyContent: 'flex-end' }}>
                                      <span style={{ alignSelf: "flex-end" }}>PREMIUM [%]</span>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody className="tablebody">
                                  {slicedRatesData?.map((row, index) => (
                                    <TableRow
                                      key={row._id}
                                      className={`${selectedRatesRow?._id === row._id ? "tablerow current" : "tablerow"}`}
                                      onClick={() => {
                                        try {
                                          setSelectedRatesRow(row)
                                        } catch (error) {
                                          console.log(error)
                                        }
                                      }}
                                    >
                                      <TableCell className="table-cell"
                                        style={{
                                          fontWeight: "bold",
                                          display: "flex",
                                          maxWidth: "50px",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          fontWeight: "bold",
                                        }}>
                                        <span>{ratesPage * rowsPerPageRates + index + 1}</span>
                                      </TableCell>
                                      <TableCell className="table-cell" style={{ fontWeight: "bold", display: "flex", alignItems: "flex-start", justifyContent: "flex-start", maxWidth: "150px" }}>
                                        <span>{`${row.class_name_xx} - [${row.cc_mc_code}]`}</span>
                                      </TableCell>
                                      <TableCell className="table-cell" style={{ fontWeight: "bold", maxWidth: "250px" }}>
                                        <span>{`${row.sub_class_name_xx} - [${row.cc_sc_code}]`}</span>
                                      </TableCell>
                                      <TableCell className="table-cell" style={{ fontWeight: "bold", width: "auto", display: 'flex', }}>
                                        <span>{`${row.cc_name} - [${row.cc_code}]`}</span>
                                      </TableCell>
                                      <TableCell className="table-cell" style={{ fontWeight: "bold", alignItems: "center", maxWidth: "150px", display: 'flex', justifyContent: 'flex-end' }}>
                                        <input
                                          id="comm_rate_xx"
                                          name="comm_rate_xx"
                                          type="text"
                                          // value={formatNumber(row.comm_rate_xx, 4)}
                                          value={row.comm_rate_xx}
                                          onChange={(e) => handleRatesChange(e, row._id)}
                                          style={{ width: "100%", textAlign: "right" }}
                                          className="form-input-item"
                                          onBlur={() => {
                                            handleRatesBlur(row._id, "comm_rate_xx");
                                          }}
                                          disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                                        />
                                      </TableCell>
                                      <TableCell className="table-cell" style={{ fontWeight: "bold", alignItems: "center", maxWidth: "150px", display: 'flex', justifyContent: 'flex-end' }}>
                                        <input
                                          id="comm_tax_rate_xx"
                                          name="comm_tax_rate_xx"
                                          type="text"
                                          // value={formatNumber(row.comm_tax_rate_xx, 4)}
                                          value={row.comm_tax_rate_xx}
                                          onChange={(e) => handleRatesChange(e, row._id)}
                                          style={{ width: "100%", textAlign: "right" }}
                                          className="form-input-item"
                                          onBlur={() => {
                                            handleRatesBlur(row._id, "comm_tax_rate_xx");
                                          }}
                                          disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                                        />
                                      </TableCell>
                                      <TableCell className="table-cell" style={{ fontWeight: "bold", alignItems: "center", maxWidth: "150px", display: 'flex', justifyContent: 'flex-end' }}>
                                        <input
                                          id="prem_rate_xx"
                                          name="prem_rate_xx"
                                          type="text"
                                          // value={formatNumber(row.prem_rate_xx, 4)}
                                          value={row.prem_rate_xx}
                                          onChange={(e) => handleRatesChange(e, row._id)}
                                          style={{ width: "100%", textAlign: "right" }}
                                          className="form-input-item"
                                          onBlur={() => {
                                            handleRatesBlur(row._id, "prem_rate_xx");
                                          }}
                                          disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                              <TablePagination
                                rowsPerPageOptions={[10]}
                                rowsPerPage={rowsPerPageRates}
                                onRowsPerPageChange={handleRatesChangeRowsPerPage}
                                component="div"
                                count={filteredRatesData.length}
                                page={ratesPage}
                                onPageChange={handleRatesChangePage}
                              />

                            </TableContainer>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10]}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleEntitiesChangeRowsPerPage}
              component="div"
              count={filteredEntitiesData.length}
              page={entitiesPage}
              onPageChange={handleEntitiesChangePage}
            />
          </TableContainer>
        </div>
      }

      {
        isProcessing && (
          <motion.div
            className="processing-dialog"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 1 } }}
            exit={{ opacity: 0, transition: { duration: 1 } }}
          >
            <div className="processing-wrapper">
              <GridLoader
                color="#F44336"
                style={{
                  height: "50px",
                  width: "50px",
                }}
              />
            </div>
          </motion.div>
        )
      }
    </motion.div>
  );
};

export default UwCommRates;
