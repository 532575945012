import "./UwHome.scss";
import AllFilesList from "../../../components/allFIlesList/AllFilesList.jsx";
import AdSysCode from "../../ad/adSysCode/AdSysCode.jsx";
import AllEntityCatg from "../../all/allEntityCatg/AllEntityCatg.jsx";
import AllEntityHome from "../../all/allEntityHome/AllEntityHome.jsx";
import GlCalendar from "../../gl/glCalendar/GlCalendar.jsx";
import GlCurrency from "../../gl/glCurrency/GlCurrency.jsx";
import UwSubClass from "../uwSubClass/UwSubClass.jsx";
import UwDb from "../uwDb/UwDb.jsx";
import UwProductHome from "../uwProductHome/UwProductHome.jsx";
import UwRegister from "../uwRegister/UwRegister.jsx";
import UwRenewal from "../uwRenewal/UwRenewal.jsx";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import GlStatement from "../../gl/glStatement/GlStatement.jsx";
import UwPolicyList from "../uwPolicyList/UwPolicyList.jsx";
import UwCommRates from "../uwCommRates/UwCommRates.jsx";
import { CustomLoadingButton } from "../../../components/ui/CustomButton.jsx";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const UwHome = ({ menus }) => {
  const location = useLocation();
  const { state } = location;
  const roleMenus = state && state.roleMenus ? state.roleMenus : [];
  const [isCollapsed, setIsCollapsed] = useState(false);

  const [homeView, setHomeView] = useState(() => {
    const initialView = {
      menu_id: roleMenus[0].group_menus[0]._id,
      menu_function: roleMenus[0].group_menus[0].menu_function,
    };
    const storedView = sessionStorage.getItem("UwHome-homeView");
    return storedView ? JSON.parse(storedView) : initialView;
  });

  useEffect(() => {
    sessionStorage.setItem("UwHome-homeView", JSON.stringify(homeView));
  }, [homeView]);

  // console.log(`homeView  ${JSON.stringify(homeView, null, 2)}`);
  const handleNavigation = (menuItem) => {
    try {
      // console.log(`menuItem  ${JSON.stringify(menuItem, null, 2)}`);
      setHomeView({
        menu_id: menuItem._id,
        menu_function: menuItem.menu_function,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const toggleLeftWrapper = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="uw-home">
      <div className="uw-home-main-wrapper">
        <div className="left-wrapper">
          <div className="left-content">
            <CustomLoadingButton startIcon={isCollapsed ? <AddIcon /> : <RemoveIcon />} onClick={() => setIsCollapsed(!isCollapsed)}></CustomLoadingButton>
            <div className="menu">
              {roleMenus.map((item) => (
                <div className="item" key={item._id}>
                  {!isCollapsed && <span className="title">{item.menu_group_name}</span>}
                  {isCollapsed && <span className="title">OTH</span>}
                  {item.group_menus.map((listItem) => (
                    <button
                      onClick={() => handleNavigation(listItem)}
                      className={`${homeView.menu_id === listItem._id
                        ? "listItem active"
                        : "listItem"
                        }`}
                      key={listItem._id}
                    >
                      <img src={listItem.icon} alt="" />
                      {!isCollapsed && <span className="listItemTitle">{listItem.menu_name}</span>}
                      {isCollapsed && <span className="listItemTitle">{<AddIcon />}</span>}
                    </button>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="center-wrapper">
          {homeView.menu_function === "policyHome" && <UwPolicyList />}
          {homeView.menu_function === "uwDb" && <UwDb />}
          {homeView.menu_function === "clientsHome" && <AllEntityHome />}
          {homeView.menu_function === "uwRenewal" && <UwRenewal />}
          {homeView.menu_function === "uwRegister" && <UwRegister />}
          {homeView.menu_function === "allFiles" && <AllFilesList />}
          {homeView.menu_function === "uwSubClass" && <UwSubClass />}
          {homeView.menu_function === "uwProductHome" && <UwProductHome />}
          {homeView.menu_function === "sysCodes" && <AdSysCode />}
          {homeView.menu_function === "glCalendar" && <GlCalendar />}
          {homeView.menu_function === "glCurrency" && <GlCurrency />}
          {homeView.menu_function === "entityCatg" && <AllEntityCatg />}
          {homeView.menu_function === "glStatement" && <GlStatement />}
          {homeView.menu_function === "defaultRates" && <UwCommRates />}
        </div>
      </div>
    </div>
  );
};

export default UwHome;
