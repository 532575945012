import "./UwRenewal.scss";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { motion } from "framer-motion";
import moment from "moment";
import numeral from "numeral";
import {
  CustomButton,
  CustomButtonGradient,
} from "../../../components/ui/CustomButton";
import SearchIcon from "@mui/icons-material/Search";
import { customSelectStyles } from "../../../components/ui/uiStyleElements";
import newRequest from "../../../utils/newRequest";
import Select from "react-select";

const UwRenewal = () => {
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const [fmDate, setFmDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  // products
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const fetchProducts = async () => {
    try {
      const params = {
        pr_org_code: currentUser.usr_org_code,
      };
      const res = await newRequest.post("/uwProduct/findAll", params, config);
      // Transform the data to match the format expected by react-select
      const options = res.data.map((product) => ({
        value: product.pr_code,
        label: product.pr_name,
      }));

      options.unshift({ value: "All", label: "All" });

      setProducts(options);
      // set default product
      setSelectedProduct({ value: "All", label: "All" });
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };

  // biz unit
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [branches, setBranches] = useState([]);
  const fetchBranches = async () => {
    try {
      const params = {
        unit_org_code: currentUser.usr_org_code,
      };
      const res = await newRequest.post("/orgUnit", params, config);

      const options = res.data.map((branch) => ({
        value: branch.unit_code,
        label: branch.unit_name,
      }));

      options.unshift({ value: "All", label: "All" });
      setBranches(options);
      setSelectedBranch({ value: "All", label: "All" });
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };
  // insurance company
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [carriers, setCarriers] = useState([]);
  const fetchCarriers = async () => {
    try {
      const params = {
        ent_org_code: currentUser.usr_org_code,
      };
      const res = await newRequest.post("/allEntity/findAll", params, config);
      // Transform the data to match the format expected by react-select
      const options = res.data.map((entity) => ({
        aent_code: entity.ent_aent_code,
        value: entity.ent_code,
        label: entity.ent_name,
      }));
      options.unshift({ aent_code: "All", label: "All", label: "All" });
      setCarriers(options);
      setSelectedCarrier({ aent_code: "All", value: "All", label: "All" });
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };

  const setDates = () => {
    try {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();

      // Beginning of the year
      const beginningOfYear = new Date(currentYear, 0, 1);
      beginningOfYear.setFullYear(currentYear); // Set full year again to handle leap years
      const formattedBeginningOfYear = beginningOfYear
        .toISOString()
        .split("T")[0];

      // End of the year
      const endOfYear = new Date(currentYear + 1, 0, 0);
      const formattedEndOfYear = endOfYear.toISOString().split("T")[0];

      // Beginning of the month
      const beginningOfMonth = new Date(currentYear, currentMonth, 1);
      const formattedBeginningOfMonth = beginningOfMonth
        .toISOString()
        .split("T")[0];

      // End of the month
      const endOfMonth = new Date(currentYear, currentMonth + 1, 0);
      endOfMonth.setDate(endOfMonth.getDate() + 1); // Add one day to get the actual end of the month
      const formattedEndOfMonth = endOfMonth.toISOString().split("T")[0];

      // Formatting current date and one year later
      const formattedCurrentDate = currentDate.toISOString().split("T")[0];
      const oneYearLater = new Date(currentDate);
      oneYearLater.setFullYear(currentYear + 1);
      const formattedOneYearLater = oneYearLater.toISOString().split("T")[0];

      // console.log("Current Date:", formattedCurrentDate);
      // console.log("Beginning of the Year:", formattedBeginningOfYear);
      // console.log("End of the Year:", formattedEndOfYear);
      // console.log("Beginning of the Month:", formattedBeginningOfMonth);
      // console.log("End of the Month:", formattedEndOfMonth);
      // console.log("One Year Later:", formattedOneYearLater);

      // Set these values to your state or do whatever you want with them
      setFmDate(formattedBeginningOfMonth);
      setToDate(formattedEndOfMonth);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setDates();
    fetchProducts();
    fetchBranches();
    fetchCarriers();
  }, []);

  const handleFromDateChange = (e) => {
    try {
      if (e.target.value) {
        const formattedFromDate = moment(e.target.value).format("YYYY-MM-DD");
        setFmDate(formattedFromDate);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleToDateChange = (e) => {
    try {
      if (e.target.value) {
        const formattedToDate = moment(e.target.value).format("YYYY-MM-DD");
        setToDate(formattedToDate);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetRenewals = async () => {
    try {
      console.log("fmDate:", fmDate);
      console.log("toDate:", toDate);
      console.log("product value:", selectedProduct.value);
      console.log("product label:", selectedProduct.label);
      console.log("branch value:", selectedBranch.value);
      console.log("branch label:", selectedBranch.label);
      console.log("selectedCarrier aent_code:", selectedCarrier.aent_code);
      console.log("selectedCarrier label:", selectedCarrier.label);
      console.log("selectedCarrier value:", selectedCarrier.value);

      const params = {
        pl_org_code: currentUser.usr_org_code,
        gl_fm_date: fmDate,
        gl_to_date: toDate,
        pl_product_code:
          selectedProduct.value === "All" ? "" : selectedProduct.value,
        pl_biz_unit: selectedBranch.value === "All" ? "" : selectedBranch.value,
        pl_ins_ent_code:
          selectedCarrier.value === "All" ? "" : selectedBranch.value,
      };

      const res = await newRequest.post("/uwPolicy/renewals", params, config);

      console.log(`res  ${JSON.stringify(res.data, null, 2)}`);
    } catch (error) {
      console.log(error);
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <motion.div
      className="uw-renewal"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      <div className="content">
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "200px",
            }}
          >
            <CircularProgress
              style={{
                height: "50px",
                width: "50px",
              }}
            />
          </div>
        ) : (
          <div className="content-wrapper">
            <div className="content-wrapper-selection">
              <h1>Renewals</h1>
              <h3>Enter filtering criterial and click get renewals to view</h3>
              <div className="filter">
                <div className="dates">
                  <div className="input-item-with-label">
                    <label
                      htmlFor="fm_date"
                      className="form-dropdown-label"
                      style={{ width: "130px" }}
                    >
                      FROM
                    </label>
                    <input
                      id="fm_date"
                      name="fm_date"
                      type="date"
                      placeholder="FROM"
                      value={fmDate}
                      className="form-input-item"
                      style={{ width: "200px" }}
                      onChange={handleFromDateChange}
                    />
                  </div>
                  <div className="input-item-with-label">
                    <label
                      htmlFor="to_date"
                      className="form-dropdown-label"
                      style={{ width: "130px" }}
                    >
                      TO
                    </label>
                    <input
                      id="to_date"
                      name="to_date"
                      type="date"
                      placeholder="TO"
                      value={toDate}
                      className="form-input-item"
                      style={{ width: "200px" }}
                      onChange={handleToDateChange}
                    />
                  </div>
                </div>
                <div className="biz-unit">
                  <div className="input-item-with-label biz_unit">
                    <label htmlFor="biz_unit" style={{ width: "130px" }}>
                      BIZ UNIT
                    </label>
                    <Select
                      id="biz_unit"
                      name="biz_unit"
                      styles={customSelectStyles}
                      width="250px"
                      value={selectedBranch}
                      onChange={(selectedOption) => {
                        try {
                          setSelectedBranch(selectedOption);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                      options={branches}
                      isSearchable={true}
                      placeholder="BIZ UNIT"
                    />
                  </div>
                  <div className="input-item-with-label">
                    <label htmlFor="product_code" style={{ width: "130px" }}>
                      PRODUCT
                    </label>
                    <Select
                      id="product_code"
                      name="product_code"
                      styles={customSelectStyles}
                      width="250px"
                      value={selectedProduct}
                      onChange={(selectedOption) => {
                        try {
                          setSelectedProduct(selectedOption);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                      options={products}
                      isSearchable={true}
                      placeholder="PRODUCT"
                    />
                  </div>
                </div>
                <div className="carrier">
                  <div className="input-item-with-label">
                    <label htmlFor="carrier" style={{ width: "130px" }}>
                      CARRIER
                    </label>
                    <Select
                      id="carrier"
                      name="carrier"
                      styles={customSelectStyles}
                      width="300px"
                      value={selectedCarrier}
                      onChange={(selectedOption) => {
                        try {
                          setSelectedCarrier(selectedOption);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                      options={carriers}
                      isSearchable={true}
                      placeholder="Carrier"
                    />
                  </div>
                  <div style={{ marginLeft: "135px" }}>
                    <CustomButton
                      height={"36px"}
                      width={"200px"}
                      startIcon={<SearchIcon />}
                      onClick={handleGetRenewals}
                    >
                      Get Renewals
                    </CustomButton>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-wrapper-summary">summary</div>
            <div className="content-wrapper-products">products</div>
            <div className="content-wrapper-result">result</div>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default UwRenewal;
