import { useSelector } from "react-redux";
import "./AdSysCode.scss";
import ConfirmDialog from "../../../components/confirmDialog/confirmDialog.jsx";
import {
  CustomButton,
  CustomLoadingButton,
} from "../../../components/ui/CustomButton.jsx";
import newRequest from "../../../utils/newRequest.js";
import { showToast } from "../../../toastService.js";
import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Tooltip,
} from "@mui/material";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  Select,
  MenuItem
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { motion } from "framer-motion";
import moment from "moment";
import { useEffect, useState } from "react";
import { FaSave } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { GridLoader, ScaleLoader } from "react-spinners";

const AdSysCode = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogAction, setConfirmDialogAction] = useState("None");
  const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
  const [notifyDialogTitle, setNotifyDialogTitle] = useState("Confirm");
  const [notifyDialogText, setNotifyDialogText] = useState("Text");
  const [isLoading, setIsLoading] = useState(true);
  const [savingTitle, setSavingTitle] = useState("Save");
  const [systemTypes, setSystemTypes] = useState([]);
  const [selectedSystemType, setSelectedSystemType] = useState({});
  const [systemCodes, setSystemCodes] = useState([]);
  const [filteredSysCodesData, setFilteredSysCodesData] = useState([]);

  const [selectedSystemCode, setSelectedSystemCode] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const [currencyAction, setCurrencyAction] = useState("");
  const [isCodeDialogOpened, setIsCodeDialogOpened] = useState(false);
  const [newCode, setNewCode] = useState(null);
  const [newName, setNewName] = useState(null);
  const [newGrouping, setNewGrouping] = useState(null);

  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [currentPage, setCurrentPage] = useState(0);
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    const filteredResults = systemCodes.filter((item) =>
      item.sys_name?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredSysCodesData(filteredResults);
  }, [systemCodes, searchTerm]);

  const slicedSysCodes = filteredSysCodesData.slice(
    currentPage * rowsPerPage,
    currentPage * rowsPerPage + rowsPerPage
  );

  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 8));
    setCurrentPage(0);
  };

  const handleAddSysCode = async () => {
    try {
      if (!newCode) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter code!`);
        setIsNotifyDialogOpen(true);
        return;
      }
      if (!newName) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please entername!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      setIsLoading(true);
      const res = await newRequest.post(
        "/sysCode/add/",
        {
          sys_org_code: currentUser.usr_org_code,
          sys_type: selectedSystemType.sys_type,
          sys_code: newCode,
          sys_name: newName,
          sys_grouping: newGrouping,
          userCode: currentUser.usr_id,
        },
        config
      );
      fetchSystemCodes(selectedSystemType.sys_type);
      setNewCode(null);
      setNewName(null);
      setNewGrouping(null);
      setIsCodeDialogOpened(false);
      setIsLoading(false);
      showToast("Code Added!", "success");
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleAddSysType = async () => {
    try {
      if (!newCode) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter code!`);
        setIsNotifyDialogOpen(true);
        return;
      }
      if (!newName) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please entername!`);
        setIsNotifyDialogOpen(true);
        return;
      }
      setIsLoading(true);
      const res = await newRequest.post(
        "/sysType/add/",
        {
          sys_org_code: currentUser.usr_org_code,
          sys_type: newCode,
          sys_name: newName,
          userCode: currentUser.usr_id,
        },
        config
      );
      fetchSystemTypes();
      setNewCode(null);
      setNewName(null);
      setNewGrouping(null);
      setIsCodeDialogOpened(false);
      setIsLoading(false);
      showToast("Type Added!", "success");
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const [objectToAction, setObjectToAction] = useState({
    object_id: "",
    action_text: "",
    action_invoked: "",
  });

  const handleClearSearch = () => {
    try {
      setSearchTerm("");
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSystemTypes = async () => {
    try {
      setIsLoading(true);
      const res = await newRequest.post(
        "/sysType/findAll",
        {
          org_code: currentUser.usr_org_code,
        },
        config
      );
      setSystemTypes(res.data);
      setSelectedSystemType(res.data[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSystemCodes = async (sysType) => {
    try {
      const res = await newRequest.post(
        "/sysCode/findAll",
        {
          sys_org_code: currentUser.usr_org_code,
          sys_type: sysType,
        },
        config
      );
      // console.log(`sysCode/findAll  ${JSON.stringify(res.data, null, 2)}`);
      setSystemCodes(res.data);
      if (res.data) {
        setSelectedSystemCode(res.data[0]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSystemTypes();
  }, []);

  const filteredSysTypes = systemTypes.filter(
    (item) =>
      item.sys_type?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.sys_name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    try {
      fetchSystemCodes(selectedSystemType?.sys_type);
    } catch (error) {
      console.log(error);
    }
  }, [selectedSystemType]);


  const handleSysCodeChange = (e, rowId) => {
    try {
      const { name, value } = e.target;
      const updatedData = [...systemCodes];
      const rowIndex = updatedData.findIndex(item => item._id === rowId);

      if (rowIndex === -1) {
        console.log('Row not found');
      }

      updatedData[rowIndex][name] = value;
      updatedData[rowIndex]["changed_xx"] = "Y";

      // console.log(`rowId is ${rowId} handleLimitChange updatedData  \n${JSON.stringify(updatedData, null, 2)}`);
      setSystemCodes(updatedData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirm = async () => {
    try {
      if (confirmDialogAction === "deleteCode") {
        const res = await newRequest.delete(
          `/sysCode/del/${objectToAction.object_id}`,
          {},
          config
        );
        fetchSystemCodes(selectedSystemType.sys_type);
      }
    } catch (err) {
      console.log(err);
    }
    // Close the dialog
    setIsConfirmDialogOpen(false);
  };

  const handleCancel = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleCloseNotifyDialog = () => {
    setIsNotifyDialogOpen(false);
  };

  const saveRecord = async (e) => {
    // e.preventDefault();
    try {
      setSavingTitle("saving...");
      setIsLoading(true);
      // Simulate delay for 5 seconds
      // await new Promise((resolve) => setTimeout(resolve, 5000));

      const changedCodes = systemCodes.filter((obj) => obj.changed_xx === "Y");

      // console.log(`changedCodes  ${JSON.stringify(changedCodes, null, 2)}`);

      for (let i = 0; i < changedCodes.length; i++) {
        const res = await newRequest.put(
          `/sysCode/upd/${changedCodes[i]._id}`,
          {
            sys_code: changedCodes[i].sys_code,
            sys_name: changedCodes[i].sys_name,
            sys_grouping: changedCodes[i].sys_grouping,
            sys_enabled: changedCodes[i].sys_enabled,
            updatedBy: currentUser.usr_id,
          },
          config
        );
      }

      showToast("Updated!", "success");
    } catch (err) {
      console.log(err);
    } finally {
      setSavingTitle("Save");
      setIsLoading(false);
    }
  };

  return (
    <div className="ad-sys-code">
      {isLoading ? (
        <div className="no-content"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <ScaleLoader
            color="#F44336"
            height={50}
            radius={8}
            width={4}
          />
        </div>
      ) : (
        <div className="sys-code-main-wrapper">
          <div className="syscode-center-wrapper">
            <div className="center-details">
              <div className="center-navbar">
                <span className="selected-title">
                  {selectedSystemType?.sys_type}
                </span>
                <span
                  style={{ display: "flex", flexDirection: "row", gap: "5px" }}
                >
                  <span
                    className="selected-type"
                    style={{ width: "max-content" }}
                  >
                    {selectedSystemType?.sys_access_level}
                  </span>
                  {!["System", "Extensible"].includes(selectedSystemType?.sys_access_level) && <CustomLoadingButton
                    startIcon={<FaSave />}
                    onClick={saveRecord}
                  >
                    {savingTitle}
                  </CustomLoadingButton>}
                  {!["System", "Extensible"].includes(selectedSystemType?.sys_access_level) && <CustomButton
                    startIcon={<AddIcon />}
                    onClick={() => {
                      try {
                        setCurrencyAction("addSysCode");
                        setIsCodeDialogOpened(true);
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  >
                    Add Code
                  </CustomButton>}
                </span>
              </div>
              <hr />
              <div className="table-content">
                <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none' }} className="table-container">
                  <Table sx={{ width: "max-content", border: 'none' }}>
                    <TableHead className="tablehead">
                      <TableRow className="tablerow">
                        <TableCell className="table-cell" style={{ maxWidth: "50px", fontWeight: "bold", display: "flex", alignItems: "center" }}>
                          #
                        </TableCell>
                        <TableCell className="table-cell" style={{ maxWidth: "200px" }}>
                          <span>CODE</span>
                        </TableCell>
                        <TableCell className="table-cell" style={{ width: "auto" }}>
                          <span>NAME</span>
                        </TableCell>
                        <TableCell className="table-cell" style={{ maxWidth: "150px" }}>
                          <span>GROUPING</span>
                        </TableCell>
                        <TableCell className="table-cell" style={{ maxWidth: "100px" }}>
                          <span>ENABLED</span>
                        </TableCell>
                        {!["System", "Extensible"].includes(selectedSystemType?.sys_access_level) && <TableCell className="table-cell" style={{ fontWeight: "bold", maxWidth: "100px", alignItems: "center", textAlign: "center" }}>
                          <span>ACTION</span>
                        </TableCell>}
                      </TableRow>
                    </TableHead>
                    <TableBody className="tablebody">
                      {slicedSysCodes?.map((row, index) => (
                        <TableRow
                          key={row._id}
                          className={`${selectedSystemCode?._id === row._id ? "tablerow current" : "tablerow"}`}
                          onClick={() => {
                            try {
                              setSelectedSystemCode(row);
                            } catch (error) {
                              console.log(error);
                            }
                          }}
                        >
                          <TableCell className="table-cell" style={{ maxWidth: "50px", fontWeight: "bold", display: "flex", alignItems: "center" }}>
                            {currentPage * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell className="table-cell" style={{ maxWidth: "200px" }}>
                            <input
                              id="sys_code"
                              name="sys_code"
                              value={row.sys_code}
                              onChange={(e) => handleSysCodeChange(e, row._id)}
                              style={{ width: "100%" }}
                              className="form-input-item"
                              disabled
                            />
                          </TableCell>
                          <TableCell className="table-cell" style={{ width: "auto" }}>
                            <input
                              id="sys_name"
                              name="sys_name"
                              value={row.sys_name}
                              onChange={(e) => handleSysCodeChange(e, row._id)}
                              style={{ width: "100%" }}
                              className="form-input-item"
                              disabled={["System", "Extensible"].includes(selectedSystemType?.sys_access_level)}
                            />
                          </TableCell>
                          <TableCell className="table-cell" style={{ maxWidth: "150px" }}>
                            <input
                              id="sys_grouping"
                              name="sys_grouping"
                              value={row.sys_grouping}
                              onChange={(e) => handleSysCodeChange(e, row._id)}
                              style={{ width: "100%" }}
                              className="form-input-item"
                              disabled={["System", "Extensible"].includes(selectedSystemType?.sys_access_level)}
                            />
                          </TableCell>
                          <TableCell className="table-cell" style={{ maxWidth: "100px" }}>
                            <Select
                              id="sys_enabled"
                              name="sys_enabled"
                              value={row.sys_enabled}
                              onChange={(e) => handleSysCodeChange(e, row._id)}
                              variant="outlined"
                              size="small"
                              disabled={["System", "Extensible"].includes(selectedSystemType?.sys_access_level)}
                            >
                              <MenuItem value="Y">Yes</MenuItem>
                              <MenuItem value="N">No</MenuItem>
                            </Select>
                          </TableCell>
                          {!["System", "Extensible"].includes(selectedSystemType?.sys_access_level) &&
                            <TableCell className="table-cell" style={{ alignItems: "center", maxWidth: "100px", fontWeight: "bold" }}>
                              <Tooltip
                                title={`Delete code ${row.sys_name}?`}
                                placement="right"
                              >
                                <DeleteIcon
                                  size={50}
                                  style={{
                                    borderRadius: "5px",
                                    padding: "3px",
                                    alignSelf: "center",
                                    cursor: "pointer",
                                    color: "rgba(244, 67, 54)",
                                    backgroundColor: "rgba(244, 67, 54, 0.5)",
                                  }}
                                  onClick={() => {
                                    try {
                                      let action_text = `Delete code ${row.sys_name}?`;
                                      setObjectToAction({
                                        object_id: row._id,
                                        action_text: action_text,
                                        new_doc_status: "deleteCode",
                                      });

                                      setConfirmDialogAction("deleteCode");
                                      setIsConfirmDialogOpen(true);
                                    } catch (error) {
                                      console.log(error);
                                    }
                                  }}
                                />
                              </Tooltip>
                            </TableCell>}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[8]}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleRowsPerPage}
                    component="div"
                    count={filteredSysCodesData?.length}
                    rows={filteredSysCodesData}
                    page={currentPage}
                    onPageChange={handlePageChange}
                  />
                </TableContainer>
              </div>
            </div>
          </div>
          <div className="right-wrapper">
            <div className="right-content">
              <div className="right-navbar" >
                <div className="search-input-wrapper">
                  <SearchIcon className="search-input-icon" />
                  <input
                    className="search-input"
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  {searchTerm.length > 0 && (
                    <CloseIcon
                      onClick={handleClearSearch}
                      className="search-input-icon"
                    />
                  )}
                </div>
              </div>
              <div className="options-list">
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "10px",
                  }}
                >
                  {["admin", "superadmin"].includes(currentUser.user_type_xx) && <CustomButton
                    startIcon={<AddIcon />}
                    onClick={() => {
                      try {
                        setCurrencyAction("addSysType");
                        setIsCodeDialogOpened(true);
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                    width={"95%"}
                  >
                    Add Type
                  </CustomButton>}
                </span>
                {filteredSysTypes?.map((stype) => (
                  <>
                    <span
                      key={stype._id}
                      className={`${selectedSystemType._id === stype._id
                        ? "single-option active"
                        : "single-option"
                        }`}
                      onClick={async () => {
                        try {
                          setSelectedSystemType(stype);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                    >
                      <div className="title-row">
                        <span className="cy-title">{stype.sys_type}</span>
                      </div>
                    </span>
                    <hr style={{ margin: "0px 10px" }} />
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Dialog to notify user about changes being saved */}
      <Dialog open={isNotifyDialogOpen} onClose={handleCloseNotifyDialog}>
        <DialogTitle>{notifyDialogTitle}</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: notifyDialogText }} />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseNotifyDialog}>Close</CustomButton>
        </DialogActions>
      </Dialog>
      {/* Confirm Dialog */}
      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        dialogTitle="Confirmation"
        dialogContent={<p>{objectToAction.action_text}</p>}
      />

      {isCodeDialogOpened && (
        <motion.div
          className="new-rate-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="new-rate-wrapper">
            <div className="new-rate-navbar">
              <span>{`${currencyAction === "addSysCode" ? "ADD CODE" : "ADD TYPE"
                }`}</span>
              <span
                onClick={() => {
                  try {
                    setNewCode(null);
                    setNewName(null);
                    setNewGrouping(null);
                    setIsCodeDialogOpened(false);
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                <IoClose
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    borderRadius: "50%",
                  }}
                />
              </span>
            </div>
            <div className="new-rate-content">
              <div className="input-item-with-label">
                <label
                  htmlFor="newName"
                  className="form-dropdown-label"
                  style={{ width: "130px" }}
                >
                  NAME
                </label>
                <input
                  id="newName"
                  name="newName"
                  type="text"
                  placeholder="NAME"
                  value={newName}
                  className="form-input-item"
                  style={{ width: "250px" }}
                  onChange={(e) => setNewName(e.target.value)}
                />
              </div>
              <div className="input-item-with-label">
                <label
                  htmlFor="newCode"
                  className="form-dropdown-label"
                  style={{ width: "130px" }}
                >
                  {currencyAction === "addSysCode" ? "CODE" : "TYPE"}
                </label>
                <input
                  id="newCode"
                  name="newCode"
                  type="text"
                  placeholder={
                    currencyAction === "addSysCode" ? "CODE" : "TYPE"
                  }
                  value={newCode}
                  className="form-input-item"
                  style={{ width: "150px" }}
                  onChange={(e) => {
                    try {
                      setNewCode(e.target.value);
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                />
              </div>
              {currencyAction === "addSysCode" && (
                <div className="input-item-with-label">
                  <label
                    htmlFor="newGrouping"
                    className="form-dropdown-label"
                    style={{ width: "130px" }}
                  >
                    GROUPING
                  </label>
                  <input
                    id="newGrouping"
                    name="newGrouping"
                    type="text"
                    placeholder="GROUPING"
                    value={newGrouping}
                    className="form-input-item"
                    style={{ width: "150px" }}
                    onChange={(e) => setNewGrouping(e.target.value)}
                  />
                </div>
              )}
            </div>
            <div className="new-rate-action">
              {currencyAction === "addSysCode" && (
                <CustomButton onClick={handleAddSysCode}>ADD</CustomButton>
              )}
              {currencyAction === "addSysType" && (
                <CustomButton onClick={handleAddSysType}>ADD</CustomButton>
              )}
            </div>
          </div>
        </motion.div>
      )}

      {isProcessing && (
        <motion.div
          className="processing-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="processing-wrapper">
            <GridLoader
              color="#F44336"
              style={{
                height: "50px",
                width: "50px",
              }}
            />
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default AdSysCode;
