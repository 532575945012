import "./GlStatement.scss";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { motion } from "framer-motion";
import moment from "moment";
import numeral from "numeral";
import {
  CustomLoadingButton,
  CustomButton,
} from "../../../components/ui/CustomButton";
import ExportToExcelButton from "../../../components/exportToExcelButton/ExportToExcelButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import { customSelectStyles } from "../../../components/ui/uiStyleElements";
import newRequest from "../../../utils/newRequest";
import Select from "react-select";
import {
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
} from "@mui/material";
import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';

const GlStatement = () => {
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
  const [notifyDialogTitle, setNotifyDialogTitle] = useState("Confirm");
  const [notifyDialogText, setNotifyDialogText] = useState("Text");
  const [statementTitle, setStatementTitle] = useState("");

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredRiskNotes, setFilteredRiskNotes] = useState([]);
  const [filteredReceipts, setFilteredReceipts] = useState([]);
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // columns to export
  const exportColumns = [
    "insured_name_xx",
    "pl_quote_no",
    "product_name_xx",
    "pl_status",
    "quote_stage_xx",
    "total_fc_si",
    "total_fc_premium",
    "commission_fc_amount",
    "commission_tax_fc_amount",
    "bizunit_name_xx",
  ];

  const [statementData, setStatementData] = useState({});
  const [buttonTitle, setButtonTitle] = useState("GET STATEMENT");
  const [fmDate, setFmDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  // products
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const fetchProducts = async () => {
    try {
      const params = {
        pr_org_code: currentUser.usr_org_code,
      };
      const res = await newRequest.post("/uwProduct/findAll", params, config);
      // Transform the data to match the format expected by react-select
      const options = res.data.map((product) => ({
        value: product.pr_code,
        label: product.pr_name,
      }));

      options.unshift({ value: "All", label: "All" });

      setProducts(options);
      // set default product
      setSelectedProduct({ value: "All", label: "All" });
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };

  // biz unit
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [branches, setBranches] = useState([]);
  const fetchBranches = async () => {
    try {

      const res = await newRequest.post("/orgUnit/findAll", {
        unit_org_code: currentUser.usr_org_code,
      }, config);

      const options = res.data.map((branch) => ({
        value: branch.unit_code,
        label: branch.unit_name,
      }));

      options.unshift({ value: "All", label: "All" });
      setBranches(options);
      setSelectedBranch({ value: "All", label: "All" });
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };
  // insurance company
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [carriers, setCarriers] = useState([]);
  const fetchCarriers = async () => {
    try {

      const res = await newRequest.post("/allEntity/findAll", {
        ent_org_code: currentUser.usr_org_code,
      }, config);
      // Transform the data to match the format expected by react-select
      const options = res.data.map((entity) => ({
        aent_code: entity.ent_aent_code,
        value: entity.ent_code,
        label: entity.ent_name,
      }));
      // options.unshift({ aent_code: "All", label: "All", label: "All" });
      setCarriers(options);
      // setSelectedCarrier({ aent_code: "All", value: "All", label: "All" });
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };

  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [currencies, setCurrencies] = useState([]);
  const fetchCurrencies = async () => {
    try {
      const res = await newRequest.post("/glCurrency/findAll");
      const options = res.data?.map((currency) => ({
        value: currency.cur_code,
        label: currency.cur_name,
      }));
      setCurrencies(options);

      setSelectedCurrency({
        value: currentUser.currency_code_xx,
        label: currentUser.currency_name_xx,
      })

    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };

  const handleCloseNotifyDialog = () => {
    setIsNotifyDialogOpen(false);
  };

  const setDates = () => {
    try {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();

      // Beginning of the year
      const beginningOfYear = new Date(currentYear, 0, 1);
      beginningOfYear.setFullYear(currentYear); // Set full year again to handle leap years
      const formattedBeginningOfYear = beginningOfYear
        .toISOString()
        .split("T")[0];

      // End of the year
      const endOfYear = new Date(currentYear + 1, 0, 0);
      const formattedEndOfYear = endOfYear.toISOString().split("T")[0];

      // Beginning of the month
      const beginningOfMonth = new Date(currentYear, currentMonth, 1);
      console.log(`Begining of the month ${beginningOfMonth}`)
      const formattedBeginningOfMonth = beginningOfMonth
        .toISOString()
        .split("T")[0];

      // End of the month
      const endOfMonth = new Date(currentYear, currentMonth + 1, 0);
      endOfMonth.setDate(endOfMonth.getDate() + 1); // Add one day to get the actual end of the month
      const formattedEndOfMonth = endOfMonth.toISOString().split("T")[0];

      // Formatting current date and one year later
      const formattedCurrentDate = currentDate.toISOString().split("T")[0];
      const oneYearLater = new Date(currentDate);
      oneYearLater.setFullYear(currentYear + 1);
      const formattedOneYearLater = oneYearLater.toISOString().split("T")[0];

      // console.log("Current Date:", formattedCurrentDate);
      // console.log("Beginning of the Year:", formattedBeginningOfYear);
      // console.log("End of the Year:", formattedEndOfYear);
      // console.log("Beginning of the Month:", formattedBeginningOfMonth);
      // console.log("End of the Month:", formattedEndOfMonth);
      // console.log("One Year Later:", formattedOneYearLater);

      // Set these values to your state or do whatever you want with them
      setFmDate(formattedBeginningOfMonth);
      setToDate(formattedEndOfMonth);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setDates();
    fetchProducts();
    fetchBranches();
    fetchCarriers();
    fetchCurrencies();
  }, []);

  const handleFromDateChange = (e) => {
    try {
      if (e.target.value) {
        const formattedFromDate = moment(e.target.value).format("YYYY-MM-DD");
        setFmDate(formattedFromDate);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleToDateChange = (e) => {
    try {
      if (e.target.value) {
        const formattedToDate = moment(e.target.value).format("YYYY-MM-DD");
        setToDate(formattedToDate);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetStatement = async () => {
    try {


      if (!selectedCarrier) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please select entity`);
        setIsNotifyDialogOpen(true);
        return;
      }
      // await new Promise((resolve) => setTimeout(resolve, 5000));

      setButtonTitle("FETCHING...");

      const res = await newRequest.post("/entityBalance/carrierStmt", {
        org_code: currentUser.usr_org_code,
        date_fm: fmDate,
        date_to: toDate,
        product_code:
          selectedProduct.value === "All" ? "" : selectedProduct.value,
        biz_unit: selectedBranch.value === "All" ? "" : selectedBranch.value,
        currency_code: selectedCurrency.value,
        aent_code:
          selectedCarrier.value === "All" ? "" : selectedCarrier.aent_code,
        ent_code:
          selectedCarrier.value === "All" ? "" : selectedCarrier.value,
        userCode: currentUser.usr_id
      });

      console.log(`carrierStmt  ${JSON.stringify(res.data, null, 2)}`);

      setStatementTitle(`${selectedCarrier.label.toUpperCase()} ${selectedCurrency.label} STATEMENT FOR ${moment(fmDate).format("DD-MMM-YYYY")} TO ${moment(toDate).format("DD-MMM-YYYY")}`)
      setStatementData(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setButtonTitle("GET STATEMENT");
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    const filteredRiskNotes = statementData.statement_data?.filter(
      (item) =>
        item.pl_quote_no?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.pl_no?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.pl_risk_note_no
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        item.pl_end_no
          ?.toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        item.product_name_xx
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        item.insured_name_xx
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        item.created_by_name_xx
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase())
    );

    const filteredReceipts = statementData.receipts_data?.filter(
      (item) =>
        item.hd_no?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.hd_ref_no?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.hd_fc_amount?.toString()
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        item.hd_chq_no
          ?.toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
    );

    const filteredPayments = statementData.payments_data?.filter(
      (item) =>
        item.hd_no?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.hd_ref_no?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.hd_fc_amount?.toString()
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        item.hd_chq_no
          ?.toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
    );
    setFilteredReceipts(filteredReceipts);
    setFilteredPayments(filteredPayments);
    setFilteredRiskNotes(filteredRiskNotes);

  }, [statementData.statement_data, searchTerm]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const slicedRiskNotes = filteredRiskNotes?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const slicedReceipts = filteredReceipts?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );


  const slicedPayments = filteredPayments?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );


  return (
    <motion.div
      className="gl-statement"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      <div className="content">
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "200px",
            }}
          >
            <CircularProgress
              style={{
                height: "50px",
                width: "50px",
              }}
            />
          </div>
        ) : (
          <div className="content-wrapper">
            <div className="content-wrapper-selection">
              <h1>Entity Statement</h1>
              <h3>Enter filtering criterial and click get statement details</h3>
              <div className="filter">
                <div className="dates">
                  <div className="input-item-with-label">
                    <label
                      htmlFor="fm_date"
                      className="form-dropdown-label"
                      style={{ width: "130px" }}
                    >
                      FROM
                    </label>
                    <input
                      id="fm_date"
                      name="fm_date"
                      type="date"
                      placeholder="FROM"
                      value={fmDate}
                      className="form-input-item"
                      style={{ width: "200px" }}
                      onChange={handleFromDateChange}
                    />
                  </div>
                  <div className="input-item-with-label">
                    <label
                      htmlFor="to_date"
                      className="form-dropdown-label"
                      style={{ width: "130px" }}
                    >
                      TO
                    </label>
                    <input
                      id="to_date"
                      name="to_date"
                      type="date"
                      placeholder="TO"
                      value={toDate}
                      className="form-input-item"
                      style={{ width: "200px" }}
                      onChange={handleToDateChange}
                    />
                  </div>
                </div>
                <div className="biz-unit">
                  <div className="input-item-with-label biz_unit">
                    <label htmlFor="biz_unit" style={{ width: "130px" }}>
                      BIZ UNIT
                    </label>
                    <Select
                      id="biz_unit"
                      name="biz_unit"
                      styles={customSelectStyles}
                      width="250px"
                      value={selectedBranch}
                      onChange={(selectedOption) => {
                        try {
                          setSelectedBranch(selectedOption);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                      options={branches}
                      isSearchable={true}
                      placeholder="BIZ UNIT"
                    />
                  </div>

                  <div className="input-item-with-label">
                    <label htmlFor="currency_code" style={{ width: "130px" }}>
                      CURRENCY
                    </label>
                    <Select
                      id="currency_code"
                      name="currency_code"
                      styles={customSelectStyles}
                      width="250px"
                      value={selectedCurrency}
                      onChange={(selectedOption) => {
                        try {
                          setSelectedCurrency(selectedOption);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                      options={currencies}
                      isSearchable={true}
                      placeholder="CURRENCY"
                    />
                  </div>
                </div>
                <div className="carrier">
                  <div className="input-item-with-label">
                    <label htmlFor="product_code" style={{ width: "130px" }}>
                      PRODUCT
                    </label>
                    <Select
                      id="product_code"
                      name="product_code"
                      styles={customSelectStyles}
                      width="300px"
                      value={selectedProduct}
                      onChange={(selectedOption) => {
                        try {
                          setSelectedProduct(selectedOption);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                      options={products}
                      isSearchable={true}
                      placeholder="PRODUCT"
                    />
                  </div>


                  <div className="input-item-with-label">
                    <label htmlFor="carrier" style={{ width: "130px" }}>
                      CARRIER
                    </label>
                    <Select
                      id="carrier"
                      name="carrier"
                      styles={customSelectStyles}
                      width="300px"
                      value={selectedCarrier}
                      onChange={(selectedOption) => {
                        try {
                          setSelectedCarrier(selectedOption);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                      options={carriers}
                      isSearchable={true}
                      placeholder="Carrier"
                    />
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", margin: "10px 0px" }}>
                <CustomLoadingButton
                  height={"36px"}
                  width={"200px"}
                  startIcon={<PageviewOutlinedIcon />}
                  onClick={handleGetStatement}
                >
                  {buttonTitle}
                </CustomLoadingButton>
              </div>
            </div>
            <div className="content-wrapper-summary">
              <h1>{statementTitle}</h1>
              <div className="summary-wrapper">
                <div className="count-view">
                  <h1> {numeral(
                    statementData.opening_balance_fc || "0"
                  ).format("0,0.00")}</h1>
                  <h3>Opening Balance</h3>
                </div>

                <div className="count-view">

                  <h1> {numeral(
                    statementData.total_debit_fc || "0"
                  ).format("0,0.00")}</h1>

                  <h3>Total Debits</h3>
                </div>
                <div className="count-view">

                  <h1> {numeral(
                    statementData.total_credit_fc || "0"
                  ).format("0,0.00")}</h1>

                  <h3>Total Credits</h3>
                </div>

                <div className="premium-view">
                  <h1>{statementData.no_of_transactions || "0.00"}</h1>
                  <h3>No of Trans</h3>
                </div>
                <div className="sum-insured-view">
                  <h1> {numeral(
                    statementData.closing_balance_fc || "0"
                  ).format("0,0.00")}</h1>
                  <h3>Closing Balance</h3>
                </div>
              </div>
            </div>
            {/* <div className="content-wrapper-products">
              <span>Breakdown By Product</span>
              <div className="product-wrapper">
              </div>
            </div> */}
            <div className="content-wrapper-result">
              <div className="results-wrapper">
                <div className="results-wrapper-navbar">
                  <div className="left">
                    <div className="search-input-wrapper">
                      <SearchIcon className="search-input-icon" />
                      <input
                        className="search-input"
                        type="text"
                        placeholder="Search..."
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="right">
                    <CustomButton
                      startIcon={<RefreshIcon />}
                      onClick={handleGetStatement}
                    >
                      Refresh
                    </CustomButton>
                    <ExportToExcelButton
                      data={slicedRiskNotes}
                      exportColumns={exportColumns}
                      fileName="Documents.xlsx"
                    />
                  </div>
                </div>
                <h1 style={{ display: "flex", width: "100%", justifyItems: "flex-start", margin: "10px 0px", marginLeft: "10px" }}>Risk Notes [{filteredRiskNotes?.length}]</h1>
                <div className="table-content">
                  <TableContainer component={Paper} className="table-container">
                    <Table sx={{ width: "max-content" }}>
                      <TableBody className="tablebody">
                        {filteredRiskNotes?.map((policy) => (
                          <TableRow
                            key={policy._id}
                            className="tablerow"
                            onClick={() => {
                              try {
                                navigate(`/uw/rn/${policy._id}`, {
                                  state: policy,
                                });
                              } catch (error) {
                                console.log(error);
                              }
                            }}
                          >
                            <TableCell
                              className="table-cell"
                              style={{
                                padding: "8px",
                                width: "85%",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div className="top-row">
                                <div className="left">
                                  <div className="entity">
                                    <span>{policy.running_sr_no}</span>


                                    <img
                                      src={
                                        policy?.insured_photo_url_xx ||
                                        "/img/noimage.png"
                                      }
                                      alt=""
                                    />
                                    <span>{policy.insured_name_xx}</span>
                                  </div>
                                </div>
                                <div className="right">
                                  <span>{policy.pl_cur_code}</span>{" "}
                                  <span>
                                    {moment(policy?.pl_gl_date).format(
                                      "DD-MMM-YYYY"
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="second-row">
                                <div className="second-row-col1">
                                  <span>{`Risk Note ${policy.pl_risk_note_no}`}</span>
                                  <span>{`Original ${policy.pl_primary_doc}`}</span>
                                  <span>{`Policy No ${policy.pl_no}`}</span>
                                </div>

                                <div
                                  className="second-row-col2"
                                  style={{ flexGrow: 1 }}
                                >
                                  <span>{`Product ${policy.product_name_xx}`}</span>
                                  <span>{`Type ${policy.end_name_xx}`}</span>
                                  <span>{`Biz Unit ${policy.bizunit_name_xx}`}</span>
                                </div>

                                <div className="second-row-col3">
                                  <span>{`Sum Insured ${numeral(
                                    policy.total_fc_si
                                  ).format("0,0.00")}`}</span>
                                  <span>{`Premium ${numeral(
                                    policy.policy_fc_premium
                                  ).format("0,0.00")}`}</span>
                                  <span>{`Charges ${numeral(
                                    policy.total_charges_fc_amount
                                  ).format("0,0.00")}`}</span>
                                </div>

                                <div className="second-row-col4">
                                  <span>{`Total Prem ${numeral(
                                    policy.total_fc_premium
                                  ).format("0,0.00")}`}</span>
                                  <span>{`Paid ${numeral(
                                    policy.total_paid_fc_amount
                                  ).format("0,0.00")}`}</span>
                                  <span>{`OS Prem ${numeral(
                                    policy.total_os_fc_amount
                                  ).format("0,0.00")}`}</span>
                                </div>
                                <div className="second-row-col5">
                                  <span>{`Commission ${numeral(
                                    policy.commission_fc_amount
                                  ).format("0,0.00")}`}</span>
                                  <span>{`Tax ${numeral(
                                    policy.commission_tax_fc_amount
                                  ).format("0,0.00")}`}</span>
                                  <span>{`DrCr ${policy.drcr_flag_xx}`}</span>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell
                              className="table-cell"
                              style={{
                                padding: "8px",
                                width: "15%",
                                display: "flex",

                              }}
                            >
                              <div style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "flex-end"
                              }}>
                                <h1>
                                  {numeral(
                                    policy.running_balance_fc
                                  ).format("0,0.00")}
                                </h1>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    {/* <TablePagination
                      rowsPerPageOptions={[10]}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      component="div"
                      count={statementData.statement_data?.length}
                      rows={filteredRiskNotes}
                      page={page}
                      onPageChange={handleChangePage}
                    /> */}
                  </TableContainer>
                </div>
                <hr />

                <h1 style={{ display: "flex", width: "100%", justifyItems: "flex-start", margin: "10px 0px", marginLeft: "10px" }}>Unapplied and Partially Applied Receipts [{filteredReceipts?.length}]</h1>
                <div className="table-content">
                  <TableContainer component={Paper} className="table-container">
                    <Table sx={{ width: "max-content" }}>
                      <TableBody className="tablebody">
                        {filteredReceipts?.map((receipt) => (
                          <TableRow
                            key={receipt._id}
                            className="tablerow"
                            onClick={() => {
                              navigate(`/receipt/${receipt._id}`, {
                                state: { shouldFetchClients: false },
                              });
                            }}
                          >
                            <TableCell
                              className="table-cell"
                              style={{
                                padding: "8px",
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div className="top-row">
                                <div className="left">
                                  <div className="entity">
                                    <span>{receipt.running_sr_no}</span>

                                    <img
                                      src={
                                        receipt?.client_photo_url_xx ||
                                        "/img/noimage.png"
                                      }
                                      alt=""
                                    />
                                    <span>{receipt.client_name_xx}</span>
                                  </div>
                                </div>
                                <div className="right">
                                  <span className={`doc-status ${receipt.hd_status}`}>
                                    {receipt.hd_status}
                                  </span>
                                </div>
                              </div>
                              <div className="second-row">
                                <div className="second-row-col1">
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                    }}
                                  >{`No ${receipt.hd_no}`}</span>
                                  <span>{`Paying For ${receipt.hd_paying_for}`}</span>
                                  <span>{`Branch ${receipt.bizunit_name_xx}`}</span>
                                </div>
                                <div
                                  className="second-row-col2"
                                  style={{ flexGrow: 1 }}
                                >
                                  <span>{`Bank A/C ${receipt.bank_ac_no_xx}`}</span>
                                  <span>{`Bank ${receipt.bank_alias_xx}`}</span>
                                  <span>{`Branch ${receipt?.bank_branch_name_xx}`}</span>
                                </div>
                                <div className="second-row-col3">
                                  <span>
                                    {`Date ${moment(receipt?.hd_gl_date).format(
                                      "DD-MMM-YYYY"
                                    )}`}
                                  </span>
                                  <span>{`Mode ${receipt.hd_mode}`}</span>
                                  <span>{`Ref No ${receipt.hd_ref_no}`}</span>
                                </div>

                                <div className="second-row-col4">
                                  <span>{`CHQ No ${receipt.hd_chq_no}`}</span>
                                  <span>
                                    {`CHQ Date ${moment(receipt?.hd_chq_date).format(
                                      "DD-MMM-YYYY"
                                    )}`}
                                  </span>
                                </div>

                                <div className="second-row-col5">
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                    }}
                                  >{`${receipt.hd_cur_code} ${numeral(
                                    receipt.hd_fc_amount
                                  ).format("0,0.00")}`}</span>
                                  {receipt.hd_cur_code !==
                                    currentUser.currency_code_xx && (
                                      <span>{`Base ${numeral(receipt.hd_lc_amount).format(
                                        "0,0.00"
                                      )}`}</span>
                                    )}
                                </div>
                              </div>
                              <div className="third-row">
                                <span
                                  style={{
                                    fontWeight: "500",
                                    maxWidth: "250px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                >{`Narration ${receipt.hd_narration}`}</span>
                                <div className="user">
                                  <span>{`Created By : ${receipt.created_by_name_xx}`}</span>
                                  <img
                                    src={
                                      receipt?.created_by_photo_url_xx ||
                                      "/img/noavatar.jpg"
                                    }
                                    alt=""
                                  />
                                  <span>{receipt.time_ago_xx}</span>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    {/* <TablePagination
                      rowsPerPageOptions={[6]}
                      component="div"
                      count={slicedReceipts?.length}
                      rowsPerPage={rowsPerPage}
                      rows={slicedReceipts}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    /> */}
                  </TableContainer>
                </div>
                <hr />
                <h1 style={{ display: "flex", width: "100%", justifyItems: "flex-start", margin: "10px 0px", marginLeft: "10px" }}>Unapplied and Partially Applied Payments [{filteredPayments?.length}]</h1>
                <div className="table-content">
                  <TableContainer component={Paper} className="table-container">
                    <Table sx={{ width: "max-content" }}>
                      <TableBody className="tablebody">
                        {filteredPayments?.map((payment) => (
                          <TableRow
                            key={payment._id}
                            className="tablerow"
                            onClick={() => {
                              navigate(`/payment/${payment._id}`, {
                                state: { shouldFetchClients: false },
                              });
                            }}
                          >
                            <TableCell
                              className="table-cell"
                              style={{
                                padding: "8px",
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div className="top-row">
                                <div className="left">
                                  <div className="entity">
                                    <span>{payment.running_sr_no}</span>

                                    <img
                                      src={
                                        payment?.client_photo_url_xx ||
                                        "/img/noimage.png"
                                      }
                                      alt=""
                                    />
                                    <span>{payment.client_name_xx}</span>
                                  </div>
                                </div>
                                <div className="right">
                                  <span
                                    className={`doc-status ${payment.hd_status}`}
                                  >
                                    {payment.hd_status}
                                  </span>
                                </div>
                              </div>
                              <div className="second-row">
                                <div className="second-row-col1">
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                    }}
                                  >{`No ${payment.hd_no}`}</span>
                                  <span>{`Paying For ${payment.hd_paying_for}`}</span>
                                  <span>{`Branch ${payment.bizunit_name_xx}`}</span>
                                </div>
                                <div
                                  className="second-row-col2"
                                  style={{ flexGrow: 1 }}
                                >
                                  <span>{`Bank A/C ${payment.bank_ac_no_xx}`}</span>
                                  <span>{`Bank ${payment.bank_alias_xx}`}</span>
                                  <span>{`Branch ${payment?.bank_branch_name_xx}`}</span>
                                </div>
                                <div className="second-row-col3">
                                  <span>
                                    {`Date ${moment(payment?.hd_gl_date).format(
                                      "DD-MMM-YYYY"
                                    )}`}
                                  </span>
                                  <span>{`Mode ${payment.hd_mode}`}</span>
                                  <span>{`Ref No ${payment.hd_ref_no}`}</span>
                                </div>

                                <div className="second-row-col4">
                                  <span>{`CHQ No ${payment.hd_chq_no}`}</span>
                                  <span>
                                    {`CHQ Date ${moment(payment?.hd_chq_date).format(
                                      "DD-MMM-YYYY"
                                    )}`}
                                  </span>
                                </div>

                                <div className="second-row-col5">
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                    }}
                                  >{`${payment.hd_cur_code} ${numeral(
                                    payment.hd_fc_amount
                                  ).format("0,0.00")}`}</span>
                                  {payment.hd_cur_code !==
                                    currentUser.currency_code_xx && (
                                      <span>{`Base ${numeral(payment.hd_lc_amount).format(
                                        "0,0.00"
                                      )}`}</span>
                                    )}
                                </div>
                              </div>
                              <div className="third-row">
                                <span
                                  style={{
                                    fontWeight: "500",
                                    maxWidth: "250px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                >{`Narration ${payment.hd_narration}`}</span>
                                <div className="user">
                                  <span>{`Created By : ${payment.created_by_name_xx}`}</span>
                                  <img
                                    src={
                                      payment?.created_by_photo_url_xx ||
                                      "/img/noavatar.jpg"
                                    }
                                    alt=""
                                  />
                                  <span>{payment.time_ago_xx}</span>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    {/* <TablePagination
                      rowsPerPageOptions={[6]}
                      component="div"
                      count={slicedPayments?.length}
                      rowsPerPage={rowsPerPage}
                      rows={slicedPayments}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    /> */}
                  </TableContainer>
                </div>

              </div>
            </div>
          </div>
        )}
      </div>
      {/* Dialog to notify user about changes being saved */}
      <Dialog open={isNotifyDialogOpen} onClose={handleCloseNotifyDialog}>
        <DialogTitle>{notifyDialogTitle}</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: notifyDialogText }} />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseNotifyDialog}>Close</CustomButton>
        </DialogActions>
      </Dialog>
    </motion.div>
  );
};

export default GlStatement;
