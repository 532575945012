import "./UwPolicyList.scss";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import newRequest from "../../../utils/newRequest";
import { IoClose } from "react-icons/io5";
import { CustomButton, SearchButton } from "../../../components/ui/CustomButton";
import AddIcon from "@mui/icons-material/Add";
import ExportToExcelButton from "../../../components/exportToExcelButton/ExportToExcelButton";
import { motion } from "framer-motion";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { ScaleLoader } from "react-spinners";
import VideoPlayer from "../../../components/videoPlayer/VideoPlayer";
import { sanitizeText } from "../../../utils/genericFunctions";
import ConfirmDialog from "../../../components/confirmDialog/confirmDialog";
import { GrNotes } from "react-icons/gr";

const UwPolicyList = () => {
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const [riskNotes, setRiskNotes] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
  const [notifyDialogTitle, setNotifyDialogTitle] = useState("Confirm");
  const [notifyDialogText, setNotifyDialogText] = useState("Text");
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogAction, setConfirmDialogAction] = useState("None");
  const [objectToAction, setObjectToAction] = useState({
    object_id: "",
    action_text: "",
    new_doc_status: "",
  });

  const fetchRiskNotes = async () => {
    try {
      setIsLoading(true);
      await newRequest
        .post("/uwPolicy/findAll", {
          pl_org_code: currentUser.usr_org_code,
          pl_type: "rn",
          result_limit: 4
        })
        .then((res) => {
          setRiskNotes(res.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        });
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    fetchRiskNotes();
  }, []);


  const handleCloseNotifyDialog = () => {
    setIsNotifyDialogOpen(false);
  };
  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const handleOpenOverlay = () => {
    setIsOverlayOpen(true);
  };

  const handleConfirm = async () => {
    try {
      if (confirmDialogAction === "underReview") {

      }
    } catch (err) {
      console.log(err);
    }
    // Close the dialog
    setIsConfirmDialogOpen(false);
  };

  const handleCancel = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleSearchRiskNotes = async () => {
    try {
      setIsLoading(true);
      if (searchTerm) {
        const res = await newRequest.post("/uwPolicy/search", { search_input: searchTerm });
        setRiskNotes(res.data);
      } else {
        fetchRiskNotes();
      }
    } catch (error) {
      console.error("Error searching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <motion.div
      className="uw-policy-list"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      {isLoading ? (
        <div className="no-content">
          <ScaleLoader
            color="#F44336"
            height={50}
            radius={8}
            width={4}
          />
        </div>
      ) : (
        <div className="uw-policy-list-main-wrapper">
          <motion.div className="list-wrapper"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 1 } }}
            exit={{ opacity: 0, transition: { duration: 1 } }}>
            <div className="list-navbar">
              <div className="list-navbar-left"><span className="main-title">Risk Notes</span></div>
              <div className="list-navbar-right">
                <CustomButton startIcon={<RefreshIcon />} onClick={fetchRiskNotes}>
                  Refresh
                </CustomButton>
                <CustomButton
                  startIcon={<AddIcon />}
                  onClick={() => {
                    try {
                      navigate(`/uw/rn/new`, {
                        state: null,
                      });
                    } catch (error) {
                      console.log(error)
                    }
                  }}
                >
                  New Risk Note
                </CustomButton>
              </div>
            </div>
            <div className="list-content">
              <GrNotes className="icon" />
              <span className="title">Create & Manage Risk Notes</span>
              <p>Easily create, manage, and share risk notes. Streamline your workflow with our intuitive tools, ensuring accurate and detailed documentation for every client. Empower your business with efficiency and precision in risk management.</p>
              <div className="search-input-wrapper">
                <SearchIcon />
                <input
                  className="search-input"
                  type="text"
                  placeholder="Search documents..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') handleSearchRiskNotes();
                  }}
                />
                {searchTerm.length > 0 && (
                  <CloseIcon
                    onClick={(e) => {
                      try {
                        fetchRiskNotes();
                        setSearchTerm("");
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                    className="search-input-icon"
                  />
                )}
                {searchTerm.length === 0 && (
                  <span className="search-input-icon" />
                )}
                <SearchButton
                  width={"200px"}
                  themeColor={"#9c27b0"}
                  onClick={handleSearchRiskNotes}>
                  Search
                </SearchButton>
              </div>
              <div className="cards-wrapper">
                {riskNotes.map((row) => (
                  <div className={`single-card c${row.end_internal_code_xx}`}
                    key={row._id}
                    onClick={() => {
                      try {
                        navigate(`/uw/rn/${row._id}`, {
                          state: row,
                        });
                      } catch (error) {
                        console.log(error)
                      }
                    }}
                  >
                    <div className="single-card-top">
                      <span className="category">
                        <h1 className={`main-title c${row.end_internal_code_xx}`}>
                          {row.product_name_xx}
                        </h1>
                        <h1 className="sub-title-twolines" style={{ fontSize: "12px" }}>
                          {row.end_name_xx}
                        </h1>
                      </span>
                    </div>
                    <div className="single-card-center">
                      <h1 className="sub-title-twolines" style={{ fontSize: "16px", color: "inherit", margin: "5px 0px" }}>
                        {`INSURED: ${row.insured_name_xx}`}
                      </h1>
                      <h1 className="sub-title-twolines" style={{ fontSize: "12px", margin: "7px 0px" }}>
                        {`CARRIER: ${row.carrier_name_xx}`}
                      </h1>
                      <span className="main-title">{row.pl_risk_note_no}</span>
                      <span className={`status ${row.pl_status}`}>{row.pl_status}</span>
                    </div>
                    <div className="single-card-bottom">
                      <div className="bottom-title">
                        <span>worked on by...</span>
                      </div>
                      <div className="avatar-container">
                        <div className="left">
                          <img src={row.updated_by_photo_url_xx} alt="avatar" className="avatar" />
                        </div>
                        <div className="right">
                          <span className="username">{row.updated_by_name_xx}</span>
                          <span className="time-ago">{row.time_ago_xx}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="list-footer">
                <button className="overlay-button" onClick={handleOpenOverlay}>
                  How it works
                </button>
              </div>
            </div>
          </motion.div>

          {isOverlayOpen && (
            <motion.div
              className="overlay-dialog"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 1 } }}
              exit={{ opacity: 0, transition: { duration: 1 } }}
            >
              <div className="overlay-wrapper">
                <div className="overlay-navbar">
                  <span>HELP</span>
                  <span onClick={() => setIsOverlayOpen(false)}>
                    <IoClose
                      style={{
                        width: "30px",
                        height: "30px",
                        cursor: "pointer",
                        borderRadius: "50%",
                      }}
                    />
                  </span>
                </div>
                <div className="overlay-content">
                  <VideoPlayer videoUrl="https://www.youtube.com/embed/PPCfDe8TfJQ" />
                </div>
                <div className="overlay-action">
                </div>
              </div>
            </motion.div>
          )}
        </div>
      )}
      {/* Dialog to notify user about changes being saved */}
      <Dialog open={isNotifyDialogOpen} onClose={handleCloseNotifyDialog}>
        <DialogTitle>{notifyDialogTitle}</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: sanitizeText(notifyDialogText) }} />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseNotifyDialog}>Close</CustomButton>
        </DialogActions>
      </Dialog>
      {/* Confirm Dialog */}
      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        dialogTitle="Confirmation"
        dialogContent={<p>{objectToAction.action_text}</p>}
      />
    </motion.div>
  );
};

export default UwPolicyList;
