import "./BusinessUnits.scss";
import { useSelector } from "react-redux";
import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  Select,
  MenuItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from '@mui/icons-material/Edit';
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import ReactSelect from "react-select";
import { GridLoader, ScaleLoader } from "react-spinners";
import newRequest from "../../utils/newRequest.js";
import { showToast } from "../../toastService.js";
import { CustomButton } from "../ui/CustomButton.jsx";
import ConfirmDialog from "../confirmDialog/confirmDialog.jsx";
import { customSelectStyles } from "../ui/uiStyleElements.jsx";

const BusinessUnits = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogAction, setConfirmDialogAction] = useState("None");
  const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
  const [notifyDialogTitle, setNotifyDialogTitle] = useState("Confirm");
  const [notifyDialogText, setNotifyDialogText] = useState("Text");
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [currencyAction, setCurrencyAction] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [orgUnits, setOrgUnits] = useState([]);
  const [selectedOrgUnit, setSelectedOrgUnit] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [objectToAction, setObjectToAction] = useState({
    object_id: "",
    action_text: "",
    action_invoked: "",
  });

  // Organization Unit
  const [openAddUnit, setOpenAddUnit] = useState(false);
  const [unitCode, setUnitCode] = useState("");
  const [unitName, setUnitName] = useState("");
  const [unitType, setUnitType] = useState(null);
  const [unitAddress1, setUnitAddress1] = useState("");
  const [unitAddress2, setUnitAddress2] = useState("");
  const [unitEmail, setUnitEmail] = useState("");
  const [unitPhone, setUnitPhone] = useState("");
  const [unitStatus, setUnitStatus] = useState(null);

  // BizUnitStatus
  const [statusOptions, setStatusOptions] = useState([]);
  const fetchBizUnitStatus = async () => {
    try {
      const res = await newRequest.post("/sysCode/findAll", { sys_type: "Biz Unit Status" });

      const options = res.data.map((unitSts) => ({
        value: unitSts.sys_code,
        label: unitSts.sys_name,
      }));

      setStatusOptions(options);
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };


  // BizUnitType
  const [unitTypeOptions, setUnitTypeOptions] = useState([]);
  const fetchBizUnitType = async () => {
    try {
      const res = await newRequest.post(
        "/sysCode/findAll", { sys_type: "Biz Unit Type", });

      const options = res.data.map((unitSts) => ({
        value: unitSts.sys_code,
        label: unitSts.sys_name,
      }));

      setUnitTypeOptions(options);
    } catch (error) {
      console.error("Error fetching type types:", error);
    }
  };

  const handleUploadDoalogClose = () => {
    setIsUploadDialogOpen(false);
  };


  const fetchOrganizationUnits = async () => {
    try {
      const res = await newRequest.post(
        "/orgUnit/findAll", { unit_status: "" }
      );

      setOrgUnits(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [currentOrgUnitPage, setCurrentOrgUnitPage] = useState(0);
  const handleOrgUnitsPageChange = (event, newPage) => {
    setCurrentOrgUnitPage(newPage);
  };

  const slicedOrgUnits = orgUnits.slice(
    currentOrgUnitPage * rowsPerPage,
    currentOrgUnitPage * rowsPerPage + rowsPerPage
  );

  const handleOrgUnitsRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setCurrentOrgUnitPage(0);
  };


  useEffect(() => {
    fetchOrganizationUnits();
    fetchBizUnitStatus();
    fetchBizUnitType();
  }, []);

  const handleConfirm = async () => {
    try {
      if (confirmDialogAction === "deleteNumGen") {

      }
    } catch (err) {
      console.log(err);
    }
    // Close the dialog
    setIsConfirmDialogOpen(false);
  };

  const handleCancel = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleCloseNotifyDialog = () => {
    setIsNotifyDialogOpen(false);
  };


  const handleOrgUnitsChange = (e, rowId) => {
    try {
      const { name, value } = e.target;
      const updatedData = [...orgUnits];
      const rowIndex = updatedData.findIndex(item => item._id === rowId);

      if (rowIndex === -1) {
        throw new Error('Row not found');
      }
      updatedData[rowIndex][name] = value;
      updatedData[rowIndex]["changed_xx"] = "Y";

      setOrgUnits(updatedData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOrgUnitsBlur = async (e, rowId) => {
    try {
      const { name, value } = e.target;
      const updatedData = [...orgUnits];
      const rowIndex = updatedData.findIndex(item => item._id === rowId);

      if (rowIndex === -1) {
        throw new Error('Row not found');
      }
      const res = await newRequest.put(
        `/orgUnit/upd/${rowId}`,
        {
          unit_name: value,
          updatedBy: currentUser.usr_id,
        }
      );

    } catch (error) {
      console.log(error);
    }
  };


  const handleBizUnitAction = async () => {
    try {

      // Check for both insert and update

      if (!unitName) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter name!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      if (!unitType) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please select type!`);
        setIsNotifyDialogOpen(true);
        return;
      }
      // console.log(`currencyAction is ${currencyAction}`)
      if (currencyAction === "addUnit") {
        if (!unitCode) {
          setNotifyDialogTitle("Note!");
          setNotifyDialogText(`Please enter unique code!`);
          setIsNotifyDialogOpen(true);
          return;
        }
        const res = await newRequest.post(
          "/orgUnit/add",
          {
            unit_org_code: currentUser.usr_org_code,
            unit_code: unitCode,
            unit_name: unitName,
            unit_type: unitType?.value,
            unit_address1: unitAddress1,
            unit_address2: unitAddress2,
            unit_email: unitEmail,
            unit_phone: unitPhone,
            unit_status: unitStatus?.value,
            userCode: currentUser.usr_id,
          }
        );
        if (res.data === "exists") {
          setNotifyDialogTitle("Note!");
          setNotifyDialogText(`<strong>${unitCode}</strong> is not unique. Choose another code!`);
          setIsNotifyDialogOpen(true);
          return;
        } else {
          setSelectedOrgUnit(null);
          setCurrencyAction("none");
          setUnitCode("")
          setUnitAddress1("")
          setUnitEmail("")
          setUnitPhone("")
          setUnitStatus(null)
          fetchOrganizationUnits();
          setOpenAddUnit(false);
          showToast(`${unitType?.label} ${unitName} added!`, "success");
          setUnitName("")
          setUnitType(null)
        }
      } else if (currencyAction === "editUnit") {
        const res = await newRequest.put(
          `/orgUnit/upd/${selectedOrgUnit._id}`,
          {
            unit_name: unitName,
            unit_type: unitType?.value,
            unit_address1: unitAddress1,
            unit_address2: unitAddress2,
            unit_email: unitEmail,
            unit_phone: unitPhone,
            unit_status: unitStatus?.value,
            updatedBy: currentUser.usr_id,
          }
        );
        setSelectedOrgUnit(null);
        setCurrencyAction("none");
        setUnitCode("")
        setUnitAddress1("")
        setUnitEmail("")
        setUnitPhone("")
        setUnitStatus(null)
        fetchOrganizationUnits();
        setOpenAddUnit(false);
        showToast(`${unitType?.label} ${unitName} updated!`, "success");
        setUnitName("")
        setUnitType(null)
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  return (
    <div className="business-units">
      {isLoading ? (
        <div className="no-content"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <ScaleLoader
            color="#F44336"
            height={50}
            radius={8}
            width={4}
          />
        </div>
      ) : (
        <div className="business-units-main-wrapper">
          {/* Business Units */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              justifyContent: "flex-start",
            }}
          >
            {["admin", "superadmin"].includes(currentUser.user_type_xx) && <Tooltip title={`Add Business Unit`} placement="right">
              <AddIcon
                size={50}
                style={{
                  borderRadius: "5px",
                  padding: "3px",
                  alignSelf: "center",
                  cursor: "pointer",
                  backgroundColor: "rgba(0, 128, 0, 0.5)",
                }}
                onClick={async () => {
                  try {
                    setCurrencyAction("addUnit");
                    setUnitType({
                      value: "Branch",
                      label: "Branch",
                    });
                    setUnitStatus({
                      value: "Active",
                      label: "Active",
                    })
                    setOpenAddUnit(true);
                  } catch (error) {
                    console.log(error);
                  }
                }}
              />
            </Tooltip>}
            <h2 className="sub-titles" style={{ margin: "10px 0px" }}>ADD</h2>
          </div>
          <div className="table-content">
            <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none' }} className="table-container">
              <Table sx={{ width: "max-content", border: 'none' }}>
                <TableHead className="tablehead">
                  <TableRow className="tablerow">
                    <TableCell className="table-cell" style={{ display: "flex", maxWidth: "50px", fontWeight: "bold", alignItems: "center", justifyContent: "center" }}>
                      #
                    </TableCell>
                    <TableCell className="table-cell" style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", maxWidth: "200px", flexDirection: "row" }}>
                      CODE
                    </TableCell>
                    <TableCell className="table-cell" style={{ fontWeight: "bold", width: "auto", display: 'flex' }}>
                      NAME
                    </TableCell>
                    <TableCell className="table-cell" style={{ fontWeight: "bold", alignItems: "center", display: 'flex', maxWidth: "150px", justifyContent: "center" }}>
                      STATUS
                    </TableCell>
                    {["admin", "superadmin"].includes(currentUser.user_type_xx) && <TableCell className="table-cell" style={{ fontWeight: "bold", alignItems: "center", display: 'flex', maxWidth: "100px", justifyContent: "center" }}>
                      ACTION
                    </TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody className="tablebody">
                  {slicedOrgUnits?.map((row, index) => (
                    <TableRow
                      key={row._id}
                      className={`${selectedOrgUnit?._id === row._id ? "tablerow current" : "tablerow"}`}
                      onClick={() => {
                        try {
                          setSelectedOrgUnit(row);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                    >
                      <TableCell className="table-cell" style={{ display: "flex", maxWidth: "50px", fontWeight: "bold", alignItems: "center", justifyContent: "center" }}>
                        {currentOrgUnitPage * rowsPerPage + index + 1}
                      </TableCell>
                      <TableCell className="table-cell" style={{ display: "flex", fontWeight: "bold", maxWidth: "200px", flexDirection: "column" }}>
                        {/* <input
                              id="unit_code"
                              name="unit_code"
                              value={row.unit_code}
                              onChange={(e) => handleOrgUnits(e, index)}
                              style={{ width: "100%" }}
                              className="form-input-item"
                              disabled
                            /> */}
                        <span>{row.unit_code}</span>
                      </TableCell>
                      <TableCell className="table-cell" style={{ display: "flex", fontWeight: "bold", width: "auto", flexDirection: "column" }}>
                        <input
                          id="unit_name"
                          name="unit_name"
                          value={row.unit_name}
                          onChange={(e) => handleOrgUnitsChange(e, row._id)}
                          onBlur={(e) => handleOrgUnitsBlur(e, row._id)}
                          style={{ width: "100%" }}
                          className="form-input-item"
                          disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                        />
                      </TableCell>
                      <TableCell className="table-cell" style={{ display: "flex", fontWeight: "bold", maxWidth: "150px", justifyContent: "center" }}>
                        <Select
                          id="unit_status"
                          name="unit_status"
                          value={row.unit_status}
                          onChange={(e) => handleOrgUnitsChange(e, row._id)}
                          variant="outlined"
                          size="small"
                          disabled
                        >
                          <MenuItem value="Active">Active</MenuItem>
                          <MenuItem value="Suspended">Suspended</MenuItem>
                          <MenuItem value="Closed">Closed</MenuItem>
                        </Select>
                      </TableCell>
                      {["admin", "superadmin"].includes(currentUser.user_type_xx) && <TableCell className="table-cell" style={{ display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: 'space-around', padding: "3px", maxWidth: "100px", }}>
                        <Tooltip
                          title={`Edit ${row.unit_name}?`}
                          placement="right"
                        >
                          <EditIcon
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "5px",
                              padding: "3px",
                              alignSelf: "center",
                              cursor: "pointer",
                              color: "rgba(0, 128, 0, 1)",
                              backgroundColor: "rgba(0, 128, 0, 0.5)",
                            }}
                            onClick={() => {
                              try {
                                setSelectedOrgUnit(row);
                                setCurrencyAction("editUnit");

                                setUnitCode(row.unit_code)
                                setUnitName(row.unit_name)
                                setUnitType({
                                  value: row.unit_type,
                                  label: row.unit_type,
                                })
                                setUnitAddress1(row.unit_address1)
                                setUnitEmail(row.unit_email)
                                setUnitPhone(row.unit_phone)
                                setUnitStatus({
                                  value: row.unit_status,
                                  label: row.unit_status,
                                })
                                setOpenAddUnit(true);
                              } catch (error) {
                                console.log(error);
                              }
                            }}
                          />
                        </Tooltip>
                      </TableCell>}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5]}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleOrgUnitsRowsPerPage}
                component="div"
                count={orgUnits?.length}
                rows={orgUnits}
                page={currentOrgUnitPage}
                onPageChange={handleOrgUnitsPageChange}
              />
            </TableContainer>
          </div>
          {/* End of Business Units */}
        </div>
      )}

      {/* Dialog to notify user about changes being saved */}
      <Dialog open={isNotifyDialogOpen} onClose={handleCloseNotifyDialog}>
        <DialogTitle>{notifyDialogTitle}</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: notifyDialogText }} />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseNotifyDialog}>Close</CustomButton>
        </DialogActions>
      </Dialog>
      {/* Confirm Dialog */}
      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        dialogTitle="Confirmation"
        dialogContent={<p>{objectToAction.action_text}</p>}
      />
      {/* add organization unit */}
      {openAddUnit && (
        <motion.div
          className="new-rate-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="new-rate-wrapper">
            <div className="new-rate-navbar">
              {currencyAction === "addUnit" && <span>ADD BUSINESS UNIT</span>}
              {currencyAction === "editUnit" && <span style={{ textTransform: "uppercase" }}>{`EDIT ${selectedOrgUnit.unit_name}`}</span>}
              <span
                onClick={() => {
                  try {
                    setSelectedOrgUnit(null);
                    setCurrencyAction("none");
                    setUnitCode("")
                    setUnitName("")
                    setUnitType(null)
                    setUnitAddress1("")
                    setUnitEmail("")
                    setUnitPhone("")
                    setUnitStatus(null)
                    setOpenAddUnit(false);
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                <IoClose
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    borderRadius: "50%",
                  }}
                />
              </span>
            </div>
            <div className="new-rate-content">
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                <div className="input-item-with-label">
                  <label htmlFor="unit_code" style={{ width: "100px" }}>
                    CODE
                  </label>
                  <input
                    name="unit_code"
                    type="text"
                    placeholder="CODE"
                    className="form-input-item"
                    style={{ width: "130px" }}
                    value={unitCode}
                    onChange={(e) => setUnitCode(e.target.value)}
                    disabled={currencyAction === "editUnit"}
                  />
                </div>
                <div className="input-item-with-label">
                  <label
                    htmlFor="unit_status"
                    style={{ width: "80px" }}
                  >
                    STATUS
                  </label>
                  <ReactSelect
                    id="unit_status"
                    name="unit_status"
                    styles={customSelectStyles}
                    width="155px"
                    value={unitStatus}
                    onChange={(selectedOption) => {
                      try {
                        setUnitStatus(selectedOption);
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                    options={statusOptions}
                    isSearchable={false}
                    placeholder="STATUS"
                  />
                </div>
              </div>
              <div className="input-item-with-label">
                <label htmlFor="unit_name" style={{ width: "100px" }}>
                  NAME
                </label>
                <input
                  name="unit_name"
                  type="text"
                  placeholder="NAME"
                  className="form-input-item"
                  style={{ width: "370px" }}
                  value={unitName}
                  onChange={(e) => setUnitName(e.target.value)}
                />
              </div>
              <div className="input-item-with-label">
                <label
                  htmlFor="unit_type"
                  style={{ width: "100px" }}
                >
                  TYPE
                </label>
                <ReactSelect
                  id="unit_type"
                  name="unit_type"
                  styles={customSelectStyles}
                  width="370px"
                  value={unitType}
                  onChange={(selectedOption) => {
                    try {
                      setUnitType(selectedOption);
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                  options={unitTypeOptions}
                  isSearchable={false}
                  placeholder="TYPE"
                />
              </div>
              <div className="input-item-with-label">
                <label htmlFor="unit_address1" style={{ width: "100px", alignSelf: "flex-start" }}>
                  ADDRESS
                </label>
                <textarea
                  name="unit_address1"
                  type="text"
                  placeholder="ADDRESS"
                  className="form-input-item"
                  style={{ width: "370px" }}
                  value={unitAddress1}
                  rows={3}
                  onChange={(e) => setUnitAddress1(e.target.value)}
                />
              </div>
              <div className="input-item-with-label">
                <label htmlFor="unit_email" style={{ width: "100px" }}>
                  EMAIL
                </label>
                <input
                  name="unit_email"
                  type="email"
                  placeholder="EMAIL"
                  className="form-input-item"
                  style={{ width: "370px" }}
                  value={unitEmail}
                  onChange={(e) => setUnitEmail(e.target.value)}
                />
              </div>
              <div className="input-item-with-label">
                <label htmlFor="unit_phone" style={{ width: "100px" }}>
                  PHONE
                </label>
                <input
                  name="unit_phone"
                  type="email"
                  placeholder="PHONE"
                  className="form-input-item"
                  style={{ width: "370px" }}
                  value={unitPhone}
                  onChange={(e) => setUnitPhone(e.target.value)}
                />
              </div>
            </div>
            <div className="new-rate-action">
              <CustomButton onClick={handleBizUnitAction}>{`${currencyAction === "addUnit" ? `ADD ${unitName}` : `UPDATE ${unitName}`}`}</CustomButton>
            </div>
          </div>
        </motion.div>
      )}
      {/* end of adding organization unit */}
      {isProcessing && (
        <motion.div
          className="processing-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="processing-wrapper">
            <GridLoader
              color="#F44336"
              style={{
                height: "50px",
                width: "50px",
              }}
            />
          </div>
        </motion.div>
      )}
      {/* progress dialog for file upload  */}
      <UploadDialog
        open={isUploadDialogOpen}
        handleClose={handleUploadDoalogClose}
        uploadProgress={uploadProgress}
      />
    </div>
  );
};

const UploadDialog = ({ open, handleClose, uploadProgress }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Progress</DialogTitle>
      <DialogContent>
        <LinearProgress variant="determinate" value={uploadProgress} />
        {uploadProgress && <p>{uploadProgress}% Complete</p>}
      </DialogContent>
    </Dialog>
  );
};

export default BusinessUnits;
