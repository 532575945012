import React, { forwardRef, useImperativeHandle } from 'react';
import "./UwPolicyCond.scss";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../../../components/ui/CustomButton";
import newRequest from "../../../utils/newRequest";
import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import { IoClose } from "react-icons/io5";
import QueueIcon from "@mui/icons-material/Queue";
import SearchIcon from "@mui/icons-material/Search";
import { showToast } from "../../../toastService.js";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  Tooltip,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GridLoader } from "react-spinners";
import { motion } from "framer-motion";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { customSelectStyles } from "../../../components/ui/uiStyleElements";
import { generateUniqueCode } from "../../../utils/genericFunctions";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill's CSS
// Import Quill's font color and indent modules
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.bubble.css';


const UwPolicyCond = forwardRef(({ quoteData, refreshQuote, profileLimitValues }, ref) => {

  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [conditions, setConditions] = useState([]);
  const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
  const [notifyDialogTitle, setNotifyDialogTitle] = useState("Confirm");
  const [notifyDialogText, setNotifyDialogText] = useState("Text");
  const [addAsDefault, setAddAsDefault] = useState(false);
  const [condDocId, setCondDocId] = useState("");
  const [condName, setCondName] = useState("");
  const [condType, setCondType] = useState(null);
  const [condDesc, setCondDesc] = useState("");
  const [condRefNo, setCondRefNo] = useState("");
  const [wordingTypes, setWordingTypes] = useState([]);
  const [showCondDetails, setShowCondDetails] = useState(false);
  const [condAction, setCondAction] = useState("-1");
  // Extend Quill with font color and indent modules
  const modules = {
    toolbar: [
      // [{ 'font': [] }],
      [{ 'size': ['small', 'medium', 'large', 'huge'] }],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'align': [] }],
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
      ['link'],
      // ['link', 'image'],
      ['clean']
    ],
  };

  // close notify dialog
  const handleCloseNotifyDialog = () => {
    setIsNotifyDialogOpen(false);
  };


  // Function to get limit_yn value by limit_code
  const getLimitValue = (limitCode) => {
    try {
      // console.log(`getLimitValue profileLimitValues ${JSON.stringify(profileLimitValues, null, 2)}`);
      const limit = profileLimitValues.find(l => l.limit_code === limitCode);
      return limit ? limit.limit_yn : null;
    } catch (error) {
      console.log(error)
    }
  };

  const fetchPolicyConditions = async () => {
    try {
      setIsProcessing(true);

      const res = await newRequest.post(
        "/uwPolicyCond/findAll",
        {
          cond_org_code: currentUser.usr_org_code,
          cond_policy_docid: quoteData._id,
        }
      );
      setConditions(res.data);
    } catch (error) {
      console.error("Error fetching entity types:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  const pickDefaultConditions = async () => {
    try {
      setIsProcessing(true);
      const res = await newRequest.post(
        "/uwPolicy/pickConds",
        {
          org_code: currentUser.usr_org_code,
          product_code: quoteData.pl_product_code,
          policy_docid: quoteData._id,
          risk_note_no: quoteData.pl_risk_note_no,
          pl_end_no: quoteData.pl_end_no,
          userCode: currentUser.usr_id,
        }
      );
      fetchPolicyConditions();
      showToast(`Default conditions, wordings and warranties added to this document!`, "success");
    } catch (error) {
      console.error("Error fetching conditions :", error);
    } finally {
      setIsProcessing(false);
    }
  };



  const fetchWordingType = async () => {
    try {
      const res = await newRequest.post(
        "/sysCode/findAll",
        {
          sys_type: "Wording Type",
        }
      );

      const options = res.data.map((wt) => ({
        value: wt.sys_code,
        label: wt.sys_name,
      }));

      setWordingTypes(options);
    } catch (error) {
      console.error("Error fetching wording types:", error);
    }
  };

  const [searchProductCond, setSearchProductCond] = useState("");
  const [filteredProductConds, setFilteredProductConds] = useState([]);
  const [showProductConds, setShowProductConds] = useState(false);
  const [productConditions, setProductConditions] = useState([]);

  const fetchProductConditions = async () => {
    try {
      const res = await newRequest.post(
        "/uwProductCond/findAll",
        {
          cond_org_code: currentUser.usr_org_code,
          cond_pr_code: quoteData.pl_product_code,
          policy_docid: quoteData._id,
        }
      );
      setProductConditions(res.data);
      setShowProductConds(true);
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };

  const handleAddCondition = async (e, cond) => {
    try {
      const res = await newRequest.post(
        "/uwPolicyCond/add",
        {
          cond_org_code: currentUser.usr_org_code,
          cond_policy_docid: quoteData._id,
          cond_risk_note_no: quoteData.pl_risk_note_no,
          cond_code: cond.cond_code,
          cond_ref_no: cond.cond_ref_no,
          cond_name: cond.cond_name,
          cond_desc: cond.cond_desc,
          cond_type: cond.cond_type,
          cond_order: cond.cond_order,
          cond_mandatory: cond.cond_mandatory,
          cond_end_no: quoteData.pl_end_no,
          createdBy: currentUser.usr_id,
        }
      );

      if (res.data === "exists") {
        showToast(`${cond.cond_type} ${cond.cond_name} already part of this document!`, "warning");
      } else {
        fetchPolicyConditions();
        fetchProductConditions();
        showToast(`${cond.cond_type} ${cond.cond_name} added to the document!`, "success");
      }

    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };

  const handleRemoveCondition = async (e, cond) => {
    try {
      const res = await newRequest.delete(`/uwPolicyCond/del/${cond._id}`);
      fetchPolicyConditions();
    } catch (error) {
      console.error("Error removing condition :", error);
    }
  };

  //   search product conditions
  useEffect(() => {
    const filteredResults = productConditions.filter(
      (item) =>
        item.cond_name
          ?.toLowerCase()
          .includes(searchProductCond.toLowerCase()) ||
        item.cond_ref_no
          ?.toLowerCase()
          .includes(searchProductCond.toLowerCase())
    );
    setFilteredProductConds(filteredResults);
  }, [productConditions, searchProductCond]);

  const slicedProductConds = filteredProductConds.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    try {
      setIsLoading(true)
      fetchPolicyConditions();
      fetchWordingType();
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }, [quoteData._id]);

  //   search policy conditions
  useEffect(() => {
    const filteredResults = conditions?.filter(
      (item) =>
        item.cond_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.cond_ref_no?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filteredResults);
  }, [conditions, searchTerm]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const slicedData = filteredData?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // editing dialog

  const [selectedRow, setSelectedRow] = useState(null);

  const handleCloseDialog = () => {
    try {
      setSearchProductCond("");
      setShowProductConds(false);
    } catch (error) {
      console.log(error)
    }
  };

  // end of dialog


  const handleCancel = () => {
    try {
      setCondDocId("");
      setCondName("");
      setCondType(null);
      setCondDesc("");
      setCondRefNo("");
      setSelectedRow(null);
      setShowCondDetails(false)
      setCondAction("-1")
    } catch (error) {
      console.log(error)
    }
  };

  const handleClearSearchTerm = () => {
    setSearchTerm("");
  };

  const handleCondAction = async () => {
    try {
      // console.log(`condDocId  ${JSON.stringify(condDocId, null, 2)}`);
      // console.log(`condName  ${JSON.stringify(condName, null, 2)}`);
      // console.log(`condType  ${JSON.stringify(condType, null, 2)}`);
      // console.log(`condDesc  ${JSON.stringify(condDesc, null, 2)}`);
      // console.log(`condRefNo  ${JSON.stringify(condRefNo, null, 2)}`);

      if (!condName) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText("Please enter name");
        setIsNotifyDialogOpen(true);
        return;
      }
      if (!condType) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText("Please select type");
        setIsNotifyDialogOpen(true);
        return;
      }

      if (condAction === "add") {
        let condCode = "-1";
        if (addAsDefault) {
          // add to product
          const res = await newRequest.post(
            "/uwProductCond/add",
            {
              cond_pr_code: quoteData.pl_product_code,
              cond_ref_no: condRefNo,
              cond_name: condName,
              cond_desc: condDesc,
              cond_type: condType?.value,
              cond_order: 10,
              cond_mandatory: "N",
              cond_default: "Y",
              cond_enabled: "Y",
            }
          );
          condCode = res.data.cond_code;
        }


        if (condCode === "-1") {
          condCode = generateUniqueCode();
        }


        const res = await newRequest.post(
          "/uwPolicyCond/add",
          {
            cond_policy_docid: quoteData._id,
            cond_risk_note_no: quoteData.pl_risk_note_no,
            cond_code: condCode,
            cond_ref_no: condRefNo,
            cond_name: condName,
            cond_desc: condDesc,
            cond_type: condType?.value,
            cond_order: 10,
            cond_mandatory: "N",
            cond_end_no: quoteData.pl_end_no,
          }
        );
      } else {
        const res = await newRequest.put(
          `/uwPolicyCond/upd/${condDocId}`,
          {
            cond_ref_no: condRefNo,
            cond_name: condName,
            cond_desc: condDesc,
            cond_type: condType?.value,
          }
        );
      }
      fetchPolicyConditions();
      setCondDocId("");
      setCondName("");
      setCondType(null);
      setCondDesc("");
      setCondRefNo("");
      setSelectedRow(null);
      setShowCondDetails(false)
      setCondAction("-1")
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };


  const handleWordingType = async (selectedOption) => {
    try {
      if (selectedOption && selectedOption.__isNew__) {
        const resModel = await newRequest.post(
          "sysCode/add",
          {
            sys_org_code: currentUser.usr_org_code,
            sys_type: "Wording Type",
            sys_code: selectedOption.value,
            sys_name: selectedOption.label
          }
        );
        setCondType({
          value: selectedOption.value,
          label: selectedOption.value,
        });
        fetchWordingType();
        showToast(`${selectedOption.value} added to the list!`, "success");
      } else {
        setCondType(selectedOption);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClearSearchProductTerm = () => {
    try {
      setSearchProductCond("");
    } catch (error) {
      console.log(error)
    }
  }

  useImperativeHandle(ref, () => ({
    saveConditionsFromParent
  }));

  const saveConditionsFromParent = async () => {
    try {
      console.log("Invoked from parent")
      // nothing to save because conditions are inserted and updated using dialog
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <motion.div
      className="uw-policy-cond"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      <div className="policy-cond-wrapper">
        <div className="navbar">
          <div className="navbar-left">
            <h1 className='main-title'> CONDITIONS, CLAUSES and/or WARRANTIES</h1>

            {/* <div className="search-input-wrapper">
              <SearchIcon className="search-input-icon" />
              <input
                className="search-input"
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              {searchTerm.length > 0 && (
                <IoClose
                  onClick={handleClearSearchTerm}
                  className="search-input-icon"
                />
              )}
            </div> */}
          </div>
          <div className="navbar-right">
            {quoteData.document_enabled_xx === "Y" && <>
              <CustomButton
                startIcon={<AddIcon />}
                onClick={() => {
                  try {
                    setCondDocId("");
                    setCondName("");
                    setCondType(null);
                    setCondDesc("");
                    setCondRefNo("");
                    setSelectedRow(null);
                    setShowCondDetails(true)
                    setCondAction("add")
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                Add
              </CustomButton>
              <CustomButton
                startIcon={<LibraryAddIcon />}
                onClick={() => {
                  try {
                    fetchProductConditions();
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                Pick
              </CustomButton>
              <CustomButton
                startIcon={<AddCircleIcon />}
                onClick={pickDefaultConditions}
              >
                Add Defaults
              </CustomButton>
            </>}
          </div>
        </div>
        <div className="table-content">
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "200px",
                width: "100%",
                height: "100%",
              }}
            >
              <GridLoader
                color="#F44336"
                style={{
                  height: "50px",
                  width: "50px",
                }}
              />
            </div>
          ) : (
            <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none' }}
              className="table-container">
              <Table sx={{ width: "max-content", border: 'none' }}>
                <TableHead className="tablehead">
                  <TableRow
                    className="tablerow"
                  >
                    <TableCell
                      className="table-cell"
                      style={{
                        display: "flex",
                        maxWidth: "50px",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: "bold",
                      }}
                    >
                      <span>#</span>
                    </TableCell>
                    <TableCell
                      className="table-cell"
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        width: "auto",
                      }}
                    >
                      <span>CONDITION</span>
                    </TableCell>
                    <TableCell
                      className="table-cell"
                      style={{
                        fontWeight: "bold",
                        maxWidth: "120px",
                      }}
                    >
                      <span>REFERENCE</span>
                    </TableCell>
                    <TableCell
                      className="table-cell"
                      style={{
                        fontWeight: "bold",
                        maxWidth: "120px",
                      }}
                    >
                      <span>TYPE</span>
                    </TableCell>
                    <TableCell
                      className="table-cell"
                      style={{
                        fontWeight: "bold",
                        alignItems: "center",
                        textAlign: "center",
                        maxWidth: "100px",
                      }}
                    >
                      <span>MANDATORY</span>
                    </TableCell>
                    <TableCell
                      className="table-cell"
                      style={{
                        fontWeight: "bold",
                        maxWidth: "100px",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <span>ACTION</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="tablebody">
                  {slicedData?.map((cond, index) => (
                    <TableRow
                      key={cond._id}
                      className={`${condDocId === cond._id ? "tablerow current" : "tablerow"}`}
                      onClick={() => {
                        try {
                          setCondDocId(cond._id);
                          setCondName(cond.cond_name);
                          setCondType({
                            label: cond.cond_type,
                            value: cond.cond_type
                          });
                          setCondDesc(cond.cond_desc);
                          setCondRefNo(cond.cond_ref_no);
                          setSelectedRow(cond);
                          setShowCondDetails(true)
                          setCondAction("edit")
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                    >
                      <TableCell
                        className="table-cell"
                        style={{
                          display: "flex",
                          maxWidth: "50px",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: "bold",
                        }}
                      >
                        {/* <span>{cond.cond_order}</span> */}
                        <span>{cond.sr_no}</span>
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        style={{
                          display: "flex",
                          fontWeight: "bold",
                          width: "auto",
                        }}
                      >
                        <span>{cond.cond_name}</span>
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        style={{
                          maxWidth: "120px",
                        }}
                      >
                        <span>{cond.cond_ref_no}</span>
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        style={{
                          fontWeight: "500",
                          maxWidth: "120px",
                          padding: "8px",
                        }}
                      >
                        <span>{cond.cond_type}</span>
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        style={{
                          fontWeight: "500",
                          alignItems: "center",
                          textAlign: "center",
                          padding: "3px",
                          maxWidth: "100px",
                        }}
                      >
                        <div className={`mandatory-cell ${cond.cond_mandatory}`}>
                          <span>{cond.cond_mandatory}</span>
                        </div>
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        style={{
                          alignItems: "center",
                          textAlign: "center",
                          padding: "3px",
                          maxWidth: "100px",
                        }}
                      >
                        {/* Action buttons */}
                        {cond.cond_mandatory === "N" && quoteData.document_enabled_xx === "Y" && (
                          <Tooltip title="Remove Condition">
                            <DeleteIcon
                              style={{
                                height: "25px",
                                width: "25px",
                                alignItems: "center",
                                textAlign: "center",
                                padding: "3px",
                                cursor: "pointer",
                                color: "#b71c1c",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRemoveCondition(e, cond);
                              }}
                            />
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[8]}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                component="div"
                count={filteredData?.length}
                rows={filteredData}
                page={page}
                onPageChange={handleChangePage}
              />
            </TableContainer>
          )}
        </div>
      </div>
      {showCondDetails && (
        <motion.div
          className="edit-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="edit-wrapper">
            <div className="edit-navbar">
              <span>{condAction === "add" ? "ADD CONDITION" : "EDIT CONDITION"}</span>
              <span onClick={handleCancel}>
                <IoClose
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    borderRadius: "50%",
                  }}
                />
              </span>
            </div>
            <div className="edit-content">
              <div className="input-item-with-label">
                <label
                  htmlFor="cond_name"
                  className="form-dropdown-label"
                  style={{ width: "120px" }}
                >
                  CONDITION
                </label>
                <input
                  id="cond_name"
                  name="cond_name"
                  type="text"
                  placeholder="CONDITION"
                  value={condName}
                  className="form-input-item"
                  style={{ width: "490px" }}
                  onChange={(e) => setCondName(e.target.value)}
                  disabled={quoteData.document_enabled_xx === "N"}
                />
              </div>
              <div className="input-item-with-label" >
                <label
                  htmlFor="cond_type"
                  className="form-dropdown-label"
                  style={{ width: "120px" }}
                >
                  TYPE
                </label>
                {getLimitValue("UW1022") === "Y" ?
                  <CreatableSelect
                    id="cond_type"
                    name="cond_type"
                    styles={customSelectStyles}
                    width="160px"
                    value={condType}
                    onChange={handleWordingType}
                    options={wordingTypes}
                    isSearchable={true}
                    isDisabled={quoteData.document_enabled_xx === "N"}
                    placeholder="TYPE"
                  />
                  :
                  <Select
                    id="cond_type"
                    name="cond_type"
                    styles={customSelectStyles}
                    width="160px"
                    value={condType}
                    onChange={(selectedOption) => {
                      try {
                        setCondType(selectedOption);
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                    options={wordingTypes}
                    isSearchable={true}
                    isDisabled={quoteData.document_enabled_xx === "N"}
                    placeholder="TYPE"
                  />
                }
                <div className="input-item-with-label" style={{ alignSelf: "flex-end" }}>
                  <label
                    htmlFor="cond_ref_no"
                    className="form-dropdown-label"
                    style={{ width: "155px" }}
                  >
                    REFERENCE NO
                  </label>
                  <input
                    id="cond_ref_no"
                    name="cond_ref_no"
                    type="text"
                    placeholder="REFERENCE NO"
                    value={condRefNo}
                    className="form-input-item"
                    style={{ width: "170px" }}
                    onChange={(e) => setCondRefNo(e.target.value)}
                    disabled={quoteData.document_enabled_xx === "N"}
                  />
                </div>
              </div>
              {/* <ReactQuill
                value={condDesc}
                onChange={setCondDesc}
                modules={modules}
                className="rich-text-editor"
              /> */}
              <div className="input-item-with-label">
                <label
                  htmlFor="cond_desc"
                  className="form-dropdown-label"
                  style={{ width: "120px", alignSelf: "flex-start" }}
                >
                  TEXT
                </label>
                <textarea
                  id="cond_desc"
                  name="cond_desc"
                  type="text"
                  placeholder="TEXT"
                  value={condDesc}
                  className="form-input-item"
                  style={{ width: "490px" }}
                  rows={10}
                  onChange={(e) => setCondDesc(e.target.value)}
                  disabled={quoteData.document_enabled_xx === "N"}
                />
              </div>
              {condAction === "add" && getLimitValue("UW1023") === "Y" &&
                <div className="input-item-with-label">
                  <label
                    htmlFor="add_dedault"
                    className="form-dropdown-label"
                    style={{ width: "130px", alignSelf: "flex-start" }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addAsDefault}
                        onChange={(e) => setAddAsDefault(e.target.checked)}
                        style={{ color: "#7B1FA2" }}
                      />
                    }
                    label="Add as default"
                  />
                </div>}
              {condAction === "add" && addAsDefault &&
                <div style={{ marginLeft: "130px", maxWidth: "450px", display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "center", marginBottom: "5px" }}>
                  <span dangerouslySetInnerHTML={{ __html: `When you check "ADD AS DEFAULT", this condition will be added as a <strong>default conditions for ${quoteData.product_name_xx}</strong> and will be available everytime you underwrite this product.` }} />
                </div>}
            </div>
            {quoteData.document_enabled_xx === "Y" &&
              <div className="edit-action">
                <CustomButton onClick={handleCancel}>
                  Cancel
                </CustomButton>
                <CustomButton onClick={handleCondAction}>
                  {condAction === "add" ? "Add" : "Update"}
                </CustomButton>
              </div>}
          </div>
        </motion.div>
      )}
      {showProductConds && (
        <motion.div
          className="edit-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="edit-wrapper" style={{ display: "flex", width: "50%", flexDirection: "column" }}>
            <div className="edit-navbar">
              <div className="search-input-wrapper">
                <SearchIcon className="search-input-icon" />
                <input
                  className="search-input"
                  type="text"
                  placeholder="Search..."
                  value={searchProductCond}
                  onChange={(e) => setSearchProductCond(e.target.value)}
                />
                {searchProductCond.length > 0 && (
                  <IoClose
                    onClick={handleClearSearchProductTerm}
                    className="search-input-icon"
                  />
                )}
              </div>
              <span onClick={handleCloseDialog}>
                <IoClose
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    borderRadius: "50%",
                  }}
                />
              </span>
            </div>
            <div className="edit-content">
              <div className="table-content">
                <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none' }}
                  className="table-container">
                  <Table sx={{ width: "max-content", border: 'none' }}>
                    <TableHead className="tablehead">
                      <TableRow
                        className="tablerow"
                      >
                        <TableCell
                          className="table-cell"
                          style={{
                            display: "flex",
                            maxWidth: "50px",
                          }}
                        >
                          <span>#</span>
                        </TableCell>
                        <TableCell
                          className="table-cell"
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            width: "auto",
                          }}
                        >
                          <span>CONDITION</span>
                        </TableCell>
                        <TableCell
                          className="table-cell"
                          style={{
                            fontWeight: "bold",
                            maxWidth: "120px",
                          }}
                        >
                          <span>REFERENCE</span>
                        </TableCell>
                        <TableCell
                          className="table-cell"
                          style={{
                            fontWeight: "bold",
                            maxWidth: "120px",
                          }}
                        >
                          <span>TYPE</span>
                        </TableCell>
                        <TableCell
                          className="table-cell"
                          style={{
                            fontWeight: "bold",
                            alignItems: "center",
                            justifyContent: "center",
                            maxWidth: "100px",
                          }}
                        >
                          <span>MANDATORY</span>
                        </TableCell>
                        <TableCell
                          className="table-cell"
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "3px",
                            maxWidth: "100px",
                          }}
                        >
                          <span>ADD</span>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="tablebody">
                      {slicedProductConds?.map((cond, index) => (
                        <TableRow
                          key={cond._id}
                          className="tablerow"
                        >
                          <TableCell
                            className="table-cell"
                            style={{
                              display: "flex",
                              maxWidth: "50px",
                              fontWeight: "bold"
                            }}
                          >
                            {/* <span>{cond.cond_order}</span> */}
                            <span>{cond.sr_no}</span>
                          </TableCell>
                          <TableCell
                            className="table-cell"
                            style={{
                              display: "flex",
                              fontWeight: "bold",
                              width: "auto",
                            }}
                          >
                            <span>{cond.cond_name}</span>
                          </TableCell>
                          <TableCell
                            className="table-cell"
                            style={{
                              maxWidth: "120px",
                            }}
                          >
                            <span>{cond.cond_ref_no}</span>
                          </TableCell>
                          <TableCell
                            className="table-cell"
                            style={{
                              fontWeight: "500",
                              maxWidth: "120px",
                              padding: "8px",
                            }}
                          >
                            <span>{cond.cond_type}</span>
                          </TableCell>
                          <TableCell
                            className="table-cell"
                            style={{
                              fontWeight: "bold",
                              alignItems: "center",
                              textAlign: "center",
                              maxWidth: "100px",
                            }}
                          >
                            <span>{cond.cond_mandatory}</span>
                          </TableCell>
                          <TableCell
                            className="table-cell"
                            style={{
                              alignItems: "center",
                              textAlign: "center",
                              padding: "3px",
                              maxWidth: "100px",
                            }}
                          >
                            <Tooltip title="Add">
                              <QueueIcon
                                style={{
                                  height: "30px",
                                  width: "30px",
                                  alignItems: "center",
                                  textAlign: "center",
                                  padding: "0px",
                                  cursor: "pointer",
                                  color: "#9c27b0",
                                }}
                                onClick={(e) => handleAddCondition(e, cond)}
                              />
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5]}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    component="div"
                    count={productConditions.length}
                    rows={filteredProductConds}
                    page={page}
                    onPageChange={handleChangePage}
                  />
                </TableContainer>
              </div>
            </div>
          </div>
        </motion.div>
      )}
      {/* Dialog to notify user about changes being saved */}
      < Dialog open={isNotifyDialogOpen} onClose={handleCloseNotifyDialog} >
        <DialogTitle>{notifyDialogTitle}</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: notifyDialogText }} />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseNotifyDialog}>Close</CustomButton>
        </DialogActions>
      </Dialog >
      {
        isProcessing && (
          <motion.div
            className="processing-dialog"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 1 } }}
            exit={{ opacity: 0, transition: { duration: 1 } }}
          >
            <div className="processing-wrapper">
              <GridLoader
                color="#F44336"
                style={{
                  height: "50px",
                  width: "50px",
                }}
              />
            </div>
          </motion.div>
        )
      }
    </motion.div>
  );
});

export default UwPolicyCond;
