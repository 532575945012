import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentTheme: JSON.parse(localStorage.getItem("theme")) || "light",
};

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setDarkMode: (state) => {
      state.currentTheme = "dark";
    },
    setLightMode: (state, action) => {
      state.currentTheme = "light";
    },
  },
});

export const { setDarkMode, setLightMode } = themeSlice.actions;

export default themeSlice.reducer;
