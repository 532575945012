import { toast } from "react-toastify";

export const showToast = (message, type = "info") => {
  const options = {
    hideProgressBar: true,
    closeOnClick: true,
    autoClose: 3000,
    className: `toast-${type}`,
  };

  switch (type) {
    case "success":
      toast.success(message, options);
      break;
    case "error":
      toast.error(message, options);
      break;
    case "warning":
      toast.warning(message, options);
      break;
    default:
      toast.info(message, options);
  }
};