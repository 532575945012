import "./ApPaymentHome.scss";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import newRequest from "../../../utils/newRequest";
import CircularButton, {
  CustomButton,
  CustomLoadingButton,
} from "../../../components/ui/CustomButton";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import { motion } from "framer-motion";
import {ScaleLoader } from "react-spinners";
import numeral from "numeral";
import moment from "moment";

const ApPaymentHome = () => {
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [buttonTitle, setButtonTitle] = useState("REFRESH");
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [payments, setPayments] = useState([]);
 

  const fetchPayments = async () => {
    try {
      setIsLoading(true);
      setButtonTitle("WORKING...");
      // Simulate delay for 5 seconds
      // await new Promise((resolve) => setTimeout(resolve, 5000));
      const res = await newRequest.post(
        "/apPaymentHdr/findAll",
        {
          hd_org_code: currentUser.usr_org_code,
        }
      );
      setPayments(res.data);
      setButtonTitle("REFRESH");
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setButtonTitle("REFRESH");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPayments();
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 7));
    setPage(0);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const filteredData = payments.filter(
    (item) =>
      item.hd_no?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.bank_ac_no_xx?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.bank_ac_name_xx?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.account_name_xx?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.hd_chq_no?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.hd_fc_amount
        ?.toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      item.hd_ref_no?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const slicedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <motion.div
      className="ap-payment-home"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      {isLoading ? (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <ScaleLoader
            color="#F44336"
            height={50}
            radius={8}
            width={4}
          />
        </div>
      ) : (
        <>
          {slicedData?.length === 0 &&
            <div className="no-content">
              <h1>Welcome to Payments home</h1>
              <p>
                Keep track of your earnings and manage your finances efficiently. Start by adding your first payment record.
              </p>
              <CircularButton onClick={() => {
                try {
                  navigate(`/payment/new`, {
                    state: null,
                  });
                } catch (error) {
                  console.log(error)
                }
              }} />
            </div>}

          {slicedData?.length > 0 &&
            <div className="navbar">
              <div className="navbar-left">
                <div className="search-input-wrapper">
                  <SearchIcon className="search-input-icon" />
                  <input
                    className="search-input"
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  {searchTerm.length > 0 && (
                    <CloseIcon
                      onClick={handleClearSearch}
                      className="search-input-icon"
                    />
                  )}
                </div>
              </div>
              <div className="navbar-right">
                <CustomLoadingButton
                  startIcon={<RefreshIcon />}
                  onClick={fetchPayments}
                >
                  {buttonTitle}
                </CustomLoadingButton>
                <CustomButton
                  startIcon={<AddIcon />}
                  onClick={() => {
                    try {
                      navigate(`/payment/new`, {
                        state: null,
                      });
                    } catch (error) {
                      console.log(error)
                    }
                  }}
                >
                  New Payment
                </CustomButton>
              </div>
            </div>}
          {slicedData?.length > 0 &&
            <div className="table-content">
              <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none' }}
                className="table-container">
                <Table sx={{ width: "max-content", border: 'none' }}>
                  <TableHead className="tablehead">
                    <TableRow
                      className="tablerow"
                    >
                      <TableCell className="table-cell" style={{
                        display: "flex",
                        maxWidth: "50px",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: "bold",
                      }}>
                        <span>#</span>
                      </TableCell>
                      <TableCell className="table-cell" style={{
                        display: "flex",
                        maxWidth: "150px"
                      }}>
                        <span>Payment No</span>
                      </TableCell>
                      <TableCell className="table-cell" style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "250px"
                      }}>
                        <span>Client</span>
                      </TableCell>
                      <TableCell className="table-cell" style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "150px"
                      }}>
                        <span>Paying For/Branch</span>
                      </TableCell>
                      <TableCell className="table-cell" style={{
                        display: "flex",
                        maxWidth: "150px"
                      }}>
                        <span>Date</span>
                      </TableCell>
                      <TableCell className="table-cell" style={{
                        alignItems: "right", display: "flex",
                      }}>
                        <span style={{
                          textAlign: "right"
                        }}>Amounts</span>
                      </TableCell>
                      <TableCell className="table-cell" style={{
                        display: "flex",
                        maxWidth: "100px"
                      }}>
                        <span>Status</span>
                      </TableCell>
                      <TableCell className="table-cell">
                        <span>Info</span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="tablebody">
                    {slicedData?.map((payment, index) => (
                      <TableRow
                        key={payment._id}
                        className="tablerow"
                        onClick={() => {
                          try {
                            navigate(`/payment/${payment._id}`, {
                              state: { shouldFetchClients: false },
                            });
                          } catch (error) {
                            console.log(error)
                          }
                        }}
                      >
                        <TableCell className="table-cell"
                          style={{
                            display: "flex",
                            maxWidth: "50px",
                            alignItems: "center",
                            justifyContent: "center",
                            fontWeight: "bold",
                          }}>
                          <span>{page * rowsPerPage + index + 1}</span>
                        </TableCell>
                        <TableCell className="table-cell" style={{
                          display: "flex",
                          maxWidth: "150px"
                        }}>
                          <span style={{
                            fontWeight: "bold"
                          }}>  {payment.hd_no} </span>
                          <span>{`Ref: ${payment?.hd_ref_no}`}</span>
                        </TableCell>

                        <TableCell className="table-cell" style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "250px"
                        }}>
                          <span>{payment.client_name_xx}</span>
                          <span style={{
                            fontWeight: "bold",
                            fontSize: "10px",
                            color: "#9c27b0"
                          }}> {payment.hd_mode}</span>
                        </TableCell>

                        <TableCell className="table-cell" style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "150px"
                        }}>
                          <span>{payment.hd_paying_for}</span>
                          <span style={{
                            fontWeight: "bold",
                            fontSize: "10px",
                            color: "#9c27b0"
                          }}> {payment.bizunit_name_xx}</span>
                        </TableCell>

                        <TableCell className="table-cell" style={{
                          display: "flex",
                          flexDirection: "column",
                          maxWidth: "150px"
                        }}>
                          <span>
                            {moment(payment?.hd_gl_date).format("DD-MMM-YYYY")}
                          </span>

                        </TableCell>

                        <TableCell className="table-cell" style={{
                          alignItems: "right",
                          display: "flex",
                          flexDirection: "column"
                        }}>
                          <span style={{
                            textAlign: "right",
                            fontWeight: "bold",
                            fontSize: "10px",
                            color: "#f44336"
                          }}>{payment.hd_cur_code}</span>
                          <span style={{
                            textAlign: "right",
                            fontWeight: "bold"
                          }}>{`Paid ${numeral(
                            payment.hd_fc_amount
                          ).format("0,0.00")}`}</span>
                          <span
                            style={{
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >{`Allocated ${numeral(
                            payment.allocated_fc_amount_xx
                          ).format("0,0.00")}`}</span>
                        </TableCell>
                        <TableCell className="table-cell" style={{
                          display: "flex",
                          maxWidth: "100px"
                        }}>
                          <span className={`doc-status ${payment.hd_status}`}>
                            {payment.hd_status}
                          </span>
                          <span style={{
                            fontWeight: "bold",
                            fontSize: "10px",
                            color: "#9c27b0"
                          }}> {payment.hd_chq_no}</span>
                        </TableCell>
                        <TableCell className="table-cell" style={{
                          alignItems: "right", display: "flex",
                          flexDirection: "column",
                          fontSize: "12px",
                          fontWeight: "bold"
                        }}>
                          <span>{`Paid By : ${payment.created_by_name_xx}`}</span>
                          <span> {payment?.time_ago_xx}</span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[7]}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  component="div"
                  count={filteredData.length}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </TableContainer>
            </div>
          }
        </>
      )}
    </motion.div>
  );
};

export default ApPaymentHome;
