
import { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import newRequest from "../../utils/newRequest";
import { useSelector } from "react-redux";

const Ratings = () => {
    const { currentUser } = useSelector((state) => state.user);
    const [users, setUsers] = useState([]);
    const fetchUsers = async () => {
        try {
            const res = await newRequest.post(
                "/adUser/findAll",
                {
                    usr_org_code: currentUser.usr_org_code,
                }
            );
            setUsers(res.data);

        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {

        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    return (
        <div className="ratings">
            <Marquee>
                {users.map((row) => (
                    <div className="single-card"
                        key={row._id}
                        onClick={() => {
                            try {

                            } catch (error) {
                                console.log(error)
                            }
                        }}
                    >
                        <div className="single-card-top">
                            <span className="category">{row.bizunit_name_xx}</span>
                        </div>
                        <div className="single-card-center">
                            <span className="main-title">{row.usr_full_name}</span>
                        </div>
                        <div className="single-card-bottom">
                            <div className="avatar-container">
                                <div className="left">
                                    <img src={row.updated_by_photo_url_xx} alt="avatar" className="avatar" />
                                </div>
                                <div className="right">
                                    <span className="username">{row.updated_by_name_xx}</span>
                                    <span className="time-ago">{row.time_ago_xx}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Marquee>
        </div>
    )
}

export default Ratings