import "./UwVehicleImport.scss";
import ErrorDialog from "../../../components/errorDialog/ErrorDialog";
import ProgressDialog from "../../../components/progressDialog/ProgressDialog";
import { CustomButton } from "../../../components/ui/CustomButton";
import { customSelectStyles } from "../../../components/ui/uiStyleElements";
import newRequest from "../../../utils/newRequest";
import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PublishIcon from "@mui/icons-material/Publish";
import SearchIcon from "@mui/icons-material/Search";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { MdOutlineAttachment } from "react-icons/md";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import numeral from "numeral";
import readXlsxFile from "read-excel-file";
import { validateMotorVehicleRegn } from "../../../utils/genericFunctions";

const UwVehicleImport = () => {
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  // Extract the state from the location object
  const subClassToPass = location.state;
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [excelData, setExcelData] = useState([]);
  const [showUpload, setShowUpload] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");
  const [openProgressDialog, setOpenProgressDialog] = useState(false);
  const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
  const [notifyDialogTitle, setNotifyDialogTitle] = useState("Confirm");
  const [notifyDialogText, setNotifyDialogText] = useState("Text");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };



  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    setShowUpload(false);
    if (!file) return;
    let vehicleRegnArray = [];
    let errorsCount = 0;

    setUploading(true);

    try {
      const rows = await readXlsxFile(file, { sheet: 1 });
      const data = rows.slice(1).map((row, index) => {
        const regn_no = row[0];
        const chassis_no = row[1] || "";
        const engine_no = row[2] || "";
        const car_make = row[3] || "";
        const car_yom = row[4];
        const car_value = row[5] || 0;
        const car_cc = row[6] || "";
        const passangers = row[7] || 0;
        const color = row[8] || "";
        const cover = row[9] || "";
        const rate = row[10] || 0;
        const premium = row[11] || 0;
        const excess_protector = row[12] || 0;
        const windscreen = row[13] || 0;
        const radio = row[14] || 0;

        let result_xx = "Ready for upload";

        // Perform your checks here
        if (!regn_no) {
          result_xx = "MISSING REGN";
          errorsCount++;
        } else {
          if (!validateMotorVehicleRegn(regn_no)) {
            result_xx = `INVALID REGISTRATION NO ${regn_no}`; errorsCount++;
          }
        }


        if (!["COMP", "TP", "TPFT"].includes(cover)) {
          result_xx = "Cover MUST be COMP, TP or TPFT"; errorsCount++;
        }



        if (vehicleRegnArray.includes(regn_no)) {
          result_xx = `${regn_no} DUPLICATED`; errorsCount++;
        } else {
          vehicleRegnArray.push(regn_no);
        }

        return {
          regn_no,
          chassis_no,
          engine_no,
          car_make,
          car_yom,
          car_value,
          car_cc,
          passangers,
          color,
          cover,
          rate,
          premium,
          excess_protector,
          windscreen,
          radio,
          result_xx,
          index // Add index for tracking the row number
        };
      });

      setExcelData(data);
      setUploading(false);

      if (errorsCount === 0) {
        setShowUpload(true)
      }

    } catch (error) {
      console.error("Error reading Excel file:", error);
      setUploading(false);
    }
  };




  const handleCloseDialog = () => {
    setError("");
  };

  const updateRecordStatus = (index, result_xx) => {
    try {
      const updatedData = [...excelData];
      const currentRow = updatedData[index];

      currentRow["result_xx"] = result_xx;
      setExcelData(updatedData);
    } catch (error) {
      console.log(error);
    }
  };


  // Update filtered data when the search term changes
  useEffect(() => {
    const filteredResults = excelData.filter(
      (item) =>
        item?.regn_no?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item?.chassis_no?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item?.engine_no?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item?.car_make?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filteredResults);
  }, [excelData, searchTerm]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const slicedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const handleCloseNotifyDialog = () => {
    setIsNotifyDialogOpen(false);
  };


  const handleSubmit = async () => {
    try {

      setOpenProgressDialog(true);
      let responseData = []
      const responseArray = [];
      const totalRecords = excelData.length;
      let completedRecords = 0;
      console.log(`excelData  ${JSON.stringify(excelData, null, 2)}`);

      for (const record of excelData) {
        const progress = Math.round((completedRecords / totalRecords) * 100);
        setProgress(progress);
        console.log(`subClassToPass ${JSON.stringify(subClassToPass, null, 2)}`);

        try {
          const uploadedRisk = {
            ...record,
            ...subClassToPass,
            ps_order: completedRecords,
            subclass_docid: subClassToPass._id,
            orgCode: currentUser.usr_org_code,
            pr_code: subClassToPass.pr_code,
            userCode: currentUser.usr_id,
          }

          const res = await newRequest.post("/uwPolicyRisk/uploadRisk", uploadedRisk, config);
          // console.log(`/uwPolicyRisk/uploadRisk ${JSON.stringify(res.data, null, 2)}`);
          responseArray.push({
            record_index: completedRecords,
            response: res.data
          });
        } catch (error) {
          console.log(error);
        }
        completedRecords++;
      }


      let uploadedCount = 0;
      let restCount = 0;

      // console.log(`responseArray ${JSON.stringify(responseArray, null, 2)}`);

      // loop to update response data
      for (const record of responseArray) {
        if (record.response === "Uploaded") {
          uploadedCount++;
        } else {
          restCount++;
        }

        updateRecordStatus(
          record.record_index,
          record.response
        );
      }

      // calculate premium
      const res = await newRequest.post("/uwPolicy/refresh", {
        policy_docid: subClassToPass.ps_policy_docid,
        userCode: currentUser.usr_id,
      }, config);

      setProgress(100);
      setOpenProgressDialog(false);

      setNotifyDialogTitle("Please Note...");
      setNotifyDialogText(`<div>Total Records Processed <strong>${responseArray.length}</strong></div>
      <div>Successfully Uploaded <strong>${uploadedCount}</strong></div>
      <div>Errors <strong>${restCount}</strong></div>
      <div><br/></div>
      <div><p>Rectify the Records with errors and try again </p> </div>
      `);
      setIsNotifyDialogOpen(true);
    } catch (error) {
      console.error("Error uploading data:", error);
    }
  };





  return (
    <div className="uw-vehicle-import">
      <div className="navbar">
        <div className="navbar-left">
          <div className="search-input-wrapper">
            <SearchIcon className="search-input-icon" />
            <input
              className="search-input"
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {searchTerm.length > 0 && (
              <CloseIcon
                onClick={handleClearSearch}
                className="search-input-icon"
              />
            )}
          </div>
        </div>

        <div className="navbar-right">
          <input
            type="file"
            accept=".xlsx, .xls"
            id="file"
            style={{ display: "none" }}
            onChange={handleFileUpload}
          />
          <label htmlFor="file">
            <div className="file-upload-item">
              <MdOutlineAttachment />
              <span>Select File</span>
            </div>
          </label>
          {showUpload && (
            <CustomButton
              startIcon={<PublishIcon />}
              onClick={handleSubmit}
              disabled={!excelData.length || uploading}
            >
              {uploading ? "Uploading..." : "Upload"}
            </CustomButton>
          )}
          <CustomButton startIcon={<IoClose />} onClick={goBack}>
            Close
          </CustomButton>
        </div>
      </div>
      <div className="content">
        <TableContainer component={Paper} className="table-container">
          <Table sx={{ minWidth: 650 }}>
            <TableHead className="tablehead">
              <TableRow>
                <TableCell
                  className="table-cell"
                  style={{ fontWeight: "500" }}
                >
                  REGN No
                </TableCell>
                <TableCell
                  className="table-cell"
                  style={{ fontWeight: "500" }}
                >
                  CHASSIS
                </TableCell>
                <TableCell
                  className="table-cell"
                  style={{ fontWeight: "500" }}
                >
                  ENGINE
                </TableCell>
                <TableCell
                  className="table-cell"
                  style={{ fontWeight: "500" }}
                >
                  MAKE
                </TableCell>
                <TableCell
                  className="table-cell"
                  style={{ fontWeight: "500", textAlign: "center" }}
                >
                  YOM
                </TableCell>
                <TableCell
                  className="table-cell"
                  style={{ fontWeight: "500", textAlign: "right" }}
                >
                  CAR VALUE
                </TableCell>
                <TableCell
                  className="table-cell"
                  style={{ fontWeight: "500", textAlign: "center" }}
                >
                  CC
                </TableCell>
                <TableCell
                  className="table-cell"
                  style={{ fontWeight: "500", textAlign: "right" }}
                >
                  PAX
                </TableCell>
                <TableCell
                  className="table-cell"
                  style={{ fontWeight: "500" }}
                >
                  COLOR
                </TableCell>
                <TableCell
                  className="table-cell"
                  style={{ fontWeight: "500" }}
                >
                  COVER
                </TableCell>

                <TableCell
                  className="table-cell"
                  style={{ fontWeight: "500", textAlign: "right" }}
                >
                  RATE
                </TableCell>
                <TableCell
                  className="table-cell"
                  style={{ fontWeight: "500", textAlign: "right" }}
                >
                  PREMIUM
                </TableCell>
                <TableCell
                  className="table-cell"
                  style={{ fontWeight: "500", textAlign: "right" }}
                >
                  EXCESS
                </TableCell>
                <TableCell
                  className="table-cell"
                  style={{ fontWeight: "500", textAlign: "right" }}
                >
                  WINDSCREEN
                </TableCell>
                <TableCell
                  className="table-cell"
                  style={{ fontWeight: "500", textAlign: "right" }}
                >
                  RADIO
                </TableCell>
                <TableCell
                  className="table-cell"
                  style={{ fontWeight: "500" }}
                >
                  RESULT
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="tablebody">
              {slicedData.map((row, index) => (
                <TableRow key={row.index} className="tablerow">
                  <TableCell
                    className={`table-cell ${row.result_xx === "Uploaded" ? "Uploaded" : "Error"
                      }`}
                    style={{
                      fontWeight: "500",
                      maxWidth: "200px",
                      padding: "8px",
                    }}
                  >
                    {row.regn_no}
                  </TableCell>
                  <TableCell
                    className={`table-cell ${row.result_xx === "Uploaded" ? "Uploaded" : "Error"
                      }`}
                    style={{
                      fontWeight: "500",
                      maxWidth: "200px",
                      padding: "8px",
                    }}
                  >
                    {row.chassis_no}
                  </TableCell>
                  <TableCell
                    className={`table-cell ${row.result_xx === "Uploaded" ? "Uploaded" : "Error"
                      }`}
                    style={{
                      fontWeight: "500",
                      maxWidth: "150px",
                      padding: "8px",
                    }}
                  >
                    {row.engine_no}
                  </TableCell>
                  <TableCell
                    className={`table-cell ${row.result_xx === "Uploaded" ? "Uploaded" : "Error"
                      }`}
                    style={{
                      fontWeight: "500",
                      maxWidth: "200px",
                      padding: "8px",
                    }}
                  >
                    {row.car_make}
                  </TableCell>
                  <TableCell
                    className={`table-cell ${row.result_xx === "Uploaded" ? "Uploaded" : "Error"
                      }`}
                    style={{
                      fontWeight: "500",
                      maxWidth: "150px",
                      padding: "8px", textAlign: "center"
                    }}
                  >
                    {row.car_yom}
                  </TableCell>
                  <TableCell
                    className={`table-cell ${row.result_xx === "Uploaded" ? "Uploaded" : "Error"
                      }`}
                    style={{
                      fontWeight: "500",
                      maxWidth: "200px",
                      padding: "8px", textAlign: "right"
                    }}
                  >
                    {numeral(row.car_value).format("0,0.00")}
                  </TableCell>
                  <TableCell
                    className={`table-cell ${row.result_xx === "Uploaded" ? "Uploaded" : "Error"
                      }`}
                    style={{
                      fontWeight: "500",
                      maxWidth: "150px",
                      padding: "8px", textAlign: "center",
                    }}
                  >
                    {row.car_cc}
                  </TableCell>
                  <TableCell
                    className={`table-cell ${row.result_xx === "Uploaded" ? "Uploaded" : "Error"
                      }`}
                    style={{
                      fontWeight: "500",
                      maxWidth: "150px",
                      padding: "8px",
                      textAlign: "right"
                    }}
                  >
                    {row.passangers}
                  </TableCell>
                  <TableCell
                    className={`table-cell ${row.result_xx === "Uploaded" ? "Uploaded" : "Error"
                      }`}
                    style={{
                      fontWeight: "500",
                      maxWidth: "150px",
                      padding: "8px",
                    }}
                  >
                    {row.color}
                  </TableCell>
                  <TableCell
                    className={`table-cell ${row.result_xx === "Uploaded" ? "Uploaded" : "Error"
                      }`}
                    style={{
                      fontWeight: "500",
                      maxWidth: "150px",
                      padding: "8px",
                    }}
                  >
                    {row.cover}
                  </TableCell>
                  <TableCell
                    className={`table-cell ${row.result_xx === "Uploaded" ? "Uploaded" : "Error"
                      }`}
                    style={{
                      fontWeight: "500",
                      maxWidth: "150px",
                      padding: "8px", textAlign: "right"
                    }}
                  >
                    {numeral(row.rate).format("0,0.00")}
                  </TableCell>
                  <TableCell
                    className={`table-cell ${row.result_xx === "Uploaded" ? "Uploaded" : "Error"
                      }`}
                    style={{
                      fontWeight: "500",
                      maxWidth: "150px",
                      padding: "8px", textAlign: "right"
                    }}
                  >
                    {numeral(row.premium).format("0,0.00")}
                  </TableCell>
                  <TableCell
                    className={`table-cell ${row.result_xx === "Uploaded" ? "Uploaded" : "Error"
                      }`}
                    style={{
                      fontWeight: "500",
                      maxWidth: "150px",
                      padding: "8px", textAlign: "right"
                    }}
                  >
                    {numeral(row.excess_protector).format("0,0.00")}
                  </TableCell>
                  <TableCell
                    className={`table-cell ${row.result_xx === "Uploaded" ? "Uploaded" : "Error"
                      }`}
                    style={{
                      fontWeight: "500",
                      maxWidth: "150px",
                      padding: "8px", textAlign: "right"
                    }}
                  >
                    {numeral(row.windscreen).format("0,0.00")}
                  </TableCell>
                  <TableCell
                    className={`table-cell ${row.result_xx === "Uploaded" ? "Uploaded" : "Error"
                      }`}
                    style={{
                      fontWeight: "500",
                      maxWidth: "150px",
                      padding: "8px", textAlign: "right"
                    }}
                  >
                    {numeral(row.radio).format("0,0.00")}
                  </TableCell>
                  <TableCell
                    className={`table-cell ${row.result_xx === "Uploaded" ? "Uploaded" : "Error"
                      }`}
                    style={{
                      fontWeight: "500",
                      maxWidth: "250px",
                      textOverflow: "wrap",
                      padding: "8px",
                    }}
                  >
                    {row.result_xx}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            rows={filteredData}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
      {/* Error dialog */}
      <ErrorDialog
        open={!!error}
        handleClose={handleCloseDialog}
        errorMessage={error}
      />
      {/* progress dialog for upload  */}
      <ProgressDialog
        open={openProgressDialog}
        handleClose={() => setOpenProgressDialog(false)}
        progress={progress}
        title="Uploading Vehicles. Please Wait...."
      />
      {/* Dialog to notify user about changes being saved */}
      <Dialog open={isNotifyDialogOpen} onClose={handleCloseNotifyDialog}>
        <DialogTitle>{notifyDialogTitle}</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: notifyDialogText }} />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseNotifyDialog}>Close</CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UwVehicleImport;
