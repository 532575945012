import { useState } from "react";
import "./UwBarChartBox.scss";
import { Bar, BarChart, ResponsiveContainer, Tooltip } from "recharts";

const UwBarChartBox = (props) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleBarClick = (data, index) => {
    setActiveIndex(index === activeIndex ? null : index);
    console.log("Clicked Bar Data:", props.chartData[index]); // Log the clicked bar's data
  };

  return (
    <div className="uw-bar-chart-box">
      <h1>{props.title}</h1>
      <div className="chart">
        <ResponsiveContainer width="99%" height={150}>
          <BarChart data={props.chartData}>
            <Tooltip
              contentStyle={{ background: "#2a3447", borderRadius: "5px" }}
              labelStyle={{ display: "none" }}
              cursor={{ fill: "none" }}
            />
            <Bar
              dataKey={props.dataKey}
              fill={props.color}
              onClick={handleBarClick}
              activeIndex={activeIndex}
              shape={<CustomBar />}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const CustomBar = (props) => {
  const { x, y, width, height, fill, onClick } = props;

  return (
    <rect
      x={x}
      y={y}
      width={width}
      height={height}
      fill={fill}
      onClick={onClick}
      style={{ cursor: "pointer" }} // Set cursor to pointer
    />
  );
};

export default UwBarChartBox;
