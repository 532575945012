import moment from "moment";
import { useDispatch } from 'react-redux';
import { showBanner } from '../../redux/userSlice';

const SubscriptionBanner = ({ currentUser }) => {
    const dispatch = useDispatch();
    // Function to handle dismiss click
    const handleDismissClick = () => {
        dispatch(showBanner("N"));
    };
    return (
        <div className="sub-banner">
            <span>
                {`We are unable to renew your Qillo subscription. Please renew here by ${moment(currentUser?.subscription_final_xx).format("DD-MMM-YYYY")} or you will not be able to use Qillo services`}
            </span>
            <span className="dismiss" onClick={handleDismissClick}>
                dismiss
            </span>
        </div>
    );
};

export default SubscriptionBanner;
