
import "./NumberGeneration.scss";
import { useSelector } from "react-redux";
import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  Select,
  MenuItem,
  Popover,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import ReactSelect from "react-select";
import { GridLoader, ScaleLoader } from "react-spinners";
import newRequest from "../../utils/newRequest.js";
import { showToast } from "../../toastService.js";
import { CustomButton } from "../ui/CustomButton.jsx";
import ConfirmDialog from "../confirmDialog/confirmDialog.jsx";
import { customSelectStyles } from "../ui/uiStyleElements.jsx";

const NumberGeneration = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogAction, setConfirmDialogAction] = useState("None");
  const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
  const [notifyDialogTitle, setNotifyDialogTitle] = useState("Confirm");
  const [notifyDialogText, setNotifyDialogText] = useState("Text");
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [savingTitle, setSavingTitle] = useState("Save");
  const [searchTerm, setSearchTerm] = useState("");
  const [currencyAction, setCurrencyAction] = useState("");
  const [objectToAction, setObjectToAction] = useState({
    object_id: "",
    action_text: "",
    action_invoked: "",
  });
  const [isCodeDialogOpened, setIsCodeDialogOpened] = useState(false);
  const [img, setImg] = useState(null);
  const [selectedAdminToAdd, setSelectedAdminToAdd] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [numberGens, setNumberGens] = useState([]);
  const [selectedNumberGen, setSelectedNumberGen] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(5);


  // documents
  const [newDocType, setNewDocType] = useState(null);
  const [documentOptions, setDocumentOptions] = useState([]);
  const fetchDocuments = async () => {
    try {
      const res = await newRequest.post(
        "/sysCode/findAll", { sys_type: "Documents" }
      );
      const options = res.data.map((row) => ({
        value: row.sys_code,
        label: row.sys_name,
      }));
      setDocumentOptions(options);
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? 'simple-popover' : undefined;

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleUploadDoalogClose = () => {
    setIsUploadDialogOpen(false);
  };


  // number generation
  const [currentNumGenPage, setCurrentNumGenPage] = useState(0);
  const handleNumGensPageChange = (event, newPage) => {
    setCurrentNumGenPage(newPage);
  };

  const slicedNumGens = numberGens.slice(
    currentNumGenPage * rowsPerPage,
    currentNumGenPage * rowsPerPage + rowsPerPage
  );

  const handleNumGensRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setCurrentNumGenPage(0);
  };

  const fetchNumberGenerations = async () => {
    try {
      const res = await newRequest.post(
        "/orgNumgen/findAll", { gen_org_code: currentUser.usr_org_code, }
      );

      setNumberGens(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const handleConfirm = async () => {
    try {
      if (confirmDialogAction === "deleteNumGen") {

      }
    } catch (err) {
      console.log(err);
    }
    // Close the dialog
    setIsConfirmDialogOpen(false);
  };

  const handleCancel = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleCloseNotifyDialog = () => {
    setIsNotifyDialogOpen(false);
  };

  useEffect(() => {
    fetchNumberGenerations();
    fetchDocuments();
  }, []);

  const handleNumGensChange = (e, rowId) => {
    try {
      const { name, value } = e.target;
      const updatedData = [...numberGens];
      const rowIndex = updatedData.findIndex(item => item._id === rowId);

      if (rowIndex === -1) {
        throw new Error('Row not found');
      }
      updatedData[rowIndex][name] = value;
      updatedData[rowIndex]["changed_xx"] = "Y";

      setNumberGens(updatedData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleNumGensBlur = async (e, rowId) => {
    try {
      const { name, value } = e.target;
      const updatedData = [...numberGens];
      const rowIndex = updatedData.findIndex(item => item._id === rowId);

      if (rowIndex === -1) {
        throw new Error('Row not found');
      }

      // console.log(`name is ${name} and value is ${value}`);

      const updatePayload = {
        [name]: value,
        updatedBy: currentUser.usr_id,
      };

      const res = await newRequest.put(`/orgNumgen/upd/${rowId}`, updatePayload);


    } catch (error) {
      console.log(error);
    }
  };

  const handleNumGenAction = async () => {
    try {

      if (!newDocType?.value) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter type!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      const res = await newRequest.post(
        "/orgNumgen/add",
        {
          gen_org_code: currentUser.usr_org_code,
          gen_type: newDocType?.value,
          gen_auto: "Y",
          gen_current_no: 1000,
          gen_prefix: "",
          gen_surfix: "",
          userCode: currentUser.usr_id,
        }
      );

      if (res.data === "exists") {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`<strong>${newDocType?.value}</strong> already exists!`);
        setIsNotifyDialogOpen(true);
      } else if (res.data === "success") {
        fetchNumberGenerations();
        handlePopoverClose();
        showToast(`${newDocType?.value} Added!`, "success");
        setNewDocType(null);
      } else {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(res.data);
        setIsNotifyDialogOpen(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  return (
    <div className="number-generation">
      {isLoading ? (
        <div className="no-content"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <ScaleLoader
            color="#F44336"
            height={50}
            radius={8}
            width={4}
          />
        </div>
      ) : (
        <div className="number-generation-main-wrapper">
          {/* Number Generattion */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              justifyContent: "flex-start",
            }}
          >
            {["admin", "superadmin"].includes(currentUser.user_type_xx) && <Tooltip title={`Add Business Unit`} placement="right">
              <AddIcon
                size={50}
                style={{
                  borderRadius: "5px",
                  padding: "3px",
                  alignSelf: "center",
                  cursor: "pointer",
                  backgroundColor: "rgba(0, 128, 0, 0.5)",
                }}
                aria-describedby={id}
                onClick={handlePopoverClick}
              />
            </Tooltip>}
            <h2 className="sub-titles" style={{ margin: "10px 0px" }}>ADD</h2>
          </div>
          <Popover
            id={id}
            open={popoverOpen}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <div style={{ padding: "20px" }}>
              <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "max-content",
                marginBottom: "5px"
              }}>
                <span>ADD NUMBER GEN</span>
                <span
                  onClick={handlePopoverClose}
                >
                  <IoClose
                    style={{
                      width: "30px",
                      height: "30px",
                      cursor: "pointer",
                      borderRadius: "50%",
                    }}
                  />
                </span>
              </div>
              <div className="input-item-with-label">
                <label
                  htmlFor="new_doc"
                  style={{ width: "50px" }}
                >
                  TYPE
                </label>
                <ReactSelect
                  id="new_doc"
                  name="new_doc"
                  width="160px"
                  value={newDocType}
                  onChange={(selectedOption) => {
                    try {
                      setNewDocType(selectedOption);
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                  options={documentOptions}
                  isSearchable={true}
                  placeholder="DOCUMENT"
                  menuPortalTarget={document.body}
                  styles={{
                    ...customSelectStyles,
                    menuPortal: base => ({
                      ...base,
                      zIndex: 9999, // Ensure the dropdown is above other elements
                    })
                  }}
                />
                <span style={{ display: "flex", margin: "0px 10px" }}>
                  <CustomButton
                    startIcon={<AddIcon />}
                    onClick={handleNumGenAction}
                  >
                    Add
                  </CustomButton>
                </span>
              </div>
            </div>
          </Popover>
          <div className="table-content">
            <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none' }} className="table-container">
              <Table sx={{ width: "max-content", border: 'none' }}>
                <TableHead className="tablehead">
                  <TableRow className="tablerow">
                    <TableCell className="table-cell" style={{ display: "flex", maxWidth: "50px", fontWeight: "bold", alignItems: "center", justifyContent: "center" }}>
                      #
                    </TableCell>
                    <TableCell className="table-cell" style={{ fontWeight: "bold", width: "auto", display: 'flex' }}>
                      <span>TYPE</span>
                    </TableCell>
                    <TableCell className="table-cell" style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", maxWidth: "150px", flexDirection: "row" }}>
                      <span>USE</span>
                    </TableCell>
                    <TableCell className="table-cell" style={{ fontWeight: "bold", alignItems: "center", display: 'flex', maxWidth: "100px", justifyContent: "center" }}>
                      <span>AUTO</span>
                    </TableCell>
                    <TableCell className="table-cell" style={{ fontWeight: "bold", alignItems: "center", display: 'flex', maxWidth: "150px", justifyContent: "center" }}>
                      <span>CURRENT NO</span>
                    </TableCell>
                    <TableCell className="table-cell" style={{ fontWeight: "bold", alignItems: "center", display: 'flex', maxWidth: "70px", justifyContent: "center" }}>
                      <span>PREFIX</span>
                    </TableCell>
                    <TableCell className="table-cell" style={{ fontWeight: "bold", alignItems: "center", display: 'flex', maxWidth: "70px", justifyContent: "center" }}>
                      <span>SUFFIX</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="tablebody">
                  {slicedNumGens?.map((row, index) => (
                    <TableRow
                      key={row._id}
                      className={`${selectedNumberGen?._id === row._id ? "tablerow current" : "tablerow"}`}
                      onClick={() => {
                        try {
                          setSelectedNumberGen(row);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                    >
                      <TableCell className="table-cell" style={{ display: "flex", maxWidth: "50px", fontWeight: "bold", alignItems: "center", justifyContent: "center" }}>
                        {currentNumGenPage * rowsPerPage + index + 1}
                      </TableCell>
                      <TableCell className="table-cell" style={{ display: "flex", fontWeight: "bold", width: "auto", flexDirection: "column" }}>
                         <span style={{ fontWeight: "400",fontSize:"18px" }}>{row.gen_type}</span>
                      </TableCell>
                      <TableCell className="table-cell" style={{ display: "flex", fontWeight: "bold", maxWidth: "150px", flexDirection: "column" }}>
                        <Select
                          id="gen_entity_type"
                          name="gen_entity_type"
                          value={row.gen_entity_type}
                          onChange={(e) => handleNumGensChange(e, row._id)}
                          onBlur={(e) => handleNumGensBlur(e, row._id)}
                          style={{ width: "100%" }}
                          variant="outlined"
                          size="small"
                          disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                        >
                          <MenuItem value="sequence">Sequence</MenuItem>
                          {row.gen_type === "Entities" && <MenuItem value="name">Name</MenuItem>}
                        </Select>
                      </TableCell>
                      <TableCell className="table-cell" style={{ display: "flex", fontWeight: "bold", maxWidth: "100px", justifyContent: "center" }}>
                        <Select
                          id="gen_auto"
                          name="gen_auto"
                          value={row.gen_auto}
                          onChange={(e) => handleNumGensChange(e, row._id)}
                          onBlur={(e) => handleNumGensBlur(e, row._id)}
                          style={{ width: "100%" }}
                          variant="outlined"
                          size="small"
                          disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                        >
                          <MenuItem value="Y">Yes</MenuItem>
                          <MenuItem value="N">No</MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell className="table-cell" style={{ display: "flex", fontWeight: "bold", maxWidth: "150px", justifyContent: "center" }}>
                        <input
                          id="gen_current_no"
                          name="gen_current_no"
                          value={row.gen_current_no}
                          onChange={(e) => handleNumGensChange(e, row._id)}
                          onBlur={(e) => handleNumGensBlur(e, row._id)}
                          style={{ width: "100%" }}
                          className="form-input-item"
                          disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                        />
                      </TableCell>
                      <TableCell className="table-cell" style={{ display: "flex", fontWeight: "bold", maxWidth: "70px", justifyContent: "center" }}>
                        <input
                          id="gen_prefix"
                          name="gen_prefix"
                          value={row.gen_prefix}
                          onChange={(e) => handleNumGensChange(e, row._id)}
                          onBlur={(e) => handleNumGensBlur(e, row._id)}
                          style={{ width: "100%" }}
                          className="form-input-item"
                          disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                        />
                      </TableCell>
                      <TableCell className="table-cell" style={{ display: "flex", fontWeight: "bold", maxWidth: "70px", justifyContent: "center" }}>
                        <input
                          id="gen_surfix"
                          name="gen_surfix"
                          value={row.gen_surfix}
                          onChange={(e) => handleNumGensChange(e, row._id)}
                          onBlur={(e) => handleNumGensBlur(e, row._id)}
                          style={{ width: "100%" }}
                          className="form-input-item"
                          disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5]}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleNumGensRowsPerPage}
                component="div"
                count={numberGens?.length}
                rows={numberGens}
                page={currentNumGenPage}
                onPageChange={handleNumGensPageChange}
              />
            </TableContainer>
          </div>

          {/* End ofNumber Generation */}
        </div>

      )}

      {/* Dialog to notify user about changes being saved */}
      <Dialog open={isNotifyDialogOpen} onClose={handleCloseNotifyDialog}>
        <DialogTitle>{notifyDialogTitle}</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: notifyDialogText }} />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseNotifyDialog}>Close</CustomButton>
        </DialogActions>
      </Dialog>
      {/* Confirm Dialog */}
      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        dialogTitle="Confirmation"
        dialogContent={<p>{objectToAction.action_text}</p>}
      />
      {isProcessing && (
        <motion.div
          className="processing-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="processing-wrapper">
            <GridLoader
              color="#F44336"
              style={{
                height: "50px",
                width: "50px",
              }}
            />
          </div>
        </motion.div>
      )}
      {isProcessing && (
        <motion.div
          className="processing-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="processing-wrapper">
            <GridLoader
              color="#F44336"
              style={{
                height: "50px",
                width: "50px",
              }}
            />
          </div>
        </motion.div>
      )}
      {/* progress dialog for file upload  */}
      <UploadDialog
        open={isUploadDialogOpen}
        handleClose={handleUploadDoalogClose}
        uploadProgress={uploadProgress}
      />
    </div>
  );
};

const UploadDialog = ({ open, handleClose, uploadProgress }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Progress</DialogTitle>
      <DialogContent>
        <LinearProgress variant="determinate" value={uploadProgress} />
        {uploadProgress && <p>{uploadProgress}% Complete</p>}
      </DialogContent>
    </Dialog>
  );
};

export default NumberGeneration;
