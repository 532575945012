import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import "./Home.scss";
import newRequest from "../../utils/newRequest";
import { IoClose } from "react-icons/io5";
import { motion } from "framer-motion";
import moment from "moment";
import { ScaleLoader } from "react-spinners";
import CircularButton, { CustomButton, CustomButtonGradient, CustomLoadingButton } from "../../components/ui/CustomButton.jsx";
import Checkbox from '@mui/material/Checkbox';
import { showToast } from "../../toastService.js";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Tooltip,
  Button as MuiButton
} from "@mui/material";
import { countToDo } from "../../redux/todoSlice";
import "react-day-picker/dist/style.css";
import { activeStatuses } from "../../utils/core.js";
import { FaMobileAlt } from "react-icons/fa";
import { FaMobileScreen } from "react-icons/fa6";
import { MdOutlinePayment } from "react-icons/md";
import { validatePhoneNumberKe } from "../../utils/genericFunctions.js";
import { logOut } from "../../redux/userSlice.js";
import SubscriptionBanner from "../../components/subscriptionBanner/SubscriptionBanner.jsx";
import GettingStarted from "../gettingStarted/GettingStarted.jsx";
import { Line, LineChart, ResponsiveContainer, Tooltip as ChartTooltip } from "recharts";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Announcements from "../../components/announcement/Announcements.jsx";
import Todolist from "../../components/todolist/Todolist.jsx";
import UpcomingEvents from "../../components/upcomingEvents/UpcomingEvents.jsx";

const Home = () => {
  const dispatch = useDispatch();
  // socket
  const socket = useSelector((state) => state.socket.socket);
  const [isProcessing, setIsProcessing] = useState(false);
  const { currentUser, showSubBanner } = useSelector((state) => state.user);
  const { todoCount } = useSelector((state) => state.todo);
  const [openTodo, setOpenTodo] = useState(false);
  const [todoShow, setTodoShow] = useState("N");
  const [todos, setTodos] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const isHomePath = location.pathname === "/";
  const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
  const [notifyDialogTitle, setNotifyDialogTitle] = useState("Confirm");
  const [notifyDialogText, setNotifyDialogText] = useState("Text");
  const [todoText, setTodoText] = useState("");
  const [todoDate, setTodoDate] = useState(null);
  const [currentPlan, setCurrentPlan] = useState({});
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState({});
  const [stkButtonTitle, setStkButtonTitle] = useState("SEND")
  const [paybillButtonTitle, setPaybillButtonTitle] = useState("COMPLETE")
  const [invoiceRef, setInvoiceRef] = useState("")
  const [mobileNo, setMobileNo] = useState("")
  const [cardNo, setCardNo] = useState("")
  const [cardExpiry, setCardExpiry] = useState("")
  const [cardCvv, setCardCvv] = useState("")
  const [isMobileNoValid, setIsMobileNoValid] = useState(false);
  const [hidePaybillButton, setHidePaybillButton] = useState(false);
  const [stkpushMessage, setStkpushMessage] = useState("");
  const [paybillMessage, setPaybillMessage] = useState("");
  const [requestDocument, setRequestDocument] = useState(null)
  const [statusClass, setStatusClass] = useState("pending")
  const [transactionStage, setTransactionStage] = useState("new")
  const [selectedPaymentCategory, setSelectedPaymentCategory] = useState()
  const [planAmount, setPlanAmount] = useState(0);
  const [defaultPaymentView, setDefaultPaymentView] = useState("enroll")
  const [uwStats, setUwStats] = useState(null);
  const [uwPremiumStats, setUwPremiumStats] = useState(null);

  const [calendarDate, setCalendarDate] = useState(new Date());


  const resetFields = () => {
    try {
      setMobileNo("")
      setCardNo("")
      setCardExpiry("")
      setCardCvv("")
    } catch (error) {
      console.log(error)
    }
  }


  const handleMobileNoBlur = () => {
    try {
      const valid = validatePhoneNumberKe(mobileNo);
      setIsMobileNoValid(valid);
    } catch (error) {
      console.log(error)
    }
  };

  const fetchPaymentOptions = async (paybillNo, invoiceRef, amount) => {
    try {
      // console.log(`fetchPaymentOptions passed paybillNo ${paybillNo} invoiceRef ${invoiceRef} amount ${amount}`)
      const res = await newRequest.post(
        "/pmtopt/findAll",
        { paybillNo, invoiceRef, amount }
      );

      // console.log(`fetchPaymentOptions  ${JSON.stringify(res.data, null, 2)}`);

      setPaymentOptions(res.data);
      if (res.data) {
        setSelectedPaymentOption(res.data[0]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchUwStats = async () => {
    try {
      const res = await newRequest.get("/uwPolicy/getUwStats");
      setUwStats(res.data);
      // console.log(`fetchUwStats res.data ${JSON.stringify(res.data?.premiums_graph, null, 2)}`);
      // uwStats?.combinedGroups
    } catch (err) {
      console.log(err);
    }
  };

  const fetchCurrentPlan = async (subCode) => {
    try {
      const res = await newRequest.post(
        "/plans/find",
        {
          sub_code: subCode
        }
      );
      setCurrentPlan(res.data);
      // console.log(`fetchCurrentPlan for code ${subCode}  ${JSON.stringify(res.data, null, 2)}`);

      // generate invoice reference 
      const invRef = await newRequest.post(
        "/org/invoiceRef"
      );
      // console.log(`Invoice Reference response is ${invRef.data}`)
      let amount = "";
      let paybillNo = "123123";
      let invoiceRef = invRef.data;
      setInvoiceRef(invRef.data);

      if (currentUser?.org_payment_freq_xx === "monthly") {
        amount = res.data?.fmt_monthly_price_show;
        setPlanAmount(res.data?.sub_monthly_price);
      } else if (currentUser?.org_payment_freq_xx === "quarterly") {
        amount = res.data?.fmt_quater_price_show;
        setPlanAmount(res.data?.quater_price_xx);
      } else if (currentUser?.org_payment_freq_xx === "annually") {
        setPlanAmount(res.data?.annual_price_xx);
        amount = res.data?.fmt_annual_price_show;
      }

      fetchPaymentOptions(paybillNo, invoiceRef, amount);

    } catch (err) {
      console.log(err);
    }
  };


  const goBack = () => {
    navigate(-1);
  };

  const fetchTodos = async () => {
    try {
      const res = await newRequest.post(
        "/todo/findGrouped",
        {
          todo_org_code: currentUser.usr_org_code,
          todo_userid: currentUser.usr_id,
          todo_completed: todoShow,
        }
      );
      setTodos(res.data);
      fetchTodoCount();
    } catch (err) {
      console.log(err);
    }
  };

  const fetchTodoCount = async () => {
    try {
      const res = await newRequest.post(
        "/todo/count",
        {
          todo_org_code: currentUser.usr_org_code,
          todo_userid: currentUser.usr_id,
          todo_completed: "N",
        }
      );
      dispatch(countToDo(res.data));
    } catch (err) {
      console.log(err);
    }
  };



  const handleCheckboxChange = async (groupLabel, taskId) => {
    try {

      // Determine if the checkbox was checked or unchecked
      const checked = todos.some(group =>
        group.label === groupLabel &&
        group.data.some(task => task._id === taskId && task.todo_completed === 'N')
      );

      // Call API to update todo_completed status
      const res = await newRequest.put(
        `/todo/upd/${taskId}`,
        {
          todo_completed: checked ? 'Y' : 'N'
        }
      );

      // Check if API call was successful
      if (res.status === 200) {
        fetchTodos();
      } else {
        // Handle API error
        console.error('Error updating todo status:', res.statusText);
      }

      // Update local state to reflect checkbox change
      setTodos(prevTodos =>
        prevTodos.map(group =>
          group.label === groupLabel
            ? {
              ...group,
              data: group.data.map(task =>
                task._id === taskId
                  ? { ...task, todo_completed: checked ? 'Y' : 'N' }
                  : task
              )
            }
            : group
        )
      );
    } catch (error) {
      console.error('Error updating todo:', error);
    }
  };


  const handleDeleteTask = async (taskId) => {
    try {
      //  delete task
      const res = await newRequest.delete(
        `/todo/del/${taskId}`
      );
      fetchTodos();
    } catch (error) {
      console.error('Error updating todo:', error);
    }
  };

  const handleCloseNotifyDialog = () => {
    setIsNotifyDialogOpen(false);
  };

  const handleAddTodo = async () => {
    try {
      if (!todoText) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Enter text!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      if (!todoDate) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Enter date!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      const res = await newRequest.post(
        "todo/add",
        {
          todo_org_code: currentUser.usr_org_code,
          todo_text: todoText,
          todo_date: todoDate,
          todo_completed: "N",
          userCode: currentUser.usr_id,
        }
      );
      showToast("Added!", "success");
      fetchTodos();
      setTodoDate(null);
      setTodoText(null);
      setOpenTodo(false)
    } catch (error) {
      console.log(error)
    }
  }

  const getPriceToShow = (value) => {
    try {
      switch (value) {
        case "monthly":
          return currentPlan?.fmt_monthly_price_show;
        case "quarterly":
          return currentPlan?.fmt_quater_price_show;
        case "annually":
          return currentPlan?.fmt_annual_price_show;
        default:
          return "";
      }
    } catch (error) {
      console.log(error)
    }
  };

  const iconMap = {
    "mpesa": <FaMobileAlt />,
    "airtel": <FaMobileScreen />,
    "card": <MdOutlinePayment />
  };

  useEffect(() => {
    fetchTodos();
    // if (currentUser.org_opened_xx === "N") {
    //   updateOrgOpened();
    // }
  }, [todoShow]);

  useEffect(() => {
    if (currentUser?.subscription_status_xx === "no_sub") {
      fetchCurrentPlan(currentUser?.org_plan_xx);
    } else {
      fetchUwStats();
    }
  }, [currentUser]);

  const handleInitiateStkPayment = async (optionCode, typeCategory, paymentFreq) => {
    try {
      setIsProcessing(true);
      setHidePaybillButton(true);
      setTransactionStage("processing")
      setStkButtonTitle("PLEASE WAIT...")

      const res = await newRequest.post(
        "/pmtreq/add",
        {
          req_doc_no: invoiceRef,
          req_pmt_option: optionCode,
          req_pmt_category: typeCategory,
          req_pmt_currency: "KES",
          req_fc_amount: planAmount,
          req_lc_amount: planAmount,
          req_pmt_ref: "",
          req_direction: "outgoing",
          req_status: "new"
        }
      );

      // console.log(`payment request  ${JSON.stringify(res.data, null, 2)}`);
      // Simulate delay for 3 seconds
      await new Promise((resolve) => setTimeout(resolve, 3000));

      console.log(`payment request  ${JSON.stringify(res.data, null, 2)}`);

      if (res.data.status === "success") {
        setRequestDocument(res.data);
        setStatusClass("complete")
        setStkpushMessage("Payment Successfully Initiated. Click complete after getting confirmation message");
      } else {
        setStatusClass("pending");
        setStkpushMessage("There was an issue initiating payment. Please retry")
      }
    } catch (error) {
      console.log(error)
    } finally {
      setTransactionStage("completed")
      setIsProcessing(false)
      setStkButtonTitle("COMPLETE")
    }
  };

  const handleCompleteStk = async () => {
    try {

      setIsProcessing(true);
      setTransactionStage("verifying");

      // console.log(`requestDocument  ${JSON.stringify(requestDocument, null, 2)}`);
      const res = await newRequest.get(
        `/pmtreq/findById/${requestDocument.doc._id}`
      );
      // console.log(`handleCompleteStk  ${JSON.stringify(res.data, null, 2)}`);

      if (res.data.req_status === "complete") {
        setStatusClass("complete");
        setStkpushMessage("Payment completed")

        const completeDoc = {
          current_plan: currentPlan.sub_code,
          payment_freq: currentUser?.org_payment_freq_xx,
          ...requestDocument.doc
        };
        const enroll = await newRequest.post(
          "/org/enroll", completeDoc
        );

        if (enroll.data.status === "success") {
          console.log("relogin")
          setDefaultPaymentView("completed");
        } else {
          console.log(enroll.data.msg)
        }

      } else {
        setStatusClass("pending");
        setStkpushMessage("Unable to find payment status")
      }
      // Simulate delay for 3 seconds
      // await new Promise((resolve) => setTimeout(resolve, 3000));
    } catch (error) {
      console.log(error)
    } finally {
      setTransactionStage("completed")
      setIsProcessing(false)
    }
  };

  const handleCompletePaybill = async () => {
    try {
      setIsProcessing(true);
      setTransactionStage("verifying");
      setPaybillButtonTitle("PLEASE WAIT...")
      // Simulate delay for 5 seconds
      await new Promise((resolve) => setTimeout(resolve, 5000));
    } catch (error) {
      console.log(error);
    } finally {
      setPaybillButtonTitle("COMPLETE");
      setTransactionStage("completed")
      setIsProcessing(false)
    }
  };


  return (
    <motion.div className="home"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}>
      {isLoading ? (
        <div className="no-content">
          <ScaleLoader
            color="#F44336"
            height={50}
            radius={8}
            width={4}
          />
        </div>
      ) : (
        <>
          {currentUser.org_opened_xx === "N" ?
            <GettingStarted />
            :
            <div className="main-wrapper">
              {currentUser?.subscription_status_xx === "no_sub" &&
                <>
                  {/* Payment View */}
                  {defaultPaymentView === "enroll" &&
                    <>
                      <div className="no-sub-wrapper">
                        <h1 className="welcome">{`Welcome ${currentUser.usr_full_name}`} </h1>
                        <h1 className="pay">{`Please Pay ${getPriceToShow(currentUser?.org_payment_freq_xx)} to activate your account`}</h1>
                        <div className="tabs-container">
                          <div className="tab-header">
                            {paymentOptions?.map((opt) => (
                              <div
                                key={opt._id}
                                className={`${selectedPaymentOption._id === opt._id ? 'tab active' : 'tab'}`}
                                onClick={() => {
                                  try {
                                    resetFields()
                                    setSelectedPaymentOption(opt)
                                  } catch (error) {
                                    console.log(error)
                                  }
                                }}
                              >
                                <span className={`${selectedPaymentOption._id === opt._id ? 'tab-icon active' : 'tab-icon'}`}
                                >
                                  {iconMap[opt.option_code]}
                                </span>
                                <span className={`${selectedPaymentOption._id === opt._id ? 'tab-title active' : 'tab-title'}`}>{opt.option_name}</span>
                              </div>
                            ))}
                          </div>
                          <div className="tab-content">
                            {selectedPaymentOption?.option_types?.map((otype) => (
                              <div
                                key={otype._id}
                                className="option-type"
                              >
                                <div className="left">
                                  <span className="title">{otype.type_title}</span>
                                  {otype?.type_steps?.map((stp) => (
                                    <div
                                      key={stp._id}
                                      className="option-step"
                                    >
                                      <span >{stp.step_order}. <span dangerouslySetInnerHTML={{ __html: stp.step_text }} /></span>
                                    </div>
                                  ))}
                                </div>
                                <div className="right">
                                  {otype.type_category === "stkPush" &&
                                    <div className="mobile">
                                      {transactionStage === "new" && <div className="input-item-with-label">
                                        <input
                                          id="mobile_no"
                                          name="mobile_no"
                                          type="text"
                                          placeholder="254***"
                                          value={mobileNo}
                                          className="form-input-item"
                                          style={{
                                            width: "300px",
                                            borderColor: isMobileNoValid ? "initial" : "red",
                                            borderWidth: isMobileNoValid ? "2px" : "2px"
                                          }}
                                          onChange={(e) => {
                                            try {
                                              const value = e.target.value;
                                              setMobileNo(value);
                                              setIsMobileNoValid(validatePhoneNumberKe(value));
                                            } catch (error) {
                                              console.log(error)
                                            }
                                          }}
                                          onBlur={handleMobileNoBlur}
                                        />
                                      </div>}
                                      {transactionStage !== "new" && <span className="title">{mobileNo}</span>}
                                      {stkpushMessage && <span className={`status-message ${statusClass}`}>{stkpushMessage}</span>}
                                      <span style={{ display: "flex", alignSelf: "flex-end", marginTop: "10px" }}>
                                        {isMobileNoValid && stkButtonTitle !== "COMPLETE" &&
                                          <CustomLoadingButton
                                            width={"max-content"}
                                            onClick={() => {
                                              try {
                                                console.log(`CustomLoadingButton  ${JSON.stringify(otype, null, 2)}`);
                                                setSelectedPaymentCategory(otype);
                                                handleInitiateStkPayment(selectedPaymentOption.option_code, otype.type_category, currentUser?.org_payment_freq_xx)
                                              } catch (error) {
                                                console.log(error)
                                              }
                                            }}
                                          >
                                            {isProcessing ? "PLEASE WAIT..." : "SEND"}
                                          </CustomLoadingButton>}
                                        {!isMobileNoValid &&
                                          <span style={{ cursor: "not-allowed" }}>SEND</span>}
                                        {stkButtonTitle === "COMPLETE" &&
                                          <CustomLoadingButton
                                            width={"max-content"}
                                            onClick={handleCompleteStk}
                                          >
                                            {isProcessing ? "CHECKING..." : "COMPLETE"}
                                          </CustomLoadingButton>}
                                      </span>
                                    </div>
                                  }
                                  {otype.type_category === "paybill" &&
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                      {paybillMessage && <span className={`status-message ${statusClass}`}>{paybillMessage}</span>}
                                      {/* hide paybill complete button once use clicks stk push */}
                                      {!hidePaybillButton && <CustomLoadingButton
                                        width={"max-content"}
                                        onClick={handleCompletePaybill}
                                      >
                                        {paybillButtonTitle}
                                      </CustomLoadingButton>}
                                    </div>
                                  }
                                  {otype.type_category === "card" &&
                                    <div className="card">
                                      <div className="input-item-with-label">
                                        <input
                                          id="card_no"
                                          name="card_no"
                                          type="text"
                                          placeholder="4444 ****"
                                          value={cardNo}
                                          className="form-input-item"
                                          style={{ width: "300px" }}
                                          onChange={(e) => setCardNo(e.target.value)}
                                        />
                                      </div>
                                      <div className="input-item-with-label">
                                        <input
                                          id="card_expiry"
                                          name="card_expiry"
                                          type="text"
                                          placeholder="Expiry"
                                          value={cardExpiry}
                                          className="form-input-item"
                                          style={{ width: "300px" }}
                                          onChange={(e) => setCardExpiry(e.target.value)}
                                        />
                                      </div>
                                      <div className="input-item-with-label">
                                        <input
                                          id="card_cvv"
                                          name="card_cvv"
                                          type="text"
                                          placeholder="CVV"
                                          value={cardCvv}
                                          className="form-input-item"
                                          style={{ width: "300px" }}
                                          onChange={(e) => setCardCvv(e.target.value)}
                                        />
                                      </div>
                                      <span style={{ display: "flex", alignSelf: "flex-end", marginTop: "10px" }}>
                                        <CustomButton
                                          width={"max-content"}
                                          onClick={() => {
                                            try {
                                            } catch (error) {
                                              console.log(error);
                                            }
                                          }}
                                        >
                                          SEND
                                        </CustomButton>
                                      </span>
                                    </div>}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </>}
                  {/* Competed Payment View */}
                  {defaultPaymentView === "completed" &&
                    <>
                      <motion.div
                        className="hero-text"
                        initial={{
                          opacity: 0,
                        }}
                        animate={{
                          opacity: 1,
                        }}
                        exit={{
                          opacity: 0,
                        }}
                      >
                        <h1>
                          Subscription Activated <span>Wonderful</span>
                        </h1>
                        <p dangerouslySetInnerHTML={{ __html: `Click the button below to log in and start securing your growth` }} />
                        <CustomButtonGradient
                          height={"45px"}
                          width={"200px"}
                          onClick={() => {
                            try {
                              dispatch(logOut());
                              navigate("/login");
                            } catch (error) {
                              console.log(error);
                            }
                          }}
                        >
                          LOGIN
                        </CustomButtonGradient>
                      </motion.div>
                    </>
                  }
                </>
              }
              {currentUser?.subscription_status_xx === "expired" &&
                <div className="no-sub-wrapper">
                  Expired Subscription
                </div>}
              {activeStatuses.includes(currentUser?.subscription_status_xx) &&
                <div className="content-wrapper">
                  {showSubBanner === "Y" && <SubscriptionBanner currentUser={currentUser} />}
                  <div className="home-container">
                    <div className="home-container-row" style={{ height: "max-content" }}>
                      <div className="home-container-column" style={{ backgroundColor: "#F1F0FF" }}>
                        <div className="title-row">
                          <span className="main-title">PREMIUM</span>
                          <span className="sub-title">Year to date</span>
                        </div>
                        <div className="content-row">
                          {uwStats?.currency} {uwStats?.ytd_net_prem_short || "0.00"}
                        </div>
                        <div className="footer-row">
                          <MuiButton sx={{ mt: 1, mr: 1, width: "max-content", color: "#f44336", textTransform: "lowercase" }}>
                            more...
                          </MuiButton>
                        </div>
                      </div>


                      <div className="home-container-column" style={{ backgroundColor: "#EDF9FD" }}>
                        <div className="title-row">
                          <span className="main-title">COMMISSION</span>
                          <span className="sub-title">Year to date</span>
                        </div>
                        <div className="content-row">
                          {uwStats?.currency} {uwStats?.ytd_net_comm_short || "0.00"}
                        </div>
                        <div className="footer-row">
                          <MuiButton sx={{ mt: 1, mr: 1, width: "max-content", color: "#f44336", textTransform: "lowercase" }}>
                            more...
                          </MuiButton>
                        </div>
                      </div>
                      <div className="home-container-column" style={{ backgroundColor: "#F1F0FF" }}>
                        <div className="title-row">
                          <span className="main-title">SUM INSURED</span>
                          <span className="sub-title">Year to date</span>
                        </div>
                        <div className="content-row">
                          {uwStats?.currency} {uwStats?.ytd_si_lc_short || "0.00"}
                        </div>
                        <div className="footer-row">
                          <MuiButton sx={{ mt: 1, mr: 1, width: "max-content", color: "#f44336", textTransform: "lowercase" }}>
                            more...
                          </MuiButton>
                        </div>
                      </div>
                      <div className="home-container-column" style={{ backgroundColor: "#EDF9FD" }}>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", flex: "1" }}>
                          <span style={{ fontWeight: "400" }}>{`Active Carriers ${uwStats?.stat_active_carrier || 0}`}</span>
                          <span style={{ fontWeight: "400" }}>{`Active Insureds ${uwStats?.stat_active_insured || 0}`}</span>
                          <span style={{ fontWeight: "400" }}>{`Active Products ${uwStats?.stat_active_products || 0}`}</span>
                        </div>
                        <span style={{ fontWeight: "bold", fontSize: "10px", marginTop: "10px", alignSelf: "flex-end" }}>{`as at ${uwStats?.time_ago_xx}`}</span>
                      </div>
                    </div>
                    <div className="home-container-row" style={{ height: "55%" }}>
                      <div className="home-container-column">
                        {currentUser.org_opened_xx === "N" &&
                          <div>{`This is the first time accessing ${currentUser.usr_org_name}`}</div>
                        }
                        {currentUser.org_opened_xx === "Y" &&
                          <div>{`${currentUser.usr_org_name} already accessed`}</div>
                        }
                      </div>
                      <div className="home-container-column">
                        {currentUser.user_has_logged_xx === "N" &&
                          <div className="di">{`This is the first time loggin in ${currentUser.usr_full_name}`}</div>
                        }
                        {currentUser.user_has_logged_xx === "Y" &&
                          <div>{`${currentUser.usr_full_name} user has already logged in`}</div>
                        }
                      </div>
                    </div>
                    <div className="home-container-row" style={{ height: "25%" }}>
                      <div className="home-container-column">
                        <CustomButtonGradient onClick={async () => {
                          try {
                            const note = {
                              "note_user_docid": currentUser.usr_id,
                              "note_type": "note",
                              "note_text": "Guinness World Records confirms that Ghanaian Chef, Ebenezer Smith LIED about breaking the World Record for the Longest Cooking Marathon.",
                              "note_from": "system"
                            }
                            const res = await newRequest.post("/note/add", note);
                            if (socket) {
                              socket.emit("sendNotification", { _id: res.data._id, ...note });
                            }
                          } catch (error) {
                            console.log(error)
                          }
                        }}>Send Notifcation</CustomButtonGradient>
                        <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus amet nam pariatur officiis voluptas reiciendis quos facilis enim repellat? Illo, asperiores tenetur! Adipisci placeat porro omnis consectetur cupiditate delectus a. Lorem ipsum, dolor sit amet consectetur adipisicing elit. Mollitia repellendus vero earum nostrum corporis quos. Veniam sunt quibusdam ea! Id voluptas excepturi ab illo magnam blanditiis, officiis optio doloribus laborum. Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi adipisci exercitationem non quisquam esse, animi nam aut cupiditate dolorum natus nostrum a aliquid molestiae sapiente dolor tempore! Inventore, beatae quisquam. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Inventore veritatis voluptates alias vel obcaecati! Possimus consequuntur numquam dignissimos distinctio eligendi minima deserunt dolore, molestiae explicabo nam consequatur qui illum temporibus.  </span>
                      </div>
                    </div>
                  </div>
                </div>}
              {activeStatuses.includes(currentUser?.subscription_status_xx) &&
                <div className="summary-wrapper">
                  <div className="summary-content">
                    <Calendar onChange={setCalendarDate} value={calendarDate} />
                    <hr />
                    <Todolist passedTodoDate={calendarDate} />
                    <hr />
                    <UpcomingEvents />
                    <hr />
                    <Announcements />
                  </div>
                </div>}
            </div>
          }
        </>
      )}
      {
        openTodo && (
          <motion.div
            className="add-todo-dialog"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 1 } }}
            exit={{ opacity: 0, transition: { duration: 1 } }}
          >
            <div className="add-todo-wrapper">
              <div className="add-todo-navbar">
                <span>Add TODO</span>
                <span  >
                  <IoClose onClick={() => {
                    try {
                      setTodoDate(null);
                      setTodoText(null);
                      setOpenTodo(false)
                    } catch (error) {
                      console.log(error)
                    }
                  }}
                    style={{
                      width: "30px",
                      height: "30px",
                      cursor: "pointer",
                      borderRadius: "50%",
                    }}
                  />
                </span>
              </div>
              <div className="add-todo-content">
                <div className="input-item-with-label">
                  <label
                    htmlFor="todo_text"
                    className="form-dropdown-label"
                    style={{ width: "100px", alignSelf: "flex-start" }}
                  >
                  </label>
                  <textarea
                    id="todo_text"
                    name="todo_text"
                    type="text"
                    placeholder="Type to do action here"
                    value={todoText}
                    row={3}
                    className="form-input-item"
                    style={{ width: "80%", height: "max-content" }}
                    onChange={(e) => setTodoText(e.target.value)}
                  />
                </div>
                <div className="input-item-with-label">
                  <label
                    className="form-dropdown-label"
                    style={{ width: "100px", }}
                  >
                    DATE
                  </label>
                  <input
                    id="todo_date"
                    name="todo_date"
                    type="date"
                    placeholder="FROM"
                    value={todoDate}
                    className="form-input-item"
                    style={{ width: "170px" }}
                    onChange={(e) => {
                      try {
                        const formattedDate = moment(e.target.value).format("YYYY-MM-DD");
                        setTodoDate(formattedDate);
                      } catch (error) {
                        console.log(error)
                      }
                    }}
                  />
                </div>
              </div>
              <div className="add-todo-action">
                <CustomButton onClick={handleAddTodo}>
                  ADD
                </CustomButton>
              </div>
            </div>
          </motion.div>
        )
      }
      {/* Dialog to notify user about changes being saved */}
      <Dialog open={isNotifyDialogOpen} onClose={handleCloseNotifyDialog}>
        <DialogTitle>{notifyDialogTitle}</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: notifyDialogText }} />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseNotifyDialog}>Close</CustomButton>
        </DialogActions>
      </Dialog>
    </motion.div>
  );
};

export default Home;
