import "./ApHome.scss";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import ApDb from "./apDb/ApDb";
import ApPaymentHome from "./apPaymentHome/ApPaymentHome";
import AllEntityCatg from "../all/allEntityCatg/AllEntityCatg";
import AllEntityHome from "../all/allEntityHome/AllEntityHome";
import GlCalendar from "../gl/glCalendar/GlCalendar";
import GlCurrency from "../gl/glCurrency/GlCurrency";
import AdSysCode from "../ad/adSysCode/AdSysCode";
import { motion } from "framer-motion";

const ApHome = ({ menus }) => {
  const location = useLocation();
  const { state } = location;
  const roleMenus = state && state.roleMenus ? state.roleMenus : [];

  const [homeView, setHomeView] = useState(() => {
    const initialView = {
      menu_id: roleMenus[0].group_menus[0]._id,
      menu_function: roleMenus[0].group_menus[0].menu_function,
    };
    const storedView = sessionStorage.getItem("ApHome-homeView");
    return storedView ? JSON.parse(storedView) : initialView;
  });

  useEffect(() => {
    sessionStorage.setItem("ApHome-homeView", JSON.stringify(homeView));
  }, [homeView]);

  const handleNavigation = (menuItem) => {
    try {
      setHomeView({
        menu_id: menuItem._id,
        menu_function: menuItem.menu_function,
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <motion.div className="ap-home"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}>
      <div className="ap-home-main-wrapper">
        <div className="left-wrapper">
          <div className="left-content">
            <div className="menu">
              {roleMenus.map((item) => (
                <div className="item" key={item._id}>
                  <span className="title">{item.menu_group_name}</span>
                  {item.group_menus.map((listItem) => (
                    <button
                      onClick={() => handleNavigation(listItem)}
                      className={`${homeView.menu_id === listItem._id
                        ? "listItem active"
                        : "listItem"
                        }`}
                      key={listItem._id}
                    >
                      <img src={listItem.icon} alt="" />
                      <span className="listItemTitle">{listItem.menu_name}</span>
                    </button>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="center-wrapper">
          {homeView.menu_function === "apDb" && <ApDb />}
          {homeView.menu_function === "apPaymentHome" && <ApPaymentHome />}
          {homeView.menu_function === "clientsHome" && <AllEntityHome />}
          {homeView.menu_function === "entityCatg" && <AllEntityCatg />}
          {homeView.menu_function === "sysCodes" && <AdSysCode />}
          {homeView.menu_function === "glCalendar" && <GlCalendar />}
          {homeView.menu_function === "glCurrency" && <GlCurrency />}
        </div>
      </div>
    </motion.div>
  );
};

export default ApHome;
