import "./ClaimSearchResults.scss";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Chip,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent
} from "@mui/material";
import { CustomButton } from "../../../components/ui/CustomButton";
import { GridLoader, ScaleLoader } from "react-spinners";
import { useSelector } from "react-redux";
import { useState } from "react";
import { motion } from "framer-motion";
import ConfirmDialog from "../../../components/confirmDialog/confirmDialog";

const ClaimSearchResults = ({ risks, handleSelect }) => {
  const { currentUser } = useSelector((state) => state.user);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogAction, setConfirmDialogAction] = useState("None");
  const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
  const [notifyDialogTitle, setNotifyDialogTitle] = useState("Confirm");
  const [notifyDialogText, setNotifyDialogText] = useState("Text");
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentRiskPage, setCurrentRiskPage] = useState(0);
  const [objectToAction, setObjectToAction] = useState({
    object_id: "",
    action_text: "",
    action_invoked: "",
  });

  const handleRiskPageChange = (event, newPage) => {
    setCurrentRiskPage(newPage);
  };
  const slicedRisks = risks.slice(
    currentRiskPage * rowsPerPage,
    currentRiskPage * rowsPerPage + rowsPerPage
  );
  const handleRiskRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setCurrentRiskPage(0);
  };


  const handleConfirm = async () => {
    try {
      if (confirmDialogAction === "deleteNumGen") {

      }
    } catch (err) {
      console.log(err);
    }
    // Close the dialog
    setIsConfirmDialogOpen(false);
  };

  const handleCancel = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleCloseNotifyDialog = () => {
    setIsNotifyDialogOpen(false);
  };

  return (
    <div className="claim-search-results">
      {isLoading ? (
        <div className="no-content"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <ScaleLoader
            color="#F44336"
            height={50}
            radius={8}
            width={4}
          />
        </div>
      ) : (
        <div className="claim-search-results-wrapper">
          <div className="claim-search-table-content">
            <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none' }} className="claim-search-table-container">
              <Table sx={{ width: '100%', border: 'none' }}>
                <TableHead className="tablehead">
                  <TableRow className="tablerow">
                    <TableCell className="table-cell" style={{ width: '50px', fontWeight: 'bold', textAlign: 'center' }}>
                      #
                    </TableCell>
                    <TableCell className="table-cell" style={{ width: '150px' }}>
                      REGN NO
                    </TableCell>
                    <TableCell className="table-cell" style={{ width: '150px' }}>
                      ENGINE NO
                    </TableCell>
                    <TableCell className="table-cell" style={{ width: '150px' }}>
                      CHASSIS NO
                    </TableCell>
                    <TableCell className="table-cell" style={{ width: '300px' }}>
                      MODEL
                    </TableCell>
                    <TableCell className="table-cell" style={{ width: '450px' }}>
                      SELECT COVER
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="tablebody">
                  {risks?.map((row, index) => (
                    <TableRow key={row._id} className="tablerow">
                      <TableCell className="table-cell" style={{ width: '50px', textAlign: 'center', fontWeight: 'bold' }}>
                        {index + 1}
                      </TableCell>
                      <TableCell className="table-cell" style={{ width: '150px' }}>
                        {row.pr_vehicle_regn}
                      </TableCell>
                      <TableCell className="table-cell" style={{ width: '150px' }}>
                        {row.pr_vehicle_engine}
                      </TableCell>
                      <TableCell className="table-cell" style={{ width: '150px' }}>
                        {row.pr_vehicle_chassis}
                      </TableCell>
                      <TableCell className="table-cell" style={{ width: '300px' }}>
                        {row.vehicle_model_xx}
                      </TableCell>
                      <TableCell className="table-cell" style={{ width: '450px' }}>
                        <div className="cover-chips">
                          {row.risk_covers_xx.map((cover) => (
                            <Chip key={cover._id} label={`Select ${cover.cover_name_xx} cover`} onClick={() => handleSelect(cover)} className="single-cover" />
                          ))}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5]}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleRiskRowsPerPage}
                component="div"
                count={slicedRisks?.length}
                page={currentRiskPage}
                onPageChange={handleRiskPageChange}
              />
            </TableContainer>
          </div>

          {/* End of Business Units */}
        </div>
      )}

      {/* Dialog to notify user about changes being saved */}
      <Dialog open={isNotifyDialogOpen} onClose={handleCloseNotifyDialog}>
        <DialogTitle>{notifyDialogTitle}</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: notifyDialogText }} />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseNotifyDialog}>Close</CustomButton>
        </DialogActions>
      </Dialog>
      {/* end of adding organization unit */}
      {isProcessing && (
        <motion.div
          className="processing-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="processing-wrapper">
            <GridLoader
              color="#F44336"
              style={{
                height: "50px",
                width: "50px",
              }}
            />
          </div>
        </motion.div>
      )}
      {/* Confirm Dialog */}
      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        dialogTitle="Confirmation"
        dialogContent={<p>{objectToAction.action_text}</p>}
      />
      {/* end of adding organization unit */}
      {isProcessing && (
        <motion.div
          className="processing-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="processing-wrapper">
            <GridLoader
              color="#F44336"
              style={{
                height: "50px",
                width: "50px",
              }}
            />
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default ClaimSearchResults;
