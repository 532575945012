import React from "react";
import * as XLSX from "xlsx";
import { CustomButton } from "../ui/CustomButton";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

const ExportToExcelButton = ({
  data,
  exportColumns,
  fileName = "exported_data.xlsx",
}) => {
  const exportToExcel = () => {

    // console.log(`data  ${JSON.stringify(data, null, 2)}`);
    // Extract only the data for visible columns
    const dataForExport = data.map((row) => {
      const rowData = {};
      exportColumns.forEach((columnKey) => {
        rowData[columnKey] = row[columnKey];
      });
      return rowData;
    });

    
    // Convert TableContainer data to Excel format
    const worksheet = XLSX.utils.json_to_sheet(dataForExport);

    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Save the workbook as an Excel file
    XLSX.writeFile(workbook, fileName);
  };
  return (
    <CustomButton startIcon={<ExitToAppIcon />} onClick={exportToExcel}>
      Export to Excel
    </CustomButton>
  );
};

export default ExportToExcelButton;
