import "./TestMenu.scss";
import { menu } from "../../data.js";
import { Link } from "react-router-dom";
import UwPolicyList from "./uwPolicyList/UwPolicyList.jsx";
 

const TestMenu = () => {
  return (
    <div className="test-menu">
      <div className="container">
        <div className="menu-wrapper">
          <div className="menu">
            {menu.map((item) => (
              <div className="item" key={item.id}>
                <span className="title">{item.title}</span>
                {item.listItems.map((listItem) => (
                  <Link
                    to={listItem.url}
                    className="listItem"
                    key={listItem.id}
                  >
                    <img src={listItem.icon} alt="" />
                    <span className="listItemTitle">{listItem.title}</span>
                  </Link>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div className="content-wrapper">
          <UwPolicyList />
        </div>
      </div>
    </div>
  );
};

export default TestMenu;
