import "./ArMatching.scss";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { motion } from "framer-motion";

const ArMatching = () => {
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <motion.div
      className="ar-matching"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      <div className="navbar">
        <div className="navbar-left"></div>
        <div className="navbar-right"></div>
      </div>

      <div className="content">
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "200px",
            }}
          >
            <CircularProgress
              style={{
                height: "50px",
                width: "50px",
              }}
            />
          </div>
        ) : (
          <div> ArMatchingContent here</div>
        )}
      </div>
    </motion.div>
  );
};

export default ArMatching;
