import numberToWords from "number-to-words";
import sanitizeHtml from 'sanitize-html';

export const calculateAgeFromMongoDate = (mongoDate) => {
  try {
    const birthDate = new Date(mongoDate);
    const currentDate = new Date();

    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Check if the birthday has occurred this year
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  } catch (error) {
    console.log(error)
  }
};

// Function to format date to "yyyy-MM-dd"
export const formatDate = (date) => {
  try {
    if (!date) {
      return ""; // Return an empty string if the date is undefined or null
    }

    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  } catch (error) {
    console.log(error)
  }
};

export const formatNumberWithDigitGrouping = (number, decimalPlaces) => {
  try {
    return Number(number).toLocaleString(undefined, {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    });
  } catch (error) {
    console.log(error)
  }
};

export const numberToWordsWithDecimal = (value) => {
  try {
    // Remove commas and quotes if they exist
    const strippedValue =
      typeof value === "string" ? value.replace(/,|"/g, "") : value;
    let decimalWords = "";
    let integerWords = "";
    let decimalPart;

    // Split the value into integer and decimal parts
    if (!Number.isInteger(strippedValue)) {
      const parts = strippedValue.split(".");
      const integerPart = parseInt(parts[0]);
      decimalPart = parts[1] ? parseInt(parts[1]) : 0;
      // Convert integer part to words
      integerWords = numberToWords.toWords(integerPart);
    } else {
      // Convert integer part to words
      integerWords = numberToWords.toWords(parseInt(strippedValue));
    }
    // Convert decimal part to words

    if (decimalPart > 0) {
      decimalWords = "point ";
      decimalWords += numberToWords.toWords(decimalPart).replace(/ and /g, " ");
    }

    // Combine integer and decimal parts
    let result = integerWords;
    if (decimalWords) {
      result += ` ${decimalWords}`;
    }

    // Capitalize the first letter
    result = result.charAt(0).toUpperCase() + result.slice(1);

    // Add currency if necessary
    // result += " dollars";

    return result;
  } catch (error) {
    console.log(error)
  }
};


// validate vehicle registration number

export const validateMotorVehicleRegn = (str) => {
  try {
    // Regular expression to match the specified format
    const regex = /^[A-Za-z]{3}\d{3}[A-Za-z]$/;

    // Test the input string against the regex
    return regex.test(str);
  } catch (error) {
    console.log(error)
  }
};

export const validateMotorCycleRegn = (str) => {
  try {
    // Regular expression to match the specified format
    const regex = /^[A-Za-z]{4}\d{3}[A-Za-z]$/;

    // Test the input string against the regex
    return regex.test(str);
  } catch (error) {
    console.log(error)
  }
};

export const generateUniqueCode = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < 10; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }
  return result;
};


export const formatStringToNumberV2 = (input) => {
  try {
    if (input) {
      // If input is already a number, return it
      if (typeof input === 'number') {
        return input;
      }

      // If input is not a string, throw an error
      if (typeof input !== 'string') {
        console.log(`Input ${input} is invalid`)
        return input;
      }

      // Remove double quotes from the string if they exist
      const stringWithoutQuotes = input.replace(/"/g, "");

      // Check if the string has a comma before replacing it
      const hasComma = stringWithoutQuotes.includes(",");

      // Remove commas from the string if it contains any
      const stringWithoutCommas = hasComma
        ? stringWithoutQuotes.replace(/,/g, "")
        : stringWithoutQuotes;

      // Convert the string to a number
      const numberValue = parseFloat(stringWithoutCommas);
      // Check if the conversion was successful
      if (isNaN(numberValue)) {
        console.log(`Input ${input} is invalid`);
        return input;
      }
      return numberValue;
    }
  } catch (error) {
    console.log(error)
  }
}

// Function to validate email format
export const validateEmail = (email) => {
  try {
    // Regular expression pattern for email validation
    // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    return emailPattern.test(email); // Returns true if email format is valid, false otherwise
  } catch (error) {
    console.log(error)
  }
};

export const isDateGreater = (date1, date2) => {
  try {
    const date1Only = getDateOnly(date1);
    const date2Only = getDateOnly(date2);
    return date1Only > date2Only;
  } catch (error) {
    console.log(error)
  }
};

export const getDateOnly = (date) => {
  try {
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth();
    const day = date.getUTCDate();
    return new Date(Date.UTC(year, month, day));
  } catch (error) {
    console.log(error)
  }
};


export const validatePhoneNumberKe = (phoneNumber) => {
  try {
    // Regular expression to match the criteria
    const phoneRegex = /^254[1-9]\d{8}$/;
    // Test the phone number against the regex
    return phoneRegex.test(phoneNumber);
  } catch (error) {
    console.log(error)
  }
}


export const getTodaysDateWithoutTime = () => {
  try {
    const today = new Date();
    // Remove the time portion by setting hours, minutes, seconds, and milliseconds to 0
    today.setHours(0, 0, 0, 0);
    // Format the date as YYYY-MM-DD
    const formattedDate = today.toISOString().split('T')[0];
    return formattedDate;
  } catch (error) {
    console.log(error)
  }
};

// Function to compare the MongoDB date with today's date
export const isMongoDateGreaterOrEqualToToday = (mongoDate) => {
  try {
    const date = new Date(mongoDate);
    const mongoDateOnly = getMongoDateOnly(date);

    const today = new Date();
    const todayDateOnly = getMongoDateOnly(today);

    // return mongoDateOnly >= todayDateOnly ? 'Y' : 'N';
    return mongoDateOnly >= todayDateOnly;
  } catch (error) {
    console.log(error)
  }
};

// Utility function to get the date portion only
export const getMongoDateOnly = (date) => {
  try {
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  } catch (error) {
    console.log(error)
  }
};

// Function to check if two dates are equal, excluding the time portion
export const areDatesEqual = (date1, date2) => {
  try {
    const parsedDate1 = new Date(date1);
    const parsedDate2 = new Date(date2);

    const dateOnly1 = getDateOnly(parsedDate1);
    const dateOnly2 = getDateOnly(parsedDate2);

    return dateOnly1 === dateOnly2;
  } catch (error) {
    console.log(error)
  }
};

// passed mongoDate is YYYY-MM-DD
export const addMonthsToMongoDate = (mongoDate, monthsToAdd) => {
  try {
    // Parse the MongoDB date string
    const date = new Date(`${mongoDate}T00:00:00Z`);

    // Calculate the new month and year
    const currentMonth = date.getUTCMonth();
    const currentYear = date.getUTCFullYear();
    const newMonth = currentMonth + monthsToAdd;

    // If newMonth is negative or greater than 11, adjust the year and month accordingly
    const adjustedYear = currentYear + Math.floor(newMonth / 12);
    const adjustedMonth = newMonth % 12;

    // Set the new date
    date.setUTCFullYear(adjustedYear);
    date.setUTCMonth(adjustedMonth);

    // Format the date as YYYY-MM-DD
    const formattedDate = date.toISOString().split('T')[0];

    return formattedDate;
  } catch (error) {
    console.log(error);
  }
};



// pass date as YYYY-MM-DD
export const addDaysToMongoDate = (mongoDate, daysToAdd, am = "a") => {
  try {
    // Parse the MongoDB date string
    const date = new Date(`${mongoDate}T00:00:00Z`);


    if (am === "a") {
      // Add the specified number of days
      date.setUTCDate(date.getUTCDate() + daysToAdd);
    } else {
      // Subtract the specified number of days
      date.setUTCDate(date.getUTCDate() - daysToAdd);
    }


    // Format the date as YYYY-MM-DD
    const formattedDate = date.toISOString().split('T')[0];

    return formattedDate;
  } catch (error) {
    console.log(error)
  }
};


export const sanitizeText = (htmlText) => {
  return sanitizeHtml(htmlText, {
    allowedTags: ['b', 'i', 'em', 'strong', 'a'],
    allowedAttributes: {
      'a': ['href']
    },
  });
};


// take in text and replace all tags with actuals
export const replaceTags = (inputText, currentUser) => {
  try {
    const placeholders = {
      insuredName: currentUser?.usr_full_name,
      carrierName: "ABC Insurance",
      clientName: currentUser?.usr_full_name,
      daysToRenewal: '30',
      expiryDate: '31-Mar-XXXX',
      ourCompanyName: currentUser?.usr_org_name,
      ourContactEmail: currentUser?.org_email,
      ourContactPhone: currentUser?.org_phone,
      ourPhysicalAddress: currentUser?.org_address1,
      ourPostalAddress: currentUser?.org_address2,
      ourWebsite: currentUser?.org_website,
      outstandingPremium: '20,000.00',
      policyCurrency: currentUser?.currency_name_xx,
      policyFromDate: '08-Jun-20XX',
      policyToDate: '30-Nov-20XX',
      policyNumber: 'P/2909/HQ/2024/19002',
      policyPremium: '30,000.00',
      policySumInsured: '1,000,000.00',
      productName: 'Public Liability',
      riskNoteNo: 'RN01401'
    };

    return inputText.replace(/{{(.*?)}}/g, (_, key) => placeholders[key.trim()] || '');

  } catch (error) {
    console.log(error)
  }
};

/**
 * Replaces placeholders in a template with corresponding values.
 * @param {string} template - The email template with placeholders.
 * @param {Object} values - An object where keys are placeholders and values are the replacements.
 * @returns {string} - The template with placeholders replaced by actual values.
 */

export const replacePlaceholders = (template, values) => {
  return template.replace(/\{\{(\w+)\}\}/g, (match, key) => {
    // Return the value if it exists, otherwise return the placeholder as is.
    return values[key] !== undefined ? values[key] : match;
  });
}