import "./SalesHome.scss";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const SalesHome = () => {
  const location = useLocation();
  const parts = location.pathname.split("/");
  const currentPath = parts[parts.length - 1];
  const { currentUser } = useSelector((state) => state.user);

  return <div className="sales-home">{`Current Path Is ${currentPath}`}</div>;
};

export default SalesHome;
