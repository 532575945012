import "./ValidateSignup.scss";
import CountdownTimer from "../../components/countdownTimer/CountdownTimer";
import {
  CustomButtonGradient,
  CustomLoadingGradient,
} from "../../components/ui/CustomButton";
import newRequest from "../../utils/newRequest";
import { motion } from "framer-motion";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DOMPurify from 'dompurify';

const ValidateSignup = () => {
  const [signupData, setSignupData] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const parts = location.pathname.split("/");
  const signup_docid = parts[parts.length - 1];
  const [fullName, setFullName] = useState("");
  const [orgName, setOrgName] = useState("");
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [financialStartDate, setFinancialStartDate] = useState(null);
  const [showSubscribedView, setShowSubscribedView] = useState(false);
  // errors
  const [signupButtonTitle, setSignupButtonTitle] = useState("Finish");
  const [orgNameError, setOrgNameError] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [dateError, setDateError] = useState("");
  const [tacError, setTacError] = useState("");
  const [passwordError, setPasswordError] = useState("");




  // default to 1st Jan
  useEffect(() => {
    // Set default value to the first day of the current year
    const today = new Date();
    const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
    const formattedDate = moment(firstDayOfYear).format("YYYY-MM-DD");
    setFinancialStartDate(formattedDate);
  }, []); // Empty dependency array ensures this effect runs only once on component mount

  const fetchSignupDetails = async () => {
    try {
      const res = await newRequest.get(
        `/q/getSignup/${signup_docid}`
      );
      setSignupData(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    try {
      fetchSignupDetails();
    } catch (error) {
      console.log(error);
    }
  }, [signup_docid]);

  const handleCompleteSignup = async () => {
    try {
      let errorOccured = "N";
      setFullNameError("");
      setOrgNameError("");
      setDateError("");
      setTacError("");
      setPasswordError("");

      if (!fullName) {
        setOrgNameError("Please enter your name");
        errorOccured = "Y";
      }

      if (!orgName) {
        setOrgNameError("Organization Name is Mandatory");
        errorOccured = "Y";
      }

      if (!financialStartDate) {
        setDateError("Your Must select financial period start date");
        errorOccured = "Y";
      }


      if (password?.length < 5) {
        setPasswordError("Password must be at least 6 Characters");
        errorOccured = "Y";
      }

      if (password !== verifyPassword) {
        setPasswordError("Passwords do not match");
        errorOccured = "Y";
      }

      if (errorOccured === "Y") {
        return;
      }
      
      const formattedDate = moment(financialStartDate).format("YYYY-MM-DD");
      setSignupButtonTitle("Setting you up...");

      const res = await newRequest.post(
        "/org/sub",
        {
          org_name: orgName,
          person_name: fullName,
          signup_docid: signup_docid,
          year_start_date: formattedDate,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(`/org/sub  ${JSON.stringify(res.data, null, 2)}`);
      if (res.data === "Subscribed") {
        setShowSubscribedView(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSignupButtonTitle("Finish");
    }
  };

  return (
    <div className="validate-signup">
      {!showSubscribedView && (
        <motion.div
          className="wrapper"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1 className="title">FINISH SIGNUP</h1>
            <span>
              <h1 style={{ cursor: "pointer" }} onClick={() => {
                try {
                  navigate("/qillo")
                } catch (error) {
                  console.log(error)
                }
              }}>Qillo</h1>
            </span>
          </div>
          <form>
            <h1>{`Welcome Back ${signupData.su_email}`}</h1>
            <CountdownTimer targetDate={signupData.su_expire} />
            <div
              className="input-item-with-label"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                margin: "10px 0px",
              }}
            >
              {/* <label
                htmlFor="full_name"
                style={{
                  width: "max-content",
                }}
              >
                YOUR NAME
              </label> */}
              <input
                id="full_name"
                name="full_name"
                type="text"
                placeholder="YOUR NAME"
                value={fullName}
                className="form-input-item"
                style={{ width: "400px" }}
                onChange={(e) => {
                  try {
                    setFullName(e.target.value);
                    if (e.target.value) {
                      if (e.target.value.length > 2) {
                        setFullNameError("");
                      }
                    }
                  } catch (error) {
                    console.log(error);
                  }
                }}
              />
              {fullNameError && <span className="error">{fullNameError}</span>}
            </div>
            <div
              className="input-item-with-label"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                margin: "10px 0px",
              }}
            >
              {/* <label
                htmlFor="full_name"
                style={{
                  width: "max-content",
                }}
              >
                ORGANIZATION NAME
              </label> */}
              <input
                id="org_name"
                name="org_name"
                type="text"
                placeholder="ORGANIZATION"
                value={orgName}
                className="form-input-item"
                style={{ width: "400px" }}
                onChange={(e) => {
                  try {
                    setOrgName(e.target.value);
                    if (e.target.value) {
                      if (e.target.value.length > 2) {
                        setOrgNameError("");
                      }
                    }
                  } catch (error) {
                    console.log(error);
                  }
                }}
              />
              {orgNameError && <span className="error">{orgNameError}</span>}
            </div>
            <div
              className="input-item-with-label"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                margin: "10px 0px",
              }}
            >
              <label
                htmlFor="full_name"
                style={{
                  width: "max-content",
                }}
              >
                FINANCIAL YEAR START DATE
              </label>
              <input
                id="financialStartDate"
                name="financialStartDate"
                type="date"
                value={financialStartDate}
                className="form-input-item"
                style={{ width: "170px" }}
                onChange={(e) => {
                  try {
                    const formattedDate = moment(e.target.value).format(
                      "YYYY-MM-DD"
                    );
                    setFinancialStartDate(formattedDate);
                  } catch (error) {
                    console.log(error);
                  }
                }}
              />
              {dateError && <span className="error">{dateError}</span>}
            </div>

            {/* password fields */}
            <label
              htmlFor="pw-layout"
              style={{
                width: "max-content",
                marginTop: "10px",
              }}
            >
              SET YOUR PASSWORD
            </label>
            <div
              id="pw-layout"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <div className="input-item-with-label">
                <input
                  id="password"
                  name="password"
                  type="text"
                  placeholder="PASSWORD"
                  value={password}
                  className="form-input-item"
                  style={{ width: "200px" }}
                  onChange={(e) => {
                    try {
                      setPassword(e.target.value);
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                  onBlur={(e) => {
                    try {
                      if (e.target.value.length < 5) {
                        setPasswordError(
                          "Password must be at least 6 Characters"
                        );
                      } else {
                        setPasswordError("");
                      }
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                />
              </div>
              <div className="input-item-with-label">
                <input
                  id="verifyPassword"
                  name="verifyPassword"
                  type="text"
                  placeholder="VERIFY"
                  value={verifyPassword}
                  className="form-input-item"
                  style={{ width: "200px" }}
                  onChange={(e) => {
                    try {
                      setVerifyPassword(e.target.value);
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                  onBlur={(e) => {
                    try {
                      if (e.target.value !== password) {
                        setPasswordError("Passwords do not match");
                      } else {
                        setPasswordError("");
                      }
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                />
              </div>
            </div>
            {passwordError && <span className="error">{passwordError}</span>}

            <CustomLoadingGradient
              height={"40px"}
              width={"250px"}
              onClick={handleCompleteSignup}
            >
              {signupButtonTitle}
            </CustomLoadingGradient>
          </form>
        </motion.div>
      )}

      {showSubscribedView && (
        <motion.div
          className="landing-section"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <motion.div
            className="hero-text"
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
          >
            <h1>
              You are all set <span>Wonderful</span>
            </h1>
            <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(`Click the button below to log in and start securing your growth. <b>Your username is ${signupData.su_email}</b> and password is the one you have entered. We are exited to work with you!`) }} />
            <CustomButtonGradient
              height={"45px"}
              width={"200px"}
              onClick={() => {
                try {
                  navigate("/login");
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              LOGIN
            </CustomButtonGradient>
          </motion.div>
        </motion.div>
      )}
    </div>
  );
};

export default ValidateSignup;
