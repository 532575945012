import "./confirmDialog.scss";
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { CustomLoadingButton } from "../ui/CustomButton";


const ConfirmDialog = ({
  isOpen,
  onClose,
  onConfirm,
  dialogTitle,
  dialogContent,
  agreeTitle = "Yes",
  disagreeTitle = "No",
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [closeLoading, setCloseLoading] = useState(false);

  return (
    <Dialog open={isOpen} onClose={onClose} className="confirmDialog">
      {dialogTitle && (
        <DialogTitle className="dialogTitle">
          <h1 style={{ fontSize: "20px", fontWeight: "500" }}>{dialogTitle}</h1>
        </DialogTitle>
      )}
      {dialogContent && (
        <DialogContent className="dialogContent">{dialogContent}</DialogContent>
      )}
      <DialogActions>
        <CustomLoadingButton
          onClick={async () => {
            setConfirmLoading(true);
            try {
              await onConfirm();
            } catch (error) {
              console.log(error);
            } finally {
              setConfirmLoading(false);
            }
          }}
          disabled={closeLoading} // Disable this button when close button is loading
        >
          {agreeTitle}
        </CustomLoadingButton>
        <CustomLoadingButton
          onClick={async () => {
            setCloseLoading(true);
            try {
              await onClose();
            } catch (error) {
              console.log(error);
            } finally {
              setCloseLoading(false);
            }
          }}
          disabled={confirmLoading} // Disable this button when confirm button is loading
        >
          {disagreeTitle}
        </CustomLoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
