import { useSelector } from "react-redux";
import "./GlCalendar.scss";
import ConfirmDialog from "../../../components/confirmDialog/confirmDialog.jsx";
import { CustomButton } from "../../../components/ui/CustomButton.jsx";
import { showToast } from "../../../toastService.js";
import newRequest from "../../../utils/newRequest";
import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  Select,
  MenuItem
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { GridLoader, ScaleLoader } from "react-spinners";
import DOMPurify from 'dompurify';

const GlCalendar = () => {
  const { currentUser } = useSelector((state) => state.user);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
  const [notifyDialogTitle, setNotifyDialogTitle] = useState("Confirm");
  const [notifyDialogText, setNotifyDialogText] = useState("Text");

  const [isLoading, setIsLoading] = useState(true);
  const [calendars, setCalendars] = useState([]);
  const [selectedCalendar, setSelectedCalendar] = useState({});
  const [calendarPeriods, setCalendarPeriods] = useState([]);
  const [selectedCalendarPeriod, setSelectedCalendarPeriod] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const [objectToAction, setObjectToAction] = useState({
    object_id: "",
    action_text: "",
    action_invoked: "",
  });

  const handleClearSearch = () => {
    try {
      setSearchTerm("");
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCalendars = async () => {
    try {
      setIsLoading(true);
      const res = await newRequest.post(
        "/glCy/findAll",
        {
          cy_org_code: currentUser.usr_org_code,
        }
      );
      setCalendars(res.data);
      setSelectedCalendar(res.data[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const fetchCalendarPeriods = async () => {
    try {
      const res = await newRequest.post(
        "/glCp/findAll",
        {
          cp_org_code: currentUser.usr_org_code,
          cp_cy_code: selectedCalendar.cy_code,
        },
        config
      );

      setCalendarPeriods(res.data);
      setSelectedCalendarPeriod(res.data[0]);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    try {
      fetchCalendarPeriods(selectedCalendar.cy_code);
    } catch (error) {
      console.log(error);
    }
  }, [selectedCalendar]);

  useEffect(() => {
    fetchCalendars();
  }, []);

  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [currentPage, setCurrentPage] = useState(0);
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const filteredCalendars = calendars.filter(
    (item) =>
      item.cy_code?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.cy_type?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.cy_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.cy_fm_date
        ?.toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      item.cy_to_date
        ?.toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
  );


  const slicedCalendarPeriods = calendarPeriods.slice(
    currentPage * rowsPerPage,
    currentPage * rowsPerPage + rowsPerPage
  );

  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 12));
    setCurrentPage(0);
  };

  const handleOpenClosePeriod = async (passedRow) => {
    try {
      setSelectedCalendarPeriod(passedRow);
      // console.log(`passedRow  ${JSON.stringify(passedRow, null, 2)}`);
      let action = "";
      let action_text = "";
      if (passedRow.cp_status === "Closed") {
        action = "Open";
        action_text = `Do you want to <b>OPEN period ${moment(
          passedRow.cp_fm_date
        ).format("DD-MMM-YYYY")} to ${moment(passedRow.cp_to_date).format(
          "DD-MMM-YYYY"
        )}</b> for ${selectedCalendar.cy_name}?`;
      } else {
        action = "Close";
        action_text = `Do you want to <b>CLOSE period ${moment(
          passedRow.cp_fm_date
        ).format("DD-MMM-YYYY")} to ${moment(passedRow.cp_to_date).format(
          "DD-MMM-YYYY"
        )}</b> for ${selectedCalendar.cy_name}?`;
      }
      setObjectToAction({
        object_id: passedRow._id,
        action_text: action_text,
        action_invoked: action,
      });
      setIsConfirmDialogOpen(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirm = async () => {
    try {
      try {
        const res = await newRequest.put(
          `/glCp/upd/${objectToAction.object_id}`,
          {
            cp_status:
              objectToAction.action_invoked === "Open" ? "Opened" : "Closed",
            updatedBy: currentUser.usr_id,
          }
        );
        // console.log(`glCp/upd  ${JSON.stringify(res.data, null, 2)}`);
        setIsLoading(true);
        fetchCalendarPeriods(selectedCalendar.cy_code);
        // show toast
        showToast(
          `Period has been ${objectToAction.action_invoked === "Open" ? "Opened" : "Closed"
          }`,
          "success"
        );
      } catch (error) {
        console.log(error);
      }
    } catch (err) {
      console.log(err);
    }
    // Close the dialog
    setIsConfirmDialogOpen(false);
  };

  const handleCancel = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleCloseNotifyDialog = () => {
    setIsNotifyDialogOpen(false);
  };

  return (
    <div className="gl-calendar">
      {isLoading ? (
        <div className="no-content"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <ScaleLoader
            color="#F44336"
            height={50}
            radius={8}
            width={4}
          />
        </div>
      ) : (
        <div className="gl-calendar-main-wrapper">
          <div className="gl-calendar-center-wrapper">
            <div className="center-details">
              <div className="center-navbar">
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h1 className="selected-title">{`${selectedCalendar.cy_name} PERIODS`}</h1>
                  <h3 className="selected-type">{selectedCalendar.cy_type}</h3>
                </div>
              </div>
              <hr />
              <motion.div className="table-content"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 1 } }}
                exit={{ opacity: 0, transition: { duration: 1 } }}>
                <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none' }}
                  className="table-container">
                  <Table sx={{ width: "max-content", border: 'none' }}>
                    <TableHead className="tablehead">
                      <TableRow className="tablerow">
                        <TableCell className="table-cell" style={{ maxWidth: "50px", display: "flex", textAlign: "center" }}>
                          #
                        </TableCell>
                        <TableCell className="table-cell" style={{ maxWidth: "200px" }}>
                          <span>CODE</span>
                        </TableCell>
                        <TableCell className="table-cell" style={{ width: "auto" }}>
                          <span>FROM</span>
                        </TableCell>
                        <TableCell className="table-cell" style={{ maxWidth: "150px" }}>
                          <span>TO</span>
                        </TableCell>
                        <TableCell className="table-cell" style={{ maxWidth: "100px" }}>
                          <span>QUATER</span>
                        </TableCell>
                        <TableCell className="table-cell" style={{ maxWidth: "100px" }}>
                          <span>STATUS</span>
                        </TableCell>
                        {["admin", "superadmin"].includes(currentUser.user_type_xx) &&
                          <TableCell className="table-cell" style={{ maxWidth: "300px", alignItems: "center" }}>
                            <span>ACTION</span>
                          </TableCell>}
                      </TableRow>
                    </TableHead>
                    <TableBody className="tablebody">
                      {slicedCalendarPeriods?.map((row, index) => (
                        <TableRow
                          key={row._id}
                          className={`${selectedCalendarPeriod?._id === row._id ? "tablerow current" : "tablerow"}`}
                          onClick={() => {
                            try {
                              setSelectedCalendarPeriod(row);
                            } catch (error) {
                              console.log(error);
                            }
                          }}
                        >
                          <TableCell className="table-cell" style={{ maxWidth: "50px", display: "flex", textAlign: "center" }}>
                            {currentPage * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell className="table-cell" style={{ maxWidth: "200px" }}>
                            {row.cp_code}
                          </TableCell>
                          <TableCell className="table-cell" style={{ width: "auto" }}>
                            {moment(row.cp_fm_date).format("DD-MMM-YYYY")}
                          </TableCell>
                          <TableCell className="table-cell" style={{ maxWidth: "150px" }}>
                            {moment(row.cp_to_date).format("DD-MMM-YYYY")}
                          </TableCell>
                          <TableCell className="table-cell" style={{ maxWidth: "100px" }}>
                            {row.cp_quoter}
                          </TableCell>
                          <TableCell className="table-cell" style={{ maxWidth: "100px" }}>
                            <span className={`period-status ${row.cp_status}`}>
                              {row.cp_status}
                            </span>
                          </TableCell>
                          {["admin", "superadmin"].includes(currentUser.user_type_xx) &&
                            <TableCell className="table-cell" style={{ maxWidth: "300px", alignItems: "center" }}>
                              <CustomButton
                                startIcon={
                                  row.cp_status === "Closed" ? (
                                    <LockOpenIcon />
                                  ) : (
                                    <LockIcon />
                                  )
                                }
                                height={"32px"}
                                onClick={() => handleOpenClosePeriod(row)}
                              >
                                {row.cp_status === "Closed"
                                  ? "Open Period"
                                  : "Close Period"}
                              </CustomButton>
                            </TableCell>}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[12]}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleRowsPerPage}
                    component="div"
                    count={calendarPeriods?.length}
                    rows={calendarPeriods}
                    page={currentPage}
                    onPageChange={handlePageChange}
                  />
                </TableContainer>
              </motion.div>
            </div>
          </div>
          <div className="gl-calendar-right-wrapper">
            <div className="gl-calendar-right-content">
              <div className="gl-calendar-right-navbar">
                <div className="search-input-wrapper">
                  <SearchIcon className="search-input-icon" />
                  <input
                    className="search-input"
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  {searchTerm.length > 0 && (
                    <CloseIcon
                      onClick={handleClearSearch}
                      className="search-input-icon"
                    />
                  )}
                </div>
              </div>
              <div className="gl-calendar-right-list">
                {filteredCalendars?.map((cy) => (
                  <>
                    <span
                      key={cy._id}
                      className={`${selectedCalendar._id === cy._id
                        ? "calendar active"
                        : "calendar"
                        }`}
                      onClick={async () => {
                        try {
                          setIsLoading(true);
                          setSelectedCalendar(cy);
                          // fetchCalendarPeriods(cy.cy_code);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                    >
                      <div className="title-row">
                        <span className="cy-title">{cy.cy_name}</span>
                        <span className="cy-type">{cy.cy_type}</span>
                      </div>
                      <div className="title-row">
                        <span className="cy-period">{`${moment(
                          cy.cy_fm_date
                        ).format("DD-MMM-YYYY")} To ${moment(
                          cy.cy_to_date
                        ).format("DD-MMM-YYYY")}`}</span>
                      </div>

                      <div className="title-row">
                        <span className="cy-period">{`CODE [${cy.cy_code}]`}</span>
                      </div>
                    </span>
                    <hr style={{ display: "flex", width: "100%" }} />
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Dialog to notify user about changes being saved */}
      <Dialog open={isNotifyDialogOpen} onClose={handleCloseNotifyDialog}>
        <DialogTitle>{notifyDialogTitle}</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: notifyDialogText }} />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseNotifyDialog}>Close</CustomButton>
        </DialogActions>
      </Dialog>
      {/* Confirm Dialog */}
      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        dialogTitle="Confirmation"
        dialogContent={
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(objectToAction.action_text) }} />
        }
      />
    </div>
  );
};

export default GlCalendar;
