import { useSelector } from "react-redux";
import "./UwProductHome.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { GridLoader } from "react-spinners";
import { motion } from "framer-motion";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import { CustomButton } from "../../../components/ui/CustomButton";
import ExportToExcelButton from "../../../components/exportToExcelButton/ExportToExcelButton";
import newRequest from "../../../utils/newRequest";
import AddIcon from "@mui/icons-material/Add";

const UwProductHome = () => {
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const [productsData, setProductsData] = useState([]);
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // columns to export
  const exportColumns = [
    "insured_name_xx",
    "pl_quote_no",
    "product_name_xx",
    "pl_status",
    "quote_stage_xx",
    "total_fc_si",
    "total_fc_premium",
    "commission_fc_amount",
    "commission_tax_fc_amount",
    "bizunit_name_xx",
  ];

  const fetchProducts = async () => {
    try {
      setIsLoading(true);
      const params = {
        pr_org_code: currentUser.usr_org_code,
      };
      const res = await newRequest.post("/uwProduct/findAll", params, config);
      setProductsData(res.data);
      
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    const filteredResults = productsData?.filter(
      (item) =>
        item.pr_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.pr_code?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filteredResults);
  }, [productsData, searchTerm]);

  return (
    <motion.div
      className="uw-product-home"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      <div className="navbar">
        <div className="navbar-left">
          <div className="search-input-wrapper">
            <SearchIcon className="search-input-icon" />
            <input
              className="search-input"
              type="text"
              placeholder="Search..."
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <h2 className="found">{`Found ${filteredData.length} Products`}</h2>
        </div>
        <div className="navbar-right">
          <CustomButton startIcon={<RefreshIcon />} onClick={fetchProducts}>
            Refresh
          </CustomButton>
          <CustomButton
            startIcon={<AddIcon />}
            onClick={() => {
              navigate("/uwProduct/new", {
                state: null,
              });
            }}
          >
            New
          </CustomButton>
          <ExportToExcelButton
            data={filteredData}
            exportColumns={exportColumns}
            fileName="Products.xlsx"
          />
        </div>
      </div>
      <div className="content">
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "200px",
              width: "100%",
              height: "100%",
            }}
          >
            <GridLoader
              color="#F44336"
              style={{
                height: "50px",
                width: "50px",
              }}
            />
          </div>
        ) : (
          <div className="products-wrapper">
            {filteredData?.map((product) => (
              <div
                className="single-product"
                key={product._id}
                onClick={() => {
                  try {
                    navigate(`/uwProduct/${product._id}`, {
                      state: product,
                    });
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                <div className="product-title-row">
                  <h2 className="product-name">{product.pr_name}</h2>
                  <h3 className="product-name">{product.pr_code}</h3>
                </div>

                <div className="created-by-wrapper">
                  <div className="created-by-user">
                    <span>{`Created By ${product.created_by_name_xx}`}</span>
                    <img
                      src={
                        product?.created_by_photo_url_xx || "/img/noavatar.jpg"
                      }
                      alt=""
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>{product.time_ago_xx}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default UwProductHome;
