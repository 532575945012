import "./ArReceipt.scss";
import AllDocsNote from "../../../components/allDocsNote/AllDocsNote.jsx";
import AllFiles from "../../../components/allFiles/AllFiles.jsx";
import ConfirmDialog from "../../../components/confirmDialog/confirmDialog.jsx";
import EntitySelectLayout from "../../../components/entitySelectLayout/EntitySelectLayout.jsx";
import CircularButton, {
  CustomButton,
  CustomButtonSmall,
  CustomLoadingButton,
} from "../../../components/ui/CustomButton.jsx";
import { customSelectStyles } from "../../../components/ui/uiStyleElements.jsx";
import { showToast } from "../../../toastService.js";
import { formatStringToNumberV2, numberToWordsWithDecimal } from "../../../utils/genericFunctions.js";
import newRequest from "../../../utils/newRequest.js";
import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import InsertCommentIcon from "@mui/icons-material/InsertComment";
import RefreshIcon from "@mui/icons-material/Refresh";
import SummarizeIcon from "@mui/icons-material/Summarize";
import CommentIcon from '@mui/icons-material/Comment';
import AddLinkIcon from '@mui/icons-material/AddLink';
import Badge from '@mui/material/Badge';
import EventNoteIcon from '@mui/icons-material/EventNote';
import TopicIcon from '@mui/icons-material/Topic';
import Big from "big.js";
import { motion } from "framer-motion";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";
import { FaSave } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import EmailIcon from '@mui/icons-material/Email';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { GridLoader, ScaleLoader } from "react-spinners";
import { ToggleSwitch } from "../../../components/toogle/ToggleSwitch.jsx";
import FileUploadComponent from "../../../components/fileUploadComponent/FileUploadComponent.jsx";
import ValidationMessages from "../../../components/validationMessages/ValidationMessages.jsx";
import DOMPurify from 'dompurify';
import PdfPreview from "../../../components/pdfPreview/PdfPreview.jsx";
import EmailComponent from "../../../components/emailComponent/EmailComponent.jsx";


const ArReceipt = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { currentUser } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
  const [notifyDialogTitle, setNotifyDialogTitle] = useState("Confirm");
  const [notifyDialogText, setNotifyDialogText] = useState("Text");
  const [figuresToShow, setFiguresToShow] = useState("org");
  const navigate = useNavigate();
  const location = useLocation();
  const parts = location.pathname.split("/");

  const [receiptData, setReceiptData] = useState(null);
  const [receiptDocId, setReceiptDocId] = useState(null);
  const [passedDocId, setPassedDocId] = useState(parts[parts.length - 1]);

  // email component 
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [emailData, setEmailData] = useState(null);
  // pdf preview dialog
  const [pdfPreviewDialogOpen, setPdfPreviewDialogOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [binaryDocid, setBinaryDocid] = useState(null);

  const [savingTitle, setSavingTitle] = useState("Save");
  const [documentEnabled, setDocumentEnabled] = useState("Y");
  const [nextAction, setNextAction] = useState("Validate");
  const [summaryCurrentView, setSummaryCurrentView] = useState("summary");
  const [isConfettiActive, setIsConfettiActive] = useState(false);
  const [validationMsgDialog, setValidationMsgDialog] = useState(false);
  const [validationMessagesResult, setValidationMessagesResult] = useState({});
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const [objectToAction, setObjectToAction] = useState({
    object_id: "",
    action_text: "",
    new_doc_status: "",
  });
  const [invokedAction, setInvokedAction] = useState("");
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogAction, setConfirmDialogAction] = useState("None");
  // number generation
  const [numGeneration, setNumGeneration] = useState({});
  let generatedDocNo = "";
  let isDocumentNumberSetup = false;
  // receipt fields
  const [hdNo, setHdNo] = useState(null);
  // currency
  const [hdCurCode, setHdCurCode] = useState({
    value: "",
    label: "",
  });
  const [hdCurRate, setHdCurRate] = useState(1);
  const [currencies, setCurrencies] = useState([]);
  const [hdNarration, setHdNarration] = useState(null);
  const [hdRefNo, setHdRefNo] = useState(null);
  const [hdAcNo, setHdAcNo] = useState(null);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [hdBizUnit, setHdBizUnit] = useState(null);
  const [branches, setBranches] = useState([]);
  const [hdGlDate, setHdGlDate] = useState(null);
  const [hdMode, setHdMode] = useState(null);
  const [receiptMode, setReceiptMode] = useState([]);
  const [hdStatus, setHdStatus] = useState("New");
  const [hdPayingFor, setHdPayingFor] = useState(null);
  const [payingFor, setPayingFor] = useState([]);
  const [hdAentCode, setHdAentCode] = useState(null);
  const [hdEntCode, setHdEntCode] = useState(null);
  const [clients, setClients] = useState([]);
  const [hdChqNo, setHdChqNo] = useState(null);
  const [hdChqBank, setHdChqBank] = useState(null);
  const [hdChqDate, setHdChqDate] = useState(null);
  const [hdFcAmount, setHdFcAmount] = useState("");
  const [hdLcAmount, setHdLcAmount] = useState("");

  const [allocatedFcAmountXx, setAllocatedFcAmountXx] = useState(0);
  const [allocatedLcAmountXx, setAllocatedLcAmountXx] = useState(0);
  const [netoffCommission, setNetoffCommission] = useState("Y");
  const [matchedFcAmount, setMatchedFcAmount] = useState();
  const [matchedLcAmount, setMatchedLcAmount] = useState();
  const [matchedCommFc, setMatchedCommFc] = useState();
  const [matchedCommLc, setMatchedCommLc] = useState();

  const [controlPremiumFc, setControlPremiumFc] = useState();
  const [controlPremiumLc, setControlPremiumLc] = useState();
  const [controlCommissionFc, setControlCommissionFc] = useState();
  const [controlCommissionLc, setControlCommissionLc] = useState();

  // end of receipt fields
  //lines
  const [receiptLines, setReceiptLines] = useState([]);
  const [selectedReceiptLine, setSelectedReceiptLine] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setCurrentPage(0);
  };
  // attachments
  const [attachmentsData, setAttachmentsData] = useState([]);

  const slicedReceiptLines = receiptLines.slice(
    currentPage * rowsPerPage,
    currentPage * rowsPerPage + rowsPerPage
  );

  const customNoOptionsMessage = (customMessage) => {
    return (
      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span
          style={{
            color: "#f44336",
            fontWeight: "500",
            fontSize: "18px",
          }}
        >
          {customMessage}
        </span>
        <CustomButtonSmall
          onClick={() => {
            try {
              navigate(`/entity/new`, {
                state: null,
              });
            } catch (error) {
              console.log(error);
            }
          }}
        >
          New Client
        </CustomButtonSmall>
      </span>
    );
  };

  const fetchReceiptLines = async (hdNo, receiptDocid) => {
    try {
      const res = await newRequest.post("/arReceiptLn/findAll", {
        ln_org_code: currentUser.usr_org_code,
        ln_hd_no: hdNo,
        ln_hd_no_docid: receiptDocid,
      });

      setReceiptLines(res.data);

      // console.log(`setReceiptLines  ${JSON.stringify(res.data, null, 2)}`);

    } catch (err) {
      console.log(err);
    }
  };

  const [selectedRiskNote, setSelectedRiskNote] = useState({});
  const [entityRiskNotes, setEntityRiskNotes] = useState([]);

  const fetchEntityRiskNotes = async () => {
    try {

      const res = await newRequest.post(
        "/uwPolicy/entitynotes",
        {
          org_code: currentUser.usr_org_code,
          aent_code: hdEntCode?.aent_code,
          ent_code: hdEntCode?.value,
        }
      );
      const options = res.data.map((rn) => ({
        pl_risk_note_no: rn.pl_risk_note_no,
        pl_end_no: rn.pl_end_no,
        pl_no: rn.pl_no,
        pl_docid: rn._id,
        os_to_remit_fc: rn.os_to_remit_fc,
        os_to_remit_lc: rn.os_to_remit_lc,
        commission_os_fc: rn.commission_os_fc,
        commission_os_lc: rn.commission_os_lc,
        value: rn.pl_risk_note_no,
        label: rn.pl_risk_note_no + " " + rn.insured_name_xx + " Prem " + rn.fmt_total_fc_prem + " OS Comm " + rn.fmt_commission_os_fc,
      }));

      // console.log(`setEntityRiskNotes ${JSON.stringify(options, null, 2)}`);
      setEntityRiskNotes(options);
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddRiskNote = async () => {
    try {
      if (!selectedRiskNote) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please select risk note to match!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      if (!matchedFcAmount) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter amount to match!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      const osFcToAllocate = formatStringToNumberV2(hdFcAmount) - allocatedFcAmountXx;

      if (matchedFcAmount > osFcToAllocate) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(
          `Total available to match is <strong>${hdCurCode.value} ${numeral(
            osFcToAllocate
          ).format("0,0.00")}</strong>. You CANNOT match more than this amount`
        );
        setIsNotifyDialogOpen(true);
        return;
      }

      // Check matched amount aganist control amount
      // premium 
      if (hdPayingFor.value === "Premium") {
        if (parseFloat(matchedFcAmount) > parseFloat(controlPremiumFc)) {
          console.log(`Matched ${matchedFcAmount} more than control amount of ${controlPremiumFc}`)
          setNotifyDialogTitle("Note!");
          setNotifyDialogText(
            `You cannot match more than <strong>${hdCurCode.value} ${numeral(
              controlPremiumFc
            ).format("0,0.00")}</strong>. This is the total Outstanding Premium to Match`
          );
          setIsNotifyDialogOpen(true);
          return;
        }

        // commission
        if (parseFloat(matchedCommFc) > parseFloat(controlCommissionFc)) {
          setNotifyDialogTitle("Note!");
          setNotifyDialogText(
            `You cannot match more than <strong>${hdCurCode.value} ${numeral(
              controlCommissionFc
            ).format("0,0.00")}</strong>. This is the total Outstanding Commission to Match`
          );
          setIsNotifyDialogOpen(true);
          return;
        }

      }
      setIsProcessing(true);


      const res = await newRequest.post("/arReceiptLn/add", {
        ln_org_code: currentUser.usr_org_code,
        ln_hd_no: hdNo,
        ln_hd_no_docid: receiptData._id,
        ln_doc_type: "RiskNote",
        ln_doc_no: selectedRiskNote.pl_risk_note_no,
        ln_doc_docid: selectedRiskNote.pl_docid,
        ln_fc_amount: matchedFcAmount,
        ln_lc_amount: matchedLcAmount,
        ln_comm_fc: matchedCommFc,
        ln_comm_lc: matchedCommLc,
        ln_narration: "Matched",
        userCode: currentUser.usr_id,
      }, config);


      if (res.data === "exists") {
        showToast(
          `Risk Note ${selectedRiskNote.pl_risk_note_no} already attached`,
          "error"
        );
      } else {
        try {
          const trace = {
            trc_org_code: currentUser.usr_org_code,
            trc_action_on: "Document",
            trc_doc_type: "Receipt",
            trc_doc_no: receiptData.hd_no,
            trc_aent_code: "",
            trc_ent_code: "",
            trc_action: "matched document",
            trc_remarks: `matched ${selectedRiskNote.pl_risk_note_no} totaling ${matchedFcAmount} to this receipt`,
            trc_tags: [`matching`],
            trc_doc_docid: receiptData._id,
            userCode: currentUser.usr_id,
          };

          const trace_res = await newRequest.post("/trace/add", trace, config);
        } catch (error) {
          console.log(error);
        }

        //  if receipt already approved, add to the policy receipt also
        if (hdStatus === "Approved") {
          try {
            const mergeLines = {
              ln_org_code: currentUser.usr_org_code,
              ln_hd_no: hdNo,
              ln_hd_no_docid: receiptData._id,
              receipt_mode: hdMode.value,
              receipt_date: hdGlDate,
              receipt_currency: hdCurCode.value,
              userCode: currentUser.usr_id,
            };
            const merge_res = await newRequest.post(
              "/arReceiptHdr/mergeMatched",
              mergeLines,
              config
            );
          } catch (error) {
            console.log(error);
          }
        }
        setMatchedFcAmount(null)
        setMatchedLcAmount(null)
        setMatchedCommFc(null)
        setMatchedCommLc(null)
        setSelectedRiskNote({
          pl_risk_note_no: "",
          pl_end_no: "",
          pl_no: "",
          pl_docid: "",
          os_to_remit_fc: 0,
          os_to_remit_lc: 0,
          commission_os_fc: 0,
          commission_os_lc: 0,
          value: "",
          label: "",
        })
        fetchReceipt(receiptData._id);
        removeRisknoteById()
        showToast(
          `Risk note ${selectedRiskNote.pl_risk_note_no} added`,
          "success"
        );
      }


    } catch (error) {
      console.log(error);
    } finally {
      setIsProcessing(false);
    }
  };


  const removeRisknoteById = (id) => {
    try {
      const updatedData = entityRiskNotes.filter(item => item._id !== id);
      setEntityRiskNotes(updatedData);
    } catch (error) {
      console.log(error)
    }
  };

  const formatNumber = (value) => {
    if (isNaN(value)) return '';
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  };



  const handleMatchedFcAmountBlur = (e) => {
    if (e.target.value) {
      const strippedValue = e.target.value.replace(/,/g, ""); // Remove commas
      const parsedValue = Big(strippedValue); // Use big.js to handle the value

      // Check if the parsed value is NaN
      if (!parsedValue.eq(parsedValue)) {
        setMatchedFcAmount("");
      } else {
        // Format the parsed value with 2 decimal places and digit grouping
        const formattedValue = parsedValue
          .toFixed(2)
          .toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        setMatchedFcAmount(formattedValue);
        const lcAmount = Number(parseFloat(parsedValue * hdCurRate).toFixed(4));
        setMatchedLcAmount(lcAmount);
      }
    }
  };

  const handleMatchedCommFcBlur = (e) => {
    if (e.target.value) {
      const strippedValue = e.target.value.replace(/,/g, ""); // Remove commas
      const parsedValue = Big(strippedValue); // Use big.js to handle the value

      // Check if the parsed value is NaN
      if (!parsedValue.eq(parsedValue)) {
        setMatchedCommFc("");
      } else {
        // Format the parsed value with 2 decimal places and digit grouping
        const formattedValue = parsedValue
          .toFixed(2)
          .toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        setMatchedCommFc(formattedValue);

        const lcAmount = Number(parseFloat(parsedValue * hdCurRate).toFixed(4));
        setMatchedCommLc(lcAmount);
      }
    }
  };

  const handleFcAmountBlur = (e) => {
    try {
      if (e.target.value) {
        const strippedValue = e.target.value.replace(/,/g, ""); // Remove commas
        const parsedValue = Big(strippedValue); // Use big.js to handle the value

        // Check if the parsed value is NaN
        if (!parsedValue.eq(parsedValue)) {
          setHdFcAmount("");
        } else {
          // Format the parsed value with 2 decimal places and digit grouping
          const formattedValue = parsedValue
            .toFixed(2)
            .toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          setHdFcAmount(formattedValue);

          const lcAmount = Number(parseFloat(parsedValue * hdCurRate).toFixed(4));
          setHdLcAmount(lcAmount);
        }
      }
    } catch (error) {
      console.log(error)
    }
  };



  const goBack = () => {
    navigate(-1);
  };



  const handleGlDateChange = async (e) => {
    try {
      if (e.target.value) {
        const formattedGlDate = moment(e.target.value).format("YYYY-MM-DD");
        setHdGlDate(formattedGlDate);
        if (hdCurCode.value !== currentUser.currency_code_xx) {
          const curRate = {
            rate_org_code: currentUser.usr_org_code,
            rate_cur_code_fm: hdCurCode.value,
            rate_cur_code_to: currentUser.currency_code_xx,
            rate_date: formattedGlDate,
          };
          // console.log(`curRate  ${JSON.stringify(curRate, null, 2)}`);
          // get rate
          const end_res = await newRequest.post(
            "/glCurrencyRate/find",
            curRate,
            config
          );
          // console.log(`end_res  ${JSON.stringify(end_res.data, null, 2)}`);
          if (end_res.data) {
            setHdCurRate(end_res.data.rate);
          } else {
            setHdCurCode({
              value: currentUser.currency_code_xx,
              label: currentUser.currency_name_xx,
            });
            setHdCurRate(1);
            setNotifyDialogTitle("Note!");
            setNotifyDialogText(
              `Conversion Rate from <strong>${hdCurCode.value} to ${currentUser.currency_code_xx
              } for date ${moment(e.target.value).format(
                "DD-MMM-YYYY"
              )}</strong> NOT found. Currency will reset to <strong>${currentUser.currency_code_xx
              }</strong>`
            );
            setIsNotifyDialogOpen(true);
          }
        } else {
          setHdCurRate(1);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };


  const fetchReceipt = async (doc_id) => {
    try {
      setIsLoading(true);

      const res = await newRequest.post(
        "/arReceiptHdr/findById", { receipt_docid: doc_id, }
      );

      setReceiptData(res.data);

      if (res.data) {
        setReceiptDocId(res.data._id);
        setPassedDocId(res.data._id);


        // set receipt information
        if (res.data.hd_no) {
          setHdNo(res.data.hd_no);
        }

        if (res.data.hd_status) {
          setHdStatus(res.data.hd_status);
        }
        if (res.data.hd_cur_rate) {
          setHdCurRate(res.data.hd_cur_rate);
        }
        if (res.data.hd_narration) {
          setHdNarration(res.data.hd_narration);
        }
        if (res.data.hd_ref_no) {
          setHdRefNo(res.data.hd_ref_no);
        }
        if (res.data.hd_chq_no) {
          setHdChqNo(res.data.hd_chq_no);
        }
        if (res.data.hd_chq_bank) {
          setHdChqBank(res.data.hd_chq_bank);
        }

        if (res.data.hd_chq_date) {
          const formattedChqDate = moment(res.data.hd_chq_date).format(
            "YYYY-MM-DD"
          );
          setHdChqDate(formattedChqDate);
        }

        if (res.data.hd_fc_amount) {
          const formattedValue = formatNumber(res.data.hd_fc_amount);
          setHdFcAmount(formattedValue)
        }

        if (res.data.hd_lc_amount) {
          setHdLcAmount(res.data.hd_lc_amount);
        }

        if (res.data.hd_biz_unit) {
          setHdBizUnit({
            value: res.data.hd_biz_unit,
            label: res.data.bizunit_name_xx,
          });
        }

        if (res.data.hd_gl_date) {
          const formattedGlDate = moment(res.data.hd_gl_date).format(
            "YYYY-MM-DD"
          );
          setHdGlDate(formattedGlDate);
        }

        if (res.data.hd_cur_code) {
          setHdCurCode({
            value: res.data.hd_cur_code,
            label: res.data.currency_xx,
          });
        }

        if (res.data.hd_mode) {
          setHdMode({
            value: res.data.hd_mode,
            label: res.data.receipt_mode_xx,
          });
        }

        if (res.data.hd_paying_for) {
          setHdPayingFor({
            value: res.data.hd_paying_for,
            label: res.data.paying_for_xx,
          });
        }

        if (res.data.bank_ac_no_xx) {
          setHdAcNo({
            bank_code: res.data.bank_code_xx,
            branch_code: res.data.bank_branch_code_xx,
            value: res.data.bank_ac_no_xx,
            label: res.data.bank_ac_name_xx,
          });
        }

        if (res.data.hd_ent_code) {
          setHdEntCode({
            ent_photo_url: res.data?.ent_photo_url,
            ent_status: res.data?.ent_status,
            aent_name_xx: res.data?.aent_name_xx,
            aent_code: res.data.hd_aent_code,
            value: res.data.hd_ent_code,
            label: res.data.client_name_xx,
          });
        }

        setDocumentEnabled(res.data.document_enabled_xx);
        setNextAction(res.data.next_action_xx);

        setAllocatedFcAmountXx(res.data.allocated_fc_amount_xx);
        setAllocatedLcAmountXx(res.data.allocated_lc_amount_xx);

        // get receipt lines
        fetchReceiptLines(res.data.hd_no, res.data._id);
      }
      // fetch attachments
      fetchDocumentAttachments("receipt", res.data._id);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };


  const fetchClientsBySearch = async (inputValue, callback) => {
    try {
      if (inputValue?.length > 2) {
        const res = await newRequest.post("/allEntity/findBySearch", { search_input: inputValue, });
        const options = res.data.map((entity) => ({
          ent_photo_url: entity?.ent_photo_url,
          ent_status: entity?.ent_status,
          aent_name_xx: entity?.aent_name_xx,
          aent_code: entity.ent_aent_code,
          value: entity.ent_code,
          label: entity.ent_name,
        }));

        callback(options);
      }
    } catch (error) {
      console.error("Error fetching entity types:", error);
      callback([]);
    }
  };

  const fetchBranches = async () => {
    try {
      const res = await newRequest.post("/orgUnit/findAll", { unit_status: "Active" });
      const options = res.data.map((branch) => ({
        value: branch.unit_code,
        label: branch.unit_name,
      }));
      setBranches(options);
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };

  const fetchCurrencies = async () => {
    try {
      const res = await newRequest.post("/glCurrency/findAll");
      const options = res.data.map((currency) => ({
        value: currency.cur_code,
        label: currency.cur_name,
      }));
      setCurrencies(options);
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };

  const fetchBankAccounts = async () => {
    try {
      const res = await newRequest.post("/glBankAc/findAll");
      const options = res.data.map((bank) => ({
        bank_code: bank.bank_code_xx,
        branch_code: bank.bank_branch_code_xx,
        value: bank.ac_no,
        label: bank.ac_name,
      }));
      setBankAccounts(options);

      // console.log(`setBankAccounts  ${JSON.stringify(res.data, null, 2)}`);

      if (passedDocId === "new") {
        setHdAcNo({
          bank_code: res.data[0].bank_code_xx,
          branch_code: res.data[0].bank_branch_code_xx,
          value: res.data[0].ac_no,
          label: res.data[0].ac_name,
        });
      }
    } catch (error) {
      console.error("Error fetching bank accounts :", error);
    }
  };

  // fetch number generation code
  const fetchNumberGeneration = async () => {
    try {
      const res = await newRequest.post(
        "/orgNumgen/find",
        {
          gen_org_code: currentUser.usr_org_code,
          gen_type: "Receipt",
        },
        config
      );
      setNumGeneration(res.data);
      isDocumentNumberSetup = true;
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDocumentAttachments = async (docType, docid) => {
    try {
      const res = await newRequest.post(
        "/files/findDocFiles",
        {
          fl_org_code: currentUser.usr_org_code,
          fl_doc_type: docType,
          fl_parent_doc_id: docid,
        },
        config
      );

      setAttachmentsData(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchReceiptMode = async () => {
    try {
      const res = await newRequest.post(
        "/sysCode/findAll", { sys_type: "Receipt Mode" }
      );
      const options = res.data.map((mode) => ({
        value: mode.sys_code,
        label: mode.sys_name,
      }));
      setReceiptMode(options);
    } catch (error) {
      console.error("Error fetching receipt modes :", error);
    }
  };

  const fetchPayingFor = async () => {
    try {
      const res = await newRequest.post(
        "/sysCode/findAll", { sys_type: "Paying For" }
      );

      const options = res.data.map((mode) => ({
        value: mode.sys_code,
        label: mode.sys_name,
      }));
      setPayingFor(options);
    } catch (error) {
      console.error("Error fetching receipt modes :", error);
    }
  };

  // generate document number
  const generateDocumentNo = async () => {
    try {
      const res = await newRequest.post(
        "/orgNumgen/gen",
        {
          gen_org_code: currentUser.usr_org_code,
          gen_type: "Receipt",
        },
        config
      );
      // console.log(`found generatedDocNo  ${JSON.stringify(res.data, null, 2)}`);
      generatedDocNo = res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const handleConfettiClick = () => {
    setIsConfettiActive(true);
    // Set a timeout to stop the confetti after a few seconds (optional)
    setTimeout(() => {
      setIsConfettiActive(false);
    }, 5000); // Stop confetti after 3 seconds
  };

  // close notify dialog
  const handleCloseNotifyDialog = () => {
    setIsNotifyDialogOpen(false);
  };

  const handleConfirm = async () => {
    try {
      if (confirmDialogAction === "receiptAction") {
        if (["Approved"].includes(objectToAction.new_doc_status)) {
          // check if period is open
          const formattedGlDate = moment(hdGlDate).format("YYYY-MM-DD");
          const res = await newRequest.post(
            "/glCp/periodStatus",
            {
              cp_org_code: currentUser.usr_org_code,
              cp_cy_type: "Financial",
              gl_date: formattedGlDate,
            },
            config
          );
          if (res.data.cp_status === "Closed") {
            setIsConfirmDialogOpen(false);
            setConfirmDialogAction("None");
            setNotifyDialogTitle("Note!");
            setNotifyDialogText(
              `Accounting date ${moment(hdGlDate).format(
                "DD-MMM-YYYY"
              )} is within Financial period ${res.data.cp_code
              }. The period is ${res.data.cp_status}. You cannot proceed.`
            );
            setIsNotifyDialogOpen(true);
            return;
          }
        }

        if ((formatStringToNumberV2(hdFcAmount) - allocatedFcAmountXx) < 0) {
          setIsConfirmDialogOpen(false);
          setConfirmDialogAction("None");
          setNotifyDialogTitle("Note!");
          setNotifyDialogText(
            `You cannot allocate more than <b>${hdCurCode?.value} ${numeral(formatStringToNumberV2(hdFcAmount)).format("0,0.00")}</b>. You cannot proceed.`
          );
          setIsNotifyDialogOpen(true);
          return;
        }


        setIsProcessing(true);
        // do all necessary checks

        const validateResponse = await newRequest.post("/arReceiptHdr/validate", { receipt_docid: receiptDocId });

        if (validateResponse.data.result_status === "error") {
          setIsProcessing(false);
          // <ValidationMessages messageData={validateResponse.data} />
          setValidationMessagesResult(validateResponse.data);
          setIsConfirmDialogOpen(false);
          setValidationMsgDialog(true);
          return;
        }

        const res = await newRequest.post(
          "/arReceiptHdr/updStatus",
          {
            userCode: currentUser.usr_id,
            hd_status: objectToAction.new_doc_status,
            receipt_docid: receiptData._id,
          }
        );

        try {
          const trace_res = await newRequest.post(
            "/trace/add",
            {
              trc_org_code: currentUser.usr_org_code,
              trc_action_on: "Document",
              trc_doc_type: "Receipt",
              trc_doc_no: receiptData.hd_no,
              trc_aent_code: "",
              trc_ent_code: "",
              trc_action: "updated document status",
              trc_remarks: `updated receipt to ${objectToAction.new_doc_status}`,
              trc_tags: [`updated receipt to ${objectToAction.new_doc_status}`],
              trc_doc_docid: receiptData._id,
              userCode: currentUser.usr_id,
            },
            config
          );
        } catch (error) {
          console.log(error);
        }

        // add document in uw commission receipts if its a comission receipt
        if (objectToAction.new_doc_status === "Approved") {
          try {
            // if new status us approved, this function loops through all lines and adds them to policy receipt
            if (hdPayingFor.value === "Premium") {
              const merge_res = await newRequest.post(
                "/arReceiptHdr/mergeMatched",
                {
                  ln_org_code: currentUser.usr_org_code,
                  ln_hd_no: hdNo,
                  ln_hd_no_docid: receiptData._id,
                  receipt_mode: hdMode.value,
                  receipt_date: hdGlDate,
                  receipt_currency: hdCurCode.value,
                  userCode: currentUser.usr_id,
                },
                config
              );
            }
            // loop to add commission line if its commission
            if (hdPayingFor.value === "Commission") {
              for (let i = 0; i < receiptLines.length; i++) {
                try {
                  const res = await newRequest.post(
                    "/uwPolicyCommPmt/add",
                    {
                      comm_org_code: currentUser.usr_org_code,
                      comm_risk_note_no: receiptLines[i].ln_doc_no,
                      comm_policy_docid: receiptLines[i].ln_doc_docid,
                      comm_doc_no: hdNo,
                      comm_mode: hdMode.value,
                      comm_date: hdGlDate,
                      comm_currency: hdCurCode.value,
                      comm_fc_amount: receiptLines[i].ln_fc_amount,
                      comm_lc_amount: receiptLines[i].ln_lc_amount,
                      comm_paid_to: "Receipt",
                      comm_our_docid: receiptData._id,
                      userCode: currentUser.usr_id,
                    },
                    config
                  );
                } catch (error) {
                  console.log(error)
                }
              }
            }

            setConfirmDialogAction("None");
            setNotifyDialogTitle("Success!");
            setNotifyDialogText(`Receipt successfully Approved`);
          } catch (error) {
            console.log(error);
          }
        }

        if (["Approved"].includes(objectToAction.new_doc_status)) { handleConfettiClick(); }

        fetchReceipt(receiptDocId);

        setConfirmDialogAction("None");
        setNotifyDialogTitle("Success!");
        setNotifyDialogText(`Receipt successfully Approved`);
        setIsProcessing(false);
      } else if (confirmDialogAction === "unmatchDocument") {
        console.log(`Ummatch document ${objectToAction.object_id}`);
        setIsProcessing(true);
        try {
          const merge_res = await newRequest.post(
            "/arReceiptLn/del",
            {
              line_docid: objectToAction.object_id, userCode: currentUser.usr_id,
            },
            config
          );
          fetchReceipt(receiptDocId);
          setIsProcessing(false);
          showToast(`Document Unmached!`, "success");
        } catch (error) {
          setIsProcessing(false);
          console.log(error);
        }
      }
    } catch (err) {
      console.log(err);
    }
    // Close the dialog
    setIsConfirmDialogOpen(false);
  };

  const handleCancel = () => {
    setIsConfirmDialogOpen(false);
  };


  const saveRecord = async (e) => {
    // e.preventDefault();
    try {
      if (!hdEntCode) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please select account!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      if (!hdAcNo) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please select bank account!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      if (!hdMode) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter receipt mode!`);
        setIsNotifyDialogOpen(true);
        return;
      }
      if (!hdGlDate) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter GL Date!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      // save new quote
      setSavingTitle("saving...");
      setIsLoading(true);
      // Simulate delay for 5 seconds
      // await new Promise((resolve) => setTimeout(resolve, 5000));

      if (passedDocId === "new") {
        // const isEmpty = Object.keys(numGeneration).length === 0;
        // console.log(
        //   `Is receipt No Entered ? ${JSON.stringify(!hdNo, null, 2)}`
        // );
        if (!hdNo) {
          // generate quote number
          await generateDocumentNo();
        }
      }

      if (!hdFcAmount) {
        // hdFcAmount is either empty or null
        setHdFcAmount(0);
      } else {
        const lcAmount = Number(parseFloat(formatStringToNumberV2(hdFcAmount) * hdCurRate).toFixed(4));
        setHdLcAmount(lcAmount);
      }

      //prepare data
      const arReceiptHeader = {
        hd_org_code: currentUser.usr_org_code,
        hd_no: hdNo || generatedDocNo,
        hd_narration: hdNarration || "",
        hd_ref_no: hdRefNo || "",
        hd_ac_no: hdAcNo?.value,
        hd_biz_unit: hdBizUnit?.value,
        hd_gl_date: hdGlDate,
        hd_cur_code: hdCurCode?.value,
        hd_cur_rate: hdCurRate,
        hd_mode: hdMode?.value,
        hd_status: hdStatus,
        hd_paying_for: hdPayingFor?.value,
        hd_aent_code: hdEntCode?.aent_code,
        hd_ent_code: hdEntCode?.value,
        hd_chq_no: hdChqNo || "",
        hd_chq_bank: hdChqBank || "",
        hd_chq_date: hdChqDate,
        hd_fc_amount: formatStringToNumberV2(hdFcAmount),
        hd_lc_amount: hdLcAmount,
        hd_entity_name: hdEntCode?.label,
        userCode: currentUser.usr_id,
      };

      if (passedDocId === "new") {
        const res = await newRequest.post(
          "/arReceiptHdr/add",
          arReceiptHeader
        );

        console.log(`saved receipt is... ${JSON.stringify(res.data, null, 2)}`);
        setHdNo(generatedDocNo);
        setSavingTitle("Save");
        setIsProcessing(false);
        setIsLoading(false);
        setNotifyDialogTitle("Success!");
        setNotifyDialogText(`Receipt ${generatedDocNo} successfully saved`);
        setIsNotifyDialogOpen(true);
        fetchReceipt(res.data._id);

      } else {
        // console.log(`Updating receiptDocId ${receiptDocId} and ReceiptData  ${JSON.stringify(receiptData, null, 2)}`);
        const res = await newRequest.put(
          `/arReceiptHdr/upd/${receiptDocId}`, arReceiptHeader);
        setNotifyDialogTitle("Success!");
        setNotifyDialogText(`Receipt successfully updated`);
        setIsNotifyDialogOpen(true);
        setSavingTitle("Save");
        setIsLoading(false);
        setPassedDocId(receiptDocId);
      }
    } catch (err) {
      setSavingTitle("Save");
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    try {
      fetchEntityRiskNotes();
    } catch (error) {
      console.log(error);
    }
  }, [hdEntCode]);

  useEffect(() => {
    // fetch number generation to check if its auto or manual
    fetchNumberGeneration();

    //if receipt is selected, use receiptDocId to get quote details
    //if its new record, no fetch is necessary

    if (passedDocId === "new" || passedDocId === undefined) {
      setReceiptDocId("new");
      setDocumentEnabled("Y")
      // // set gl date
      const currentDate = new Date();
      const formattedCurrentDate = currentDate.toISOString().split("T")[0];
      setHdGlDate(formattedCurrentDate);
      // set defult business unit
      setHdBizUnit({
        value: currentUser.biz_unit_code_xx,
        label: currentUser.biz_unit_name_xx,
      });
      // set default currency
      setHdCurCode({
        value: currentUser.currency_code_xx,
        label: currentUser.currency_name_xx,
      });
    } else {
      fetchReceipt(passedDocId);
    }
    fetchBankAccounts();
    fetchCurrencies();
    fetchBranches();
    // fetchClients();
    fetchReceiptMode();
    fetchPayingFor();
  }, [passedDocId]);


  // top menu 
  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const [activeTab, setActiveTab] = useState(0);

  const topMenus = [
    {
      menu_key: "details",
      menu_title: "Details",
      menu_icon: <EventNoteIcon />,
      menu_count: 0,
    },
    {
      menu_key: "attachments",
      menu_title: "Attachments",
      menu_icon: <TopicIcon />,
      menu_count: attachmentsData.length,
    },
    {
      menu_key: "matching",
      menu_title: "Matching",
      menu_icon: <AddLinkIcon />,
      menu_count: 0,
    },
    {
      menu_key: "comments",
      menu_title: "Comments",
      menu_icon: <CommentIcon />,
      menu_count: 0,
    }
  ]

  return (
    <motion.div
      className="ar-receipt"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      <div className="main-wrapper">
        <div className="navbar">
          <div className="navbar-left">
            <h1 className="main-title">
              {hdEntCode?.label || "NEW RECEIPT"}
            </h1>
            <h1 className="sub-title">
              {passedDocId !== "new" &&
                `${hdMode?.label} ${hdNo} [${hdStatus}]`} {passedDocId}
            </h1>
          </div>
          <div className="navbar-right">
            {passedDocId !== "new" && nextAction !== "None" && (
              <>
                {documentEnabled === "Y" && (
                  <CustomButton
                    startIcon={<RefreshIcon />}
                    onClick={() => { fetchReceipt(receiptDocId) }}
                  >
                    Refresh
                  </CustomButton>
                )}
                <CustomButton
                  startIcon={<DragIndicatorIcon />}
                  onClick={() => {
                    try {
                      // console.log(`Status is ${hdStatus}`);
                      let action_text = "";
                      let new_doc_status = hdStatus;
                      switch (hdStatus) {
                        case "New":
                          action_text = `Do you want to Validate this receipt ?`;
                          setInvokedAction("Validate");
                          new_doc_status = "Validated";
                          break;
                        case "Validated":
                          action_text = `Do you want to Approve this receipt?`;
                          setInvokedAction("Approve");
                          new_doc_status = "Approved";
                          break;
                        case "Approved":
                          action_text = `Do you want to cancel this receipt?`;
                          setInvokedAction("Cancel");
                          new_doc_status = "Cancelled";
                          break;
                        default:
                          action_text = "";
                          new_doc_status = "";
                      }
                      setObjectToAction({
                        object_id: receiptData._id,
                        action_text: action_text,
                        new_doc_status,
                      });
                      setConfirmDialogAction("receiptAction");
                      setIsConfirmDialogOpen(true);
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                >
                  {nextAction}
                </CustomButton>
              </>
            )}
            {documentEnabled === "Y" && (
              <CustomLoadingButton startIcon={<FaSave />} onClick={saveRecord}>
                {savingTitle}
              </CustomLoadingButton>
            )}
            {passedDocId !== "new" && (
              <CustomButton
                startIcon={<DescriptionIcon />}
                width={"max-content"}
                onClick={async () => {
                  try {
                    setIsProcessing(true);
                    // Log the event here

                    const trace_res = await newRequest.post(
                      "/trace/add", {
                      trc_org_code: currentUser.usr_org_code,
                      trc_action_on: "Document",
                      trc_doc_type: "receipt",
                      trc_doc_no: receiptData.hd_no,
                      trc_aent_code: "",
                      trc_ent_code: "",
                      trc_action: "previewed receipt",
                      trc_remarks: `previewed receipt ${receiptData.hd_no}`,
                      trc_tags: ["previewed receipt", receiptData.hd_no],
                      trc_doc_docid: receiptData._id,
                      userCode: currentUser.usr_id,
                    }
                    );
                    // navigate(`/pmtslip/${receiptData._id}`);

                    const res = await newRequest.post(
                      "/gendocs/rcp",
                      {
                        receipt_docid: receiptData._id,
                        org_code: receiptData.hd_org_code,
                      }, {
                      responseType: 'json' // Ensure the response is treated as a Blob
                    }
                    );

                    // set the document ID of the saved binary data
                    // this will be used in the backend when we need to send attachments

                    setBinaryDocid(res.data.binary_docid);
                    console.log(`Binary docid ${res.data.binary_docid}`)

                    const response = await newRequest.get(`/viewdocs/view/${res.data.binary_docid}`, {
                      responseType: 'arraybuffer', // Ensure response is treated as binary data
                    });

                    // Create a blob from the binary data received
                    const blob = new Blob([response.data], { type: response.headers['content-type'] });
                    const url = URL.createObjectURL(blob);

                    // Create a blob URL from the response
                    // const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
                    // Open the PDF in a new tab
                    // const newTab = window.open();
                    // newTab.location.href = url;
                    setPdfUrl(url);

                    // Open the PDF in a new tab
                    // const newTab = window.open();
                    // newTab.location.href = url;
                    // show pdf previewer
                    setPdfPreviewDialogOpen(true);
                  } catch (error) {
                    console.log(error);
                  } finally {
                    setIsProcessing(false);
                  }
                }}
              >
                {`Preview Receipt`}
              </CustomButton>
            )}
            <CustomButton startIcon={<IoClose />} onClick={goBack}>
              Close
            </CustomButton>
          </div>
        </div>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "200px",
              width: "100%",
              height: "100%",
            }}
          >
            <ScaleLoader
              color="#F44336"
              height={50}
              radius={8}
              width={4}
            />
          </div>
        ) : (
          <div className="content-summary-wrapper">
            <div className="content-wrapper">
              <div className="tabs-container">
                <div className="tab-header">
                  {topMenus?.map((tab, index) => (
                    <div
                      key={index}
                      className={`${activeTab === index ? 'tab active' : 'tab'}`}
                      onClick={() => handleTabClick(index)}
                    >
                      <span className={`${activeTab === index ? 'tab-icon active' : 'tab-icon'}`}
                      >
                        {tab.menu_icon}
                      </span>
                      <span className={`${activeTab === index ? 'tab-title active' : 'tab-title'}`}>{tab.menu_title}</span>
                      {tab.menu_count > 0 && <Badge badgeContent={tab.menu_count} showZero sx={{ '& .MuiBadge-badge': { backgroundColor: activeTab === index ? '#9c27b0' : '#c3c3c3', color: 'white' } }}
                        className="tab-badge" />}
                    </div>
                  ))}
                </div>
              </div>
              <div className="content-details">
                {/* Summary Information */}
                {activeTab === 0 &&
                  <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "flex-start", justifyItems: "flex-start" }}>
                    <div className="content-details-row">
                      <h1>RECEIPT</h1>
                      <h1 className={`status ${hdStatus}`}>
                        {hdStatus}
                      </h1>
                    </div>
                    <div className="content-details-row">
                      <div className="content-details-left">
                        <div className="input-item-with-label">
                          <label htmlFor="hd_ent_code">CLIENT</label>
                          <AsyncSelect
                            id="hd_ent_code"
                            name="hd_ent_code"
                            styles={customSelectStyles}
                            width="500px"
                            value={hdEntCode}
                            onChange={(selectedOption) => {
                              try {
                                setHdEntCode(selectedOption);
                              } catch (error) {
                                console.log(error);
                              }
                            }}
                            loadOptions={fetchClientsBySearch} // Define this function to load options asynchronously
                            isSearchable={true}
                            isDisabled={documentEnabled === "N"}
                            placeholder="Clients"
                            noOptionsMessage={() =>
                              customNoOptionsMessage(
                                "Type Minimum 3 Letter to Search Clients"
                              )
                            }
                          // components={{
                          //   Option: EntitySelectLayout,
                          // }}
                          />
                        </div>
                        <div className="input-item-with-label">
                          <label htmlFor="hd_ac_no">BANK AC</label>
                          <Select
                            id="hd_ac_no"
                            name="hd_ac_no"
                            styles={customSelectStyles}
                            width="500px"
                            value={hdAcNo}
                            onChange={(selectedOption) => {
                              try {
                                setHdAcNo(selectedOption);
                              } catch (error) {
                                console.log(error);
                              }
                            }}
                            options={bankAccounts}
                            isSearchable={true}
                            isDisabled={documentEnabled === "N"}
                            placeholder="BANK AC"
                          />
                        </div>
                        <div style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "start",
                          justifyContent: "space-between",
                          width: "100%",
                        }}>
                          <div className="input-item-with-label">
                            <label htmlFor="hd_biz_unit">BIZ UNIT</label>
                            <Select
                              id="hd_biz_unit"
                              name="hd_biz_unit"
                              styles={customSelectStyles}
                              width="170px"
                              value={hdBizUnit}
                              onChange={(selectedOption) => {
                                try {
                                  setHdBizUnit(selectedOption);
                                } catch (error) {
                                  console.log(error);
                                }
                              }}
                              options={branches}
                              isSearchable={true}
                              isDisabled={documentEnabled === "N"}
                              placeholder="BIZ UNIT"
                            />
                          </div>
                          <div className="input-item-with-label">
                            <label htmlFor="hd_cur_code">CURRENCY</label>
                            <Select
                              id="hd_cur_code"
                              name="hd_cur_code"
                              styles={customSelectStyles}
                              width="170px"
                              value={hdCurCode}
                              onChange={async (selectedOption) => {
                                try {
                                  //get currency rate
                                  if (
                                    selectedOption.value !==
                                    currentUser.currency_code_xx
                                  ) {
                                    setHdCurCode(selectedOption);
                                    const curRate = {
                                      rate_org_code: currentUser.usr_org_code,
                                      rate_cur_code_fm: selectedOption.value,
                                      rate_cur_code_to:
                                        currentUser.currency_code_xx,
                                      rate_date: hdGlDate,
                                    };

                                    // get rate
                                    const end_res = await newRequest.post(
                                      "/glCurrencyRate/find",
                                      curRate,
                                      config
                                    );

                                    if (end_res.data) {
                                      setHdCurRate(end_res.data.rate);
                                    } else {
                                      setHdCurCode({
                                        value: currentUser.currency_code_xx,
                                        label: currentUser.currency_name_xx,
                                      });
                                      setHdCurRate(1);
                                      setNotifyDialogTitle("Note!");
                                      setNotifyDialogText(
                                        `Conversion Rate from <strong>${selectedOption.value
                                        } to ${currentUser.currency_code_xx
                                        } for date ${moment(hdGlDate).format(
                                          "DD-MMM-YYYY"
                                        )}</strong> NOT found. Currency will reset to <strong>${currentUser.currency_code_xx
                                        }</strong>`
                                      );
                                      setIsNotifyDialogOpen(true);
                                    }
                                  } else {
                                    setHdCurCode({
                                      value: currentUser.currency_code_xx,
                                      label: currentUser.currency_name_xx,
                                    });
                                    setHdCurRate(1);
                                    const lcAmount = Number(parseFloat(formatStringToNumberV2(hdFcAmount)).toFixed(4));
                                    setHdLcAmount(lcAmount);
                                  }
                                } catch (error) {
                                  console.log(error);
                                }
                              }}
                              options={currencies}
                              isSearchable={true}
                              isDisabled={documentEnabled === "N"}
                              placeholder="CURRENCY"
                            />
                          </div>
                        </div>
                        <div style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "start",
                          justifyContent: "space-between",
                          width: "100%",
                        }}>
                          <div className="input-item-with-label">
                            <label
                              htmlFor="hd_mode"
                              className="form-dropdown-label"
                            >
                              MODE
                            </label>
                            <Select
                              id="hd_mode"
                              name="hd_mode"
                              styles={customSelectStyles}
                              width="170px"
                              value={hdMode}
                              onChange={(selectedOption) => {
                                try {
                                  setHdMode(selectedOption);
                                } catch (error) {
                                  console.log(error);
                                }
                              }}
                              options={receiptMode}
                              isSearchable={true}
                              placeholder="MODE"
                              isDisabled={documentEnabled === "N"}
                            />
                          </div>
                          <div className="input-item-with-label">
                            <label
                              htmlFor="hd_gl_date"
                              className="form-dropdown-label"
                            >
                              GL DATE
                            </label>
                            <input
                              id="hd_gl_date"
                              name="hd_gl_date"
                              type="date"
                              placeholder="FROM"
                              value={hdGlDate}
                              className="form-input-item"
                              style={{ width: "170px" }}
                              onChange={handleGlDateChange}
                              disabled={documentEnabled === "N"}
                            />
                          </div>
                        </div>
                        <div style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "start",
                          justifyContent: "space-between",
                          width: "100%",
                        }}>
                          <div className="input-item-with-label">
                            <label
                              htmlFor="hd_paying_for"
                              className="form-dropdown-label"
                            >
                              PURPOSE
                            </label>
                            <Select
                              id="hd_paying_for"
                              name="hd_paying_for"
                              styles={customSelectStyles}
                              width="170px"
                              value={hdPayingFor}
                              onChange={(selectedOption) => {
                                try {
                                  setHdPayingFor(selectedOption);
                                } catch (error) {
                                  console.log(error);
                                }
                              }}
                              options={payingFor}
                              isSearchable={true}
                              placeholder="PAYING FOR"
                              isDisabled={documentEnabled === "N"}
                            />
                          </div>
                          <div className="input-item-with-label">
                            <label
                              htmlFor="hd_fc_amount"
                              className="form-dropdown-label"
                            >
                              AMOUNT
                            </label>
                            <input
                              id="hd_fc_amount"
                              name="hd_fc_amount"
                              type="text"
                              placeholder="0.00"
                              value={hdFcAmount}
                              className="form-input-item"
                              style={{ width: "170px", textAlign: "right" }}
                              onChange={(e) => setHdFcAmount(e.target.value)}
                              disabled={documentEnabled === "N"}
                              onBlur={handleFcAmountBlur}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="content-details-right">
                        <div className="input-item-with-label">
                          <label htmlFor="hd_no" className="form-dropdown-label">
                            RECEIPT NO
                          </label>
                          <input
                            id="hd_no"
                            name="hd_no"
                            type="text"
                            placeholder="RECEIPT NO"
                            value={hdNo}
                            disabled={!isDocumentNumberSetup}
                            className="form-input-item"
                            style={{ width: "200px" }}
                            onChange={(e) => setHdNo(e.target.value)}
                          />
                        </div>
                        <div className="input-item-with-label">
                          <label
                            htmlFor="hd_status"
                            className="form-dropdown-label"
                          >
                            STATUS
                          </label>
                          <input
                            id="hd_status"
                            name="hd_status"
                            type="text"
                            placeholder="STATUS"
                            disabled
                            value={hdStatus}
                            className="form-input-item"
                            style={{ width: "200px" }}
                            onChange={(e) => setHdStatus(e.target.value)}
                          />
                        </div>
                        <div className="input-item-with-label">
                          <label
                            htmlFor="hd_chq_no"
                            className="form-dropdown-label"
                          >
                            CHQ NO
                          </label>
                          <input
                            id="hd_chq_no"
                            name="hd_chq_no"
                            type="text"
                            placeholder="CHQ NO"
                            disabled={documentEnabled === "N"}
                            value={hdChqNo}
                            className="form-input-item"
                            style={{ width: "200px" }}
                            onChange={(e) => setHdChqNo(e.target.value)}
                          />
                        </div>
                        <div className="input-item-with-label">
                          <label
                            htmlFor="hd_chq_date"
                            className="form-dropdown-label"
                          >
                            CHQ DATE
                          </label>
                          <input
                            id="hd_chq_date"
                            name="hd_chq_date"
                            type="date"
                            placeholder="CHQ DATE"
                            value={hdChqDate}
                            className="form-input-item"
                            style={{ width: "200px" }}
                            onChange={(e) => {
                              try {
                                const formattedChqDate = moment(
                                  e.target.value
                                ).format("YYYY-MM-DD");
                                setHdChqDate(formattedChqDate);
                              } catch (error) {
                                console.log(error);
                              }
                            }}
                            disabled={documentEnabled === "N"}
                          />
                        </div>
                        <div className="input-item-with-label">
                          <label
                            htmlFor="hd_ref_no"
                            className="form-dropdown-label"
                          >
                            REF NO
                          </label>
                          <input
                            id="hd_ref_no"
                            name="hd_ref_no"
                            type="text"
                            placeholder="REF NO"
                            value={hdRefNo}
                            className="form-input-item"
                            style={{ width: "200px" }}
                            onChange={(e) => setHdRefNo(e.target.value)}
                            disabled={documentEnabled === "N"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="content-details-row" >
                      <div className="input-item-with-label"
                        style={{
                          display: "flex",
                          width: "100%",
                        }}>
                        <label
                          htmlFor="hd_narration"
                          className="form-dropdown-label"
                          style={{ width: "175px", alignSelf: "flex-start" }}
                        >
                          NARRATION
                        </label>
                        <textarea
                          id="hd_narration"
                          name="hd_narration"
                          type="text"
                          placeholder="NARRATION"
                          value={hdNarration}
                          row={5}
                          className="form-input-item"
                          style={{ width: "100%" }}
                          onChange={(e) => setHdNarration(e.target.value)}
                          disabled={documentEnabled === "N"}
                        />
                      </div>
                    </div>
                    <hr style={{ display: "flex", width: "100%" }} />
                    <h1>LINES</h1>
                    <div className="lines-wrapper">
                      {passedDocId === "new" && (
                        <motion.div className="doc-not-saved"
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1, transition: { duration: 1 } }}
                          exit={{ opacity: 0, transition: { duration: 1 } }}>
                          NEW RECEIPT. AFTER SAVING, YOU'LL BE ABLE TO PERFORM MORE ACTIONS
                        </motion.div>
                      )}
                      {passedDocId !== "new" && (
                        <>
                          {!["Cancelled"].includes(hdStatus) &&
                            <div className="inline-buttons-wrapper">
                              <Select
                                id="rn"
                                name="rn"
                                styles={customSelectStyles}
                                width="350px"
                                value={selectedRiskNote}
                                onChange={(selectedOption) => {
                                  try {
                                    // console.log(`selectedOption  ${JSON.stringify(selectedOption, null, 2)}`);
                                    setControlPremiumFc(selectedOption.os_to_remit_fc)
                                    setControlPremiumLc(selectedOption.os_to_remit_lc)
                                    setControlCommissionFc(selectedOption.commission_os_fc)
                                    setControlCommissionLc(selectedOption.commission_os_lc)

                                    setMatchedFcAmount(selectedOption.os_to_remit_fc)
                                    setMatchedLcAmount(selectedOption.os_to_remit_lc)
                                    setMatchedCommFc(selectedOption.commission_os_fc)
                                    setMatchedCommLc(selectedOption.commission_os_lc)
                                    setSelectedRiskNote(selectedOption);
                                  } catch (error) {
                                    console.log(error);
                                  }
                                }}
                                options={entityRiskNotes}
                                isSearchable={true}
                                placeholder="RISK NOTES"
                              />
                              <div className="input-item-with-label">
                                <label
                                  htmlFor="matched_fc_amount"
                                  className="form-dropdown-label"
                                  style={{ width: "80px" }}
                                >
                                  AMOUNT
                                </label>
                                <input
                                  id="matched_fc_amount"
                                  name="matched_fc_amount"
                                  type="number"
                                  placeholder="0.00"
                                  value={matchedFcAmount}
                                  className="form-input-item"
                                  style={{ width: "120px", textAlign: "right" }}
                                  onChange={(e) => setMatchedFcAmount(e.target.value)}
                                  onBlur={handleMatchedFcAmountBlur}
                                />
                              </div>
                              {hdPayingFor?.value === "Premium" && <div className="input-item-with-label">
                                <label
                                  htmlFor="matched_comm_fc"
                                  className="form-dropdown-label"
                                  style={{ width: "120px" }}
                                >
                                  COMMISSION
                                </label>
                                <input
                                  id="matched_comm_fc"
                                  name="matched_comm_fc"
                                  type="number"
                                  placeholder="0.00"
                                  value={matchedCommFc}
                                  className="form-input-item"
                                  style={{ width: "100px", textAlign: "right" }}
                                  onChange={(e) => setMatchedCommFc(e.target.value)}
                                  onBlur={handleMatchedCommFcBlur}
                                />
                              </div>}
                              {hdPayingFor?.value === "Premium" &&
                                <ToggleSwitch
                                  id="netoffCommission"
                                  labelWidth="max-content"
                                  value={netoffCommission}
                                  onChange={setNetoffCommission}
                                  label={"NETOFF COMMISSION"}
                                />}
                              <CustomButton
                                startIcon={<AddIcon />}
                                onClick={handleAddRiskNote}
                              >
                                Add
                              </CustomButton>
                            </div>}

                          <motion.div
                            className="table-content"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1, transition: { duration: 1 } }}
                            exit={{ opacity: 0, transition: { duration: 1 } }}>
                            <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none' }} className="table-container">
                              <Table sx={{ width: "max-content", border: 'none' }}>
                                <TableHead className="tablehead">
                                  <TableRow className="tablerow">
                                    <TableCell className="table-cell center" style={{ maxWidth: "50px", }}>
                                      #
                                    </TableCell>
                                    <TableCell className="table-cell left" style={{ maxWidth: "200px", }}>
                                      <span>TYPE</span>
                                    </TableCell>
                                    <TableCell className="table-cell left" style={{ width: "auto", }}>
                                      <span>DOC NO</span>
                                    </TableCell>
                                    <TableCell className="table-cell right" style={{ maxWidth: "250px", }}>
                                      <span>DOC AMOUNT</span>
                                    </TableCell>
                                    <TableCell className="table-cell right" style={{ maxWidth: "200px", }}>
                                      <span> MATCHED</span>
                                    </TableCell>
                                    <TableCell className="table-cell right" style={{ maxWidth: "200px", }}>
                                      <span>COMMISSION</span>
                                    </TableCell>
                                    <TableCell className="table-cell center" style={{ maxWidth: "100px", }}>
                                      <span>ACTION</span>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody className="tablebody">
                                  {slicedReceiptLines?.map((row, index) => (
                                    <TableRow
                                      key={row._id}
                                      className={`${selectedReceiptLine?._id === row._id ? "tablerow current" : "tablerow"}`}
                                      onClick={() => {
                                        try {
                                          setSelectedReceiptLine(row);
                                        } catch (error) {
                                          console.log(error);
                                        }
                                      }}
                                    >
                                      <TableCell className="table-cell center" style={{ display: "flex", maxWidth: "50px", fontWeight: "bold" }}>
                                        <span>{currentPage * rowsPerPage + index + 1}</span>
                                      </TableCell>
                                      <TableCell className="table-cell left" style={{ maxWidth: "200px", }}>
                                        <span>{row.ln_doc_type}</span>
                                      </TableCell>
                                      <TableCell className="table-cell left" style={{ width: "auto", }}>
                                        <span>{row.ln_doc_no}</span>
                                        <span>{`Insured ${row.insured_name_xx}`}</span>
                                      </TableCell>
                                      <TableCell className="table-cell right" style={{ maxWidth: "250px", }}>
                                        <span>{numeral(row.total_fc_premium).format("0,0.00")}</span>
                                      </TableCell>
                                      <TableCell className="table-cell right" style={{ maxWidth: "200px", }}>
                                        <span>{numeral(row.ln_fc_amount).format("0,0.00")}</span>
                                      </TableCell>
                                      <TableCell className="table-cell right" style={{ maxWidth: "200px", }}>
                                        <span>{numeral(row.ln_comm_fc).format("0,0.00")}</span>
                                      </TableCell>
                                      <TableCell className="table-cell center" style={{ maxWidth: "100px", }}>
                                        <Tooltip
                                          title={`Unmatch ${row.ln_doc_no} from this receipt`}
                                          placement="right"
                                          style={{
                                            backgroundColor: "blue",
                                            color: "white",
                                          }}
                                        >
                                          <DeleteIcon
                                            size={50}
                                            style={{
                                              borderRadius: "5px",
                                              padding: "3px",
                                              alignSelf: "center",
                                              cursor: "pointer",
                                              color: "rgba(244, 67, 54)",
                                              backgroundColor:
                                                "rgba(244, 67, 54, 0.5)",
                                            }}
                                            onClick={() => {
                                              try {
                                                let action_text = `Unmatch ${row.ln_doc_no} from this receipt?`;
                                                setObjectToAction({
                                                  object_id: row._id,
                                                  action_text: action_text,
                                                  new_doc_status: "unmatch",
                                                });
                                                setConfirmDialogAction(
                                                  "unmatchDocument"
                                                );
                                                setIsConfirmDialogOpen(true);
                                              } catch (error) {
                                                console.log(error);
                                              }
                                            }}
                                          />
                                        </Tooltip>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                              <TablePagination
                                rowsPerPageOptions={[5]}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleRowsPerPage}
                                component="div"
                                count={receiptLines?.length}
                                rows={receiptLines}
                                page={currentPage}
                                onPageChange={handlePageChange}
                              />
                            </TableContainer>
                          </motion.div>
                        </>
                      )}
                    </div>
                  </div>
                }
                {activeTab === 1 && <>
                  <motion.div
                    className="tabs-view-wrapper"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 1 } }}
                    exit={{ opacity: 0, transition: { duration: 1 } }}>
                    <div className="attachment">
                      <FileUploadComponent documentNo={hdNo} documentId={receiptDocId} documentType="receipt" fetchDocumentAttachments={fetchDocumentAttachments} uploadFolder="receipt" />
                      <div className="attachment-files">
                        <AllFiles filesList={attachmentsData} bgColor={"#fff"} />
                      </div>
                    </div>
                  </motion.div>
                </>}
                {activeTab === 2 &&

                  <motion.div
                    className="content-details-row"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 1 } }}
                    exit={{ opacity: 0, transition: { duration: 1 } }}
                  >
                    Matching
                  </motion.div>
                }
                {activeTab === 3 &&
                  <motion.div
                    className="content-details-row"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 1 } }}
                    exit={{ opacity: 0, transition: { duration: 1 } }}
                  >
                    <AllDocsNote
                      docId={receiptData._id}
                      docType="Receipt"
                      noteType={"Normal"}
                    />
                  </motion.div>
                }
              </div>
            </div>
            <div className="summary-wrapper">
              <div className="summary-content">
                {hdCurCode?.value !== currentUser.currency_code_xx && (
                  <div
                    className="input-item-with-label"
                    style={{ alignSelf: "flex-end" }}
                  >
                    <label
                      htmlFor="hd_cur_rate"
                      className="form-dropdown-label"
                    >
                      RATE
                    </label>
                    <input
                      id="hd_cur_rate"
                      name="hd_cur_rate"
                      type="number"
                      placeholder="RATE"
                      value={hdCurRate}
                      className="form-input-item"
                      style={{
                        width: "100px",
                        margin: "5px 0px",
                        textAlign: "right",
                      }}
                      onChange={(e) => setHdCurRate(e.target.value)}
                      disabled={documentEnabled === "N"}
                    />
                  </div>
                )}

                <hr />
                <motion.div
                  className="summary-view"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { duration: 1 } }}
                  exit={{ opacity: 0, transition: { duration: 1 } }}
                >
                  <div className="title-row">
                    <span>SUMMARY</span>
                    {currentUser?.currency_code_xx !== hdCurCode?.value && (
                      <CustomButtonSmall
                        style={{ display: "flex" }}
                        onClick={() => {
                          try {
                            if (figuresToShow === "org") {
                              setFiguresToShow("base");
                            } else {
                              setFiguresToShow("org");
                            }
                          } catch (error) {
                            console.log(error);
                          }
                        }}
                      >
                        {figuresToShow === "org"
                          ? `view ${currentUser.currency_code_xx} amounts`
                          : `view ${hdCurCode.value} amounts`}
                      </CustomButtonSmall>
                    )}

                    <span style={{ marginLeft: "5px" }}>
                      {figuresToShow === "org"
                        ? `${hdCurCode?.value}`
                        : `${currentUser.currency_code_xx}`}
                    </span>
                  </div>
                  <hr />
                  <div className="content-row">
                    <h1 className="receipt-amount-title">
                      RECEIPT AMOUNT
                    </h1>
                    <span span className="receipt-amount">
                      {numeral(
                        figuresToShow === "org" ? hdFcAmount : hdLcAmount
                      ).format("0,0.00")}
                    </span>
                  </div>
                  <div className="content-row">
                    {figuresToShow === "org" && hdFcAmount && (
                      <span className="amount-to-words">{`${hdCurCode?.value
                        } ${numberToWordsWithDecimal(hdFcAmount)}`}</span>
                    )}
                    {figuresToShow !== "org" && hdLcAmount && (
                      <span className="amount-to-words">{`${currentUser.currency_code_xx
                        } ${numberToWordsWithDecimal(hdLcAmount)}`}</span>
                    )}
                  </div>
                  <div className="content-row">
                    <h1 className="allocated-title">ALLOCATED</h1>
                    <h1 className="allocated-amount">
                      {numeral(
                        figuresToShow === "org"
                          ? allocatedFcAmountXx
                          : allocatedLcAmountXx
                      ).format("0,0.00")}
                    </h1>
                  </div>
                  <div className="content-row">
                    <h1 className="osto-allocate-title">
                      OS TO ALLOCATE
                    </h1>
                    <h1 className="osto-allocate-amount">
                      {numeral(
                        figuresToShow === "org"
                          ? formatStringToNumberV2(hdFcAmount) - allocatedFcAmountXx
                          : hdLcAmount - allocatedLcAmountXx
                      ).format("0,0.00")}
                    </h1>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        )}
      </div>
      {validationMsgDialog && (
        <motion.div
          className="overlay-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="overlay-wrapper">
            <div className="overlay-navbar">
              <span>MESSAGES</span>
              <span onClick={() => setValidationMsgDialog(false)}>
                <IoClose
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    borderRadius: "50%",
                  }}
                />
              </span>
            </div>
            <div className="overlay-content">
              <ValidationMessages messageData={validationMessagesResult} />
            </div>
          </div>
        </motion.div>
      )}

      {/* Dialog to notify user about changes being saved */}
      <Dialog open={isNotifyDialogOpen} onClose={handleCloseNotifyDialog}>
        <DialogTitle>{notifyDialogTitle}</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(notifyDialogText) }} />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseNotifyDialog}>Close</CustomButton>
        </DialogActions>
      </Dialog>
      {/* Confirm Dialog */}
      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        dialogTitle="Confirmation"
        dialogContent={<p>{objectToAction.action_text}</p>}
      />
      {isProcessing && (
        <motion.div
          className="processing-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="processing-wrapper">
            <GridLoader
              color="#F44336"
              style={{
                height: "50px",
                width: "50px",
              }}
            />
          </div>
        </motion.div>
      )}
      {/* email component */}
      {emailDialogOpen && (
        <motion.div
          className="overlay-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="overlay-wrapper">
            <div className="overlay-navbar">
              <span>EMAIL</span>
              <span onClick={() => setEmailDialogOpen(false)}>
                <IoClose
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    borderRadius: "50%",
                  }}
                />
              </span>
            </div>
            <div className="overlay-content">
              <EmailComponent setEmailDialogOpen={setEmailDialogOpen} emailData={emailData} />
            </div>
          </div>
        </motion.div>
      )}
      {pdfPreviewDialogOpen && (
        <motion.div
          className="overlay-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="overlay-wrapper pdf">
            <div className="overlay-navbar">
              <div className="left">
                <span>PREVIEW</span>
              </div>
              <div className="right">
                <CustomLoadingButton startIcon={<EmailIcon />} onClick={() => {
                  try {
                    const urlString = pdfUrl.toString().replace("blob:", "");
                    setEmailData({
                      to: receiptData?.client_email_xx,
                      cc: `${currentUser?.usr_email}`,
                      bcc: '',
                      subject: `Receipt No ${hdNo}`,
                      show_attach: "N",
                      attached_urls: [{ id: 1, url: urlString, name: `Receipt No ${hdNo}`, binary_docid: binaryDocid }],
                      binary_docs: [{ doc_id: binaryDocid, doc_name: `Receipt No ${hdNo}`, doc_type: "pdf", doc_url: "", doc_size_kb: 0 }],
                      doc_no: hdNo,
                      doc_type: "Receipt",
                      doc_id: receiptDocId,
                      entity_codes: [hdEntCode?.value],
                      search_string: hdEntCode?.label + "," + hdNo
                    });
                    setPdfPreviewDialogOpen(false);
                    setEmailDialogOpen(true);
                  } catch (error) {
                    console.log(error)
                  }
                }}>
                  Email to Client
                </CustomLoadingButton>
                <CustomLoadingButton startIcon={<GetAppIcon />} onClick={() => {
                  try {
                    const link = document.createElement('a');
                    link.href = pdfUrl;
                    link.download = `Receipt ${hdNo}.pdf`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  } catch (error) {
                    console.log(error)
                  }
                }}>
                  Download
                </CustomLoadingButton>
                <CircularButton onClick={() => setPdfPreviewDialogOpen(false)} icon={<IoClose />} width={"35px"} height={"35px"} />
              </div>
            </div>
            <div className="overlay-content-pdf" >
              <PdfPreview pdfUrl={pdfUrl} />
            </div>
            <div className="overlay-action">
            </div>
          </div>
        </motion.div>
      )}
      {isConfettiActive && <Confetti />}
    </motion.div>
  );
};

const UploadDialog = ({ open, handleClose, uploadProgress }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Progress</DialogTitle>
      <DialogContent>
        <LinearProgress variant="determinate" value={uploadProgress} />
        {uploadProgress && <p>{uploadProgress}% Complete</p>}
      </DialogContent>
    </Dialog>
  );
};

export default ArReceipt;
