import { useSelector } from "react-redux";
import "./AllEntityCentral.scss"
import { useLocation, useNavigate } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { motion } from "framer-motion";
import moment from "moment";
import { useEffect, useState } from "react";
import newRequest from "../../../utils/newRequest";
import Correspondence from "../../../components/correspondence/Correspondence";
import { IoClose } from "react-icons/io5";
import { CustomLoadingButton } from "../../../components/ui/CustomButton";
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import TagIcon from '@mui/icons-material/Tag';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import BusinessIcon from '@mui/icons-material/Business';

const AllEntityCentral = () => {
    const { currentUser } = useSelector((state) => state.user);
    const [isLoading, setIsLoading] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
    const [notifyDialogTitle, setNotifyDialogTitle] = useState("Confirm");
    const [notifyDialogText, setNotifyDialogText] = useState("Text");
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [objectToAction, setObjectToAction] = useState({
        object_id: "",
        action_text: "",
        new_doc_status: "",
    });
    //get entity data
    const [entityData, setEntityData] = useState({});
    const location = useLocation();
    const parts = location.pathname.split("/");
    const [entityId, setEntityId] = useState(parts[parts.length - 1]);
    const navigate = useNavigate();

    const fetchEntity = async () => {
        try {
            const res = await newRequest.post(
                "/allEntity/findById",
                {
                    ent_org_code: currentUser.usr_org_code,
                    entity_docid: entityId,
                }
            );
            console.log(`setEntityData  ${JSON.stringify(res.data, null, 2)}`);
            setEntityData(res.data);
        } catch (err) {
            console.log(err);
        }
    };

    const goBack = () => {
        navigate(-1);
    };


    useEffect(() => {
        fetchEntity()
    }, []);

    return (
        <motion.div
            className="all-entity-central"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 1 } }}
            exit={{ opacity: 0, transition: { duration: 1 } }}
        >
            {isLoading ? (
                <div className="no-content"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <ScaleLoader
                        color="#F44336"
                        height={50}
                        radius={8}
                        width={4}
                    />
                </div>
            ) : (
                <div className="all-entity-central-wrapper">
                    {/* <div className="left-wrapper">
                        <div className="left-navbar">
                            <CustomLoadingButton startIcon={<IoClose />} onClick={goBack}>
                                Close
                            </CustomLoadingButton>
                        </div>
                        <hr />
                        <div className="left-content">
                        </div>
                    </div> */}
                    <div className="center-wrapper">
                        <div className="center-details">
                            {/* <div className="center-navbar">
                                <div className="center-navbar-left">center-navbar-left</div>
                                <div className="center-navbar-right">center-navbar-right</div>
                            </div> */}
                            <Correspondence
                                correspondenceParams={{ fetch_type: "entity", doc_id: entityId, doc_no: entityData?.ent_code, doc_type: "entity", entity_codes: [entityData?.ent_code] }}
                                passedEmailData={{
                                    to: entityData?.ent_email,
                                    cc: '',
                                    bcc: '',
                                    subject: ``,
                                    show_attach: "Y",
                                    attached_urls: [],
                                    binary_docs: [],
                                    doc_no: entityData?.ent_code,
                                    doc_type: "entity",
                                    doc_id: entityId,
                                    entity_codes: [entityData?.ent_code],
                                    search_string: entityData?.ent_name,
                                    carrierName: '',
                                    productName: '',
                                    clientName: entityData?.ent_name,
                                    riskNoteNo: '',
                                    daysToRenewal: 0,
                                    expiryDate: '',
                                    insuredName: entityData?.ent_name,
                                    outstandingPremium: 0,
                                    policyCurrency: currentUser.currenxy_xx,
                                    policyNumber: '',
                                    policyPremium: 0,
                                    policyPremiumTotal: 0,
                                    policySumInsured: 0,
                                    policyFromDate: '',
                                    policyToDate: '',
                                    tpt_category: [] //empty array picks all templates
                                }} />
                        </div>
                    </div>
                    <div className="right-wrapper">
                        <div className="right-navbar">
                            <CustomLoadingButton startIcon={<IoClose />} onClick={goBack}>
                                Close
                            </CustomLoadingButton>
                        </div>
                        <hr />
                        <div className="right-content">
                            <div className="single-card"
                                key={entityData._id}

                            >
                                <div className="single-card-top">

                                    {entityData?.ent_photo_url && <img
                                        src={entityData?.ent_photo_url}
                                        alt=""
                                        className="img"
                                    />}
                                    {!entityData?.ent_photo_url && <BrokenImageOutlinedIcon style={{ width: "60px", height: "60px" }} />}
                                    <span className="user-full-name"
                                        onClick={() => {
                                            try {
                                                navigate(`/entity/${entityData._id}`, {
                                                    state: { shouldFetchClients: false },
                                                });
                                            } catch (error) {
                                                console.log(error)
                                            }
                                        }}
                                    >{entityData?.ent_name}</span>
                                    <span className="user-type">{`${entityData.aent_name_xx}`}</span>
                                </div>
                                <div className="single-card-center">
                                    <div className="single-card-center-row">
                                        <span className="user-title">Type</span>
                                        <span className="user-title">Status</span>
                                    </div>
                                    <div className="single-card-center-row">
                                        <span className="user-title-value">{entityData.ent_type}</span>
                                        <span className="user-title-value">{entityData.ent_status}</span>
                                        {/* <span className={`status ${row.ent_status}`}>{row.ent_status}</span> */}
                                    </div>
                                    <div className="single-card-center-col">
                                        <div className="email-phone-row">
                                            <TagIcon style={{ color: '#f44336' }} /> <span>{entityData.ent_code}</span>
                                        </div>
                                        <div className="email-phone-row">
                                            <LocalPhoneIcon style={{ color: '#9c27b0' }} /> <span>{entityData.ent_phone_no}</span>
                                        </div>
                                        <div className="email-phone-row">
                                            <AlternateEmailIcon style={{ color: '#FAE27C' }} /> <span>{entityData.ent_email}</span>
                                        </div>
                                        <div className="email-phone-row">
                                            <LocationOnIcon style={{ color: '#FAE27C' }} /> <span>{entityData.ent_physical_address}</span>
                                        </div>
                                        <div className="email-phone-row">
                                            <BusinessIcon style={{ color: '#FAE27C' }} /> <span>{entityData.ent_postal_address}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="single-card-bottom">
                                    <div className="bottom-title">
                                        <span>created by...</span>
                                    </div>
                                    <div className="avatar-container">
                                        <div className="left">
                                            {entityData?.updated_by_photo_url_xx ? <img
                                                src={entityData?.updated_by_photo_url_xx}
                                                alt=""
                                                className="avatar"
                                            /> : <BrokenImageOutlinedIcon style={{ width: "30px", height: "30px" }} />}
                                        </div>
                                        <div className="right">
                                            <span className="username">{entityData.updated_by_name_xx}</span>
                                            <span className="time-ago">{entityData.created_xx}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </motion.div>
    );
};


export default AllEntityCentral