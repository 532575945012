import "./LandingPage.scss";
import { CustomButtonGradient } from "../../components/ui/CustomButton";
import { MdCheckCircle } from "react-icons/md";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import newRequest from "../../utils/newRequest";
import Ratings from "../../components/ratings/Ratings";


const LandingPage = () => {
  const navigate = useNavigate();
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [plans, setPlans] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const paymentOptions = [
    { value: 'monthly', label: 'Monthly', desc: "Start growing your intermediary with efficiency" },
    { value: 'quarterly', label: 'Quarterly', desc: "Select quarter payment plan to get 10% off" },
    { value: 'annually', label: 'Annually', desc: "Select annual payment plan and get 20% off" },
  ];

  const fetchPlans = async () => {
    try {
      const res = await newRequest.get(
        "/plans/findAll"
      );
      setPlans(res.data);
      // set selected default plan to monthly
      setSelectedPayment({ value: 'monthly', label: 'Monthly', desc: "Start growing your intermediary" })
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  useEffect(() => {
    fetchPlans();
  }, []);

  return (
    <div className="landing">
      <motion.div
        className="landing-page"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 1 } }}
        exit={{ opacity: 0, transition: { duration: 1 } }}
      >
        {/* navbar  */}
        <div className="navbar">
          <h1
            onClick={() => {
              try {
                navigate("/");
              } catch (error) {
                console.log(error);
              }
            }}
          >
            Qillo
          </h1>
          <CustomButtonGradient
            height={"40px"}
            width={"200px"}
            onClick={() => {
              try {
                navigate("/login");
              } catch (error) {
                console.log(error);
              }
            }}
          >
            Login
          </CustomButtonGradient>
        </div>
        {/* hero text */}
        <motion.div
          className="hero-text"
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
        >
          <h1>
            Secure Growth: Insurance Made Simple{" "}
            <span> Innovate, Serve, Succeed </span>
          </h1>
          <p>
            Our SaaS application offers an innovative suite, from document
            management with QR codes for policy access to a robust renewal
            module. Empowering you to deliver exceptional service and drive
            growth, our platform ensures efficiency and client focus. Join us in
            transforming the insurance experience and maximizing your business
            potential.
          </p>
          <CustomButtonGradient
            height={"45px"}
            width={"max-content"}
            onClick={() => {
              try {
                navigate('/register', { state: null });
              } catch (error) {
                console.log(error);
              }
            }}
          >
            Signup and start Streamlining Today!
          </CustomButtonGradient>
        </motion.div>
        {/* features. skewed background */}
        <div className="features">
          <div className="skewed-background">
            <div className="card-container">
              <div className="skewed-card">
                <div className="logo">
                  <HistoryEduIcon size={60} style={{ color: "#fff" }} />
                </div>
                <div className="title">
                  Integrated Document Management in all processes
                </div>
                <div className="desc">
                  Our innovative platform empowers insurance agents with
                  seamless document management, boosting efficiency and
                  organization.
                </div>
              </div>
              <div className="skewed-card">
                <div className="logo">LOGO</div>
                <div className="title">
                  QR Code on Risk Note for enhanced validation
                </div>
                <div className="desc">
                  Simplify Risk Notes handling for with QR codes embedded in
                  documents, enabling quick access to vital information.
                </div>
              </div>
              <div className="skewed-card">
                <div className="logo">LOGO</div>
                <div className="title">
                  Retain Business efficient Renewal Management
                </div>
                <div className="desc">
                  Optimize client retention by integrating seamless renewal
                  management ensuring no lost opportunities.
                </div>
              </div>
            </div>
          </div>
        </div>
       
        {/* pricing */}
        <div className="pricing">
          <h1>Plans & Pricing</h1>
          <div className="pricing-cards-container">
            {plans?.map((row, index) => (
              <div
                key={row._id}
                className="single-pricing-card"
                onClick={() => {
                  try {
                    setSelectedPlan(row);
                    const planSelected = row;
                    navigate('/register', { state: planSelected });
                  } catch (error) {
                    console.log(error)
                  }
                }}
              >
                {row.sub_flag_show === "Y" && <div className="flag">{row.sub_flag_text}</div>}
                <span className="plan-name">{row.sub_name}</span>
                <div className="plan-price-wrapper">
                  {selectedPayment?.value === "monthly" && <span className="price">{row.fmt_monthly_price_show}</span>}
                  {selectedPayment?.value === "quarterly" && <span className="price">{row.fmt_quater_price_show}</span>}
                  {selectedPayment?.value === "annually" && <span className="price">{row.fmt_annual_price_show}</span>}
                  <div className="price-details">
                    <span className="decimals">.00</span>
                    <span className="freq">{row.sub_monthly_price > 0 ? `${selectedPayment?.value}` : "free"} </span>
                  </div>
                </div>
                {/* <div className="button-wrapper">
                  <CustomButton
                    width={"80%"}
                  >
                    Subscribe
                  </CustomButton>
                </div> */}
                <div className="target-wrapper">
                  <span className="target">{row.sub_target}</span>
                </div>
                <div className="features-wrapper">
                  {row?.sub_features?.map((row, index) => (
                    <span key={row._id} className="single-feature"><MdCheckCircle className="custom-check" />{row.feature_title}</span>
                  ))}
                </div>
                <div className="purpose-wrapper">
                  <span className="purpose">{row.sub_purpose}</span>
                </div>
              </div>
            ))}
          </div>
          <div className="payment-options-wrapper">
            {paymentOptions.map((row) => (
              <div key={row.value}
                className={`${row.value === selectedPayment?.value ? 'payment-option active' : 'payment-option'}`}
                onClick={() => {
                  try {
                    setSelectedPayment(row)
                  } catch (error) {
                    console.log(error)
                  }
                }} >
                <span className="title">{row.label}</span>
                <span className="desc">{row.desc}</span>
              </div>
            ))}
          </div>
        </div>
        {/* ready to transform */}
        <div className="ready-to-transform">
          <h1>
            Are you ready to transform your business?{" "}
            <span> Sign up below </span>
          </h1>
          <p>
            Our SaaS application offers an innovative suite, with embeded
            document attachment feature,Risk Notes with QR codes, comphehensive
            renewal cycle management and more, the application empowers your
            team to deliver exceptional service and drives growth by ensuring
            efficiency in business processes with customer focus. Join us in
            transforming the insurance experience and maximizing your business
            potential.
          </p>

          <CustomButtonGradient
            height={"45px"}
            width={"max-content"}
            onClick={() => {
              try {
                navigate('/register', { state: null });
              } catch (error) {
                console.log(error);
              }
            }}
          >
            Signup and start Streamlining Today!
          </CustomButtonGradient>
        </div>

        {/* footer */}
        <hr />
        <div className="footer">Footer Here</div>
      </motion.div>
    </div>
  );
};

export default LandingPage;
