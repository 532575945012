// DocumentList.jsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import newRequest from '../../utils/newRequest';

const DocumentList = () => {
    const [documents, setDocuments] = useState([]);
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };
    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                const response = await newRequest.get(
                    "/viewdocs/findAll",
                    {},
                    config
                );
                setDocuments(response.data);
            } catch (error) {
                console.error('Error fetching documents:', error);
                // Handle error state or display error message
            }
        };

        fetchDocuments();
    }, []);

    const handleDocumentClick = async (documentId) => {
        try {
            const response = await newRequest.get(`/viewdocs/view/${documentId}`, {
                responseType: 'arraybuffer', // Ensure response is treated as binary data
            });

            // Create a blob from the binary data received
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = URL.createObjectURL(blob);

            // Open the document in a new tab
            window.open(url, '_blank');
        } catch (error) {
            console.error('Error fetching document:', error);
            // Handle error state or display error message
        }
    };

    return (
        <div>
            <h1>Document List</h1>
            <ul>
                {documents.map((document) => (
                    <li key={document._id} onClick={() => handleDocumentClick(document._id)}>
                        <span>{`${document.sub_doc_type} ${document.sub_doc_no}  is a ${document.sub_doc_extension}`}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default DocumentList;
