import "./UwSubClass.scss";
import ConfirmDialog from "../../../components/confirmDialog/confirmDialog";
import { CustomButton } from "../../../components/ui/CustomButton";
import { showToast } from "../../../toastService";
import { getIconComponent } from "../../../utils/getIconComponent";
import newRequest from "../../../utils/newRequest";
import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
//sub class covers
import SaveIcon from "@mui/icons-material/Save";
import SearchIcon from "@mui/icons-material/Search";
//class details
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { FiShield } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { SlArrowDown } from "react-icons/sl";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { GridLoader } from "react-spinners";

const UwSubClass = () => {
  const location = useLocation();
  const parts = location.pathname.split("/");
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const { currentUser } = useSelector((state) => state.user);
  const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
  const [notifyDialogTitle, setNotifyDialogTitle] = useState("Confirm");
  const [notifyDialogText, setNotifyDialogText] = useState("Text");
  const [addSmiDialog, setAddSmiDialog] = useState(false);
  const [classesWithSub, setClassesWithSub] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedSubClass, setSelectedSubClass] = useState({});
  const [subClassCovers, setSubClassCovers] = useState([]);
  const [selectedSubClassCover, setSelectedSubClassCover] = useState({});
  const [subClassCoverSmi, setSubClassCoverSmi] = useState([]);
  const [selectedCoverSmi, setSelectedCoverSmi] = useState({});
  const [objectToAction, setObjectToAction] = useState({
    object_id: "",
    action_text: "",
    new_doc_status: "",
  });
  const [invokedAction, setInvokedAction] = useState("");
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmDialogAction, setConfirmDialogAction] = useState("None");
  const [searchTermSmi, setSearchTermSmi] = useState("");
  const [filteredSmiData, setFilteredSmiData] = useState([]);
  // add class and sub class dialog
  const [addClassSubCode, setAddClassSubCode] = useState("");
  const [addClassSubName, setAddClassSubName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // new smi data
  const [formData, setFormData] = useState({
    smiCode: "",
    smiName: "",
    smiDesc: "",
    smiDefault: "Y",
    smiEnabled: "Y",
    smiRate: 0,
    smiRatePer: 100,
    smiFcl: 0,
  });

  const handleAddSmiInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const {
    smiCode,
    smiName,
    smiDesc,
    smiDefault,
    smiEnabled,
    smiRate,
    smiRatePer,
    smiFcl,
  } = formData;

  const handleAddSmiSubmit = async () => {
    try {
      // Handle form submission here

      if (!smiName || smiName.trim() === "" || smiName.length === 0) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter name!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      setIsProcessing(true);
      const params = {
        smi_org_code: currentUser.usr_org_code,
        smi_mc_code: selectedSubClassCover.cc_mc_code,
        smi_sc_code: selectedSubClassCover.cc_sc_code,
        smi_cc_code: selectedSubClassCover.cc_code,
        smi_code: smiCode,
        smi_name: smiName,
        smi_desc: smiDesc || smiName,
        smi_rate: smiRate,
        smi_rate_per: smiRatePer,
        smi_fcl_value: smiFcl,
        smi_default: smiDefault,
        smi_enabled: smiEnabled,
      };

      const res = await newRequest.post(
        "/uwSubClassCoverSmi/add",
        params,
        config
      );
      setIsProcessing(false);
      setFormData({
        smiCode: "",
        smiName: "",
        smiDesc: "",
        smiDefault: "Y",
        smiEnabled: "Y",
        smiRate: 0,
        smiRatePer: 100,
        smiFcl: 0,
      });
      fetchSubClassCoverSmi(
        selectedSubClassCover.cc_mc_code,
        selectedSubClassCover.cc_sc_code,
        selectedSubClassCover.cc_code
      );
      showToast(`SMI Added!`, "success");
    } catch (error) {
      setIsProcessing(false);
      console.log(error);
    }
  };

  // close notify dialog
  const handleCloseNotifyDialog = () => {
    setIsNotifyDialogOpen(false);
  };

  const handleConfirm = async () => {
    try {
      if (confirmDialogAction === "deleteSmi") {
        try {
          setIsProcessing(true);
          const merge_res = await newRequest.delete(
            `/uwSubClassCoverSmi/del/${objectToAction.object_id}`,
            config
          );

          fetchSubClassCoverSmi(
            selectedSubClassCover.cc_mc_code,
            selectedSubClassCover.cc_sc_code,
            selectedSubClassCover.cc_code
          );
          setIsProcessing(false);
          showToast(`SMI Deleted!`, "success");
        } catch (error) {
          setIsProcessing(false);
          console.log(error);
        }
      }
    } catch (err) {
      console.log(err);
    }
    // Close the dialog
    setConfirmDialogOpen(false);
  };

  const handleCancel = () => {
    setConfirmDialogOpen(false);
  };

  const fetchClassesWithSub = async () => {
    try {
      const res = await newRequest.post(
        "/uwClass/findWithSub",
        {
          mc_org_code: currentUser.usr_org_code,
        },
        config
      );
      setClassesWithSub(res.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchSubClassCovers = async (mcCode, scCode) => {
    try {
      const params = {
        cc_org_code: currentUser.usr_org_code,
        cc_mc_code: mcCode,
        cc_sc_code: scCode,
      };
      const res = await newRequest.post(
        "/uwSubClassCover/findAll",
        params,
        config
      );
      if (res.data) {
        setSubClassCovers(res.data);
        setSelectedSubClassCover(res.data[0]);
        fetchSubClassCoverSmi(
          res.data[0]?.cc_mc_code,
          res.data[0]?.cc_sc_code,
          res.data[0]?.cc_code
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchSubClassCoverSmi = async (mcCode, scCode, ccCode) => {
    try {
      setIsProcessing(true);
      const params = {
        smi_org_code: currentUser.usr_org_code,
        smi_mc_code: mcCode,
        smi_sc_code: scCode,
        smi_cc_code: ccCode,
      };
      const res = await newRequest.post(
        "/uwSubClassCoverSmi/findAll",
        params,
        config
      );
      if (res.data) {
        setSubClassCoverSmi(res.data);
        setSelectedCoverSmi(res.data[0]);
      }
      setIsProcessing(false);
    } catch (err) {
      setIsProcessing(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchClassesWithSub();
  }, [currentUser.usr_org_code]);

  useEffect(() => {
    const filteredResults = classesWithSub.filter((item) => {
      const subClassMatches = item.subClasses.some(
        (subClass) =>
          subClass.sc_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          subClass.sc_code?.toLowerCase().includes(searchTerm.toLowerCase())
      );

      return subClassMatches;
    });

    setFilteredData(filteredResults);
  }, [classesWithSub, searchTerm]);

  const handleInputChange = (e, index) => {
    const { name, value, type } = e.target;
    const updatedData = [...filteredSmiData];
    const currentRow = updatedData[index];

    // Update the current row based on the input's name
    switch (type) {
      case "checkbox":
        currentRow[name] = e.target.checked ? "Y" : "N"; // Convert checkbox value to 'Y' or 'N'
        break;
      default:
        currentRow[name] = value;
        break;
    }

    currentRow.changed_xx = "Y";

    // Update the state with the modified data
    setFilteredSmiData(updatedData);
  };

  useEffect(() => {
    const filteredSmiResults = subClassCoverSmi.filter(
      (item) =>
        item.smi_code?.toLowerCase().includes(searchTermSmi.toLowerCase()) ||
        item.smi_name?.toLowerCase().includes(searchTermSmi.toLowerCase())
    );
    setFilteredSmiData(filteredSmiResults);
  }, [subClassCoverSmi, searchTermSmi]);

  const smiColumns = [
    {
      header: "Code",
      key: "smi_code",
      align: "left",
      type: "text",
      width: "120px",
    },
    {
      header: "Name",
      key: "smi_name",
      align: "left",
      type: "text",
      width: "auto",
    },
    {
      key: "smi_rate",
      header: "Rate",
      align: "right",
      type: "number",
      width: "120px",
    },
    {
      key: "smi_rate_per",
      header: "Per",
      align: "right",
      type: "number",
      width: "100px",
    },
    {
      key: "smi_fcl_value",
      header: "FCL",
      align: "right",
      type: "number",
      width: "150px",
    },
    {
      key: "smi_default",
      header: "Default",
      align: "center",
      type: "text",
      width: "50px",
    },
    {
      key: "smi_enabled",
      header: "Enabled",
      align: "center",
      type: "text",
      width: "50px",
    },

    {
      key: "actions",
      header: "Action",
      align: "center",
      type: "text",
      width: "50px",
    },
  ];

  const saveRecords = async (e) => {
    e.preventDefault();
    try {
      const changedObjects = filteredSmiData.filter(
        (obj) => obj.changed_xx === "Y"
      );
      //

      // Loop through each object in the array
      for (let i = 0; i < changedObjects.length; i++) {
        // const obj = changedObjects[i];
        const updatedSmi = {
          smi_code: changedObjects[i].smi_code,
          smi_name: changedObjects[i].smi_name,
          smi_desc: changedObjects[i].smi_desc,
          smi_rate: changedObjects[i].smi_rate,
          smi_rate_per: changedObjects[i].smi_rate_per,
          smi_fcl_value: changedObjects[i].smi_fcl_value,
          smi_icon: changedObjects[i].smi_icon,
          smi_default: changedObjects[i].smi_default,
          smi_enabled: changedObjects[i].smi_enabled,
          updatedBy: currentUser.usr_id,
        };
        const res = await newRequest.put(
          `/uwSubClassCoverSmi/upd/${changedObjects[i]._id}`,
          updatedSmi,
          config
        );
      }
      showToast("Updated!", "success");
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddSubClass = async (selectedClass) => {
    try {
      if (!addClassSubName) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter name!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      if (!addClassSubCode) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter code!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      const addSc = {
        sc_org_code: currentUser.usr_org_code,
        sc_mc_code: selectedClass.mc_code,
        sc_code: addClassSubCode,
        sc_name: addClassSubName,
        createdBy: currentUser.usr_id,
      };
      const res = await newRequest.post("/uwSubClass/add", addSc, config);

      setAddClassSubName("");
      setAddClassSubCode("");
      fetchClassesWithSub();
      showToast("Sub Class Added!", "success");
    } catch (error) {
      showToast("Error Occured!", "error");
      setAddClassSubName("");
      setAddClassSubCode("");
      console.log(error);
    }
  };

  return (
    <div className="uw-sub-class">
      <div className="left">
        <div className="no-sub-selected">
          <div className="no-sub-selected-wrapper">
            {Object.keys(selectedSubClass).length === 0 && (
              <h1>Please Select Sub Class to View Details</h1>
            )}
          </div>
        </div>
        <div className="sub-selected">
          {Object.keys(selectedSubClass).length > 0 && (
            <div className="sub-class-form">
              <div className="sub-class-details">
                <span className="code">
                  {`${selectedSubClass.sc_code} ${selectedSubClass.sc_name} Applicable Covers`}{" "}
                </span>
              </div>
              <div className="sub-class-cover-details">
                {subClassCovers.map((subClassCover) => (
                  <div
                    className={`${subClassCover._id === selectedSubClassCover._id
                        ? "single-sublass-cover active"
                        : "single-sublass-cover"
                      }`}
                    key={subClassCover._id}
                    onClick={() => {
                      try {
                        setSelectedSubClassCover(subClassCover);
                        fetchSubClassCoverSmi(
                          subClassCover.cc_mc_code,
                          subClassCover.cc_sc_code,
                          subClassCover.cc_code
                        );
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  >
                    <div className="single-sublass-cover-icon">
                      {getIconComponent(subClassCover.cc_icon, 24) || (
                        <FiShield size={24} />
                      )}
                    </div>
                    <div className="single-sublass-cover-content">
                      <div
                        className="single-sublass-cover-title"
                        title={subClassCover.cc_name}
                      >
                        <span className="name">{subClassCover.cc_name}</span>
                        <span className="code">{subClassCover.cc_code}</span>
                      </div>
                      <div className="single-sublass-cover-desc">
                        {subClassCover.cc_desc}
                      </div>
                    </div>
                  </div>

                ))}
              </div>

              {/* cover smis */}

              <div className="uw-smi-wrapper">
                <div className="uw-smi-wrapper-navbar">
                  <div className="uw-smi-wrapper-navbar-left">
                    <div className="search-input-wrapper">
                      <SearchIcon className="search-input-icon" />
                      <input
                        className="search-input"
                        type="text"
                        placeholder="Search..."
                        onChange={(e) => setSearchTermSmi(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="uw-smi-wrapper-navbar-right">
                    <CustomButton
                      startIcon={<AddIcon />}
                      onClick={() => {
                        try {
                          setAddSmiDialog(true);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                    >
                      Add
                    </CustomButton>
                    <CustomButton
                      startIcon={<SaveIcon />}
                      onClick={saveRecords}
                    >
                      Save
                    </CustomButton>
                  </div>
                </div>
                <div className="content">
                  {isLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <GridLoader
                        color="#F44336"
                        style={{
                          height: "50px",
                          width: "50px",
                        }}
                      />
                    </div>
                  ) : (
                    <table className="custom-table">
                      <tbody>
                        {filteredSmiData?.map((row, index) => (
                          <tr
                            key={row._id}
                            className={`${selectedCoverSmi?._id === row?._id
                                ? "smi-data-row active"
                                : "smi-data-row"
                              }`}
                            onClick={() => setSelectedCoverSmi(row)}
                          >
                            <div className="smi-data-wrapper">
                              <div
                                className={`${selectedCoverSmi?._id === row?._id
                                    ? "smi-data-col1 active"
                                    : "smi-data-col1"
                                  }`}
                              >
                                {index + 1}
                              </div>
                              <div className="smi-data-col2">
                                <div className="code-col">
                                  <div className="input-item-with-label">
                                    <label
                                      htmlFor="smi_code"
                                      className="form-dropdown-label"
                                      style={{
                                        width: "100px",
                                        marginRight: "3px",
                                      }}
                                    >
                                      CODE
                                    </label>
                                    <input
                                      id="smi_code"
                                      name="smi_code"
                                      type="text"
                                      placeholder="CODE"
                                      value={row.smi_code}
                                      className="form-input-item"
                                      style={{ width: "100px" }}
                                      onChange={(e) =>
                                        handleInputChange(e, index)
                                      }
                                    />
                                  </div>
                                  <div className="input-item-with-label">
                                    <label
                                      htmlFor="smi_rate"
                                      className="form-dropdown-label"
                                      style={{
                                        width: "100px",
                                        marginRight: "3px",
                                      }}
                                    >
                                      RATE
                                    </label>
                                    <input
                                      id="smi_rate"
                                      name="smi_rate"
                                      type="number"
                                      placeholder="RATE"
                                      value={row.smi_rate}
                                      className="form-input-item"
                                      style={{ width: "100px" }}
                                      onChange={(e) =>
                                        handleInputChange(e, index)
                                      }
                                    />
                                  </div>
                                  <div className="input-item-with-label">
                                    <label
                                      htmlFor="smi_rate_per"
                                      className="form-dropdown-label"
                                      style={{
                                        width: "100px",
                                        marginRight: "3px",
                                      }}
                                    >
                                      PER
                                    </label>
                                    <input
                                      id="smi_rate_per"
                                      name="smi_rate_per"
                                      type="number"
                                      placeholder="PER"
                                      value={row.smi_rate_per}
                                      className="form-input-item"
                                      style={{ width: "100px" }}
                                      onChange={(e) =>
                                        handleInputChange(e, index)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="name-col">
                                  <div className="input-item-with-label">
                                    <label
                                      htmlFor="smi_name"
                                      className="form-dropdown-label"
                                      style={{
                                        width: "100px",
                                        marginRight: "3px",
                                      }}
                                    >
                                      NAME
                                    </label>
                                    <input
                                      id="smi_name"
                                      name="smi_name"
                                      type="text"
                                      placeholder="NAME"
                                      value={row.smi_name}
                                      className="form-input-item"
                                      style={{ width: "80%" }}
                                      onChange={(e) =>
                                        handleInputChange(e, index)
                                      }
                                    />
                                  </div>
                                  <div className="input-item-with-label">
                                    <label
                                      htmlFor="smi_desc"
                                      className="form-dropdown-label"
                                      style={{
                                        width: "100px",
                                        marginRight: "3px",
                                        alignSelf: "flex-start",
                                      }}
                                    >
                                      DESC
                                    </label>
                                    <textarea
                                      id="smi_desc"
                                      name="smi_desc"
                                      type="text"
                                      placeholder="DESCRIPTION"
                                      value={row.smi_desc}
                                      className="form-input-item"
                                      style={{
                                        width: "80%",
                                        minHeight: "50px",
                                        height: "auto",
                                      }}
                                      onChange={(e) =>
                                        handleInputChange(e, index)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="fcl-col">
                                  <div className="input-item-with-label">
                                    <label
                                      htmlFor="smi_fcl_value"
                                      className="form-dropdown-label"
                                      style={{
                                        width: "100px",
                                        marginRight: "3px",
                                      }}
                                    >
                                      FCL
                                    </label>
                                    <input
                                      id="smi_fcl_value"
                                      name="smi_fcl_value"
                                      type="number"
                                      placeholder="FCL"
                                      value={row.smi_fcl_value}
                                      className="form-input-item"
                                      style={{ width: "100px" }}
                                      onChange={(e) =>
                                        handleInputChange(e, index)
                                      }
                                    />
                                  </div>
                                  <div className="input-item-with-label">
                                    <label
                                      htmlFor="smi_default"
                                      className="form-dropdown-label"
                                      style={{
                                        width: "100px",
                                        marginRight: "3px",
                                      }}
                                    >
                                      Default
                                    </label>
                                    <select
                                      id="smi_default"
                                      name="smi_default"
                                      className="select-yn"
                                      value={row.smi_default}
                                      onChange={(e) =>
                                        handleInputChange(e, index)
                                      }
                                    >
                                      <option value="Y">Yes</option>
                                      <option value="N">No</option>
                                    </select>
                                  </div>
                                  <div className="input-item-with-label">
                                    <label
                                      htmlFor="smi_enabled"
                                      className="form-dropdown-label"
                                      style={{
                                        width: "100px",
                                        marginRight: "3px",
                                      }}
                                    >
                                      Enabled
                                    </label>
                                    <select
                                      id="smi_enabled"
                                      name="smi_enabled"
                                      className="select-yn"
                                      value={row.smi_enabled}
                                      onChange={(e) =>
                                        handleInputChange(e, index)
                                      }
                                    >
                                      <option value="Y">Yes</option>
                                      <option value="N">No</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="smi-data-col3">
                                <DeleteIcon
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    try {
                                      let action_text = `Delete SMI ${row.smi_name}?`;
                                      setObjectToAction({
                                        object_id: row._id,
                                        action_text: action_text,
                                        new_doc_status: "delete",
                                      });
                                      setConfirmDialogAction("deleteSmi");
                                      setConfirmDialogOpen(true);
                                    } catch (error) {
                                      console.log(error);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="right">
        <div className="menu">
          <div className="search-input-wrapper">
            <SearchIcon className="search-input-icon" />
            <input
              className="search-input"
              type="text"
              placeholder="Search..."
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="class-details-wrapper">
            {filteredData.map((singleClass) => (
              <div className="class-item" key={singleClass._id}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={
                      <SlArrowDown
                        className="content-accordion-expand-icon"
                        style={{ color: "#f44336", fontWeight: "bold" }}
                      />
                    }
                    id="panel-header"
                  >
                    <Typography className="class-item-title">
                      <span>
                        {singleClass.mc_name} [{singleClass.mc_code}]
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="add-subclass-content">
                      <hr style={{ margin: "5px 0px" }} />
                      <span>Add New Sub Class</span>
                      <div className="input-item-with-label">
                        <input
                          id="scName"
                          name="scName"
                          type="text"
                          placeholder="NAME"
                          value={addClassSubName}
                          className="form-input-item"
                          onChange={(e) => setAddClassSubName(e.target.value)}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          gap: "5px",
                        }}
                      >
                        <div className="input-item-with-label">
                          <input
                            id="scCode"
                            name="scCode"
                            type="text"
                            placeholder="CODE"
                            value={addClassSubCode}
                            className="form-input-item"
                            style={{ width: "100%" }}
                            onChange={(e) => setAddClassSubCode(e.target.value)}
                          />
                        </div>
                        <CustomButton
                          startIcon={<AddIcon />}
                          style={{ width: "80px" }}
                          onClick={() => handleAddSubClass(singleClass)}
                        >
                          Add
                        </CustomButton>
                      </div>
                    </div>
                    <hr style={{ margin: "10px 0px" }} />
                    {singleClass.subClasses.map((subClass) => (
                      <div
                        className={`${subClass.sc_doc_id === selectedSubClass.sc_doc_id
                            ? "single-sublass active"
                            : "single-sublass"
                          }`}
                        key={subClass.sc_doc_id}
                        onClick={() => {
                          try {
                            const updatedSubClass = {
                              ...subClass,
                              mc_name_xx: singleClass.mc_name,
                              mc_code_xx: singleClass.mc_code,
                            };
                            setSelectedSubClass(updatedSubClass);
                            fetchSubClassCovers(
                              singleClass.mc_code,
                              subClass.sc_code
                            );
                          } catch (error) {
                            console.log(error);
                          }
                        }}
                      >
                        <div className="single-sublass-icon">
                          {getIconComponent(subClass.sc_icon, 20) || (
                            <FiShield size={20} />
                          )}
                        </div>
                        <div className="single-sublass-content">
                          <div
                            className="single-sublass-title"
                            title={subClass.sc_name}
                          >
                            <span className="name">{subClass.sc_name}</span>
                            <span className="code">{subClass.sc_code}</span>
                          </div>
                          {/* <div className="single-sublass-desc">
                            {subClass.sc_desc}
                          </div> */}
                        </div>
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Confirm Dialog */}
      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        dialogTitle="Confirmation"
        dialogContent={<p>{objectToAction.action_text}</p>}
      />
      {/* Dialog to notify user about changes being saved */}
      <Dialog open={isNotifyDialogOpen} onClose={handleCloseNotifyDialog}>
        <DialogTitle>{notifyDialogTitle}</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: notifyDialogText }} />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseNotifyDialog}>Close</CustomButton>
        </DialogActions>
      </Dialog>
      {addSmiDialog && (
        <motion.div
          className="add-smi-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="add-smi-wrapper">
            <div className="add-smi-navbar">
              <span>ADD SMI</span>
              <span onClick={() => setAddSmiDialog(false)}>
                <IoClose
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    borderRadius: "50%",
                  }}
                />
              </span>
            </div>
            <div className="add-smi-content">
              <div className="input-item-with-label">
                <label htmlFor="smiName" style={{ width: "130px" }}>
                  NAME
                </label>
                <input
                  id="smiName"
                  name="smiName"
                  type="text"
                  placeholder="NAME"
                  value={smiName}
                  className="form-input-item"
                  style={{ width: "300px" }}
                  onChange={handleAddSmiInputChange}
                />
              </div>
              <div className="input-item-with-label">
                <label
                  htmlFor="smiDesc"
                  style={{ width: "130px", alignSelf: "flex-start" }}
                >
                  DESCRIPTION
                </label>
                <textarea
                  id="smiDesc"
                  name="smiDesc"
                  type="text"
                  placeholder="DESCRIPTION"
                  value={smiDesc}
                  rows={5}
                  className="form-input-item"
                  style={{ width: "300px" }}
                  onChange={handleAddSmiInputChange}
                />
              </div>

              <div style={{ display: "flex", flexDirection: "row" }}>
                <div className="input-item-with-label">
                  <label htmlFor="smiRate" style={{ width: "130px" }}>
                    RATE
                  </label>
                  <input
                    id="smiRate"
                    name="smiRate"
                    type="number"
                    placeholder="NAME"
                    value={smiRate}
                    className="form-input-item"
                    style={{ width: "100px", textAlign: "right" }}
                    onChange={handleAddSmiInputChange}
                  />
                </div>
                <div className="input-item-with-label">
                  <label htmlFor="smiRatePer" style={{ width: "95px" }}>
                    RATE PER
                  </label>
                  <input
                    id="smiRatePer"
                    name="smiRatePer"
                    type="number"
                    placeholder="NAME"
                    value={smiRatePer}
                    className="form-input-item"
                    style={{ width: "100px", textAlign: "right" }}
                    onChange={handleAddSmiInputChange}
                  />
                </div>
              </div>
              <div className="input-item-with-label">
                <label htmlFor="smiFcl" style={{ width: "130px" }}>
                  FCL
                </label>
                <input
                  id="smiFcl"
                  name="smiFcl"
                  type="number"
                  placeholder="NAME"
                  value={smiFcl}
                  className="form-input-item"
                  style={{ width: "300px", textAlign: "right" }}
                  onChange={handleAddSmiInputChange}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div className="input-item-with-label">
                  <label htmlFor="smiDefault" style={{ width: "130px" }}>
                    DEFAULT
                  </label>
                  <select
                    id="smiDefault"
                    name="smiDefault"
                    className="select-yn"
                    style={{ width: "100px" }}
                    value={smiDefault}
                    onChange={handleAddSmiInputChange}
                  >
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                  </select>
                </div>
                <div className="input-item-with-label">
                  <label htmlFor="smiEnabled" style={{ width: "95px" }}>
                    ENABLED
                  </label>
                  <select
                    id="smiEnabled"
                    name="smiEnabled"
                    className="select-yn"
                    style={{ width: "100px" }}
                    value={smiEnabled}
                    onChange={handleAddSmiInputChange}
                  >
                    <option value="Y" style={{ borderRadius: "3px" }}>
                      Yes
                    </option>
                    <option value="N" style={{ borderRadius: "3px" }}>
                      No
                    </option>
                  </select>
                </div>
              </div>
              {/* <div className="input-item-with-label">
                <label htmlFor="smiCode" style={{ width: "130px" }}>
                  CODE
                </label>
                <input
                  id="smiCode"
                  name="smiCode"
                  type="text"
                  placeholder="CODE"
                  value={smiCode}
                  className="form-input-item"
                  style={{ width: "300px" }}
                  onChange={handleAddSmiInputChange}
                />
              </div> */}
            </div>
            <div className="add-smi-action">
              <CustomButton onClick={handleAddSmiSubmit} width={"100px"}>
                Add
              </CustomButton>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default UwSubClass;
