import React, { useState, useEffect } from "react";

const CountdownTimer = ({ targetDate }) => {
  const calculateTimeRemaining = (targetDate) => {
    const now = new Date().getTime();
    const targetTime = new Date(targetDate).getTime();
    const timeDifference = targetTime - now;

    if (timeDifference <= 0) {
      // Target date has passed
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    return { days, hours, minutes, seconds };
  };

  const [timeRemaining, setTimeRemaining] = useState(
    calculateTimeRemaining(targetDate)
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining(targetDate));
    }, 1000); // Update every second

    // Clean up the timer when the component unmounts
    return () => clearInterval(timer);
  }, [targetDate]);

  return (
    <div>
      {/* <p>Countdown:</p> */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontWeight: "500",
          fontSize: "22px",
        }}
      >
        {/* <span>{timeRemaining.days} days</span> */}
        {/* <span>{timeRemaining.hours} hours</span> */}
        <span>
          {`Finish signup in ${timeRemaining.minutes} minutes ${timeRemaining.seconds} seconds`}{" "}
        </span>
      </div>
    </div>
  );
};

export default CountdownTimer;
