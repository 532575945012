import "./style.scss";
import HorizontalNavbar from "./components/navbar/HorizontalNavbar";
import AllEntity from "./pages/all/allEntity/AllEntity";
import AllEntityHome from "./pages/all/allEntityHome/AllEntityHome";
import EntityImport from "./pages/all/entityImport/EntityImport";
import ClaimsHome from "./pages/cm/ClaimsHome";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import MarketingHome from "./pages/mk/MarketingHome";
import SalesHome from "./pages/sl/SalesHome";
import UwPolicy from "./pages/uw/uwPolicy/UwPolicy";
import UwRegister from "./pages/uw/uwRegister/UwRegister";
import UwRenewal from "./pages/uw/uwRenewal/UwRenewal";
import { logOut } from "./redux/userSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TermsAndConditions from "./components/termsAndConditions/TermsAndConditions";
import AdHome from "./pages/ad/AdHome";
import ApHome from "./pages/ap/ApHome";
import ApPayment from "./pages/ap/apPayment/ApPayment";
import ApPaymentHome from "./pages/ap/apPaymentHome/ApPaymentHome";
import ArHome from "./pages/ar/ArHome";
import ArReceipt from "./pages/ar/arReceipt/ArReceipt";
import ArReceiptHome from "./pages/ar/arReceiptHome/ArReceiptHome";
import CmClaim from "./pages/cm/cmClaim/CmClaim";
import CmHome from "./pages/cm/cmHome/CmHome";
import GlHome from "./pages/gl/GlHome";
import Register from "./pages/register/Register";
import TestMenu from "./pages/uw/TestMenu";
import UwHome from "./pages/uw/uwHome/UwHome";
import UwProduct from "./pages/uw/uwProduct/UwProduct";
import UwSubClass from "./pages/uw/uwSubClass/UwSubClass";
import ValidateSignup from "./pages/validateSignup/ValidateSignup";
import AllEntityCatg from "./pages/all/allEntityCatg/AllEntityCatg";
import UwVehicleImport from "./pages/uw/uwVehicleImport/UwVehicleImport";
import SearchResults from "./pages/all/searchResults/SearchResults";
import Todo from "./pages/all/todo/Todo";
import MyCalendar from "./pages/myCalendar/MyCalendar";
import UwPolicyNew from "./pages/uw/uwPolicyNew/UwPolicyNew";
import LandingPage from "./pages/landingPage/LandingPage";
import { connectSocket, disconnectSocket } from "./redux/socketSlice";
import AdProfile from "./pages/ad/adProfile/AdProfile";
import VerifyDocument from "./pages/verifyDocument/VerifyDocument";
import AllEntityCentral from "./pages/all/allEntityCentral/AllEntityCentral";


const DefaultErrorPage = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1>Oops! Something went wrong.</h1>
      <p>We apologize for the inconvenience. Please try again later.</p>
    </div>
  );
};

function App() {
  const { currentUser } = useSelector((state) => state.user);
  const { currentTheme } = useSelector((state) => state.theme);
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(connectSocket());

    return () => {
      dispatch(disconnectSocket());
    };
  }, [dispatch]);

  // For Detecting Browser Close and Logging User Out
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      try {
        dispatch(logOut());
      } catch (err) {
        console.log(err);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [currentUser]);



  const Layout = () => (
    <div className={`theme-${currentTheme}`}>
      <div className="main-container">
        <HorizontalNavbar />
        <Outlet />
      </div>
    </div>
  );

  const ProtectedRoute = ({ children }) => {
    if (!currentUser) {
      return <Navigate to="/qillo" />;
    }
    return children;
  };


  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <ProtectedRoute>
          <Layout />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "/",
          element: <Home />,
          errorElement: <DefaultErrorPage />,
        },
        {
          path: "/calendar",
          element: <MyCalendar />,
          errorElement: <DefaultErrorPage />,
        },
        {
          path: "/menu",
          element: <TestMenu />,
          errorElement: <DefaultErrorPage />,
        },
        {
          path: "/todo",
          element: <Todo />,
          errorElement: <DefaultErrorPage />,
        },
        {
          path: "/searchResults",
          element: <SearchResults />,
          errorElement: <DefaultErrorPage />,
        },
        {
          path: "/entity",
          element: <AllEntityHome />,
          errorElement: <DefaultErrorPage />,
        },
        {
          path: "/entityCatg",
          element: <AllEntityCatg />,
          errorElement: <DefaultErrorPage />,
        },
        {
          path: "/entity/:docId",
          element: <AllEntity />,
          errorElement: <DefaultErrorPage />,
        },
        {
          path: "/central/:entity_docid",
          element: <AllEntityCentral />,
          errorElement: <DefaultErrorPage />,
        },
        {
          path: "/uw",
          element: <UwHome />,
          errorElement: <DefaultErrorPage />,
        },
        {
          path: "/uwProduct/:docid",
          element: <UwProduct />,
          errorElement: <DefaultErrorPage />,
        },
        {
          path: "/uwSuClass",
          element: <UwSubClass />,
          errorElement: <DefaultErrorPage />,
        },
        {
          path: "/uw/:doc_type/:docId",
          element: <UwPolicy />,
          errorElement: <DefaultErrorPage />,
        },
        {
          path: "/uwnew/:doc_type/:docId",
          element: <UwPolicyNew />,
          errorElement: <DefaultErrorPage />,
        },
        {
          path: "/vehicleImport",
          element: <UwVehicleImport />,
          errorElement: <DefaultErrorPage />,
        },
        {
          path: "/cm",
          element: <CmHome />,
          errorElement: <DefaultErrorPage />,
        },
        {
          path: "/cm/:docid",
          element: <CmClaim />,
          errorElement: <DefaultErrorPage />,
        },
        {
          path: "/ar",
          element: <ArHome />,
          errorElement: <DefaultErrorPage />,
        },
        {
          path: "/arHome",
          element: <ArReceiptHome />,
          errorElement: <DefaultErrorPage />,
        },
        {
          path: "/receipt/:docId",
          element: <ArReceipt />,
          errorElement: <DefaultErrorPage />,
        },
        {
          path: "/ad",
          element: <AdHome />,
          errorElement: <DefaultErrorPage />,
        },
        {
          path: "/ap",
          element: <ApHome />,
          errorElement: <DefaultErrorPage />,
        },
        {
          path: "/apHome",
          element: <ApPaymentHome />,
          errorElement: <DefaultErrorPage />,
        },
        {
          path: "/payment/:docId",
          element: <ApPayment />,
          errorElement: <DefaultErrorPage />,
        },
        {
          path: "/renewal",
          element: <UwRenewal />,
          errorElement: <DefaultErrorPage />,
        },
        {
          path: "/uwreg",
          element: <UwRegister />,
          errorElement: <DefaultErrorPage />,
        },
        {
          path: "/claims",
          element: <ClaimsHome />,
          errorElement: <DefaultErrorPage />,
        },
        {
          path: "/gl",
          element: <GlHome />,
          errorElement: <DefaultErrorPage />,
        },
        {
          path: "/marketing",
          element: <MarketingHome />,
          errorElement: <DefaultErrorPage />,
        },
        {
          path: "/sales",
          element: <SalesHome />,
          errorElement: <DefaultErrorPage />,
        },
        {
          path: "/entityImport",
          element: <EntityImport />,
          errorElement: <DefaultErrorPage />,
        },
        {
          path: "/clients",
          element: <AllEntityHome />,
          errorElement: <DefaultErrorPage />,
        },
        {
          path: "/profile",
          element: <AdProfile />,
          errorElement: <DefaultErrorPage />,
        },
      ],
    },
    {
      path: "/qillo",
      element: <LandingPage />,
    },
    {
      path: "/login",
      element: currentUser ? <Navigate to="/" /> : <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/tac",
      element: <TermsAndConditions />,
    },
    {
      path: "/signup/:docid",
      element: <ValidateSignup />,
    },
    {
      path: "/verify/:docType/:docId",
      element: <VerifyDocument />,
    },
    {
      path: "*",
      element: <DefaultErrorPage />
    },
  ]);

  return (
    <div
      className="entire-doc"
      style={{
        flexDirection: "column",
        justifyContent: "space-between",
        overflowY: "auto",
      }}
    >
      <ToastContainer position="top-center" style={{ marginTop: "100px" }} />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
