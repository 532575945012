import { useEffect, useState } from "react";
import "./Todolist.scss"
import { useSelector } from "react-redux";
import newRequest from "../../utils/newRequest";
import Checkbox from '@mui/material/Checkbox';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IoClose } from "react-icons/io5";
import AddIcon from "@mui/icons-material/Add";
import { showToast } from "../../toastService.js";
import {
  Tooltip,
  Popover,
} from "@mui/material";
import moment from "moment";
import { CustomLoadingButton } from "../ui/CustomButton";
import { spacing } from "@mui/system";

const Todolist = ({ passedTodoDate }) => {
  const { currentUser } = useSelector((state) => state.user);

  const [anchorEl, setAnchorEl] = useState(null);
  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? 'simple-popover' : undefined;

  const handlePopoverClick = (event) => {
    setTodoDate(moment(passedTodoDate).format("YYYY-MM-DD"));
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const [todoShow, setTodoShow] = useState("N");
  const { todoCount } = useSelector((state) => state.todo);
  const [todoDate, setTodoDate] = useState(null);
  const [todoText, setTodoText] = useState(null);
  const [todos, setTodos] = useState([]);



  const fetchTodos = async () => {
    try {
      const res = await newRequest.post(
        "/todo/findAll",
        {
          todo_org_code: currentUser.usr_org_code,
          todo_userid: currentUser.usr_id,
          todo_completed: "",
          todo_date: moment(passedTodoDate).format("YYYY-MM-DD")
        }
      );
      setTodos(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const [todoStates, setTodoStates] = useState(
    todos.reduce((acc, todo) => {
      acc[todo._id] = todo.todo_completed === 'Y';
      return acc;
    }, {})
  );

  const handleCheckboxChange = async (event, id) => {
    const isChecked = event.target.checked;
    setTodoStates((prevStates) => ({
      ...prevStates,
      [id]: isChecked,
    }));

    const res = await newRequest.put(
      `/todo/upd/${id}`,
      {
        todo_completed: isChecked ? 'Y' : 'N'
      }
    );

  };


  const handleDeleteTask = async (taskId) => {
    try {
      //  delete task
      const res = await newRequest.delete(
        `/todo/del/${taskId}`
      );
      fetchTodos();
    } catch (error) {
      console.error('Error updating todo:', error);
    }
  };

  const handleAddTodo = async () => {
    try {

      const res = await newRequest.post(
        "todo/add",
        {
          todo_org_code: currentUser.usr_org_code,
          todo_text: todoText,
          todo_date: todoDate,
          todo_completed: "N",
          userCode: currentUser.usr_id,
        }
      );
      showToast("Added!", "success");
      fetchTodos();
      setTodoDate(null);
      setTodoText(null);
      setAnchorEl(null);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchTodos();
  }, [passedTodoDate]);

  return (
    <div className="todolist">
      <div className="todo-navbar">
        <span className="todo-title">Events & Todo List</span>
        <span style={{ display: "flex", alignItems: "center", gap: "3px" }}>
          <span className="todo-view-all" onClick={handlePopoverClick}>Add Todo</span>
          <span className="todo-view-all" onClick={handlePopoverClick}>Add Event</span>
          {popoverOpen && <div className="backdrop" onClick={handlePopoverClose}></div>}
          <Popover
            id={id}
            open={popoverOpen}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <div style={{ padding: "10px" }}>
              <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "max-content",
                marginBottom: "5px"
              }}>
                <span>TODO</span>
                <span
                  onClick={handlePopoverClose}
                >
                  <IoClose
                    style={{
                      width: "30px",
                      height: "30px",
                      cursor: "pointer",
                      borderRadius: "50%",
                    }}
                  />
                </span>
              </div>
              <div className="input-item-with-label" style={{ width: "300px" }}>
                <textarea
                  id="todo_text"
                  name="todo_text"
                  type="text"
                  placeholder="Type to do action here"
                  value={todoText}
                  row={3}
                  className="form-input-item"
                  style={{ width: "100%" }}
                  onChange={(e) => setTodoText(e.target.value)}
                />
              </div>
              <div className="input-item-with-label" style={{ width: "300px", marginTop: "10px", display: "flex", justifyContent: "space-between" }}>
                <input
                  id="todo_date"
                  name="todo_date"
                  type="date"
                  placeholder="FROM"
                  value={todoDate}
                  className="form-input-item"
                  style={{ width: "60%" }}
                  onChange={(e) => {
                    try {
                      const formattedDate = moment(e.target.value).format("YYYY-MM-DD");
                      setTodoDate(formattedDate);
                    } catch (error) {
                      console.log(error)
                    }
                  }}
                />
                <CustomLoadingButton
                  startIcon={<AddIcon />}
                  onClick={handleAddTodo}
                  disabled={!todoText || !todoDate}
                >
                  Add
                </CustomLoadingButton>
              </div>
            </div>
          </Popover>
        </span>
      </div>
      {todos?.length === 0 &&
        <span style={{ fontWeight: "300", fontSize: "18px" }}>{`No todo list found on ${moment(passedTodoDate).format("Do MMMM YYYY")}`}</span>
      }
      {todos?.length > 0 &&
        <div className="todo-content">
          {todos?.map((row) => (
            <span className="todo-card" key={row._id}>
              <div className="left">{row.sr_no}.</div>
              <div className="center">{row.todo_text}</div>
              <div className="right">
                <Checkbox
                  checkedIcon={<CheckCircleIcon />}
                  checked={todoStates[row._id] || false}
                  onChange={(e) => handleCheckboxChange(e, row._id)}
                  style={{ color: '#9c27b0', margin: "0px", padding: "0px" }}
                />
                <Tooltip
                  title={`Delete ${row.todo_text}`}
                  placement="left"
                >
                  <IoClose
                    size={20}
                    style={{
                      borderRadius: "5px",
                      padding: "3px",
                      cursor: "pointer",
                      color: "rgba(172, 33, 9 )",
                      backgroundColor: "rgba(172, 33, 9, 0.3)",
                    }}
                    onClick={() => {
                      try {
                        const action_text = `Delete ${row.todo_text}?`;
                        handleDeleteTask(row._id);
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  />
                </Tooltip>
              </div>
            </span>
          ))}
        </div>}
    </div>
  )
}

export default Todolist