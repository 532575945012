import "./Todo.scss";
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoClose } from "react-icons/io5";
import newRequest from "../../../utils/newRequest";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { GridLoader } from "react-spinners";
import moment from "moment";
import numeral from "numeral";


const Todo = () => {
    const { currentUser } = useSelector((state) => state.user);
    const location = useLocation();
    const navigate = useNavigate();
    const [isSearching, setIsSearching] = useState(false);
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };


    const goBack = () => {
        navigate(-1);
    };

    return (
        <div className="todo">
            {isSearching ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "200px",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <GridLoader
                        color="#F44336"
                        style={{
                            height: "50px",
                            width: "50px",
                        }}
                    />
                </div>
            ) : (
                <motion.div
                    className="vehicle-check-dialog"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 1 } }}
                    exit={{ opacity: 0, transition: { duration: 1 } }}
                >
                    <div className="vehicle-check-wrapper">
                        <div className="vehicle-check-navbar">
                            <span>VEHICLE CHECK</span>
                            <span onClick={goBack}>
                                <IoClose
                                    style={{
                                        width: "30px",
                                        height: "30px",
                                        cursor: "pointer",
                                        borderRadius: "50%",
                                    }}
                                />
                            </span>
                        </div>
                        <div className="vehicle-check-content">
                            content here
                        </div>
                        <div className="vehicle-check-action">
                            <span>proceed</span>
                        </div>
                    </div>
                </motion.div>
            )}
        </div>
    );
};

export default Todo;
