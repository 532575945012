import "./UwPolicy.scss";
import AllFiles from "../../../components/allFiles/AllFiles.jsx";
import ConfirmDialog from "../../../components/confirmDialog/confirmDialog.jsx";
import CircularButton, {
  CustomButton,
  CustomButtonSmall,
  CustomLoadingButton,
} from "../../../components/ui/CustomButton.jsx";
import { customSelectStyles } from "../../../components/ui/uiStyleElements.jsx";
import { showToast } from "../../../toastService.js";
import newRequest from "../../../utils/newRequest.js";
import UwPolicyCond from "../uwPolicyCond/UwPolicyCond.jsx";
import UwPolicyRisk from "../uwPolicyRisk/UwPolicyRisk.jsx";
import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DescriptionIcon from "@mui/icons-material/Description";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import RefreshIcon from "@mui/icons-material/Refresh";
import GetAppIcon from '@mui/icons-material/GetApp';
import EmailIcon from '@mui/icons-material/Email';
import EventNoteIcon from '@mui/icons-material/EventNote';
import TopicIcon from '@mui/icons-material/Topic';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  Box,
  Typography
} from "@mui/material";
import moment from "moment";
import numeral from "numeral";
import { useEffect, useRef, useState } from "react";
import { FaSave } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import "react-loading-skeleton/dist/skeleton.css";
import { motion } from "framer-motion";
import Confetti from "react-confetti";
import CommentIcon from '@mui/icons-material/Comment';
import AsyncSelect from "react-select/async";
import { GridLoader } from "react-spinners";
import { addDaysToMongoDate, addMonthsToMongoDate, validateMotorVehicleRegn } from "../../../utils/genericFunctions.js";
import ReceiptIcon from '@mui/icons-material/Receipt';
import SubjectIcon from '@mui/icons-material/Subject';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import Badge from '@mui/material/Badge';
import UwPolicyExcess from "../uwPolicyExcess/UwPolicyExcess.jsx";
import ValidationMessages from "../../../components/validationMessages/ValidationMessages.jsx";
import { MdOutlineMenuOpen } from "react-icons/md";
import EmailComponent from "../../../components/emailComponent/EmailComponent.jsx";
import DOMPurify from 'dompurify';
import FileUploadComponent from "../../../components/fileUploadComponent/FileUploadComponent.jsx";
import AllDocsNote from "../../../components/allDocsNote/AllDocsNote.jsx";
import PdfPreview from "../../../components/pdfPreview/PdfPreview.jsx";
import Correspondence from "../../../components/correspondence/Correspondence.jsx";

const UploadDialog = ({ open, handleClose, uploadProgress }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Progress</DialogTitle>
      <DialogContent>
        <LinearProgress variant="determinate" value={uploadProgress} />
        {uploadProgress && <p>{uploadProgress}% Complete</p>}
      </DialogContent>
    </Dialog>
  );
};

const UwPolicy = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { currentUser } = useSelector((state) => state.user);
  const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
  const [notifyDialogTitle, setNotifyDialogTitle] = useState("Confirm");
  const [notifyDialogText, setNotifyDialogText] = useState("Text");
  const [figuresToShow, setFiguresToShow] = useState("org");
  const [newRiskNoteNo, setNewRiskNoteNo] = useState(true);
  const [isConfettiActive, setIsConfettiActive] = useState(false);
  const [savingTitle, setSavingTitle] = useState("Save");
  const [enablePolicyNo, setEnablePolicyNo] = useState(false);
  const receiptRef = useRef(null);
  const actionsRef = useRef(null);
  const expiringInRef = useRef(null);
  const [openOptions, setOpenOptions] = useState(false);
  const [profileLimitValues, setProfileLimitValues] = useState([]);
  const limitsToCheck = ["UW1005", "UW1008", "UW1010", "UW1021", "UW1012", "UW1019", "UW1020", "UW1021", "UW1022", "UW1024", "UW1025"];
  // top menu 
  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const [activeTab, setActiveTab] = useState(0);
  const [policyRiskKey, setPolicyRiskKey] = useState(0);
  const policyRiskRef = useRef(null);
  const policyExcessRef = useRef(null);
  const policyCondRef = useRef(null);

  // email component 
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [emailData, setEmailData] = useState(null);
  // pdf preview dialog
  const [pdfPreviewDialogOpen, setPdfPreviewDialogOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [binaryDocid, setBinaryDocid] = useState(null);

  const actionsMenuRef = useRef(null);

  // vehicle registration validation
  const [vehicleDialogOpen, setVehicleDialogOpen] = useState(false);
  const [vehicleProceedButton, setVehicleProceedButton] = useState(false);
  const [vehicleCheckTitle, setVehicleCheckTitle] = useState("CHECK");
  const [vehicleDialogTitle, setVehicleDialogTitle] = useState("Confirm");
  const [vehicleResultView, setVehicleResultView] = useState("None");
  const [vehicleDialogRegnNo, setVehicleDialogRegnNo] = useState("");
  const [vehicleMatchingRisks, setVehicleMatchingRisks] = useState([]);
  const vehicleInputRef = useRef(null);
  const [vehiclePage, setVehiclePage] = useState(0);
  const [vehicleRowsPerPage, setVehicleRowsPerPage] = useState(5);


  const [validationMsgDialog, setValidationMsgDialog] = useState(false);
  const [validationMessagesResult, setValidationMessagesResult] = useState({});

  // endorsement
  const [endDialogOpen, setEndDialogOpen] = useState(false);
  const [productEnds, setProductEnds] = useState([]);
  const [selectedProductEnd, setSelectedProductEnd] = useState(null);
  const [endorsementDesc, setEndorsementDesc] = useState("");
  const [endorsementGlDate, setEndorsementGlDate] = useState(new Date());
  const [endorsementFmDate, setEndorsementFmDate] = useState(new Date());
  const [endorsementToDate, setEndorsementToDate] = useState(new Date());
  const [expiringInMenuOpen, setExpiringInMenuOpen] = useState(false);
  const [toggleEndorsementFmDate, setToggleEndorsementFmDate] = useState(false);
  const [toggleEndorsementToDate, setToggleEndorsementToDate] = useState(false);
  const [toggleEndorsementGlDate, setToggleEndorsementGlDate] = useState(false);

  const [endorsementFmTitle, setEndorsementFmTitle] = useState("ENDORSEMENT EFFECTIVE DATE");
  const [endorsementToTitle, setEndorsementToTitle] = useState("TO");

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const customNoOptionsMessage = (customMessage, newEntity) => {
    return (
      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span
          style={{
            color: "#f44336",
            fontWeight: "500",
            fontSize: "18px",
          }}
        >
          {customMessage}
        </span>
        <CustomButtonSmall
          onClick={() => {
            try {
              // UW1005 - User can create entity from underwriting
              navigate(`/entity/new`, {
                state: null,
              });
            } catch (error) {
              console.log(error);
            }
          }}
          width={"max-content"}
        >
          {/* {`New ${newEntity}`} */}
          {newEntity}
        </CustomButtonSmall>
      </span>
    );
  };

  const saveRisksInParent = () => {
    // console.log("saveRisksInParent")
    try {
      if (policyRiskRef.current) {
        policyRiskRef.current.saveRisksAndSmisFromParent();
      }
      if (policyExcessRef.current) {
        policyExcessRef.current.saveExcessFromParent();
      }

      if (policyCondRef.current) {
        policyCondRef.current.saveConditionsFromParent();
      }
    } catch (error) {
      console.log(error)
    }
  };



  const handleRiskNoteNoChange = async () => {
    try {
      setNewRiskNoteNo(!newRiskNoteNo);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePassEndorsement = async () => {
    try {
      // check if period is open
      // const formattedGlDate = moment(plGlDate).format("YYYY-MM-DD");
      // console.log(`endorsementGlDate ${endorsementGlDate}`);
      const res = await newRequest.post(
        "/glCp/periodStatus",
        {
          cp_org_code: currentUser.usr_org_code,
          cp_cy_type: "Underwriting",
          gl_date: endorsementGlDate,
        },
        config
      );
      if (res.data.cp_status === "Closed") {
        setIsConfirmDialogOpen(false);
        setConfirmDialogAction("None");
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(
          `Accounting date ${moment(endorsementGlDate).format(
            "DD-MMM-YYYY"
          )} is within Underwriting period ${res.data.cp_code
          }. The period is <b>${res.data.cp_status
          }</b>. Open the period to be able to Approve.`
        );
        setIsNotifyDialogOpen(true);
        return;
      }

      // pass endorsement
      const end_res = await newRequest.post(
        "/uwPolicy/end",
        {
          source_policy_docid: quoteData._id,
          endorsement_no: quoteData.pl_risk_note_no,
          endorsement_code: selectedProductEnd.pe_end_code,
          new_status: "New",
          pe_policy_no: quoteData.pl_no,
          pe_gl_date: endorsementGlDate,
          pe_fm_date: endorsementFmDate,
          pe_to_date: endorsementToDate,
          pe_remarks: endorsementDesc,
          userCode: currentUser.usr_id,
        }
      );
      // console.log(`data load  ${JSON.stringify(end_res.data, null, 2)}`);
      // set quote data to blank so that when we set the data BsAirplaneEngines, UwPolicyRisk Component will re-render
      setQuoteData({});
      setEndDialogOpen(false);
      setQuoteId(end_res.data._id);
      fetchQuote();
      setPolicyRiskKey(prevKey => prevKey + 1);
      showToast(`Endorsement ${end_res.data.pl_end_no} Passed!`, "success");

    } catch (error) {
      console.log(error);
    }
  };

  // deal with dates
  const handleEndGlDateChange = (e) => {
    try {
      if (e.target.value) {
        const formattedGlDate = moment(e.target.value).format("YYYY-MM-DD");
        setEndorsementGlDate(formattedGlDate);
      }
    } catch (error) {
      console.log(error);
    }
  };


  const [specialNote, setSpecialNote] = useState("");
  const [specialNotes, setSpecialNotes] = useState([]);
  const fetchSpecialNotes = async (docId, noteType) => {
    try {
      const res = await newRequest.post("/allDocsNote/findAll", {
        note_docid: docId,
        note_type: noteType
      });
      setSpecialNotes(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  // deal with dates
  const handleEndFmDateChange = (e) => {
    try {
      if (e.target.value) {
        const formattedFmDate = moment(e.target.value).format("YYYY-MM-DD");
        setEndorsementFmDate(formattedFmDate);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEndToDateChange = (e) => {
    try {
      if (e.target.value) {
        const formattedToDate = moment(e.target.value).format("YYYY-MM-DD");
        setEndorsementToDate(formattedToDate);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProductEnds = async () => {
    try {
      const params = {
        pe_org_code: quoteData.pl_org_code,
        pe_pr_code: quoteData.pl_product_code,
        doc_status: quoteData.pl_status,
      };
      const res = await newRequest.post(
        "/uwProductEnd/findByStatus",
        params,
        config
      );
      const options = res.data.map((end) => ({
        pe_end_code: end.pe_end_code,
        pe_name: end.pe_name,
        pe_desc: end.pe_desc,
        pe_internal_code: end.pe_internal_code,
        pe_icon: end.pe_icon,
        value: end.pe_end_code,
        label: end.pe_name,
      }));
      setProductEnds(options);
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };

  //basis of claim
  const [basisOfClaim, setBasisOfClaim] = useState("");
  const [claimBasisOfSettlement, setClaimBasisOfSettlement] = useState([]);

  let isDocumentNumberSetup = false;
  const [confirmDialogAction, setConfirmDialogAction] = useState("None");
  const [receiptOpen, setReceiptOpen] = useState(false);
  const [selectedReceiptPaidTo, setSelectedReceiptPaidTo] = useState({
    label: "Paid To Us",
    value: "ToUs",
  });
  const [receiptPaidToOptions, setReceiptPaidToOptions] = useState([
    { label: "Paid To Us", value: "ToUs" },
    { label: "Paid To Insurance Co", value: "ToInsurance" },
    { label: "Paid To Sub Agent", value: "ToSubAgent" },
  ]);

  const [selectedReceiptMode, setSelectedReceiptMode] = useState({
    label: "Unspecified",
    value: "Unspecified",
  });

  const [receiptModeOptions, setReceiptModeOptions] = useState([]);
  const fetchReceiptMode = async () => {
    try {
      const res = await newRequest.post("/sysCode/findAll", {
        user_lang: "en_US",
        sys_org_code: currentUser.usr_org_code,
        sys_type: "Receipt Mode",
      });
      const options = res.data.map((mode) => ({
        value: mode.sys_code,
        label: mode.sys_name,
      }));
      setReceiptModeOptions(options);
    } catch (error) {
      console.error("Error fetching receipt modes :", error);
    }
  };


  const [policyClaimsList, setPolicyClaimsList] = useState([]);
  const fetchPolicyClaimsList = async (policyNo) => {
    try {

      const res = await newRequest.post("/cmClaim/findByPolicy", {
        cm_policy_no: policyNo,
      }, config);

      setPolicyClaimsList(res.data);
    } catch (error) {
      console.error("Error fetching policy claims list :", error);
    }
  };

  // receipt currency
  const [selectedReceiptCurrency, setSelectedReceiptCurrency] = useState({
    cur_code: currentUser.currency_code_xx,
    cur_name: currentUser.currency_name_xx,
    value: currentUser.currency_code_xx,
    label: currentUser.currency_name_xx,
  });
  const [receiptDate, setReceiptDate] = useState(new Date());
  const [receiptNo, setReceiptNo] = useState("");
  const [receiptAmount, setReceiptAmount] = useState(null);

  // receipt date
  const handleReceiptDateChange = (e) => {
    try {
      if (e.target.value) {
        const formattedFromDate = moment(e.target.value).format("YYYY-MM-DD");
        setReceiptDate(formattedFromDate);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddReceipt = async () => {
    try {
      // check nulls before proceeding

      if (!selectedReceiptPaidTo) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please select <b>PAID TO</b>`);
        setIsNotifyDialogOpen(true);
        return;
      }

      if (!selectedReceiptMode) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please select <b>RECEIPT MODE</b>`);
        setIsNotifyDialogOpen(true);
        return;
      }

      if (!selectedReceiptCurrency) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please select <b>RECEIPT CURRENCY</b>`);
        setIsNotifyDialogOpen(true);
        return;
      }

      if (!receiptNo) {
        if (selectedReceiptPaidTo.value !== "ToUs") {
          setNotifyDialogTitle("Note!");
          setNotifyDialogText(`Please enter <b>RECEIPT NUMBER</b>`);
          setIsNotifyDialogOpen(true);
          return;
        }
      }

      if (!receiptDate) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter <b>RECEIPT DATE</b>`);
        setIsNotifyDialogOpen(true);
        return;
      }

      if (!receiptAmount) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter <b>RECEIPT AMOUNT</b>`);
        setIsNotifyDialogOpen(true);
        return;
      }

      // check a document cannot be over matched
      if (receiptAmount > quoteData.total_os_fc_amount) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(
          `Total Outstanding is <b>${plCurCode.cur_code} ${numeral(
            quoteData.total_os_fc_amount
          ).format(
            "0,0.00"
          )}</b>. You CANNOT receipt more than this amount`
        );
        setIsNotifyDialogOpen(true);
        return;
      }

      if (selectedReceiptPaidTo.value === "ToUs") {
        // check if period is open
        const formattedGlDate = moment(receiptDate).format("YYYY-MM-DD");
        const res = await newRequest.post(
          "/glCp/periodStatus",
          {
            cp_org_code: currentUser.usr_org_code,
            cp_cy_type: "Financial",
            gl_date: formattedGlDate,
          }
        );
        if (res.data.cp_status === "Closed") {
          setIsConfirmDialogOpen(false);
          setConfirmDialogAction("None");
          setNotifyDialogTitle("Note!");
          setNotifyDialogText(
            `Accounting date <b>${moment(receiptDate).format(
              "DD-MMM-YYYY"
            )}</b> is within Financial period <b>${res.data.cp_code
            }</b>. The period is <b>${res.data.cp_status
            }</b>. Open the period to allow receipting.`
          );
          setIsNotifyDialogOpen(true);
          return;
        }

        const action_text = `Please note that Receipts <b>PAID TO US</b> will also be added in Finance. Do you want to proceed?`;
        setObjectToAction({
          object_id: quoteData._id,
          action_text: action_text,
        });
        setConfirmDialogAction("paidToUsReceipt");
        setIsConfirmDialogOpen(true);
      } else {
        createReceipt(null);
        // reset receipt fields
        setSelectedReceiptCurrency(null);
        setReceiptDate(new Date());
        setReceiptNo("");
        setReceiptAmount(null);
        setReceiptOpen(false);
        refreshQuote();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createReceipt = async (passedReceiptNo) => {
    try {
      // create receipt
      const res = await newRequest.post("/uwPolicy/receipt", {
        receipt_org_code: quoteData.pl_org_code,
        receipt_risk_note_no: quoteData.pl_risk_note_no,
        receipt_policy_docid: quoteData._id,
        receipt_no: passedReceiptNo || receiptNo,
        receipt_mode: selectedReceiptMode.value,
        receipt_date: receiptDate,
        receipt_currency: selectedReceiptCurrency.cur_code,
        receipt_fc_amount: receiptAmount,
        receipt_lc_amount: receiptAmount,
        receipt_paid_to: selectedReceiptPaidTo.value,
        userCode: currentUser.usr_id,
      });
      // add trace
      try {
        const trace_res = await newRequest.post("/trace/add", {
          trc_org_code: currentUser.usr_org_code,
          trc_action_on: "Document",
          trc_doc_type: quoteData.pl_type,
          trc_doc_no: quoteData.pl_risk_note_no,
          trc_aent_code: "",
          trc_ent_code: "",
          trc_action: `added receipt on ${quoteData.pl_type}`,
          trc_remarks: `added receipt ${receiptNo} of ${selectedReceiptCurrency.cur_code} ${receiptAmount}`,
          trc_tags: ["added receipt"],
          trc_doc_docid: quoteData._id,
          userCode: currentUser.usr_id,
        });
      } catch (error) {
        console.log(error);
      }

      // if receipt is paid to useEffect, add in finance
      if (selectedReceiptPaidTo.value === "ToUs") {
        try {
          //prepare data
          const arReceiptHeader = {
            hd_org_code: currentUser.usr_org_code,
            hd_no: passedReceiptNo || receiptNo,
            hd_narration: `Premium Receipt Issued by ${currentUser.usr_full_name} from Underwriting`,
            hd_ref_no: quoteData.pl_risk_note_no,
            hd_ac_no: "01161",
            hd_biz_unit: selectedBranch.value,
            hd_gl_date: receiptDate,
            hd_cur_code: selectedReceiptCurrency.cur_code,
            hd_cur_rate: plCurRate,
            hd_mode: selectedReceiptMode.value,
            hd_status: "Approved",
            hd_paying_for: "Premium",
            hd_aent_code: selectedInsured.ent_aent_code,
            hd_ent_code: selectedInsured.ent_code,
            hd_chq_no: "",
            hd_chq_bank: "",
            hd_chq_date: "",
            hd_fc_amount: receiptAmount,
            hd_lc_amount: receiptAmount,
            userCode: currentUser.usr_id,
          };

          const res = await newRequest.post(
            "/arReceiptHdr/add",
            arReceiptHeader
          );

          // add receipt line
          const resLine = await newRequest.post(
            "/arReceiptLn/add",
            {
              ln_org_code: currentUser.usr_org_code,
              ln_hd_no: passedReceiptNo || receiptNo,
              ln_hd_no_docid: res.data._id,
              ln_doc_type: "RiskNote",
              ln_doc_no: quoteData.pl_risk_note_no,
              ln_doc_docid: quoteData._id,
              ln_fc_amount: receiptAmount,
              ln_lc_amount: receiptAmount,
              ln_narration: "Matched",
              userCode: currentUser.usr_id,
            }
          );
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [summaryCurrentView, setSummaryCurrentView] = useState("summary");
  // confirm dialog
  const [objectToAction, setObjectToAction] = useState({
    object_id: "",
    action_text: "",
    new_doc_status: "",
  });
  const [invokedAction, setInvokedAction] = useState("");
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  // attachments
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [attachmentsData, setAttachmentsData] = useState([]);
  const fetchDocumentAttachments = async (docType, policyDocid) => {
    try {
      const res = await newRequest.post("/files/findDocFiles", {
        fl_org_code: currentUser.usr_org_code,
        fl_doc_type: docType,
        fl_parent_doc_id: policyDocid,
      });
      setAttachmentsData(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUploadCloseDialog = () => {
    setIsUploadDialogOpen(false);
  };



  // end of attachments

  // number generation
  const [numGeneration, setNumGeneration] = useState({});
  // get quote data
  const [quoteData, setQuoteData] = useState({});
  // let quoteData = {};
  const location = useLocation();
  const parts = location.pathname.split("/");
  // let quoteId = parts[parts.length - 1];

  const [quoteId, setQuoteId] = useState(parts[parts.length - 1]);
  const [docType, setDocType] = useState(parts[parts.length - 2]);

  const navigate = useNavigate();

  // close notify dialog
  const handleCloseNotifyDialog = () => {
    setIsNotifyDialogOpen(false);
  };

  // exit page
  const goBack = () => {
    navigate(-1);
  };


  //other quote information
  let passedDocType = "RiskNote";
  switch (docType) {
    case "rn":
      passedDocType = "RiskNote";
      break;
    case "qt":
      passedDocType = "Quote";
      break;
    default:
      passedDocType = "RiskNote";
  }

  const [plType, setPlType] = useState(passedDocType);
  let generatedDocNo = "";
  const [plNo, setPlNo] = useState("");
  const [plRiskNoteNo, setPlRiskNoteNo] = useState("");
  const [plQuoteNo, setPlQuoteNo] = useState("");
  const [plCurRate, setPlCurRate] = useState(1);
  const [plFmDate, setPlFmDate] = useState(null);
  const [plToDate, setPlToDate] = useState(null);
  const [plGlDate, setPlGlDate] = useState(null);
  const [daysDifference, setDaysDifference] = useState(0);
  const [daysDifferenceEnd, setDaysDifferenceEnd] = useState(0);
  const [plStatus, setPlStatus] = useState("New");
  const [plRemarks, setPlRemarks] = useState("");
  const [plRegnNo, setPlRegnNo] = useState("");
  const [documentEnabled, setDocumentEnabled] = useState("Y");
  const [saveEnabled, setSaveEnabled] = useState("Y");
  const [nextAction, setNextAction] = useState("None");
  const [plEndNo, setPlEndNo] = useState("");
  const [plEndCode, setPlEndCode] = useState("");
  const [plInternalEndCode, setPlInternalEndCode] = useState("");
  const [businessType, setBusinessType] = useState("New Business");

  const fetchQuote = async () => {
    try {
      // console.log(`quoteId  ${quoteId} and quoteData._id ${quoteData._id}`);
      if (quoteId === "new" && quoteData._id) {
        // quoteId = quoteData._id;
        setQuoteId(quoteData._id);
      }
      // console.log(`new quoteId  ${quoteId}`);

      const res = await newRequest.get(
        `/uwPolicy/findById/${quoteId}`,
        {
          org_code: currentUser.usr_org_code,
          docId: quoteId,
        }
      );

      setQuoteData(res.data);

      //set Other values not associated with dropdown

      if (res.data.pl_no) {
        setEnablePolicyNo(false)
        setPlNo(res.data.pl_no);
        await fetchPolicyClaimsList(res.data.pl_no);
      } else {
        setEnablePolicyNo(true)
      }

      if (res.data.pl_risk_note_no) {
        setPlRiskNoteNo(res.data.pl_risk_note_no);
      }

      if (res.data.pl_quote_no) {
        setPlQuoteNo(res.data.pl_quote_no);
      }

      if (res.data.pl_status) {
        setPlStatus(res.data.pl_status);
      }

      if (res.data.pl_cur_rate) {
        setPlCurRate(res.data.pl_cur_rate);
      }

      if (res.data.pl_gl_date) {
        const formattedGlDate = moment(res.data.pl_gl_date).format(
          "YYYY-MM-DD"
        );
        setPlGlDate(formattedGlDate);
      }

      if (res.data.pl_fm_date) {
        const formattedFmDate = moment(res.data.pl_fm_date).format(
          "YYYY-MM-DD"
        );
        setPlFmDate(formattedFmDate);
      }

      if (res.data.pl_to_date) {
        const formattedToDate = moment(res.data.pl_to_date).format(
          "YYYY-MM-DD"
        );
        setPlToDate(formattedToDate);
      }

      if (res.data.pl_fm_date && res.data.pl_to_date) {
        calculateDateDifference(res.data.pl_fm_date, res.data.pl_to_date);
      }

      if (res.data.pl_claim_basis) {
        setClaimBasisOfSettlement(res.data.pl_claim_basis);
      }

      if (res.data.pl_assr_ent_code) {
        const initialSelectedInsured = {
          ent_code: res.data.pl_assr_ent_code,
          ent_aent_code: res.data.pl_assr_aent_code,
          ent_name: res.data.insured_name_xx,
          value: res.data.pl_assr_ent_code,
          label: res.data.insured_name_xx,
        };
        setSelectedInsured(initialSelectedInsured);
      }

      if (res.data.pl_ins_ent_code) {
        const initialSelectedCarrier = {
          ent_code: res.data.pl_ins_ent_code,
          ent_aent_code: res.data.pl_ins_aent_code,
          ent_name: res.data.carrier_name_xx,
          value: res.data.pl_ins_ent_code,
          label: res.data.carrier_name_xx,
        };
        setSelectedCarrier(initialSelectedCarrier);
      }

      if (res.data.pl_product_code) {
        const initialSelectedProduct = {
          pr_code: res.data.pl_product_code,
          pr_name: res.data.product_name_xx,
          pr_period_type: res.data.product_period_type_xx,
          pr_cash_carry: res.data.product_cash_carry_xx,
          pr_onetime: res.data.product_onetime_xx,
          pr_motor: res.data.product_motor_xx,
          value: res.data.pl_product_code,
          label: res.data.product_name_xx,
        };

        // UW1005 - User can create entity from underwriting
        // UW1008 - User allowed to change commission rate
        // UW1010 - User allowed to add receipt
        // UW1021 - User can override exchange rate
        // get profile limit values
        const limits = await newRequest.post("/adProfile/checkValues", { module_code: res.data.pl_product_code, limit_codes: limitsToCheck }, config);
        setProfileLimitValues(limits.data)
        setSelectedProduct(initialSelectedProduct);
      }

      if (res.data.pl_cur_code) {
        const initialplCurCode = {
          value: res.data.pl_cur_code,
          label: res.data.currency_xx,
        };
        setPlCurCode(initialplCurCode);
      }

      if (res.data.pl_biz_unit) {
        const initialSelectedBranch = {
          unit_code: res.data.pl_biz_unit,
          unit_name: res.data.bizunit_name_xx,
          unit_type: res.data.bizunit_type_xx,
          value: res.data.pl_biz_unit,
          label: res.data.bizunit_name_xx,
        };
        setSelectedBranch(initialSelectedBranch);
      }

      if (res.data.pl_quote_stage) {
        const initialSelectedStage = {
          sys_code: res.data.pl_quote_stage,
          sys_name: res.data.quote_stage_xx,
          value: res.data.pl_quote_stage,
          label: res.data.quote_stage_xx,
        };
        setSelectedQuoteStage(initialSelectedStage);
      }

      setPlRemarks(res.data.pl_remarks);
      setPlRegnNo(res.data.pl_regn_no);
      setDocumentEnabled(res.data.document_enabled_xx);
      setSaveEnabled(res.data.save_enabled_xx);
      console.log(`setting next action to ${res.data.next_action_xx}`)
      setNextAction(res.data.next_action_xx);
      setPlEndNo(res.data.pl_end_no);
      setPlEndCode(res.data.pl_end_code);
      setPlInternalEndCode(res.data.end_internal_code_xx);
      setBusinessType(res.data.end_name_xx);
      setPlType(res.data.pl_type);
      // fetch attachements
      fetchDocumentAttachments(res.data.pl_type, res.data._id);
      fetchSpecialNotes(res.data._id, "Special");
    } catch (err) {
      console.log(err);
    } finally {

    }
  };

  // fetch number generation code
  const fetchNumberGeneration = async () => {
    try {

      const res = await newRequest.post("/orgNumgen/find", {
        gen_org_code: currentUser.usr_org_code,
        gen_type: plType,
      }, config);
      setNumGeneration(res.data);
      isDocumentNumberSetup = true;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    // fetch number generation to check if its auto or manual
    fetchNumberGeneration();
    //if quote is selected, use quoteId to get quote details
    //if its new record, no fetch is necessary
    if (quoteId !== "new") {
      fetchQuote();
    } else {
      const currentDate = new Date();
      const oneYearLater = new Date(currentDate);
      oneYearLater.setFullYear(currentDate.getFullYear() + 1);
      // Subtract one day from the next year's date
      oneYearLater.setDate(oneYearLater.getDate() - 1);

      // Formatting date to match the input type "date" format (YYYY-MM-DD)
      const formattedCurrentDate = currentDate.toISOString().split("T")[0];
      const formattedOneYearLater = oneYearLater.toISOString().split("T")[0];

      setPlGlDate(formattedCurrentDate);
      setPlFmDate(formattedCurrentDate);
      setPlToDate(formattedOneYearLater);

      const fmDate = new Date(formattedCurrentDate);
      const toDate = new Date(formattedOneYearLater);
      calculateDateDifference(fmDate, toDate);


      setPlEndNo("0");
      setPlEndCode("000");
      setPlInternalEndCode("000");
    }
    fetchCarriers();
    // fetchInsureds();
    fetchProducts();
    fetchCurrencies();
    fetchBranches();
    fetchQuoteStages();
    fetchReceiptMode();
  }, [quoteId]);

  useEffect(() => {
    if (vehicleDialogOpen) {
      // Focus the input field when the component mounts
      vehicleInputRef.current.focus();
    }
  }, [vehicleDialogOpen]);

  // insureds
  const [selectedInsured, setSelectedInsured] = useState(null);
  const fetchEntityBySearch = async (inputValue, callback) => {
    try {
      const res = await newRequest.post(
        "/allEntity/findBySearch",
        {
          ent_org_code: currentUser.usr_org_code,
          search_input: inputValue,
        },
        config
      );
      const options = res.data.map((entity) => ({
        ent_photo_url: entity?.ent_photo_url,
        ent_status: entity?.ent_status,
        aent_name_xx: entity?.aent_name_xx,
        ent_code: entity.ent_code,
        ent_aent_code: entity.ent_aent_code,
        ent_name: entity.ent_name,
        value: entity.ent_code,
        label: entity.ent_name,
      }));

      if (res.data.length === 0) {
        // No results found
        const noOptionsError = { message: "No Results found." };
        throw noOptionsError;
      }

      callback(options);
    } catch (error) {
      console.error("Error fetching entity types:", error);
      callback([]);
    }
  };

  // insurance company
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [carriers, setCarriers] = useState([]);
  const fetchCarriers = async () => {
    try {

      const res = await newRequest.post("/allEntity/findAll", {
        ent_org_code: currentUser.usr_org_code,
      }, config);

      // Transform the data to match the format expected by react-select
      const options = res.data.map((entity) => ({
        ent_code: entity.ent_code,
        ent_aent_code: entity.ent_aent_code,
        ent_name: entity.ent_name,
        value: entity.ent_code,
        label: entity.ent_name,
      }));

      setCarriers(options);
      //set default values
      if (quoteData) {
        const initialSelectedCarrier = {
          ent_code: quoteData.pl_ins_ent_code,
          ent_aent_code: quoteData.pl_ins_aent_code,
          ent_name: quoteData.carrier_name_xx,
          value: quoteData.pl_assr_ent_code,
          label: quoteData.carrier_name_xx,
        };

        setSelectedCarrier(initialSelectedCarrier);
      }
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };

  // products
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const fetchProducts = async () => {
    try {

      const res = await newRequest.post("/uwProduct/findAll", {}, config);

      // Transform the data to match the format expected by react-select
      const options = res.data.map((product) => ({
        pr_code: product.pr_code,
        pr_name: product.pr_name,
        pr_period_type: product.pr_period_type,
        pr_cash_carry: product.pr_cash_carry,
        pr_onetime: product.pr_onetime,
        pr_motor: product.pr_motor,
        value: product.pr_code,
        label: product.pr_name,
      }));

      setProducts(options);
      //set default values
      if (quoteData) {
        const initialSelectedProduct = {
          pr_code: quoteData.pl_product_code,
          pr_name: quoteData.product_name_xx,
          pr_period_type: quoteData.product_period_type_xx,
          pr_cash_carry: quoteData.product_cash_carry_xx,
          pr_onetime: quoteData.product_onetime_xx,
          pr_motor: quoteData.product_motor_xx,
          value: quoteData.pl_product_code,
          label: quoteData.product_name_xx,
        };

        // UW1005 - User can create entity from underwriting
        // UW1008 - User allowed to change commission rate
        // UW1010 - User allowed to add receipt
        // UW1021 - User can override exchange rate
        // get profile limit values
        const limits = await newRequest.post("/adProfile/checkValues", { module_code: quoteData.pl_product_code, limit_codes: limitsToCheck }, config);
        setProfileLimitValues(limits.data)

        setSelectedProduct(initialSelectedProduct);
      }
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };

  // Function to get limit_yn value by limit_code
  const getLimitValue = (limitCode) => {
    try {
      // console.log(`getLimitValue profileLimitValues ${JSON.stringify(profileLimitValues, null, 2)}`);
      const limit = profileLimitValues.find(l => l.limit_code === limitCode);
      return limit ? limit.limit_yn : null;
    } catch (error) {
      console.log(error)
    }
  };

  // currency
  const [plCurCode, setPlCurCode] = useState(null);
  const [currencies, setCurrencies] = useState([]);
  const fetchCurrencies = async () => {
    try {

      const res = await newRequest.post("/glCurrency/findAll", {}, config);

      // Transform the data to match the format expected by react-select
      const options = res.data.map((currency) => ({
        cur_code: currency.cur_code,
        cur_name: currency.cur_name,
        value: currency.cur_code,
        label: currency.cur_name,
      }));

      setCurrencies(options);
      //set default values
      if (quoteData) {
        const initialplCurCode = {
          value: quoteData.pl_cur_code,
          label: quoteData.currency_xx,
        };
        setPlCurCode(initialplCurCode);
      }

      if (quoteId === "new") {
        // set default currency
        const defaultCurrency = {
          value: currentUser.currency_code_xx,
          label: currentUser.currency_name_xx,
        };
        setPlCurCode(defaultCurrency);
      }
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };

  // biz unit
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [branches, setBranches] = useState([]);
  const fetchBranches = async () => {
    try {
      const res = await newRequest.post(
        "/orgUnit/findAll",
        {
          unit_org_code: currentUser.usr_org_code,
          unit_status: "Active"
        },
        config
      );

      // console.log(`orgUnit  ${JSON.stringify(res.data, null, 2)}`);
      // Transform the data to match the format expected by react-select
      const options = res.data.map((branch) => ({
        unit_code: branch.unit_code,
        unit_name: branch.unit_name,
        unit_type: branch.unit_type,
        value: branch.unit_code,
        label: branch.unit_name,
      }));

      setBranches(options);
      //set default values
      if (quoteData) {
        const initialSelectedBranch = {
          unit_code: quoteData.pl_biz_unit,
          unit_name: quoteData.bizunit_name_xx,
          unit_type: quoteData.bizunit_type_xx,
          value: quoteData.pl_biz_unit,
          label: quoteData.bizunit_name_xx,
        };
        setSelectedBranch(initialSelectedBranch);
      }
      if (quoteId === "new") {
        // set default branch
        const defaultBranch = {
          unit_code: currentUser.biz_unit_code_xx,
          unit_name: currentUser.biz_unit_name_xx,
          unit_type: currentUser.biz_unit_type_xx,
          value: currentUser.biz_unit_code_xx,
          label: currentUser.biz_unit_name_xx,
        };
        setSelectedBranch(defaultBranch);
      }
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };

  // quote stage
  const [selectedQuoteStage, setSelectedQuoteStage] = useState(null);
  const [quoteStages, setQuoteStages] = useState([]);
  const fetchQuoteStages = async () => {
    try {

      const res = await newRequest.post("/sysCode/findAll", {
        user_lang: "en_US",
        sys_org_code: currentUser.usr_org_code,
        sys_type: "Quote Stages",
      }, config);
      // console.log(`found quoteStages  ${JSON.stringify(res.data, null, 2)}`);
      // Transform the data to match the format expected by react-select
      const options = res.data.map((stage) => ({
        sys_code: stage.sys_code,
        sys_name: stage.sys_name,
        value: stage.sys_code,
        label: stage.sys_name,
      }));

      setQuoteStages(options);
      //set default values
      if (quoteData) {
        const initialSelectedStage = {
          sys_code: quoteData.pl_quote_stage,
          sys_name: quoteData.quote_stage_xx,
          value: quoteData.pl_quote_stage,
          label: quoteData.quote_stage_xx,
        };

        setSelectedQuoteStage(initialSelectedStage);
      }
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };

  // deal with dates
  const handleGlDateChange = async (e) => {
    try {
      if (e.target.value) {
        const formattedGlDate = moment(e.target.value).format("YYYY-MM-DD");
        setPlGlDate(formattedGlDate);
        if (plCurCode?.value !== currentUser.currency_code_xx) {
          const curRate = {
            rate_org_code: currentUser.usr_org_code,
            rate_cur_code_fm: plCurCode.cur_code,
            rate_cur_code_to: currentUser.currency_code_xx,
            rate_date: formattedGlDate,
          };
          // console.log(`curRate  ${JSON.stringify(curRate, null, 2)}`);
          // pass endorsement
          const end_res = await newRequest.post(
            "/glCurrencyRate/find",
            curRate
          );
          // console.log(`end_res  ${JSON.stringify(end_res.data, null, 2)}`);
          if (end_res.data) {
            setPlCurRate(end_res.data.rate);
          } else {
            setPlCurCode({
              value: currentUser.currency_code_xx,
              label: currentUser.currency_name_xx,
            });
            setPlCurRate(1);
            setNotifyDialogTitle("Note!");
            setNotifyDialogText(
              `Conversion Rate from <b>${plCurCode?.value} to ${currentUser.currency_code_xx
              } for date ${moment(e.target.value).format(
                "DD-MMM-YYYY"
              )}</b> NOT found. Currency will reset to <b>${currentUser.currency_code_xx
              }</b>`
            );
            setIsNotifyDialogOpen(true);
          }
        } else {
          setPlCurRate(1);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFromDateChange = (e) => {
    try {
      if (e.target.value) {
        const formattedFromDate = moment(e.target.value).format("YYYY-MM-DD");
        setPlFmDate(formattedFromDate);
        calculateDateDifference(plFmDate, plToDate);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleToDateChange = (e) => {
    try {
      if (e.target.value) {
        const formattedToDate = moment(e.target.value).format("YYYY-MM-DD");
        setPlToDate(formattedToDate);
        calculateDateDifference(plFmDate, plToDate);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFromDateBlur = () => {
    try {
      if (plFmDate && plToDate) {
        // console.log(`plFmDate: ${plFmDate}`);
        // Parse plFmDate to a Date object
        const fromDate = new Date(plFmDate);
        // Add one year to the date
        const nextYearDate = new Date(fromDate);
        nextYearDate.setFullYear(nextYearDate.getFullYear() + 1);
        // Subtract one day from the next year's date
        nextYearDate.setDate(nextYearDate.getDate() - 1);
        // Format the new date to YYYY-MM-DD
        const formattedNextYearDate = nextYearDate.toISOString().split('T')[0];
        // console.log(`Next year date: ${formattedNextYearDate}`);
        setPlToDate(formattedNextYearDate);
        const toDate = new Date(formattedNextYearDate);
        calculateDateDifference(fromDate, toDate);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const handleToDateBlur = () => {
    try {
      if (plFmDate && plToDate) {
        const fromDate = new Date(plFmDate);
        const toDate = new Date(plToDate);
        calculateDateDifference(fromDate, toDate);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const calculateDateDifference = (plFmDate, plToDate) => {
    try {
      if (plFmDate && plToDate) {
        const fromDate = moment(plFmDate, "YYYY-MM-DD");
        const toDate = moment(plToDate, "YYYY-MM-DD");
        const differenceInDays = toDate.diff(fromDate, "days");
        setDaysDifference(differenceInDays + 1);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const calculateDateDifferenceEnd = (plFmDate, plToDate) => {
    try {
      if (plFmDate && plToDate) {
        const fromDate = moment(plFmDate, "YYYY-MM-DD");
        const toDate = moment(plToDate, "YYYY-MM-DD");
        const differenceInDays = toDate.diff(fromDate, "days");
        setDaysDifferenceEnd(differenceInDays + 1);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const refreshQuote = async () => {
    // refresh charges and commission
    try {
      setIsProcessing(true)
      if (quoteId === "new" && quoteData._id !== "new") {
        // quoteId = quoteData._id;
        console.log(`Refreshing Quote id ${quoteData._id}`)
        setQuoteId(quoteData._id);
      }
      const res = await newRequest.post("/uwPolicy/refresh", {
        policy_docid: quoteId,
        userCode: currentUser.usr_id,
      }, config);

      //log the event here

      try {
        const trace_res = await newRequest.post(
          "/trace/add",
          {
            trc_org_code: currentUser.usr_org_code,
            trc_action_on: "Document",
            trc_doc_type: quoteData.pl_type,
            trc_doc_no: quoteData.pl_risk_note_no,
            trc_aent_code: "",
            trc_ent_code: "",
            trc_action: "calculated premium",
            trc_remarks: "calculated premium",
            trc_tags: ["calculated premium"],
            trc_doc_docid: quoteData._id,
            userCode: currentUser.usr_id,
          },
          config
        );
      } catch (error) {
        console.log(error);
      }

      fetchQuote();
    } catch (err) {
      console.log(err);
    } finally {
      setIsProcessing(false)
    }
  };

  // generate document number
  const generateDocumentNo = async () => {
    try {
      // console.log(`plType is ${plType}`);
      const res = await newRequest.post(
        "/orgNumgen/gen",
        {
          gen_org_code: currentUser.usr_org_code,
          gen_type: plType,
        },
        config
      );

      generatedDocNo = res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const saveRecord = async (e) => {
    // e.preventDefault();
    try {

      if (!selectedProduct?.value) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Select Product!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      if (!selectedInsured?.value) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Select Insured!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      const formattedFmDate = new Date(moment(plFmDate).format("YYYY-MM-DD"));
      formattedFmDate.setHours(0, 0, 0, 0);

      const formattedToDate = new Date(
        moment(plToDate).format("YYYY-MM-DD")
      );
      formattedToDate.setHours(0, 0, 0, 0);
      const today = new Date();

      if (formattedFmDate > formattedToDate) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(
          `Your dates are Invalid. From date <b>${moment(plFmDate).format(
            "DD-MMM-YYYY"
          )}</b> cannot be greater than expiry date <b>${moment(
            plToDate
          ).format("DD-MMM-YYYY")}</b>.<br><br>Please rectify!`
        );
        setIsNotifyDialogOpen(true);
        return;
      }

      saveRisksInParent();

      // save new quote
      setSavingTitle("saving...");
      setIsProcessing(true);
      // Simulate delay for 5 seconds
      // await new Promise((resolve) => setTimeout(resolve, 5000));

      let uwPolicy = {};
      if (quoteId === "new") {
        // const isEmpty = Object.keys(numGeneration).length === 0;
        console.log(
          `Is Quote No Entered plRiskNoteNo ? ${JSON.stringify(
            plRiskNoteNo,
            null,
            2
          )}`
        );
        if (!plRiskNoteNo) {
          console.log("generating risk note no");
          // generate quote number
          await generateDocumentNo();
        }

        //prepare data
        uwPolicy = {
          pl_org_code: currentUser.usr_org_code,
          pl_risk_note_no: plRiskNoteNo || generatedDocNo,
          pl_primary_doc: plRiskNoteNo || generatedDocNo,
          pl_quote_no: plQuoteNo,
          pl_quote_version: 1,
          pl_quote_stage: selectedQuoteStage.sys_code,
          pl_no: plNo,
          pl_end_no: plEndNo,
          pl_end_code: plEndCode,
          pl_type: plType,
          pl_status: plStatus,
          pl_product_code: selectedProduct.pr_code,
          pl_biz_unit: selectedBranch.unit_code,
          pl_cur_code: plCurCode?.value,
          pl_cur_rate: plCurRate,
          pl_gl_date: plGlDate,
          pl_fm_date: plFmDate,
          pl_to_date: plToDate,
          pl_onetime: "N",
          pl_assr_aent_code: selectedInsured.ent_aent_code,
          pl_assr_ent_code: selectedInsured.ent_code,
          pl_ins_aent_code: selectedCarrier?.ent_aent_code,
          pl_ins_ent_code: selectedCarrier?.ent_code,
          pl_remarks: plRemarks,
          pl_regn_no: plRegnNo?.toUpperCase(),
          pl_assr_entity_name: selectedInsured?.label,
          pl_int_entity_name: selectedCarrier?.label,
          userCode: currentUser.usr_id,
        };

        const res = await newRequest.post("/uwPolicy/add", uwPolicy, config);

        //quoteId = res.data._id;
        setQuoteId(res.data._id);
        setQuoteData(res.data);
        setPlQuoteNo(generatedDocNo);
        setSavingTitle("Save");
        setIsProcessing(false);
        setNotifyDialogTitle("Success!");
        setNotifyDialogText(`${quoteData.pl_type} successfully saved`);
      } else {
        //prepare data
        uwPolicy = {
          pl_no: plNo,
          pl_quote_stage: selectedQuoteStage.sys_code,
          pl_status: quoteData.pl_status,
          pl_product_code: selectedProduct.pr_code,
          pl_biz_unit: selectedBranch.unit_code,
          pl_cur_code: plCurCode?.value,
          pl_cur_rate: plCurRate,
          pl_gl_date: plGlDate,
          pl_fm_date: plFmDate,
          pl_to_date: plToDate,
          pl_assr_aent_code: selectedInsured.ent_aent_code,
          pl_assr_ent_code: selectedInsured.ent_code,
          pl_ins_aent_code: selectedCarrier?.ent_aent_code,
          pl_ins_ent_code: selectedCarrier?.ent_code,
          pl_remarks: plRemarks,
          pl_regn_no: plRegnNo?.toUpperCase(),
          pl_assr_entity_name: selectedInsured?.label,
          pl_int_entity_name: selectedCarrier?.label,
          userCode: currentUser.usr_id,
        };

        const res = await newRequest.put(
          `/uwPolicy/upd/${quoteId}`,
          uwPolicy,
          config
        );
      }

      setNotifyDialogTitle("Success!");
      setNotifyDialogText(`${plType} successfully updated`);
      setIsNotifyDialogOpen(true);
    } catch (err) {
      console.log(err);
    } finally {
      setSavingTitle("Save");
      setIsProcessing(false);
    }
  };

  const chargesColumns = [
    { header: "Charge", key: "charge_name", align: "left", width: "auto" },
    { header: "Code", key: "charge_code", align: "left", width: "100px" },
    { header: "Basis", key: "charge_basis", align: "left", width: "100px" },
    {
      header: "Rate",
      key: "charge_rate",
      align: "right",
      width: "150px",
    },
    {
      header: "Amount",
      key: "charge_fc_amount",
      align: "right",
      width: "100px",
    },
  ];

  const handleChargeInputChange = (index, key, value) => {
    // Create a shallow copy of the array
    const updatedData = [...quoteData.policy_charges];
    // Update the value in the copied data
    updatedData[index][key] = value;
    // Update the state with the modified data
    //setPolicyRiskCoverSmi(updatedData);
  };

  const formatNumber = (number, decimalPlaces) => {
    return Number(number).toLocaleString(undefined, {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    });
  };

  const handleConfettiClick = () => {
    setIsConfettiActive(true);
    // Set a timeout to stop the confetti after a few seconds (optional)
    setTimeout(() => {
      setIsConfettiActive(false);
    }, 5000); // Stop confetti after 3 seconds
  };

  const handleChargeBlur = (index, key) => {
    // Format the number and update the state
    const updatedData = [...quoteData.policy_charges];
    let decimalPlaces = 0;
    if (key === "smi_si_fc") {
      decimalPlaces = 2;
    } else if (key === "smi_premium_fc") {
      decimalPlaces = 2;
    } else if (key === "smi_rate") {
      decimalPlaces = 4;
    } else if (key === "smi_rate_per") {
      decimalPlaces = 2;
    } else {
      decimalPlaces = 0;
    }

    if (key !== "smi_name_xx") {
      updatedData[index][key] = formatNumber(
        updatedData[index][key],
        decimalPlaces
      );
    }
    //setPolicyRiskCoverSmi(updatedData);
  };


  const handleConfirm = async () => {
    try {
      if (confirmDialogAction === "policyAction") {
        // do all necessary checks
        // update status
        // console.log(`policyAction  ${objectToAction.new_doc_status}`);

        if (["Approved"].includes(objectToAction.new_doc_status)) {
          // check if period is open
          const formattedGlDate = moment(plGlDate).format("YYYY-MM-DD");
          const res = await newRequest.post(
            "/glCp/periodStatus",
            {
              cp_org_code: currentUser.usr_org_code,
              cp_cy_type: "Underwriting",
              gl_date: formattedGlDate,
            },
            config
          );
          if (res.data.cp_status === "Closed") {
            setIsConfirmDialogOpen(false);
            setConfirmDialogAction("None");
            setNotifyDialogTitle("Note!");
            setNotifyDialogText(
              `Accounting date <b>${moment(plGlDate).format(
                "DD-MMM-YYYY"
              )}</b> is within Underwriting period <b>${res.data.cp_code
              }</b>. The period is <b>${res.data.cp_status
              }</b>. Open the period to allow approval.`
            );
            setIsNotifyDialogOpen(true);
            return;
          }
        }

        // show endorsement view
        if (objectToAction.new_doc_status === "Endorsed") {
          // set default dates
          const formattedFmDate = moment(new Date(Date.now())).format(
            "YYYY-MM-DD"
          );
          const formattedToDate = moment(plToDate).format(
            "YYYY-MM-DD"
          );

          setEndorsementGlDate(formattedFmDate);
          setEndorsementFmDate(formattedFmDate);
          setEndorsementToDate(formattedToDate);

          fetchProductEnds();
          setEndDialogOpen(true);
        } else {

          if (objectToAction.new_doc_status === "Validated") {
            // console.log(".......Run Policy Validation Here...");
            const validateResponse = await newRequest.post(
              "/uwPolicy/validate",
              {
                user_id: currentUser.usr_id,
                policy_docid: quoteData._id,
              },
              config
            );
            // console.log(`validateResponse.data  ${JSON.stringify(validateResponse.data, null, 2)}`);
            if (validateResponse.data.result_status === "error") {
              // <ValidationMessages messageData={validateResponse.data} />
              setValidationMessagesResult(validateResponse.data);
              setIsConfirmDialogOpen(false);
              setValidationMsgDialog(true);
              return;
            }
          }

          const res = await newRequest.post(
            "/uwPolicy/status",
            {
              userCode: currentUser.usr_id,
              pl_status: objectToAction.new_doc_status === "Approved" ? "Active" : objectToAction.new_doc_status,
              policy_docid: quoteData._id,
            },
            config
          );

          try {
            const trace_res = await newRequest.post(
              "/trace/add",
              {
                trc_org_code: currentUser.usr_org_code,
                trc_action_on: "Document",
                trc_doc_type: quoteData.pl_type,
                trc_doc_no: quoteData.pl_risk_note_no,
                trc_aent_code: "",
                trc_ent_code: "",
                trc_action: "updated document status",
                trc_remarks: `updated ${quoteData.pl_type} to ${objectToAction.new_doc_status}`,
                trc_tags: ["added cover"],
                trc_doc_docid: quoteData._id,
                userCode: currentUser.usr_id,
              },
              config
            );
          } catch (error) {
            console.log(error);
          }

          // if new status us approved, insert into endorsements table
          // console.log(`new_doc_status is ${objectToAction.new_doc_status} and plEndNo is ${plEndNo} and plNo is ${plNo}`);
          if (
            objectToAction.new_doc_status === "Approved" &&
            plEndNo === 0
          ) {
            try {
              const end_res = await newRequest.post(
                "/uwPolicyEnd/add",
                {
                  pe_org_code: quoteData.pl_org_code,
                  pe_risk_note_no: quoteData.pl_risk_note_no,
                  pe_policy_docid: quoteData._id,
                  pe_policy_no: plNo,
                  pe_end_no: quoteData.pl_end_no,
                  pe_doc_type: quoteData.pl_type,
                  pe_end_code: quoteData.pl_end_code,
                  pe_pr_code: quoteData.pl_product_code,
                  pe_gl_date: quoteData.pl_gl_date,
                  pe_fm_date: quoteData.pl_fm_date,
                  pe_to_date: quoteData.pl_to_date,
                  pe_status: "Approved",
                  pe_remarks: `new business endorsement ${quoteData.pl_end_no}`,
                  userCode: currentUser.usr_id,
                },
                config
              );
              // console.log(`end_res  ${JSON.stringify(end_res.data, null, 2)}`);
              handleConfettiClick();
              setConfirmDialogAction("None");
              setNotifyDialogTitle("Success!");
              setNotifyDialogText(
                `${quoteData.end_name_xx} successfully updated to ${objectToAction.new_doc_status}`
              );
            } catch (error) {
              console.log(error);
            }
          } else if (
            objectToAction.new_doc_status === "Approved" &&
            quoteData.pl_end_no > 0
          ) {
            const end_res = await newRequest.post(
              "/uwPolicyEnd/upd",
              {
                pe_org_code: quoteData.pl_org_code,
                pe_policy_docid: quoteData._id,
                pe_status: "Approved",
                updatedBy: currentUser.usr_id,
              },
              config
            );

            // merge details from previous document

            const mg_res = await newRequest.post(
              "/uwPolicy/mg",
              {
                policy_docid: quoteData._id
              }
            );

            setConfirmDialogAction("None");
            setNotifyDialogTitle("Success!");
            setNotifyDialogText(
              `${quoteData.end_name_xx} successfully approved`
            );
          } else {
            setConfirmDialogAction("None");
            setNotifyDialogTitle("Success!");
            setNotifyDialogText(
              `${quoteData.end_name_xx} successfully updated to ${objectToAction.new_doc_status}`
            );
          }

          fetchQuote();
          setIsNotifyDialogOpen(true);
        }
      } else if (confirmDialogAction === "removeReceipt") {
        // unmatch receipt
        try {
          const res = await newRequest.delete(
            `/uwPolicyReceipt/del/${objectToAction.object_id}`
          );
          fetchQuote();
          showToast("Receipt Unmatched!", "success");
        } catch (error) {
          console.log(error);
        }

        setConfirmDialogAction("None");
      } else if (confirmDialogAction === "removeClaimBasis") {
        // console.log(`remove claim basis  ${objectToAction.object_id}`);
        // remove claim basis
        try {
          const res = await newRequest.post(
            "/uwPolicy/claimBasisDel",
            {
              policy_docid: quoteData._id,
              basis_docid: objectToAction.object_id,
            }
          );
          fetchQuote();
          showToast("Removed!", "success");
        } catch (error) {
          console.log(error);
        }

        setConfirmDialogAction("None");
      } else if (confirmDialogAction === "removeSpecialNote") {
        // console.log(`remove claim basis  ${objectToAction.object_id}`);
        // remove claim basis
        try {
          const res = await newRequest.delete(`/allDocsNote/del/${objectToAction.object_id}`);
          fetchSpecialNotes(quoteData._id, "Special");
          showToast("Removed!", "success");
        } catch (error) {
          console.log(error);
        }

        setConfirmDialogAction("None");
      } else if (confirmDialogAction === "paidToUsReceipt") {
        // generate receipt no
        const params = {
          gen_org_code: currentUser.usr_org_code,
          gen_type: "Receipt",
        };
        const res = await newRequest.post("/orgNumgen/gen", params, config);
        const generatedReceiptNo = res.data;
        setReceiptNo(generatedReceiptNo);

        createReceipt(generatedReceiptNo);
        // reset receipt fields
        setSelectedReceiptCurrency(null);
        setReceiptDate(new Date());
        setReceiptNo("");
        setReceiptAmount(null);
        setReceiptOpen(false);
        refreshQuote();
      } else if (confirmDialogAction === "invalidatePolicy") {
        const res = await newRequest.post(
          "/uwPolicy/status",
          {
            userCode: currentUser.usr_id,
            pl_status: objectToAction.new_doc_status,
            policy_docid: quoteData._id,
          }
        );

        try {
          const trace_res = await newRequest.post(
            "/trace/add",
            {
              trc_org_code: currentUser.usr_org_code,
              trc_action_on: "Document",
              trc_doc_type: quoteData.pl_type,
              trc_doc_no: quoteData.pl_risk_note_no,
              trc_aent_code: "",
              trc_ent_code: "",
              trc_action: "updated document status",
              trc_remarks: `updated ${quoteData.pl_type} to ${objectToAction.new_doc_status}`,
              trc_tags: ["updated document status"],
              trc_doc_docid: quoteData._id,
              userCode: currentUser.usr_id,
            }
          );
        } catch (error) {
          console.log(error);
        }
        fetchQuote();
        showToast(`Status changed to ${objectToAction.new_doc_status}`, "success");
      }

    } catch (err) {
      console.log(err);
    }
    // Close the dialog
    setIsConfirmDialogOpen(false);
  };

  const handleCancel = () => {
    setIsConfirmDialogOpen(false);
  };

  // check vehicle registration
  const handleVehicleCheck = async () => {
    try {

      if (!vehicleDialogRegnNo) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter registration number`);
        setIsNotifyDialogOpen(true);
        return;
      }


      if (!validateMotorVehicleRegn(vehicleDialogRegnNo)) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Invalid registration number <b>${vehicleDialogRegnNo.toUpperCase()}</b><br><br>Ensure you use pattern like AAA000A. E.g KDM100Q`);
        setIsNotifyDialogOpen(true);
        return;
      }


      if (vehicleDialogRegnNo) {
        setVehicleResultView("None");
        setVehicleProceedButton(false);
        setVehicleCheckTitle("CHECKING...");

        // Simulate delay for 5 seconds
        // await new Promise((resolve) => setTimeout(resolve, 5000));

        const res = await newRequest.post(
          "/uwPolicyRisk/checkVeh",
          {
            pr_org_code: currentUser.usr_org_code,
            vehicle_regn: vehicleDialogRegnNo,
            userCode: currentUser.usr_id,
          },
          config
        );

        if (res.data === "VehicleNotFound") {
          setVehicleResultView("NoVehicleFound");
          setVehicleProceedButton(true);
        } else {
          setVehicleMatchingRisks(res.data);
          setVehicleResultView("VehicleFound");
          setVehicleProceedButton(false);
        }
        setVehicleCheckTitle("COMPLETE");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleVehicleCheckProceed = async () => {
    try {
      setPlRegnNo(vehicleDialogRegnNo);
      setVehicleDialogRegnNo("");
      setVehicleResultView("None");
      setVehicleProceedButton(false);
      setVehicleCheckTitle("CHECK");
      setVehicleDialogOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleVehicleChangePage = (event, newPage) => {
    setVehiclePage(newPage);
  };
  // add claim basis

  const handleAddClaimBasis = async () => {
    try {
      if (basisOfClaim) {
        try {
          const res = await newRequest.post(
            "/uwPolicy/claimBasisAdd/",
            {
              policy_docid: quoteData._id,
              basis_text: basisOfClaim,
              userCode: currentUser.usr_id,
            },
            config
          );
          // console.log(`claimBasis  ${JSON.stringify(res.data, null, 2)}`);

          // refresh list
          const getBasis = await newRequest.post(
            "/uwPolicy/claimBasisGet/",
            {
              policy_docid: quoteData._id,
            },
            config
          );
          setClaimBasisOfSettlement(getBasis.data);
          showToast("Added!", "success");
        } catch (error) {
          console.log(error);
        }
      }
      setBasisOfClaim("");
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddSpecialNote = async () => {
    try {
      if (specialNote) {
        try {
          const res = await newRequest.post(
            "/allDocsNote/add/",
            {
              note_org_code: currentUser.usr_org_code,
              note_doc_type: "RiskNote",
              note_docid: quoteData._id,
              note_text: specialNote,
              note_tags: [currentUser.usr_id],
              note_type: "Special",
              userCode: currentUser.usr_id,
            }
          );
          // console.log(`claimBasis  ${JSON.stringify(res.data, null, 2)}`);
          await fetchSpecialNotes(quoteData._id, "Special");
          showToast("Added!", "success");
        } catch (error) {
          console.log(error);
        }
      }
      setSpecialNote("");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      // for hiding options
      if (actionsRef.current && !actionsRef.current.contains(event.target)) {
        setOpenOptions(false);
      }

      if (expiringInRef.current && !expiringInRef.current.contains(event.target)) {
        setExpiringInMenuOpen(false);
      }

      // for hiding receipt
      // if (receiptRef.current && !receiptRef.current.contains(event.target)) {
      //   setReceiptOpen(false);
      // }
    }

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const topMenus = [
    {
      menu_key: "details",
      menu_title: "Details",
      menu_icon: <EventNoteIcon />,
      menu_count: 0,
    },
    {
      menu_key: "wordings",
      menu_title: "Wordings",
      menu_icon: <ReceiptIcon />,
      menu_count: quoteData?.wordings_count_xx,
    },
    {
      menu_key: "attachments",
      menu_title: "Attachments",
      menu_icon: <TopicIcon />,
      menu_count: attachmentsData.length,
    },
    {
      menu_key: "claims",
      menu_title: "Claims",
      menu_icon: <LocalFireDepartmentIcon />,
      menu_count: policyClaimsList.length,
    },
    {
      menu_key: "correspondence",
      menu_title: "Correspondence",
      menu_icon: <SubjectIcon />,
      menu_count: 0,
    },
    {
      menu_key: "comments",
      menu_title: "Comments",
      menu_icon: <CommentIcon />,
      menu_count: 0,
    }
  ]

  return (
    <motion.div
      className="uw-policy"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      <div className="main-wrapper">
        <div className="navbar">
          <div className="navbar-left">
            <h1 className="main-title" style={{ fontSize: "28px" }}>
              {selectedInsured?.ent_name || "New"}
            </h1>
            {Object.keys(quoteData).length > 0 &&
              <h1 className="sub-title" >
                {selectedProduct?.label} - [{selectedProduct?.value}] {quoteData?._id}
              </h1>}
          </div>
          <div className="navbar-right">
            {Object.keys(quoteData).length > 0 && (
              <>
                {documentEnabled === "Y" && (
                  <CustomLoadingButton
                    startIcon={<RefreshIcon className="button-icon" />}
                    onClick={refreshQuote}
                  >
                    REFRESH
                  </CustomLoadingButton>
                )}
                {documentEnabled === "N" && (
                  <CustomLoadingButton
                    startIcon={<RefreshIcon className="button-icon" />}
                    onClick={fetchQuote}
                  />
                )}
                {quoteData.pl_latest === "Y" &&
                  <CustomLoadingButton
                    startIcon={<DragIndicatorIcon className="button-icon" />}
                    onClick={() => {
                      try {
                        let action_text = "";
                        let new_doc_status = quoteData.pl_status;
                        switch (quoteData.pl_status) {
                          case "New":
                            action_text = `Do you want to Validate ${quoteData.pl_type} no ${quoteData.pl_risk_note_no}?`;
                            setInvokedAction("Validate");
                            new_doc_status = "Validated";
                            break;
                          case "Validated":
                            if (quoteData.pl_end_no === 0) {
                              action_text = `Do you want to Approve ${quoteData.pl_type} for ${quoteData.pl_risk_note_no}?`;
                            } else {
                              action_text = `Do you want to Approve ${quoteData.end_name_xx} endorsement for ${quoteData.pl_risk_note_no}?`;
                            }
                            setInvokedAction("Approve");
                            new_doc_status = "Approved";
                            break;
                          case "Approved":
                            action_text = `Do you want to Endorse ${quoteData.pl_type} no ${quoteData.pl_risk_note_no}?`;
                            setInvokedAction("Endorse");
                            new_doc_status = "Endorsed";
                            break;
                          case "Active":
                            action_text = `Do you want to Endorse ${quoteData.pl_type} no ${quoteData.pl_risk_note_no}?`;
                            setInvokedAction("Endorse");
                            new_doc_status = "Endorsed";
                            break;
                          default:
                            action_text = "";
                            new_doc_status = "";
                        }

                        if (!quoteData.pl_no && new_doc_status === "Endorsed") {
                          setNotifyDialogTitle("Note!");
                          setNotifyDialogText(
                            `You cannot endorse until you specify the Policy Number for this Risk note`
                          );
                          setIsNotifyDialogOpen(true);
                          return;
                        }

                        // UW1025 - User allowed complete document without commission
                        if (getLimitValue("UW1025") === "N" && new_doc_status === "Validated" && parseFloat(quoteData?.commission_net_fc) === 0) {
                          setNotifyDialogTitle("Note!");
                          setNotifyDialogText(
                            `UW1025 - You cannot complete this document without specifying commission`
                          );
                          setIsNotifyDialogOpen(true);
                          return;
                        }
                        setObjectToAction({
                          object_id: quoteData._id,
                          action_text: action_text,
                          new_doc_status,
                        });
                        setConfirmDialogAction("policyAction");
                        setIsConfirmDialogOpen(true);

                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  >
                    {quoteData.next_action_xx}
                  </CustomLoadingButton>}
                {quoteData.pl_latest === "N" &&
                  <CustomLoadingButton
                    startIcon={<DragIndicatorIcon className="button-icon" />}
                    onClick={async () => {
                      try {
                        setIsProcessing(true)
                        setQuoteId(quoteData?.latest_risk_note_docid);
                        setQuoteData({});
                        setActiveTab(10);
                        // Simulate delay for 2 seconds
                        await new Promise((resolve) => setTimeout(resolve, 2000));
                        fetchQuote();
                        setActiveTab(0);
                        setIsProcessing(false)
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  >
                    {`LATEST RISK NOTE ${quoteData?.latest_risk_note_no}`}
                  </CustomLoadingButton>
                }
              </>
            )}
            {saveEnabled === "Y" && (
              <CustomLoadingButton startIcon={<FaSave className="button-icon" />} onClick={saveRecord}>
                {savingTitle}
              </CustomLoadingButton>
            )}
            {Object.keys(quoteData).length > 0 && (
              <CustomLoadingButton
                startIcon={<DescriptionIcon className="button-icon" />}
                width={"max-content"}
                onClick={async () => {
                  try {
                    // Log the event here
                    setIsProcessing(true);
                    const trace_res = await newRequest.post(
                      "/trace/add",
                      {
                        trc_org_code: currentUser.usr_org_code,
                        trc_action_on: "Document",
                        trc_doc_type: quoteData.pl_type,
                        trc_doc_no: plRiskNoteNo,
                        trc_aent_code: "",
                        trc_ent_code: "",
                        trc_action: "previewed risk note",
                        trc_remarks: `previewed risk note ${plRiskNoteNo}`,
                        trc_tags: [
                          "previewed risk note",
                          plRiskNoteNo,
                        ],
                        trc_doc_docid: quoteData._id,
                        userCode: currentUser.usr_id,
                      }
                    );
                    // console.log(`Previewing Risk Note id ${quoteData._id} and org Code ${quoteData.pl_org_code}`)
                    const res = await newRequest.post(
                      "gendocs/rn",
                      {
                        policy_docid: quoteData._id,
                        org_code: quoteData.pl_org_code,
                      }, {
                      responseType: 'json'
                    }
                    );

                    // set the document ID of the saved binary data
                    // this will be used in the backend when we need to send attachments

                    setBinaryDocid(res.data.binary_docid);
                    console.log(`Binary docid ${res.data.binary_docid}`)

                    const response = await newRequest.get(`/viewdocs/view/${res.data.binary_docid}`, {
                      responseType: 'arraybuffer', // Ensure response is treated as binary data
                    });

                    // Create a blob from the binary data received
                    const blob = new Blob([response.data], { type: response.headers['content-type'] });
                    const url = URL.createObjectURL(blob);

                    // Create a blob URL from the response
                    // const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
                    // Open the PDF in a new tab
                    // const newTab = window.open();
                    // newTab.location.href = url;
                    setPdfUrl(url);

                    // Open the PDF in a new tab
                    // const newTab = window.open();
                    // newTab.location.href = url;
                    // show pdf previewer
                    setPdfPreviewDialogOpen(true);

                  } catch (error) {
                    console.log(error);
                  } finally {
                    setIsProcessing(false);
                  }
                }}
              >
                {`Preview ${plType}`}
              </CustomLoadingButton>
            )}
            <CustomLoadingButton startIcon={<IoClose className="button-icon" />} onClick={goBack}>
              Close
            </CustomLoadingButton>
            {/* Additional Actions for priviledged users */}
            {["Validated", "Active"].includes(plStatus) && getLimitValue("UW1012") === "Y" &&
              <div className="icon-container"
                ref={actionsMenuRef}
                onClick={(e) => {
                  try {
                    e.stopPropagation();
                    setOpenOptions(!openOptions)
                  } catch (error) {
                    console.log(error)
                  }
                }}>
                <MdOutlineMenuOpen className="icon" />
                {openOptions && (
                  <div className="dropdown">
                    {/* UW1012 User allowed to reset validation */}
                    {plStatus === "Validated" && getLimitValue("UW1012") === "Y" &&
                      <div className="dropdown-item"
                        onClick={() => {
                          try {
                            setObjectToAction({
                              object_id: quoteData._id,
                              action_text: `Do you want to reset DOCUMENT status back to <b>NEW</b>?<br><br>Please note the document will be opened for <b>AMENDMENTS</b>`,
                              new_doc_status: "New",
                            });
                            setConfirmDialogAction("invalidatePolicy");
                            setIsConfirmDialogOpen(true);
                          } catch (error) {
                            console.log(error)
                          }
                        }}>Reset</div>}
                    {plStatus === "Active" &&
                      <div className="dropdown-item"
                        onClick={() => {
                          try {
                            setObjectToAction({
                              object_id: quoteData._id,
                              action_text: `Do you want to <b>VOID APPROVAL</b> this document?`,
                              new_doc_status: "New",
                            });
                            setConfirmDialogAction("voidPolicy");
                            setIsConfirmDialogOpen(true);
                          } catch (error) {
                            console.log(error)
                          }
                        }}
                      >Void Approval</div>}
                  </div>
                )}
              </div>}
          </div>
        </div>
        <div className="content-summary-wrapper">
          <div className="content-wrapper">
            <div className="tabs-container">
              <div className="tab-header">
                {topMenus?.map((tab, index) => (
                  <div
                    key={index}
                    className={`${activeTab === index ? 'tab active' : 'tab'}`}
                    onClick={() => handleTabClick(index)}
                  >
                    <span className={`${activeTab === index ? 'tab-icon active' : 'tab-icon'}`}
                    >
                      {tab.menu_icon}
                    </span>
                    <span className={`${activeTab === index ? 'tab-title active' : 'tab-title'}`}>{tab.menu_title}</span>
                    {tab.menu_count > 0 && <Badge badgeContent={tab.menu_count} showZero sx={{ '& .MuiBadge-badge': { backgroundColor: activeTab === index ? '#9c27b0' : '#c3c3c3', color: 'white' } }}
                      className="tab-badge" />}
                  </div>
                ))}
              </div>
            </div>
            <div className="content-details">
              {/* Summary Information */}
              {activeTab === 0 && <>
                <div className="content-details-row">
                  <h1 className="main-title">CLIENT</h1>
                  <h1 className={`business-type c${plInternalEndCode}`}>
                    {businessType}
                  </h1>
                </div>
                <div className="content-details-row">
                  <div className="content-details-left">
                    <div className="input-item-with-label">
                      <label htmlFor="pl_assr_ent_code">INSURED</label>
                      <AsyncSelect
                        id="pl_assr_ent_code"
                        name="pl_assr_ent_code"
                        styles={customSelectStyles}
                        width="500px"
                        value={selectedInsured}
                        onChange={(selectedOption) => {
                          try {
                            setSelectedInsured(selectedOption);
                          } catch (error) {
                            console.log(error);
                          }
                        }}
                        loadOptions={fetchEntityBySearch}
                        isSearchable={true}
                        isDisabled={documentEnabled === "N"}
                        placeholder="Insured"
                        noOptionsMessage={() =>
                          customNoOptionsMessage(
                            "Type Minimum 3 Letter to Search Insured",
                            "create"
                          )
                        }
                      // components={{
                      //   Option: EntitySelectLayout,
                      // }}
                      />
                    </div>
                    <div className="input-item-with-label">
                      <label htmlFor="pl_product_code">PRODUCT</label>
                      <Select
                        id="pl_product_code"
                        name="pl_product_code"
                        styles={customSelectStyles}
                        width="500px"
                        value={selectedProduct}
                        onChange={(selectedOption) => {
                          try {
                            setSelectedProduct(selectedOption);
                            // show motor validation screen if document is new and its motor
                            if (selectedOption.pr_motor === "Y") {
                              setVehicleDialogOpen(true);
                            }
                          } catch (error) {
                            console.log(error);
                          }
                        }}
                        options={products}
                        isSearchable={true}
                        isDisabled={documentEnabled === "N" || quoteData?.policy_risks?.length > 0} // Disable if documentEnabled is "N"
                        placeholder="PRODUCT"
                      />
                    </div>
                    <div style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "space-between"

                    }}>
                      <div className="input-item-with-label">
                        <label htmlFor="pl_cur_code">CURRENCY</label>
                        <Select
                          id="pl_cur_code"
                          name="pl_cur_code"
                          styles={customSelectStyles}
                          width="170px"
                          value={plCurCode}
                          onChange={async (selectedOption) => {
                            try {
                              //get currency rate

                              if (
                                selectedOption.cur_code !==
                                currentUser.currency_code_xx
                              ) {
                                setPlCurCode(selectedOption);
                                const curRate = {
                                  rate_org_code: currentUser.usr_org_code,
                                  rate_cur_code_fm: selectedOption.cur_code,
                                  rate_cur_code_to: currentUser.currency_code_xx,
                                  rate_date: plGlDate,
                                };

                                // pass endorsement
                                const end_res = await newRequest.post(
                                  "/glCurrencyRate/find",
                                  curRate,
                                  config
                                );
                                if (end_res.data) {
                                  setPlCurRate(end_res.data.rate);
                                } else {
                                  setPlCurCode({
                                    value: currentUser.currency_code_xx,
                                    label: currentUser.currency_name_xx,
                                  });
                                  setPlCurRate(1);
                                  setNotifyDialogTitle("Note!");
                                  setNotifyDialogText(
                                    `Conversion Rate from <b>${selectedOption.cur_code
                                    } to ${currentUser.currency_code_xx
                                    } for date ${moment(plGlDate).format(
                                      "DD-MMM-YYYY"
                                    )}</b> NOT found. Currency will reset to <b>${currentUser.currency_code_xx
                                    }</b>`
                                  );
                                  setIsNotifyDialogOpen(true);
                                }
                              } else {
                                setPlCurCode({
                                  value: currentUser.currency_code_xx,
                                  label: currentUser.currency_name_xx,
                                });
                                setPlCurRate(1);
                              }
                            } catch (error) {
                              console.log(error);
                            }
                          }}
                          options={currencies}
                          isSearchable={true}
                          isDisabled={documentEnabled === "N" || plEndNo > 0}
                          placeholder="CURRENCY"
                        />
                      </div>
                      <div className="input-item-with-label" >
                        <label htmlFor="pl_biz_unit" >BIZ UNIT</label>
                        <Select
                          id="pl_biz_unit"
                          name="pl_biz_unit"
                          styles={customSelectStyles}
                          width="175px"
                          value={selectedBranch}
                          onChange={(selectedOption) => {
                            try {
                              setSelectedBranch(selectedOption);
                            } catch (error) {
                              console.log(error);
                            }
                          }}
                          options={branches}
                          isSearchable={true}
                          isDisabled={documentEnabled === "N"}
                          placeholder="BIZ UNIT"
                        />
                      </div>
                    </div>
                    <div style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      alignItems: "flex-start",
                      justifyContent: "space-between"

                    }}>
                      <div className="input-item-with-label">
                        <label
                          htmlFor="pl_fm_date"
                          className="form-dropdown-label"
                        >
                          FROM DATE
                        </label>
                        <input
                          id="pl_fm_date"
                          name="pl_fm_date"
                          type="date"
                          placeholder="FROM"
                          value={plFmDate}
                          className="form-input-item"
                          style={{ width: "170px" }}
                          onChange={handleFromDateChange}
                          onBlur={handleFromDateBlur}
                          disabled={documentEnabled === "N"}
                        />
                        <span className="days">{`${daysDifference} DAYS`}</span>
                      </div>
                      <div className="input-item-with-label" style={{ display: "flex", alignSelf: "flex-end", }}>
                        <label
                          htmlFor="pl_to_date"
                          className="form-dropdown-label"
                          style={{ display: "flex", width: "max-content" }}
                        >
                          TO DATE
                        </label>
                        <input
                          id="pl_to_date"
                          name="pl_to_date"
                          type="date"
                          placeholder="TO"
                          value={plToDate}
                          className="form-input-item"
                          style={{ width: "175px" }}
                          onChange={handleToDateChange}
                          onBlur={handleToDateBlur}
                          disabled={documentEnabled === "N"}
                        />
                      </div>
                    </div>
                    <div className="input-item-with-label">
                      <label htmlFor="pl_ins_ent_code">CARRIER</label>
                      <AsyncSelect
                        id="pl_ins_ent_code"
                        name="pl_ins_ent_code"
                        styles={customSelectStyles}
                        width="500px"
                        value={selectedCarrier}
                        onChange={(selectedOption) => {
                          try {
                            setSelectedCarrier(selectedOption);
                          } catch (error) {
                            console.log(error);
                          }
                        }}
                        loadOptions={fetchEntityBySearch}
                        isSearchable={true}
                        isDisabled={documentEnabled === "N" || plEndNo > 0}
                        placeholder="Carrier"
                        noOptionsMessage={() =>
                          customNoOptionsMessage(
                            "Type Minimum 3 Letter to Search Carrier",
                            "create"
                          )
                        }
                      // components={{
                      //   Option: EntitySelectLayout,
                      // }}
                      />
                    </div>
                  </div>
                  <div className="content-details-right">
                    <div className="input-item-with-label">
                      <label
                        htmlFor="pl_risk_note_no"
                        className="form-dropdown-label"
                      >
                        RISK NOTE NO
                      </label>
                      <input
                        id="pl_risk_note_no"
                        name="pl_risk_note_no"
                        type="text"
                        placeholder="RISK NOTE NO"
                        value={plRiskNoteNo}
                        disabled={!isDocumentNumberSetup}
                        className="form-input-item"
                        style={{ width: "200px" }}
                        onChange={(e) => setPlRiskNoteNo(e.target.value)}
                      />
                    </div>
                    <div className="input-item-with-label">
                      <label htmlFor="pl_end_no" className="form-dropdown-label">
                        END No
                      </label>
                      <input
                        id="pl_end_no"
                        name="pl_end_no"
                        type="text"
                        placeholder="END NO"
                        disabled
                        value={plEndNo}
                        className="form-input-item"
                        style={{ width: "200px" }}
                        onChange={(e) => setPlEndNo(e.target.value)}
                      />
                    </div>
                    <div className="input-item-with-label">
                      <label htmlFor="pl_status" className="form-dropdown-label">
                        STATUS
                      </label>
                      <input
                        id="pl_status"
                        name="pl_status"
                        type="text"
                        placeholder="STATUS"
                        disabled
                        value={plStatus}
                        className="form-input-item"
                        style={{ width: "200px" }}
                        onChange={(e) => setPlStatus(e.target.value)}
                      />
                    </div>
                    <div className="input-item-with-label">
                      <label htmlFor="pl_gl_date" className="form-dropdown-label">
                        GL DATE
                      </label>
                      <input
                        id="pl_gl_date"
                        name="pl_gl_date"
                        type="date"
                        placeholder="GL DATE"
                        value={plGlDate}
                        className="form-input-item"
                        style={{ width: "200px" }}
                        onChange={handleGlDateChange}
                        disabled={documentEnabled === "N"} // Disable if documentEnabled is "N"
                      />
                    </div>
                    <div className="input-item-with-label">
                      <label htmlFor="pl_no" className="form-dropdown-label">
                        POLICY NO
                      </label>
                      <input
                        id="pl_no"
                        name="pl_no"
                        type="text"
                        placeholder="POLICY NO"
                        value={plNo}
                        className="form-input-item"
                        style={{ width: "200px" }}
                        onChange={(e) => {
                          try {
                            setPlNo(e.target.value)
                            setQuoteData(prevState => ({
                              ...prevState,
                              pl_no: e.target.value
                            }));
                          } catch (error) {
                            console.log(error)
                          }
                        }}
                        disabled={!enablePolicyNo}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-item-with-label">
                  <label htmlFor="pl_remarks" style={{ width: "170px", alignSelf: "flex-start" }}>
                    UW REMARKS
                  </label>
                  <textarea
                    id="pl_remarks"
                    name="pl_remarks"
                    type="text"
                    placeholder="UNDERWRITER REMARKS"
                    value={plRemarks}
                    className="form-input-item"
                    style={{ width: "100%" }}
                    rows={3}
                    onChange={(e) => {
                      try {
                        setPlRemarks(e.target.value)
                      } catch (error) {
                        console.log(error)
                      }
                    }}
                    disabled={documentEnabled === "N"}
                  />
                </div>
                <hr />
                <div className="content-details-row">
                  <h1 className="main-title">{`BASIS OF CLAIM SETTLEMENT ${claimBasisOfSettlement?.length === 0 ? "NOT SPECIFIED" : ""}`}</h1>
                </div>
                {documentEnabled === "Y" && (
                  <div className="content-details-row" style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "5px",
                    paddingLeft: "150px"
                  }}>
                    <div
                      className="input-item-with-label"
                      style={{ width: "100%" }}
                    >
                      <input
                        type="text"
                        value={basisOfClaim}
                        className="form-input-item"
                        placeholder="Enter Basis Text"
                        style={{ width: "100%" }}
                        onChange={(e) => setBasisOfClaim(e.target.value)}
                      />
                    </div>
                    <CustomLoadingButton
                      startIcon={<AddIcon />}
                      onClick={handleAddClaimBasis}
                      style={{ width: "20%" }}
                    >
                      Add
                    </CustomLoadingButton>
                  </div>
                )}
                <div className="content-details-row" style={{ marginTop: "0px" }}>
                  <div className="basis-of-claim">
                    {/* basis of claim text */}
                    {claimBasisOfSettlement?.length > 0 && (
                      <div>
                        {claimBasisOfSettlement?.map((claim, index) => (
                          <div key={claim._id}>
                            <div className="single-basis-row">
                              <span
                                style={{
                                  alignContent: "flex-start",
                                }}
                              >
                                {index + 1}.
                              </span>
                              <span
                                style={{
                                  fontWeight: "500",
                                  fontSize: "16px",
                                }}
                              >{`${claim.basis_text}`}</span>
                              {documentEnabled === "Y" && (
                                <Tooltip
                                  title={`Remove ${claim.basis_text}`}
                                  placement="right"
                                >
                                  <IoClose
                                    size={24}
                                    style={{
                                      borderRadius: "5px",
                                      padding: "3px",
                                      cursor: "pointer",
                                      color: "rgba(172, 33, 9)",
                                      backgroundColor: "rgba(172, 33, 9, 0.5)",
                                    }}
                                    onClick={() => {
                                      try {
                                        // console.log(`claim is ${claim}`);
                                        const action_text = `Do remove ${claim.basis_text} from this document?`;
                                        setObjectToAction({
                                          object_id: claim._id,
                                          action_text: action_text,
                                        });

                                        setConfirmDialogAction("removeClaimBasis");
                                        setIsConfirmDialogOpen(true);
                                      } catch (error) {
                                        console.log(error);
                                      }
                                    }}
                                  />
                                </Tooltip>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <hr />
                <div className="content-details-row">
                  <h1 className="main-title">SPECIAL NOTES</h1>
                </div>
                {documentEnabled === "Y" && (
                  <div className="content-details-row" style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "5px",
                    paddingLeft: "150px"
                  }}>
                    <div
                      className="input-item-with-label"
                      style={{ width: "100%" }}
                    >
                      <input
                        type="text"
                        value={specialNote}
                        className="form-input-item"
                        placeholder="Note"
                        style={{ width: "100%" }}
                        onChange={(e) => setSpecialNote(e.target.value)}
                      />
                    </div>
                    <CustomLoadingButton
                      startIcon={<AddIcon className="button-icon" />}
                      onClick={handleAddSpecialNote}
                      style={{ width: "20%" }}
                    >
                      Add
                    </CustomLoadingButton>
                  </div>
                )}
                <div className="content-details-row" style={{ marginTop: "0px" }}>
                  <div className="basis-of-claim">
                    {/* basis of claim text */}
                    {specialNotes?.length > 0 && (
                      <div>
                        {specialNotes?.map((row, index) => (
                          <div key={row._id}>
                            <div className="single-basis-row">
                              <span
                                style={{
                                  alignContent: "flex-start",
                                }}
                              >
                                {index + 1}.
                              </span>
                              <span
                                style={{
                                  fontWeight: "500",
                                  fontSize: "16px",
                                }}
                              >{`${row.note_text}`}</span>
                              {documentEnabled === "Y" && (
                                <Tooltip
                                  title={`Remove ${row.note_text}`}
                                  placement="right"
                                >
                                  <IoClose
                                    size={24}
                                    style={{
                                      borderRadius: "5px",
                                      padding: "3px",
                                      cursor: "pointer",
                                      color: "rgba(172, 33, 9)",
                                      backgroundColor: "rgba(172, 33, 9, 0.5)",
                                    }}
                                    onClick={() => {
                                      try {
                                        // console.log(`claim is ${claim}`);
                                        const action_text = `Do remove ${row.note_text} from this document?`;
                                        setObjectToAction({
                                          object_id: row._id,
                                          action_text: action_text,
                                        });
                                        setConfirmDialogAction("removeSpecialNote");
                                        setIsConfirmDialogOpen(true);
                                      } catch (error) {
                                        console.log(error);
                                      }
                                    }}
                                  />
                                </Tooltip>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <hr />
                <div className="content-details-row" style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "5px",
                  paddingLeft: "0px"
                }}>
                  {Object.keys(quoteData).length === 0 && (
                    <div className="quote-not-saved">
                      SAVE TO VIEW MORE INFORMATION
                    </div>
                  )}
                  {Object.keys(quoteData).length > 0 && (
                    <UwPolicyRisk
                      key={policyRiskKey}
                      ref={policyRiskRef}
                      quoteData={quoteData}
                      refreshQuote={refreshQuote}
                      profileLimitValues={profileLimitValues}
                    />
                  )}
                </div>
              </>}
              {/* Wordings */}
              {activeTab === 1 && <>
                <div className="content-details-row" style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "5px",
                  paddingLeft: "0px"
                }}>
                  {Object.keys(quoteData).length > 0 && (
                    <UwPolicyCond
                      quoteData={quoteData}
                      refreshQuote={refreshQuote}
                      ref={policyCondRef}
                      profileLimitValues={profileLimitValues}
                    />
                  )}
                </div>
                {/* Excess */}
                <div className="content-details-row" style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "5px",
                  paddingLeft: "0px"
                }}>
                  {Object.keys(quoteData).length > 0 && (
                    <UwPolicyExcess
                      quoteData={quoteData}
                      refreshQuote={refreshQuote}
                      ref={policyExcessRef}
                      profileLimitValues={profileLimitValues}
                    />
                  )}
                </div>
              </>}
              {/* Attachments */}
              {activeTab === 2 &&
                <div className="attachment">
                  <FileUploadComponent documentNo={quoteData.pl_risk_note_no} documentId={quoteData._id} documentType={quoteData.pl_type} fetchDocumentAttachments={fetchDocumentAttachments} uploadFolder={quoteData.pl_type} />
                  <div className="attachment-files">
                    <AllFiles filesList={attachmentsData} bgColor={"#fff"} />
                  </div>
                </div>
              }
              {/* Claims */}
              {activeTab === 3 &&
                <div className="content-details-row">
                  <div className="claims-wrapper">
                    {policyClaimsList?.length === 0 && <div className="no-claim">
                      <h1 className="main-title">{`NO CLAIM REGISTERED FOR POLICY NUMBER ${plNo}`}</h1>
                    </div>}
                    {policyClaimsList?.length > 0 && <div className="claims-content">
                      {policyClaimsList?.map((row, index) => (
                        <div key={row?._id} className="single-claim" onClick={() => {
                          navigate(`/cm/${row?._id}`, {
                            state: row,
                          });
                        }}>
                          <h1 className="main-title">{`CLAIM NO : ${row?.cm_claim_no}`}</h1>
                          <h3>{`INTIMATED : ${moment(row?.cm_intimation_date).format("DD-MMM-YYYY")}`}</h3>
                          <h3>{`LOSS DATE : ${moment(row?.cm_loss_date).format("DD-MMM-YYYY")}`}</h3>
                          <h3>{`REGISTERED : ${row?.time_ago_xx}`}</h3>
                        </div>
                      ))}
                    </div>}
                  </div>
                </div>}
              {activeTab === 4 &&
                <motion.div
                  className="content-details-row"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { duration: 1 } }}
                  exit={{ opacity: 0, transition: { duration: 1 } }}
                >
                  <Correspondence
                    correspondenceParams={{ fetch_type: "document", doc_id: quoteData._id, doc_no: plRiskNoteNo, doc_type: plType, entity_codes: [selectedInsured?.value, selectedCarrier?.value] }}
                    passedEmailData={{
                      to: quoteData?.insured_email_xx,
                      cc: `${quoteData?.carrier_email_xx},${currentUser?.usr_email}`,
                      bcc: '',
                      subject: ``,
                      show_attach: "Y",
                      attached_urls: [],
                      binary_docs: [],
                      doc_no: plRiskNoteNo,
                      doc_type: plType,
                      doc_id: quoteId,
                      entity_codes: [selectedInsured?.value, selectedCarrier?.value],
                      search_string: selectedInsured?.label + "," + selectedCarrier?.label + "," + plRiskNoteNo,
                      carrierName: selectedCarrier?.label,
                      productName: selectedProduct?.label,
                      clientName: selectedInsured?.label,
                      riskNoteNo: plRiskNoteNo,
                      daysToRenewal: quoteData?.expiring_in_days,
                      expiryDate: quoteData?.fmt_to_date,
                      insuredName: selectedInsured?.label,
                      outstandingPremium: quoteData?.outstandingPremium,
                      policyCurrency: plCurCode?.value,
                      policyNumber: plNo,
                      policyPremium: quoteData?.fmt_policy_fc_prem,
                      policyPremiumTotal: quoteData?.fmt_total_fc_prem,
                      policySumInsured: quoteData?.fmt_total_fc_si,
                      policyFromDate: quoteData?.fmt_fm_date,
                      policyToDate: quoteData?.fmt_to_date,
                      tpt_category: ["Policy Renewal", "Birthday"]
                    }} />
                </motion.div>
              }
              {activeTab === 5 &&
                <motion.div
                  className="content-details-row"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { duration: 1 } }}
                  exit={{ opacity: 0, transition: { duration: 1 } }}
                >
                  <div className="comments">
                    <AllDocsNote
                      docId={quoteData._id}
                      docType="RiskNote"
                      noteType={"Normal"}
                    />
                  </div>
                </motion.div>
              }
            </div>
          </div>
          <div className="summary-wrapper">
            <div className="summary-content">
              {plCurCode?.value !== currentUser.currency_code_xx && (
                <div
                  className="input-item-with-label"
                  style={{ alignSelf: "flex-end" }}
                >
                  <label
                    htmlFor="pl_cur_rate"
                    className="form-dropdown-label"
                  >
                    RATE
                  </label>
                  <input
                    id="pl_cur_rate"
                    name="pl_cur_rate"
                    type="number"
                    placeholder="RATE"
                    value={plCurRate}
                    className="form-input-item"
                    style={{
                      width: "100px",
                      margin: "5px 0px",
                      textAlign: "right",
                    }}
                    onChange={(e) => setPlCurRate(e.target.value)}
                    disabled={documentEnabled === "N" || getLimitValue("UW1021") === "N"} // Disable if documentEnabled is "N" or user not allowed to override exchange rate
                  />
                </div>
              )}
              <hr />
              <motion.div
                className="summary-view"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 1 } }}
                exit={{ opacity: 0, transition: { duration: 1 } }}
              >
                <div className="title-row">
                  <h1 className="main-title">SUMMARY</h1>
                  {currentUser?.currency_code_xx !==
                    plCurCode?.value && (
                      <CustomButtonSmall
                        style={{ display: "flex" }}
                        onClick={() => {
                          try {
                            if (figuresToShow === "org") {
                              setFiguresToShow("base");
                            } else {
                              setFiguresToShow("org");
                            }
                          } catch (error) {
                            console.log(error);
                          }
                        }}
                      >
                        {figuresToShow === "org"
                          ? `view ${currentUser.currency_code_xx} amounts`
                          : `view ${plCurCode.cur_code} amounts`}
                      </CustomButtonSmall>
                    )}

                  <h1 className="main-title" style={{ marginLeft: "5px" }}>
                    {figuresToShow === "org"
                      ? `${plCurCode?.value}`
                      : `${currentUser.currency_code_xx}`}
                  </h1>
                </div>
                <hr />
                <div className="content-row">
                  <span>SUM INSURED</span>
                  <span>
                    {numeral(
                      figuresToShow === "org"
                        ? quoteData?.total_fc_si
                        : quoteData?.total_lc_si
                    ).format("0,0.00")}
                  </span>
                </div>
                <div className="content-row">
                  <span>PREMIUM</span>
                  <span>
                    {numeral(
                      figuresToShow === "org"
                        ? quoteData?.policy_fc_premium
                        : quoteData?.policy_lc_premium
                    ).format("0,0.00")}
                  </span>
                </div>
                {/* charges and levies */}
                <div>
                  {quoteData.policy_charges?.map((charge) => (
                    <div className="content-row" key={charge._id}>
                      <span>{charge.charge_name}</span>
                      <span>
                        {numeral(
                          figuresToShow === "org"
                            ? charge.charge_fc_amount
                            : charge.charge_lc_amount
                        ).format("0,0.00")}
                      </span>
                    </div>
                  ))}
                </div>
                {/* <hr /> */}
                <div className="content-row" style={{ backgroundColor: "rgba(156, 39, 176, 0.5)", padding: "5px 3px", fontSize: "18px", borderRadius: "5px", borderTop: "3px solid #9c27b0" }}>
                  <span>TOTAL PREMIUM</span>
                  <span className="total-row" style={{ fontSize: "22px" }}>
                    {numeral(
                      figuresToShow === "org"
                        ? quoteData?.total_fc_premium
                        : quoteData?.total_lc_premium
                    ).format("0,0.00")}
                  </span>
                </div>
                {/* <hr /> */}

                {/* PREMIUMS */}
                {quoteData.drcr_flag_xx === "D" &&
                  <div className="title-row commission">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        marginBottom: "5px",
                      }}
                    >
                      <h1 className="section-sub-title">RECEIPTS</h1>
                      {Object.keys(quoteData).length > 0 && getLimitValue("UW1010") === "Y" && (
                        <CustomButtonSmall
                          style={{ display: "flex", textAlign: "right" }}
                          startIcon={<LibraryAddIcon />}
                          onClick={() => {
                            try {
                              const formattedToday = moment(
                                new Date(Date.now())
                              ).format("YYYY-MM-DD");
                              if (receiptOpen) {
                                setReceiptDate(formattedToday);
                              }
                              setReceiptOpen(!receiptOpen);
                            } catch (error) {
                              console.log(error);
                            }
                          }}
                        >
                          ADD RECEIPT
                        </CustomButtonSmall>
                      )}
                      {receiptOpen && (
                        <motion.div
                          className="options"
                          initial={{
                            opacity: 0,
                            scale: 0,
                            originX: 0.5,
                            originY: 0.5,
                          }}
                          animate={{
                            opacity: 1,
                            scale: 1,
                            transition: { duration: 0.5 },
                          }}
                          exit={{
                            opacity: 0,
                            scale: 0,
                            transition: { duration: 0.5 },
                          }}
                          transition={{ type: "tween", duration: 0.5 }}
                          ref={receiptRef}
                        >
                          <h3 className="mandatory-text">
                            All Fields are Mandatory
                          </h3>
                          <Select
                            id="receipt_paidto"
                            name="receipt_paidto"
                            styles={customSelectStyles}
                            width="250px"
                            value={selectedReceiptPaidTo}
                            onChange={(selectedOption) => {
                              try {
                                setSelectedReceiptPaidTo(selectedOption);
                              } catch (error) {
                                console.log(error);
                              }
                            }}
                            options={receiptPaidToOptions}
                            isSearchable={false}
                            placeholder="Paid To"
                          />
                          <div className="input-item-with-label">
                            <label
                              htmlFor="receipt_mode"
                              className="form-dropdown-label"
                            >
                              MODE
                            </label>
                            <Select
                              id="receipt_mode"
                              name="receipt_mode"
                              styles={customSelectStyles}
                              width="170px"
                              value={selectedReceiptMode}
                              onChange={(selectedOption) => {
                                try {
                                  setSelectedReceiptMode(selectedOption);
                                } catch (error) {
                                  console.log(error);
                                }
                              }}
                              options={receiptModeOptions}
                              isSearchable={true}
                              placeholder="Mode"
                            />
                          </div>
                          <div className="input-item-with-label">
                            <label
                              htmlFor="receipt_cur"
                              className="form-dropdown-label"
                            >
                              CURRENCY
                            </label>
                            <Select
                              id="receipt_cur"
                              name="receipt_cur"
                              styles={customSelectStyles}
                              width="170px"
                              value={plCurCode}
                              onChange={(selectedOption) => {
                                try {
                                  setSelectedReceiptCurrency(selectedOption);
                                } catch (error) {
                                  console.log(error);
                                }
                              }}
                              options={currencies}
                              placeholder="CURRENCY"
                            />
                          </div>
                          <div className="input-item-with-label">
                            <label
                              htmlFor="receipt_no"
                              className="form-dropdown-label"
                            >
                              RECEIPT NO
                            </label>
                            <input
                              id="receipt_no"
                              name="receipt_no"
                              type="text"
                              placeholder={
                                selectedReceiptPaidTo.value === "ToUs"
                                  ? "AUTO GEN"
                                  : "ENTER NO"
                              }
                              value={receiptNo}
                              className="form-input-item"
                              style={{ width: "170px" }}
                              disabled={
                                selectedReceiptPaidTo.value === "ToUs"
                              }
                              onChange={(e) => setReceiptNo(e.target.value)}
                            />
                          </div>

                          <div className="input-item-with-label">
                            <label
                              htmlFor="receipt_date"
                              className="form-dropdown-label"
                            >
                              DATE
                            </label>
                            <input
                              id="receipt_date"
                              name="receipt_date"
                              type="date"
                              placeholder="DATE"
                              value={receiptDate}
                              className="form-input-item"
                              style={{ width: "170px" }}
                              onChange={handleReceiptDateChange}
                            />
                          </div>
                          <div className="input-item-with-label">
                            <label
                              htmlFor="receipt_amount"
                              className="form-dropdown-label"
                            >
                              AMOUNT
                            </label>
                            <input
                              id="receipt_amount"
                              name="receipt_amount"
                              type="number"
                              placeholder="0.00"
                              value={receiptAmount}
                              className="form-input-item"
                              style={{ width: "170px", textAlign: "right" }}
                              onChange={(e) =>
                                setReceiptAmount(e.target.value)
                              }
                            />
                          </div>

                          <div className="actions-row">
                            <CustomButton
                              startIcon={<IoClose />}
                              onClick={() => setReceiptOpen(false)}
                            >
                              CANCEL
                            </CustomButton>
                            <CustomButton
                              startIcon={<IoClose />}
                              onClick={handleAddReceipt}
                            >
                              ADD
                            </CustomButton>
                          </div>
                        </motion.div>
                      )}
                    </div>
                  </div>}
                {quoteData.drcr_flag_xx === "D" && <div className="content-row">
                  <h1 className="section-sub-title">PREMIUM DUE</h1>
                  <span>
                    {numeral(
                      figuresToShow === "org"
                        ? quoteData?.total_fc_premium
                        : quoteData?.total_lc_premium
                    ).format("0,0.00")}
                  </span>
                </div>}
                {/* this view is for when premium has been paid */}
                {quoteData.policy_receipts?.length > 0 && quoteData.drcr_flag_xx === "D" && (
                  <div>
                    {quoteData.policy_receipts?.map((receipt) => (
                      <div className="content-row" key={receipt._id}>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {documentEnabled === "Y" && (
                            <Tooltip
                              title={`Remove ${receipt.receipt_mode} Receipt ${receipt.receipt_no}`}
                              placement="right"
                            >
                              <IoClose
                                size={24}
                                style={{
                                  marginRight: "10px",
                                  borderRadius: "5px",
                                  padding: "3px",
                                  alignSelf: "center",
                                  cursor: "pointer",
                                  color: "rgba(172, 33, 9)",
                                  backgroundColor: "rgba(172, 33, 9, 0.5)",
                                }}
                                onClick={() => {
                                  try {
                                    const action_text = `Do you want to Unmatch ${receipt.receipt_mode} Receipt ${receipt.receipt_no} from this document?`;
                                    setObjectToAction({
                                      object_id: receipt._id,
                                      action_text: action_text,
                                    });

                                    setConfirmDialogAction("removeReceipt");
                                    setIsConfirmDialogOpen(true);
                                  } catch (error) {
                                    console.log(error);
                                  }
                                }}
                              />
                            </Tooltip>
                          )}
                          <div className="receipt-no-view">
                            <span>{`${receipt.receipt_mode}`}</span>
                            <span
                              className={`receipt-no ${receipt.receipt_paid_to === "ToUs"
                                ? "our"
                                : ""
                                }`}
                              onClick={() => {
                                try {
                                  if (receipt.receipt_paid_to === "ToUs") {
                                    navigate(
                                      `/receipt/${receipt.actual_receipt_docid}`,
                                      {
                                        state: {
                                          shouldFetchClients: false,
                                        },
                                      }
                                    );
                                  }
                                } catch (error) {
                                  console.log(error);
                                }
                              }}
                            >
                              {receipt.receipt_no}
                            </span>
                          </div>
                        </span>
                        <span>
                          {numeral(
                            figuresToShow === "org"
                              ? receipt.receipt_fc_amount
                              : receipt.receipt_lc_amount
                          ).format("0,0.00")}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
                {/* this view shows when no premium has been received */}
                {quoteData.policy_receipts?.length === 0 && quoteData.drcr_flag_xx === "D" && (
                  <div className="content-row">
                    <h1 className="section-sub-title">PAID</h1>
                    <span> {numeral(0).format("0,0.00")}</span>
                  </div>
                )}
                {quoteData.drcr_flag_xx === "D" && <div className="content-row" style={{ backgroundColor: `${quoteData.total_os_fc_amount > 0 ? "rgba(244, 67, 54, 0.5)" : "rgba(0, 128, 0, 0.5)"}`, padding: "5px 3px", fontSize: "18px", borderRadius: "5px", borderTop: "3px solid #f44336" }}>
                  <h1 className="section-sub-title">OUTSTANDING PREMIUM</h1>
                  <span className="total-row" style={{ fontSize: "22px" }}>
                    {numeral(
                      figuresToShow === "org"
                        ? quoteData.total_os_fc_amount
                        : quoteData.total_os_lc_amount
                    ).format("0,0.00")}
                  </span>
                </div>}

                {/* COMMISSIONS */}
                <div className="title-row commission">
                  <h1 className="section-sub-title">COMMISSION</h1>
                  <span>

                    {figuresToShow === "org"
                      ? `${plCurCode?.value}`
                      : `${currentUser.currency_code_xx}`}
                  </span>
                </div>
                <div>
                  {quoteData?.policy_aggregated_commission?.map((commission, index) => (
                    <div
                      className="content-row"
                      key={index}
                    >
                      <span>{commission.commission_name}</span>
                      <span>
                        {numeral(
                          figuresToShow === "org"
                            ? commission.total_commission_fc_amount
                            : commission.total_commission_lc_amount
                        ).format("0,0.00")}
                      </span>
                    </div>
                  ))}
                </div>
                <div className="content-row">
                  <span>NET COMMISSION</span>
                  <span className="total-row">
                    {numeral(
                      figuresToShow === "org"
                        ? quoteData?.commission_net_fc
                        : quoteData?.commission_net_lc
                    ).format("0,0.00")}
                  </span>
                </div>
                {/* commission that has been paid */}
                <hr />
                {quoteData.drcr_flag_xx === "D" && <>  <div className="title-row commission">
                  <h1 className="section-sub-title">PAID COMMISSION</h1>
                </div>
                  {quoteData.policy_paid_commission?.length === 0 && (
                    <div className="content-row">
                      <span>PAID</span>
                      <span> {numeral(0).format("0,0.00")}</span>
                    </div>
                  )}
                  {quoteData.policy_paid_commission?.length > 0 && (
                    <div>
                      {quoteData.policy_paid_commission?.map((comm) => (
                        <div className="content-row" key={comm._id}>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {documentEnabled === "Y" && (
                              <Tooltip
                                title={`Remove ${comm.comm_mode} Receipt ${comm.comm_doc_no}`}
                                placement="right"
                              >
                                <IoClose
                                  size={24}
                                  style={{
                                    marginRight: "10px",
                                    borderRadius: "5px",
                                    padding: "3px",
                                    alignSelf: "center",
                                    cursor: "pointer",
                                    color: "rgba(172, 33, 9)",
                                    backgroundColor: "rgba(172, 33, 9, 0.5)",
                                  }}
                                  onClick={() => {
                                    try {
                                      const action_text = `Do you want to Unmatch ${comm.comm_mode} Receipt ${comm.comm_doc_no} from this document?`;
                                      setObjectToAction({
                                        object_id: comm._id,
                                        action_text: action_text,
                                      });

                                      setConfirmDialogAction("removeCommReceipt");
                                      setIsConfirmDialogOpen(true);
                                    } catch (error) {
                                      console.log(error);
                                    }
                                  }}
                                />
                              </Tooltip>
                            )}

                            <div className="receipt-no-view">
                              <span>{`${comm.comm_mode}`}</span>
                              <span
                                className="our"
                                onClick={() => {
                                  try {
                                    if (comm.comm_paid_to === "Receipt") {
                                      navigate(
                                        `/receipt/${comm.actual_receipt_docid}`,
                                        {
                                          state: {
                                            shouldFetchClients: false,
                                          },
                                        }
                                      );
                                    }
                                  } catch (error) {
                                    console.log(error);
                                  }
                                }}
                              >
                                {comm.comm_doc_no}
                              </span>
                            </div>
                          </span>
                          <span>
                            {numeral(
                              figuresToShow === "org"
                                ? comm.comm_fc_amount
                                : comm.comm_lc_amount
                            ).format("0,0.00")}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="content-row">
                    <span>OS COMMISSION</span>
                    <span className="total-row">
                      {numeral(
                        figuresToShow === "org"
                          ? quoteData?.commission_os_fc
                          : quoteData?.commission_os_lc
                      ).format("0,0.00")}
                    </span>
                  </div></>}
                {quoteData.drcr_flag_xx === "D" && <hr />}
                {quoteData?.show_remittances_xx === "Y" && <>
                  <div className="title-row commission">
                    <h1 className="section-sub-title">REMITANCES TO CARRIER</h1>
                  </div>
                  {quoteData.policy_remittances?.length === 0 && (
                    <div className="content-row">
                      <span>TOTAL REMITTED</span>
                      <span> {numeral(0).format("0,0.00")}</span>
                    </div>
                  )}
                  {quoteData.policy_remittances?.length > 0 && (
                    <div>
                      {quoteData.policy_remittances?.map((comm) => (
                        <div className="content-row" key={comm._id}>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {documentEnabled === "Y" && (
                              <Tooltip
                                title={`Remove remittance ${comm.payment_no}`}
                                placement="right"
                              >
                                <IoClose
                                  size={24}
                                  style={{
                                    marginRight: "10px",
                                    borderRadius: "5px",
                                    padding: "3px",
                                    alignSelf: "center",
                                    cursor: "pointer",
                                    color: "rgba(172, 33, 9)",
                                    backgroundColor: "rgba(172, 33, 9, 0.5)",
                                  }}
                                  onClick={() => {
                                    try {
                                      const action_text = `Do you want to Unmatch remittance ${comm.payment_no} from this document?`;
                                      setObjectToAction({
                                        object_id: comm._id,
                                        action_text: action_text,
                                      });

                                      setConfirmDialogAction("removeRemittance");
                                      setIsConfirmDialogOpen(true);
                                    } catch (error) {
                                      console.log(error);
                                    }
                                  }}
                                />
                              </Tooltip>
                            )}
                            <div className="receipt-no-view">
                              <span>{`${comm.payment_mode}`}</span>
                              <span
                                className="our"
                                onClick={() => {
                                  try {
                                    navigate(
                                      `/receipt/${comm.actual_payment_docid}`,
                                      {
                                        state: {
                                          shouldFetchClients: false,
                                        },
                                      }
                                    );
                                  } catch (error) {
                                    console.log(error);
                                  }
                                }}
                              >
                                {comm.payment_no}
                              </span>
                            </div>
                          </span>
                          <span>
                            {numeral(
                              figuresToShow === "org"
                                ? comm.payment_fc_amount
                                : comm.payment_lc_amount
                            ).format("0,0.00")}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="content-row">
                    <span>OS TO REMITT </span>
                    <span className="total-row">
                      {numeral(
                        figuresToShow === "org"
                          ? quoteData?.os_to_remit_fc
                          : quoteData?.os_to_remit_lc
                      ).format("0,0.00")}
                    </span>
                  </div>
                </>}
                {quoteData.expiring_show_xx === "Y" &&
                  <div className="expiring-content" ref={expiringInRef} onClick={() => setExpiringInMenuOpen(false)}>
                    <div className="header">
                      <h2 className="title">{quoteData?.expiring_status}</h2>
                      <div className="icon-container" onClick={(e) => {
                        try {
                          e.stopPropagation();
                          setExpiringInMenuOpen(!expiringInMenuOpen)
                        } catch (error) {
                          console.log(error)
                        }
                      }}>
                        <MdOutlineMenuOpen className="icon" />
                        {expiringInMenuOpen && (
                          <div className="dropdown">
                            <div className="dropdown-item">Preview Letter</div>
                            <div className="dropdown-item" onClick={() => {
                              try {
                                setEmailData({
                                  to: quoteData?.insured_email_xx,
                                  cc: `${quoteData?.carrier_email_xx},${currentUser?.usr_email}`,
                                  bcc: '',
                                  subject: `${plNo} Expiring In ${quoteData.expiring_in_days} days`,
                                  show_attach: "Y",
                                  attached_urls: [],
                                  binary_docs: [],
                                  doc_no: plRiskNoteNo,
                                  doc_type: plType,
                                  doc_id: quoteId,
                                  entity_codes: [selectedInsured?.value, selectedCarrier?.value],
                                  search_string: selectedInsured?.label + "," + selectedCarrier?.label + "," + plRiskNoteNo,
                                  carrierName: selectedCarrier?.label,
                                  productName: selectedProduct?.label,
                                  clientName: selectedInsured?.label,
                                  riskNoteNo: plRiskNoteNo,
                                  daysToRenewal: quoteData?.expiring_in_days,
                                  expiryDate: quoteData?.fmt_to_date,
                                  insuredName: selectedInsured?.label,
                                  outstandingPremium: quoteData?.outstandingPremium,
                                  policyCurrency: plCurCode?.value,
                                  policyNumber: plNo,
                                  policyPremium: quoteData?.fmt_policy_fc_prem,
                                  policyPremiumTotal: quoteData?.fmt_total_fc_prem,
                                  policySumInsured: quoteData?.fmt_total_fc_si,
                                  policyFromDate: quoteData?.fmt_fm_date,
                                  policyToDate: quoteData?.fmt_to_date,
                                  tpt_category: ["Policy Renewal", "Birthday"]
                                });
                                setEmailDialogOpen(true);
                              } catch (error) {
                                console.log(error)
                              }
                            }}>Send Email</div>
                            <div className="dropdown-item">Send SMS</div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="content">
                      <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(quoteData.expiring_text) }} />
                    </div>
                    <div className="footer">
                      <CustomButton onClick={(e) => {
                        try {
                          e.stopPropagation();
                          setExpiringInMenuOpen(false);
                          console.log("Learn More...")
                        } catch (error) {
                          console.log(error)
                        }
                      }} width={"80%"} height={"25px"}>Learn More</CustomButton>
                    </div>
                  </div>}
              </motion.div>
            </div>
          </div>
        </div>
      </div>
      {/* progress dialog for file upload  */}
      < UploadDialog
        open={isUploadDialogOpen}
        handleClose={handleUploadCloseDialog}
        uploadProgress={uploadProgress}
      />
      {/* Dialog to user about changes being saved */}
      < Dialog open={isNotifyDialogOpen} onClose={handleCloseNotifyDialog} >
        <DialogTitle>{notifyDialogTitle}</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(notifyDialogText) }} />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseNotifyDialog}>Close</CustomButton>
        </DialogActions>
      </Dialog >
      {/* Confirm Dialog */}
      < ConfirmDialog
        isOpen={isConfirmDialogOpen}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        dialogTitle="Confirmation"
        dialogContent={
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(objectToAction.action_text) }} />
        }
      />
      {/* check vehicle registration if already on cover */}
      {
        vehicleDialogOpen && (
          <motion.div
            className="vehicle-check-dialog"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 1 } }}
            exit={{ opacity: 0, transition: { duration: 1 } }}
          >
            <div className="vehicle-check-wrapper">
              <div className="vehicle-check-navbar">
                <span>VEHICLE CHECK</span>
                <span onClick={goBack}>
                  <IoClose
                    style={{
                      width: "30px",
                      height: "30px",
                      cursor: "pointer",
                      borderRadius: "50%",
                    }}
                  />
                </span>
              </div>
              <div className="vehicle-check-content">
                <div className="registration-no-row">
                  <input
                    ref={vehicleInputRef}
                    type="text"
                    value={vehicleDialogRegnNo}
                    placeholder="Enter Vehicle Registration No"
                    style={{ textTransform: "uppercase", width: "100px" }}
                    onChange={(e) => setVehicleDialogRegnNo(e.target.value)}
                    disabled={vehicleCheckTitle === "CHECKING..."}
                  />
                  <CustomLoadingButton
                    startIcon={<DoneAllIcon />}
                    onClick={handleVehicleCheck}
                  >
                    {vehicleCheckTitle}
                  </CustomLoadingButton>
                </div>
              </div>
              {vehicleResultView === "NoVehicleFound" && (
                <div className="no-vehicle-found">
                  <span
                    style={{ textTransform: "uppercase" }}
                  >{`No Vehicle Found with Registration ${vehicleDialogRegnNo}. Click Proceed`}</span>
                </div>
              )}
              {vehicleResultView === "VehicleFound" && (
                <motion.div
                  className="vehicle-found"
                  initial={{ opacity: 0, scale: 0, originX: 0.5, originY: 0.5 }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                    transition: { duration: 0.5 },
                  }}
                  exit={{ opacity: 0, scale: 0, transition: { duration: 0.5 } }}
                  transition={{ type: "tween", duration: 0.5 }}
                >
                  <p style={{ textTransform: "uppercase" }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(`This vehicle <b>${vehicleDialogRegnNo}</b>  was Issued under below risk notes that are still active. You  cannot Proceed`) }} />
                  <div className="vehicle-found-table">
                    <TableContainer component={Paper} className="table-container">
                      <Table sx={{ minWidth: 300 }}>
                        <TableHead className="tablehead">
                          <TableRow>
                            <TableCell
                              className="table-cell"
                              align="right"
                              style={{
                                fontWeight: "bold",
                                width: "50px",
                                textAlign: "center",
                                padding: "3px",
                              }}
                            >
                              #
                            </TableCell>
                            <TableCell
                              className="table-cell"
                              style={{
                                fontWeight: "bold",
                                maxWidth: "120px",
                                padding: "3px",
                              }}
                            >
                              Risk Note No
                            </TableCell>
                            <TableCell
                              className="table-cell"
                              style={{
                                fontWeight: "bold",
                                maxWidth: "maxContent",
                                padding: "3px",
                              }}
                            >
                              Carrier
                            </TableCell>
                            <TableCell
                              className="table-cell"
                              style={{
                                fontWeight: "bold",
                                width: "100px",
                                padding: "3px",
                              }}
                            >
                              Policy No
                            </TableCell>
                            <TableCell
                              className="table-cell"
                              style={{
                                fontWeight: "bold",
                                width: "50px",
                                alignItems: "center",
                                textAlign: "center",
                                padding: "3px",
                              }}
                            >
                              Expiry Date
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className="tablebody">
                          {vehicleMatchingRisks.map((risk, index) => (
                            <TableRow
                              key={risk._id}
                              className="tablerow"
                              style={{ padding: "12px" }}
                              onClick={() => {
                                try {
                                  console.log(
                                    `Selected Row  ${JSON.stringify(
                                      risk,
                                      null,
                                      2
                                    )}`
                                  );
                                } catch (error) {
                                  console.log(error);
                                }
                              }}
                            >
                              <TableCell
                                className="table-cell"
                                align="right"
                                style={{
                                  fontWeight: "bold",
                                  width: "50px",
                                  textAlign: "center",
                                  padding: "3px",
                                }}
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell
                                className="table-cell"
                                style={{
                                  fontWeight: "bold",
                                  maxWidth: "max-content",
                                  padding: "3px",
                                }}
                              >
                                {risk.risk_note_no_xx}
                              </TableCell>
                              <TableCell
                                className="table-cell"
                                style={{
                                  fontWeight: "bold",
                                  maxWidth: "max-content",
                                  padding: "3px",
                                }}
                              >
                                {risk.carrier_name_xx}
                              </TableCell>
                              <TableCell
                                className="table-cell"
                                style={{
                                  fontWeight: "bold",
                                  maxWidth: "max-content",
                                  padding: "3px",
                                }}
                              >
                                {risk.policy_no_xx}
                              </TableCell>
                              <TableCell
                                className="table-cell"
                                style={{
                                  fontWeight: "bold",
                                  maxWidth: "max-content",
                                  padding: "3px",
                                }}
                              >
                                {risk.pr_to_date.toString()}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <TablePagination
                        rowsPerPageOptions={[5]}
                        rowsPerPage={5}
                        // onRowsPerPageChange={handleChangeRowsPerPage}
                        component="div"
                        count={vehicleMatchingRisks.length}
                        rows={vehicleMatchingRisks}
                        page={vehicleRowsPerPage}
                        onPageChange={handleVehicleChangePage}
                      />
                    </TableContainer>
                  </div>
                </motion.div>
              )}
              <div className="vehicle-check-action">
                {vehicleProceedButton && (
                  <CustomButton onClick={handleVehicleCheckProceed}>
                    proceed
                  </CustomButton>
                )}
              </div>
            </div>
          </motion.div>
        )
      }

      {/* endorsement view */}
      {endDialogOpen && (
        <motion.div
          className="overlay-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="overlay-wrapper">
            <div className="overlay-navbar">
              <span>ENDORSEMENT</span>
              <span onClick={() => setEndDialogOpen(false)}>
                <IoClose
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    borderRadius: "50%",
                  }}
                />
              </span>
            </div>
            <span className="current-cover">CURRENT COVER PERIOD FROM {moment(plFmDate).format("DD-MMM-YYYY")} TO {moment(plToDate).format("DD-MMM-YYYY")}</span>
            <div className="overlay-content">
              <div className="input-item-with-label">
                <label htmlFor="pe_end_code" style={{ width: "130px" }}>
                  TYPE
                </label>
                <Select
                  id="pe_end_code"
                  name="pe_end_code"
                  styles={customSelectStyles}
                  width="500px"
                  value={selectedProductEnd}
                  onChange={(selectedOption) => {
                    try {
                      // console.log(`selectedOption ${JSON.stringify(selectedOption, null, 2)}`);
                      setSelectedProductEnd(selectedOption);
                      setEndorsementDesc(selectedOption.pe_desc);

                      // internal code that determines the behaviour of the endorsement
                      // 000 New Business



                      if (["100", "200", "300"].includes(selectedOption.pe_internal_code)) {
                        // 100 Additional Premium
                        // 200 Refund
                        // 300 General Endorsement


                        const formattedFmDate = moment(new Date(Date.now())).format(
                          "YYYY-MM-DD"
                        );
                        const formattedToDate = moment(plToDate).format(
                          "YYYY-MM-DD"
                        );

                        setEndorsementFmDate(formattedFmDate);
                        setEndorsementToDate(formattedToDate);
                        setToggleEndorsementFmDate(false);
                        setToggleEndorsementToDate(true);

                        setEndorsementFmTitle(`${selectedOption.pe_name} EFFECTIVE FROM`);
                        setEndorsementToTitle(`UNTIL END OF COVER PERIOD`);

                        calculateDateDifferenceEnd(endorsementFmDate, endorsementToDate);

                      } else if (["400"].includes(selectedOption.pe_internal_code)) {
                        // 400 Period Extension


                        let formattedFmDate = moment(plToDate).format(
                          "YYYY-MM-DD"
                        );
                        formattedFmDate = addDaysToMongoDate(formattedFmDate, 1);
                        const formattedToDate = addDaysToMongoDate(formattedFmDate, 30);

                        setEndorsementFmDate(formattedFmDate);
                        setEndorsementToDate(formattedToDate);
                        setToggleEndorsementFmDate(true);
                        setToggleEndorsementToDate(false);

                        setEndorsementFmTitle(`${selectedOption.pe_name} STARTS FROM`);
                        setEndorsementToTitle(`${selectedOption.pe_name} TILL`);

                        calculateDateDifferenceEnd(endorsementFmDate, endorsementToDate);

                      } else if (["500"].includes(selectedOption.pe_internal_code)) {
                        // 500 Period Reduction

                        // set dates
                        const formattedFmDate = moment(new Date(Date.now())).format(
                          "YYYY-MM-DD"
                        );
                        const formattedToDate = addDaysToMongoDate(plToDate, 30, "s");

                        setEndorsementFmDate(formattedFmDate);
                        setEndorsementToDate(formattedToDate);
                        setToggleEndorsementFmDate(true);
                        setToggleEndorsementToDate(false);

                        setEndorsementFmTitle(`EFFECTIVE DATE OF ${selectedOption.pe_name}`);
                        setEndorsementToTitle(`COVER NOW ENDS ON`);

                        calculateDateDifferenceEnd(endorsementFmDate, endorsementToDate);

                      } else if (["600"].includes(selectedOption.pe_internal_code)) {
                        // 600 Renewal

                        // set dates
                        let formattedFmDate = moment(plToDate).format(
                          "YYYY-MM-DD"
                        );
                        formattedFmDate = addDaysToMongoDate(formattedFmDate, 1);
                        const formattedToDate = addMonthsToMongoDate(formattedFmDate, 12);

                        setEndorsementFmDate(formattedFmDate);
                        setEndorsementToDate(formattedToDate);
                        setToggleEndorsementFmDate(true);
                        setToggleEndorsementToDate(false);

                        setEndorsementFmTitle(`${selectedOption.pe_name} STARTS FROM`);
                        setEndorsementToTitle(`TILL`);

                        calculateDateDifferenceEnd(endorsementFmDate, endorsementToDate);

                      } else if (["700"].includes(selectedOption.pe_internal_code)) {
                        // 700 Cancellation

                        const formattedFmDate = moment(plToDate).format(
                          "YYYY-MM-DD"
                        );
                        const formattedToDate = moment(new Date(Date.now())).format(
                          "YYYY-MM-DD"
                        );
                        setEndorsementFmDate(formattedFmDate);
                        setEndorsementToDate(formattedToDate);
                        setToggleEndorsementFmDate(true);
                        setToggleEndorsementToDate(false);

                        setEndorsementFmTitle(`COVER WAS TO END ON`);
                        setEndorsementToTitle(`EFFECT ${selectedOption.pe_name} AS OF`);

                        calculateDateDifferenceEnd(endorsementFmDate, endorsementToDate);

                      } else if (["800"].includes(selectedOption.pe_internal_code)) {
                        // 800 Reinstatement

                        const formattedFmDate = moment(new Date(Date.now())).format(
                          "YYYY-MM-DD"
                        );
                        const formattedToDate = addDaysToMongoDate(formattedFmDate, 30);
                        setEndorsementFmDate(formattedFmDate);
                        setEndorsementToDate(formattedToDate);
                        setToggleEndorsementFmDate(true);
                        setToggleEndorsementToDate(false);

                        setEndorsementFmTitle(`${selectedOption.pe_name} STARTS FROM`);
                        setEndorsementToTitle(`TILL`);

                        calculateDateDifferenceEnd(endorsementFmDate, endorsementToDate);

                      } else if (["900"].includes(selectedOption.pe_internal_code)) {
                        // 900 Lapse
                        let formattedFmDate = moment(plToDate).format(
                          "YYYY-MM-DD"
                        );
                        formattedFmDate = addDaysToMongoDate(formattedFmDate, 1);
                        const formattedToDate = moment(plToDate).format(
                          "YYYY-MM-DD"
                        );
                        setEndorsementFmDate(formattedFmDate);
                        setEndorsementToDate(formattedToDate);
                        setToggleEndorsementFmDate(true);
                        setToggleEndorsementToDate(true);

                        setEndorsementFmTitle(`${selectedOption.pe_name} FROM`);
                        setEndorsementToTitle(`END OF COVER`);

                        calculateDateDifferenceEnd(endorsementFmDate, endorsementToDate);

                      }


                    } catch (error) {
                      console.log(error);
                    }
                  }}
                  options={productEnds}
                  isSearchable={true}
                  placeholder="TYPE"
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "flex-end",
                }}
              >
                <div className="input-item-with-label">
                  <label htmlFor="pe_fm_date" style={{ width: "max-content", textTransform: "uppercase" }}>
                    {endorsementFmTitle}
                  </label>
                  <input
                    id="pe_fm_date"
                    name="pe_fm_date"
                    type="date"
                    placeholder={endorsementFmTitle}
                    value={endorsementFmDate}
                    className="form-input-item"
                    style={{ width: "170px" }}
                    onChange={handleEndFmDateChange}
                    disabled={toggleEndorsementFmDate}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "flex-end",
                }}
              >
                <div className="input-item-with-label">
                  <label htmlFor="pe_to_date" style={{ width: "max-content", textTransform: "uppercase" }}>
                    {endorsementToTitle}
                  </label>
                  <input
                    id="pe_to_date"
                    name="pe_to_date"
                    type="date"
                    placeholder={endorsementToTitle}
                    value={endorsementToDate}
                    className="form-input-item"
                    style={{ width: "170px" }}
                    onChange={handleEndToDateChange}
                    disabled={toggleEndorsementToDate}
                  />
                </div>
              </div>

              <div className="input-item-with-label">
                <label
                  htmlFor="pe_end_desc"
                  className="form-dropdown-label"
                  style={{ width: "130px", alignSelf: "flex-start" }}
                >
                  REMARKS
                </label>
                <textarea
                  id="pe_end_desc"
                  name="pe_end_desc"
                  type="text"
                  placeholder="DESCRIPTION"
                  value={endorsementDesc}
                  row={10}
                  className="form-input-item"
                  style={{ width: "500px" }}
                  onChange={(e) => setEndorsementDesc(e.target.value)}
                />
              </div>
            </div>
            <div className="overlay-action">
              <div className="input-item-with-label" style={{ display: "flex", width: "100%", alignSelf: "flex-start" }}>
                <label htmlFor="pe_gl_date" style={{ width: "130px" }}>
                  GL DATE
                </label>
                <input
                  id="pe_gl_date"
                  name="pe_gl_date"
                  type="date"
                  placeholder="DATE"
                  value={endorsementGlDate}
                  className="form-input-item"
                  style={{ width: "170px" }}
                  onChange={handleEndGlDateChange}
                  disabled={toggleEndorsementGlDate}
                />
              </div>
              {selectedProductEnd && (
                <CustomLoadingButton onClick={handlePassEndorsement}>
                  proceed
                </CustomLoadingButton>
              )}
            </div>
          </div>
        </motion.div>
      )}
      {validationMsgDialog && (
        <motion.div
          className="overlay-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="overlay-wrapper">
            <div className="overlay-navbar">
              <span>MESSAGES</span>
              <span onClick={() => setValidationMsgDialog(false)}>
                <IoClose
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    borderRadius: "50%",
                  }}
                />
              </span>
            </div>
            <div className="overlay-content">
              <ValidationMessages messageData={validationMessagesResult} />
            </div>
          </div>
        </motion.div>
      )}
      {
        isProcessing && (
          <motion.div
            className="processing-dialog"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 1 } }}
            exit={{ opacity: 0, transition: { duration: 1 } }}
          >
            <div className="processing-wrapper">
              <GridLoader
                color="#F44336"
                style={{
                  height: "50px",
                  width: "50px",
                }}
              />
            </div>
          </motion.div>
        )
      }
      {/* email component */}
      {emailDialogOpen && (
        <motion.div
          className="overlay-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="overlay-wrapper">
            <div className="overlay-navbar">
              <span>EMAIL</span>
              <span onClick={() => setEmailDialogOpen(false)}>
                <IoClose
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    borderRadius: "50%",
                  }}
                />
              </span>
            </div>
            <div className="overlay-content">
              <EmailComponent setEmailDialogOpen={setEmailDialogOpen} emailData={emailData} />
            </div>
          </div>
        </motion.div>
      )}
      {pdfPreviewDialogOpen && (
        <motion.div
          className="overlay-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="overlay-wrapper pdf">
            <div className="overlay-navbar">
              <div className="left">
                <span>PREVIEW</span>
              </div>
              <div className="right">
                <CustomLoadingButton startIcon={<EmailIcon />} onClick={() => {
                  try {
                    const urlString = pdfUrl.toString().replace("blob:", "");
                    setEmailData({
                      to: quoteData?.insured_email_xx,
                      cc: `${currentUser?.usr_email}`,
                      bcc: '',
                      subject: `Risk Note No ${plRiskNoteNo}`,
                      show_attach: "N",
                      attached_urls: [{ id: 1, url: urlString, name: `Risk Note No ${plRiskNoteNo}`, binary_docid: binaryDocid }],
                      binary_docs: [{ doc_id: binaryDocid, doc_name: `Risk Note No ${plRiskNoteNo}`, doc_type: "pdf", doc_url: "", doc_size_kb: 0 }],
                      doc_no: plRiskNoteNo,
                      doc_type: plType,
                      doc_id: quoteId,
                      entity_codes: [selectedInsured?.value, selectedCarrier?.value],
                      search_string: selectedInsured?.label + "," + selectedCarrier?.label + "," + plRiskNoteNo,
                      carrierName: selectedCarrier?.label,
                      productName: selectedProduct?.label,
                      clientName: selectedInsured?.label,
                      riskNoteNo: plRiskNoteNo,
                      daysToRenewal: quoteData?.expiring_in_days,
                      expiryDate: quoteData?.fmt_to_date,
                      insuredName: selectedInsured?.label,
                      outstandingPremium: quoteData?.outstandingPremium,
                      policyCurrency: plCurCode?.value,
                      policyNumber: plNo,
                      policyPremium: quoteData?.fmt_policy_fc_prem,
                      policyPremiumTotal: quoteData?.fmt_total_fc_prem,
                      policySumInsured: quoteData?.fmt_total_fc_si,
                      policyFromDate: quoteData?.fmt_fm_date,
                      policyToDate: quoteData?.fmt_to_date,
                      tpt_category: ["Policy Renewal", "Birthday"]
                    });

                    setPdfPreviewDialogOpen(false);
                    setEmailDialogOpen(true);
                  } catch (error) {
                    console.log(error)
                  }
                }}>
                  Email to Insured
                </CustomLoadingButton>
                <CustomLoadingButton startIcon={<EmailIcon />} onClick={() => {
                  try {
                    const urlString = pdfUrl.toString().replace("blob:", "");
                    setEmailData({
                      to: quoteData?.carrier_email_xx,
                      cc: `${currentUser?.usr_email}`,
                      bcc: '',
                      subject: `Risk Note No ${plRiskNoteNo}`,
                      show_attach: "N",
                      attached_urls: [{ id: 1, url: urlString, name: `Risk Note No ${plRiskNoteNo}` }],
                      binary_docs: [{ doc_id: binaryDocid, doc_name: `Risk Note No ${plRiskNoteNo}`, doc_type: "pdf", doc_url: "", doc_size_kb: 0 }],
                      doc_no: plRiskNoteNo,
                      doc_type: plType,
                      doc_id: quoteId,
                      entity_codes: [selectedInsured?.value, selectedCarrier?.value],
                      search_string: selectedInsured?.label + "," + selectedCarrier?.label + "," + plRiskNoteNo,
                      carrierName: selectedCarrier?.label,
                      productName: selectedProduct?.label,
                      clientName: selectedInsured?.label,
                      riskNoteNo: plRiskNoteNo,
                      daysToRenewal: quoteData?.expiring_in_days,
                      expiryDate: quoteData?.fmt_to_date,
                      insuredName: selectedInsured?.label,
                      outstandingPremium: quoteData?.outstandingPremium,
                      policyCurrency: plCurCode?.value,
                      policyNumber: plNo,
                      policyPremium: quoteData?.fmt_policy_fc_prem,
                      policyPremiumTotal: quoteData?.fmt_total_fc_prem,
                      policySumInsured: quoteData?.fmt_total_fc_si,
                      policyFromDate: quoteData?.fmt_fm_date,
                      policyToDate: quoteData?.fmt_to_date,
                      tpt_category: ["Policy Renewal", "Birthday"]
                    });
                    setPdfPreviewDialogOpen(false);
                    setEmailDialogOpen(true);
                  } catch (error) {
                    console.log(error)
                  }
                }}>
                  Email to Carrier
                </CustomLoadingButton>
                <CustomLoadingButton
                  startIcon={<GetAppIcon />}
                  onClick={() => {
                    try {
                      const link = document.createElement('a');
                      link.href = pdfUrl;
                      link.download = `Risk Note ${plRiskNoteNo}.pdf`;
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    } catch (error) {
                      console.log(error)
                    }
                  }}>
                  Download
                </CustomLoadingButton>
                <CircularButton onClick={() => setPdfPreviewDialogOpen(false)} icon={<IoClose />} width={"35px"} height={"35px"} />
              </div>
            </div>
            <div className="overlay-content-pdf" >
              <PdfPreview pdfUrl={pdfUrl} />
            </div>
            <div className="overlay-action">
            </div>
          </div>
        </motion.div>
      )}
      {isConfettiActive && <Confetti />}
    </motion.div >
  );
};

export default UwPolicy;
