// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.FIREBASE_KEY,
  authDomain: "qillo-3dcd0.firebaseapp.com",
  projectId: "qillo-3dcd0",
  storageBucket: "qillo-3dcd0.appspot.com",
  messagingSenderId: "610061196066",
  appId: "1:610061196066:web:09a60ab4aad1cef18a637b"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;
