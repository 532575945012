import "./AllEntityHome.scss";
import CircularButton, { CustomButton, SearchButton } from "../../../components/ui/CustomButton";
import app from "../../../utils/firebase";
import newRequest from "../../../utils/newRequest";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import GetAppIcon from "@mui/icons-material/GetApp";
import SearchIcon from "@mui/icons-material/Search";
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import TagIcon from '@mui/icons-material/Tag';
import { FaPeopleRoof } from "react-icons/fa6";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Tooltip,
  LinearProgress,
} from "@mui/material";
import { motion } from "framer-motion";
import { IoClose } from "react-icons/io5";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GridLoader } from "react-spinners";
import VideoPlayer from "../../../components/videoPlayer/VideoPlayer.jsx";

const AllEntityHome = () => {
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [entitiesExists, setEntitiesExists] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [entities, setEntities] = useState([]);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
  const [notifyDialogTitle, setNotifyDialogTitle] = useState("Confirm");
  const [notifyDialogText, setNotifyDialogText] = useState("Text");

  const handleCloseNotifyDialog = () => {
    setIsNotifyDialogOpen(false);
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleOpenOverlay = () => {
    setIsOverlayOpen(true);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const filteredEntitiesData = entities.filter(
    (item) =>
      item.ent_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.ent_id_no?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.ent_tax_no?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.ent_phone_no?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.ent_email?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const slicedEntitiesData = filteredEntitiesData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const [video, setVideo] = useState(undefined);
  const [videoPerc, setVideoPerc] = useState(0);
  const [inputs, setInputs] = useState({});

  const uploadFile = (file, urlType) => {
    const storage = getStorage(app);
    const fileName = new Date().getTime() + file.name;
    const storageRef = ref(storage, "Videos/" + fileName);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setVideoPerc(Math.round(progress));
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => { },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setInputs((prev) => {
            return { ...prev, [urlType]: downloadURL };
          });
        });
      }
    );
  };

  useEffect(() => {
    video && uploadFile(video, "videoUrl");
  }, [video]);

  const fetchEntities = async () => {
    try {
      setIsLoading(true);
      const res = await newRequest.post(
        "/allEntity/findAll",
        {
          ent_aent_code: "",
          result_limit: 4
        }
      );
      setEntities(res.data);

      if (res.data?.length > 0) {
        setEntitiesExists(true)
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEntities();
  }, []);

  const handleSearchEntities = async () => {
    try {
      if (searchTerm) {
        setIsLoading(true);
        const res = await newRequest.post(
          "/allEntity/findBySearch",
          {
            search_input: searchTerm,
            multiple_fields: "Y"
          }
        );
        setEntities(res.data);
      } else {
        // setNotifyDialogTitle("Note!");
        // setNotifyDialogText(`Please enter full name!`);
        // setIsNotifyDialogOpen(true);
        // return;
        fetchEntities();
      }
    } catch (error) {
      console.error("Error searching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <motion.div
      className="all-entity-home"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "200px",
            width: "100%",
            height: "100%",
          }}
        >
          <GridLoader
            color="#F44336"
            style={{
              height: "50px",
              width: "50px",
            }}
          />
        </div>
      ) : (
        <div className="list-wrapper" >
          <div className="list-navbar">
            <span className="main-title">Entities</span>
            <CustomButton
              startIcon={<AddIcon />}
              onClick={() => {
                try {
                  navigate(`/entity/new`, {
                    state: null,
                  });
                } catch (error) {
                  console.log(error)
                }
              }}
            >
              New Entity
            </CustomButton>
          </div>
          <div className="list-content">
            <FaPeopleRoof className="icon" />
            <span className="title">Clients & Partners</span>
            <p>Start building your client and business partners base and keep track of all their details in one place. Add your first client to get started.</p>
            <div className="search-input-wrapper">
              <SearchIcon />
              <input
                className="search-input"
                type="text"
                placeholder="Search entities..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') handleSearchEntities();
                }}
              />
              {searchTerm.length > 0 && (
                <CloseIcon
                  onClick={(e) => {
                    try {
                      fetchEntities();
                      setSearchTerm("");
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                  className="search-input-icon"
                />
              )}
              {searchTerm.length === 0 && (
                <span className="search-input-icon" />
              )}
              <SearchButton
                width={"200px"}
                themeColor={"#9c27b0"}
                onClick={handleSearchEntities}>
                Search
              </SearchButton>
            </div>
            <div className="cards-wrapper">
              {entities.map((row) => (
                <div className="single-card"
                  key={row._id}
                  onClick={() => {
                    try {
                      navigate(`/entity/${row._id}`, {
                        state: { shouldFetchClients: false },
                      });
                    } catch (error) {
                      console.log(error)
                    }
                  }}
                >
                  <div className="single-card-top">
                    <div className="single-card-top-left">
                      {row?.ent_photo_url && <img
                        src={row?.ent_photo_url}
                        alt=""
                        className="img"
                      />}
                      {!row?.ent_photo_url && <BrokenImageOutlinedIcon style={{ width: "60px", height: "60px" }} />}
                    </div>
                    <div className="single-card-top-right">
                      <span className="user-full-name">{row?.ent_name}</span>
                      <span className="user-type">{`${row.aent_name_xx}`}</span>
                    </div>
                  </div>
                  <div className="single-card-center">
                    <div className="single-card-center-row">
                      <span className="user-title">Type</span>
                      <span className="user-title">Status</span>
                    </div>
                    <div className="single-card-center-row">
                      <span className="user-title-value">{row.ent_type}</span>
                      <span className="user-title-value">{row.ent_status}</span>
                      {/* <span className={`status ${row.ent_status}`}>{row.ent_status}</span> */}
                    </div>
                    <div className="single-card-center-col">
                      <div className="email-phone-row">
                        <TagIcon style={{ color: '#f44336' }} /> <span>{row.ent_code}</span>
                      </div>
                      <div className="email-phone-row">
                        <LocalPhoneIcon style={{ color: '#9c27b0' }} /> <span>{row.ent_phone_no}</span>
                      </div>
                      <div className="email-phone-row">
                        <EmailIcon style={{ color: '#FAE27C' }} /> <span>{row.ent_email}</span>
                      </div>
                    </div>
                  </div>
                  <div className="single-card-bottom">
                    <div className="bottom-title">
                      <span>worked on by...</span>
                    </div>
                    <div className="avatar-container">
                      <div className="left">
                        {row?.updated_by_photo_url_xx ? <img
                          src={row?.updated_by_photo_url_xx}
                          alt=""
                          className="avatar"
                        /> : <BrokenImageOutlinedIcon style={{ width: "30px", height: "30px" }} />}
                      </div>
                      <div className="right">
                        <span className="username">{row.updated_by_name_xx}</span>
                        <span className="time-ago">{row.time_ago_xx}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="list-footer">
              <button className="overlay-button" onClick={handleOpenOverlay}>
                How it works
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Dialog to notify user about changes being saved */}
      <Dialog open={isNotifyDialogOpen} onClose={handleCloseNotifyDialog}>
        <DialogTitle>{notifyDialogTitle}</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: notifyDialogText }} />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseNotifyDialog}>Close</CustomButton>
        </DialogActions>
      </Dialog>
      {isOverlayOpen && (
        <motion.div
          className="overlay-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="overlay-wrapper">
            <div className="overlay-navbar">
              <span>HELP</span>
              <span onClick={() => setIsOverlayOpen(false)}>
                <IoClose
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    borderRadius: "50%",
                  }}
                />
              </span>
            </div>
            <div className="overlay-content">
              <VideoPlayer videoUrl="https://www.youtube.com/embed/PPCfDe8TfJQ" />
            </div>
            <div className="overlay-action">
            </div>
          </div>
        </motion.div>
      )}
    </motion.div>


  );
};

export default AllEntityHome;
