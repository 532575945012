import "./ValidationMessages.scss";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
} from "@mui/material";
import { useState } from "react";
import DOMPurify from "dompurify";

const ValidationMessages = ({ messageData }) => {
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  console.log(`ValidationMessages messageData ${JSON.stringify(messageData, null, 2)}`);
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 6));
    setCurrentPage(0);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const filteredData = messageData?.result_msg_list.filter(
    (item) =>
      item?.msg_no?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item?.msg_text?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const slicedData = filteredData.slice(
    currentPage * rowsPerPage,
    currentPage * rowsPerPage + rowsPerPage
  );

  // exit page
  const goBack = () => {
    navigate(-1);
  };
  // console.log(`ValidationMessages  ${JSON.stringify(messageData, null, 2)}`);

  return (
    <motion.div
      className="validation-message"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >

      <span className="validation-message-title" dangerouslySetInnerHTML={{ __html: messageData.result_msg }} />
      <div className="validation-message-content">
        <div className="table-content">
          <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none' }}
            className="table-container">
            <Table sx={{ width: "max-content", border: 'none' }}>
              <TableHead className="tablehead">
                <TableRow
                  className="tablerow"
                >
                  <TableCell className="table-cell" style={{
                    display: "flex",
                    maxWidth: "50px",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: "bold",
                  }}>
                    #
                  </TableCell>
                  <TableCell className="table-cell" style={{
                    display: "flex",
                    maxWidth: "80px",
                    justifyContent: "center",
                  }}>
                    CODE
                  </TableCell>
                  <TableCell className="table-cell" style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "auto",
                    maxWidth: "650px"
                  }}>
                    EXCEPTION
                  </TableCell>
                  <TableCell className="table-cell" style={{
                    display: "flex",
                    maxWidth: "80px",
                    justifyContent: "center",
                  }}>
                    TYPE
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="tablebody">
                {slicedData?.map((row, index) => (
                  <TableRow
                    key={row.msg_index}
                    className={`${selectedRow?.msg_index === row.msg_index ? "tablerow current" : "tablerow"}`}
                    onClick={() => {
                      try {
                        setSelectedRow(row)
                      } catch (error) {
                        console.log(error)
                      }
                    }}
                  >
                    <TableCell className="table-cell"
                      style={{
                        display: "flex",
                        maxWidth: "50px",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: "bold",
                      }}>
                      {currentPage * rowsPerPage + index + 1}
                    </TableCell>
                    <TableCell className="table-cell" style={{
                      display: "flex",
                      maxWidth: "80px",
                      justifyContent: "center",
                    }}>
                      <span>{row.msg_no}</span>
                    </TableCell>
                    <TableCell className="table-cell" style={{
                      display: "flex",
                      width: "auto",
                      maxWidth: "650px"
                    }}>
                      <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(row.msg_text) }} />
                    </TableCell>
                    <TableCell className="table-cell" style={{
                      display: "flex",
                      maxWidth: "80px",
                      justifyContent: "center",
                    }}>
                      <span>{row.msg_type}</span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[6]}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              component="div"
              count={filteredData.length}
              page={currentPage}
              onPageChange={handleChangePage}
            />
          </TableContainer>
        </div>
      </div>
    </motion.div>
  );
};

export default ValidationMessages;
