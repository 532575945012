import "./MarketingHome.scss";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import newRequest from "../../utils/newRequest";
import { FiShield } from "react-icons/fi";
import { getIconComponent } from "../../utils/getIconComponent";
import SearchIcon from "@mui/icons-material/Search";
//sub class covers
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
//class details
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { SlArrowDown } from "react-icons/sl";

const MarketingHome = () => {
  const location = useLocation();
  const parts = location.pathname.split("/");
  const currentPath = parts[parts.length - 1];
  const { currentUser } = useSelector((state) => state.user);
  const [classesWithSub, setClassesWithSub] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedSubClass, setSelectedSubClass] = useState({});
  const [subClassCovers, setSubClassCovers] = useState([]);
  const [selectedSubClassCover, setSelectedSubClassCover] = useState({});

  const fetchClassesWithSub = async () => {
    try {
      const params = {
        mc_org_code: currentUser.usr_org_code,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await newRequest.post("/uwClass/findWithSub", params, config);
      console.log(res.data);
      setClassesWithSub(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchSubClassCovers = async (mcCode, scCode) => {
    try {
      const params = {
        cc_org_code: currentUser.usr_org_code,
        cc_mc_code: mcCode,
        cc_sc_code: scCode,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await newRequest.post(
        "/uwSubClassCover/findAll",
        params,
        config
      );
      const objectString = JSON.stringify(res.data, null, 2);
      console.log(`found sub class covers ${objectString}`);

      setSubClassCovers(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchClassesWithSub();
  }, [currentUser.usr_org_code]);

  useEffect(() => {
    const filteredResults = classesWithSub.filter((item) => {
      // const mcMatches =
      //   item.mc_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      //   item.mc_code?.toLowerCase().includes(searchTerm.toLowerCase());

      const subClassMatches = item.subClasses.some(
        (subClass) =>
          subClass.sc_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          subClass.sc_code?.toLowerCase().includes(searchTerm.toLowerCase())
      );

      //return mcMatches || subClassMatches;
      return subClassMatches;
    });

    setFilteredData(filteredResults);
  }, [classesWithSub, searchTerm]);

  return (
    <div className="marketing-home">
      <div className="left">
        <div className="menu">
          <div className="search-input-wrapper">
            <SearchIcon className="search-input-icon" />
            <input
              className="search-input"
              type="text"
              placeholder="Search..."
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="class-details-wrapper">
            {filteredData.map((singleClass) => (
              <div className="class-item" key={singleClass._id}>
                <Accordion>
                  <AccordionSummary
                    className="class-item-title"
                    expandIcon={
                      <SlArrowDown className="content-accordion-expand-icon" />
                    }
                    id="panel-header"
                  >
                    <Typography className="class-item-title">
                      {singleClass.mc_name} [{singleClass.mc_code}]
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {singleClass.subClasses.map((subClass) => (
                      <div
                        className={`${
                          subClass.sc_doc_id === selectedSubClass.sc_doc_id
                            ? "single-sublass active"
                            : "single-sublass"
                        }`}
                        key={subClass.sc_doc_id}
                        onClick={() => {
                          try {
                            const updatedSubClass = {
                              ...subClass,
                              mc_name_xx: singleClass.mc_name,
                              mc_code_xx: singleClass.mc_code,
                            };
                            setSelectedSubClass(updatedSubClass);
                            fetchSubClassCovers(
                              singleClass.mc_code,
                              subClass.sc_code
                            );
                          } catch (error) {
                            console.log(error);
                          }
                        }}
                      >
                        <div className="single-sublass-icon">
                          {getIconComponent(subClass.sc_icon, 40) || (
                            <FiShield size={40} />
                          )}
                        </div>
                        <div className="single-sublass-content">
                          <div
                            className="single-sublass-title"
                            title={subClass.sc_name}
                          >
                            <span className="name">{subClass.sc_name}</span>
                            <span className="code">{subClass.sc_code}</span>
                          </div>
                          <div className="single-sublass-desc">
                            {subClass.sc_desc}
                          </div>
                        </div>
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="right">
        <div className="no-sub-selected">
          <div className="no-sub-selected-wrapper">
            {Object.keys(selectedSubClass).length === 0 && (
              // Render your component here if selectedSubClass is empty
              <h1>Please Select Sub Class to View Details</h1>
            )}
          </div>{" "}
        </div>

        <div className="sub-selected">
          {Object.keys(selectedSubClass).length > 0 && (
            // Render your component here if selectedSubClass is empty
            <div className="sub-class-form">
              <div className="sub-class-details">
                <span className="code"> {selectedSubClass.sc_code} </span>
                <span className="name"> {selectedSubClass.sc_name}</span>
                <span className="name"> Applicable Covers</span>
              </div>
              <div className="sub-class-cover-details">
                {subClassCovers.map((subClassCover) => (
                  <div key={subClassCover._id}>
                    <div
                      className={`${
                        subClassCover._id === selectedSubClassCover._id
                          ? "single-sublass-cover active"
                          : "single-sublass-cover"
                      }`}
                      key={subClassCover._id}
                      onClick={() => {
                        try {
                          setSelectedSubClassCover(subClassCover);
                          // fetchSubClassCoverSmi(
                          //   singleClass.mc_code,
                          //   subClass.sc_code
                          // );
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                    >
                      <div className="single-sublass-cover-icon">
                        {getIconComponent(subClassCover.cc_icon, 40) || (
                          <FiShield size={40} />
                        )}
                      </div>
                      <div className="single-sublass-cover-content">
                        <div
                          className="single-sublass-cover-title"
                          title={subClassCover.cc_name}
                        >
                          <span className="name">{subClassCover.cc_name}</span>
                          <span className="code">{subClassCover.cc_code}</span>
                        </div>
                        <div className="single-sublass-cover-desc">
                          {subClassCover.cc_desc}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MarketingHome;
