import "./CmHome.scss";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import CmClaimList from "../../cm/cmClaimList/CmClaimList";
import CmDb from "../../cm/cmDb/CmDb";
import AllEntityHome from "../../all/allEntityHome/AllEntityHome.jsx";
import AllFilesList from "../../../components/allFIlesList/AllFilesList.jsx";
import GlCalendar from "../../gl/glCalendar/GlCalendar.jsx";
import GlCurrency from "../../gl/glCurrency/GlCurrency.jsx";
import AllEntityCatg from "../../all/allEntityCatg/AllEntityCatg.jsx";
import AdSysCode from "../../ad/adSysCode/AdSysCode.jsx";

const CmHome = ({ menus }) => {
  const location = useLocation();
  const { state } = location;
  const roleMenus = state && state.roleMenus ? state.roleMenus : [];

  const [homeView, setHomeView] = useState(() => {
    const initialView = {
      menu_id: roleMenus[0].group_menus[0]._id,
      menu_function: roleMenus[0].group_menus[0].menu_function,
    };
    const storedView = sessionStorage.getItem("CmHome-homeView"); // Check if there's a stored view

    return storedView ? JSON.parse(storedView) : initialView; // If stored view exists, use it; otherwise, use initial view
  });

  useEffect(() => {
    sessionStorage.setItem("CmHome-homeView", JSON.stringify(homeView));
  }, [homeView]);

  // console.log(`homeView  ${JSON.stringify(homeView, null, 2)}`);
  const handleNavigation = (menuItem) => {
    try {
      // console.log(`menuItem  ${JSON.stringify(menuItem, null, 2)}`);
      setHomeView({
        menu_id: menuItem._id,
        menu_function: menuItem.menu_function,
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="cm-home">
      <div className="cm-home-main-wrapper">
        <div className="left-wrapper">
          <div className="left-content">
            <div className="menu">
              {roleMenus.map((item) => (
                <div className="item" key={item._id}>
                  <span className="title">{item.menu_group_name}</span>
                  {item.group_menus.map((listItem) => (
                    <button
                      onClick={() => handleNavigation(listItem)}
                      className={`${homeView.menu_id === listItem._id
                          ? "listItem active"
                          : "listItem"
                        }`}
                      key={listItem._id}
                    >
                      <img src={listItem.icon} alt="" />
                      <span className="listItemTitle">{listItem.menu_name}</span>
                    </button>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="center-wrapper">
          {homeView.menu_function === "cmClaimList" && <CmClaimList />}
          {homeView.menu_function === "cmDb" && <CmDb />}
          {homeView.menu_function === "entityCatg" && <AllEntityCatg />}
          {homeView.menu_function === "clientsHome" && <AllEntityHome />}
          {homeView.menu_function === "allFiles" && <AllFilesList />}
          {homeView.menu_function === "glCalendar" && <GlCalendar />}
          {homeView.menu_function === "glCurrency" && <GlCurrency />}
          {homeView.menu_function === "sysCodes" && <AdSysCode />}
        </div>
      </div>
    </div>
  );
};

export default CmHome;
