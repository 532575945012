import "./CmClaimList.scss";
import ConfirmDialog from "../../../components/confirmDialog/confirmDialog.jsx";
import CloseIcon from "@mui/icons-material/Close";
import { CustomButton, SearchButton } from "../../../components/ui/CustomButton";
import newRequest from "../../../utils/newRequest";
import ClaimSearchResults from "./ClaimSearchResults.jsx";
import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import { AiFillFire } from "react-icons/ai";
import { motion } from "framer-motion";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { GridLoader, ScaleLoader } from "react-spinners";
import VideoPlayer from "../../../components/videoPlayer/VideoPlayer.jsx";

const CmClaimList = () => {
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredClaimData, setFilteredClaimData] = useState([]);
  const [claimPage, setClaimPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const [claims, setClaims] = useState([]);
  const [objectToAction, setObjectToAction] = useState({
    object_id: "",
    action_text: "",
    new_doc_status: "",
    param1: "",
    param2: "",
  });
  const [invokedAction, setInvokedAction] = useState("");
  const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
  const [notifyDialogTitle, setNotifyDialogTitle] = useState("Confirm");
  const [notifyDialogText, setNotifyDialogText] = useState("Text");
  // register new claim
  const [newClaimSearchScreen, setNewClaimSearchScreen] = useState(false);
  const [newClaimSearchTerm, setNewClaimSearchTerm] = useState("");
  const [isConfirmNewDialogOpen, setIsConfirmNewDialogOpen] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [lossDate, setLossDate] = useState(null);
  const [intimationDate, setIntimationDate] = useState(null);
  const [selectedCover, setSelectedCover] = useState(null);
  const [description, setDescription] = useState(null);
  const [isDatesDialogOpen, setIsDatesDialogOpen] = useState(false);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  let generatedDocNo = "";

  const handleRiskCoverSelect = (cover) => {
    try {

      if (!cover.pl_no) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`<strong>You must specify POLICY Number for risk note ${cover.pl_risk_note_no} before you can register any claim</strong>. This has to be done in underwriting!`);
        setIsNotifyDialogOpen(true);
        return;
      }
      console.log(`selected cover  ${JSON.stringify(cover, null, 2)}`);
      setSelectedCover(cover);
      setNewClaimSearchScreen(false);
      setIsDatesDialogOpen(true);
    } catch (error) {
      console.log(error);
    }
  };

  const generateDocumentNo = async () => {
    try {
      const res = await newRequest.post(
        "/orgNumgen/gen",
        {
          gen_org_code: currentUser.usr_org_code,
          gen_type: "Claim",
        }
      );
      generatedDocNo = res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const handleRegisterClaim = async () => {
    try {

      if (!lossDate) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter LOSS DATE!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      if (!intimationDate) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter INTIMATION DATE!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      if (!description) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter DESCRIPTION!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      const formattedLossDate = new Date(moment(lossDate).format("YYYY-MM-DD"));
      formattedLossDate.setHours(0, 0, 0, 0); // Set time to midnight

      const formattedIntimationDate = new Date(
        moment(intimationDate).format("YYYY-MM-DD")
      );
      formattedIntimationDate.setHours(0, 0, 0, 0); // Set time to midnight
      const today = new Date();

      if (formattedLossDate > today) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(
          `Loss date ${moment(lossDate).format(
            "DD-MMM-YYYY"
          )} cannot be greater than today ${moment(
            today
          ).format("DD-MMM-YYYY")}!`
        );
        setIsNotifyDialogOpen(true);
        return;
      }


      if (formattedIntimationDate > today) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(
          `Intimation date ${moment(intimationDate).format(
            "DD-MMM-YYYY"
          )} cannot be greater than today ${moment(
            today
          ).format("DD-MMM-YYYY")}!`
        );
        setIsNotifyDialogOpen(true);
        return;
      }


      // console.log("formattedLossDate :", formattedLossDate);
      // console.log("formattedIntimationDate :", formattedIntimationDate);

      // Compare the dates
      if (formattedLossDate <= formattedIntimationDate) {
        console.log(
          "formattedLossDate is equal to or less than formattedIntimationDate"
        );
      } else {
        console.log(
          "formattedLossDate is greater than formattedIntimationDate"
        );
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(
          `Loss date ${moment(lossDate).format(
            "DD-MMM-YYYY"
          )} cannot be greater than intimation date ${moment(
            intimationDate
          ).format("DD-MMM-YYYY")}!`
        );
        setIsNotifyDialogOpen(true);
        return;
      }
      // console.log(
      //   `Registering claim for :  ${JSON.stringify(selectedCover, null, 2)}`
      // );
      await generateDocumentNo();
      if (!generatedDocNo) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Claim Document Number Generation is missing`);
        setIsNotifyDialogOpen(true);
        return;
      }

      console.log(`selectedCover  ${JSON.stringify(selectedCover, null, 2)}`);


      const res = await newRequest.post(
        "/cmClaim/add/",
        {
          cm_org_code: currentUser.usr_org_code,
          cm_our_ref: generatedDocNo,
          cm_claim_no: "Unspecified",
          cm_policy_no: selectedCover.pl_no,
          cm_risk_note_no: selectedCover.rc_risk_note_no,
          cm_risk_note_docid: selectedCover.rc_policy_docid,
          cm_loss_date: formattedLossDate,
          cm_intimation_date: formattedIntimationDate,
          cm_description: description,
          cm_risk_code: selectedCover.rc_risk_code,
          cm_risk_docid: selectedCover.rc_risk_docid,
          cm_cover_code: selectedCover.rc_cc_code,
          cm_cover_docid: selectedCover.cover_docid,
          cm_entity_name: selectedCover.insured_name_xx,
          userCode: currentUser.usr_id,
        }
      );

      setLossDate(null);
      setIntimationDate(null);
      setSelectedCover(null);
      setDescription(null);
      setIsDatesDialogOpen(false);
      // navigate to newly generated claim
      navigate(`/cm/${res.data._id}`, {
        state: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

  // close notify dialog
  const handleCloseNotifyDialog = () => {
    setIsNotifyDialogOpen(false);
  };

  const handleNewClaimSearch = async () => {
    try {
      if (!newClaimSearchTerm) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter registration number!`);
        setIsNotifyDialogOpen(true);
        return;
      }
      const res = await newRequest.post(
        "/cmClaim/searchRisk/",
        {
          org_code: currentUser.usr_org_code,
          search_value: newClaimSearchTerm,
        }
      );
      setSearchResults(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenOverlay = () => {
    setIsOverlayOpen(true);
  };


  const handleNewNonMotorClaim = () => {
    try {
      console.log("New NON Motor Claim");
      setIsConfirmNewDialogOpen(false);
      setNewClaimSearchScreen(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleNewMotorClaim = () => {
    try {
      console.log("New Motor Claim");
      setIsConfirmNewDialogOpen(false);
      setNewClaimSearchScreen(true);
    } catch (error) {
      console.log(error);
    }
  };

  // columns to export
  const exportColumns = [
    "insured_name_xx",
    "pl_quote_no",
    "product_name_xx",
    "pl_status",
    "quote_stage_xx",
    "total_fc_si",
    "total_fc_premium",
    "commission_fc_amount",
    "commission_tax_fc_amount",
    "bizunit_name_xx",
  ];

  const fetchClaims = async () => {
    try {
      setIsLoading(true);
      await newRequest
        .post("/cmClaim/findAll", {
          cm_org_code: currentUser.usr_org_code,
          result_limit: 4,
          cm_status: ""
        })
        .then((res) => {
          // console.log(`setClaims  ${JSON.stringify(res.data, null, 2)}`);
          setClaims(res.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        });
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const handleSearchClaims = async () => {
    try {
      setIsLoading(true);
      if (searchTerm) {
        const res = await newRequest.post("/cmClaim/search", { search_input: searchTerm });
        setClaims(res.data);
      } else {
        fetchClaims();
      }
    } catch (error) {
      console.error("Error searching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    fetchClaims();
  }, []);


  return (
    <motion.div
      className="cm-claim-list"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      {isLoading ? (
        <div className="no-content">
          <ScaleLoader
            color="#F44336"
            height={50}
            radius={8}
            width={4}
          />
        </div>
      ) : (
        <div className="cm-claim-list-main-wrapper">
          <motion.div className="list-wrapper"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 1 } }}
            exit={{ opacity: 0, transition: { duration: 1 } }}>
            <div className="list-navbar">
              <div className="list-navbar-left"><span className="main-title">Claims</span></div>
              <div className="list-navbar-right">
                <CustomButton startIcon={<RefreshIcon />} onClick={fetchClaims}>
                  Refresh
                </CustomButton>
                <CustomButton
                  startIcon={<AddIcon />}
                  onClick={() => {
                    try {
                      setObjectToAction({
                        object_id: "",
                        action_text: "Which claim do you want to open?",
                        new_doc_status: "",
                      });
                      setIsConfirmNewDialogOpen(true);
                    } catch (error) {
                      console.log(error)
                    }
                  }}
                >
                  New Claim
                </CustomButton>
              </div>
            </div>
            <div className="list-content">
              <AiFillFire className="icon" />
              <span className="title">Create & Manage Claims</span>
              <p>Effortlessly register, track, and manage claims across all lines of business. Keep both clients and carriers informed with automated email notifications, ensuring transparency and timely updates. Simplify claim handling with our user-friendly tools, enhancing your efficiency and accuracy in managing every step of the claims process.</p>
              <div className="search-input-wrapper">
                <SearchIcon />
                <input
                  className="search-input"
                  type="text"
                  placeholder="Search template..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') handleSearchClaims();
                  }}
                />
                {searchTerm.length > 0 && (
                  <CloseIcon
                    onClick={(e) => {
                      try {
                        fetchClaims();
                        setSearchTerm("");
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                    className="search-input-icon"
                  />
                )}
                {searchTerm.length === 0 && (
                  <span className="search-input-icon" />
                )}
                <SearchButton
                  width={"200px"}
                  themeColor={"#9c27b0"}
                  onClick={handleSearchClaims}>
                  Search
                </SearchButton>
              </div>
              <div className="cards-wrapper">
                {claims.map((row) => (
                  <div className={`single-card c${row.end_internal_code_xx}`}
                    key={row._id}
                    onClick={() => {
                      try {
                        navigate(`/cm/${row._id}`, {
                          state: row,
                        });
                      } catch (error) {
                        console.log(error)
                      }
                    }}
                  >
                    <div className="single-card-top">
                      <span className="category">
                        <h1 className={`main-title c${row.cm_our_ref}`}>
                          {row.product_name_xx}
                        </h1>
                        <h1 className="sub-title-twolines" style={{ fontSize: "12px" }}>
                          {row.cm_policy_no}
                        </h1>
                      </span>
                    </div>
                    <div className="single-card-center">
                      <h1 className="sub-title-twolines" style={{ fontSize: "16px", color: "inherit", margin: "5px 0px" }}>
                        {`INSURED: ${row.insured_name_xx}`}
                      </h1>
                      <h1 className="sub-title-twolines" style={{ fontSize: "12px", margin: "7px 0px" }}>
                        {`CARRIER: ${row.carrier_name_xx}`}
                      </h1>
                      <span className="main-title">{row.cm_risk_note_no}</span>
                      <span className={`status ${row.cm_status}`}>{row.cm_status}</span>
                    </div>
                    <div className="single-card-bottom">
                      <div className="bottom-title">
                        <span>worked on by...</span>
                      </div>
                      <div className="avatar-container">
                        <div className="left">
                          <img src={row.updated_by_photo_url_xx} alt="avatar" className="avatar" />
                        </div>
                        <div className="right">
                          <span className="username">{row.updated_by_name_xx}</span>
                          <span className="time-ago">{row.time_ago_xx}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="list-footer">
                <button className="overlay-button" onClick={handleOpenOverlay}>
                  How it works
                </button>
              </div>
            </div>
          </motion.div>
          {isOverlayOpen && (
            <motion.div
              className="overlay-dialog"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 1 } }}
              exit={{ opacity: 0, transition: { duration: 1 } }}
            >
              <div className="overlay-wrapper">
                <div className="overlay-navbar">
                  <span>HELP</span>
                  <span onClick={() => setIsOverlayOpen(false)}>
                    <IoClose
                      style={{
                        width: "30px",
                        height: "30px",
                        cursor: "pointer",
                        borderRadius: "50%",
                      }}
                    />
                  </span>
                </div>
                <div className="overlay-content">
                  <VideoPlayer videoUrl="https://www.youtube.com/embed/PPCfDe8TfJQ" />
                </div>
                <div className="overlay-action">
                </div>
              </div>
            </motion.div>
          )}
        </div>
      )}
      {/* register new claim */}
      {newClaimSearchScreen && (
        <motion.div
          className="new-claim-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="new-claim-wrapper">
            <div className="new-claim-navbar">
              <span>
                <div
                  className="input-item-with-label"
                  style={{ gap: "10px" }}
                >
                  <label
                    htmlFor="newClaimSearchTerm"
                    className="form-dropdown-label"
                  >
                    REGISTRATION NO
                  </label>
                  <input
                    id="newClaimSearchTerm"
                    name="newClaimSearchTerm"
                    type="text"
                    value={newClaimSearchTerm}
                    className="form-input-item"
                    style={{ width: "275px" }}
                    onChange={(e) => setNewClaimSearchTerm(e.target.value)}
                  />
                  <CustomButton onClick={handleNewClaimSearch}>
                    Search
                  </CustomButton>
                </div>
              </span>
              <span
                onClick={() => {
                  try {
                    setNewClaimSearchScreen(false);
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                <Tooltip title="Close" placement="right">
                  <IoClose
                    style={{
                      width: "30px",
                      height: "30px",
                      cursor: "pointer",
                      borderRadius: "50%",
                    }}
                  />
                </Tooltip>
              </span>
            </div>
            <div className="new-claim-content">
              <h1 className="main-title">SEARCH RESULTS</h1>
              {searchResults?.length > 10 ? <div>
                {`Found ${searchResults?.length} Documents. Please Refine your Search`}
              </div> : <ClaimSearchResults
                risks={searchResults}
                handleSelect={handleRiskCoverSelect}
              />}
            </div>
          </div>
        </motion.div>
      )}
      {/* dates dialog */}
      {isDatesDialogOpen && (
        <motion.div
          className="new-claim-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="new-claim-wrapper">
            <div className="new-claim-navbar">
              <span>ENTER DATES & DESCRIPTION</span>
              <span
                onClick={() => {
                  try {
                    setIsDatesDialogOpen(false);
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                <Tooltip title="Close" placement="right">
                  <IoClose
                    style={{
                      width: "30px",
                      height: "30px",
                      cursor: "pointer",
                      borderRadius: "50%",
                    }}
                  />
                </Tooltip>
              </span>
            </div>
            <div className="new-claim-content">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div className="input-item-with-label">
                  <label
                    htmlFor="loss_date"
                    className="form-dropdown-label"
                    style={{ width: "120px" }}
                  >
                    LOSS DATE
                  </label>
                  <input
                    id="loss_date"
                    name="loss_date"
                    type="date"
                    placeholder="LOSS DATE"
                    value={lossDate}
                    className="form-input-item"
                    style={{ width: "170px" }}
                    onChange={(e) => setLossDate(e.target.value)}
                  />
                </div>
                <div className="input-item-with-label">
                  <label
                    htmlFor="intimation_date"
                    className="form-dropdown-label"
                    style={{ width: "120px" }}
                  >
                    INTIMATION
                  </label>
                  <input
                    id="intimation_date"
                    name="intimation_date"
                    type="date"
                    placeholder="FROM"
                    value={intimationDate}
                    className="form-input-item"
                    style={{ width: "170px" }}
                    onChange={(e) => setIntimationDate(e.target.value)}
                  />
                </div>
              </div>
              <div className="input-item-with-label">
                <label
                  htmlFor="description"
                  className="form-dropdown-label"
                  style={{ alignSelf: "flex-start", width: "120px" }}
                >
                  DESCRIPTION
                </label>
                <textarea
                  id="description"
                  name="description"
                  type="text"
                  placeholder="LOSS DESCRIPTION"
                  value={description}
                  className="form-input-item"
                  rows={3}
                  style={{ width: "465px" }}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>
            <div className="new-claim-action-withnote">
              <span>Note: These value can be changed later</span>
              <CustomButton onClick={handleRegisterClaim}>
                REGISTER
              </CustomButton>
            </div>
          </div>
        </motion.div>
      )}

      {/* new claim dialog */}
      <ConfirmDialog
        isOpen={isConfirmNewDialogOpen}
        onClose={handleNewMotorClaim}
        onConfirm={handleNewNonMotorClaim}
        dialogTitle="New Claim"
        dialogContent={<p>{objectToAction.action_text}</p>}
        agreeTitle={"Non Motor"}
        disagreeTitle={"Motor"}
      />
      {/* Dialog to notify user about changes being saved */}
      <Dialog open={isNotifyDialogOpen} onClose={handleCloseNotifyDialog}>
        <DialogTitle>{notifyDialogTitle}</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: notifyDialogText }} />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseNotifyDialog}>Close</CustomButton>
        </DialogActions>
      </Dialog>
    </motion.div>
  );
};

export default CmClaimList;
