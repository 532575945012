import "./VerifyDocument.scss";
import CountdownTimer from "../../components/countdownTimer/CountdownTimer";
import {
  CustomButtonGradient,
  CustomLoadingGradient,
} from "../../components/ui/CustomButton";
import newRequest from "../../utils/newRequest";
import { motion } from "framer-motion";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ScaleLoader } from "react-spinners";


const VerifyDocument = () => {
  const [documentData, setDocumentData] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const parts = location.pathname.split("/");
  const docType = parts[parts.length - 2];
  const docId = parts[parts.length - 1];
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const fetchDocument = async () => {
    try {
      setIsLoading(true)
      console.log(`docType ${docType}`);
      console.log(`docId ${docId}`);

      const res = await newRequest.post("/verify/doc", {
        doc_type: docType,
        doc_id: docId
      });
      console.log(`setDocumentData  ${JSON.stringify(res.data, null, 2)}`);
      setDocumentData(res.data);
      setResponse('ok');
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          console.log('not found');
          setResponse('not found');
        } else if (error.response.status === 416) {
          console.log('invalid id format');
          setResponse('invalid id format');
        } else if (error.response.status === 417) {
          console.log('invalid doc type');
          setResponse('invalid doc type');
        } else {
          console.log('An error occurred');
          setResponse('An error occurred');
        }
      } else {
        console.log('An error occurred');
        setResponse('An error occurred');
      }
    } finally {
      setIsLoading(false)
    }
  };

  useEffect(() => {
    try {
      fetchDocument();
    } catch (error) {
      console.log(error);
    }
  }, [docType, docId]);

  return (
    <div className="verify-document">
      {isLoading ? (
        <motion.div
          className="wrapper"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <ScaleLoader
            color="#F44336"
            height={50}
            radius={8}
            width={4}
          />
        </motion.div>
      ) : (
        <motion.div
          className="wrapper"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          {response === "ok" && <span className="verified">Verified Document</span>}
          {response !== "ok" && <span className="unverified">Unverified Document</span>}

          {response === "ok" && docType === "rn" &&
            <div className="risk-note">
              <span className="gradient-text">{`This is a Valid Risk Note`}</span>
              <span className="valid no">{`No ${documentData?.doc_no}`}</span>
              <span className="valid">{`Issued By ${documentData?.issuing_org_xx}`}</span>
              <span className="valid">{`Branch ${documentData?.issuing_branch_xx}`}</span>
              <span className="valid">{`Issued ${documentData?.time_ago_xx}`}</span>
              <span className="valid">{`Issued to ${documentData?.client_name_xx}`}</span>
              <span className="valid">{`Product ${documentData?.product_name_xx}`}</span>
              <span className="valid">{`Period ${documentData?.formatted_period_xx}`}</span>
            </div>}
          {response === "ok" && docType === "rcp" &&
            <div className="receipt">
              <span className="gradient-text">{`This is a Valid Receipt`}</span>
              <span className="valid no">{`No ${documentData?.doc_no}`}</span>
              <span className="valid">{`Issued By ${documentData?.issuing_org_xx}`}</span>
              <span className="valid">{`Branch ${documentData?.issuing_branch_xx}`}</span>
              <span className="valid">{`Received ${documentData?.time_ago_xx}`}</span>
              <span className="valid">{`Received From ${documentData?.client_name_xx}`}</span>
              <span className="valid">{`Total ${documentData?.doc_currency} ${documentData?.fmt_fc_amount}`}</span>
              <span className="valid">{`Total ${documentData?.doc_currency} ${documentData?.fmt_fc_amount} [${documentData?.doc_currency} ${documentData?.number_to_words_fc_xx}]`}</span>
              </div>}
          {response === "ok" && docType === "pmt" &&
            <div className="payment">
              <span className="gradient-text">{`This is a Valid Payment`}</span>
              <span className="valid no">{`No ${documentData?.doc_no}`}</span>
              <span className="valid">{`Issued By ${documentData?.issuing_org_xx}`}</span>
              <span className="valid">{`Branch ${documentData?.issuing_branch_xx}`}</span>
              <span className="valid">{`Paid ${documentData?.time_ago_xx}`}</span>
              <span className="valid">{`Paid To ${documentData?.client_name_xx}`}</span>
              <span className="valid">{`Total ${documentData?.doc_currency} ${documentData?.fmt_fc_amount} [${documentData?.doc_currency} ${documentData?.number_to_words_fc_xx}]`}</span>
 
            </div>}
          {response !== "ok" &&
            <div className="other">
              <span className="error">{`We Could not Validate Authenticity of this document`}</span>
            </div>}
        </motion.div>
      )}
    </div>
  );
};

export default VerifyDocument;
