import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentUser: null,
  currentUserAvator: null,
  showSubBanner: "N",
  loading: false,
  error: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginStart: (state) => {
      state.loading = true;
    },
    loginSuccess: (state, action) => {
      // console.log(`userSlice ${JSON.stringify(action.payload, null, 2)}`)
      state.loading = false;
      state.currentUser = action.payload;
      if (action.payload?.subscription_status_xx === 'grace_period' && action.payload?.user_type_xx === 'superadmin') {
        state.showSubBanner = "Y";
      } else {
        state.showSubBanner = "N";
      }
      if (action.payload?.usr_photo_url) {
        state.currentUserAvator = action.payload?.usr_photo_url;
      }
    },
    loginFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    logOut: (state) => {
      state.currentUser = null;
      state.loading = false;
      state.error = false;
    },
    showBanner: (state, action) => {
      state.showSubBanner = action.payload;
    },
    setAvator: (state, action) => {
      state.currentUserAvator = action.payload;
    },
  },
});

export const { loginStart, loginSuccess, loginFailure, logOut, showBanner, setAvator } =
  userSlice.actions;

export default userSlice.reducer;
