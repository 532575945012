import "./AllFiles.scss";
import app from "../../utils/firebase.js";
import newRequest from "../../utils/newRequest.js";
import ConfirmDialog from "../confirmDialog/confirmDialog";
import { Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { BiSolidFileTxt } from "react-icons/bi";
import { BiSolidFileJpg } from "react-icons/bi";
import { BiSolidFilePng } from "react-icons/bi";
import { BsFiletypeMp4 } from "react-icons/bs";
import { FaFileWord } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa6";
import { MdFolderZip } from "react-icons/md";
import { PiFilePptFill } from "react-icons/pi";
import { useSelector } from "react-redux";
import { GridLoader } from "react-spinners";

const AllFiles = ({ filesList, bgColor }) => {
  const { currentUser } = useSelector((state) => state.user);
  const [isProcessing, setIsProcessing] = useState(false);
  const [objectToAction, setObjectToAction] = useState({
    object_id: "",
    action_text: "",
    file_path: "",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const [invokedAction, setInvokedAction] = useState("");
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogAction, setConfirmDialogAction] = useState("None");
  const [filteredFileList, setFilteredFileList] = useState([]);

  useEffect(() => {
    // Set filteredFileList to filesList when filesList changes
    setFilteredFileList(filesList);
  }, [filesList]);

  const deleteFileFromStorage = async (fileLink) => {
    try {
      const storage = getStorage(app);
      // Get a reference to the file using the fileLink
      const storageRef = storage().refFromURL(fileLink);

      // Delete the file
      await storageRef.delete();

      console.log("File deleted successfully");
      return "deleted";
    } catch (error) {
      console.error("Error deleting file:", error.message);
    }
  };

  const handleConfirm = async () => {
    try {
      if (confirmDialogAction === "deleteFile") {
        setIsConfirmDialogOpen(false);
        setIsProcessing(true);
        // delete file from db
        // this function will also delete from firebase
        const res = await newRequest.delete(
          `/files/del/${objectToAction.object_id}`,
          {},
          config
        );
        const filteredArray = filteredFileList.filter(
          (obj) => obj._id !== objectToAction.object_id
        );
        setFilteredFileList(filteredArray);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsProcessing(false);
      // Close the dialog
      setIsConfirmDialogOpen(false);
    }
  };

  const handleCancel = () => {
    setIsConfirmDialogOpen(false);
  };

  return (
    <div className="all-files">
      <div className="all-files-wrapper">
        {filteredFileList?.map((singleFile) => (
          <div
            className="single-file"
            style={{ backgroundColor: bgColor }}
            key={singleFile._id}
            onClick={() => {
              try {
                window.open(singleFile.fl_path, "_blank");
              } catch (error) {
                console.log(error);
              }
            }}
          >
            <div className="single-file-title">
              <h2 className="file-name">{singleFile.fl_name}</h2>
              {singleFile.fl_type === "pdf" && <FaFilePdf className="logo" />}
              {singleFile.fl_type === "docx" && <FaFileWord className="logo" />}
              {singleFile.fl_type === "doc" && <FaFileWord className="logo" />}
              {singleFile.fl_type === "txt" && (
                <BiSolidFileTxt className="logo" />
              )}
              {singleFile.fl_type === "jpg" && (
                <BiSolidFileJpg className="logo" />
              )}
              {singleFile.fl_type === "png" && (
                <BiSolidFilePng className="logo" />
              )}
              {singleFile.fl_type === "mp4" && (
                <BsFiletypeMp4 className="logo" />
              )}

              {singleFile.fl_type === "zip" && <MdFolderZip className="logo" />}

              {singleFile.fl_type === "rar" && <MdFolderZip className="logo" />}
              {singleFile.fl_type === "pptx" && (
                <PiFilePptFill className="logo" />
              )}
            </div>
            <div className="file-category">
              <span className="title">{singleFile.fl_category}</span>
              {singleFile.createdBy === currentUser.usr_id && (
                <Tooltip
                  title={`Delete ${singleFile.fl_name}`}
                  placement="left"
                  style={{
                    backgroundColor: "blue",
                    color: "white",
                  }}
                >
                  <DeleteIcon
                    size={50}
                    style={{
                      borderRadius: "5px",
                      padding: "3px",
                      alignSelf: "center",
                      cursor: "pointer",
                      color: "rgba(244, 67, 54)",
                      backgroundColor: "rgba(244, 67, 54, 0.5)",
                    }}
                    onClick={(e) => {
                      try {
                        e.stopPropagation();
                        let action_text = `Delete ${singleFile.fl_name}?`;
                        setObjectToAction({
                          object_id: singleFile._id,
                          action_text: action_text,
                          file_path: singleFile.fl_path,
                        });
                        setConfirmDialogAction("deleteFile");
                        setIsConfirmDialogOpen(true);
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  />
                </Tooltip>
              )}
            </div>

            <div className="uploaded-by-wrapper">
              <div className="uploaded-by-user">
                <span>{`Uploaded By ${singleFile.uploaded_by_name_xx}`}</span>
                <img
                  src={
                    singleFile?.uploaded_by_photo_url_xx || "/img/noavatar.jpg"
                  }
                  alt=""
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span>{singleFile.time_ago_xx}</span>
                <span>{singleFile.file_size}</span>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Confirm Dialog */}
      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        dialogTitle="Confirmation"
        dialogContent={<p>{objectToAction.action_text}</p>}
      />
      {isProcessing && (
        <motion.div
          className="processing-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="processing-wrapper">
            <GridLoader
              color="#F44336"
              style={{
                height: "50px",
                width: "50px",
              }}
            />
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default AllFiles;
