import "./ClaimsHome.scss";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

function bytesToGB(bytes) {
  return (bytes / 1024 ** 3).toFixed(2);
}

function bytesToMB(bytes) {
  return (bytes / 1024 ** 2).toFixed(2);
}

const ClaimsHome = () => {
  const location = useLocation();
  const parts = location.pathname.split("/");
  const currentPath = parts[parts.length - 1];
  const { currentUser } = useSelector((state) => state.user);
  const [filesAndSpace, setFilesAndSpace] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("http://localhost:8800/getFilesAndSpace");
        const data = await response.json();
        setFilesAndSpace(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {filesAndSpace && (
        <>
          <h2>
            Total Space Used: {bytesToGB(filesAndSpace.totalSpace)} GB (
            {bytesToMB(filesAndSpace.totalSpace)} MB)
          </h2>
          <ul>
            {filesAndSpace.files.map((file) => (
              <li key={file.name}>
                {file.name} - {bytesToGB(file.size)} GB {bytesToMB(file.size)}{" "}
                MB
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default ClaimsHome;
