import "./EntityImport.scss";
import ErrorDialog from "../../../components/errorDialog/ErrorDialog";
import ProgressDialog from "../../../components/progressDialog/ProgressDialog";
import { CustomButton, CustomLoadingButton } from "../../../components/ui/CustomButton";
import { customSelectStyles } from "../../../components/ui/uiStyleElements";
import newRequest from "../../../utils/newRequest";
import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PublishIcon from "@mui/icons-material/Publish";
import SearchIcon from "@mui/icons-material/Search";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ClearIcon from '@mui/icons-material/Clear';
import { IoClose } from "react-icons/io5";
import { MdOutlineAttachment } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import readXlsxFile from "read-excel-file";

const EntityImport = ({ showClose = "Y" }) => {
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [excelData, setExcelData] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");
  const [openProgressDialog, setOpenProgressDialog] = useState(false);
  const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
  const [notifyDialogTitle, setNotifyDialogTitle] = useState("Confirm");
  const [notifyDialogText, setNotifyDialogText] = useState("Text");


  const goBack = () => {
    navigate(-1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Update filtered data when the search term changes
  useEffect(() => {
    const filteredResults = excelData.filter(
      (item) =>
        item?.ent_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item?.ent_id_no?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item?.ent_tax_no?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item?.ent_phone_no?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item?.ent_email?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filteredResults);
  }, [excelData, searchTerm]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [selectedEntityCatg, setSelectedEntityCatg] = useState({});
  const [entityCatgOptions, setEntityCatgOptions] = useState([]);

  useEffect(() => {
    const fetchEntityCatg = async () => {
      try {
        const res = await newRequest.post(
          "/allEntityCatg/findAll", {
          aent_org_code: currentUser.usr_org_code,
        }
        );

        const options = res.data.map((entityCatg) => ({
          value: entityCatg.aent_code,
          label: entityCatg.aent_name,
        }));

        setEntityCatgOptions(options);
      } catch (error) {
        console.error("Error fetching entity types:", error);
      }
    };
    fetchEntityCatg();
  }, [currentUser]);

  const slicedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const handleCloseNotifyDialog = () => {
    setIsNotifyDialogOpen(false);
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setUploading(true);

    try {
      const rows = await readXlsxFile(file, { sheet: 2 });

      // console.log(`rows  ${JSON.stringify(rows, null, 2)}`);

      const data = rows.slice(1).map((row) => ({
        ent_code: row[0],
        ent_name: row[1],
        ent_type: row[2],
        ent_tax_no: row[3],
        ent_phone_no: row[4],
        ent_email: row[5],
        ent_id_no: row[6],
        ent_license_no: row[7],
        ent_physical_address: row[8],
        ent_postal_address: row[9],
        ent_gender: row[10],
        result_xx: "",
      }));

      setExcelData(data);
      setUploading(false);
    } catch (error) {
      console.error("Error reading Excel file:", error);
      setUploading(false);
    }
  };

  const handleCloseDialog = () => {
    setError("");
  };

  const updateRecordStatus = (index, result_xx, ent_code) => {
    try {
      const updatedData = [...excelData];
      const currentRow = updatedData[index];
      currentRow["result_xx"] = result_xx;
      currentRow["ent_code"] = ent_code;
      setExcelData(updatedData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    try {

      if (!selectedEntityCatg?.value) {
        setError("Please select a category");
        return;
      }

      setOpenProgressDialog(true);

      const responseArray = [];
      const totalRecords = excelData.length;
      let completedRecords = 0;

      for (const record of excelData) {
        const progress = Math.round((completedRecords / totalRecords) * 100);
        setProgress(progress);

        const entity = {
          ...record,
          record_index: completedRecords,
          ent_status: "Active",
          ent_org_code: currentUser.usr_org_code,
          ent_aent_code: selectedEntityCatg.value,
          userCode: currentUser.usr_id,
        };

        let res = {};

        try {
          res = await newRequest.post("/allEntity/upload", entity);
        } catch (error) {
          console.log(error);
        }

        const responseData = await res.data;

        responseArray.push(responseData);

        completedRecords++;
      }


      let uploadedCount = 0;
      let restCount = 0;
      // loop to update response data
      for (const record of responseArray) {
        if (record.response === "Uploaded") {
          uploadedCount++;
        } else {
          restCount++;
        }

        updateRecordStatus(
          record.record_index,
          record.response,
          record.ent_code
        );
      }

      setProgress(100);
      setOpenProgressDialog(false);

      setNotifyDialogTitle("Please Note...");
      setNotifyDialogText(`<div>Total Records Processed <strong>${responseArray.length}</strong></div>
      <div>Successfully Uploaded <strong>${uploadedCount}</strong></div>
      <div>Errors <strong>${restCount}</strong></div>
      <div><br/></div>
      <div><p>Rectify the Records with errors and try again </p> </div>
      `);
      setIsNotifyDialogOpen(true);
    } catch (error) {
      console.error("Error uploading data:", error);
    }
  };


  return (
    <div className="entity-import">
      <div className="navbar">
        <div className="navbar-left">
          <div className="search-input-wrapper">
            <SearchIcon className="search-input-icon" />
            <input
              className="search-input"
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {searchTerm.length > 0 && (
              <CloseIcon
                onClick={handleClearSearch}
                className="search-input-icon"
              />
            )}
          </div>
        </div>
        <div className="navbar-right">
          {excelData?.length > 0 &&
            <CustomLoadingButton
              startIcon={<ClearIcon />}
              onClick={() => {
                try {
                  setExcelData([]);
                } catch (error) {
                  console.log(error)
                }
              }}
            >
              Clear Data
            </CustomLoadingButton>}
          <input
            type="file"
            accept=".xlsx, .xls"
            id="file"
            style={{ display: "none" }}
            onChange={handleFileUpload}
          />
          <label htmlFor="file">
            <div className="file-upload-item">
              <MdOutlineAttachment />
              <span>Select File</span>
            </div>
          </label>
          <Select
            id="ent_catg"
            name="ent_catg"
            styles={customSelectStyles}
            width="300px"
            value={selectedEntityCatg}
            onChange={(selectedOption) => {
              try {
                setSelectedEntityCatg(selectedOption);
              } catch (error) {
                console.log(error);
              }
            }}
            options={entityCatgOptions}
            isSearchable={true}
            placeholder="Category"
          />
          <CustomLoadingButton
            startIcon={<PublishIcon />}
            onClick={handleSubmit}
            disabled={excelData?.length === 0 || !selectedEntityCatg?.value || uploading}
          >
            {uploading ? "Uploading..." : "Upload"}
          </CustomLoadingButton>
          {showClose === "Y" && <CustomButton startIcon={<IoClose />} onClick={goBack}>
            Close
          </CustomButton>}
        </div>
      </div>
      <div className="content">
        <TableContainer component={Paper} className="table-container">
          <Table sx={{ minWidth: 650 }}>
            <TableHead className="tablehead">
              <TableRow>
                <TableCell
                  className="table-cell"
                  style={{ fontWeight: "bold" }}
                >
                  CODE
                </TableCell>
                <TableCell
                  className="table-cell"
                  style={{ fontWeight: "bold" }}
                >
                  NAME
                </TableCell>
                <TableCell
                  className="table-cell"
                  style={{ fontWeight: "bold" }}
                >
                  TYPE
                </TableCell>
                <TableCell
                  className="table-cell"
                  style={{ fontWeight: "bold" }}
                >
                  TAX PIN
                </TableCell>
                <TableCell
                  className="table-cell"
                  style={{ fontWeight: "bold" }}
                >
                  PHONE NO
                </TableCell>
                <TableCell
                  className="table-cell"
                  style={{ fontWeight: "bold" }}
                >
                  EMAIL
                </TableCell>
                <TableCell
                  className="table-cell"
                  style={{ fontWeight: "bold" }}
                >
                  ID NUMBER
                </TableCell>
                <TableCell
                  className="table-cell"
                  style={{ fontWeight: "bold" }}
                >
                  LICENCE NUMBER
                </TableCell>
                <TableCell
                  className="table-cell"
                  style={{ fontWeight: "bold" }}
                >
                  PHYSICAL ADDR
                </TableCell>
                <TableCell
                  className="table-cell"
                  style={{ fontWeight: "bold" }}
                >
                  POSTAL ADDR
                </TableCell>
                <TableCell
                  className="table-cell"
                  style={{ fontWeight: "bold" }}
                >
                  GENDER
                </TableCell>
                <TableCell
                  className="table-cell"
                  style={{ fontWeight: "bold" }}
                >
                  RESULT
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="tablebody">
              {slicedData.map((entity, index) => (
                <TableRow key={entity.index} className="tablerow">
                  <TableCell
                    className={`table-cell ${!entity.ent_code ? "no-code" : ""
                      }`}
                    style={{
                      fontWeight: "500",
                      maxWidth: "200px",
                      padding: "8px",
                    }}
                  >
                    {entity.ent_code || "System Gen"}
                  </TableCell>
                  <TableCell
                    className={`table-cell ${entity.result_xx === "Uploaded" ? "Uploaded" : "Error"
                      }`}
                    style={{
                      fontWeight: "500",
                      maxWidth: "200px",
                      padding: "8px",
                    }}
                  >
                    {entity.ent_name}
                  </TableCell>
                  <TableCell
                    className={`table-cell ${entity.result_xx === "Uploaded" ? "Uploaded" : "Error"
                      }`}
                    style={{
                      fontWeight: "500",
                      maxWidth: "150px",
                      padding: "8px",
                    }}
                  >
                    {entity.ent_type}
                  </TableCell>
                  <TableCell
                    className={`table-cell ${entity.result_xx === "Uploaded" ? "Uploaded" : "Error"
                      }`}
                    style={{
                      fontWeight: "500",
                      maxWidth: "150px",
                      padding: "8px",
                    }}
                  >
                    {entity.ent_tax_no}
                  </TableCell>
                  <TableCell
                    className={`table-cell ${entity.result_xx === "Uploaded" ? "Uploaded" : "Error"
                      }`}
                    style={{
                      fontWeight: "500",
                      maxWidth: "150px",
                      padding: "8px",
                    }}
                  >
                    {entity.ent_phone_no}
                  </TableCell>
                  <TableCell
                    className={`table-cell ${entity.result_xx === "Uploaded" ? "Uploaded" : "Error"
                      }`}
                    style={{
                      fontWeight: "500",
                      maxWidth: "250px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      padding: "8px",
                    }}
                  >
                    {entity.ent_email}
                  </TableCell>
                  <TableCell
                    className={`table-cell ${entity.result_xx === "Uploaded" ? "Uploaded" : "Error"
                      }`}
                    style={{
                      fontWeight: "500",
                      maxWidth: "150px",
                      padding: "8px",
                    }}
                  >
                    {entity.ent_id_no}
                  </TableCell>
                  <TableCell
                    className={`table-cell ${entity.result_xx === "Uploaded" ? "Uploaded" : "Error"
                      }`}
                    style={{
                      fontWeight: "500",
                      maxWidth: "150px",
                      padding: "8px",
                    }}
                  >
                    {entity.ent_licence_no}
                  </TableCell>
                  <TableCell
                    className={`table-cell ${entity.result_xx === "Uploaded" ? "Uploaded" : "Error"
                      }`}
                    style={{
                      fontWeight: "500",
                      maxWidth: "150px",
                      padding: "8px",
                    }}
                  >
                    {entity.ent_physical_address}
                  </TableCell>
                  <TableCell
                    className={`table-cell ${entity.result_xx === "Uploaded" ? "Uploaded" : "Error"
                      }`}
                    style={{
                      fontWeight: "500",
                      maxWidth: "150px",
                      padding: "8px",
                    }}
                  >
                    {entity.ent_postal_address}
                  </TableCell>
                  <TableCell
                    className={`table-cell ${entity.result_xx === "Uploaded" ? "Uploaded" : "Error"
                      }`}
                    style={{
                      fontWeight: "500",
                      maxWidth: "150px",
                      padding: "8px",
                    }}
                  >
                    {entity.ent_gender}
                  </TableCell>
                  <TableCell
                    className={`table-cell ${entity.result_xx === "Uploaded" ? "Uploaded" : "Error"
                      }`}
                    style={{
                      fontWeight: "500",
                      maxWidth: "250px",
                      textOverflow: "wrap",
                      padding: "8px",
                    }}
                  >
                    {entity.result_xx}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            rows={filteredData}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
      {/* Error dialog */}
      <ErrorDialog
        open={!!error}
        handleClose={handleCloseDialog}
        errorMessage={error}
      />
      {/* progress dialog for upload  */}
      <ProgressDialog
        open={openProgressDialog}
        handleClose={() => setOpenProgressDialog(false)}
        progress={progress}
        title="Uploading Clients. Please Wait...."
      />
      {/* Dialog to notify user about changes being saved */}
      <Dialog open={isNotifyDialogOpen} onClose={handleCloseNotifyDialog}>
        <DialogTitle>{notifyDialogTitle}</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: notifyDialogText }} />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseNotifyDialog}>Close</CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EntityImport;
