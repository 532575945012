import React, { forwardRef, useImperativeHandle } from 'react';
import { useNavigate } from "react-router-dom";
import "./UwPolicyExcess.scss";
import { CustomButton } from "../../../components/ui/CustomButton";
import newRequest from "../../../utils/newRequest";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { IoClose } from "react-icons/io5";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  Tooltip,
  Select,
  MenuItem
} from "@mui/material";
import { showToast } from "../../../toastService.js";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GridLoader } from "react-spinners";
import { motion } from "framer-motion";
import numeral from "numeral";


const UwPolicyExcess = forwardRef(({ quoteData, refreshQuote, profileLimitValues }, ref) => {
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [refreshDeductibles, setRefreshDeductibles] = useState("N");
  const [filteredPolicyExcess, setFilteredPolicyExcess] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [fetchExcess, setFetchExcess] = useState(true);
  const [selectedPolicyExcess, setSelectedPolicyExcess] = useState(null);
  const [policyExcess, setPolicyExcess] = useState([]);
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // Function to get limit_yn value by limit_code
  const getLimitValue = (limitCode) => {
    try {
      // console.log(`getLimitValue profileLimitValues ${JSON.stringify(profileLimitValues, null, 2)}`);
      const limit = profileLimitValues.find(l => l.limit_code === limitCode);
      return limit ? limit.limit_yn : null;
    } catch (error) {
      console.log(error)
    }
  };

  const fetchPolicyExcess = async () => {
    try {
      setIsProcessing(true);
      const res = await newRequest.post(
        "/uwPolicyExcess/findAll",
        {
          ed_org_code: quoteData.pl_org_code,
          ed_policy_docid: quoteData._id,
        } 
      );
      // console.log(`res.data ${JSON.stringify(res.data, null, 2)}`);
      setPolicyExcess(res.data);
    } catch (error) {
      console.error("Error fetching excess types:", error);
    } finally {
      setIsProcessing(false);
    }
  };
  const [productExcess, setProductExcess] = useState([]);
  const fetchProductExcess = async () => {
    try {
      const res = await newRequest.post(
        "/uwProductExcess/findAll",
        {
          ed_org_code: currentUser.usr_org_code,
          ed_pr_code: quoteData.pl_product_code
        }  
      );
      setProductExcess(res.data);
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };


  const handleRemoveExcess = async (e, excess) => {
    try {
      if (excess._id) {
        const res = await newRequest.delete(`/uwPolicyExcess/del/${excess._id}`);
        removeExcessById(excess._id);
      } else {
        fetchPolicyExcess();
      }
    } catch (error) {
      console.error("Error removing condition :", error);
    }
  };

  const removeExcessById = (id) => {
    try {
      const updatedData = policyExcess.filter(item => item._id !== id);
      setPolicyExcess(updatedData);
    } catch (error) {
      console.log(error)
    }
  };


  const handleExcessInputChange = (e, id, field) => {
    try {
      const newExcessData = [...policyExcess];
      const index = newExcessData.findIndex(ex => ex._id === id);
      if (index !== -1) {
        newExcessData[index][field] = e.target.value;
        newExcessData[index]["changed_xx"] = "Y";

        if (field === "ed_type" && e.target.value === "None") {
          newExcessData[index]["ed_value"] = 0;
          newExcessData[index]["ed_limit_fc"] = 0;
          newExcessData[index]["ed_limit_lc"] = 0;
        }

        setPolicyExcess(newExcessData);
      }
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    try {
      setIsLoading(true)
      fetchPolicyExcess();
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }, [refreshDeductibles]);



  useEffect(() => {
    const filteredResults = policyExcess.filter(
      (item) =>
        item.ed_text?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredPolicyExcess(filteredResults);
  }, [policyExcess, searchTerm]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const slicedPolicyExcess = filteredPolicyExcess.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  const handleClearSearchTerm = () => {
    setSearchTerm("");
  };


  useImperativeHandle(ref, () => ({
    saveExcessFromParent
  }));

  const saveExcessFromParent = async () => {
    try {
      saveExcess("Y");
    } catch (error) {
      console.log(error)
    }
  }

  const saveExcess = async (fromParent = "N") => {
    try {
      // console.log(`policyExcess  ${JSON.stringify(policyExcess, null, 2)}`);
      const changedExcess = policyExcess?.filter(
        (obj) => obj.changed_xx === "Y"
      );

      for (let i = 0; i < changedExcess.length; i++) {
        // console.log(`Now on ${changedExcess.ed_text} flag ${changedExcess[i].flag_xx} changed_xx ${changedExcess[i].changed_xx}`)
        if (changedExcess[i].flag_xx === "new" && changedExcess[i].ed_text) {
          const res = await newRequest.post(
            "/uwPolicyExcess/add",
            {
              ed_risk_note_no: quoteData.pl_risk_note_no,
              ed_policy_docid: quoteData._id,
              ed_end_no: quoteData.pl_end_no,
              ed_text: changedExcess[i].ed_text,
              ed_limit_fc: changedExcess[i].ed_limit_fc,
              ed_limit_lc: changedExcess[i].ed_limit_lc,
              ed_mandatory: "N",
              ed_order: changedExcess[i].ed_order,
              ed_type: changedExcess[i].ed_type,
              ed_value: changedExcess[i].ed_value,
              createdBy: currentUser.usr_id,
            }
          );
        } else {
          if (changedExcess[i]?._id) {
            const res = await newRequest.put(
              `/uwPolicyExcess/upd/${changedExcess[i]._id}`,
              {
                ed_text: changedExcess[i].ed_text,
                ed_limit_fc: changedExcess[i].ed_limit_fc,
                ed_limit_lc: changedExcess[i].ed_limit_lc,
                ed_mandatory: changedExcess[i].ed_mandatory,
                ed_order: changedExcess[i].ed_order,
                ed_type: changedExcess[i].ed_type,
                ed_value: changedExcess[i].ed_value,
                updatedBy: currentUser.usr_id,
              }
            );
          }
        }
      }

      if (fromParent === "N") {
        refreshQuote();
        // fetchPolicyExcess();
        // setFetchExcess(!fetchExcess);
        setRefreshDeductibles(refreshDeductibles === "N" ? "Y" : "N");
        showToast("Saved!", "success");
      }
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };

  const addExcessRow = () => {
    try {
      // console.log(`policyExcess  ${JSON.stringify(selectedPolicyExcess, null, 2)}`);
      const defaultExcessRow = {
        ed_risk_note_no: quoteData.pl_risk_note_no,
        ed_policy_docid: quoteData._id,
        ed_text: "",
        ed_formatted_text: "",
        ed_limit_fc: 0,
        ed_limit_lc: 0,
        ed_mandatory: "N",
        ed_order: policyExcess?.length + 1,
        sr_no: policyExcess?.length + 1,
        ed_type: "None",
        ed_value: 0,
        changed_xx: "Y",
        flag_xx: "new",
        ed_end_no: quoteData.pl_end_no,
      };
      setPolicyExcess([...policyExcess, defaultExcessRow]);
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <motion.div
      className="uw-policy-excess"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      <div className="policy-excess-wrapper">
        <hr />
        <div className="navbar">
          <div className="navbar-left">
            <h1 className='main-title'>EXCESS/DEDUCTIBLES</h1>
            {/* <div className="search-input-wrapper">
              <SearchIcon className="search-input-icon" />
              <input
                className="search-input"
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              {searchTerm.length > 0 && (
                <IoClose
                  onClick={handleClearSearchTerm}
                  className="search-input-icon"
                />
              )}
            </div> */}
          </div>
          <div className="navbar-right">
            {quoteData.document_enabled_xx === "Y" && <>
              <CustomButton
                startIcon={<AddIcon />}
                onClick={addExcessRow}
              >
                Add
              </CustomButton>
              <CustomButton
                startIcon={<SaveIcon />}
                onClick={saveExcess}
              >
                Save
              </CustomButton>
            </>}
          </div>
        </div>
        <div className="table-content">
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "200px",
                width: "100%",
                height: "100%",
              }}
            >
              <GridLoader
                color="#F44336"
                style={{
                  height: "50px",
                  width: "50px",
                }}
              />
            </div>
          ) : (
            <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none' }} className="table-container">
              <Table sx={{ width: "max-content", border: 'none' }}>
                <TableHead className="tablehead">
                  <TableRow className="tablerow">
                    <TableCell className="table-cell" style={{ display: "flex", maxWidth: "50px", alignItems: "center", justifyContent: "center", fontWeight: "bold" }}>
                      <span>#</span>
                    </TableCell>
                    <TableCell className="table-cell" style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start", width: "auto" }}>
                      <span>TEXT</span>
                    </TableCell>
                    <TableCell className="table-cell" style={{ fontWeight: "bold", maxWidth: "200px" }}>
                      <span>VALUE TYPE</span>
                    </TableCell>
                    <TableCell className="table-cell" style={{ fontWeight: "bold", maxWidth: "150px", display: 'flex', justifyContent: 'flex-end' }}>
                      <span>VALUE</span>
                    </TableCell>
                    <TableCell className="table-cell" style={{ fontWeight: "bold", alignItems: "center", maxWidth: "150px", display: 'flex', justifyContent: 'flex-end' }}>
                      <span style={{ alignSelf: "flex-end" }}>{`LIMIT [${quoteData.pl_cur_code}]`}</span>
                    </TableCell>
                    <TableCell className="table-cell" style={{ fontWeight: "bold", maxWidth: "100px", alignItems: "center", textAlign: "center" }}>
                      <span>ACTION</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="tablebody">
                  {slicedPolicyExcess?.map((excess, index) => (
                    <TableRow
                      key={excess._id}
                      className={`${selectedPolicyExcess?._id === excess._id ? "tablerow current" : "tablerow"}`}
                      onClick={() => {
                        try {
                          setSelectedPolicyExcess(excess)
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                    >
                      <TableCell className="table-cell" style={{ display: "flex", maxWidth: "50px", fontWeight: "bold", alignItems: "center", justifyContent: "center" }}>
                        {excess.sr_no}
                      </TableCell>
                      <TableCell className="table-cell" style={{ display: "flex", fontWeight: "bold", width: "auto", flexDirection: "column" }}>
                        <input
                          id="ed_text"
                          value={excess.ed_text}
                          onChange={(e) => handleExcessInputChange(e, excess._id, 'ed_text')}
                          style={{ width: "auto" }}
                          className="form-input-item"
                          disabled={quoteData.document_enabled_xx === "N"}
                        />
                        <span style={{ fontWeight: "500", fontSize: "10px" }}>{excess.ed_formatted_text}</span>
                      </TableCell>
                      <TableCell className="table-cell" style={{ maxWidth: "200px", flexDirection: "column" }}>
                        <Select
                          value={excess.ed_type}
                          onChange={(e) => handleExcessInputChange(e, excess._id, 'ed_type')}
                          variant="outlined"
                          size="small"
                          disabled={quoteData.document_enabled_xx === "N"}
                        >
                          <MenuItem value="Si_Percentage">% of SI</MenuItem>
                          <MenuItem value="Premium_Percentage">% Of Premium</MenuItem>
                          <MenuItem value="Fixed Value">Fixed Value</MenuItem>
                          <MenuItem value="None">None</MenuItem>
                        </Select>
                        <span style={{ fontWeight: "500", fontSize: "10px", color: "transparent" }}>1</span>
                      </TableCell>
                      <TableCell className="table-cell" style={{ fontWeight: "500", maxWidth: "150px", padding: "8px", display: 'flex', justifyContent: 'flex-end', flexDirection: "column" }}>
                        <input
                          id="ed_value"
                          type="number"
                          value={excess.ed_value}
                          onChange={(e) => handleExcessInputChange(e, excess._id, 'ed_value')}
                          className="form-input-item"
                          style={{ width: "100%", textAlign: "right" }}
                          disabled={quoteData.document_enabled_xx === "N"}
                        />
                        <span style={{ fontWeight: "500", fontSize: "10px", color: "transparent" }}>1</span>
                      </TableCell>
                      <TableCell className="table-cell" style={{ fontWeight: "500", alignItems: "center", padding: "3px", maxWidth: "150px", display: 'flex', textAlign: 'right', flexDirection: "column" }}>
                        <span style={{
                          width: "100%",
                          fontWeight: "bold", fontSize: "18px"
                        }}>{numeral(
                          excess.ed_limit_fc
                        ).format("0,0.00")}</span>
                      </TableCell>
                      <TableCell className="table-cell" style={{ alignItems: "center", textAlign: "center", padding: "3px", maxWidth: "100px" }}>
                        {quoteData.document_enabled_xx === "Y" && <Tooltip title="Remove Excess">
                          <DeleteIcon
                            style={{ height: "25px", width: "25px", alignItems: "center", textAlign: "center", padding: "3px", cursor: "pointer", color: "#b71c1c" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRemoveExcess(e, excess);
                            }}
                          />
                        </Tooltip>}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5]}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                component="div"
                count={filteredPolicyExcess?.length}
                rows={filteredPolicyExcess}
                page={page}
                onPageChange={handleChangePage}
              />
            </TableContainer>
          )}
        </div>
      </div>
      {
        isProcessing && (
          <motion.div
            className="processing-dialog"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 1 } }}
            exit={{ opacity: 0, transition: { duration: 1 } }}
          >
            <div className="processing-wrapper">
              <GridLoader
                color="#F44336"
                style={{
                  height: "50px",
                  width: "50px",
                }}
              />
            </div>
          </motion.div>
        )
      }
    </motion.div>
  );
});

export default UwPolicyExcess;
