import { useSelector } from "react-redux";
import "./AdCompany.scss";
import ConfirmDialog from "../../../components/confirmDialog/confirmDialog.jsx";
import {
  CustomButton,
  CustomLoadingButton,
} from "../../../components/ui/CustomButton.jsx";
import { customSelectStyles } from "../../../components/ui/uiStyleElements.jsx";
import { showToast } from "../../../toastService.js";
import app from "../../../utils/firebase";
import newRequest from "../../../utils/newRequest.js";
import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  Select,
  MenuItem,
  Popover,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from '@mui/icons-material/Edit';
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { motion } from "framer-motion";
import numeral from "numeral";
import { useEffect, useState } from "react";
import { FaSave } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import ReactSelect from "react-select";
import { GridLoader, ScaleLoader } from "react-spinners";
import { Segmented } from 'antd';


const AdCompany = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogAction, setConfirmDialogAction] = useState("None");
  const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
  const [notifyDialogTitle, setNotifyDialogTitle] = useState("Confirm");
  const [notifyDialogText, setNotifyDialogText] = useState("Text");
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [savingTitle, setSavingTitle] = useState("Save");
  const [searchTerm, setSearchTerm] = useState("");
  const [currencyAction, setCurrencyAction] = useState("");
  const [isCodeDialogOpened, setIsCodeDialogOpened] = useState(false);
  const [img, setImg] = useState(null);
  const [selectedAdminToAdd, setSelectedAdminToAdd] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [orgUnits, setOrgUnits] = useState([]);

  const [reportVersions, setReportVersions] = useState([]);
  const [searchTermRepVer, setSearchTermRepVer] = useState("");

  const [selectedOrgUnit, setSelectedOrgUnit] = useState({});
  const [orgStats, setOrgStats] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [objectToAction, setObjectToAction] = useState({
    object_id: "",
    action_text: "",
    action_invoked: "",
  });
  const [alignValue, setAlignValue] = useState('more');
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const [orgRecordData, setOrgRecordData] = useState({});
  const [orgDocId, setOrgDocId] = useState("");
  const [orgCode, setOrgCode] = useState("");
  const [orgName, setOrgName] = useState("");
  const [orgType, setOrgType] = useState("");
  const [orgWebsite, setOrgWebsite] = useState("");
  const [orgAddress1, setOrgAddress1] = useState("");
  const [orgAddress2, setOrgAddress2] = useState("");
  const [orgEmail, setOrgEmail] = useState("");
  const [orgPhone, setOrgPhone] = useState("");
  const [orgLogoUrl, setOrgLogoUrl] = useState("");
  const [orgBaseCur, setOrgBaseCur] = useState("");
  const [orgTaxPin, setOrgTaxPin] = useState("");
  const [orgLicenseNo, setOrgLicenseNo] = useState("");
  const [orgStatus, setOrgStatus] = useState("");
  const [orgAdmins, setOrgAdmins] = useState([]);
  const [numberGens, setNumberGens] = useState([]);
  const [selectedNumberGen, setSelectedNumberGen] = useState({});

  // Organization Unit
  const [openAddUnit, setOpenAddUnit] = useState(false);
  const [unitCode, setUnitCode] = useState("");
  const [unitName, setUnitName] = useState("");
  const [unitType, setUnitType] = useState(null);
  const [unitAddress1, setUnitAddress1] = useState("");
  const [unitAddress2, setUnitAddress2] = useState("");
  const [unitEmail, setUnitEmail] = useState("");
  const [unitPhone, setUnitPhone] = useState("");
  const [unitStatus, setUnitStatus] = useState(null);

  // BizUnitStatus
  const [statusOptions, setStatusOptions] = useState([]);
  const fetchBizUnitStatus = async () => {
    try {
      const res = await newRequest.post(
        "/sysCode/findAll",
        {
          user_lang: "en_US",
          sys_org_code: currentUser.usr_org_code,
          sys_type: "Biz Unit Status",
        },
        config
      );

      const options = res.data.map((unitSts) => ({
        value: unitSts.sys_code,
        label: unitSts.sys_name,
      }));

      setStatusOptions(options);
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };


  // BizUnitType
  const [unitTypeOptions, setUnitTypeOptions] = useState([]);
  const fetchBizUnitType = async () => {
    try {
      const res = await newRequest.post(
        "/sysCode/findAll",
        {
          user_lang: "en_US",
          sys_org_code: currentUser.usr_org_code,
          sys_type: "Biz Unit Type",
        },
        config
      );

      const options = res.data.map((unitSts) => ({
        value: unitSts.sys_code,
        label: unitSts.sys_name,
      }));

      setUnitTypeOptions(options);
    } catch (error) {
      console.error("Error fetching type types:", error);
    }
  };

  // documents
  const [newDocType, setNewDocType] = useState(null);
  const [documentOptions, setDocumentOptions] = useState([]);
  const fetchDocuments = async () => {
    try {
      const res = await newRequest.post(
        "/sysCode/findAll", { sys_type: "Documents" }
      );
      const options = res.data.map((row) => ({
        value: row.sys_code,
        label: row.sys_name,
      }));

      setDocumentOptions(options);
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? 'simple-popover' : undefined;

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setNewDocType(null);
    setAnchorEl(null);
  };

  const handleUploadDoalogClose = () => {
    setIsUploadDialogOpen(false);
  };

  const fetchOrganization = async () => {
    try {
      setIsLoading(true);
      const res = await newRequest.post(
        "/org/find", { org_code: currentUser.usr_org_code }
      );

      if (res.data) {
        setOrgRecordData(res.data);
        setOrgDocId(res.data._id);
        setOrgCode(res.data.org_code);
        setOrgName(res.data.org_name);
        setOrgType(res.data.org_type);
        setOrgWebsite(res.data.org_website);
        setOrgAddress1(res.data.org_address1);
        setOrgAddress2(res.data.org_address2);
        setOrgEmail(res.data.org_email);
        setOrgPhone(res.data.org_phone);
        setOrgLogoUrl(res.data.org_logo_url);
        setOrgBaseCur(res.data.org_base_cur);
        setOrgTaxPin(res.data.org_tax_pin);
        setOrgLicenseNo(res.data.org_license_no);
        setOrgStatus(res.data.org_status);
        setReportVersions(res.data.org_report_versions);
        fetchOrganizationAdmins(res.data._id);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOrganizationStats = async () => {
    try {
      const res = await newRequest.post(
        "/org/stats", { org_code: currentUser.usr_org_code }
      );

      setOrgStats(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchOrganizationAdmins = async (org_docid) => {
    try {
      const res = await newRequest.post(
        "/org/getAdmins", { org_docid: org_docid }
      );

      setOrgAdmins(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const fetchOrganizationUnits = async () => {
    try {
      const res = await newRequest.post(
        "/orgUnit/findAll", { unit_status: "" }
      );

      setOrgUnits(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [currentOrgUnitPage, setCurrentOrgUnitPage] = useState(0);
  const handleOrgUnitsPageChange = (event, newPage) => {
    setCurrentOrgUnitPage(newPage);
  };

  const slicedOrgUnits = orgUnits.slice(
    currentOrgUnitPage * rowsPerPage,
    currentOrgUnitPage * rowsPerPage + rowsPerPage
  );

  const handleOrgUnitsRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setCurrentOrgUnitPage(0);
  };


  // number generation
  const [currentNumGenPage, setCurrentNumGenPage] = useState(0);
  const handleNumGensPageChange = (event, newPage) => {
    setCurrentNumGenPage(newPage);
  };

  const slicedNumGens = numberGens.slice(
    currentNumGenPage * rowsPerPage,
    currentNumGenPage * rowsPerPage + rowsPerPage
  );

  const handleNumGensRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setCurrentNumGenPage(0);
  };

  const fetchNumberGenerations = async () => {
    try {
      const res = await newRequest.post(
        "/orgNumgen/findAll", { gen_org_code: currentUser.usr_org_code }
      );

      setNumberGens(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  useEffect(() => {
    fetchOrganization();
    fetchNumberGenerations();
    fetchOrganizationUnits();
    fetchUsers();
    fetchOrganizationStats();
    fetchBizUnitStatus();
    fetchBizUnitType();
    fetchDocuments();
  }, []);

  const handleConfirm = async () => {
    try {
      if (confirmDialogAction === "removeAdmin") {
        const res = await newRequest.post(
          "/org/delAdmin",
          {
            org_docid: orgDocId,
            org_admin_docid: objectToAction.object_id,
            userCode: currentUser.usr_id,
          }
        );
        fetchOrganizationAdmins(orgDocId);
      } else if (confirmDialogAction === "deleteNumGen") {
        const res = await newRequest.delete(`/orgNumgen/del/${objectToAction.object_id}`);
        fetchNumberGenerations();
        showToast("Deleted!", "success");
      }
    } catch (err) {
      console.log(err);
    }
    // Close the dialog
    setIsConfirmDialogOpen(false);
  };

  const handleCancel = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleCloseNotifyDialog = () => {
    setIsNotifyDialogOpen(false);
  };

  const handleGenNumberUse = (e, index) => {
    try {
      const { name, value } = e.target;
      const updatedData = [...numberGens];
      const currentRow = updatedData[index];
      currentRow[name] = value;
      currentRow.changed_xx = "Y";
      setNumberGens(updatedData);
    } catch (error) {
      console.log(error);
    }
  };



  const handleOrgUnitsChange = (e, rowId) => {
    try {
      const { name, value } = e.target;
      const updatedData = [...orgUnits];
      const rowIndex = updatedData.findIndex(item => item._id === rowId);

      if (rowIndex === -1) {
        throw new Error('Row not found');
      }
      updatedData[rowIndex][name] = value;
      updatedData[rowIndex]["changed_xx"] = "Y";

      setOrgUnits(updatedData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleNumGens = (e, index) => {
    try {
      // console.log(`numberGens  ${JSON.stringify(numberGens, null, 2)}`);
      const { name, value } = e.target;
      const updatedData = [...numberGens];
      const currentRow = updatedData[index];
      currentRow[name] = value;
      currentRow.changed_xx = "Y";
      setNumberGens(updatedData);
    } catch (error) {
      console.log(error);
    }
  };

  const saveRecord = async (e) => {
    // e.preventDefault();
    try {
      setSavingTitle("saving...");
      setIsProcessing(true);

      const res = await newRequest.put(
        `/org/upd/${orgDocId}`,
        {
          org_name: orgName,
          org_website: orgWebsite,
          org_address1: orgAddress1,
          org_address2: orgAddress2,
          org_email: orgEmail,
          org_phone: orgPhone,
          org_tax_pin: orgTaxPin,
          org_license_no: orgLicenseNo,
          updatedBy: currentUser.usr_id,
        }
      );

      if (img) {
        await uploadToFirebase(img, "logos");
      }

      const changedNumgen = numberGens?.filter((obj) => obj.changed_xx === "Y");
      for (let i = 0; i < changedNumgen.length; i++) {
        const res = await newRequest.put(
          `/orgNumgen/upd/${changedNumgen[i]._id}`,
          {
            gen_auto: changedNumgen[i].gen_auto,
            gen_current_no: changedNumgen[i].gen_current_no,
            gen_prefix: changedNumgen[i].gen_prefix,
            gen_surfix: changedNumgen[i].gen_surfix,
            gen_entity_type: changedNumgen[i].gen_entity_type,
            updatedBy: currentUser.usr_id,
          }
        );
      }
      showToast("Updated!", "success");
    } catch (err) {
      console.log(err);
    } finally {
      setSavingTitle("Save");
      setIsProcessing(false);
    }
  };

  const uploadToFirebase = async (file, fileFolder) => {
    setIsUploadDialogOpen(true);
    const storage = getStorage(app);
    const fileName = new Date().getTime() + file.name;
    const fileExtension = file.name.split(".").pop(); // Get file extension
    const fileType = file.type; // Get file type
    const fileSizeKB = file.size / 1024; // Get file size in KB
    const storageRef = ref(
      storage,
      `qc_${currentUser.usr_org_code}/${fileFolder}/${fileName}`
    );
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(Math.round(progress));
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        setIsUploadDialogOpen(false);
        console.log(error);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

        // update org logo
        const res = await newRequest.put(
          `/org/upd/${orgDocId}`,
          {
            org_logo_url: downloadURL,
            updatedBy: currentUser.usr_id,
          },
          config
        );
        // console.log(`Update Logo response ${res.data}`)
        setOrgLogoUrl(downloadURL);

        // download logo as a file and store in assets folder
        const logo = await newRequest.post(
          `/org/logo`,
          {
            logo_url: downloadURL,
            org_code: currentUser.usr_org_code,
          },
          config
        );
        console.log(`Reset Logo response ${logo.data}`)
        setImg("");
        setIsUploadDialogOpen(false);
      }
    );
  };


  const fetchUsers = async () => {
    try {
      const res = await newRequest.post(
        "/adUser/findAll",
        {
          usr_org_code: currentUser.usr_org_code,
        }
      );
      const options = res.data.map((user) => ({
        value: user._id,
        label: user.usr_full_name,
      }));

      setUsers(options);
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };

  const handleAddAdmin = async () => {
    try {
      const res = await newRequest.post(
        "/org/addAdmin",
        {
          org_docid: orgDocId,
          user_docid: selectedAdminToAdd.value,
          userCode: currentUser.usr_id,
        }
      );
      // console.log(`/org/addAdmin  ${JSON.stringify(res.data, null, 2)}`);
      if (res.data === "exists") {
        showToast(
          `User ${selectedAdminToAdd.label} is already an admin`,
          "error"
        );
      } else {
        showToast(`User ${selectedAdminToAdd.label} added as admin`, "success");
      }
      setSelectedAdminToAdd({ value: "", label: "" });
      fetchOrganizationAdmins(orgDocId);
    } catch (error) {
      console.log(error);
    }
  };


  const handleBizUnitAction = async () => {
    try {

      // Check for both insert and update

      if (!unitName) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter name!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      if (!unitType) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please select type!`);
        setIsNotifyDialogOpen(true);
        return;
      }
      // console.log(`currencyAction is ${currencyAction}`)
      if (currencyAction === "addUnit") {
        if (!unitCode) {
          setNotifyDialogTitle("Note!");
          setNotifyDialogText(`Please enter unique code!`);
          setIsNotifyDialogOpen(true);
          return;
        }
        const res = await newRequest.post(
          "/orgUnit/add",
          {
            unit_org_code: currentUser.usr_org_code,
            unit_code: unitCode,
            unit_name: unitName,
            unit_type: unitType?.value,
            unit_address1: unitAddress1,
            unit_address2: unitAddress2,
            unit_email: unitEmail,
            unit_phone: unitPhone,
            unit_status: unitStatus?.value,
            userCode: currentUser.usr_id,
          }
        );
        if (res.data === "exists") {
          setNotifyDialogTitle("Note!");
          setNotifyDialogText(`<strong>${unitCode}</strong> is not unique. Choose another code!`);
          setIsNotifyDialogOpen(true);
          return;
        } else {
          setSelectedOrgUnit(null);
          setCurrencyAction("none");
          setUnitCode("")
          setUnitAddress1("")
          setUnitEmail("")
          setUnitPhone("")
          setUnitStatus(null)
          fetchOrganizationUnits();
          setOpenAddUnit(false);
          showToast(`${unitType?.label} ${unitName} added!`, "success");
          setUnitName("")
          setUnitType(null)
        }
      } else if (currencyAction === "editUnit") {
        const res = await newRequest.put(
          `/orgUnit/upd/${selectedOrgUnit._id}`,
          {
            unit_name: unitName,
            unit_type: unitType?.value,
            unit_address1: unitAddress1,
            unit_address2: unitAddress2,
            unit_email: unitEmail,
            unit_phone: unitPhone,
            unit_status: unitStatus?.value,
            updatedBy: currentUser.usr_id,
          }
        );
        setSelectedOrgUnit(null);
        setCurrencyAction("none");
        setUnitCode("")
        setUnitAddress1("")
        setUnitEmail("")
        setUnitPhone("")
        setUnitStatus(null)
        fetchOrganizationUnits();
        setOpenAddUnit(false);
        showToast(`${unitType?.label} ${unitName} updated!`, "success");
        setUnitName("")
        setUnitType(null)
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleNumGenAction = async () => {
    try {

      if (!newDocType?.value) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter type!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      const res = await newRequest.post(
        "/orgNumgen/add",
        {
          gen_org_code: currentUser.usr_org_code,
          gen_type: newDocType?.value,
          gen_auto: "Y",
          gen_current_no: 1000,
          gen_prefix: "",
          gen_surfix: "",
          userCode: currentUser.usr_id,
        }
      );

      if (res.data === "exists") {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`<strong>${newDocType?.value}</strong> already exists!`);
        setIsNotifyDialogOpen(true);
      } else if (res.data === "success") {
        fetchNumberGenerations();
        handlePopoverClose();
        showToast(`${newDocType?.value} Added!`, "success");
        setNewDocType(null);
      } else {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(res.data);
        setIsNotifyDialogOpen(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleReportVersionChange = async (e, rowId) => {
    try {
      const { name, value } = e.target;
      const updatedData = [...reportVersions];
      const rowIndex = updatedData.findIndex(item => item._id === rowId);

      if (rowIndex === -1) {
        console.log('Row not found');
      }
      const res = await newRequest.post(
        "/org/ver",
        {
          org_code: currentUser.usr_org_code,
          report_docid: rowId,
          version: value
        },
        config
      );
      console.log(res.data)
      updatedData[rowIndex][name] = value;
      setReportVersions(updatedData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClearRepVerSearch = () => {
    setSearchTermRepVer("");
  };

  const filteredRepVerData = reportVersions.filter(
    (item) =>
      item.report_title?.toLowerCase().includes(searchTermRepVer.toLowerCase()) ||
      item.report_desc?.toLowerCase().includes(searchTermRepVer.toLowerCase())
  );

  const handleDocumentPreview = async (docType, docTitle) => {
    try {
      setIsProcessing(true);
      let docResponse = null;

      // check if document preview has been set
      const getLast = await newRequest.post(
        "/gendocs/last",
        {
          doc_type: docType,
        }, {
      },
        config
      );

      if (getLast.data.doc_id !== "-1") {
        if (docType === "risk_note") {
          docResponse = await newRequest.post(
            "/gendocs/rn",
            {
              policy_docid: getLast.data.doc_id,
              org_code: currentUser.usr_org_code,
            }, {
            responseType: 'json'
          }
          );
        } else if (docType === "payment") {
          docResponse = await newRequest.post(
            "/gendocs/pmt",
            {
              payment_docid: getLast.data.doc_id,
              org_code: currentUser.usr_org_code,
            }, {
            responseType: 'json'
          },
            config
          );
        } else if (docType === "receipt") {
          docResponse = await newRequest.post(
            "/gendocs/rcp",
            {
              receipt_docid: getLast.data.doc_id,
              org_code: currentUser.usr_org_code,
            }, {
            responseType: 'json'
          }
          );
        }

        const response = await newRequest.get(`/viewdocs/view/${docResponse.data.binary_docid}`, {
          responseType: 'arraybuffer', // Ensure response is treated as binary data
        });

        // Create a blob from the binary data received
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const url = URL.createObjectURL(blob);
        // Open the PDF in a new tab
        const newTab = window.open();
        newTab.location.href = url;
      } else {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(getLast.data.result_msg.replace("$$reportTitle$$", docTitle));
        setIsNotifyDialogOpen(true);
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsProcessing(false);
    }
  }


  return (
    <div className="ad-company">
      {isLoading ? (
        <div className="no-content"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <ScaleLoader
            color="#F44336"
            height={50}
            radius={8}
            width={4}
          />
        </div>
      ) : (
        <div className="ad-company-main-wrapper">
          <div className="center-wrapper">
            <div className="center-details">
              <div className="center-navbar">
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h1>{orgName}</h1>
                  <CustomLoadingButton
                    startIcon={<FaSave />}
                    onClick={saveRecord}
                  >
                    {savingTitle}
                  </CustomLoadingButton>
                </div>
              </div>
              <hr />
              <div className="org-details-wrapper">
                <div className="org-details-left">
                  <div className="input-item-with-label">
                    <label
                      htmlFor="org_name"
                      style={{
                        width: "140px",
                      }}
                    >
                      NAME
                    </label>
                    <input
                      id="org_name"
                      name="org_name"
                      type="text"
                      placeholder="NAME"
                      value={orgName}
                      onChange={(e) => setOrgName(e.target.value)}
                      className="form-input-item"
                      style={{ width: "350px" }}
                      disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                    />
                  </div>
                  <div className="input-item-with-label">
                    <label
                      htmlFor="org_email"
                      style={{
                        width: "140px",
                      }}
                    >
                      EMAIL
                    </label>
                    <input
                      id="org_email"
                      name="org_email"
                      type="text"
                      placeholder="EMAIL"
                      value={orgEmail}
                      onChange={(e) => setOrgEmail(e.target.value)}
                      className="form-input-item"
                      style={{ width: "350px" }}
                      disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                    />
                  </div>
                  <div className="input-item-with-label">
                    <label
                      htmlFor="org_phone"
                      style={{
                        width: "140px",
                      }}
                    >
                      PHONE NO
                    </label>
                    <input
                      id="org_phone"
                      name="org_phone"
                      type="text"
                      placeholder="PHONE NO"
                      value={orgPhone}
                      onChange={(e) => setOrgPhone(e.target.value)}
                      className="form-input-item"
                      style={{ width: "350px" }}
                      disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                    />
                  </div>
                  <div className="input-item-with-label">
                    <label
                      htmlFor="org_website"
                      style={{
                        width: "140px",
                      }}
                    >
                      WEBSITE
                    </label>
                    <input
                      id="org_website"
                      name="org_website"
                      type="text"
                      placeholder="WEBSITE"
                      value={orgWebsite}
                      onChange={(e) => setOrgWebsite(e.target.value)}
                      className="form-input-item"
                      style={{ width: "350px" }}
                      disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                    />
                  </div>
                  <div className="input-item-with-label">
                    <label
                      htmlFor="org_address1"
                      style={{
                        width: "140px",
                      }}
                    >
                      ADDRESS 1
                    </label>
                    <textarea
                      id="org_address1"
                      name="org_address1"
                      type="text"
                      placeholder="ADDRESS 1"
                      value={orgAddress1}
                      rows={2}
                      onChange={(e) => setOrgAddress1(e.target.value)}
                      className="form-input-item"
                      style={{ width: "350px" }}
                      disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                    />
                  </div>
                  <div className="input-item-with-label">
                    <label
                      htmlFor="org_address2"
                      style={{
                        width: "140px",
                      }}
                    >
                      ADDRESS 2
                    </label>
                    <textarea
                      id="org_address2"
                      name="org_address2"
                      type="text"
                      placeholder="ADDRESS 2"
                      value={orgAddress2}
                      rows={2}
                      onChange={(e) => setOrgAddress2(e.target.value)}
                      className="form-input-item"
                      style={{ width: "350px" }}
                      disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                    />
                  </div>
                </div>
                <div className="org-details-right">
                  <div className="logo">
                    {!img && (
                      <img
                        src={orgLogoUrl || "/img/noavatar.jpg"}
                        alt=""
                        className="img"
                      />
                    )}
                    {img && (
                      <img
                        src={
                          img ? URL.createObjectURL(img) : <BrokenImageOutlinedIcon style={{ width: "120px", height: "120px" }} />
                        }
                        alt=""
                        className="img"
                      />
                    )}
                    <input
                      type="file"
                      id="file"
                      style={{ display: "none" }}
                      // accept="image/*"
                      accept=".png,image/png"
                      onChange={(e) => {
                        try {
                          const file = e.target.files[0];
                          if (file && file.type.includes('image/png')) {
                            setImg(file);
                          } else {
                            // Handle case where file doesn't match expected type
                            console.log('Please select a .png file.');
                          }
                        } catch (error) {
                          console.log(error)
                        }
                      }}
                    />
                    {["admin", "superadmin"].includes(currentUser.user_type_xx) &&
                      <label htmlFor="file">
                        <div className="item">
                          <span>SELECT (.png Only)</span>
                        </div>
                      </label>}
                  </div>
                  <div className="inputs">
                    <div className="input-item-with-label">
                      <label
                        htmlFor="org_license_no"
                        style={{
                          width: "140px",
                        }}
                      >
                        LICENSE NO
                      </label>
                      <input
                        id="org_license_no"
                        name="org_license_no"
                        type="text"
                        placeholder="LICENSE NO"
                        value={orgLicenseNo}
                        onChange={(e) => setOrgLicenseNo(e.target.value)}
                        className="form-input-item"
                        style={{ width: "160px" }}
                        disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                      />
                    </div>
                    <div
                      className="input-item-with-label"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <label
                        htmlFor="org_tax_pin"
                        style={{
                          width: "max-content",
                        }}
                      >
                        TAX PIN
                      </label>
                      <input
                        id="org_tax_pin"
                        name="org_tax_pin"
                        type="text"
                        placeholder="TAX PIN"
                        value={orgTaxPin}
                        onChange={(e) => setOrgTaxPin(e.target.value)}
                        className="form-input-item"
                        style={{ width: "160px" }}
                        disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                      />
                    </div>
                    <div
                      className="input-item-with-label"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <label
                        htmlFor="org_base_cur"
                        style={{
                          width: "max-content",
                        }}
                      >
                        CURRENCY
                      </label>
                      <input
                        id="org_base_cur"
                        name="org_base_cur"
                        type="text"
                        placeholder="CURRENCY"
                        value={orgBaseCur}
                        onChange={(e) => setOrgBaseCur(e.target.value)}
                        className="form-input-item"
                        style={{ width: "160px" }}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>

              <Segmented
                defaultValue="more"
                style={{
                  margin: "8px 5px",
                  height: '40px',
                }}
                className="custom-segmented"
                onChange={(value) => setAlignValue(value)}
                options={[
                  { label: <span style={{ fontSize: "15px", fontWeight: "500" }}>MORE</span>, value: 'more' },
                  { label: <span style={{ fontSize: "15px", fontWeight: "500" }}>REPORT VERSIONS</span>, value: 'reports' },
                  { label: <span style={{ fontSize: "15px", fontWeight: "500" }}>BANK A/C</span>, value: 'bank_ac' },
                  { label: <span style={{ fontSize: "15px", fontWeight: "500" }}>USERS</span>, value: 'users' },
                ]}
              />

              {alignValue === "more" &&
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { duration: 1 } }}
                  exit={{ opacity: 0, transition: { duration: 1 } }}
                >
                  {["admin", "superadmin"].includes(currentUser.user_type_xx) && <hr />}
                  {/* Organization Administrators. Can be viewed by administrators and super administrators only */}
                  {["admin", "superadmin"].includes(currentUser.user_type_xx) && <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <h2 className="sub-titles">ADMINISTRATORS</h2>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <div className="input-item-with-label">
                        <label htmlFor="admin_id">USER</label>
                        <ReactSelect
                          id="admin_id"
                          name="admin_id"
                          styles={customSelectStyles}
                          width="250px"
                          value={selectedAdminToAdd}
                          onChange={(selectedOption) => {
                            try {
                              setSelectedAdminToAdd(selectedOption);
                            } catch (error) {
                              console.log(error);
                            }
                          }}
                          options={users}
                          isSearchable={true}
                          placeholder="Users"
                        />
                      </div>
                      <CustomButton
                        startIcon={<AddIcon />}
                        onClick={handleAddAdmin}
                      >
                        Add
                      </CustomButton>
                    </div>
                  </div>}
                  {["admin", "superadmin"].includes(currentUser.user_type_xx) && <div className="org-admins-wrapper">
                    {orgAdmins.length > 0 && (
                      <>
                        {orgAdmins?.map((row, index) => (
                          <div key={row._id} className="single-item">
                            <span
                              style={{
                                alignContent: "flex-start",
                                fontWeight: "bold",
                                fontSize: "20px",
                              }}
                            >
                              {index + 1}.
                            </span>
                            <div className="user">
                              <img
                                src={row?.usr_photo_url || "/img/noavatar.jpg"}
                                alt=""
                              />
                              <div className="user-org-view">
                                <span className="user-full-name">
                                  {row.usr_full_name}
                                </span>
                              </div>
                              {row.superadmin === "Y" && <span>Super Admin</span>}
                              {row.superadmin === "N" && (
                                <Tooltip
                                  title={`Remove ${row.usr_full_name} as ADMINISTRATOR from ${orgName}`}
                                  placement="right"
                                >
                                  <IoClose
                                    size={24}
                                    style={{
                                      borderRadius: "5px",
                                      padding: "3px",
                                      cursor: "pointer",
                                      color: "rgba(172, 33, 9)",
                                      backgroundColor: "rgba(172, 33, 9, 0.5)",
                                    }}
                                    onClick={() => {
                                      try {
                                        const action_text = `Do you want to remove ${row.usr_full_name} as ADMINISTRATOR from ${orgName}?`;
                                        setObjectToAction({
                                          object_id: row._id,
                                          action_text: action_text,
                                        });
                                        setConfirmDialogAction("removeAdmin");
                                        setIsConfirmDialogOpen(true);
                                      } catch (error) {
                                        console.log(error);
                                      }
                                    }}
                                  />
                                </Tooltip>
                              )}
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>}
                  {/* End of Administrators */}
                  {/* Business Units */}
                  <hr />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      justifyContent: "flex-start",
                    }}
                  >
                    <h2 className="sub-titles">BUSINESS UNITS</h2>
                    {["admin", "superadmin"].includes(currentUser.user_type_xx) && <Tooltip title={`Add Business Unit`} placement="right">
                      <AddIcon
                        size={50}
                        style={{
                          borderRadius: "5px",
                          padding: "3px",
                          alignSelf: "center",
                          cursor: "pointer",
                          backgroundColor: "rgba(0, 128, 0, 0.5)",
                        }}
                        onClick={async () => {
                          try {
                            setCurrencyAction("addUnit");
                            setUnitType({
                              value: "Branch",
                              label: "Branch",
                            });
                            setUnitStatus({
                              value: "Active",
                              label: "Active",
                            })
                            setOpenAddUnit(true);
                          } catch (error) {
                            console.log(error);
                          }
                        }}
                      />
                    </Tooltip>}
                  </div>
                  <div className="table-content">
                    <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none' }} className="table-container">
                      <Table sx={{ width: "max-content", border: 'none' }}>
                        <TableHead className="tablehead">
                          <TableRow className="tablerow">
                            <TableCell className="table-cell" style={{ maxWidth: "50px", fontWeight: "bold", display: "flex", alignItems: "center" }}>
                              #
                            </TableCell>
                            <TableCell className="table-cell" style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", maxWidth: "200px", flexDirection: "row" }}>
                              <span>CODE</span>
                            </TableCell>
                            <TableCell className="table-cell" style={{ fontWeight: "bold", width: "auto", display: 'flex' }}>
                              <span>NAME</span>
                            </TableCell>
                            <TableCell className="table-cell" style={{ fontWeight: "bold", alignItems: "center", display: 'flex', maxWidth: "150px", justifyContent: "center" }}>
                              <span>STATUS</span>
                            </TableCell>
                            {["admin", "superadmin"].includes(currentUser.user_type_xx) && <TableCell className="table-cell" style={{ fontWeight: "bold", alignItems: "center", display: 'flex', maxWidth: "100px", justifyContent: "center" }}>
                              <span>ACTION</span>
                            </TableCell>}
                          </TableRow>
                        </TableHead>
                        <TableBody className="tablebody">
                          {slicedOrgUnits?.map((row, index) => (
                            <TableRow
                              key={row._id}
                              className={`${selectedOrgUnit?._id === row._id ? "tablerow current" : "tablerow"}`}
                              onClick={() => {
                                try {
                                  setSelectedOrgUnit(row);
                                } catch (error) {
                                  console.log(error);
                                }
                              }}
                            >
                              <TableCell className="table-cell" style={{ maxWidth: "50px", fontWeight: "bold", display: "flex", alignItems: "center" }}>
                                {currentOrgUnitPage * rowsPerPage + index + 1}
                              </TableCell>
                              <TableCell className="table-cell" style={{ display: "flex", fontWeight: "bold", maxWidth: "200px", flexDirection: "column" }}>
                                {/* <input
                              id="unit_code"
                              name="unit_code"
                              value={row.unit_code}
                              onChange={(e) => handleOrgUnits(e, index)}
                              style={{ width: "100%" }}
                              className="form-input-item"
                              disabled
                            /> */}
                                <span style={{ fontWeight: "400", fontSize: "18px" }}>{row.unit_code}</span>
                              </TableCell>
                              <TableCell className="table-cell" style={{ display: "flex", fontWeight: "bold", width: "auto", flexDirection: "column" }}>
                                <input
                                  id="unit_name"
                                  name="unit_name"
                                  value={row.unit_name}
                                  onChange={(e) => handleOrgUnitsChange(e, row._id)}
                                  style={{ width: "100%" }}
                                  className="form-input-item"
                                  disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                                />
                              </TableCell>
                              <TableCell className="table-cell" style={{ display: "flex", fontWeight: "bold", maxWidth: "150px", justifyContent: "center" }}>
                                <Select
                                  id="unit_status"
                                  name="unit_status"
                                  value={row.unit_status}
                                  onChange={(e) => handleOrgUnitsChange(e, row._id)}
                                  variant="outlined"
                                  size="small"
                                  disabled
                                >
                                  <MenuItem value="Active">Active</MenuItem>
                                  <MenuItem value="Suspended">Suspended</MenuItem>
                                  <MenuItem value="Closed">Closed</MenuItem>
                                </Select>
                              </TableCell>
                              {["admin", "superadmin"].includes(currentUser.user_type_xx) && <TableCell className="table-cell" style={{ display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: 'space-around', padding: "3px", maxWidth: "100px", }}>
                                <Tooltip
                                  title={`Edit ${row.unit_name}?`}
                                  placement="right"
                                >
                                  <EditIcon
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      borderRadius: "5px",
                                      padding: "3px",
                                      alignSelf: "center",
                                      cursor: "pointer",
                                      color: "rgba(0, 128, 0, 1)",
                                      backgroundColor: "rgba(0, 128, 0, 0.5)",
                                    }}
                                    onClick={() => {
                                      try {
                                        setSelectedOrgUnit(row);
                                        setCurrencyAction("editUnit");

                                        setUnitCode(row.unit_code)
                                        setUnitName(row.unit_name)
                                        setUnitType({
                                          value: row.unit_type,
                                          label: row.unit_type,
                                        })
                                        setUnitAddress1(row.unit_address1)
                                        setUnitEmail(row.unit_email)
                                        setUnitPhone(row.unit_phone)
                                        setUnitStatus({
                                          value: row.unit_status,
                                          label: row.unit_status,
                                        })
                                        setOpenAddUnit(true);
                                      } catch (error) {
                                        console.log(error);
                                      }
                                    }}
                                  />
                                </Tooltip>
                              </TableCell>}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <TablePagination
                        rowsPerPageOptions={[5]}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleOrgUnitsRowsPerPage}
                        component="div"
                        count={orgUnits?.length}
                        rows={orgUnits}
                        page={currentOrgUnitPage}
                        onPageChange={handleOrgUnitsPageChange}
                      />
                    </TableContainer>
                  </div>
                  {/* End of Business Units */}

                  {/* Number Generattion */}
                  <hr />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      justifyContent: "flex-start",
                    }}
                  >
                    <h2 className="sub-titles">NUMBER GENERATION</h2>
                    {["admin", "superadmin"].includes(currentUser.user_type_xx) && <Tooltip title={`Add Business Unit`} placement="right">
                      <AddIcon
                        size={50}
                        style={{
                          borderRadius: "5px",
                          padding: "3px",
                          alignSelf: "center",
                          cursor: "pointer",
                          backgroundColor: "rgba(0, 128, 0, 0.5)",
                        }}
                        aria-describedby={id}
                        onClick={handlePopoverClick}
                      />
                    </Tooltip>}
                  </div>
                  <Popover
                    id={id}
                    open={popoverOpen}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    <div style={{ padding: "20px" }}>
                      <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: "max-content",
                        marginBottom: "5px"
                      }}>
                        <span>ADD NUMBER GEN</span>
                        <span
                          onClick={handlePopoverClose}
                        >
                          <IoClose
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                              borderRadius: "50%",
                            }}
                          />
                        </span>
                      </div>
                      <div className="input-item-with-label">
                        <label
                          htmlFor="new_doc"
                          style={{ width: "50px" }}
                        >
                          TYPE
                        </label>
                        <ReactSelect
                          id="new_doc"
                          name="new_doc"
                          width="160px"
                          value={newDocType}
                          onChange={(selectedOption) => {
                            try {
                              setNewDocType(selectedOption);
                            } catch (error) {
                              console.log(error);
                            }
                          }}
                          options={documentOptions}
                          isSearchable={true}
                          placeholder="Document"
                          menuPortalTarget={document.body}
                          styles={{
                            ...customSelectStyles,
                            menuPortal: base => ({
                              ...base,
                              zIndex: 9999, // Ensure the dropdown is above other elements
                            })
                          }}
                        />
                        <span style={{ display: "flex", margin: "0px 10px" }}>
                          <CustomButton
                            startIcon={<AddIcon />}
                            onClick={handleNumGenAction}
                          >
                            Add
                          </CustomButton>
                        </span>
                      </div>
                    </div>
                  </Popover>
                  <div className="table-content">
                    <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none' }} className="table-container">
                      <Table sx={{ width: "max-content", border: 'none' }}>
                        <TableHead className="tablehead">
                          <TableRow className="tablerow">
                            <TableCell className="table-cell" style={{ maxWidth: "50px", fontWeight: "bold", display: "flex", alignItems: "center" }}>
                              #
                            </TableCell>
                            <TableCell className="table-cell" style={{ fontWeight: "bold", width: "auto", display: 'flex' }}>
                              <span>TYPE</span>
                            </TableCell>
                            <TableCell className="table-cell" style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", maxWidth: "150px", flexDirection: "row" }}>
                              <span>USE</span>
                            </TableCell>
                            <TableCell className="table-cell" style={{ fontWeight: "bold", alignItems: "center", display: 'flex', maxWidth: "100px", justifyContent: "center" }}>
                              <span>AUTO</span>
                            </TableCell>
                            <TableCell className="table-cell" style={{ fontWeight: "bold", alignItems: "center", display: 'flex', maxWidth: "150px", justifyContent: "center" }}>
                              <span>CURRENT NO</span>
                            </TableCell>
                            <TableCell className="table-cell" style={{ fontWeight: "bold", alignItems: "center", display: 'flex', maxWidth: "70px", justifyContent: "center" }}>
                              <span>PREFIX</span>
                            </TableCell>
                            <TableCell className="table-cell" style={{ fontWeight: "bold", alignItems: "center", display: 'flex', maxWidth: "70px", justifyContent: "center" }}>
                              <span>SUFFIX</span>
                            </TableCell>
                            {["admin", "superadmin"].includes(currentUser.user_type_xx) && <TableCell className="table-cell" style={{ fontWeight: "bold", alignItems: "center", display: 'flex', maxWidth: "100px", justifyContent: "center" }}>
                              <span>ACTION</span>
                            </TableCell>}
                          </TableRow>
                        </TableHead>
                        <TableBody className="tablebody">
                          {slicedNumGens?.map((row, index) => (
                            <TableRow
                              key={row._id}
                              className={`${selectedNumberGen?._id === row._id ? "tablerow current" : "tablerow"}`}
                              onClick={() => {
                                try {
                                  setSelectedNumberGen(row);
                                } catch (error) {
                                  console.log(error);
                                }
                              }}
                            >
                              <TableCell className="table-cell" style={{ maxWidth: "50px", fontWeight: "bold", display: "flex", alignItems: "center" }}>
                                {currentNumGenPage * rowsPerPage + index + 1}
                              </TableCell>
                              <TableCell className="table-cell" style={{ display: "flex", fontWeight: "bold", width: "auto", flexDirection: "column" }}>
                                <span style={{ fontWeight: "400", fontSize: "18px" }}>{row.gen_type}</span>
                              </TableCell>
                              <TableCell className="table-cell" style={{ display: "flex", fontWeight: "bold", maxWidth: "150px", flexDirection: "column" }}>
                                <Select
                                  id="gen_entity_type"
                                  name="gen_entity_type"
                                  value={row.gen_entity_type}
                                  onChange={(e) => handleNumGens(e, currentOrgUnitPage * rowsPerPage + index)}
                                  style={{ width: "100%" }}
                                  variant="outlined"
                                  size="small"
                                  disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                                >
                                  <MenuItem value="sequence">Sequence</MenuItem>
                                  {row.gen_type === "Entities" && <MenuItem value="name">Name</MenuItem>}
                                </Select>
                              </TableCell>
                              <TableCell className="table-cell" style={{ display: "flex", fontWeight: "bold", maxWidth: "100px", justifyContent: "center" }}>
                                <Select
                                  id="gen_auto"
                                  name="gen_auto"
                                  value={row.gen_auto}
                                  onChange={(e) => handleNumGens(e, currentOrgUnitPage * rowsPerPage + index)}
                                  style={{ width: "100%" }}
                                  variant="outlined"
                                  size="small"
                                  disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                                >
                                  <MenuItem value="Y">Yes</MenuItem>
                                  <MenuItem value="N">No</MenuItem>
                                </Select>
                              </TableCell>
                              <TableCell className="table-cell" style={{ display: "flex", fontWeight: "bold", maxWidth: "150px", justifyContent: "center" }}>
                                <input
                                  id="gen_current_no"
                                  name="gen_current_no"
                                  value={row.gen_current_no}
                                  onChange={(e) => handleNumGens(e, currentOrgUnitPage * rowsPerPage + index)}
                                  style={{ width: "100%" }}
                                  className="form-input-item"
                                  disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                                />
                              </TableCell>
                              <TableCell className="table-cell" style={{ display: "flex", fontWeight: "bold", maxWidth: "70px", justifyContent: "center" }}>
                                <input
                                  id="gen_prefix"
                                  name="gen_prefix"
                                  value={row.gen_prefix}
                                  onChange={(e) => handleNumGens(e, currentOrgUnitPage * rowsPerPage + index)}
                                  style={{ width: "100%" }}
                                  className="form-input-item"
                                  disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                                />
                              </TableCell>
                              <TableCell className="table-cell" style={{ display: "flex", fontWeight: "bold", maxWidth: "70px", justifyContent: "center" }}>
                                <input
                                  id="gen_surfix"
                                  name="gen_surfix"
                                  value={row.gen_surfix}
                                  onChange={(e) => handleNumGens(e, currentOrgUnitPage * rowsPerPage + index)}
                                  style={{ width: "100%" }}
                                  className="form-input-item"
                                  disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                                />
                              </TableCell>
                              {["admin", "superadmin"].includes(currentUser.user_type_xx) && <TableCell className="table-cell" style={{ display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: 'space-around', padding: "3px", maxWidth: "100px", }}>
                                <Tooltip
                                  title={`Delete ${row.gen_type}?`}
                                  placement="right"
                                >
                                  <DeleteIcon
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      borderRadius: "5px",
                                      padding: "3px",
                                      alignSelf: "center",
                                      cursor: "pointer",
                                      color: "rgba(244, 67, 54)",
                                      backgroundColor: "rgba(244, 67, 54, 0.5)",
                                    }}
                                    onClick={() => {
                                      try {
                                        let action_text = `Delete ${row.gen_type}?`;
                                        setObjectToAction({
                                          object_id: row._id,
                                          action_text: action_text,
                                          new_doc_status: "deleteNumGen",
                                        });
                                        setConfirmDialogAction("deleteNumGen");
                                        setIsConfirmDialogOpen(true);
                                      } catch (error) {
                                        console.log(error);
                                      }
                                    }}
                                  />
                                </Tooltip>
                              </TableCell>}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <TablePagination
                        rowsPerPageOptions={[5]}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleNumGensRowsPerPage}
                        component="div"
                        count={numberGens?.length}
                        rows={numberGens}
                        page={currentNumGenPage}
                        onPageChange={handleNumGensPageChange}
                      />
                    </TableContainer>
                  </div>

                  {/* End ofNumber Generation */}
                </motion.div>}
              {alignValue === "reports" &&
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { duration: 1 } }}
                  exit={{ opacity: 0, transition: { duration: 1 } }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "5px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: "10px"
                      }}
                    >
                      <div className="search-input-wrapper">
                        <SearchIcon className="search-input-icon" />
                        <input
                          className="search-input"
                          type="text"
                          placeholder="Search..."
                          value={searchTermRepVer}
                          onChange={(e) => setSearchTermRepVer(e.target.value)}
                        />
                        {searchTermRepVer.length > 0 && (
                          <CloseIcon
                            onClick={handleClearRepVerSearch}
                            className="search-input-icon"
                          />
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        gap: "10px"
                      }}
                    >
                      {filteredRepVerData.map((row, index) => (
                        <div
                          key={row._id}
                        >
                          <div className="single-report-version">
                            <span className="title">{row.report_title}</span>
                            <span className="desc">{row.report_desc}</span>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                              <Select
                                id="version"
                                name="version"
                                style={{ width: "80px" }}
                                value={row.version}
                                onChange={(e) => handleReportVersionChange(e, row._id)}
                                variant="outlined"
                                size="small"
                              >
                                <MenuItem value="v0">v0</MenuItem>
                                <MenuItem value="v1">v1</MenuItem>
                                <MenuItem value="v2">v2</MenuItem>
                                <MenuItem value="v3">v3</MenuItem>
                              </Select>
                              <CustomButton onClick={() => handleDocumentPreview(row.report_key, row.report_title)}>Preview</CustomButton>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </motion.div>}
              {alignValue === "users" &&
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { duration: 1 } }}
                  exit={{ opacity: 0, transition: { duration: 1 } }}
                ></motion.div>}
            </div>
          </div>
          <div className="right-wrapper">
            <div className="right-content">
              <h2 className="titles">SUMMARY</h2>
              <hr style={{ margin: "5px 0px" }} />
              <span>{`Opened ${orgRecordData.time_ago_xx}`}</span>
              <span>{`Users ${orgStats.users_count}`}</span>
              <span>{`Products ${orgStats.uw_products}`}</span>
              <hr style={{ margin: "5px 0px" }} />
              <span className="titles">Entity Stats</span>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                {orgStats.entities_stats?.map((st) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      margin: "5px 0px",
                      gap: "10px",
                    }}
                    key={st._id}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {st.aent_name}
                    </span>
                    <span>{numeral(st.count).format("0,0")}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Dialog to notify user about changes being saved */}
      <Dialog open={isNotifyDialogOpen} onClose={handleCloseNotifyDialog}>
        <DialogTitle>{notifyDialogTitle}</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: notifyDialogText }} />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseNotifyDialog}>Close</CustomButton>
        </DialogActions>
      </Dialog>
      {/* Confirm Dialog */}
      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        dialogTitle="Confirmation"
        dialogContent={<p>{objectToAction.action_text}</p>}
      />
      {isCodeDialogOpened && (
        <motion.div
          className="new-rate-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="new-rate-wrapper">
            <div className="new-rate-navbar">
              <span>{`${currencyAction === "addSysCode" ? "ADD CODE" : "ADD TYPE"
                }`}</span>
              <span
                onClick={() => {
                  try {
                    setIsCodeDialogOpened(false);
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                <IoClose
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    borderRadius: "50%",
                  }}
                />
              </span>
            </div>
            <div className="new-rate-action">
              {currencyAction === "addSysCode" && (
                <CustomButton>ADD</CustomButton>
              )}
              {currencyAction === "addSysType" && (
                <CustomButton>ADD</CustomButton>
              )}
            </div>
          </div>
        </motion.div>
      )}
      {/* add organization unit */}
      {openAddUnit && (
        <motion.div
          className="new-rate-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="new-rate-wrapper">
            <div className="new-rate-navbar">
              {currencyAction === "addUnit" && <span>ADD BUSINESS UNIT</span>}
              {currencyAction === "editUnit" && <span style={{ textTransform: "uppercase" }}>{`EDIT ${selectedOrgUnit.unit_name}`}</span>}
              <span
                onClick={() => {
                  try {
                    setSelectedOrgUnit(null);
                    setCurrencyAction("none");
                    setUnitCode("")
                    setUnitName("")
                    setUnitType(null)
                    setUnitAddress1("")
                    setUnitEmail("")
                    setUnitPhone("")
                    setUnitStatus(null)
                    setOpenAddUnit(false);
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                <IoClose
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    borderRadius: "50%",
                  }}
                />
              </span>
            </div>
            <div className="new-rate-content">
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                <div className="input-item-with-label">
                  <label htmlFor="unit_code" style={{ width: "100px" }}>
                    CODE
                  </label>
                  <input
                    name="unit_code"
                    type="text"
                    placeholder="CODE"
                    className="form-input-item"
                    style={{ width: "130px" }}
                    value={unitCode}
                    onChange={(e) => setUnitCode(e.target.value)}
                    disabled={currencyAction === "editUnit"}
                  />
                </div>
                <div className="input-item-with-label">
                  <label
                    htmlFor="unit_status"
                    style={{ width: "80px" }}
                  >
                    STATUS
                  </label>
                  <ReactSelect
                    id="unit_status"
                    name="unit_status"
                    styles={customSelectStyles}
                    width="155px"
                    value={unitStatus}
                    onChange={(selectedOption) => {
                      try {
                        setUnitStatus(selectedOption);
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                    options={statusOptions}
                    isSearchable={false}
                    placeholder="STATUS"
                  />
                </div>
              </div>
              <div className="input-item-with-label">
                <label htmlFor="unit_name" style={{ width: "100px" }}>
                  NAME
                </label>
                <input
                  name="unit_name"
                  type="text"
                  placeholder="NAME"
                  className="form-input-item"
                  style={{ width: "370px" }}
                  value={unitName}
                  onChange={(e) => setUnitName(e.target.value)}
                />
              </div>
              <div className="input-item-with-label">
                <label
                  htmlFor="unit_type"
                  style={{ width: "100px" }}
                >
                  TYPE
                </label>
                <ReactSelect
                  id="unit_type"
                  name="unit_type"
                  styles={customSelectStyles}
                  width="370px"
                  value={unitType}
                  onChange={(selectedOption) => {
                    try {
                      setUnitType(selectedOption);
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                  options={unitTypeOptions}
                  isSearchable={false}
                  placeholder="TYPE"
                />
              </div>
              <div className="input-item-with-label">
                <label htmlFor="unit_address1" style={{ width: "100px", alignSelf: "flex-start" }}>
                  ADDRESS
                </label>
                <textarea
                  name="unit_address1"
                  type="text"
                  placeholder="ADDRESS"
                  className="form-input-item"
                  style={{ width: "370px" }}
                  value={unitAddress1}
                  rows={3}
                  onChange={(e) => setUnitAddress1(e.target.value)}
                />
              </div>
              <div className="input-item-with-label">
                <label htmlFor="unit_email" style={{ width: "100px" }}>
                  EMAIL
                </label>
                <input
                  name="unit_email"
                  type="email"
                  placeholder="EMAIL"
                  className="form-input-item"
                  style={{ width: "370px" }}
                  value={unitEmail}
                  onChange={(e) => setUnitEmail(e.target.value)}
                />
              </div>
              <div className="input-item-with-label">
                <label htmlFor="unit_phone" style={{ width: "100px" }}>
                  PHONE
                </label>
                <input
                  name="unit_phone"
                  type="email"
                  placeholder="PHONE"
                  className="form-input-item"
                  style={{ width: "370px" }}
                  value={unitPhone}
                  onChange={(e) => setUnitPhone(e.target.value)}
                />
              </div>
            </div>
            <div className="new-rate-action">
              <CustomButton onClick={handleBizUnitAction}>{`${currencyAction === "addUnit" ? `ADD ${unitName}` : `UPDATE ${unitName}`}`}</CustomButton>
            </div>
          </div>
        </motion.div>
      )}
      {/* end of adding organization unit */}
      {isProcessing && (
        <motion.div
          className="processing-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="processing-wrapper">
            <GridLoader
              color="#F44336"
              style={{
                height: "50px",
                width: "50px",
              }}
            />
          </div>
        </motion.div>
      )}
      {/* progress dialog for file upload  */}
      <UploadDialog
        open={isUploadDialogOpen}
        handleClose={handleUploadDoalogClose}
        uploadProgress={uploadProgress}
      />
    </div>
  );
};

const UploadDialog = ({ open, handleClose, uploadProgress }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Progress</DialogTitle>
      <DialogContent>
        <LinearProgress variant="determinate" value={uploadProgress} />
        {uploadProgress && <p>{uploadProgress}% Complete</p>}
      </DialogContent>
    </Dialog>
  );
};

export default AdCompany;
