import "./GettingStarted.scss"
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GridLoader } from "react-spinners";
import { motion } from "framer-motion";
import {
    getStorage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
} from "firebase/storage";
import app from "../../utils/firebase";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { CustomLoadingButton } from "../../components/ui/CustomButton";
import newRequest from "../../utils/newRequest";
import { addMonthsToMongoDate, getTodaysDateWithoutTime, validateEmail } from "../../utils/genericFunctions";
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';
import EntityImport from "../all/entityImport/EntityImport";
import BusinessUnits from "../../components/businessUnits/BusinessUnits";
import NumberGeneration from "../../components/numberGeneration/NumberGeneration";
import DownloadIcon from '@mui/icons-material/Download';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { logOut } from "../../redux/userSlice.js";
import { useNavigate } from "react-router-dom";

const GettingStarted = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { currentUser } = useSelector((state) => state.user);
    const [isProcessing, setIsProcessing] = useState(false);
    // organization details
    const [orgImg, setOrgImg] = useState(null);
    const [orgDocId, setOrgDocId] = useState("");
    const [orgCode, setOrgCode] = useState("");
    const [orgName, setOrgName] = useState("");
    const [orgType, setOrgType] = useState("");
    const [orgWebsite, setOrgWebsite] = useState("");
    const [orgAddress1, setOrgAddress1] = useState("");
    const [orgAddress2, setOrgAddress2] = useState("");
    const [orgEmail, setOrgEmail] = useState("");
    const [orgPhone, setOrgPhone] = useState("");
    const [orgLogoUrl, setOrgLogoUrl] = useState("");
    const [orgBaseCur, setOrgBaseCur] = useState("");
    const [orgTaxPin, setOrgTaxPin] = useState("");
    const [orgLicenseNo, setOrgLicenseNo] = useState("");

    const [orgDocIdError, setOrgDocIdError] = useState("");
    const [orgCodeError, setOrgCodeError] = useState("");
    const [orgNameError, setOrgNameError] = useState("");
    const [orgTypeError, setOrgTypeError] = useState("");
    const [orgWebsiteError, setOrgWebsiteError] = useState("");
    const [orgAddress1Error, setOrgAddress1Error] = useState("");
    const [orgAddress2Error, setOrgAddress2Error] = useState("");
    const [orgEmailError, setOrgEmailError] = useState("");
    const [orgPhoneError, setOrgPhoneError] = useState("");
    const [orgLogoUrlError, setOrgLogoUrlError] = useState("");
    const [orgTaxPinError, setOrgTaxPinError] = useState("");
    const [orgLicenseNoError, setOrgLicenseNoError] = useState("");

    // end of organization details

    // upload templates
    const [entitiesUploadTemplate, setEntitiesUploadTemplate] = useState({});

    const fetchUploadTemplates = async () => {
        try {
            const res = await newRequest.post("/dataTemplate/findByCode", { tpl_code: "Entities" });
            setEntitiesUploadTemplate(res.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {

        }
    };



    const fetchOrganization = async () => {
        try {
            const res = await newRequest.post("/org/find");
            if (res.data) {
                setOrgDocId(res.data._id);
                setOrgCode(res.data.org_code);
                setOrgName(res.data.org_name);
                setOrgType(res.data.org_type);
                setOrgWebsite(res.data.org_website);
                setOrgAddress1(res.data.org_address1);
                setOrgAddress2(res.data.org_address2);
                setOrgEmail(res.data.org_email);
                setOrgPhone(res.data.org_phone);
                setOrgLogoUrl(res.data.org_logo_url);
                setOrgBaseCur(res.data.org_base_cur);
                setOrgTaxPin(res.data.org_tax_pin);
                setOrgLicenseNo(res.data.org_license_no);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {

        }
    };

    const [activeStep, setActiveStep] = useState(0);
    const steps = [
        {
            label: 'Overview',
            description: `Almost there!`,
        },
        {
            label: `Update ${currentUser?.usr_org_name} information`,
            description: `
        You need to enter the following information<br>
         <ol>
            <li>Email address - Mandatory</li>
            <li>Phone number - Mandatory</li>
            <li>Website - Optional</li>
            <li>Business Licence No - Optional</li>
            <li>TAX Pin No - Optional</li>
            <li>Postal address- Optional</li>
            <li>Physical address- Optional</li>
            <li>Upload your logo</li>
          </ol>
        <br>
        This information will be displayed on your documents like risk notes and quotes.
    `,
        },
        {
            label: 'Business Units',
            description: `Enter your business units (branches) where you will be transacting business from. A default branch has been created. You can amend the name add/or more by clicked the "PLUS SIGN"`,
        },
        {
            label: 'Bank Accounts',
            description: `Default operational bank account. A default account has been created. You can amend its details and/or add others . You can add more by clicked the "PLUS SIGN"`,
        },
        {
            label: 'Number Generation syntax',
            description: `Select how you want your numbers to be generated for diffent codes and documents.`,
        },
        {
            label: 'Download entities template',
            description: `Get the template you will use to upload your clients and partners data.`,
        },
        {
            label: 'Upload Entities',
            description: `Download the entities upload template, enter the data as per the template and upload it. Please note, if a record has a missing entity code, it will be autogenerated`,
        },
    ];

    const handleNext = async () => {
        try {
            // console.log(`handleNext activeStep ${activeStep}`)
            let errors = 0;
            if (activeStep === 1) {
                errors = validateOrgDetails();
                if (errors > 0) {
                    // console.log("errors")
                    return;
                } else {
                    // console.log("updating org values")
                    await updateOrgDetails();
                };
            } else if (activeStep === 2) {
                // console.log("leaving org units")
            } else if (activeStep === 4) {
                // console.log("leaving num gen values")
            }

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } catch (error) {
            console.log(error)
        }
    };

    const handleBack = async () => {
        try {
            // console.log(`handleBack activeStep ${activeStep}`)
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        } catch (error) {
            console.log(error)
        }
    };


    const handleFinish = async () => {
        try {
            const currentDate = getTodaysDateWithoutTime();
            const subToDate = addMonthsToMongoDate(currentDate, 3);
            const res = await newRequest.put(
                `/org/upd/${currentUser.org_docid}`,
                {
                    org_plan: "pro",
                    org_payment_freq: "quarterly",
                    org_sub_code: "pro",
                    org_sub_from: currentDate,
                    org_sub_to: subToDate,
                    org_opened: "Y",
                }
            );
            // console.log(`/org/upd  ${JSON.stringify(res.data, null, 2)}`);

            dispatch(logOut());
            // navigate("/login");
        } catch (error) {
            console.log(error)
        }
    };



    const updateOrgDetails = async () => {
        try {
            setIsProcessing(true);
            const res = await newRequest.put(
                `/org/upd/${orgDocId}`,
                {
                    org_name: orgName,
                    org_website: orgWebsite,
                    org_address1: orgAddress1,
                    org_address2: orgAddress2,
                    org_email: orgEmail,
                    org_phone: orgPhone,
                    org_tax_pin: orgTaxPin,
                    org_license_no: orgLicenseNo,
                    updatedBy: currentUser.usr_id,
                }
            );

            if (orgImg) {
                await uploadToFirebase(orgImg, "logos");
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsProcessing(false);
        }
    };

    const uploadToFirebase = async (file, fileFolder) => {
        const storage = getStorage(app);
        const fileName = new Date().getTime() + file.name;
        const fileExtension = file.name.split(".").pop(); // Get file extension
        const fileType = file.type; // Get file type
        const fileSizeKB = file.size / 1024; // Get file size in KB
        const storageRef = ref(
            storage,
            `qc_${currentUser.usr_org_code}/${fileFolder}/${fileName}`
        );
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                // setUploadProgress(Math.round(progress));
                switch (snapshot.state) {
                    case "paused":
                        console.log("Upload is paused");
                        break;
                    case "running":
                        console.log("Upload is running");
                        break;
                    default:
                        break;
                }
            },
            (error) => {
                // setIsUploadDialogOpen(false);
                console.log(error);
            },
            async () => {
                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

                // update org logo
                const res = await newRequest.put(
                    `/org/upd/${orgDocId}`,
                    {
                        org_logo_url: downloadURL,
                        updatedBy: currentUser.usr_id,
                    }
                );

                // console.log(`Update Logo response  ${JSON.stringify(res.data, null, 2)}`);
                setOrgLogoUrl(downloadURL);

                // download logo as a file and store in assets folder
                const logo = await newRequest.post(
                    `/org/logo`,
                    {
                        logo_url: downloadURL,
                        org_code: currentUser.usr_org_code,
                    }
                );
                // console.log(`Reset Logo response  ${JSON.stringify(res.data, null, 2)}`);
                setOrgImg("");
            }
        );
    };

    const handleReset = async () => {
        try {
            resetOrgDetails();
            setActiveStep(0);
        } catch (error) {
            console.log(error)
        }
    };

    const validateOrgDetails = () => {
        try {
            let error = 0;
            if (!orgName) {
                error++;
                setOrgNameError("Name is mandatory")
            } else {
                setOrgNameError("")
            }
            if (!orgEmail) {
                error++;
                setOrgEmailError("Email is mandatory")
            } else {
                console.log(`Valid email ? ${validateEmail(orgEmail)} using email ${orgEmail}`)
                // validate email
                if (!validateEmail(orgEmail)) {
                    error++;
                    setOrgEmailError("Invalid email address");
                } else {
                    setOrgEmailError("");
                }
            }
            if (!orgPhone) {
                error++;
                setOrgPhoneError("Phone No is mandatory")
            } else {
                setOrgPhoneError("")
            }
            return error;
        } catch (error) {
            console.log(error)
        }
    }

    const resetOrgDetails = () => {
        try {
            setOrgDocIdError("");
            setOrgCodeError("");
            setOrgNameError("");
            setOrgTypeError("");
            setOrgWebsiteError("");
            setOrgAddress1Error("");
            setOrgAddress2Error("");
            setOrgEmailError("");
            setOrgPhoneError("");
            setOrgLogoUrlError("");
            setOrgTaxPinError("");
            setOrgLicenseNoError("");
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        fetchOrganization();
        fetchUploadTemplates();
    }, []);

    return (
        <motion.div className="getting-started"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 1 } }}
            exit={{ opacity: 0, transition: { duration: 1 } }}
        >
            <div className="gs-navbar">
                <div className="gs-navbar-left">
                    <h1 className="gs-title">Getting Started</h1>
                </div>
                <div className="gs-navbar-right">
                    <span className="step-title">{steps[activeStep]?.label}</span>
                    <div className="gs-navbar-buttons">
                        <CustomLoadingButton
                            disabled={activeStep === 0}
                            onClick={handleBack}>
                            Back
                        </CustomLoadingButton>
                        {activeStep < steps.length &&
                            <CustomLoadingButton
                                onClick={handleNext}>
                                Continue
                            </CustomLoadingButton>
                        }
                        {activeStep === steps.length &&
                            <>
                                <CustomLoadingButton onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                                    Start Over
                                </CustomLoadingButton>
                                <CustomLoadingButton onClick={handleFinish} sx={{ mt: 1, mr: 1 }}>
                                    Finish
                                </CustomLoadingButton>
                            </>
                        }
                    </div>
                </div>
            </div>
            <hr style={{ margin: "10px 0px" }} />
            <div className="getting-started-wrapper">
                <div className="gs-left">
                    <Box sx={{ maxWidth: 400 }}>
                        <Stepper activeStep={activeStep} orientation="vertical" >
                            {steps.map((step, index) => (
                                <Step key={step.label} >
                                    <StepLabel
                                        optional={
                                            index === (steps?.length - 1) ? (
                                                <Typography variant="caption">Last step</Typography>
                                            ) : null
                                        }
                                    >
                                        <h1 className={`${index === activeStep ? "step-label current" : "step-label"}`}>{step.label}</h1>
                                    </StepLabel>
                                    <StepContent >
                                        <Typography
                                            dangerouslySetInnerHTML={{ __html: step.description }}
                                        />
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                        {activeStep === steps.length && (
                            <Paper square elevation={0} sx={{ p: 3 }}>
                                <Typography>All steps completed - you&apos;re finished</Typography>
                                <div className="gs-left-buttons">
                                    <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                                        Reset
                                    </Button>
                                </div>
                            </Paper>
                        )}
                    </Box>
                </div>
                <div className="gs-right">
                    {activeStep === 0 &&
                        <motion.div className="step-0"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1, transition: { duration: 1 } }}
                            exit={{ opacity: 0, transition: { duration: 1 } }}>
                            <h1 className="welcome-title">{`Welcome ${currentUser.usr_full_name}`}</h1>
                            <h1 className="welcome-sub-title">{`Just a few steps before you can get going`}</h1>
                        </motion.div>
                    }
                    {activeStep === 1 && <motion.div className="step-1"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 1 } }}
                        exit={{ opacity: 0, transition: { duration: 1 } }}>
                        <div className="org-details-wrapper">
                            <div className="org-details-left">

                                <div className="input-item-with-label" style={{ width: "400px" }}>
                                    <div className="input-item-bar">
                                        <span className="prompt">
                                            EMAIL
                                        </span>
                                        {orgEmailError && <span className="error" >
                                            {orgEmailError}
                                        </span>}
                                    </div>
                                    <input
                                        id="org_email"
                                        name="org_email"
                                        type="text"
                                        placeholder="EMAIL"
                                        value={orgEmail}
                                        onChange={(e) => setOrgEmail(e.target.value)}
                                        className="form-input-item"
                                        disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                                    />
                                    <p className="footer-prompt">This is the name of your Company. This name will be show in all documents</p>
                                </div>
                                <div className="input-item-with-label" style={{ width: "400px" }}>
                                    <div className="input-item-bar">
                                        <span className="prompt">
                                            PHONE NO
                                        </span>
                                        {orgPhoneError && <span className="error" >
                                            {orgPhoneError}
                                        </span>}
                                    </div>
                                    <input
                                        id="org_phone"
                                        name="org_phone"
                                        type="text"
                                        placeholder="PHONE NO"
                                        value={orgPhone}
                                        onChange={(e) => setOrgPhone(e.target.value)}
                                        className="form-input-item"
                                        disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                                    />
                                    <p className="footer-prompt">This is the name of your Company. This name will be show in all documents</p>
                                </div>

                                <div className="input-item-with-label" style={{ width: "400px" }}>
                                    <div className="input-item-bar">
                                        <span className="prompt">
                                            WEBSITE
                                        </span>
                                        {orgWebsiteError && <span className="error" >
                                            {orgWebsiteError}
                                        </span>}
                                    </div>
                                    <input
                                        id="org_website"
                                        name="org_website"
                                        type="text"
                                        placeholder="WEBSITE"
                                        value={orgWebsite}
                                        onChange={(e) => setOrgWebsite(e.target.value)}
                                        className="form-input-item"
                                        disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                                    />
                                    <p className="footer-prompt">This is the name of your Company. This name will be show in all documents</p>
                                </div>
                                <div className="input-item-with-label" style={{ width: "400px" }}>
                                    <div className="input-item-bar">
                                        <span className="prompt">
                                            LICENCE NO
                                        </span>
                                        {orgLicenseNoError && <span className="error" >
                                            {orgLicenseNoError}
                                        </span>}
                                    </div>
                                    <input
                                        id="org_license_no"
                                        name="org_license_no"
                                        type="text"
                                        placeholder="LICENSE NO"
                                        value={orgLicenseNo}
                                        onChange={(e) => setOrgLicenseNo(e.target.value)}
                                        className="form-input-item"
                                        disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                                    />
                                    <p className="footer-prompt">This is the name of your Company. This name will be show in all documents</p>
                                </div>
                                <div className="input-item-with-label" style={{ width: "400px" }}>
                                    <div className="input-item-bar">
                                        <span className="prompt">
                                            TAX PIN
                                        </span>
                                        {orgTaxPinError && <span className="error" >
                                            {orgTaxPinError}
                                        </span>}
                                    </div>
                                    <input
                                        id="org_tax_pin"
                                        name="org_tax_pin"
                                        type="text"
                                        placeholder="TAX PIN"
                                        value={orgTaxPin}
                                        onChange={(e) => setOrgTaxPin(e.target.value)}
                                        className="form-input-item"
                                        disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                                    />
                                    <p className="footer-prompt">This is the name of your Company. This name will be show in all documents</p>
                                </div>
                            </div>
                            <div className="org-details-right">
                                <div className="input-item-with-label" style={{ width: "400px" }}>
                                    <div className="input-item-bar">
                                        <span className="prompt">
                                            POSTAL ADDRESS
                                        </span>
                                        {orgAddress1Error && <span className="error" >
                                            {orgAddress1Error}
                                        </span>}
                                    </div>
                                    <textarea
                                        id="org_address1"
                                        name="org_address1"
                                        type="text"
                                        placeholder="POSTAL ADDRESS"
                                        value={orgAddress1}
                                        rows={2}
                                        onChange={(e) => setOrgAddress1(e.target.value)}
                                        className="form-input-item"
                                        disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                                    />
                                    <p className="footer-prompt">This is the name of your Company. This name will be show in all documents</p>
                                </div>
                                <div className="input-item-with-label" style={{ width: "400px" }}>
                                    <div className="input-item-bar">
                                        <span className="prompt">
                                            PHYSICAL ADDRESS
                                        </span>
                                        {orgAddress2Error && <span className="error" >
                                            {orgAddress2Error}
                                        </span>}
                                    </div>
                                    <textarea
                                        id="org_address2"
                                        name="org_address2"
                                        type="text"
                                        placeholder="PHYSICAL ADDRESS"
                                        value={orgAddress2}
                                        rows={2}
                                        onChange={(e) => setOrgAddress2(e.target.value)}
                                        className="form-input-item"
                                        disabled={!["admin", "superadmin"].includes(currentUser.user_type_xx)}
                                    />
                                    <p className="footer-prompt">This is the name of your Company. This name will be show in all documents</p>
                                </div>
                                <div className="logo">
                                    {orgImg ? <img
                                        src={URL.createObjectURL(orgImg)}
                                        alt=""
                                        className="img"
                                    /> :
                                        <>{orgLogoUrl ?
                                            <img src={orgLogoUrl} alt="" className="logo" /> : <BrokenImageOutlinedIcon style={{ width: "200px", height: "200px" }} />}</>
                                    }
                                    <input
                                        type="file"
                                        id="file"
                                        style={{ display: "none" }}
                                        accept="image/*"
                                        // accept=".png,image/png"
                                        onChange={(e) => {
                                            try {
                                                const file = e.target.files[0];
                                                if (file && file.type.includes('image/')) {
                                                    setOrgImg(file);
                                                } else {
                                                    // Handle case where file doesn't match expected type
                                                    console.log('Please select a .png file.');
                                                }
                                            } catch (error) {
                                                console.log(error)
                                            }
                                        }}
                                    />
                                    {["admin", "superadmin"].includes(currentUser.user_type_xx) &&
                                        <label htmlFor="file">
                                            <div className="item" style={{ marginTop: "30px", cursor: "pointer" }}>
                                                <span>SELECT (.png only)</span>
                                            </div>
                                        </label>}
                                </div>
                            </div>
                        </div>
                    </motion.div>}
                    {activeStep === 2 && <motion.div className="step-2"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 1 } }}
                        exit={{ opacity: 0, transition: { duration: 1 } }}>
                        <BusinessUnits />
                    </motion.div>}
                    {activeStep === 3 && <motion.div className="step-3"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 1 } }}
                        exit={{ opacity: 0, transition: { duration: 1 } }}>3</motion.div>}
                    {activeStep === 4 && <motion.div className="step-4"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 1 } }}
                        exit={{ opacity: 0, transition: { duration: 1 } }}>
                        <NumberGeneration />
                    </motion.div>}
                    {activeStep === 5 && <motion.div className="step-5"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 1 } }}
                        exit={{ opacity: 0, transition: { duration: 1 } }}>
                        <span className="title">Entities template</span>
                        <p>Click on the button below to download template for uploading entities. Entities are all your clients, Insurance Companies and other partners. Read "ReadMe" sheet for further instructions</p>
                        <DownloadIcon
                            className="icon"
                            onClick={() => {
                                try {
                                    window.open(entitiesUploadTemplate?.tpl_url, "_blank");
                                } catch (error) {
                                    console.log(error);
                                }
                            }} />
                    </motion.div>}
                    {activeStep === 6 && <motion.div className="step-6"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 1 } }}
                        exit={{ opacity: 0, transition: { duration: 1 } }}>
                        <EntityImport showClose={"N"} />
                    </motion.div>}
                    {activeStep === 7 && <motion.div className="step-7"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 1 } }}
                        exit={{ opacity: 0, transition: { duration: 1 } }}>
                        <h1 className="welcome-title">{`All set ${currentUser.usr_full_name}`}</h1>
                        <h1 className="welcome-sub-title">{`You are ready to issue your first business. Click below`}</h1>
                        <PlayArrowIcon
                            className="icon"
                            onClick={handleFinish} />
                        <p>You will be redirected to login page</p>
                    </motion.div>}
                </div>
            </div>

            {/* end of adding organization unit */}
            {isProcessing && (
                <motion.div
                    className="processing-dialog"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 1 } }}
                    exit={{ opacity: 0, transition: { duration: 1 } }}
                >
                    <div className="processing-wrapper">
                        <GridLoader
                            color="#F44336"
                            style={{
                                height: "50px",
                                width: "50px",
                            }}
                        />
                    </div>
                </motion.div>
            )}
        </motion.div>
    )
}

export default GettingStarted