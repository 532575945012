import "./AllEntity.scss";
import AllFiles from "../../../components/allFiles/AllFiles";
import ConfirmDialog from "../../../components/confirmDialog/confirmDialog";
import {
  CustomButton,
  CustomLoadingButton,
} from "../../../components/ui/CustomButton";
import { customSelectStyles } from "../../../components/ui/uiStyleElements";
import app from "../../../utils/firebase";
import { showToast } from "../../../toastService.js";
import newRequest from "../../../utils/newRequest";
import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { motion } from "framer-motion";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { FaSave } from "react-icons/fa";
import AddIcon from "@mui/icons-material/Add";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { IoClose } from "react-icons/io5";
import { MdOutlineMenuOpen } from "react-icons/md";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { ScaleLoader } from "react-spinners";
import FileUploadComponent from "../../../components/fileUploadComponent/FileUploadComponent.jsx";
import { sanitizeText } from "../../../utils/genericFunctions.js";


const UploadDialog = ({ open, handleClose, uploadProgress }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Progress</DialogTitle>
      <DialogContent>
        <LinearProgress variant="determinate" value={uploadProgress} />
        {uploadProgress && <p>{uploadProgress}% Complete</p>}
      </DialogContent>
    </Dialog>
  );
};

const AllEntity = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
  const [notifyDialogTitle, setNotifyDialogTitle] = useState("Confirm");
  const [notifyDialogText, setNotifyDialogText] = useState("Text");
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [additionalActionsMenu, setAdditionalActionsMenu] = useState(false);
  const [confirmDialogAction, setConfirmDialogAction] = useState("None");
  const [objectToAction, setObjectToAction] = useState({
    object_id: "",
    action_text: "",
    new_doc_status: "",
  });
  //get entity data
  const [entityData, setEntityData] = useState({});
  const [entityTraceData, setEntityTraceData] = useState([]);

  const [entOrgCode, setEntOrgCode] = useState(currentUser.usr_org_code);
  const [entCode, setEntCode] = useState(null);
  const [entAentCode, setEntAentCode] = useState(null);
  const [entName, setEntName] = useState(null);
  const [entType, setEntType] = useState(null);
  const [entStatus, setEntStatus] = useState(null);
  const [entSector, setEntSector] = useState(null);
  const [entSubSector, setEntSubSector] = useState(null);
  const [entSurname, setEntSurname] = useState(null);
  const [entOtherNames, setEntOtherNames] = useState(null);
  const [entIdNo, setEntIdNo] = useState(null);
  const [entGender, setEntGender] = useState(null);
  const [entDob, setEntDob] = useState(null);
  const [entAgeXx, setEntAgeXx] = useState(null);
  const [entTaxNo, setEntTaxNo] = useState(null);
  const [entLicenseNo, setEntLicenseNo] = useState(null);
  const [entPhoneNo, setEntPhoneNo] = useState(null);
  const [entEmail, setEntEmail] = useState(null);
  const [entPhotoUrl, setEntPhotoUrl] = useState(null);
  const [entPhysicalAddress, setEntPhysicalAddress] = useState(null);
  const [entPostalAddress, setEntPostalAddress] = useState(null);
  const [profileLimitValues, setProfileLimitValues] = useState([]);
  const [documentEnabled, setDocumentEnabled] = useState("Y");
  const [saveEnabled, setSaveEnabled] = useState("Y");
  const [nextAction, setNextAction] = useState("Activate");
  //entity saved files
  const [entityFilesData, setEntityFilesData] = useState([]);
  const [file, setFile] = useState(null);
  const location = useLocation();
  const parts = location.pathname.split("/");
  const [entityId, setEntityId] = useState(parts[parts.length - 1]);
  const navigate = useNavigate();
  const actionsMenuRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (actionsMenuRef.current && !actionsMenuRef.current.contains(event.target)) {
        setAdditionalActionsMenu(false);
      }
    }

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleCloseNotifyDialog = () => {
    setIsNotifyDialogOpen(false);
  };

  const handleSetEntityData = (name, value) => {
    setEntityData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const goBack = () => {
    // navigate(-1, { state: { shouldFetchClients: false } });
    navigate(-1);
  };

  const saveFileToDb = async (
    fl_name,
    fl_internal_name,
    fl_type,
    fl_path,
    fl_category,
    fl_size_kb,
    fl_parent_doc_id
  ) => {
    try {
      const res = await newRequest.post(
        "/files/add",
        {
          fl_org_code: currentUser.usr_org_code,
          fl_name: fl_name,
          fl_internal_name: fl_internal_name,
          fl_type: fl_type,
          fl_path: fl_path,
          fl_category: fl_category,
          fl_doc_type: "",
          fl_doc_no: "",
          fl_doc_index: "",
          fl_doc_sub_index: "",
          fl_ent_code: entCode,
          fl_aent_code: entAentCode?.value,
          fl_user_id: "",
          fl_size_kb,
          fl_parent_doc_id,
          userCode: currentUser.usr_id,
        }
      );

      fetchEntityFiles(entityId);
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };

  const handleFirebaseFileUpload = () => {
    try {
      file && uploadToFirebase(file, "kyc");
    } catch (error) {
      console.log(error);
    }
  };

  // firebase uploads
  const [img, setImg] = useState(undefined);
  const [imgPerc, setImgPerc] = useState(0);
  const [inputs, setInputs] = useState({});

  useEffect(() => {
    img && uploadToFirebase(img, "profiles");
  }, [img]);

  const uploadToFirebase = async (file, fileFolder) => {
    setIsDialogOpen(true);
    const storage = getStorage(app);
    const fileName = new Date().getTime() + file.name;
    const fileExtension = file.name.split(".").pop(); // Get file extension
    const fileType = file.type; // Get file type
    const fileSizeKB = file.size / 1024; // Get file size in KB
    const storageRef = ref(
      storage,
      `qc_${currentUser.usr_org_code}/${fileFolder}/${fileName}`
    );
    const uploadTask = uploadBytesResumable(storageRef, file);

    // console.log(`fileName  ${fileName}`);
    // console.log(`fileExtension  ${fileExtension}`);
    // console.log(`fileType  ${fileType}`);
    // console.log(`fileSizeKB  ${fileSizeKB}`);
    // console.log(`storageRef  ${storageRef}`);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(Math.round(progress));
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        setIsDialogOpen(false);
        console.log(error);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        // console.log(`downloadURL  ${JSON.stringify(downloadURL, null, 2)}`);
        if (fileFolder === "profiles") {
          // update entity profile image

          try {
            const res = await newRequest.put(
              "/allEntity/profileUpd/",
              {
                entity_docid: entityId,
                ent_photo_url: downloadURL,
                userCode: currentUser.usr_id,
                org_code: currentUser.usr_org_code,
              }
            );
            entityData.ent_photo_url = downloadURL;
            setEntPhotoUrl(downloadURL);
            // fetchEntityFiles(entityId);

            const trace_res = await newRequest.post(
              "/trace/add",
              {
                trc_org_code: currentUser.usr_org_code,
                trc_action_on: "Entity",
                trc_doc_type: "Entity",
                trc_doc_no: entCode,
                trc_aent_code: entAentCode?.value,
                trc_ent_code: entCode,
                trc_action: "amended",
                trc_remarks: `uploaded new photo`,
                trc_tags: ["upload"],
                trc_doc_docid: entityId,
                userCode: currentUser.usr_id,
              }
            );
            fetchEntityTrace(entAentCode?.value, entCode);
            setIsDialogOpen(false);
          } catch (error) {
            console.log(error);
            setIsDialogOpen(false);
          }
        } else {
          await saveFileToDb(
            file.name,
            fileName,
            fileExtension,
            downloadURL,
            "kyc",
            fileSizeKB,
            entityId
          );
          setFile("");
          setIsDialogOpen(false);
        }
      }
    );
  };

  // end of firebase

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleConfirm = async () => {
    try {
      if (confirmDialogAction === "underReview") {
        // close account here
        const res = await newRequest.put(
          `/allEntity/upd/${entityId}`,
          {
            ent_status: "Under Review"
          }
        );
        if (res.status === 200) {
          await fetchEntity();
          showToast(`${entName} successfully set to UNDER REVIEW!`, "success");
        } else {
          showToast(`Error putting ${entName} UNDER REVIEW!`, "error");
        }
      } else if (confirmDialogAction === "closeAccount") {
        // close account here
        const res = await newRequest.put(
          `/allEntity/upd/${entityId}`,
          {
            ent_status: "Closed"
          }
        );
        if (res.status === 200) {
          await fetchEntity();
          showToast(`${entName} successfully CLOSED!`, "success");
        } else {
          showToast(`Error CLOSING ${entName}!`, "error");
        }
      } else if (confirmDialogAction === "reactivateAccount") {
        // reactivate account here
        const res = await newRequest.put(
          `/allEntity/upd/${entityId}`,
          {
            ent_status: "Active"
          }
        );
        if (res.status === 200) {
          await fetchEntity();
          showToast(`${entName} successfully RE-ACTIVATED!`, "success");
        } else {
          showToast(`Error RE-ACTIVATING ${entName}!`, "error");
        }
      }
    } catch (err) {
      console.log(err);
    }
    // Close the dialog
    setIsConfirmDialogOpen(false);
  };

  const handleCancel = () => {
    setIsConfirmDialogOpen(false);
  };

  const fetchEntity = async () => {
    try {
      const res = await newRequest.post(
        "/allEntity/findById",
        {
          ent_org_code: currentUser.usr_org_code,
          entity_docid: entityId,
        }
      );

      setEntityData(res.data);

      //  entity type
      if (res.data.ent_type) {
        setEntType({
          value: res.data.ent_type,
          label: res.data.entity_type_xx,
        });
      }

      // category
      if (res.data.ent_aent_code) {
        setEntAentCode({
          value: res.data.ent_aent_code,
          label: res.data.aent_name_xx,
        });
      }

      if (res.data.ent_gender) {
        setEntGender({
          value: res.data.ent_gender,
          label: res.data.gender_xx,
        });
      }

      if (res.data.ent_status) {
        setEntStatus({
          value: res.data.ent_status,
          label: res.data.entity_status_xx,
        });
      }
      setEntOrgCode(res.data?.ent_org_code);
      setEntCode(res.data?.ent_code);
      setEntName(res.data?.ent_name);
      setEntSurname(res.data?.ent_surname);
      setEntOtherNames(res.data?.ent_other_names);
      setEntIdNo(res.data?.ent_id_no);
      setEntTaxNo(res.data?.ent_tax_no);
      setEntLicenseNo(res.data?.ent_licence_no);
      setEntPhoneNo(res.data?.ent_phone_no);
      setEntEmail(res.data?.ent_email);
      setEntPhotoUrl(res.data?.ent_photo_url);
      setEntPhysicalAddress(res.data?.ent_physical_address);
      setEntPostalAddress(res.data?.ent_postal_address);


      if (res.data.ent_dob) {
        const formattedDate = moment(res.data.ent_dob).format("YYYY-MM-DD");
        setEntDob(formattedDate);
      }

      if (res.data.ent_age_xx) {
        setEntAgeXx(res.data.ent_age_xx)
      }

      if (res.data.document_enabled_xx) {
        setDocumentEnabled(res.data.document_enabled_xx);
      }
      if (res.data.save_enabled_xx) {
        setSaveEnabled(res.data.save_enabled_xx);
      }
      if (res.data.next_action_xx) {
        setNextAction(res.data.next_action_xx);
      }
      // fet account getManagers
      await fetchAccountManagers(res.data.ent_aent_code, res.data.ent_code);
      //Fetch Entity Files
      fetchEntityFiles(entityId);
      // fetch trace data
      fetchEntityTrace(res.data.ent_aent_code, res.data.ent_code);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchEntityFiles = async (entity_docid) => {
    try {
      const res = await newRequest.post(
        "/files/findEntityFiles",
        {
          fl_org_code: currentUser.usr_org_code,
          entity_docid: entity_docid,
        }
      );

      setEntityFilesData(res.data);
    } catch (err) {
      console.log(err);
    }
  };


  const fetchEntityTrace = async (aentCode, entCode) => {
    try {
      const res = await newRequest.post(
        "/trace/findEnt",
        {
          trc_aent_code: aentCode,
          trc_ent_code: entCode,
        }
      );
      setEntityTraceData(res.data);
    } catch (err) {
      console.log(err);
    }
  };


  const actionOnEntity = async (e) => {
    // e.preventDefault();
    try {
      if (entType) {

        let valueXx = "N";
        let limitXx = "N";

        if (nextAction === "Activate") {
          if (entType.value === "Corporate") {
            limitXx = "GB1000";
            valueXx = getLimitValue(limitXx);
          } else if (entType.value === "Government") {
            limitXx = "GB1001";
            valueXx = getLimitValue(limitXx);
          } else if (entType.value === "Individual") {
            limitXx = "GB1002";
            valueXx = getLimitValue(limitXx);
          } else if (entType.value === "NGO") {
            limitXx = "GB1003";
            valueXx = getLimitValue(limitXx);
          } else {
            limitXx = "GB1004";
            valueXx = getLimitValue(limitXx);
          }

          if (valueXx === "N") {
            setNotifyDialogTitle("Note!");
            setNotifyDialogText(`${limitXx}: You are not authorized to activate ${entType.value} accounts!`);
            setIsNotifyDialogOpen(true);
            return;
          }

          // activate account here
          const res = await newRequest.put(
            `/allEntity/upd/${entityId}`,
            {
              ent_status: "Active"
            }
          );

          if (res.status === 200) {
            await fetchEntity();
            showToast(`${entName} status changed to Active!`, "success");
          } else {
            showToast(`Error updating ${entName} status to Active!`, "error");
          }
        } else if (nextAction === "Re-Activate") {
          limitXx = "GB1007";
          valueXx = getLimitValue(limitXx);
          if (valueXx === "N") {
            setNotifyDialogTitle("Note!");
            setNotifyDialogText(`${limitXx}: You are not authorized to RE-ACTIVATE this account!`);
            setIsNotifyDialogOpen(true);
            return;
          }

          // reactivate account here
          const res = await newRequest.put(
            `/allEntity/upd/${entityId}`,
            {
              ent_status: "Active"
            }
          );

          if (res.status === 200) {
            await fetchEntity();
            showToast(`${entName} successfully RE-ACTIVATED!`, "success");
          } else {
            showToast(`Error RE-ACTIVATING ${entName}!`, "error");
          }
        }
      } else {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please select type!`);
        setIsNotifyDialogOpen(true);
        return;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const saveRecord = async (e) => {
    // e.preventDefault();
    try {
      if (!entType?.value) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please select type!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      if (!entAentCode?.value) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please select category!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      if (!entStatus?.value) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please select status!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      if (!entName) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter name!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      if (entityId !== "new") {
        const updatedAllEntity = {
          ...entityData,
          docId: entityId,
          updatedBy: currentUser.usr_id,
        };

        const res = await newRequest.put(
          `/allEntity/upd/${entityId}`,
          updatedAllEntity
        );
        fetchEntityTrace(entAentCode, entCode);
      } else {
        let updatedAllEntity = {};

        if (!entCode) {
          const res = await newRequest.post(
            "/allEntity/gen",
            {
              org_code: currentUser.usr_org_code,
              entity_name: entName,
            }
          );
          updatedAllEntity = {
            ...entityData,
            ent_code: res.data,
            ent_org_code: currentUser.usr_org_code,
            userCode: currentUser.usr_id,
          };
          setEntCode(res.data);
        } else {
          updatedAllEntity = {
            ...entityData,
            ent_org_code: currentUser.usr_org_code,
            userCode: currentUser.usr_id,
          };
        }

        // console.log(`Adding entity  ${JSON.stringify(updatedAllEntity, null, 2)}`);

        const res = await newRequest.post(
          "/allEntity/add",
          updatedAllEntity
        );

        setEntityId(res.data._id);
        fetchEntityTrace(res.data.ent_aent_code, res.data.ent_code);
        try {
          const trace_res = await newRequest.post(
            "/trace/add",
            {
              trc_org_code: currentUser.usr_org_code,
              trc_action_on: "Entity",
              trc_doc_type: "Entity",
              trc_doc_no: res.data.ent_code,
              trc_aent_code: res.data.ent_aent_code,
              trc_ent_code: res.data.ent_code,
              trc_action: "created",
              trc_remarks: `created this account`,
              trc_tags: ["creation"],
              trc_doc_docid: res.data._id,
              userCode: currentUser.usr_id,
            }
          );
        } catch (error) {
          console.log(error);
        }
      }

      setNotifyDialogTitle("Success!");
      setNotifyDialogText(`Details successfully saved`);
      setIsNotifyDialogOpen(true);
    } catch (err) {
      console.log(err);
    }
  };

  // entity types
  const [entityTypeOptions, setEntityTypeOptions] = useState([]);
  const fetchEntityType = async () => {
    try {
      const res = await newRequest.post(
        "/sysCode/findAll",
        {
          sys_org_code: currentUser.usr_org_code,
          sys_type: "Entity Types",
        }
      );

      const options = res.data.map((entityType) => ({
        value: entityType.sys_code,
        label: entityType.sys_name,
      }));

      setEntityTypeOptions(options);
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };

  // entity category
  const [entityCatgOptions, setEntityCatgOptions] = useState([]);
  const fetchEntityCatg = async () => {
    try {
      const res = await newRequest.post(
        "/allEntityCatg/findAll",
        {
          user_lang: "en_US",
          aent_org_code: currentUser.usr_org_code,
        }
      );
      const options = res.data.map((entityCatg) => ({
        value: entityCatg?.aent_code,
        label: entityCatg?.aent_name,
      }));
      setEntityCatgOptions(options);
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };

  // entity status
  const [entityStatusOptions, setEntityStatusOptions] = useState([]);
  const fetchEntityStatus = async () => {
    try {
      const res = await newRequest.post(
        "/sysCode/findAll",
        {
          sys_org_code: currentUser.usr_org_code,
          sys_type: "Entity Status",
        }
      );

      const options = res.data.map((entityStatus) => ({
        value: entityStatus.sys_code,
        label: entityStatus.sys_name,
      }));

      setEntityStatusOptions(options);
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };

  // entity gender
  const [entityGenderOptions, setEntityGenderOptions] = useState([]);
  const fetchEntityGender = async () => {
    try {
      const res = await newRequest.post(
        "/sysCode/findAll",
        {
          sys_org_code: currentUser.usr_org_code,
          sys_type: "Gender",
        }
      );

      const options = res.data.map((entityGender) => ({
        value: entityGender.sys_code,
        label: entityGender.sys_name,
      }));

      setEntityGenderOptions(options);
    } catch (error) {
      console.error("Error fetching entity Gender:", error);
    }
  };

  const fetchProfileLimits = async () => {
    try {
      const limitsToCheck = ["GB1000", "GB1001", "GB1002", "GB1003", "GB1004", "GB1005", "GB1006", "GB1007"];
      const limits = await newRequest.post("/adProfile/checkValues", { module_code: "gb", limit_codes: limitsToCheck });
      setProfileLimitValues(limits.data)
    } catch (error) {
      console.log(error)
    }
  }

  // Function to get limit_yn value by limit_code
  const getLimitValue = (limitCode) => {
    try {
      // console.log(`getLimitValue profileLimitValues ${JSON.stringify(profileLimitValues, null, 2)}`);
      const limit = profileLimitValues.find(l => l.limit_code === limitCode);
      return limit ? limit.limit_yn : null;
    } catch (error) {
      console.log(error)
    }
  };

  const [selectedManagerToAdd, setSelectedManagerToAdd] = useState(null)
  const [selectedRoleToAdd, setSelectedRoleToAdd] = useState(null)
  const [accountManagers, setAccountManagers] = useState([])
  const [users, setUsers] = useState([])
  const [userRoles, setUserRoles] = useState([]);

  const fetchAccountManagers = async (aentCode, entCode) => {
    try {

      const res = await newRequest.post(
        "/allEntityManager/findAll", {
        em_aent_code: aentCode,
        em_ent_code: entCode,
        em_user_role: ""
      });

      // console.log(`/allEntityManager/findAll  ${JSON.stringify(res.data, null, 2)}`);
      setAccountManagers(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchUsers = async () => {
    try {
      const res = await newRequest.post(
        "/adUser/findAll",
        {
          usr_org_code: currentUser.usr_org_code,
        }
      );
      const options = res.data.map((user) => ({
        value: user._id,
        label: user.usr_full_name,
      }));

      setUsers(options);
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };

  const fetchUserRoles = async () => {
    try {
      const res = await newRequest.post(
        "/sysCode/findAll",
        {
          sys_org_code: currentUser.usr_org_code,
          sys_type: "Account Role",
        }
      );

      const options = res.data.map((role) => ({
        value: role.sys_code,
        label: role.sys_name,
      }));

      setUserRoles(options);
    } catch (error) {
      console.error("Error fetching type types:", error);
    }
  };

  const handleAddManager = async () => {
    try {

      if (!selectedManagerToAdd?.value) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Select manager!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      if (!selectedRoleToAdd?.value) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Select role!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      const res = await newRequest.post(
        "/allEntityManager/add",
        {
          em_aent_code: entAentCode?.value,
          em_ent_code: entCode,
          em_user_id: selectedManagerToAdd?.value,
          em_user_role: selectedRoleToAdd?.value
        }
      );

      console.log(`/allEntityManager/add  ${JSON.stringify(res.data, null, 2)}`);
      if (res.data === "exists") {
        showToast(
          `User ${selectedManagerToAdd.label} is already ${selectedRoleToAdd?.value}`,
          "error"
        );
      } else {
        showToast(`User ${selectedManagerToAdd.label} added as ${selectedRoleToAdd?.value}`, "success");
      }
      setSelectedManagerToAdd(null);
      setSelectedRoleToAdd(null);
      await fetchAccountManagers(entAentCode?.value, entCode);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    //if entity is selected, use entityId to get entity details
    //if its new record, no fetch is necessary
    if (entityId !== "new") {
      fetchEntity();
      fetchUsers();
      fetchUserRoles();
    } else {
      setEntStatus({
        value: "New",
        label: "New",
      });
    }
    //get entity types
    fetchEntityType();
    //get entity categories
    fetchEntityCatg();
    //get entity status options
    fetchEntityStatus();
    //get gender options
    fetchEntityGender();
    // fet profile limits
    fetchProfileLimits();
  }, [entityData?.ent_code]);

  return (
    <motion.div
      className="all-entity"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      {isLoading ? (
        <div className="no-content"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <ScaleLoader
            color="#F44336"
            height={50}
            radius={8}
            width={4}
          />
        </div>
      ) : (
        <div className="all-entity-main-wrapper">
          <div className="center-wrapper">
            <div className="center-details">
              <div className="center-navbar">
                <div className="navbar-left">
                  <h1 className="main-title">
                    {entityData?.ent_name || "New"}
                  </h1>
                </div>
                <div className="navbar-right">
                  {entityId !== "new" && ["Under Review", "New"].includes(entStatus?.value) &&
                    <CustomLoadingButton startIcon={<DoneAllIcon />} onClick={actionOnEntity}>
                      {nextAction}
                    </CustomLoadingButton>}
                  {saveEnabled === "Y" &&
                    <CustomLoadingButton startIcon={<FaSave />} onClick={saveRecord}>
                      Save
                    </CustomLoadingButton>}
                  <CustomLoadingButton startIcon={<IoClose />} onClick={goBack}>
                    Close
                  </CustomLoadingButton>
                  <div className="icon-container"
                    ref={actionsMenuRef}
                    onClick={(e) => {
                      try {
                        e.stopPropagation();
                        setAdditionalActionsMenu(!additionalActionsMenu)
                      } catch (error) {
                        console.log(error)
                      }
                    }}>
                    <MdOutlineMenuOpen className="icon" />
                    {additionalActionsMenu && (
                      <div className="dropdown">
                        <div className="dropdown-item">Form</div>
                        {entStatus?.value === "Active" &&
                          <div className="dropdown-item"
                            onClick={() => {
                              try {
                                if (getLimitValue("GB1005") === "N") {
                                  setNotifyDialogTitle("Note!");
                                  setNotifyDialogText(`GB1005: You are not authorized to put ${entName} Under Review!`);
                                  setIsNotifyDialogOpen(true);
                                  return;
                                }
                                const action_text = `Are you sure you want to put <b>${entName}</b> UNDER REVIEW?. Please note all transactions related to this entity that are in progress cannot be approved until the account returns to ACTIVE state.`;
                                setObjectToAction({
                                  object_id: entityId,
                                  action_text: action_text,
                                });
                                setConfirmDialogAction("underReview");
                                setIsConfirmDialogOpen(true);
                              } catch (error) {
                                console.log(error)
                              }
                            }}>Under Review</div>}
                        {entStatus?.value === "Under Review" &&
                          <div className="dropdown-item"
                            onClick={() => {
                              try {
                                if (getLimitValue("GB1007") === "N") {
                                  setNotifyDialogTitle("Note!");
                                  setNotifyDialogText(`GB1007: You are not authorized to RE-ACTIVATE ${entName}!`);
                                  setIsNotifyDialogOpen(true);
                                  return;
                                }
                                const action_text = `Are you sure you want to RE-ACTIVATE <b>${entName}</b>?`;
                                setObjectToAction({
                                  object_id: entityId,
                                  action_text: action_text,
                                });
                                setConfirmDialogAction("reactivateAccount");
                                setIsConfirmDialogOpen(true);
                              } catch (error) {
                                console.log(error)
                              }
                            }}
                          >Re-Activate</div>}
                        {entStatus?.value === "Active" && <div className="dropdown-item"
                          onClick={() => {
                            try {
                              if (getLimitValue("GB1006") === "N") {
                                setNotifyDialogTitle("Note!");
                                setNotifyDialogText(`GB1006: You are not authorized to CLOSE ${entName}!`);
                                setIsNotifyDialogOpen(true);
                                return;
                              }
                              const action_text = `Are you sure you want to CLOSE <b>${entName}</b>?. Please note this action is irrevasible. A CLOSED account CANNOT be reopened. All transactions related to this entity that are in progress cannot be approved.`;
                              setObjectToAction({
                                object_id: entityId,
                                action_text: action_text,
                              });
                              setConfirmDialogAction("closeAccount");
                              setIsConfirmDialogOpen(true);
                            } catch (error) {
                              console.log(error)
                            }
                          }}
                        >Close Account</div>}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <hr />
              <div className="form-details-wrapper">
                <div className="form-details-left">
                  <div className="input-item-with-label">
                    <label htmlFor="ent_type" style={{ width: "130px" }}>
                      Type
                    </label>
                    <Select
                      id="ent_type"
                      name="ent_type"
                      styles={customSelectStyles}
                      width="250px"
                      value={entType}
                      onChange={(selectedOption) => {
                        try {
                          handleSetEntityData("ent_type", selectedOption.value);
                          setEntType(selectedOption);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                      options={entityTypeOptions}
                      isSearchable={true}
                      placeholder="Type"
                      isDisabled={documentEnabled === "N"}
                    />
                  </div>
                  <div className="input-item-with-label">
                    <label htmlFor="ent_aent_code" style={{ width: "130px" }}>
                      Category
                    </label>
                    <Select
                      id="ent_aent_code"
                      name="ent_aent_code"
                      styles={customSelectStyles}
                      width="250px"
                      value={entAentCode}
                      onChange={(selectedOption) => {
                        try {
                          handleSetEntityData("ent_aent_code", selectedOption.value);
                          setEntAentCode(selectedOption);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                      options={entityCatgOptions}
                      isSearchable={true}
                      placeholder="Category"
                      isDisabled={documentEnabled === "N"}
                    />
                  </div>
                  <div className="input-item-with-label">
                    <label htmlFor="ent_status" style={{ width: "130px" }}>
                      Status
                    </label>
                    <Select
                      id="ent_status"
                      name="ent_status"
                      styles={customSelectStyles}
                      width="250px"
                      value={entStatus}
                      onChange={(selectedOption) => {
                        try {
                          handleSetEntityData("ent_status", selectedOption?.value);
                          setEntStatus(selectedOption);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                      options={entityStatusOptions}
                      isSearchable={true}
                      placeholder="Status"
                      isDisabled={true}
                    />
                  </div>
                </div>
                <div className="form-details-center">
                  <div className="input-item-with-label">
                    <label htmlFor="ent_name" className="form-input-label" style={{ width: "130px" }}>
                      Name
                    </label>
                    <input
                      name="ent_name"
                      type="text"
                      placeholder="Name"
                      className="form-input-item"
                      style={{ width: "400px" }}
                      value={entName}
                      onChange={(e) => {
                        try {
                          handleSetEntityData("ent_name", e.target.value);
                          setEntName(e.target.value);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                      disabled={documentEnabled === "N"}
                    />
                  </div>
                  <div className="input-item-with-label">
                    <label htmlFor="ent_code" className="form-input-label" style={{ width: "130px" }}>
                      Code
                    </label>
                    <input
                      name="ent_code"
                      type="text"
                      placeholder="Code"
                      disabled={entityId !== "new"}
                      className="form-input-item"
                      style={{ width: "170px" }}
                      value={entCode}
                      onChange={(e) => {
                        try {
                          handleSetEntityData("ent_code", e.target.value);
                          setEntCode(e.target.value);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                    />
                  </div>
                  <div className="input-item-with-label">
                    <label htmlFor="ent_tax_no" className="form-input-label" style={{ width: "130px" }}>
                      Tax No
                    </label>
                    <input
                      name="ent_tax_no"
                      type="text"
                      placeholder="Tax No"
                      className="form-input-item"
                      style={{ width: "170px" }}
                      value={entTaxNo}
                      onChange={(e) => {
                        try {
                          handleSetEntityData("ent_tax_no", e.target.value);
                          setEntTaxNo(e.target.value);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                      disabled={documentEnabled === "N"}
                    />
                  </div>
                </div>
                <div className="form-details-right">
                  <div className="logo">
                    {entPhotoUrl ?
                      <img src={entPhotoUrl} alt="" className="logo" /> : <BrokenImageOutlinedIcon style={{ width: "120px", height: "120px" }} />}
                    <input
                      type="file"
                      id="file"
                      style={{ display: "none" }}
                      accept="image/*"
                      onChange={(e) => setImg(e.target.files[0])}
                    />
                    {entStatus?.value !== "Closed" &&
                      <label htmlFor="file" style={{ cursor: "pointer" }}>
                        <div className="item">
                          <span>{entPhotoUrl ? "Change Image" : "Select Image"}</span>
                        </div>
                      </label>}
                  </div>
                </div>
              </div>
              <hr />
              <h1 className="main-title">CONTACT INFORMATION</h1>
              <div className="form-details-wrapper">
                <div className="form-details-left">
                  <div className="input-item-with-label">
                    <label htmlFor="ent_phone_no" style={{ width: "130px" }}>
                      Phone No
                    </label>
                    <input
                      name="ent_phone_no"
                      type="phone"
                      placeholder="Phone No"
                      className="form-input-item"
                      style={{ width: "400px" }}
                      value={entPhoneNo}
                      onChange={(e) => {
                        try {
                          handleSetEntityData("ent_phone_no", e.target.value);
                          setEntPhoneNo(e.target.value);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                      disabled={documentEnabled === "N"}
                    />
                  </div>
                  <div className="input-item-with-label">
                    <label htmlFor="ent_physical_address" style={{ width: "130px", alignSelf: "flex-start" }}>
                      Physical Address
                    </label>
                    <textarea
                      name="ent_physical_address"
                      type="text"
                      placeholder="Physical Address"
                      className="form-input-item"
                      style={{ width: "400px" }}
                      value={entPhysicalAddress}
                      rows={3}
                      onChange={(e) => {
                        try {
                          handleSetEntityData("ent_physical_address", e.target.value);
                          setEntPhysicalAddress(e.target.value);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                      disabled={documentEnabled === "N"}
                    />
                  </div>
                </div>
                <div className="form-details-center">
                  <div className="input-item-with-label">
                    <label htmlFor="ent_email" className="form-input-label" style={{ width: "130px" }}>
                      Email
                    </label>
                    <input
                      name="ent_email"
                      type="email"
                      placeholder="Email"
                      className="form-input-item"
                      style={{ width: "400px" }}
                      value={entEmail}
                      onChange={(e) => {
                        try {
                          handleSetEntityData("ent_email", e.target.value);
                          setEntEmail(e.target.value);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                      disabled={documentEnabled === "N"}
                    />
                  </div>
                  <div className="input-item-with-label">
                    <label htmlFor="ent_postal_address" style={{ width: "130px", alignSelf: "flex-start" }}>
                      Postal Address
                    </label>
                    <textarea
                      name="ent_postal_address"
                      type="text"
                      placeholder="Postal Address"
                      className="form-input-item"
                      style={{ width: "400px" }}
                      value={entPostalAddress}
                      rows={3}
                      onChange={(e) => {
                        try {
                          handleSetEntityData("ent_postal_address", e.target.value);
                          setEntPostalAddress(e.target.value);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                      disabled={documentEnabled === "N"}
                    />
                  </div>
                </div>
              </div>
              {entityData?.ent_type === "Individual" && <>
                <hr />
                <h1 className="main-title">PERSONAL INFORMATION</h1>
                <div className="form-details-wrapper">
                  <div className="form-details-left">
                    <div className="input-item-with-label">
                      <label htmlFor="ent_surname" style={{ width: "130px" }}>
                        Surname
                      </label>
                      <input
                        name="ent_surname"
                        type="text"
                        placeholder="Surname"
                        className="form-input-item"
                        style={{ width: "300px" }}
                        value={entSurname}
                        onChange={(e) => {
                          try {
                            handleSetEntityData("ent_surname", e.target.value);
                            setEntSurname(e.target.value);
                          } catch (error) {
                            console.log(error);
                          }
                        }}
                        disabled={documentEnabled === "N"}
                      />
                    </div>
                    <div className="input-item-with-label">
                      <label
                        htmlFor="ent_other_names"
                        style={{ width: "130px" }}
                      >
                        Other Names
                      </label>
                      <input
                        name="ent_other_names"
                        type="text"
                        placeholder="Other Names"
                        className="form-input-item"
                        style={{ width: "300px" }}
                        value={entOtherNames}
                        onChange={(e) => {
                          try {
                            handleSetEntityData(
                              "ent_other_names",
                              e.target.value
                            );
                            setEntOtherNames(e.target.value);
                          } catch (error) {
                            console.log(error);
                          }
                        }}
                        disabled={documentEnabled === "N"}
                      />
                    </div>
                    <div className="input-item-with-label">
                      <label htmlFor="ent_id_no" style={{ width: "130px" }}>
                        ID No
                      </label>
                      <input
                        name="ent_id_no"
                        type="text"
                        placeholder="ID No"
                        className="form-input-item"
                        style={{ width: "300px" }}
                        value={entIdNo}
                        onChange={(e) => {
                          try {
                            handleSetEntityData("ent_id_no", e.target.value);
                            setEntIdNo(e.target.value);
                          } catch (error) {
                            console.log(error);
                          }
                        }}
                        disabled={documentEnabled === "N"}
                      />
                    </div>
                  </div>
                  <div className="form-details-center" style={{
                    justifyContent: "flex-start",
                  }}>
                    <div className="input-item-with-label">
                      <label
                        htmlFor="ent_gender"
                        className="form-dropdown-label"
                      >
                        Gender
                      </label>
                      <Select
                        id="ent_gender"
                        name="ent_gender"
                        styles={customSelectStyles}
                        width="270px"
                        value={entGender}
                        onChange={(selectedOption) => {
                          try {
                            handleSetEntityData(
                              "ent_gender",
                              selectedOption.value
                            );

                            setEntGender(selectedOption);
                          } catch (error) {
                            console.log(error);
                          }
                        }}
                        options={entityGenderOptions}
                        isSearchable={true}
                        placeholder="Gender"
                        isDisabled={documentEnabled === "N"}
                      />
                    </div>
                    <div className="input-item-with-label" >
                      <label htmlFor="ent_dob" className="form-input-label">
                        D.O.B
                      </label>
                      <input
                        name="ent_dob"
                        type="date"
                        placeholder="D.O.B"
                        className="form-input-item"
                        style={{ width: "170px", borderTopRightRadius: "0px", borderBottomRightRadius: "0px" }}
                        value={entDob}
                        onChange={(e) => {
                          try {
                            const formattedDate = moment(e.target.value).format(
                              "YYYY-MM-DD"
                            );
                            handleSetEntityData("ent_dob", formattedDate);
                            setEntDob(formattedDate);
                          } catch (error) {
                            console.log(error);
                          }
                        }}
                        disabled={documentEnabled === "N"}
                      />
                      <input
                        id="ent_age_xx"
                        name="ent_age_xx"
                        type="text"
                        placeholder="AGE"
                        value={entAgeXx}
                        className="form-input-item"
                        style={{
                          width: "100px",
                          height: "39px",
                          fontSize: "16px",
                          fontWeight: "500",
                          textAlign: "center",
                          borderTopLeftRadius: "0px",
                          borderBottomLeftRadius: "0px",
                          borderLeft: "none"
                        }}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </>}
              <hr />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <h1 className="main-title">ACCOUNT MANAGERS</h1>
                {["admin", "superadmin"].includes(currentUser.user_type_xx) && <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: "5px",
                  }}
                >
                  <div className="input-item-with-label" style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
                    <label htmlFor="admin_id">MANAGER</label>
                    <Select
                      id="admin_id"
                      name="admin_id"
                      styles={customSelectStyles}
                      width="250px"
                      value={selectedManagerToAdd}
                      onChange={(selectedOption) => {
                        try {
                          setSelectedManagerToAdd(selectedOption);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                      options={users}
                      isSearchable={true}
                      placeholder="MANAGER"
                    />
                    <Select
                      id="role_id"
                      name="role_id"
                      styles={customSelectStyles}
                      width="150px"
                      value={selectedRoleToAdd}
                      onChange={(selectedOption) => {
                        try {
                          setSelectedRoleToAdd(selectedOption);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                      options={userRoles}
                      isSearchable={true}
                      placeholder="ROLE"
                    />
                    <CustomButton
                      startIcon={<AddIcon />}
                      onClick={handleAddManager}
                    >
                      Add
                    </CustomButton>
                  </div>
                </div>}
              </div>
              {/* account managers */}
              <div className="org-admins-wrapper">
                {accountManagers.length > 0 && (
                  <>
                    {accountManagers?.map((row, index) => (
                      <div key={row._id} className="single-item">
                        <span
                          style={{
                            alignContent: "flex-start",
                            fontWeight: "bold",
                            fontSize: "20px",
                          }}
                        >
                          {index + 1}.
                        </span>
                        <div className="user">
                          {row?.manager_by_photo_url_xx ?
                            <img src={row?.manager_by_photo_url_xx} alt="" className="logo" /> : <BrokenImageOutlinedIcon style={{ width: "30px", height: "30px" }} />}
                          <div className="user-org-view">
                            <span className="user-full-name">
                              {row.manager_by_name_xx}
                            </span>
                            <span className="user-full-name">
                              {row.manager_role_xx}
                            </span>
                          </div>
                          {["admin", "superadmin"].includes(currentUser.user_type_xx) && <Tooltip
                            title={`Remove ${row.manager_by_name_xx} as ${row.manager_role_xx} from ${entName}`}
                            placement="right"
                          >
                            <IoClose
                              size={24}
                              style={{
                                borderRadius: "5px",
                                padding: "3px",
                                cursor: "pointer",
                                color: "rgba(172, 33, 9)",
                                backgroundColor: "rgba(172, 33, 9, 0.5)",
                              }}
                              onClick={() => {
                                try {
                                  const action_text = `Remove ${row.manager_by_name_xx} as ${row.manager_role_xx} from ${entName}?`;
                                  setObjectToAction({
                                    object_id: row._id,
                                    action_text: action_text,
                                  });
                                  setConfirmDialogAction("removeManager");
                                  setIsConfirmDialogOpen(true);
                                } catch (error) {
                                  console.log(error);
                                }
                              }}
                            />
                          </Tooltip>}
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
              <hr />
              <h1 className="main-title">ATTACHMENTS</h1>
              <motion.div
                className="attachment"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 1 } }}
                exit={{ opacity: 0, transition: { duration: 1 } }}>
                <FileUploadComponent documentNo={entCode} documentId={entityId} documentType="entity" fetchDocumentAttachments={() => fetchEntityFiles(entityId)} uploadFolder="entity" />
                <div className="attachment-files">
                  <AllFiles filesList={entityFilesData} bgColor={"#fff"} />
                </div>
              </motion.div>
            </div>
          </div>
          <div className="right-wrapper">
            <div className="right-content">
              <h1 className="main-title">TIMELINE</h1>
              <hr />
              <div className="timeline-root">
                {entityTraceData.map((item) => (
                  <div key={item?._id} className="timeline-item">
                    <div className="timeline-separator">
                      <div className="timeline-dot"></div>
                      <div className="timeline-connector"></div>
                    </div>
                    <div className="timeline-content">
                      <div className="timeline-navbar">
                        <div className="avatar-container">
                          {item?.updated_by_photo_url_xx ?
                            <img src={item?.updated_by_photo_url_xx} alt="" className="avatar" /> : <BrokenImageOutlinedIcon style={{ width: "30px", height: "30px" }} />}
                          <span className="username">{item?.updated_by_name_xx}</span>
                        </div>
                        <span className="time-ago">{item?.time_ago_xx}</span>
                      </div>
                      <div className="remarks">
                        {item?.trc_remarks}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* progress dialog for file upload  */}
      <UploadDialog
        open={isDialogOpen}
        handleClose={handleCloseDialog}
        uploadProgress={uploadProgress}
      />
      {/* Dialog to notify user about changes being saved */}
      <Dialog open={isNotifyDialogOpen} onClose={handleCloseNotifyDialog}>
        <DialogTitle>{notifyDialogTitle}</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: sanitizeText(notifyDialogText) }} />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseNotifyDialog}>Close</CustomButton>
        </DialogActions>
      </Dialog>
      {/* Confirm Dialog */}
      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        dialogTitle="Confirmation"
        dialogContent={<p>{objectToAction.action_text}</p>}
      />
    </motion.div>
  );
};

export default AllEntity;
