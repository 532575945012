import "./Register.scss";
import { CustomButtonGradient, CustomLoadingGradient } from "../../components/ui/CustomButton";
import { MdCheckCircle } from "react-icons/md";
import newRequest from "../../utils/newRequest";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import { validateEmail } from "../../utils/genericFunctions.js";


const Register = () => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [tacError, setTacError] = useState("");
  const [showView, setShowView] = useState("register");
  const [emailSent, setEmailSent] = useState(false);
  const [registerTitle, setRegisterTitle] = useState("Register");
  const userAgent = navigator.userAgent;
  const [tacChecked, setTacChecked] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [plans, setPlans] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const paymentOptions = [
    { value: 'monthly', label: 'Monthly', desc: "Start growing your intermediary with efficiency" },
    { value: 'quarterly', label: 'Quarterly', desc: "Select quarter payment plan to get 10% off" },
    { value: 'annually', label: 'Annually', desc: "Select annual payment plan and get 20% off" },
  ];


  const planSelected = location.state;


  const fetchPlans = async () => {
    try {
      const res = await newRequest.get(
        "/plans/findAll"
      );
      setPlans(res.data);
      // set selected default plan to monthly
      setSelectedPayment({ value: 'monthly', label: 'Monthly', desc: "Start growing your intermediary" })
      // if plan has been selected, set as default
      if (planSelected) {
        setSelectedPlan(planSelected);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  useEffect(() => {
    fetchPlans();
  }, []);

  const handleTacChange = (event) => {
    setTacChecked(event.target.checked);
  };

  const handleRegister = async (e) => {
    // e.preventDefault();
    try {

      // console.log(`selectedPlan  ${JSON.stringify(selectedPlan, null, 2)}`);
      // console.log(`selectedPayment  ${JSON.stringify(selectedPayment, null, 2)}`);

      if (!selectedPlan) {
        setError("Please select your plan");
        return;
      }

      if (selectedPlan) {
        if (!selectedPayment) {
          setError("Please select payment option");
          return;
        }
      }

      if (!email) {
        setError("Please enter email");
        return;
      }

      if (!validateEmail(email)) {
        setError("Please valid email");
        return;
      }

      if (!tacChecked) {
        setTacError("You must agree to Terms and Conditions");
        return;
      }

      if (registerTitle === "Register") {
        setRegisterTitle("Please Wait...");
        // create signup record
        const resSignup = await newRequest.post("/q/signup", {
          su_email: email,
          su_plan: selectedPlan.sub_code,
          su_payment_freq: selectedPayment?.value
        });

        // console.log(`resSignup  ${JSON.stringify(resSignup.data, null, 2)}`);

        if (resSignup.data.status === "success") {
          // send email
          const resEmail = await newRequest.post("/email/signup", {
            to: "eric.kweyu@iconsoft.co",
            subject: "Qillo Signup",
            message:
              "Welcome to Qillo!\n\nPlease click below to verify your email address and start enjoying our services:",
            signup_docid: resSignup.data.signup_id,
          });

          // console.log(`resEmail  ${JSON.stringify(resEmail.data, null, 2)}`);
          setShowView("emailSent");
        } else {
          setError(resSignup.data[0].text);
          return;
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setRegisterTitle("Register");
    }
  };


  return (
    <div className="register">
      {showView === "register" &&
        <motion.div
          className="wrapper"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1 className="title">REGISTER</h1>
            <span>
              <h1
                style={{ cursor: "pointer" }}
                onClick={() => {
                  try {
                    navigate("/qillo")
                  } catch (error) {
                    console.log(error)
                  }
                }}
              >
                Qillo
              </h1>
            </span>
          </div>
          <div className="pricing">
            <h1>Choose your plan</h1>
            <div className="pricing-cards-container">
              {plans?.map((row, index) => (
                <div
                  key={row._id}
                  className={`${row._id === selectedPlan?._id ? 'single-pricing-card active' : 'single-pricing-card'}`}
                  onClick={() => {
                    try {
                      setSelectedPlan(row);
                    } catch (error) {
                      console.log(error)
                    }
                  }}
                >
                  {row.sub_flag_show === "Y" && <div className="flag">{row.sub_flag_text}</div>}
                  <span className="plan-name">{row.sub_name}</span>
                  <div className="plan-price-wrapper">
                    {selectedPayment?.value === "monthly" && <span className="price">{row.fmt_monthly_price_show}</span>}
                    {selectedPayment?.value === "quarterly" && <span className="price">{row.fmt_quater_price_show}</span>}
                    {selectedPayment?.value === "annually" && <span className="price">{row.fmt_annual_price_show}</span>}
                    <div className="price-details">
                      <span className="decimals">.00</span>
                      <span className="freq">{row.sub_monthly_price > 0 ? `${selectedPayment?.value}` : "free"} </span>
                    </div>
                  </div>
                  {/* <div className="button-wrapper">
                    <CustomButton
                      width={"80%"}
                    >
                      Subscribe
                    </CustomButton>
                  </div> */}
                  <div className="target-wrapper">
                    <span className="target">{row.sub_target}</span>
                  </div>
                  <div className="features-wrapper">
                    {row?.sub_features?.map((row, index) => (
                      <span key={row._id} className="single-feature"><MdCheckCircle className={`${row._id === selectedPlan?._id ? 'custom-check active' : 'custom-check'}`}
                      />{row.feature_title}</span>
                    ))}
                  </div>
                  <div className="purpose-wrapper">
                    <span className="purpose">{row.sub_purpose}</span>
                  </div>
                </div>
              ))}
            </div>
            {selectedPlan?.sub_monthly_price > 0 && <h1>Choose Payment frequency</h1>}
            {selectedPlan?.sub_monthly_price > 0 &&
              <motion.div className="payment-options-wrapper" initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 1 } }}
                exit={{ opacity: 0, transition: { duration: 1 } }}>
                {paymentOptions.map((row) => (
                  <div key={row.value}
                    className={`${row.value === selectedPayment?.value ? 'payment-option active' : 'payment-option'}`}
                    onClick={() => {
                      try {
                        setSelectedPayment(row)
                      } catch (error) {
                        console.log(error)
                      }
                    }} >
                    <span className="title">{row.label}</span>
                    <span className="desc">{row.desc}</span>
                  </div>
                ))}
              </motion.div>}
          </div>
          <form>
            <div className="input-item-with-label">
              <input
                id="email"
                name="email"
                type="email"
                placeholder="YOUR EMAIL"
                value={email}
                className="form-input-item"
                style={{ width: "400px", height: "40px" }}
                onChange={(e) => {
                  try {
                    setError("");
                    setEmail(e.target.value);
                  } catch (error) {
                    console.log(error);
                  }
                }}
              />
            </div>
            {error && <div className="error" dangerouslySetInnerHTML={{ __html: error }} />}
            {/* Terms and Conditions */}
            <FormControl component="fieldset">
              {/* <FormLabel component="legend">Terms and Conditions</FormLabel> */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={tacChecked}
                      onChange={handleTacChange}
                      style={{ color: "#7B1FA2" }}
                    />
                  }
                  label={
                    <Typography>
                      By signing up, you are agreeing to{" "}
                      <a href="/tac" target="_blank" rel="noopener noreferrer">
                        Terms & Conditions
                      </a>
                      .
                    </Typography>
                  }
                />
              </FormGroup>
            </FormControl>
            {tacError && <div className="error" dangerouslySetInnerHTML={{ __html: tacError }} />}
            <CustomLoadingGradient
              height={"45px"}
              width={"350px"}
              onClick={handleRegister}
            >
              {registerTitle}
            </CustomLoadingGradient>
            {/* <Link
              onClick={() => {
                try {
                  navigate("/login")
                } catch (error) {
                  console.log(error)
                }
              }}
              style={{ marginTop: "100px" }}
            >
              LOGIN
            </Link> */}
          </form>
        </motion.div>}
      {showView === "emailSent" && (
        <motion.div
          className="wrapper"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <motion.div
            className="hero-text"
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
          >
            <h1>
              <span> Great!</span>
            </h1>
            <p dangerouslySetInnerHTML={{ __html: `We have sent an email to <strong>${email}</strong>. Please click on the verify button to finish signup process. The link will <strong>expire in 60 minutes</strong> after which you will be required to signup again.` }} />
            <CustomButtonGradient
              height={"45px"}
              width={"200px"}
              onClick={() => {
                try {
                  setEmail("");
                  setError("");
                  setTacError("");
                  setEmailSent(false);
                  navigate("/qillo")
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              CLICK HERE
            </CustomButtonGradient>
          </motion.div>
        </motion.div>
      )}
    </div>
  );
};



export default Register;
