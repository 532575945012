import "./CmClaim.scss";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { IoClose } from "react-icons/io5";
import { FaSave } from "react-icons/fa";
import {
  CustomButton,
  CustomButtonSmall,
  CustomLoadingButton,
} from "../../../components/ui/CustomButton.jsx";
import { showToast } from "../../../toastService.js";
import DescriptionIcon from "@mui/icons-material/Description";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import DoneIcon from "@mui/icons-material/Done";
import EditNoteIcon from '@mui/icons-material/EditNote';
import { MdOutlineAttachment } from "react-icons/md";
import SummarizeIcon from "@mui/icons-material/Summarize";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import InsertCommentIcon from "@mui/icons-material/InsertComment";
import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import Select from "react-select";
import { customSelectStyles } from "../../../components/ui/uiStyleElements.jsx";
import moment from "moment";
import numeral from "numeral";
import newRequest from "../../../utils/newRequest.js";
import AllFiles from "../../../components/allFiles/AllFiles.jsx";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import app from "../../../utils/firebase.js";
import ConfirmDialog from "../../../components/confirmDialog/confirmDialog.jsx";
import Confetti from "react-confetti";
import { GridLoader } from "react-spinners";
import Big from "big.js";
import { numberToWordsWithDecimal } from "../../../utils/genericFunctions.js";
import CreatableSelect from "react-select/creatable";
import AllDocsNote from "../../../components/allDocsNote/AllDocsNote.jsx";
import { ToggleSwitch } from "../../../components/toogle/ToggleSwitch.jsx";
import SubjectIcon from '@mui/icons-material/Subject';
import Badge from '@mui/material/Badge';
import EventNoteIcon from '@mui/icons-material/EventNote';
import TopicIcon from '@mui/icons-material/Topic';
import CommentIcon from '@mui/icons-material/Comment';
import FileUploadComponent from "../../../components/fileUploadComponent/FileUploadComponent.jsx";
import Correspondence from "../../../components/correspondence/Correspondence.jsx";

const CmClaim = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { currentUser } = useSelector((state) => state.user);
  const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
  const [notifyDialogTitle, setNotifyDialogTitle] = useState("Confirm");
  const [notifyDialogText, setNotifyDialogText] = useState("Text");
  const [isLoading, setIsLoading] = useState(false);
  const [injuredDialogOpen, setInjuredDialogOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const parts = location.pathname.split("/");
  const [claimData, setClaimData] = useState({});
  const [claimDocId, setClaimDocId] = useState(parts[parts.length - 1]);
  const [savingTitle, setSavingTitle] = useState("Save");
  const [documentEnabled, setDocumentEnabled] = useState("Y");
  const [nextAction, setNextAction] = useState("Validate");
  const [summaryCurrentView, setSummaryCurrentView] = useState("summary");
  const [isConfettiActive, setIsConfettiActive] = useState(false);
  const [objectToAction, setObjectToAction] = useState({
    object_id: "",
    action_text: "",
    new_doc_status: "",
    param1: "",
    param2: "",
  });
  const [invokedAction, setInvokedAction] = useState("");
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmDialogAction, setConfirmDialogAction] = useState("None");
  // number generation
  const [numGeneration, setNumGeneration] = useState({});
  let isDocumentNumberSetup = false;

  // claim fields
  const [cmOrgCode, setCmOrgCode] = useState(null);
  const [cmOurRef, setCmOurRef] = useState(null);
  const [cmClaimNo, setCmClaimNo] = useState(null);
  const [cmStatus, setCmStatus] = useState(null);
  const [cmRiskNoteNo, setCmRiskNoteNo] = useState(null);
  const [cmPolicyNo, setCmPolicyNo] = useState(null);
  const [cmRiskNoteDocid, setCmRiskNoteDocid] = useState(null);
  const [cmLossDate, setCmLossDate] = useState(new Date());
  const [cmIntimationDate, setCmIntimationDate] = useState(new Date());
  const [createdDate, setCreatedDate] = useState(new Date());
  const [cmDescription, setCmDescription] = useState(null);
  const [cmLocation, setCmLocation] = useState(null);
  const [cmArea, setCmArea] = useState(null);
  const [cmRiskCode, setCmRiskCode] = useState(null);
  const [cmRiskDocid, setCmRiskDocid] = useState(null);
  const [cmRiskTotalLoss, setCmRiskTotalLoss] = useState(null);
  const [cmInjury, setCmInjury] = useState(null);
  const [cmInjuredList, setCmInjuredList] = useState([]);
  const [cmCoverCode, setCmCoverCode] = useState(null);
  const [cmCoverDocid, setCmCoverDocid] = useState(null);
  const [cmStage, setCmStage] = useState(null);
  const [claimStages, setClaimStages] = useState([]);
  const [basisOfClaimXx, setBasisOfClaimXx] = useState([]);
  const [summaryNotesXx, setSummaryNotesXx] = useState([]);
  const [claimReserveAmountFc, setClaimReserveAmountFc] = useState(0);
  const [claimReserveAmountLc, setClaimReserveAmountLc] = useState(0);
  const [claimPaidAmountFc, setClaimPaidAmountFc] = useState(0);
  const [claimPaidAmountLc, setClaimPaidAmountLc] = useState(0);
  const [riskDescriptionXx, setRiskDescriptionXx] = useState(null);
  const [coverDescriptionXx, setCoverDescriptionXx] = useState(null);
  const [coverNameXx, setCoverNameXx] = useState(null);
  const [insuredNameXx, setInsuredNameXx] = useState(null);
  const [bizunitNameXx, setBizunitNameXx] = useState(null);
  const [carrierNameXx, setCarrierNameXx] = useState(null);
  const [productNameXx, setProductNameXx] = useState(null);
  const [currencyXx, setCurrencyXx] = useState(null);
  const [riskFmDateXx, setRiskFmDateXx] = useState(null);
  const [riskToDateXx, setRiskToDateXx] = useState(null);
  const [policyFmDateXx, setPolicyFmDateXx] = useState(null);
  const [policyToDateXx, setPolicyToDateXx] = useState(null);
  const [currentStageXx, setCurrentStageXx] = useState(null);
  const [totalFcSi, setTotalFcSi] = useState(0);
  const [totalLcSi, setTotalLcSi] = useState(0);
  const [totalFcPremium, setTotalFcPremium] = useState(0);
  const [totalLcPremium, setTotalLcPremium] = useState(0);
  const [totalPaidFcAmount, setTotalPaidFcAmount] = useState(0);
  const [totalPaidLcAmount, setTotalPaidLcAmount] = useState(0);
  const [totalOsFcAmount, setTotalOsFcAmount] = useState(0);
  const [totalOsLcAmount, setTotalOsLcAmount] = useState(0);

  // injured person
  const [injuredFullName, setInjuredFullName] = useState("");
  const [injuredIdNumber, setInjuredIdNumber] = useState("");
  const [injuredAge, setInjuredAge] = useState(0);
  const [injuredGender, setInjuredGender] = useState(null);
  const [injuredInjuryDesc, setInjuredInjuryDesc] = useState("");
  const [injuredFatal, setInjuredFatal] = useState("N");
  const [injuredAction, setInjuredAction] = useState("");

  // end of claim fields
  // claim close and reopen reasons
  const [selectedCloseReason, setSelectedCloseReason] = useState(null);
  const [closeReasons, setCloseReasons] = useState([]);
  const [closeRemarks, setCloseRemarks] = useState("");
  const [isCloseDialogOpened, setIsCloseDialogOpened] = useState(false);
  const [selectedReopenReason, setSelectedReopenReason] = useState(null);
  const [reopenRemarks, setReopenRemarks] = useState("");
  const [reopenReasons, setReopenReasons] = useState([]);
  const [isReopenDialogOpened, setIsReopenDialogOpened] = useState(false);

  // attachments
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [attachmentsData, setAttachmentsData] = useState([]);
  const [attachmentFile, setAttachmentFile] = useState(null);


  const handleInsuredPerson = async () => {
    try {
      if (!injuredFullName) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter full name!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      if (!injuredGender?.value) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please select gender!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      if (!injuredInjuryDesc) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter injury description!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      // update claim injured flag
      const res = await newRequest.put(
        `/cmClaim/upd/${claimDocId}`,
        {
          cm_injury: cmInjury,
          updatedBy: currentUser.usr_id,
        }
      );

      if (injuredAction === "add") {
        const res = await newRequest.post(
          "/cmClaim/addInjured/",
          {
            claim_docid: claimDocId,
            full_name: injuredFullName,
            age: injuredAge,
            gender: injuredGender?.value,
            injury_desc: injuredInjuryDesc,
            id_number: injuredIdNumber,
            fatal: injuredFatal,
            createdBy: currentUser.usr_id,
          }
        );
      } else {
        const res = await newRequest.post(
          "/cmClaim/updInjured/",
          {
            claim_docid: claimDocId,
            injured_person_docid: objectToAction.object_id,
            full_name: injuredFullName,
            age: injuredAge,
            gender: injuredGender?.value,
            injury_desc: injuredInjuryDesc,
            id_number: injuredIdNumber,
            fatal: injuredFatal,
            updatedBy: currentUser.usr_id,
          }
        );
      }

      // console.log(`addInjured  ${JSON.stringify(res.data, null, 2)}`);
      // refresh list
      const getInjured = await newRequest.post(
        "/cmClaim/getInjured/",
        {
          claim_docid: claimDocId,
        }
      );
      // console.log(`getInjured  ${JSON.stringify(getInjured.data, null, 2)}`);
      setCmInjuredList(getInjured.data);
      resetInjuredData();

      if (injuredAction === "amend") {
        setInjuredDialogOpen(false);
        showToast("Updated!", "success");
      } else {
        showToast("Added!", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const resetInjuredData = () => {
    try {
      setInjuredFullName("");
      setInjuredIdNumber("");
      setInjuredAge(0);
      setInjuredGender({
        value: "",
        label: "",
      });
      setInjuredInjuryDesc("");
      setInjuredFatal("N");
      setInjuredAction("");
    } catch (error) {
      console.log(error);
    }
  };
  const amendInjuredPerson = async (person) => {
    try {
      setInjuredFullName(person?.full_name);
      setInjuredIdNumber(person?.id_number);
      setInjuredAge(person?.age);
      setInjuredGender({
        value: person?.gender,
        label: person?.gender_xx,
      });
      setInjuredInjuryDesc(person?.injury_desc);
      setInjuredFatal(person?.fatal);
      setInjuredAction("amend");
      setInjuredDialogOpen(true);
    } catch (error) {
      console.log(error);
    }
  };

  const goBack = () => {
    navigate(-1);
  };


  const fetchClaim = async () => {
    try {
      setIsLoading(true);

      if (claimDocId === "new" && claimData._id) {
        setClaimDocId(claimData._id);
      }
      const res = await newRequest.post("/cmClaim/findById", {
        claim_docid: claimDocId
      });
      console.log(`setClaimData  ${JSON.stringify(res.data, null, 2)}`);
      setClaimData(res.data);

      if (res.data) {
        // set receipt information
        if (res.data.cm_our_ref) {
          setCmOurRef(res.data.cm_our_ref);
        }
        if (res.data.cm_claim_no) {
          setCmClaimNo(res.data.cm_claim_no);
        }
        if (res.data.cm_status) {
          setCmStatus(res.data.cm_status);
        }
        if (res.data.cm_risk_note_no) {
          setCmRiskNoteNo(res.data.cm_risk_note_no);
        }
        if (res.data.cm_policy_no) {
          setCmPolicyNo(res.data.cm_policy_no);
        }
        if (res.data.cm_risk_note_docid) {
          setCmRiskNoteDocid(res.data.cm_risk_note_docid);
        }

        if (res.data.cm_description) {
          setCmDescription(res.data.cm_description);
        }

        if (res.data.cm_location) {
          setCmLocation(res.data.cm_location);
        }
        if (res.data.cm_area) {
          setCmArea(res.data.cm_area);
        }
        if (res.data.cm_risk_code) {
          setCmRiskCode(res.data.cm_risk_code);
        }
        if (res.data.cm_risk_docid) {
          setCmRiskDocid(res.data.cm_risk_docid);
        }
        if (res.data.cm_risk_total_loss) {
          setCmRiskTotalLoss(res.data.cm_risk_total_loss);
        }


        if (res.data.cm_injury) {
          setCmInjury(res.data.cm_injury);
        }

        if (res.data.cm_injured_list) {
          setCmInjuredList(res.data.cm_injured_list);
        }

        if (res.data.cm_loss_date) {
          const formattedDate = moment(res.data.cm_loss_date).format(
            "YYYY-MM-DD"
          );
          setCmLossDate(formattedDate);
        }

        if (res.data.cm_intimation_date) {
          const formattedDate = moment(res.data.cm_intimation_date).format(
            "YYYY-MM-DD"
          );
          setCmIntimationDate(formattedDate);
        }

        if (res.data.createdAt) {
          const formattedDate = moment(res.data.createdAt).format(
            "DD-MMM-YYYY"
          );
          setCreatedDate(formattedDate);
        }

        if (res.data.claim_reserve_amount_fc) {
          const formattedValue = res.data.claim_reserve_amount_fc
            .toFixed(2)
            .toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          setClaimReserveAmountFc(formattedValue);
        }
        if (res.data.claim_reserve_amount_lc) {
          setClaimReserveAmountLc(res.data.claim_reserve_amount_lc);
        }

        if (res.data.insured_name_xx) {
          setInsuredNameXx(res.data.insured_name_xx);
        }

        if (res.data.bizunit_name_xx) {
          setBizunitNameXx(res.data.bizunit_name_xx);
        }
        if (res.data.product_name_xx) {
          setProductNameXx(res.data.product_name_xx);
        }

        if (res.data.carrier_name_xx) {
          setCarrierNameXx(res.data.carrier_name_xx);
        }

        if (res.data.currency_xx) {
          setCurrencyXx(res.data.currency_xx);
        }

        if (res.data.risk_fm_date_xx) {
          setRiskFmDateXx(res.data.risk_fm_date_xx);
        }

        if (res.data.risk_to_date_xx) {
          setRiskToDateXx(res.data.risk_to_date_xx);
        }

        if (res.data.policy_fm_date_xx) {
          setPolicyFmDateXx(res.data.policy_fm_date_xx);
        }

        if (res.data.policy_to_date_xx) {
          setPolicyToDateXx(res.data.policy_to_date_xx);
        }

        if (res.data.current_stage_xx) {
          setCurrentStageXx(res.data.current_stage_xx);
        }

        if (res.data.cm_stage) {
          setCmStage({
            value: res.data.cm_stage,
            label: res.data.current_stage_xx,
          });
        }

        if (res.data.total_fc_si) {
          setTotalFcSi(res.data.total_fc_si);
        }
        if (res.data.total_lc_si) {
          setTotalLcSi(res.data.total_lc_si);
        }

        if (res.data.total_fc_premium) {
          setTotalFcPremium(res.data.total_fc_premium);
        }

        if (res.data.total_lc_premium) {
          setTotalLcPremium(res.data.total_lc_premium);
        }

        if (res.data.total_paid_fc_amount) {
          setTotalPaidFcAmount(res.data.total_paid_fc_amount);
        }
        if (res.data.total_paid_lc_amount) {
          setTotalPaidLcAmount(res.data.total_paid_lc_amount);
        }

        if (res.data.total_os_fc_amount) {
          setTotalOsFcAmount(res.data.total_os_fc_amount);
        }

        if (res.data.total_os_lc_amount) {
          setTotalOsLcAmount(res.data.total_os_lc_amount);
        }

        if (res.data.basis_of_claim_xx) {
          setBasisOfClaimXx(res.data.basis_of_claim_xx);
        }

        if (res.data.summary_notes_xx) {
          setSummaryNotesXx(res.data.summary_notes_xx);
        }

        if (res.data.risk_description_xx) {
          setRiskDescriptionXx(res.data.risk_description_xx);
        }

        if (res.data.cm_cover_code) {
          setCmCoverCode(res.data.cm_cover_code);
        }

        if (res.data.cover_description_xx) {
          setCoverDescriptionXx(res.data.cover_description_xx);
        }

        if (res.data.cover_name_xx) {
          setCoverNameXx(res.data.cover_name_xx);
        }

        setDocumentEnabled(res.data.document_enabled_xx);
        setNextAction(res.data.next_action_xx);
      }

      // fetch attachements
      fetchDocumentAttachments("claim", res.data._id);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const [genders, setGenders] = useState([]);
  const fetchGenders = async () => {
    try {
      const res = await newRequest.post("/sysCode/findAll", {
        user_lang: "en_US",
        sys_org_code: currentUser.usr_org_code,
        sys_type: "Gender",
      });
      const options = res.data.map((gender) => ({
        value: gender.sys_code,
        label: gender.sys_name,
      }));

      setGenders(options);
    } catch (error) {
      console.error("Error fetching entity Gender:", error);
    }
  };

  const fetchDocumentAttachments = async (docType, docid) => {
    try {
      const res = await newRequest.post("/files/findDocFiles", {
        fl_org_code: currentUser.usr_org_code,
        fl_doc_type: docType,
        fl_parent_doc_id: docid,
      });
      setAttachmentsData(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchClaimStage = async () => {
    try {
      const res = await newRequest.post("/sysCode/findAll", {
        user_lang: "en_US",
        sys_org_code: currentUser.usr_org_code,
        sys_type: "Claim Stages",
      });
      const options = res.data.map((mode) => ({
        value: mode.sys_code,
        label: mode.sys_name,
      }));
      setClaimStages(options);
    } catch (error) {
      console.error("Error fetching receipt modes :", error);
    }
  };

  const fetchCloseReasons = async () => {
    try {
      const res = await newRequest.post("/sysCode/findAll", {
        user_lang: "en_US",
        sys_org_code: currentUser.usr_org_code,
        sys_type: "Claim Close Reasons",
      });
      const options = res.data.map((mode) => ({
        value: mode.sys_code,
        label: mode.sys_name,
      }));
      setCloseReasons(options);
    } catch (error) {
      console.error("Error fetching receipt modes :", error);
    }
  };

  const handleSelectedCloseReason = async (selectedOption) => {
    try {
      if (selectedOption && selectedOption.__isNew__) {
        const resModel = await newRequest.post("sysCode/add", {
          sys_org_code: currentUser.usr_org_code,
          sys_type: "Claim Close Reasons",
          sys_code: selectedOption.value,
          sys_name: selectedOption.value,
          userCode: currentUser.usr_id,
        });

        setSelectedCloseReason({
          value: selectedOption.value,
          label: selectedOption.value,
        });

        showToast(`${selectedOption.value} added to the list!`, "success");
      } else {
        setSelectedCloseReason(selectedOption);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectedReopenReason = async (selectedOption) => {
    try {
      if (selectedOption && selectedOption.__isNew__) {
        const resModel = await newRequest.post("sysCode/add", {
          sys_org_code: currentUser.usr_org_code,
          sys_type: "Claim Reopen Reasons",
          sys_code: selectedOption.value,
          sys_name: selectedOption.value,
          userCode: currentUser.usr_id,
        });

        setSelectedReopenReason({
          value: selectedOption.value,
          label: selectedOption.value,
        });

        showToast(`${selectedOption.value} added to the list!`, "success");
      } else {
        setSelectedReopenReason(selectedOption);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchReopenReasons = async () => {
    try {
      const res = await newRequest.post("/sysCode/findAll", {
        user_lang: "en_US",
        sys_org_code: currentUser.usr_org_code,
        sys_type: "Claim Reopen Reasons",
      });
      const options = res.data.map((mode) => ({
        value: mode.sys_code,
        label: mode.sys_name,
      }));
      setReopenReasons(options);
    } catch (error) {
      console.error("Error fetching receipt modes :", error);
    }
  };

  const handleCloseClaim = async () => {
    try {
      if (!selectedCloseReason) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please select reason for closing this claim!`);
        setIsNotifyDialogOpen(true);
        return;
      }
      if (!closeRemarks) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter remarks!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      // update claim status
      const res = await newRequest.post(
        "/cmClaim/updStatus/",
        {
          claim_docid: claimDocId,
          cm_status: objectToAction.new_doc_status,
          userCode: currentUser.usr_id,
        }
      );

      try {
        const trace = {
          trc_org_code: currentUser.usr_org_code,
          trc_action_on: "Document",
          trc_doc_type: "Claim",
          trc_doc_no: claimData.cm_our_ref,
          trc_aent_code: "",
          trc_ent_code: "",
          trc_action: selectedCloseReason.value,
          trc_remarks: `updated status to ${objectToAction.new_doc_status}. Entered remarks : ${closeRemarks}`,
          trc_tags: [objectToAction.new_doc_status, selectedCloseReason.value],
          trc_doc_docid: claimDocId,
          userCode: currentUser.usr_id,
        };
        const trace_res = await newRequest.post("/trace/add", trace);

        setSelectedCloseReason(null);
        setCloseReasons([]);
        setCloseRemarks("");
        setIsCloseDialogOpened(false);

        handleConfettiClick();
        fetchClaim();
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleReopenClaim = async () => {
    try {

      if (!selectedReopenReason) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please select reason for reopening this claim!`);
        setIsNotifyDialogOpen(true);
        return;
      }
      if (!reopenRemarks) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter remarks!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      // update claim status
      const res = await newRequest.post(
        "/cmClaim/updStatus/",
        {
          claim_docid: claimDocId,
          cm_status: objectToAction.new_doc_status,
          userCode: currentUser.usr_id,
        }
      );

      try {
        const trace = {
          trc_org_code: currentUser.usr_org_code,
          trc_action_on: "Document",
          trc_doc_type: "Claim",
          trc_doc_no: claimData.cm_our_ref,
          trc_aent_code: "",
          trc_ent_code: "",
          trc_action: selectedReopenReason.value,
          trc_remarks: `updated status to ${objectToAction.new_doc_status}. Entered remarks : ${reopenRemarks}`,
          trc_tags: [objectToAction.new_doc_status, selectedReopenReason.value],
          trc_doc_docid: claimDocId,
          userCode: currentUser.usr_id,
        };

        // console.log(`/trace/add  ${JSON.stringify(trace, null, 2)}`);

        const trace_res = await newRequest.post("/trace/add", trace);

        setSelectedReopenReason(null);
        setReopenReasons([]);
        setReopenRemarks("");
        setIsReopenDialogOpened(false);
        fetchClaim();
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfettiClick = () => {
    setIsConfettiActive(true);
    // Set a timeout to stop the confetti after a few seconds (optional)
    setTimeout(() => {
      setIsConfettiActive(false);
    }, 5000);
  };

  // close notify dialog
  const handleCloseNotifyDialog = () => {
    setIsNotifyDialogOpen(false);
  };

  const handleConfirm = async () => {
    try {
      if (confirmDialogAction === "deleteInjured") {
        setIsProcessing(true);
        // do all necessary checks

        const res = await newRequest.post(
          "/cmClaim/delInjured",
          {
            claim_docid: claimDocId,
            injured_person_docid: objectToAction.object_id,
          }
        );

        try {

          const trace_res = await newRequest.post("/trace/add", {
            trc_org_code: currentUser.usr_org_code,
            trc_action_on: "Document",
            trc_doc_type: "Claim",
            trc_doc_no: claimData.cm_our_ref,
            trc_aent_code: "",
            trc_ent_code: "",
            trc_action: "deleted injured person",
            trc_remarks: `deleted ${objectToAction.param1} from injured people list`,
            trc_tags: ["deleted injured person"],
            trc_doc_docid: claimDocId,
            userCode: currentUser.usr_id,
          });
        } catch (error) {
          console.log(error);
        }

        // refresh list
        const getInjured = await newRequest.post(
          "/cmClaim/getInjured/",
          {
            claim_docid: claimDocId,
          }
        );
        setCmInjuredList(getInjured.data);
        setIsProcessing(false);
      } else if (confirmDialogAction === "claimAction") {
        try {
          if (objectToAction.new_doc_status === "Closed") {
            fetchCloseReasons();
            setConfirmDialogOpen(false);
            setIsCloseDialogOpened(true);
          } else if (objectToAction.new_doc_status === "Opened") {
            fetchReopenReasons();
            setConfirmDialogOpen(false);
            setIsReopenDialogOpened(true);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (err) {
      console.log(err);
    }
    // Close the dialog
    setConfirmDialogOpen(false);
  };

  const handleCancel = () => {
    setConfirmDialogOpen(false);
  };

  const saveRecord = async (e) => {
    try {
      if (!cmDescription) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Please enter description!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      if (!cmLossDate) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Loss Date is Mandatory!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      if (!cmIntimationDate) {
        setNotifyDialogTitle("Note!");
        setNotifyDialogText(`Intimation date is mandatory!`);
        setIsNotifyDialogOpen(true);
        return;
      }

      // save new quote
      setSavingTitle("saving...");
      setIsLoading(true);
      // Simulate delay for 5 seconds
      // await new Promise((resolve) => setTimeout(resolve, 3000));



      const res = await newRequest.put(
        `/cmClaim/upd/${claimDocId}`,
        {
          cm_claim_no: cmClaimNo,
          cm_description: cmDescription,
          cm_stage: cmStage.value,
          cm_loss_date: cmLossDate,
          cm_intimation_date: cmIntimationDate,
          cm_injury: cmInjury,
          updatedBy: currentUser.usr_id,
        }
      );

      setNotifyDialogTitle("Success!");
      setNotifyDialogText(`Claim updated`);
      setIsNotifyDialogOpen(true);
      setSavingTitle("Save");
      setIsLoading(false);
    } catch (err) {
      setSavingTitle("Save");
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchClaim();
    fetchClaimStage();
    fetchGenders();
  }, [claimDocId]);

  // top menu 
  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const [activeTab, setActiveTab] = useState(0);

  const topMenus = [
    {
      menu_key: "details",
      menu_title: "Details",
      menu_icon: <EventNoteIcon />,
      menu_count: 0,
    },
    {
      menu_key: "attachments",
      menu_title: "Attachments",
      menu_icon: <TopicIcon />,
      menu_count: attachmentsData.length,
    },
    {
      menu_key: "correspondence",
      menu_title: "Correspondence",
      menu_icon: <SubjectIcon />,
      menu_count: 0,
    },
    {
      menu_key: "comments",
      menu_title: "Comments",
      menu_icon: <CommentIcon />,
      menu_count: 0,
    }
  ]

  return (
    <motion.div
      className="cm-claim"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      <div className="main-wrapper">
        <div className="navbar">
          <div className="navbar-left">
            <h3 className="main-title" >
              {insuredNameXx}
            </h3>
            <h1 className="sub-title" >
              {claimData?.length > 0 && `${cmPolicyNo} [${cmStatus}]`}
            </h1>
          </div>
          <div className="navbar-right">
            {documentEnabled === "Y" && (
              <CustomButton startIcon={<RefreshIcon />} onClick={fetchClaim}>
                Refresh
              </CustomButton>
            )}
            <CustomButton
              startIcon={<DragIndicatorIcon />}
              onClick={() => {
                try {
                  let action_text = "";
                  let new_doc_status = cmStatus;
                  switch (cmStatus) {
                    case "Opened":
                      action_text = `Do you want to close this claim?`;
                      setInvokedAction("Close");
                      new_doc_status = "Closed";
                      break;
                    case "Closed":
                      action_text = `Do you want to ReOpen this claim?`;
                      setInvokedAction("Reopen");
                      new_doc_status = "Opened";
                      break;
                    default:
                      action_text = "";
                      new_doc_status = "";
                  }
                  setObjectToAction({
                    object_id: claimData._id,
                    action_text: action_text,
                    new_doc_status,
                  });
                  setConfirmDialogAction("claimAction");
                  setConfirmDialogOpen(true);
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              {nextAction}
            </CustomButton>
            {documentEnabled === "Y" && (
              <CustomLoadingButton startIcon={<FaSave />} onClick={saveRecord}>
                {savingTitle}
              </CustomLoadingButton>
            )}
            {claimData?.length > 0 && (
              <CustomButton
                startIcon={<DescriptionIcon />}
                width={"250px"}
                onClick={async () => {
                  try {
                    // Log the event here
                    const trace = {
                      trc_org_code: currentUser.usr_org_code,
                      trc_action_on: "Document",
                      trc_doc_type: "claim",
                      trc_doc_no: claimData.cm_our_ref,
                      trc_aent_code: "",
                      trc_ent_code: "",
                      trc_action: "previewed claim",
                      trc_remarks: `previewed claim ${claimData.cm_our_ref}`,
                      trc_tags: ["previewed claim", claimData.cm_our_ref],
                      trc_doc_docid: claimData._id,
                      userCode: currentUser.usr_id,
                    };

                    const trace_res = await newRequest.post(
                      "/trace/add",
                      trace
                    );
                    navigate(`/rcpview/${claimData._id}`);
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                {`Preview Document`}
              </CustomButton>
            )}
            <CustomButton startIcon={<IoClose />} onClick={goBack}>
              Close
            </CustomButton>
          </div>
        </div>

        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "200px",
              width: "100%",
              height: "100%",
            }}
          >
            <GridLoader
              color="#F44336"
              style={{
                height: "50px",
                width: "50px",
              }}
            />
          </div>
        ) : (
          <div className="content-summary-wrapper">
            <div className="content-wrapper">
              <div className="tabs-container">
                <div className="tab-header">
                  {topMenus?.map((tab, index) => (
                    <div
                      key={index}
                      className={`${activeTab === index ? 'tab active' : 'tab'}`}
                      onClick={() => handleTabClick(index)}
                    >
                      <span className={`${activeTab === index ? 'tab-icon active' : 'tab-icon'}`}
                      >
                        {tab.menu_icon}
                      </span>
                      <span className={`${activeTab === index ? 'tab-title active' : 'tab-title'}`}>{tab.menu_title}</span>
                      {tab.menu_count > 0 && <Badge badgeContent={tab.menu_count} showZero sx={{ '& .MuiBadge-badge': { backgroundColor: activeTab === index ? '#9c27b0' : '#c3c3c3', color: 'white' } }}
                        className="tab-badge" />}
                    </div>
                  ))}
                </div>
              </div>
              <div className="content-details">
                {/* Summary Information */}
                {activeTab === 0 &&
                  <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "flex-start", justifyItems: "flex-start" }}>
                    <div className="content-details-row">
                      <h1 className="main-title">CLIENT</h1>
                      <span className={`status ${cmStatus}`}>
                        {cmStatus}
                      </span>
                    </div>
                    <div className="content-details-row">
                      <div className="content-details-left">
                        <div className="input-item-with-label">
                          <label
                            htmlFor="insuredNameXx"
                            className="form-dropdown-label"
                            style={{ width: "130px" }}
                          >
                            CLIENT
                          </label>
                          <input
                            id="insuredNameXx"
                            name="insuredNameXx"
                            type="text"
                            placeholder="STATUS"
                            disabled
                            defaultValue={insuredNameXx}
                            className="form-input-item"
                            style={{ width: "500px" }}
                          />
                        </div>
                        <div className="input-item-with-label">
                          <label
                            htmlFor="productNameXx"
                            className="form-dropdown-label"
                            style={{ width: "130px" }}
                          >
                            PRODUCT
                          </label>
                          <input
                            id="productNameXx"
                            name="productNameXx"
                            type="text"
                            placeholder="STATUS"
                            disabled
                            defaultValue={productNameXx}
                            className="form-input-item"
                            style={{ width: "500px" }}
                          />
                        </div>
                        <div className="two-column-row">
                          <div className="input-item-with-label">
                            <label
                              htmlFor="bizunitNameXx"
                              className="form-dropdown-label"
                              style={{ width: "130px" }}
                            >
                              BIZ UNIT
                            </label>
                            <input
                              id="bizunitNameXx"
                              name="bizunitNameXx"
                              type="text"
                              placeholder="BIZ UNIT"
                              disabled
                              defaultValue={bizunitNameXx}
                              className="form-input-item"
                              style={{ width: "170px" }}
                            />
                          </div>
                          <div className="input-item-with-label">
                            <label
                              htmlFor="currencyXx"
                              className="form-dropdown-label"
                            >
                              CURRENCY
                            </label>
                            <input
                              id="currencyXx"
                              name="currencyXx"
                              type="text"
                              placeholder="CURRENCY"
                              disabled
                              defaultValue={currencyXx}
                              className="form-input-item"
                              style={{ width: "170px" }}
                            />
                          </div>
                        </div>
                        <div className="two-column-row">
                          <div className="input-item-with-label">
                            <label
                              htmlFor="riskFmDateXx"
                              className="form-dropdown-label"
                              style={{ width: "130px" }}
                            >
                              COVER FROM
                            </label>
                            <input
                              id="riskFmDateXx"
                              name="riskFmDateXx"
                              type="text"
                              placeholder="FROM"
                              disabled
                              defaultValue={riskFmDateXx}
                              className="form-input-item"
                              style={{ width: "170px" }}
                            />
                          </div>
                          <div className="input-item-with-label">
                            <label
                              htmlFor="riskToDateXx"
                              className="form-dropdown-label"
                            >
                              COVER TO
                            </label>
                            <input
                              id="riskToDateXx"
                              name="riskToDateXx"
                              type="text"
                              placeholder="FROM"
                              disabled
                              defaultValue={riskToDateXx}
                              className="form-input-item"
                              style={{ width: "170px" }}
                            />
                          </div>
                        </div>
                        <div className="input-item-with-label">
                          <label
                            htmlFor="carrierNameXx"
                            className="form-dropdown-label"
                            style={{ width: "130px" }}
                          >
                            CARRIER
                          </label>
                          <input
                            id="carrierNameXx"
                            name="carrierNameXx"
                            type="text"
                            placeholder="CARRIER"
                            disabled
                            defaultValue={carrierNameXx}
                            className="form-input-item"
                            style={{ width: "500px" }}
                          />
                        </div>
                      </div>
                      <div className="content-details-right">
                        <div className="input-item-with-label">
                          <label
                            htmlFor="cm_claim_no"
                            className="form-dropdown-label"
                          >
                            CLAIM NO
                          </label>
                          <input
                            id="cm_claim_no"
                            name="cm_claim_no"
                            type="text"
                            placeholder="CLAIM NO"
                            className="form-input-item"
                            style={{ width: "200px" }}
                            value={cmClaimNo}
                            onChange={(e) => setCmClaimNo(e.target.value)}
                            disabled={documentEnabled === "N"}
                          />
                        </div>
                        <div className="input-item-with-label">
                          <label
                            htmlFor="cm_policy_no"
                            className="form-dropdown-label"
                          >
                            POLICY NO
                          </label>
                          <input
                            id="cm_policy_no"
                            name="cm_policy_no"
                            type="text"
                            placeholder="POLICY NO"
                            disabled
                            defaultValue={cmPolicyNo}
                            className="form-input-item"
                            style={{ width: "200px" }}
                          />
                        </div>
                        <div className="input-item-with-label">
                          <label
                            htmlFor="cm_risk_note_no"
                            className="form-dropdown-label"
                          >
                            RISK NOTE
                          </label>
                          <input
                            id="cm_risk_note_no"
                            name="cm_risk_note_no"
                            type="text"
                            placeholder="RISK NOTE"
                            disabled
                            defaultValue={cmRiskNoteNo}
                            className="form-input-item"
                            style={{ width: "200px" }}
                          />
                        </div>
                        <div className="input-item-with-label">
                          <label
                            htmlFor="cm_our_ref"
                            className="form-dropdown-label"
                          >
                            OUR REF
                          </label>
                          <input
                            id="cm_our_ref"
                            name="cm_our_ref"
                            type="text"
                            placeholder="OUR REF"
                            defaultValue={cmOurRef}
                            className="form-input-item"
                            style={{ width: "200px" }}
                            disabled
                          />
                        </div>
                        <div className="input-item-with-label">
                          <label
                            htmlFor="current_stage_xx"
                            className="form-dropdown-label"
                          >
                            STAGE
                          </label>
                          <Select
                            id="current_stage_xx"
                            name="current_stage_xx"
                            styles={customSelectStyles}
                            width="200px"
                            value={cmStage}
                            onChange={(selectedOption) => {
                              try {
                                setCmStage(selectedOption);
                              } catch (error) {
                                console.log(error);
                              }
                            }}
                            options={claimStages}
                            isSearchable={true}
                            placeholder="STAGE"
                            isDisabled={documentEnabled === "N"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="input-item-with-label" style={{ width: "100%" }}>
                      <label
                        htmlFor="cm_description"
                        className="form-dropdown-label"
                        style={{ width: "145px", alignSelf: "flex-start" }}
                      >
                        NARRATION
                      </label>
                      <textarea
                        id="cm_description"
                        name="cm_description"
                        type="text"
                        placeholder="NARRATION"
                        value={cmDescription}
                        row={10}
                        className="form-input-item"
                        style={{ width: "100%", height: "80px" }}
                        onChange={(e) => setCmDescription(e.target.value)}
                        disabled={documentEnabled === "N"}
                      />
                    </div>
                    <hr style={{ width: "100%", margin: "10px 0px" }} />
                    <div className="basis-of-claim">
                      <h1 className="main-title">{`BASIS OF CLAIM SETTLEMENT ${basisOfClaimXx?.length === 0 ? "NOT SPECIFIED" : ""}`}</h1>
                      {/* basis of claim text */}
                      {basisOfClaimXx?.length > 0 && (
                        <div>
                          {basisOfClaimXx?.map((claim, index) => (
                            <div key={claim._id}>
                              <div className="single-basis-row">
                                <span
                                  style={{
                                    alignContent: "flex-start",
                                  }}
                                >
                                  {index + 1}.
                                </span>
                                <span
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "16px",
                                  }}
                                >{`${claim.basis_text}`}</span>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <hr style={{ width: "100%", margin: "10px 0px" }} />
                    <div className="basis-of-claim">
                      <h1 className="main-title">IMPORTANT DATES</h1>
                      <div className="two-column-row">
                        <div className="input-item-with-label">
                          <label
                            htmlFor="cm_loss_date"
                            className="form-dropdown-label"
                          >
                            LOSS DATE
                          </label>
                          <input
                            id="cm_loss_date"
                            name="cm_loss_date"
                            type="date"
                            placeholder="FROM"
                            disabled={documentEnabled === "N"}
                            value={cmLossDate}
                            className="form-input-item"
                            style={{ width: "170px" }}
                            onChange={(e) => setCmLossDate(e.target.value)}
                          />
                        </div>
                        <div className="input-item-with-label">
                          <label
                            htmlFor="riskToDateXx"
                            className="form-dropdown-label"
                          >
                            INTIMATION
                          </label>
                          <input
                            id="cm_intimation_date"
                            name="cm_intimation_date"
                            type="date"
                            placeholder="FROM"
                            disabled={documentEnabled === "N"}
                            value={cmIntimationDate}
                            className="form-input-item"
                            style={{ width: "170px" }}
                            onChange={(e) => setCmIntimationDate(e.target.value)}
                          />
                        </div>

                        <div className="input-item-with-label">
                          <label
                            htmlFor="createdDate"
                            className="form-dropdown-label"
                          >
                            CREATED
                          </label>
                          <input
                            id="createdDate"
                            name="createdDate"
                            type="text"
                            placeholder="FROM"
                            disabled
                            defaultValue={createdDate}
                            className="form-input-item"
                            style={{ width: "200px" }}
                          />
                        </div>
                      </div>
                    </div>
                    <hr style={{ width: "100%", margin: "10px 0px" }} />
                    <span className="main-title">PARTICULARS</span>
                    <div className="two-column-row" style={{ margin: "10px 0px" }}>
                      <div className="single-cover">
                        <div className="single-cover-title">
                          <span>{cmRiskCode}</span>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <span>{`Total Loss ${cmRiskTotalLoss}`}</span>
                          </span>
                        </div>
                        <div className="single-cover-desc">
                          {riskDescriptionXx}
                        </div>
                      </div>
                    </div>
                    <div className="two-column-row" style={{ margin: "5px 0px" }}>
                      <div className="single-cover">
                        <div className="single-cover-title">
                          <span>{coverNameXx}</span>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <span>{cmCoverCode}</span>
                          </span>
                        </div>
                        <div className="single-cover-desc">
                          {coverDescriptionXx}
                        </div>
                      </div>
                    </div>
                    <hr style={{ width: "100%", margin: "10px 0px" }} />
                    <div className="injuries">
                      <div className="injuries-bar">
                        <span className="main-title">INJURIES</span>
                        <ToggleSwitch
                          id="cmInjury"
                          value={cmInjury}
                          onChange={setCmInjury}
                          label={""}
                          labelPosition={"end"}
                          labelWidth={"max-content"}
                          disabled={documentEnabled === "N"}
                          style={{
                            width: "100px",
                          }}
                        />
                        {cmInjury === "Y" && documentEnabled === "Y" && (
                          <CustomButton
                            height="32px"
                            startIcon={<AddIcon />}
                            onClick={() => {
                              try {
                                setInjuredAction("add");
                                setInjuredDialogOpen(!injuredDialogOpen);
                              } catch (error) {
                                console.log(error);
                              }
                            }}
                          >
                            Add Injured Person
                          </CustomButton>
                        )}
                      </div>
                      <div className="injured-wrapper">
                        {cmInjuredList?.map((person) => (
                          <div key={person._id}>
                            <div
                              className="single-person"
                              onClick={() => {
                                try {
                                  setObjectToAction({
                                    object_id: person._id,
                                    action_text: "",
                                    new_doc_status: "amendInjured",
                                    param1: person?.full_name,
                                  });
                                  amendInjuredPerson(person);
                                } catch (error) {
                                  console.log(error);
                                }
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  width: "100%",
                                  marginBottom: "10px",
                                }}
                              >
                                <span className="single-person-name">
                                  {person?.full_name}
                                </span>
                                {documentEnabled === "Y" && (
                                  <Tooltip
                                    title={`Delete ${person?.full_name}`}
                                    placement="right"
                                    style={{
                                      backgroundColor: "blue",
                                      color: "white",
                                    }}
                                  >
                                    <DeleteIcon
                                      size={50}
                                      style={{
                                        borderRadius: "5px",
                                        padding: "3px",
                                        alignSelf: "center",
                                        cursor: "pointer",
                                        color: "rgba(244, 67, 54)",
                                        backgroundColor: "rgba(244, 67, 54, 0.5)",
                                      }}
                                      onClick={(e) => {
                                        try {
                                          e.stopPropagation();
                                          let action_text = `Delete ${person?.full_name}?`;
                                          setObjectToAction({
                                            object_id: person._id,
                                            action_text: action_text,
                                            new_doc_status: "deleteInjured",
                                            param1: person?.full_name,
                                            param2: "",
                                          });

                                          setConfirmDialogAction("deleteInjured");
                                          setConfirmDialogOpen(true);
                                        } catch (error) {
                                          console.log(error);
                                        }
                                      }}
                                    />
                                  </Tooltip>
                                )}
                              </div>
                              <span className="single-person-desc">
                                {person?.injured_desc_xx}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                }
                {/* Attachments */}
                {activeTab === 1 && <>
                  <motion.div
                    className="tabs-view-wrapper"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 1 } }}
                    exit={{ opacity: 0, transition: { duration: 1 } }}>
                    <div className="attachment">
                      <FileUploadComponent documentNo={cmClaimNo} documentId={claimDocId} documentType="claim" fetchDocumentAttachments={fetchDocumentAttachments} uploadFolder="claim" />
                      <div className="attachment-files">
                        <AllFiles filesList={attachmentsData} bgColor={"#fff"} />
                      </div>
                    </div>
                  </motion.div>
                </>}
                {/* correspondence */}
                {activeTab === 2 &&
                  <motion.div
                    className="tabs-view-wrapper"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 1 } }}
                    exit={{ opacity: 0, transition: { duration: 1 } }}
                  >
                    <div className="correspondence">
                      <Correspondence
                        correspondenceParams={{ fetch_type: "document", doc_id: claimData._id, doc_no: cmOurRef, doc_type: "Claim", entity_codes: [claimData?.insured_code_xx, claimData?.carrier_code_xx] }}
                        passedEmailData={{
                          to: claimData?.insured_email_xx,
                          cc: `${claimData?.carrier_email_xx},${currentUser?.usr_email}`,
                          bcc: '',
                          subject: ``,
                          show_attach: "Y",
                          attached_urls: [],
                          binary_docs: [],
                          doc_no: cmOurRef,
                          doc_type: "Claim",
                          doc_id: claimDocId,
                          entity_codes: [claimData?.insured_code_xx, claimData?.carrier_code_xx],
                          search_string: claimData?.insured_name_xx + "," + claimData?.carrier_name_xx + "," + cmOurRef + "," + cmClaimNo,
                          carrierName: claimData?.carrier_name_xx,
                          productName: claimData?.product_name_xx,
                          clientName: claimData?.insured_name_xx,
                          riskNoteNo: cmRiskNoteNo,
                          daysToRenewal: claimData?.expiring_in_days,
                          expiryDate: claimData?.fmt_to_date,
                          insuredName: claimData?.insured_name_xx,
                          outstandingPremium: claimData?.total_os_fc_amount,
                          policyCurrency: claimData?.currency_xx,
                          policyNumber: cmPolicyNo,
                          policyPremium: claimData?.fmt_policy_fc_prem,
                          policyPremiumTotal: claimData?.fmt_total_fc_prem,
                          policySumInsured: claimData?.fmt_total_fc_si,
                          policyFromDate: claimData?.fmt_fm_date,
                          policyToDate: claimData?.fmt_to_date,
                          claimCurrentStage: cmStage?.label,
                          claimDesc: cmDescription,
                          claimIntimationDate: claimData?.fmt_intimation_date,
                          claimLossDate: claimData?.fmt_loss_date,
                          claimNo: cmClaimNo,
                          claimOurRef: cmOurRef,
                          tpt_category: ["claimDocuments", "claimAcknowledgment"]
                        }} />
                    </div>
                  </motion.div>
                }
                {/* Notes */}
                {activeTab === 3 &&
                  <motion.div
                    className="tabs-view-wrapper"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 1 } }}
                    exit={{ opacity: 0, transition: { duration: 1 } }}
                  >
                    <AllDocsNote
                      docId={claimData._id}
                      docType="Claim"
                      noteType={"Normal"}
                    />
                  </motion.div>
                }
              </div>
            </div>
            <div className="summary-wrapper">
              <div className="summary-content">
                <hr />
                <div className="title-row">
                  <h1 className="main-title">SUMMARY</h1>
                  <h1 className="main-title" style={{ color: "#f44336" }}>{currencyXx}</h1>
                </div>
                <hr />
                <div className="content-row">
                  <span>SUM INSURED</span>
                  <span className="amounts">
                    {numeral(totalFcSi).format("0,0.00")}
                  </span>
                </div>
                <div className="content-row">
                  <span>BILLED PREMIUM</span>
                  <span className="amounts">
                    {numeral(totalFcPremium).format("0,0.00")}
                  </span>
                </div>

                <div className="content-row">
                  <span>PAID PREMIUM</span>
                  <span className="amounts">
                    {numeral(totalPaidFcAmount).format("0,0.00")}
                  </span>
                </div>

                <div className="content-row">
                  <div
                    className={`os-premium ${totalOsFcAmount > 0 ? "os" : "cleared"
                      }`}
                  >
                    <span>OS PREMIUM</span>
                    <span>
                      {numeral(totalOsFcAmount).format("0,0.00")}
                    </span>
                  </div>
                </div>

                <hr />
                <div className="content-row">
                  <h1 className="section-sub-title">
                    CLAIM AMOUNT
                  </h1>
                  <span span className="receipt-amount">
                    {numeral(claimReserveAmountFc).format("0,0.00")}
                  </span>
                </div>
                <div className="content-row">
                  <h1 className="section-sub-title">PAID AMOUNT</h1>
                  <span span className="receipt-amount">
                    {numeral(claimPaidAmountFc).format("0,0.00")}
                  </span>
                </div>
                <hr />
                <div className="title-row">
                  <h1 className="section-sub-title">PLEASE NOTE</h1>
                </div>

                <div className="notes-row">
                  {summaryNotesXx?.length > 0 && (
                    <div>
                      {summaryNotesXx?.map((claim, index) => (
                        <div key={claim._id}>
                          <div className="single-note-row">
                            <h1
                              className="sr-no"
                            >
                              {index + 1}.
                            </h1>
                            <h1 className="summary_text"
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: claim.summary_text,
                                }}
                              />
                            </h1>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <hr />
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Dialog to notify user about changes being saved */}
      <Dialog open={isNotifyDialogOpen} onClose={handleCloseNotifyDialog}>
        <DialogTitle>{notifyDialogTitle}</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: notifyDialogText }} />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseNotifyDialog}>Close</CustomButton>
        </DialogActions>
      </Dialog>
      {/* Confirm Dialog */}
      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        dialogTitle="Confirmation"
        dialogContent={<p>{objectToAction.action_text}</p>}
      />
      {isProcessing && (
        <motion.div
          className="processing-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="processing-wrapper">
            <GridLoader
              color="#F44336"
              style={{
                height: "50px",
                width: "50px",
              }}
            />
          </div>
        </motion.div>
      )}
      {isConfettiActive && <Confetti />}

      {/* add injured person */}
      {injuredDialogOpen && (
        <motion.div
          className="injured-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="injured-wrapper">
            <div className="injured-navbar">
              <span>INJURED PERSON</span>
              <span
                onClick={() => {
                  try {
                    resetInjuredData();
                    setInjuredDialogOpen(false);
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                <IoClose
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    borderRadius: "50%",
                  }}
                />
              </span>
            </div>
            <div className="injured-content">
              <div className="input-item-with-label">
                <label
                  htmlFor="injuredFullName"
                  className="form-dropdown-label"
                >
                  FULL NAME
                </label>
                <input
                  id="injuredFullName"
                  name="injuredFullName"
                  type="text"
                  value={injuredFullName}
                  className="form-input-item"
                  style={{ width: "275px" }}
                  disabled={documentEnabled === "N"}
                  onChange={(e) => setInjuredFullName(e.target.value)}
                />
              </div>

              <div className="input-item-with-label">
                <label
                  htmlFor="injuredIdNumber"
                  className="form-dropdown-label"
                >
                  ID Number
                </label>
                <input
                  id="injuredIdNumber"
                  name="injuredIdNumber"
                  type="text"
                  value={injuredIdNumber}
                  className="form-input-item"
                  style={{ width: "275px" }}
                  disabled={documentEnabled === "N"}
                  onChange={(e) => setInjuredIdNumber(e.target.value)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div className="input-item-with-label">
                  <label htmlFor="injuredAge" className="form-dropdown-label">
                    Age
                  </label>
                  <input
                    id="injuredAge"
                    name="injuredAge"
                    type="number"
                    value={injuredAge}
                    className="form-input-item"
                    style={{ width: "70px" }}
                    disabled={documentEnabled === "N"}
                    onChange={(e) => setInjuredAge(e.target.value)}
                  />
                </div>

                <div className="input-item-with-label">
                  <label
                    htmlFor="injuredGender"
                    className="form-dropdown-label"
                    style={{ width: "80px" }}
                  >
                    GENDER
                  </label>
                  <Select
                    id="injuredGender"
                    name="injuredGender"
                    styles={customSelectStyles}
                    width="120px"
                    value={injuredGender}
                    onChange={(selectedOption) => {
                      try {
                        setInjuredGender(selectedOption);
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                    options={genders}
                    isSearchable={true}
                    placeholder="Select"
                    isDisabled={documentEnabled === "N"}
                  />
                </div>
              </div>

              <div className="input-item-with-label">
                <label
                  htmlFor="injuredInjuryDesc"
                  className="form-dropdown-label"
                  style={{ alignSelf: "flex-start" }}
                >
                  DESCRIPTION
                </label>
                <textarea
                  id="injuredInjuryDesc"
                  name="injuredInjuryDesc"
                  type="text"
                  rows={5}
                  value={injuredInjuryDesc}
                  className="form-input-item"
                  style={{ width: "275px" }}
                  disabled={documentEnabled === "N"}
                  onChange={(e) => setInjuredInjuryDesc(e.target.value)}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <ToggleSwitch
                  id="injuredFatal"
                  value={injuredFatal}
                  onChange={setInjuredFatal}
                  label={"FATAL"}
                  labelPosition={"start"}
                  labelWidth={"max-content"}
                  style={{
                    width: "100px",
                    alignSelf: "flex-end !important",
                  }}
                  disabled={documentEnabled === "N"}
                />
              </div>
            </div>
            <div className="injured-action">
              {documentEnabled === "Y" && (
                <CustomButton onClick={handleInsuredPerson}>{`${injuredAction === "amend" ? "Update" : "Add"
                  }`}</CustomButton>
              )}
            </div>
          </div>
        </motion.div>
      )}
      {/* claim close reasons */}
      {isCloseDialogOpened && (
        <motion.div
          className="injured-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="injured-wrapper">
            <div className="injured-navbar">
              <span>CLOSE CLAIM</span>
              <span
                onClick={() => {
                  try {
                    setIsCloseDialogOpened(false);
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                <IoClose
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    borderRadius: "50%",
                  }}
                />
              </span>
            </div>
            <div className="injured-content">
              <div className="input-item-with-label">
                <label
                  htmlFor="close_reason"
                  className="form-dropdown-label"
                  style={{ width: "130px" }}
                >
                  REASON
                </label>
                <CreatableSelect
                  id="close_reason"
                  name="close_reason"
                  styles={customSelectStyles}
                  width="350px"
                  value={selectedCloseReason}
                  onChange={handleSelectedCloseReason}
                  options={closeReasons}
                  isSearchable={true}
                  placeholder="Select Reason"
                />
              </div>
              <div className="input-item-with-label">
                <label
                  htmlFor="closeRemarks"
                  className="form-dropdown-label"
                  style={{ width: "130px", alignSelf: "flex-start" }}
                >
                  REMARKS
                </label>
                <textarea
                  id="closeRemarks"
                  name="closeRemarks"
                  type="text"
                  rows={5}
                  value={closeRemarks}
                  className="form-input-item"
                  style={{ width: "350px" }}
                  disabled={documentEnabled === "N"}
                  onChange={(e) => setCloseRemarks(e.target.value)}
                />
              </div>
            </div>
            <div className="injured-action">
              <CustomButton onClick={handleCloseClaim}>Close</CustomButton>
            </div>
          </div>
        </motion.div>
      )}
      {/* claim reopen reasons */}
      {isReopenDialogOpened && (
        <motion.div
          className="injured-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="injured-wrapper">
            <div className="injured-navbar">
              <span>REOPEN CLAIM</span>
              <span
                onClick={() => {
                  try {
                    setIsReopenDialogOpened(false);
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                <IoClose
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    borderRadius: "50%",
                  }}
                />
              </span>
            </div>
            <div className="injured-content">
              <div className="input-item-with-label">
                <label
                  htmlFor="reopen_reason"
                  className="form-dropdown-label"
                  style={{ width: "130px" }}
                >
                  REASON
                </label>
                <CreatableSelect
                  id="reopen_reason"
                  name="reopen_reason"
                  styles={customSelectStyles}
                  width="350px"
                  value={selectedReopenReason}
                  onChange={handleSelectedReopenReason}
                  options={reopenReasons}
                  isSearchable={true}
                  placeholder="Select Reason"
                />
              </div>

              <div className="input-item-with-label">
                <label
                  htmlFor="reopenRemarks"
                  className="form-dropdown-label"
                  style={{ width: "130px", alignSelf: "flex-start" }}
                >
                  REMARKS
                </label>
                <textarea
                  id="reopenRemarks"
                  name="reopenRemarks"
                  type="text"
                  rows={5}
                  value={reopenRemarks}
                  className="form-input-item"
                  style={{ width: "350px" }}
                  onChange={(e) => setReopenRemarks(e.target.value)}
                />
              </div>
            </div>
            <div className="injured-action">
              <CustomButton onClick={handleReopenClaim}>Reopen</CustomButton>
            </div>
          </div>
        </motion.div>
      )}

    </motion.div>
  );
};

const UploadDialog = ({ open, handleClose, uploadProgress }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Progress</DialogTitle>
      <DialogContent>
        <LinearProgress variant="determinate" value={uploadProgress} />
        {uploadProgress && <p>{uploadProgress}% Complete</p>}
      </DialogContent>
    </Dialog>
  );
};

export default CmClaim;
