import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import LinearProgress from "@mui/material/LinearProgress";

function ProgressDialog({ open, handleClose, progress, title }) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <LinearProgress variant="determinate" value={progress} />
      </DialogContent>
    </Dialog>
  );
}

export default ProgressDialog;
