import { CustomButton } from "../ui/CustomButton";
import "./AllDocsNote.scss";
import { useSelector } from "react-redux";
import SendIcon from "@mui/icons-material/Send";
import newRequest from "../../utils/newRequest";
import { useEffect, useState } from "react";

const AllDocsNote = ({ docId, docType, noteType }) => {
  const { currentUser } = useSelector((state) => state.user);
  const [currentNote, setCurrentNote] = useState("");
  const [notes, setNotes] = useState([]);
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const fetchNotes = async () => {
    try {
      const res = await newRequest.post("/allDocsNote/findAll", {
        note_org_code: currentUser.usr_org_code,
        note_docid: docId,
        note_type: noteType
      });
      setNotes(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchNotes();
  }, [docId]);

  const addNote = async (e) => {
    // e.preventDefault();
    try {
      if (currentNote !== null && currentNote !== "") {
        const params = {
          note_org_code: currentUser.usr_org_code,
          note_doc_type: docType,
          note_docid: docId,
          note_text: currentNote,
          note_tags: [currentUser.usr_id],
          note_type: noteType,
          userCode: currentUser.usr_id,
        };
        const res = await newRequest.post("/allDocsNote/add", params, config);
        setCurrentNote("");
        fetchNotes();
      } else {
        console.log("currentNote is null or empty");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleInputChange = (event) => {
    setCurrentNote(event.target.value);
  };

  return (
    <div className="all-docs-note">
      <div className="write">
        {noteType === "Special" && (
          <img src={currentUser?.usr_photo_url || "/img/noavatar.jpg"} alt="" />
        )}
        <input
          type="text"
          value={currentNote}
          placeholder={`Add ${noteType} Note...`}
          onChange={handleInputChange}
        />
        <CustomButton startIcon={<SendIcon />} onClick={addNote}>
          Add
        </CustomButton>
      </div>
      {notes.map((note) => (
        <div key={note._id} className="comment">
          <img src={note.created_by_photo_url_xx} alt="" />
          <div className="info">
            <span>{note.created_by_name_xx}</span>
            <p>{note.note_text}</p>
          </div>
          <span className="date">{note.time_ago_xx}</span>
        </div>
      ))}
    </div>
  );
};

export default AllDocsNote;
