import "./CmDb.scss";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { motion } from "framer-motion";
import UwBoxOne from "../../../components/uwBoxOne/UwBoxOne";
import UwChartBox from "../../../components/uwChartBox/UwChartBox";
import UwPieChartBox from "../../../components/uwPieChartBox/UwPieChartBox";
import UwBarChartBox from "../../../components/uwBarChartBox/UwBarChartBox";
import UwBigChartBox from "../../../components/uwBigChartBox/UwBigChartBox";

import {
  barChartBoxRevenue,
  barChartBoxVisit,
  chartBoxConversion,
  chartBoxProduct,
  chartBoxRevenue,
  chartBoxUser,
} from "../../../data";

const CmDb = () => {
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <motion.div
      className="cm-db"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      <div className="content">
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "200px",
            }}
          >
            <CircularProgress
              style={{
                height: "50px",
                width: "50px",
              }}
            />
          </div>
        ) : (
          <div className="content-wrapper">
            <div className="box box1">
              <UwBoxOne />
            </div>
            <div className="box box2">
              <UwChartBox {...chartBoxUser} />
            </div>
            <div className="box box3">
              <UwChartBox {...chartBoxProduct} />
            </div>
            <div className="box box4">
              <UwPieChartBox />
            </div>
            <div className="box box5">
              <UwChartBox {...chartBoxConversion} />
            </div>
            <div className="box box6">
              <UwChartBox {...chartBoxRevenue} />
            </div>
            <div className="box box7">
              <UwBigChartBox />
            </div>
            <div className="box box8">
              <UwBarChartBox {...barChartBoxVisit} />
            </div>
            <div className="box box9">
              <UwBarChartBox {...barChartBoxRevenue} />
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default CmDb;
