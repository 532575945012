import "./UwProduct.scss";
import AllFiles from "../../../components/allFiles/AllFiles.jsx";
import ConfirmDialog from "../../../components/confirmDialog/confirmDialog.jsx";
import { ToggleSwitch } from "../../../components/toogle/ToggleSwitch.jsx";
import {
  CustomButton,
  CustomLoadingButton,
} from "../../../components/ui/CustomButton.jsx";
import { customSelectStyles } from "../../../components/ui/uiStyleElements.jsx";
import { showToast } from "../../../toastService.js";
import app from "../../../utils/firebase.js";
import { numberToWordsWithDecimal } from "../../../utils/genericFunctions.js";
import newRequest from "../../../utils/newRequest.js";
import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import DoneIcon from "@mui/icons-material/Done";
import InsertCommentIcon from "@mui/icons-material/InsertComment";
import RefreshIcon from "@mui/icons-material/Refresh";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { Tabs, Tab, Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Big from "big.js";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { motion } from "framer-motion";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { FaSave } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { MdOutlineAttachment } from "react-icons/md";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { GridLoader } from "react-spinners";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const UwProduct = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { currentUser } = useSelector((state) => state.user);
  const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [notifyDialogTitle, setNotifyDialogTitle] = useState("Confirm");
  const [notifyDialogText, setNotifyDialogText] = useState("Text");
  const [figuresToShow, setFiguresToShow] = useState("org");
  const navigate = useNavigate();
  const location = useLocation();
  const parts = location.pathname.split("/");
  const [productData, setProductData] = useState({});
  const [productDocId, setProductDocId] = useState(parts[parts.length - 1]);
  const [savingTitle, setSavingTitle] = useState("Save");
  const [nextAction, setNextAction] = useState("Validate");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // product fields
  const [prRisknoteSections, setPrRisknoteSections] = useState([]);
  const [prCode, setPrCode] = useState("");
  const [prName, setPrName] = useState("");
  const [prPeriodType, setPrPeriodType] = useState("Normal");
  const [prCashCarry, setPrCashCarry] = useState("Y");
  const [prOnetime, setPrOnetime] = useState("N");
  const [prEnabled, setPrEnabled] = useState("Y");
  const [prMotor, setPrMotor] = useState("N");
  // end of product fields
  const [objectToAction, setObjectToAction] = useState({
    object_id: "",
    action_text: "",
    new_doc_status: "",
  });
  const [invokedAction, setInvokedAction] = useState("");
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmDialogAction, setConfirmDialogAction] = useState("None");
  const handleCancel = () => {
    setConfirmDialogOpen(false);
  };
  // tab controls
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const tabStyles = {
    "& .MuiTabs-flexContainer": {
      padding: 0,
    },
  };

  const getTabStyles = (index) => ({
    fontWeight: tabValue === index ? "bold" : "normal",
    color: tabValue === index ? "#7B1FA2" : "black",
    fontSize: "14px",
    "&.MuiSelected": {
      color: "#7B1FA2",
      borderBottom: `1px solid #7B1FA2`,
      width: "100%",
    },
  });

  // product excess
  const [selectedProductExcess, setSelectedProductExcess] = useState({});
  const [productExcess, setProductExcess] = useState([]);
  const fetchProductExcess = async (pr_code) => {
    try {
      const res = await newRequest.post(
        "/uwProductExcess/findAll",
        {
          ed_org_code: currentUser.usr_org_code,
          ed_pr_code: pr_code,
        },
        config
      );
      setProductExcess(res.data);

      if (res.data) {
        setSelectedProductExcess(res.data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleInputExcessChange = (e, index) => {
    const { name, value, type } = e.target;
    const updatedData = [...productExcess];
    const currentRow = updatedData[index];

    // Update the current row based on the input's name
    switch (type) {
      case "checkbox":
        currentRow[name] = e.target.checked ? "Y" : "N"; // Convert checkbox value to 'Y' or 'N'
        break;
      default:
        currentRow[name] = value;
        break;
    }

    currentRow.changed_xx = "Y";

    setProductExcess(updatedData);
  };

  // product wordings
  const [selectedProductWordings, setSelectedProductWordings] = useState({});
  const [productWordings, setProductWordings] = useState([]);
  const fetchProductWordings = async (pr_code) => {
    try {
      const res = await newRequest.post(
        "/uwProductCond/findAll",
        {
          cond_org_code: currentUser.usr_org_code,
          cond_pr_code: pr_code,
        },
        config
      );
      setProductWordings(res.data);

      if (res.data) {
        setSelectedProductWordings(res.data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleInputWordingsChange = (e, index) => {
    const { name, value, type } = e.target;
    const updatedData = [...productWordings];
    const currentRow = updatedData[index];

    // Update the current row based on the input's name
    switch (type) {
      case "checkbox":
        currentRow[name] = e.target.checked ? "Y" : "N"; // Convert checkbox value to 'Y' or 'N'
        break;
      default:
        currentRow[name] = value;
        break;
    }

    currentRow.changed_xx = "Y";

    setProductWordings(updatedData);
  };

  // end of product excess

  // product endorsements
  const [selectedProductEnd, setSelectedProductEnd] = useState({});
  const [productEndorsements, setProductEndorsements] = useState([]);
  const fetchProductEndorsements = async (pr_code) => {
    try {
      const res = await newRequest.post(
        "/uwProductEnd/findAll",
        {
          pe_org_code: currentUser.usr_org_code,
          pe_pr_code: pr_code,
        },
        config
      );
      setProductEndorsements(res.data);

      if (res.data) {
        setSelectedProductEnd(res.data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleInputEndChange = (e, index) => {
    const { name, value, type } = e.target;
    const updatedData = [...productEndorsements];
    const currentRow = updatedData[index];

    // Update the current row based on the input's name
    switch (type) {
      case "checkbox":
        currentRow[name] = e.target.checked ? "Y" : "N";
        break;
      default:
        currentRow[name] = value;
        break;
    }

    currentRow.changed_xx = "Y";

    setProductEndorsements(updatedData);
  };
  const endorsementColumns = [
    {
      header: "Endorsement",
      key: "pe_name",
      type: "text",
      align: "flex-start",
      width: "auto",
    },
    {
      header: "Code",
      key: "pe_end_code",
      type: "text",
      align: "flex-start",
      width: "250px",
    },
    {
      header: "Description",
      key: "pe_desc",
      type: "text",
      align: "flex-start",
      width: "auto",
    },
    {
      header: "Internal",
      key: "pe_internal_code",
      type: "text",
      align: "flex-start",
      width: "250px",
    },
    {
      header: "Enabled",
      key: "pe_enabled",
      type: "text",
      align: "flex-start",
      width: "250px",
    },
  ];

  const [selectedEndInternal, setSelectedEndInternal] = useState(null);
  const [endInternalCodes, setEndInternalCodes] = useState([]);
  const fetchEndInternalCodes = async () => {
    try {

      const res = await newRequest.post("/sysCode/findAll", {
        user_lang: "en_US",
        sys_org_code: currentUser.usr_org_code,
        sys_type: "End Internal Codes",
      }, config);
      const options = res.data.map((sc) => ({
        value: sc.sys_code,
        label: sc.sys_name,
      }));
      setEndInternalCodes(options);
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };

  // product applicable sub classes
  const [selectedProductSc, setSelectedProductSc] = useState({});
  const [productSc, setProductSc] = useState([]);
  const fetchProductSc = async (pr_code) => {
    try {

      const res = await newRequest.post("/uwProductSc/findAll", {
        ps_org_code: currentUser.usr_org_code,
        ps_pr_code: pr_code,
      }, config);
      setProductSc(res.data);
      if (res.data) {
        setSelectedProductSc(res.data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const subClassColumns = [
    {
      header: "Sub Class",
      key: "ps_sc_code",
      type: "text",
      align: "flex-start",
      width: "auto",
    },
    {
      header: "Class",
      key: "ps_mc_code",
      type: "text",
      align: "flex-start",
      width: "250px",
    },
    {
      header: "Layout",
      key: "ps_view",
      type: "text",
      align: "flex-start",
      width: "250px",
    },
    {
      header: "Default",
      key: "ps_default",
      type: "text",
      align: "flex-start",
      width: "250px",
    },
    {
      header: "Enabled",
      key: "ps_enabled",
      type: "text",
      align: "flex-start",
      width: "250px",
    },
  ];

  const [selectedScLayout, setSelectedScLayout] = useState(null);
  const [scLayouts, setScLayouts] = useState([]);
  const fetchScLayouts = async () => {
    try {

      const res = await newRequest.post("/sysCode/findAll", {
        user_lang: "en_US",
        sys_org_code: currentUser.usr_org_code,
        sys_type: "Risk Layout",
      }, config);
      const options = res.data.map((sc) => ({
        name: "ps_view",
        type: "select",
        value: sc.sys_code,
        label: sc.sys_name,
      }));
      setScLayouts(options);
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };

  const handleSubClassChange = (e, index) => {
    try {
      const { name, value, type } = e.target;
      const updatedData = [...productSc];
      const currentRow = updatedData[index];
      currentRow[name] = value;
      currentRow.changed_xx = "Y";
      setProductSc(updatedData);
    } catch (error) {
      console.log(error);
    }
  };

  const saveRecord = async (e) => {
    try {
      setIsProcessing(true);
      // update product details
      const res = await newRequest.put(
        `/uwProduct/upd/${productDocId}`,
        {
          pr_name: prName,
          pr_period_type: prPeriodType,
          pr_cash_carry: prCashCarry,
          pr_onetime: prOnetime,
          pr_enabled: prEnabled,
          pr_motor: prMotor,
          updatedBy: currentUser.usr_id,
        },
        config
      );

      // end of product update
      // update wordings
      const changedWordings = productWordings.filter(
        (obj) => obj.changed_xx === "Y"
      );

      // Loop through each object in the array
      for (let i = 0; i < changedWordings.length; i++) {
        const updatedWording = {
          cond_ref_no: changedWordings[i].cond_ref_no,
          cond_name: changedWordings[i].cond_name,
          cond_desc: changedWordings[i].cond_desc,
          cond_type: changedWordings[i].cond_type,
          cond_order: changedWordings[i].cond_order,
          cond_icon: changedWordings[i].cond_icon,
          cond_mandatory: changedWordings[i].cond_mandatory,
          cond_default: changedWordings[i].cond_default,
          cond_enabled: changedWordings[i].cond_enabled,
          updatedBy: currentUser.usr_id,
        };
        const res = await newRequest.put(
          `/uwProductCond/upd/${changedWordings[i]._id}`,
          updatedWording,
          config
        );
      }
      // end of update wordings

      // sub classes
      const changedSubClass = productSc.filter((obj) => obj.changed_xx === "Y");

      for (let i = 0; i < changedSubClass.length; i++) {
        const res = await newRequest.put(
          `/uwProductSc/upd/${changedSubClass[i]._id}`,
          {
            ps_view: changedSubClass[i].ps_view,
            ps_default: changedSubClass[i].ps_default,
            ps_enabled: changedSubClass[i].ps_enabled,
            updatedBy: currentUser.usr_id,
          },
          config
        );
      }

      const changedEndorsements = productEndorsements.filter(
        (obj) => obj.changed_xx === "Y"
      );

      for (let i = 0; i < changedEndorsements.length; i++) {
        const updatedWording = {
          pe_end_code: changedEndorsements[i].pe_end_code,
          pe_desc: changedEndorsements[i].pe_desc,
          pe_name: changedEndorsements[i].pe_name,
          pe_internal_code: changedEndorsements[i].pe_internal_code,
          pe_enabled: changedEndorsements[i].pe_enabled,
          updatedBy: currentUser.usr_id,
        };

        const res = await newRequest.put(
          `/uwProductEnd/upd/${changedEndorsements[i]._id}`,
          updatedWording,
          config
        );
      }

      // update risk note sections
      for (let i = 0; i < prRisknoteSections.length; i++) {
        const updatedSection = {
          product_docid: productDocId,
          note_section_docid: prRisknoteSections[i]._id,
          section_title: prRisknoteSections[i].section_title,
          section_show: prRisknoteSections[i].section_show,
          userCode: currentUser.usr_id,
        };
        const res = await newRequest.post(
          "/uwProduct/updNoteSection",
          updatedSection,
          config
        );
      }

      setNotifyDialogTitle("Note!");
      setNotifyDialogText(`Changes Saved!`);
      setIsNotifyDialogOpen(true);
    } catch (error) {
      console.log(error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleConfirm = async () => { };
  const goBack = () => {
    navigate(-1);
  };
  const handleCloseNotifyDialog = () => {
    setIsNotifyDialogOpen(false);
  };

  const handleToggleChange = (sectionId, newValue) => {
    const updatedSections = prRisknoteSections.map((section) =>
      section._id === sectionId
        ? { ...section, section_show: newValue }
        : section
    );
    setPrRisknoteSections(updatedSections);
  };

  const fetchProduct = async () => {
    try {
      setIsLoading(true);
      if (productDocId === "new" && productDocId._id) {
        setProductDocId(productData._id);
      }

      const params = {
        docid: productDocId,
      };

      const res = await newRequest.post("/uwProduct/findById", params, config);
      setProductData(res.data);
      if (res.data) {
        if (res.data.pr_code) {
          setPrCode(res.data.pr_code);
        }
        if (res.data.pr_name) {
          setPrName(res.data.pr_name);
        }
        if (res.data.pr_period_type) {
          setPrPeriodType(res.data.pr_period_type);
        }
        if (res.data.pr_cash_carry) {
          setPrCashCarry(res.data.pr_cash_carry);
        }
        if (res.data.pr_onetime) {
          setPrOnetime(res.data.pr_onetime);
        }
        if (res.data.pr_enabled) {
          setPrEnabled(res.data.pr_enabled);
        }
        if (res.data.pr_motor) {
          setPrMotor(res.data.pr_motor);
        }
        if (res.data.pr_risknote_sections) {
          setPrRisknoteSections(res.data.pr_risknote_sections);
        }

        fetchProductExcess(res.data?.pr_code);
        fetchProductWordings(res.data?.pr_code);
        fetchProductSc(res.data?.pr_code);
        fetchProductEndorsements(res.data?.pr_code);
      }

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if (productDocId === "new") {
      // set gl date
      // const currentDate = new Date();
      // const formattedCurrentDate = currentDate.toISOString().split("T")[0];
      // setHdGlDate(formattedCurrentDate);
      // // set defult business unit
      // setHdBizUnit({
      //   value: currentUser.biz_unit_code_xx,
      //   label: currentUser.biz_unit_name_xx,
      // });
      // // set default currency
      // setHdCurCode({
      //   value: currentUser.currency_code_xx,
      //   label: currentUser.currency_name_xx,
      // });
    } else {
      console.log(`fetching productData....with id ${productDocId}`);
      fetchProduct();
    }
    fetchScLayouts();
    fetchEndInternalCodes();
  }, [productDocId]);

  return (
    <motion.div
      className="uw-product"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      <div className="navbar">
        <div className="navbar-left">
          <h3
            style={{
              fontWeight: "500",
              maxWidth: "600px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {prName || "New"}
          </h3>
        </div>
        <div className="navbar-right">
          {Object.keys(productData).length > 0 && (
            <CustomButton startIcon={<RefreshIcon />}>Refresh</CustomButton>
          )}
          <CustomLoadingButton startIcon={<FaSave />} onClick={saveRecord}>
            {savingTitle}
          </CustomLoadingButton>
          <CustomButton startIcon={<IoClose />} onClick={goBack}>
            Close
          </CustomButton>
        </div>
      </div>

      <div className="content">
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "200px",
              width: "100%",
              height: "100%",
            }}
          >
            <GridLoader
              color="#F44336"
              style={{
                height: "50px",
                width: "50px",
              }}
            />
          </div>
        ) : (
          <div className="product-details">
            <div className="form-grid-layout">
              <div className="box box1">
                <div className="product-details-header">
                  <div className="left">
                    <div className="input-item-with-label">
                      <label
                        htmlFor="pr_name"
                        style={{
                          width: "150px",
                        }}
                      >
                        NAME
                      </label>
                      <input
                        id="pr_name"
                        name="pr_name"
                        type="text"
                        placeholder="NAME"
                        value={prName}
                        className="form-input-item"
                        style={{ width: "400px" }}
                        onChange={(e) => setPrName(e.target.value)}
                      />
                    </div>

                    <div className="input-item-with-label">
                      <label
                        htmlFor="pr_code"
                        style={{
                          width: "150px",
                        }}
                      >
                        CODE
                      </label>
                      <input
                        id="pr_code"
                        name="pr_code"
                        type="text"
                        placeholder="CODE"
                        value={prCode}
                        disabled={productDocId !== "new"}
                        className="form-input-item"
                        style={{ width: "200px" }}
                        onChange={(e) => setPrCode(e.target.value)}
                      />
                    </div>

                    <div className="input-item-with-label">
                      <label
                        htmlFor="pr_period_type"
                        className="form-dropdown-label"
                        style={{
                          width: "150px",
                        }}
                      >
                        PERIOD TYPE
                      </label>
                      <select
                        id="pr_period_type"
                        name="pr_period_type"
                        className="select-yn"
                        style={{ width: "200px" }}
                        value={prPeriodType}
                        onChange={(e) => {
                          setPrPeriodType(e.target.value);
                          console.log(e.target.value);
                        }}
                      >
                        <option value="Normal">Normal</option>
                        <option value="NextDay">NextDay</option>
                      </select>
                    </div>
                  </div>
                  <div className="right">
                    <ToggleSwitch
                      id="prCashCarry"
                      value={prCashCarry}
                      onChange={setPrCashCarry}
                      label={"CASH & CARRY"}
                      style={{
                        width: "250px",
                      }}
                    />
                    <ToggleSwitch
                      id="prOnetime"
                      value={prOnetime}
                      onChange={setPrOnetime}
                      label={"ONETIME"}
                    />
                    <ToggleSwitch
                      id="prEnabled"
                      value={prEnabled}
                      onChange={setPrEnabled}
                      label={"ENABLED"}
                    />
                    <ToggleSwitch
                      id="prMotor"
                      value={prMotor}
                      onChange={setPrMotor}
                      label={"MOTOR"}
                    />
                  </div>
                </div>
                <hr
                  style={{
                    border: "none",
                    height: "0.5px",
                    backgroundColor: "#ccc",
                    margin: "10px 0px",
                  }}
                />
                <h1>Risk Note Sections</h1>
                <div className="risk-note-sections">
                  {prRisknoteSections.map((section) => (
                    <div key={section._id}>
                      {/* <span>{section.section_title}</span> */}
                      <ToggleSwitch
                        key={section._id}
                        id={section._id}
                        value={section.section_show}
                        onChange={(newValue) =>
                          handleToggleChange(section._id, newValue)
                        }
                        label={section.section_title}
                        labelPosition="end"
                        labelWidth="150px"
                        disabled={false}
                        border="Y"
                        style={{
                          width: "250px",
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="box box2">
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  sx={tabStyles}
                >
                  <Tab label="DEDUCTIBLES" style={getTabStyles(0)} />
                  <Tab label="WORDINGS" style={getTabStyles(1)} />
                  <Tab label="CHARGES" style={getTabStyles(2)} />
                  <Tab label="ENDORSEMENTS" style={getTabStyles(3)} />
                  <Tab label="SUB CLASSES" style={getTabStyles(4)} />
                </Tabs>

                <TabPanel value={tabValue} index={0}>
                  <table
                    className="custom-table"
                    style={{ display: "flex", width: "100%" }}
                  >
                    <tbody
                      style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "column",
                      }}
                    >
                      {productExcess?.map((row, index) => (
                        <tr
                          key={row._id}
                          className={`${selectedProductExcess?._id === row?._id
                              ? "excess-data-row active"
                              : "excess-data-row"
                            }`}
                          onClick={() => setSelectedProductExcess(row)}
                        >
                          <div className="excess-data-wrapper">
                            <span
                              className={`${selectedProductExcess?._id === row?._id
                                  ? "excess-data-col1 active"
                                  : "excess-data-col1"
                                }`}
                            >
                              {index + 1}
                            </span>
                            <span className="excess-data-col2">
                              <span className="left">
                                <div className="input-item-with-label">
                                  <label
                                    htmlFor="ed_text"
                                    className="form-dropdown-label"
                                    style={{
                                      width: "130x",
                                      marginRight: "3px",
                                      alignSelf: "flex-start",
                                    }}
                                  >
                                    DESCRIPTION
                                  </label>
                                  <textarea
                                    id="ed_text"
                                    name="ed_text"
                                    type="text"
                                    placeholder="DESCRIPTION"
                                    value={row.ed_text}
                                    className="form-input-item"
                                    style={{
                                      width: "auto",
                                      minHeight: "50px",
                                      height: "max-content",
                                    }}
                                    onChange={(e) =>
                                      handleInputExcessChange(e, index)
                                    }
                                  />
                                </div>
                                <div className="input-item-with-label">
                                  <label
                                    htmlFor="ed_type"
                                    className="form-dropdown-label"
                                    style={{
                                      width: "130x",
                                      marginRight: "3px",
                                    }}
                                  >
                                    Type
                                  </label>
                                  <select
                                    id="ed_type"
                                    name="ed_type"
                                    className="select-yn"
                                    value={row.ed_type}
                                    onChange={(e) =>
                                      handleInputExcessChange(e, index)
                                    }
                                  >
                                    <option value="None">None</option>
                                    <option value="Si_Percentage">
                                      SI Percentage
                                    </option>
                                    <option value="Premium_Percentage">
                                      Premium Percentage
                                    </option>
                                    <option value="Fixed Value">
                                      Fixed Value
                                    </option>
                                  </select>
                                </div>
                                <div className="input-item-with-label">
                                  <label
                                    htmlFor="ed_max_limit"
                                    className="form-dropdown-label"
                                    style={{
                                      width: "130x",
                                      marginRight: "3px",
                                    }}
                                  >
                                    Limit
                                  </label>
                                  <input
                                    id="ed_max_limit"
                                    name="ed_max_limit"
                                    type="number"
                                    placeholder="FCL"
                                    value={row.ed_max_limit}
                                    className="form-input-item"
                                    style={{ width: "100px" }}
                                    onChange={(e) =>
                                      handleInputExcessChange(e, index)
                                    }
                                  />
                                </div>
                              </span>
                              <span className="right">
                                <div className="input-item-with-label">
                                  <label
                                    htmlFor="ed_mandatory"
                                    className="form-dropdown-label"
                                    style={{
                                      width: "100px",
                                      marginRight: "3px",
                                    }}
                                  >
                                    Mandatory
                                  </label>
                                  <select
                                    id="ed_mandatory"
                                    name="ed_mandatory"
                                    className="select-yn"
                                    value={row.ed_mandatory}
                                    onChange={(e) =>
                                      handleInputExcessChange(e, index)
                                    }
                                  >
                                    <option value="Y">Yes</option>
                                    <option value="N">No</option>
                                  </select>
                                </div>

                                <div className="input-item-with-label">
                                  <label
                                    htmlFor="ed_default"
                                    className="form-dropdown-label"
                                    style={{
                                      width: "100px",
                                      marginRight: "3px",
                                    }}
                                  >
                                    Default
                                  </label>
                                  <select
                                    id="ed_default"
                                    name="ed_default"
                                    className="select-yn"
                                    value={row.ed_default}
                                    onChange={(e) =>
                                      handleInputExcessChange(e, index)
                                    }
                                  >
                                    <option value="Y">Yes</option>
                                    <option value="N">No</option>
                                  </select>
                                </div>
                                <div className="input-item-with-label">
                                  <label
                                    htmlFor="ed_enabled"
                                    className="form-dropdown-label"
                                    style={{
                                      width: "100px",
                                      marginRight: "3px",
                                    }}
                                  >
                                    Enabled
                                  </label>
                                  <select
                                    id="ed_enabled"
                                    name="ed_enabled"
                                    className="select-yn"
                                    value={row.ed_enabled}
                                    onChange={(e) =>
                                      handleInputExcessChange(e, index)
                                    }
                                  >
                                    <option value="Y">Yes</option>
                                    <option value="N">No</option>
                                  </select>
                                </div>
                              </span>
                            </span>
                            <span className="excess-data-col3">
                              <DeleteIcon
                                style={{
                                  height: "20px",
                                  width: "20px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  try {
                                    // let action_text = `Delete SMI ${row.smi_name}?`;
                                    // setObjectToAction({
                                    //   object_id: row._id,
                                    //   action_text: action_text,
                                    //   new_doc_status: "delete",
                                    // });
                                    // setConfirmDialogAction("deleteSmi");
                                    // setConfirmDialogOpen(true);
                                  } catch (error) {
                                    console.log(error);
                                  }
                                }}
                              />
                            </span>
                          </div>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <table
                      className="custom-table"
                      style={{ display: "flex", width: "100%" }}
                    >
                      <tbody
                        style={{
                          display: "flex",
                          width: "100%",
                          flexDirection: "column",
                        }}
                      >
                        {productWordings?.map((row, index) => (
                          <>
                            <tr
                              key={row._id}
                              className={`${selectedProductWordings?._id === row?._id
                                  ? "data-row active"
                                  : "data-row"
                                }`}
                              style={{ display: "flex", width: "100%" }}
                              onClick={() => setSelectedProductWordings(row)}
                            >
                              <div
                                className="data-columns"
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span
                                  className={`${selectedProductWordings?._id === row?._id
                                      ? "col1 active"
                                      : "col1"
                                    }`}
                                >
                                  {index + 1}
                                </span>
                                <span
                                  className="col2"
                                  style={{
                                    display: "flex",
                                    flexGrow: "1",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div className="input-item-with-label">
                                    <label
                                      htmlFor="cond_name"
                                      className="form-dropdown-label"
                                      style={{
                                        width: "10%",
                                        marginRight: "3px",
                                      }}
                                    >
                                      Name
                                    </label>
                                    <input
                                      id="cond_name"
                                      name="cond_name"
                                      type="text"
                                      placeholder="Name"
                                      value={row.cond_name}
                                      className="form-input-item"
                                      style={{ width: "80%" }}
                                      onChange={(e) =>
                                        handleInputWordingsChange(e, index)
                                      }
                                    />
                                  </div>
                                  <div
                                    className="input-item-with-label"
                                    style={{ marginTop: "5px" }}
                                  >
                                    <label
                                      htmlFor="cond_desc"
                                      className="form-dropdown-label"
                                      style={{
                                        width: "10%",
                                        marginRight: "3px",
                                        alignSelf: "flex-start",
                                      }}
                                    >
                                      DESCRIPTION
                                    </label>
                                    <textarea
                                      id="cond_desc"
                                      name="cond_desc"
                                      type="text"
                                      placeholder="DESCRIPTION"
                                      value={row.cond_desc}
                                      rows={5}
                                      className="form-input-item"
                                      style={{
                                        width: "80%",
                                        height: "max-content",
                                      }}
                                      onChange={(e) =>
                                        handleInputWordingsChange(e, index)
                                      }
                                    />
                                  </div>
                                  <div
                                    className="input-item-with-label"
                                    style={{ marginTop: "5px" }}
                                  >
                                    <label
                                      htmlFor="cond_type"
                                      className="form-dropdown-label"
                                      style={{
                                        width: "10%",
                                        marginRight: "3px",
                                      }}
                                    >
                                      Type
                                    </label>
                                    <select
                                      id="cond_type"
                                      name="cond_type"
                                      className="select-yn"
                                      style={{
                                        width: "20%",
                                      }}
                                      value={row.cond_type}
                                      onChange={(e) =>
                                        handleInputWordingsChange(e, index)
                                      }
                                    >
                                      <option value="Wording">Wording</option>
                                      <option value="Clause">Clause</option>
                                      <option value="Warranty">Warranty</option>
                                    </select>
                                    <label
                                      htmlFor="cond_code"
                                      className="form-dropdown-label"
                                      style={{
                                        width: "10%",
                                        marginRight: "3px",
                                      }}
                                    >
                                      Code
                                    </label>
                                    <input
                                      id="cond_code"
                                      name="cond_code"
                                      type="text"
                                      placeholder="CODE"
                                      value={row.cond_code}
                                      className="form-input-item"
                                      style={{ width: "20%" }}
                                      onChange={(e) =>
                                        handleInputWordingsChange(e, index)
                                      }
                                    />
                                    <label
                                      htmlFor="cond_ref_no"
                                      className="form-dropdown-label"
                                      style={{
                                        width: "10%",
                                        marginRight: "3px",
                                      }}
                                    >
                                      REF No
                                    </label>
                                    <input
                                      id="cond_ref_no"
                                      name="cond_ref_no"
                                      type="text"
                                      placeholder="REF No"
                                      value={row.cond_ref_no}
                                      className="form-input-item"
                                      style={{ width: "20%" }}
                                      onChange={(e) =>
                                        handleInputWordingsChange(e, index)
                                      }
                                    />
                                  </div>

                                  <div
                                    className="input-item-with-label"
                                    style={{ marginTop: "5px" }}
                                  >
                                    <label
                                      htmlFor="cond_mandatory"
                                      className="form-dropdown-label"
                                      style={{
                                        width: "10%",
                                        marginRight: "3px",
                                      }}
                                    >
                                      Mandatory
                                    </label>
                                    <select
                                      id="cond_mandatory"
                                      name="cond_mandatory"
                                      className="select-yn"
                                      style={{
                                        width: "20%",
                                      }}
                                      value={row.cond_mandatory}
                                      onChange={(e) =>
                                        handleInputWordingsChange(e, index)
                                      }
                                    >
                                      <option value="Y">Yes</option>
                                      <option value="N">No</option>
                                    </select>

                                    <label
                                      htmlFor="cond_default"
                                      className="form-dropdown-label"
                                      style={{
                                        width: "10%",
                                        marginRight: "3px",
                                      }}
                                    >
                                      Default
                                    </label>
                                    <select
                                      id="cond_default"
                                      name="cond_default"
                                      className="select-yn"
                                      value={row.cond_default}
                                      style={{
                                        width: "20%",
                                      }}
                                      onChange={(e) =>
                                        handleInputWordingsChange(e, index)
                                      }
                                    >
                                      <option value="Y">Yes</option>
                                      <option value="N">No</option>
                                    </select>
                                    <label
                                      htmlFor="cond_enabled"
                                      className="form-dropdown-label"
                                      style={{
                                        width: "10%",
                                        marginRight: "3px",
                                      }}
                                    >
                                      Enabled
                                    </label>
                                    <select
                                      id="cond_enabled"
                                      name="cond_enabled"
                                      className="select-yn"
                                      value={row.cond_enabled}
                                      style={{
                                        width: "20%",
                                      }}
                                      onChange={(e) =>
                                        handleInputWordingsChange(e, index)
                                      }
                                    >
                                      <option value="Y">Yes</option>
                                      <option value="N">No</option>
                                    </select>
                                  </div>
                                </span>
                                <span className="col3">
                                  <Tooltip
                                    title={`Delete ${row.cond_name}?`}
                                    placement="right"
                                    style={{
                                      backgroundColor: "blue",
                                      color: "white",
                                    }}
                                  >
                                    <DeleteIcon
                                      style={{
                                        height: "20px",
                                        width: "20px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        try {
                                          let action_text = `Delete SMI ${row.smi_name}?`;
                                          setObjectToAction({
                                            object_id: row._id,
                                            action_text: action_text,
                                            new_doc_status: "delete",
                                          });
                                          setConfirmDialogAction("deleteSmi");
                                          setConfirmDialogOpen(true);
                                        } catch (error) {
                                          console.log(error);
                                        }
                                      }}
                                    />
                                  </Tooltip>
                                </span>
                              </div>
                            </tr>
                            <hr
                              style={{
                                border: "none",
                                height: "0.5px",
                                backgroundColor: "#ccc",
                                margin: "5px 0px",
                              }}
                            />
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                  <h1>CHARGES</h1>
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                  <div className="endorsements">
                    <div
                      className="endorsements-wrapper"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ flex: "2" }}>
                        <table className="custom-table">
                          <thead>
                            <tr>
                              {endorsementColumns.map((column, index) => (
                                <th
                                  key={column.key}
                                  style={{
                                    textAlign: column.align,
                                    justifyContent: "center",
                                    width: column.width,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: column.align,
                                    }}
                                  >
                                    {column.header}
                                  </div>
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {productEndorsements.map((row, index) => (
                              <tr key={row._id}>
                                {endorsementColumns.map((column, colIndex) => (
                                  <td
                                    key={column.key}
                                    style={{
                                      textAlign: column.align,
                                      width: column.width,
                                      alignItems: "center",
                                      padding: "8px 12px",
                                    }}
                                  >
                                    {column.key === "pe_name" && (
                                      // <span>{`${row["pe_name"]}`}</span>
                                      <input
                                        id="pe_name"
                                        name="pe_name"
                                        type="text"
                                        placeholder="NAME"
                                        value={row["pe_name"]}
                                        className="form-input-item"
                                        style={{ width: "250px" }}
                                        onChange={(e) =>
                                          handleInputEndChange(e, index)
                                        }
                                      />
                                    )}
                                    {column.key === "pe_end_code" && (
                                      <span>{`${row["pe_end_code"]}`}</span>
                                    )}
                                    {column.key === "pe_desc" && (
                                      <span>{`${row["pe_desc"]}`}</span>
                                    )}

                                    {column.key === "pe_enabled" && (
                                      <select
                                        id="pe_enabled"
                                        name="pe_enabled"
                                        className="select-yn"
                                        value={row.ps_enabled}
                                        onChange={(e) =>
                                          handleInputEndChange(e, index)
                                        }
                                      >
                                        <option value="Y">Yes</option>
                                        <option value="N">No</option>
                                      </select>
                                    )}
                                    {column.key === "pe_internal_code" && (
                                      <select
                                        id="pe_internal_code"
                                        name="pe_internal_code"
                                        className="select-yn"
                                        value={row.pe_internal_code}
                                        onChange={(e) =>
                                          handleInputEndChange(e, index)
                                        }
                                      >
                                        {endInternalCodes.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    )}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div style={{ backgroundColor: "red", flex: "4" }}>
                        Right
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={tabValue} index={4}>
                  <div className="sub-classes-content">
                    <table className="custom-table">
                      <thead>
                        <tr>
                          {subClassColumns.map((column, index) => (
                            <th
                              key={column.key}
                              style={{
                                textAlign: column.align,
                                justifyContent: "center",
                                width: column.width,
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: column.align,
                                }}
                              >
                                {column.header}
                              </div>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {productSc.map((row, index) => (
                          <tr key={row._id}>
                            {subClassColumns.map((column, colIndex) => (
                              <td
                                key={column.key}
                                style={{
                                  textAlign: column.align,
                                  width: column.width,
                                  alignItems: "center",
                                  padding: "8px 12px",
                                }}
                              >
                                {column.key === "ps_sc_code" && (
                                  <span>{`${row["sc_name_xx"]} - ${row["ps_sc_code"]}`}</span>
                                )}
                                {column.key === "ps_mc_code" && (
                                  <span>{`${row["mc_code_xx"]} - ${row["ps_mc_code"]}`}</span>
                                )}

                                {column.key === "ps_default" && (
                                  <select
                                    id="ps_default"
                                    name="ps_default"
                                    className="select-yn"
                                    value={row.ps_default}
                                    onChange={(e) =>
                                      handleSubClassChange(e, index)
                                    }
                                  >
                                    <option value="Y">Yes</option>
                                    <option value="N">No</option>
                                  </select>
                                )}
                                {column.key === "ps_enabled" && (
                                  <select
                                    id="ps_enabled"
                                    name="ps_enabled"
                                    className="select-yn"
                                    value={row.ps_enabled}
                                    onChange={(e) =>
                                      handleSubClassChange(e, index)
                                    }
                                  >
                                    <option value="Y">Yes</option>
                                    <option value="N">No</option>
                                  </select>
                                )}
                                {column.key === "ps_view" && (
                                  <select
                                    id="ps_view"
                                    name="ps_view"
                                    className="select-yn"
                                    value={row.ps_view}
                                    onChange={(e) =>
                                      handleSubClassChange(e, index)
                                    }
                                  >
                                    {scLayouts.map((option) => (
                                      <option
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </option>
                                    ))}
                                  </select>
                                )}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </TabPanel>
              </div>
            </div>
          </div>
        )}

        {/* Dialog to notify user about changes being saved */}
        <Dialog open={isNotifyDialogOpen} onClose={handleCloseNotifyDialog}>
          <DialogTitle>{notifyDialogTitle}</DialogTitle>
          <DialogContent>
            <div dangerouslySetInnerHTML={{ __html: notifyDialogText }} />
          </DialogContent>
          <DialogActions>
            <CustomButton onClick={handleCloseNotifyDialog}>Close</CustomButton>
          </DialogActions>
        </Dialog>
        {/* Confirm Dialog */}
        <ConfirmDialog
          isOpen={isConfirmDialogOpen}
          onClose={handleCancel}
          onConfirm={handleConfirm}
          dialogTitle="Confirmation"
          dialogContent={<p>{objectToAction.action_text}</p>}
        />
        {isProcessing && (
          <motion.div
            className="processing-dialog"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 1 } }}
            exit={{ opacity: 0, transition: { duration: 1 } }}
          >
            <div className="processing-wrapper">
              <GridLoader
                color="#F44336"
                style={{
                  height: "50px",
                  width: "50px",
                }}
              />
            </div>
          </motion.div>
        )}
      </div>
    </motion.div>
  );
};

export default UwProduct;
