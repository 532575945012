import React, { useEffect, useState } from 'react';
import './FileUploadComponent.scss';
import { Chip } from '@mui/material';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import app from "../../utils/firebase.js";
import { showToast } from "../../toastService.js";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    LinearProgress,
    DialogActions
} from "@mui/material";
import { useSelector } from 'react-redux';
import newRequest from '../../utils/newRequest.js';
import { CustomLoadingButton, CustomButton } from '../ui/CustomButton.jsx';
import PublishIcon from '@mui/icons-material/Publish';
import DOMPurify from 'dompurify';
import AttachmentIcon from '@mui/icons-material/Attachment';

const FileUploadComponent = ({ documentNo, documentId, documentType, fetchDocumentAttachments, uploadFolder }) => {
    const { currentUser } = useSelector((state) => state.user);
    const filesLimit = 5;
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [currentFileName, setCurrentFileName] = useState('');
    const [currentFileIndex, setCurrentFileIndex] = useState(0);
    const [totalFiles, setTotalFiles] = useState(0);
    const [uploadButtonTitle, setUploadButtonTitle] = useState("Upload");
    const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
    const [notifyDialogTitle, setNotifyDialogTitle] = useState("Confirm");
    const [notifyDialogText, setNotifyDialogText] = useState("Text");

    useEffect(() => {
        setTotalFiles(selectedFiles.length);
    }, [selectedFiles]);

    const saveFileToDb = async (
        fl_name,
        fl_internal_name,
        fl_type,
        fl_path,
        fl_category,
        fl_size_kb,
        fl_parent_doc_id
    ) => {
        try {
            await newRequest.post("/files/add", {
                fl_org_code: currentUser.usr_org_code,
                fl_name,
                fl_internal_name,
                fl_type,
                fl_path,
                fl_category,
                fl_doc_type: documentType,
                fl_doc_no: documentNo,
                fl_doc_index: "",
                fl_doc_sub_index: "",
                fl_ent_code: "",
                fl_aent_code: "",
                fl_user_id: "",
                fl_size_kb,
                fl_parent_doc_id,
                userCode: currentUser.usr_id,
            });
        } catch (error) {
            console.error("Error saving file to DB:", error);
        }
    };

    // close notify dialog
    const handleCloseNotifyDialog = () => {
        setIsNotifyDialogOpen(false);
    };

    const handleSelectedFiles = (e) => {
        const files = Array.from(e.target.files);
        const newFiles = files.map((file, index) => ({
            id: selectedFiles.length + index,
            name: file.name,
            file, // Store the actual file
        }));
        if (selectedFiles.length + newFiles.length <= filesLimit) {
            setSelectedFiles([...selectedFiles, ...newFiles]);
        } else {
            setNotifyDialogTitle("Note!");
            setNotifyDialogText(`Please select a maximum of <strong>${filesLimit} files</strong> at a time.`);
            setIsNotifyDialogOpen(true);
        }
    };

    const handleRemoveFile = (id) => {
        setSelectedFiles(selectedFiles.filter(attachment => attachment.id !== id));
    };

    const uploadToFirebase = async (file, fileFolder) => {
        const storage = getStorage(app);
        const fileName = new Date().getTime() + file.name;
        const fileExtension = file.name.split(".").pop();
        const fileType = file.type;
        const fileSizeKB = file.size / 1024;
        const storageRef = ref(
            storage,
            `qc_${currentUser.usr_org_code}/${fileFolder}/${fileName}`
        );
        const uploadTask = uploadBytesResumable(storageRef, file);

        return new Promise((resolve, reject) => {
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const fileProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    // Calculate overall progress based on the current file index and the file progress
                    const progressPerFile = 100 / totalFiles;
                    // const overallProgress = ((currentFileIndex - 1) * progressPerFile) + (fileProgress / totalFiles);
                    // setUploadProgress(Math.round(overallProgress));
                    const progress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadProgress(Math.round(progress));
                    switch (snapshot.state) {
                        case "paused":
                            console.log("Upload is paused");
                            break;
                        case "running":
                            console.log("Upload is running");
                            break;
                        default:
                            break;
                    }
                },
                (error) => {
                    setIsUploadDialogOpen(false);
                    console.log(error);
                    reject(error);
                },
                async () => {
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                    await saveFileToDb(
                        file.name,
                        fileName,
                        fileExtension,
                        downloadURL,
                        documentType,
                        fileSizeKB,
                        documentId
                    );
                    fetchDocumentAttachments(documentType, documentId);
                    resolve();
                }
            );
        });
    };

    const uploadAllFiles = async () => {
        try {
            setIsUploadDialogOpen(true);
            setUploadButtonTitle("Please Wait...")
            for (let i = 0; i < selectedFiles.length; i++) {
                const attachment = selectedFiles[i];
                setCurrentFileName(attachment.name);
                setCurrentFileIndex(i + 1);
                await uploadToFirebase(attachment.file, uploadFolder);
            }
            const noOfFiles = selectedFiles?.length;
            let fileName = "";

            if (selectedFiles?.length === 1) {
                fileName = selectedFiles[0].name;
            }

            setSelectedFiles([]); // Clear the selected files array
            setIsUploadDialogOpen(false); // Close the dialog
            showToast(`${noOfFiles === 1 ? `${fileName} successfully uploaded!` : `${noOfFiles} files successfully uploaded!`}`, "success");
        } catch (error) {
            console.log(error);
        } finally {
            setUploadButtonTitle("Upload");
        }
    };

    const handleUploadCloseDialog = () => {
        setIsUploadDialogOpen(false);
    };

    return (
        <div className="main-wrapper">
            <div className="attachment-upload">
                <input
                    type="file"
                    multiple
                    id="file-upload"
                    style={{ display: 'none' }}
                    onChange={handleSelectedFiles}
                />
                <label htmlFor="file-upload">
                    <span className='file-upload-item' component="span" style={{ display: "flex", width: "max-content", padding: "3px 5px" }}>
                        <AttachmentIcon /> Attachments
                    </span>
                </label>
                <div className="attachments">
                    {selectedFiles.map((attachment, index) => (
                        <Chip
                            key={attachment.id}
                            label={attachment.name}
                            onDelete={() => handleRemoveFile(attachment.id)}
                            className={`${currentFileIndex === index ? "attachment-chip current" : "attachment-chip"}`}
                        />
                    ))}
                    {selectedFiles?.length > 0 &&
                        <CustomLoadingButton
                            startIcon={<PublishIcon />}
                            onClick={uploadAllFiles}
                            height={"32px"}
                            width={"max-content"}
                            borderRadius={"30px"}
                        >
                            {uploadButtonTitle} {selectedFiles?.length === 1 ? selectedFiles?.length + " file" : selectedFiles?.length + " files"}
                        </CustomLoadingButton>}
                </div>
            </div>
            <UploadDialog
                open={isUploadDialogOpen}
                handleClose={handleUploadCloseDialog}
                uploadProgress={uploadProgress}
                currentFileName={currentFileName}
                currentFileIndex={currentFileIndex}
                totalFiles={totalFiles}
            />
            {/* Dialog to user about changes being saved */}
            < Dialog open={isNotifyDialogOpen} onClose={handleCloseNotifyDialog} >
                <DialogTitle>{notifyDialogTitle}</DialogTitle>
                <DialogContent>
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(notifyDialogText) }} />
                </DialogContent>
                <DialogActions>
                    <CustomButton onClick={handleCloseNotifyDialog}>Close</CustomButton>
                </DialogActions>
            </Dialog >

        </div>
    );
};

const UploadDialog = ({ open, handleClose, uploadProgress, currentFileName, currentFileIndex, totalFiles }) => {
    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle style={{
                margin: "5px 5px",
                marginLeft: "25px",
                padding: "1px 5px",
                borderRadius: "5px",
                width: "max-content",
                fontWeight: "400",
                backgroundColor: "rgba(244, 67, 54, 0.3)",
                color: "rgba(244, 67, 54, 1)",
            }}>
                {`Uploading ${currentFileIndex} of ${totalFiles}`}
            </DialogTitle>
            <DialogContent>
                <div style={{ width: '100%', overflowWrap: 'break-word' }}>
                    <p style={{ fontWeight: "400", fontSize: "18px" }}>{currentFileName}</p>
                    <div style={{ margin: "10px 0px" }}>
                        <LinearProgress variant="determinate" value={uploadProgress} />
                    </div>
                    {uploadProgress && uploadProgress === 0 ? "Starting..." : <p>{uploadProgress}% Complete</p>}
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default FileUploadComponent;