import { styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export const customSelectStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    padding: "0px",
    borderRadius: "5px",
    height: state.selectProps.height || "36px",
    width: state.selectProps.width || "300px",
    border: state.isFocused ? "none" : "0.3px solid #ccc",
    backgroundColor: state.isDisabled ? "rgba(255, 202, 40, 0.2)" : "#ffffff",
    boxShadow: state.isFocused ? "0 0 0 1.5px #fff, 0 0 0 4px rgba(255, 202, 40)" : "none",
    "&:hover": {
      borderColor: state.isFocused ? "#ffca28" : "#ccc",
    },
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: state.isSelected ? "#ffca28" : state.isFocused ? "#f0f0f0" : "#ffffff",
    color: state.isSelected ? "#ffffff" : "#000",
    "&:hover": {
      backgroundColor: "#ce93d8",
      color: "#ffffff",
      cursor: "pointer",
      transition: "top 1s ease-in-out",
    },
    borderRadius: "5px",
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    color: "#000",
  }),
  menu: (baseStyles, state) => ({
    ...baseStyles,
    width: state.selectProps.width || "300px",
    // marginTop: "3px",
    borderRadius: "5px",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.1)",
    border: "0.1px solid #ffca28",
    padding: "0px 3px",
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  loadingIndicator: (baseStyles) => ({
    ...baseStyles,
    color: "#ffca28",
  }),
  dropdownIndicator: (baseStyles, state) => ({
    ...baseStyles,
    display: state.isDisabled ? 'none' : 'block',
  }),
};

// mui select 
export const MuiSelect = styled(Select)(({ theme }) => ({
  padding: "0px",
  borderRadius: "5px",
  height: "36px",
  width: "300px",
  border: "0.5px solid #ccc",
  backgroundColor: "#ffffff",
  '&:focus': {
    border: "none",
    boxShadow: "0 0 0 1.5px #fff, 0 0 0 4px rgba(255, 202, 40)",
  },
  '&:hover': {
    borderColor: "#ccc",
  },
}));

export const MuiMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: "#ffffff",
  color: "#000",
  '&:hover': {
    backgroundColor: "#ce93d8",
    color: "#ffffff",
    cursor: "pointer",
    transition: "top 1s ease-in-out",
  },
  borderRadius: "5px",
  '&.Mui-selected': {
    backgroundColor: "#ffca28",
    color: "#ffffff",
  },
}));