import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";
import "../../style.scss";
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/system';


export const CustomButton = ({
  onClick,
  children,
  startIcon,
  height,
  width,
  disabled = false,
}) => {
  return (
    <Button
      onClick={onClick}
      startIcon={startIcon}
      disabled={disabled} // Pass the disabled prop to the Button component
      sx={{
        "&:hover": {
          backgroundColor: "#FFB300",
          color: "#333333",
        },
        padding: "5px 15px",
        backgroundColor: "#FFB300",
        color: "#333333",
        border: disabled ? "0.1px solid #f0f0f0" : "0.1px solid #FFB300",
        fontWeight: "bold",
        fontSize: "14px",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#fff",
        display: "flex",
        height: height || "36px",
        width: width || "max-content",
      }}
    >
      {children}
    </Button>
  );
};


export const CustomButtonFilled = ({
  onClick,
  children,
  startIcon,
  height,
  width,
}) => {
  return (
    <Button
      onClick={onClick} // Pass the onClick prop to the underlying MUI Button
      startIcon={startIcon}
      sx={{
        "&:hover": {
          backgroundColor: "#fff", // Change hover color
          border: "0.1px solid #FFB300", // Change hover color
        },
        padding: "5px 15px", // padding
        border: "0.1px solid #FFB300", // Change hover color
        fontWeight: "bold",
        fontSize: "14px", // Change font size
        color: "#fff", // Change hover color
        // backgroundColor: "#FFB300",
        background: "linear-gradient(to right, #E91E63, #FFB300)", // Gradient background
        height: height || "36px",
        width: width || "max-content",
      }}
    >
      {children}
    </Button>
  );
};

export const SearchButton = ({
  onClick,
  children,
  startIcon,
  height,
  width,
  themeColor = "#ffca28",
  borderRadius = "0px 5px 5px 0px",
}) => {
  return (
    <Button
      onClick={onClick}
      startIcon={startIcon}
      sx={{
        "&:hover": {
          backgroundColor: "#FFB300",
          border: "none",
        },
        padding: "5px 15px",
        border: "none",
        fontWeight: "bold",
        fontSize: "14px",
        color: "#333",
        backgroundColor: themeColor,
        height: height || "36px",
        width: width || "max-content",
        borderRadius: borderRadius,
      }}
    >
      {children}
    </Button>
  );
};

export const CustomButtonGradient = ({
  onClick,
  children,
  startIcon,
  height,
  width,
}) => {
  return (
    <Button
      onClick={onClick}
      startIcon={startIcon}
      sx={{
        "&:hover": {
          color: "#fff",
          border: "0.1px solid #FFB300",
          background: "linear-gradient(to right, #FFB300, #E91E63)",
        },
        transition: "transform 1s ease",
        padding: "5px 15px",
        fontWeight: "bold",
        fontSize: "14px",
        color: "#fff",
        background: "linear-gradient(to right, #E91E63, #FFB300)",
        height: height || "36px",
        width: width || "max-content",
      }}
    >
      {children}
    </Button>
  );
};

export const CustomLoadingGradient = ({
  onClick,
  children,
  startIcon,
  height,
  width,
}) => {
  const [loading, setLoading] = useState(false); // Define loading state

  const handleClick = async () => {
    try {
      // Set loading state to true
      setLoading(true);
      // Call the onClick function passed as a prop
      await onClick();
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
    } finally {
      // Set loading state back to false after the operation completes (success or failure)
      setLoading(false);
    }
  };

  return (
    <Button
      loading={loading} // Pass the loading state to the LoadingButton component
      onClick={handleClick} // Call handleClick function when the button is clicked
      startIcon={
        loading ? <CircularProgress size={24} color="inherit" /> : startIcon
      }
      disabled={loading}
      sx={{
        "&:hover": {
          background: "linear-gradient(to right, #FFB300, #E91E63)",
          color: "#fff",
        },
        padding: "5px 15px",
        background: "linear-gradient(to right, #E91E63, #FFB300)",
        color: "#fff",
        border: "0.1px solid #FFB300",
        fontWeight: "bold",
        height: height || "36px",
        width: width || "max-content",
      }}
    >
      {children}
    </Button>
  );
};

export const CustomButtonMenu = ({
  onClick,
  children,
  startIcon,
  currentButton,
}) => {
  return (
    <Button
      onClick={onClick} // Pass the onClick prop to the underlying MUI Button
      startIcon={startIcon}
      sx={{
        "&:hover": {
          backgroundColor: "#FFB300", // Change hover color
          color: "#fff", // Change hover color
          borderRadius: "5px",
        },
        padding: "5px 15px", // padding
        backgroundColor: currentButton ? "#FFB300" : "inherit",
        fontWeight: "bold",
        fontSize: "13px", // Change font size
        color: currentButton ? "#fff" : "#9f9b9b",
        borderRadius: "5px",
      }}
    >
      {children}
    </Button>
  );
};

export const CustomLoadingButton = ({
  onClick,
  children,
  startIcon,
  height,
  width,
  borderRadius = "5px",
  disabled,
}) => {
  const [loading, setLoading] = useState(false);
  const [originalChildren, setOriginalChildren] = useState(children);

  const handleClick = async () => {
    try {
      // Set loading state to true
      setLoading(true);
      // Change the button text to "Please Wait"
      setOriginalChildren("Working...");
      // Call the onClick function passed as a prop
      await onClick();
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
    } finally {
      // Set loading state back to false after the operation completes (success or failure)
      setLoading(false);
      // Restore the original button text
      setOriginalChildren(children);
    }
  };

  return (
    <Button
      onClick={handleClick}
      startIcon={loading ? <CircularProgress size={24} color="inherit" /> : startIcon}
      disabled={loading || disabled} // Combine internal loading state and disabled prop
      sx={{
        "&:hover": {
          backgroundColor: "#FFB300",
          color: "#333333",
        },
        padding: "5px 15px",
        backgroundColor: loading ? "#FFB300" : "#ffffff",
        color: loading ? "#333333" : disabled ? "#000" : "#000",
        border: disabled ? "0.1px solid #f0f0f0" : "0.1px solid #FFB300",
        fontWeight: "bold",
        fontSize: "15px",
        height: height || "36px",
        borderRadius: borderRadius,
        width: width || "max-content",
        cursor: loading || disabled ? 'not-allowed' : 'pointer', 
      }}
    >
      {originalChildren}
    </Button>
  );
};



export const CustomButtonSmall = ({
  onClick,
  children,
  startIcon,
  currentButton,
  height,
  width,
  borderRadius,
}) => {
  return (
    <Button
      onClick={onClick}
      startIcon={startIcon}
      sx={{
        "&:hover": {
          backgroundColor: "#FFB300",
          color: "#fff",
        },
        border: "0.1px solid #FFB300",
        backgroundColor: currentButton ? "#FFB300" : "inherit",
        color: currentButton ? "#fff" : "#000",
        fontWeight: "bold",
        fontSize: "12px",
        padding: "5px 15px",
        height: height || "25px",
        width: width || "max-content",
        borderRadius: borderRadius || "5px",
      }}
    >
      {children}
    </Button>
  );
};

export const CustomButtonPlain = ({
  onClick,
  children,
  startIcon,
  height,
  width,
}) => {
  return (
    <Button
      onClick={onClick}
      startIcon={startIcon}
      sx={{
        "&:hover": {
          backgroundColor: "#FFB300",
          color: "#fff",
        },
        fontWeight: "bold",
        fontSize: "12px",
        color: "#000",
        backgroundColor: "rgb(0, 0, 0, 0.05)",
        padding: "5px 15px",
        height: height || "36px",
        width: width || "max-content",
        // zIndex: 1,
      }}
    >
      {children}
    </Button>
  );
};

export const CustomButtonSmallDarkBg = ({
  onClick,
  children,
  startIcon,
  bgColor,
  ftColor,
  height,
  width,
  borderRadius,
}) => {
  return (
    <Button
      onClick={onClick}
      startIcon={startIcon}
      sx={{
        "&:hover": {
          backgroundColor: "#FFB300",
          color: "#fff",
        },
        border: "0.1px solid #FFB300",
        backgroundColor: bgColor || "inherit",
        color: ftColor || "#000",
        fontWeight: "bold",
        fontSize: "12px",
        padding: "5px 15px",
        height: height || "25px",
        width: width || "max-content",
        borderRadius: borderRadius || "5px",
        // zIndex: 1,
      }}
    >
      {children}
    </Button>
  );
};

const CircularButtonStyle = styled(IconButton)(({ width, height }) => ({
  width: width || 40,
  height: height || 40,
  backgroundColor: 'black',
  color: 'white',
  '&:hover': {
    backgroundColor: 'lightgray',
    '& .MuiSvgIcon-root': {
      color: 'black',
    },
  },
}));

const CircularButton = ({ onClick, icon = <AddIcon />, width = 40, height = 40 }) => {
  return (
    <CircularButtonStyle onClick={onClick} width={width} height={height}>
      {icon}
    </CircularButtonStyle>
  );
};

export default CircularButton;