import "./UwPolicyNew.scss";
import newRequest from "../../../utils/newRequest.js";
import { IoClose } from "react-icons/io5";
import { motion } from "framer-motion";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { GridLoader } from "react-spinners";
import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import CircularButton, { CustomButton } from "../../../components/ui/CustomButton.jsx";


const UwPolicyNew = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const [isSearching, setIsSearching] = useState(false);
  const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
  const [notifyDialogTitle, setNotifyDialogTitle] = useState("Confirm");
  const [notifyDialogText, setNotifyDialogText] = useState("Text");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };


  const goBack = () => {
    navigate(-1);
  };

  const handleCloseNotifyDialog = () => {
    setIsNotifyDialogOpen(false);
  };

  return (
    < motion.div
      className="uw-policy-new"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}>
      {isSearching ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "200px",
            width: "100%",
            height: "100%",
          }}
        >
          <GridLoader
            color="#F44336"
            style={{
              height: "50px",
              width: "50px",
            }}
          />
        </div>
      ) : (
        <div className="main-wrapper">
          <div className="content-summary-wrapper">
            <div className="content-wrapper">
              <div className="content-details">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Numquam consequuntur nihil et libero maiores excepturi vel nesciunt. Nostrum rem enim temporibus error necessitatibus accusamus amet dicta, repudiandae velit dignissimos iste?            </div>
            </div>
            <div className="summary-wrapper">
              <div className="summary-content">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque illum natus vitae quod nisi praesentium ducimus repellat! Laboriosam minima ipsam eligendi fugiat eos repellendus ducimus. Consequatur, praesentium! Laudantium, magnam vel?             </div>
            </div>
          </div>
        </div>
      )}
      {/* Dialog to notify user about changes being saved */}
      <Dialog open={isNotifyDialogOpen} onClose={handleCloseNotifyDialog}>
        <DialogTitle>{notifyDialogTitle}</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: notifyDialogText }} />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseNotifyDialog}>Close</CustomButton>
        </DialogActions>
      </Dialog>
    </motion.div>
  );
};

export default UwPolicyNew;
