import React, { forwardRef, useImperativeHandle } from 'react';
import { useEffect, useState } from "react";
import "./UwPolicyRisk.scss";
import { ToggleSwitch } from "../../../components/toogle/ToggleSwitch.jsx";
import {
  CustomButton,
  CustomButtonSmall,
  CustomButtonSmallDarkBg,
  CustomLoadingButton,
} from "../../../components/ui/CustomButton.jsx";
import { customSelectStyles } from "../../../components/ui/uiStyleElements.jsx";
import { showToast } from "../../../toastService.js";
import { calculateAgeFromMongoDate, formatStringToNumberV2, generateUniqueCode, validateMotorVehicleRegn } from "../../../utils/genericFunctions.js";
import newRequest from "../../../utils/newRequest.js";
import AddIcon from "@mui/icons-material/Add";
import CalculateIcon from "@mui/icons-material/Calculate";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import QueueIcon from "@mui/icons-material/Queue";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import GetAppIcon from "@mui/icons-material/GetApp";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from "@mui/icons-material/Save";
import SearchIcon from "@mui/icons-material/Search";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  Tooltip,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent
} from "@mui/material";
import Big from "big.js";
import { motion } from "framer-motion";
import moment from "moment";
import { IoClose } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import ConfirmDialog from "../../../components/confirmDialog/confirmDialog.jsx";

const UwPolicyRisk = forwardRef(({ quoteData, refreshQuote, profileLimitValues }, ref) => {
  const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
  const [notifyDialogTitle, setNotifyDialogTitle] = useState("Confirm");
  const [notifyDialogText, setNotifyDialogText] = useState("Text");
  const { currentUser } = useSelector((state) => state.user);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogAction, setConfirmDialogAction] = useState("None");
  const [policyRisks, setPolicyRisks] = useState([]);
  const [selectedPolicyRisk, setSelectedPolicyRisk] = useState({});
  const [policyRiskCovers, setPolicyRiskCovers] = useState([]);
  const [selectedPolicyRiskCover, setSelectedPolicyRiskCover] = useState({});
  const [policyRiskCoverSmi, setPolicyRiskCoverSmi] = useState([]);
  const [selectedPolicyRiskCoverSmi, setSelectedPolicyRiskCoverSmi] = useState(null);
  const [sysCodePage, setSysCodePage] = useState(0);
  const [rowsPerPageSysCode, setRowsPerPageSysCode] = useState(5);
  const [showAddSysCodes, setShowAddSysCodes] = useState(false);
  const [sysCodeSearchTerm, setSysCodeSearchTerm] = useState("");
  const [filteredSysCodesData, setFilteredSysCodesData] = useState([]);
  const [sysCodesData, setSysCodesData] = useState([]);
  const navigate = useNavigate();
  const [showView, setShowView] = useState("multiple");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const [objectToAction, setObjectToAction] = useState({
    object_id: "",
    action_text: "",
    action_invoked: "",
    param1: "",
    param2: "",
    param3: "",
    param4: "",
    param5: "",
  });
  const [showHelp, setShowHelp] = useState(null);
  const parsedFmDate = new Date(quoteData.pl_fm_date);
  // Format the date as "yyyy-MM-dd"
  const formattedFmDate = `${parsedFmDate.getFullYear()}-${(
    parsedFmDate.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${parsedFmDate.getDate().toString().padStart(2, "0")}`;

  const parsedToDate = new Date(quoteData.pl_to_date);
  const formattedToDate = `${parsedToDate.getFullYear()}-${(
    parsedToDate.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${parsedToDate.getDate().toString().padStart(2, "0")}`;

  // Function to get limit_yn value by limit_code
  const getLimitValue = (limitCode) => {
    try {
      // console.log(`getLimitValue profileLimitValues ${JSON.stringify(profileLimitValues, null, 2)}`);
      const limit = profileLimitValues.find(l => l.limit_code === limitCode);
      return limit ? limit.limit_yn : null;
    } catch (error) {
      console.log(error)
    }
  };

  // sub classes
  const [selectedSubClass, setSelectedSubClass] = useState(
    quoteData?.policy_sub_classes[0]
  );

  const fetchSysCode = async (sysType) => {
    try {
      const res = await newRequest.post(
        "/sysCode/findAll",
        {
          user_lang: "en_US",
          sys_org_code: currentUser.usr_org_code,
          sys_type: sysType,
        },
        config
      );
      setSysCodesData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  // close notify dialog
  const handleCloseNotifyDialog = () => {
    setIsNotifyDialogOpen(false);
  };


  const handleClearSysCodeSearchTerm = () => {
    setSysCodeSearchTerm("");
  };

  useEffect(() => {
    try {
      if (["marine_cert"].includes(selectedSubClass.sc_view_xx)) {
        fetchShippingMode();
        fetchPorts();
      }
    } catch (error) {
      console.log(error);
    }
  }, [selectedSubClass]);

  useEffect(() => {
    const filteredResults = sysCodesData.filter((item) =>
      item.sys_name?.toLowerCase().includes(sysCodeSearchTerm.toLowerCase())
    );
    setFilteredSysCodesData(filteredResults);
  }, [sysCodesData, sysCodeSearchTerm]);

  const handleSysCodePageChange = (event, newPage) => {
    setSysCodePage(newPage);
  };

  const handleSysCodeRowsPerPage = (event) => {
    setRowsPerPageSysCode(parseInt(event.target.value, 5));
    setSysCodePage(0);
  };

  const slicedSysCodes = filteredSysCodesData.slice(
    sysCodePage * rowsPerPageSysCode,
    sysCodePage * rowsPerPageSysCode + rowsPerPageSysCode
  );


  const handleConfirm = async () => {
    try {
      if (confirmDialogAction === "deleteAllRisks") {
        // loop delete all risks
        for (const risk of filteredData) {
          const res = await newRequest.delete(`/uwPolicyRisk/del/${risk._id}`, {}, config);
          // console.log("risk delete response " + res.data)
        }
        const res = await newRequest.post("/uwPolicy/refresh", {
          policy_docid: quoteData._id,
          userCode: currentUser.usr_id,
        }, config);

        fetchPolicyRisks(quoteData._id, selectedSubClass._id);
      } else if (confirmDialogAction === "deleteSmi") {
        const smiRes = await newRequest.delete(`/uwPolicyRiskSmi/del/${objectToAction.object_id}`, {}, config);
        // console.log("smi delete response " + smiRes.data)
        refreshQuote();
        fetchSelectedCoverSmi(
          selectedPolicyRiskCover.rc_risk_note_no,
          selectedPolicyRiskCover._id
        );
      } else if (confirmDialogAction === "setDefaultCommRate") {
        const res = await newRequest.post("/uwCoverCommRate/inject", {
          cr_mc_code: objectToAction.param3,
          cr_sc_code: objectToAction.param4,
          cr_cc_code: objectToAction.param5,
          cr_ent_code: quoteData.pl_ins_ent_code,
          cr_aent_code: quoteData.pl_ins_aent_code,
          cr_comm_rate: objectToAction.param1,
          cr_comm_tax_rate: objectToAction.param2
        }, config);
        // console.log(`uwCoverCommRate/inject  ${JSON.stringify(res.data, null, 2)}`);
        if (res.data.status === "success") {
          showToast(`Commission rate ${objectToAction.param1} and tax rate ${objectToAction.param2} successfully set!`, "success");
        } else {
          showToast(`error ${res.data.msg}`, "error");
        }
      }
    } catch (err) {
      console.log(err);
    }
    // Close the dialog
    setIsConfirmDialogOpen(false);
  };

  const handleCancel = () => {
    setIsConfirmDialogOpen(false);
  };


  // editing SMI
  const [selectedSmiForEdit, setSelectedSmiForEdit] = useState(null);
  const handleSmiCloseDialog = () => {
    setSelectedSmiForEdit(null);
  };

  const handleSmiEditCancel = () => {
    setSelectedSmiForEdit(null);
  };

  const handleSmiEditUpdate = async () => {
    try {
      const res = await newRequest.post(
        `/uwPolicyRiskSmi/upd/${selectedSmiForEdit._id}`,
        {
          smi_policy_docid: quoteData._id,
          smi_annual_wage_fc: selectedSmiForEdit.smi_annual_wage_fc,
          smi_wiba_wage_fc: selectedSmiForEdit.smi_wiba_wage_fc,
          smi_desc: selectedSmiForEdit.smi_desc,
          smi_rate: selectedSmiForEdit.smi_rate,
          smi_rate_per: selectedSmiForEdit.smi_rate_per,
          smi_premium_fc: selectedSmiForEdit.smi_premium_fc,
          smi_si_fc: selectedSmiForEdit.smi_si_fc,
          smi_limit_fc: selectedSmiForEdit.smi_limit_fc,
          smi_fcl_value_fc: selectedSmiForEdit.smi_fcl_value_fc,
          smi_qty: selectedSmiForEdit.smi_qty,
          updatedBy: currentUser.usr_id,
        },
        config
      );

      setSelectedSmiForEdit(null);
      // refresh SMI
      fetchSelectedCoverSmi(
        selectedPolicyRiskCover.rc_risk_note_no,
        selectedPolicyRiskCover._id
      );
      // refresh quote
      refreshQuote();
      showToast("Updated!", "success");
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };

  const handleSmiEditChange = (e) => {
    const { name, value } = e.target;
    setSelectedSmiForEdit({
      ...selectedSmiForEdit,
      [name]: value,
    });
  };

  const handleSmiForBlur = (e) => {
    let formattedValue;
    const { name, value } = e.target;
    // console.log(`handleSmiForBlur  ${JSON.stringify(name, null, 2)}`);
    const numberFields = [
      "smi_rate",
      "smi_rate_per",
      "smi_premium_fc",
      "smi_premium_lc",
      "smi_si_fc",
      "smi_si_lc",
      "smi_fcl_value_fc",
      "smi_fcl_value_lc",
      "smi_qty",
      "smi_annual_wage_fc",
      "smi_annual_wage_lc",
      "smi_wiba_wage_fc",
      "smi_wiba_wage_lc",
      "smi_limit_fc",
      "smi_limit_lc",
    ];

    if (numberFields.includes(name)) {
      // console.log(`Its a Number and comma is ther ${value.includes(",")}`);
      if (value.includes(",")) {
        formattedValue = parseFloat(value.replace(/,/g, ""));
      } else {
        formattedValue = parseFloat(value);
      }
    } else {
      formattedValue = value;
    }

    // Update the state with the formatted value
    setSelectedSmiForEdit({
      ...selectedSmiForEdit,
      [name]: formattedValue,
    });
  };

  // product covers
  const [selectedSubClassCover, setSelectedSubClassCover] = useState({});
  const [subClassCovers, setSubClassCovers] = useState([]);
  const fetchSubClassCovers = async () => {
    try {
      const res = await newRequest.post(
        "/uwSubClassCover/findAll",
        {
          cc_org_code: currentUser.usr_org_code,
          cc_mc_code: selectedSubClass?.ps_mc_code,
          cc_sc_code: selectedSubClass?.ps_sc_code,
        },
        config
      );

      const options = res.data.map((cover) => ({
        mc_code: cover.cc_mc_code,
        sc_code: cover.cc_sc_code,
        cc_code: cover.cc_code,
        cc_desc: cover.cc_desc,
        value: cover.cc_code,
        label: cover.cc_name,
      }));
      setSubClassCovers(options);
    } catch (error) {
      console.error("Error fetching product covers :", error);
    }
  };

  // editing cover desc
  const [showCoverForEditing, setShowCoverForEditing] = useState(false);

  const handleCoverEditCancel = () => {
    setShowCoverForEditing(false);
  };

  const handleCoverEditUpdate = async () => {
    try {

      const res = await newRequest.post(
        `/uwPolicyRiskCover/upd/${selectedPolicyRiskCover._id}`,
        { rc_desc: selectedPolicyRiskCover.rc_desc },
        config
      );

      fetchCoversForSelectedRisk(selectedPolicyRisk._id);
      setShowCoverForEditing(false);
      showToast("Updated!", "success");
    } catch (error) {
      console.error("Error fetching entity types:", error);
    }
  };

  useEffect(() => {
    fetchSubClassCovers();
  }, [quoteData._id, selectedSubClass._id]);

  const handleAddCover = async () => {
    try {
      if (selectedSubClassCover?.cc_code && selectedPolicyRisk._id) {
        // save risk details
        await saveRisk();
        const res = await newRequest.post(
          "/uwPolicyRiskCover/add",
          {
            rc_org_code: currentUser.usr_org_code,
            rc_risk_note_no: selectedPolicyRisk.pr_risk_note_no,
            rc_risk_code:
              selectedPolicyRisk.pr_risk_code || quoteData.pl_risk_note_no,
            rc_mc_code: selectedSubClassCover.mc_code,
            rc_sc_code: selectedSubClassCover.sc_code,
            rc_cc_code: selectedSubClassCover.cc_code,
            rc_desc: selectedSubClassCover.cc_desc,
            rc_risk_docid: selectedPolicyRisk._id,
            rc_policy_docid: selectedPolicyRisk.pr_policy_docid,
            rc_end_no: quoteData.pl_end_no,
            userCode: currentUser.usr_id,
          },
          config
        );

        //log the event here
        try {
          const trace_res = await newRequest.post(
            "/trace/add",
            {
              trc_org_code: currentUser.usr_org_code,
              trc_action_on: "Document",
              trc_doc_type: quoteData.pl_type,
              trc_doc_no: quoteData.pl_risk_note_no,
              trc_aent_code: "",
              trc_ent_code: "",
              trc_action: "added cover",
              trc_remarks: `added cover ${selectedSubClassCover.cc_code}`,
              trc_tags: ["added cover"],
              trc_doc_docid: quoteData._id,
              userCode: currentUser.usr_id,
            },
            config
          );
        } catch (error) {
          console.log(error);
        }

        fetchCoversForSelectedRisk(selectedPolicyRisk._id);
        setSelectedSubClassCover(null);
      }
    } catch (error) {
      console.log(error);
    }
  };


  useImperativeHandle(ref, () => ({
    saveRisksAndSmisFromParent
  }));

  const saveRisksAndSmisFromParent = async () => {
    try {
      // save risks also invokes save smi
      saveRisk("N");
    } catch (error) {
      console.log(error)
    }
  }


  const saveSmis = async () => {
    try {

      // console.log(`saveSmis  policyRiskCoverSmi ${JSON.stringify(policyRiskCoverSmi, null, 2)}`);

      for (const [index, obj] of policyRiskCoverSmi.entries()) {
        // Create a JSON object with desired properties
        const jsonData = {
          index: index + 1,
          data: obj,
        };

        try {

          if (obj.flag_xx === "existing") {
            const updatedData = {
              ...jsonData.data,
              userCode: currentUser.usr_id,
            };

            const res = await newRequest.post(
              `/uwPolicyRiskSmi/upd/${obj._id}`,
              updatedData,
              config
            );

            // console.log(`Update Response ${res.data}`)
          } else if (obj.flag_xx === "new" && jsonData.data.smi_desc) {
            const updatedData = {
              ...jsonData.data,
              smi_rate: formatStringToNumberV2(jsonData.data.smi_rate),
              smi_rate_per: formatStringToNumberV2(jsonData.data.smi_rate_per),
              smi_premium_fc: formatStringToNumberV2(jsonData.data.smi_premium_fc),
              smi_si_fc: formatStringToNumberV2(jsonData.data.smi_si_fc),
              smi_fcl_value_fc: formatStringToNumberV2(jsonData.data.smi_fcl_value_fc),
              smi_limit_fc: formatStringToNumberV2(jsonData.data.smi_limit_fc),
              smi_add_to_si: jsonData.data.smi_add_to_si
            };

            // console.log(`new Smi ${JSON.stringify(updatedData, null, 2)}`);
            const res = await newRequest.post(
              "/uwPolicyRiskSmi/add",
              updatedData,
              config
            );
            // console.log(`Insert Response ${res.data}`)
          }

          // Handle the response as needed
        } catch (error) {
          // Handle errors
          console.error(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const calculatePremium = async () => {
    try {
      await saveRisk("N");
      // refresh SMI
      fetchSelectedCoverSmi(
        selectedPolicyRiskCover.rc_risk_note_no,
        selectedPolicyRiskCover._id
      );
      // refresh quote
      await refreshQuote();
      showToast("Premium Calculated!", "success");
    } catch (error) {
      console.log(error)
    }
  };

  const formatNumber = (value, decimals = 2) => {
    if (isNaN(value)) return '';
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals
    }).format(value);
  };

  const goBack = () => {
    navigate(-1);
  };

  const [prRiskNoteNo, setPrRiskNoteNo] = useState("");
  const [prRiskCode, setPrRiskCode] = useState("");
  const [prOrderNo, setPrOrderNo] = useState(1);
  const [prRiskAddress, setPrRiskAddress] = useState("");
  const [prRiskFunction, setPrRiskFunction] = useState(null);
  const [riskFunctions, setRiskFunctions] = useState([]);
  const fetchRiskFunctions = async () => {
    try {
      const res = await newRequest.post(
        "/sysCode/findAll",
        {
          user_lang: "en_US",
          sys_org_code: currentUser.usr_org_code,
          sys_type: "Risk Categories",
        },
        config
      );

      const options = res.data.map((codes) => ({
        risk_function: codes.sys_code,
        value: codes.sys_code,
        label: codes.sys_name,
      }));

      setRiskFunctions(options);
      //set default values
      if (selectedPolicyRisk) {
        const initialSelected = {
          risk_function: selectedPolicyRisk.pr_risk_function,
          value: selectedPolicyRisk.pr_risk_function,
          label: selectedPolicyRisk.risk_function_xx,
        };
        setPrRiskFunction(initialSelected);
      }
    } catch (error) {
      console.error("Error fetching risk functions :", error);
    }
  };

  const [prRiskArea, setPrRiskArea] = useState(null);
  const [riskAreas, setRiskAreas] = useState([]);
  const fetchRiskAreas = async () => {
    try {
      const res = await newRequest.post(
        "/sysCode/findAll",
        {
          user_lang: "en_US",
          sys_org_code: currentUser.usr_org_code,
          sys_type: "Risk Areas",
        },
        config
      );

      const options = res.data.map((codes) => ({
        risk_area: codes.sys_code,
        value: codes.sys_code,
        label: codes.sys_name,
      }));

      setRiskAreas(options);
      //set default values
      if (selectedPolicyRisk) {
        const initialSelected = {
          risk_area: selectedPolicyRisk.pr_risk_area,
          value: selectedPolicyRisk.pr_risk_area,
          label: selectedPolicyRisk.risk_area_xx,
        };
        setPrRiskArea(initialSelected);
      }
    } catch (error) {
      console.error("Error fetching risk areas :", error);
    }
  };

  const handleSelectedRiskArea = async (selectedOption) => {
    try {
      if (selectedOption && selectedOption.__isNew__) {
        const res = await newRequest.post(
          "sysCode/add",
          {
            sys_org_code: currentUser.usr_org_code,
            sys_type: "Risk Areas",
            sys_code: selectedOption.value,
            sys_name: selectedOption.value,
            sys_grouping: "",
            userCode: currentUser.usr_id,
          },
          config
        );
        setPrRiskArea({
          risk_area: selectedOption.value,
          value: selectedOption.value,
          label: selectedOption.value,
        });
        showToast(`${selectedOption.value} added to the list!`, "success");
      } else {
        setPrRiskArea(selectedOption);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [genders, setGenders] = useState([]);
  const fetchGender = async () => {
    try {
      const res = await newRequest.post(
        "/sysCode/findAll",
        {
          user_lang: "en_US",
          sys_org_code: currentUser.usr_org_code,
          sys_type: "Gender",
        },
        config
      );

      const options = res.data.map((codes) => ({
        value: codes.sys_code,
        label: codes.sys_name,
      }));
      setGenders(options);
    } catch (error) {
      console.error("Error fetching risk functions :", error);
    }
  };

  const [prOtherAttributes, setPrOtherAttributes] = useState([]);
  const [prFullName, setPrFullName] = useState("");
  const [prGender, setPrGender] = useState(null);
  const [prPersonDob, setPrPersonDob] = useState(null);

  const handlePersonDob = (e) => {
    try {
      if (e.target.value) {
        const formattedDate = moment(e.target.value).format("YYYY-MM-DD");
        setPrPersonDob(formattedDate);
        setPersonAgeXx(calculateAgeFromMongoDate(formattedDate) + " years");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOtherAttributeChange = (id, newValue) => {
    const updatedAttributes = prOtherAttributes.map((attr) =>
      attr._id === id ? { ...attr, attribute_yn: newValue } : attr
    );
    setPrOtherAttributes(updatedAttributes);
  };

  const fetchOtherAttributes = async () => {
    try {
      const res = await newRequest.post(
        "/uwPolicyRisk/getOtherAttr",
        {
          risk_docid: selectedPolicyRisk._id,
        },
        config
      );
      setPrOtherAttributes(res.data);
    } catch (error) {
      console.error("Error fetching attributes :", error);
    }
  };

  const [personAgeXx, setPersonAgeXx] = useState("");
  const [prPersonId, setPrPersonId] = useState("");
  const [prPersonEmail, setPrPersonEmail] = useState("");
  const [prPersonPhone, setPrPersonPhone] = useState("");
  const [prPersonOccupation, setPrPersonOccupation] = useState(null);

  const handlePersonOccupation = async (selectedOption) => {
    try {
      if (selectedOption && selectedOption.__isNew__) {
        const resModel = await newRequest.post("sysCode/add", {
          sys_org_code: currentUser.usr_org_code,
          sys_type: "Occupations",
          sys_code: selectedOption.value,
          sys_name: selectedOption.value,
          userCode: currentUser.usr_id,
        }, config);

        setPrPersonOccupation({
          value: selectedOption.value,
          label: selectedOption.value,
        });

        showToast(`${selectedOption.value} added to the list!`, "success");
      } else {
        setPrPersonOccupation(selectedOption);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // marine certificate details
  const [marineCertRow, setMarineCertRow] = useState({});
  const [certNo, setCertNo] = useState(null);
  const [originAddress, setOriginAddress] = useState("");
  const [destAddress, setDestAddress] = useState("");
  const [originPort, setOriginPort] = useState(null);
  const [destPort, setDestPort] = useState(null);
  const [vesselNo, setVesselNo] = useState("");
  const [flightNo, setFlightNo] = useState("");
  const [billOfLadingNo, setBillOfLadingNo] = useState("");
  const [billOfLadingDate, setBillOfLadingDate] = useState(null);
  const [anyOneLossLimit, setAnyOneLossLimit] = useState(null);
  const [singleTransitLimit, setSingleTransitLimit] = useState(null);
  const [annualCarryLimit, setAnnualCarryLimit] = useState(null);
  const [estDepatureDate, setEstDepatureDate] = useState(null);
  const [actDepatureDate, setActDepatureDate] = useState(null);
  const [estArrivalDate, setEstArrivalDate] = useState(null);
  const [actArrivalDate, setActArrivalDate] = useState(null);
  const [invoiceNo, setInvoiceNo] = useState("");
  const [transhipAt, setTranshipAt] = useState("");
  const [noOfPackages, setNoOfPackages] = useState("");
  const [marksAndNumber, setMarksAndNumber] = useState("");
  const [idfNumber, setIdfNumber] = useState("");
  const [supplier, setSupplier] = useState("");
  const [clearingAgent, setClearingAgent] = useState("");
  const [goodsDesc, setGoodsDesc] = useState("");
  const [shippingMode, setShippingMode] = useState(null);
  const [shippingModes, setShippingModes] = useState([]);
  const fetchShippingMode = async () => {
    try {
      const res = await newRequest.post(
        "/sysCode/findAll",
        {
          user_lang: "en_US",
          sys_org_code: currentUser.usr_org_code,
          sys_type: "Shipping Mode",
        },
        config
      );
      const options = res.data.map((codes) => ({
        value: codes.sys_code,
        label: codes.sys_name,
      }));

      setShippingModes(options);
    } catch (error) {
      console.error("Error fetching Occupations :", error);
    }
  };

  const [ports, setPorts] = useState([]);
  const fetchPorts = async () => {
    try {
      const res = await newRequest.post(
        "/sysCode/findAll",
        {
          user_lang: "en_US",
          sys_org_code: currentUser.usr_org_code,
          sys_type: "Ports",
        },
        config
      );
      const options = res.data.map((codes) => ({
        value: codes.sys_code,
        label: codes.sys_name,
      }));

      setPorts(options);
    } catch (error) {
      console.error("Error fetching Occupations :", error);
    }
  };

  const handleMarineLimitsBlur = (e) => {
    if (e.target.value) {
      const strippedValue = e.target.value.replace(/,/g, "");
      const parsedValue = Big(strippedValue); // Use big.js to handle the value

      // Check if the parsed value is NaN
      if (!parsedValue.eq(parsedValue)) {
        if (e.target.name === "any_one_loss_limit") {
          setAnyOneLossLimit("");
        } else if (e.target.name === "single_transit_limit") {
          setSingleTransitLimit("");
        } else if (e.target.name === "annual_carry_limit") {
          setAnnualCarryLimit("");
        }
      } else {
        // Format the parsed value with 2 decimal places and digit grouping
        const formattedValue = parsedValue
          .toFixed(2)
          .toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        if (e.target.name === "any_one_loss_limit") {
          setAnyOneLossLimit(formattedValue);
        } else if (e.target.name === "single_transit_limit") {
          setSingleTransitLimit(formattedValue);
        } else if (e.target.name === "annual_carry_limit") {
          setAnnualCarryLimit(formattedValue);
        }
      }
    }
  };

  const handleSelectedOriginPort = async (selectedOption) => {
    try {
      if (selectedOption && selectedOption.__isNew__) {
        const res = await newRequest.post(
          "sysCode/add",
          {
            sys_org_code: currentUser.usr_org_code,
            sys_type: "Ports",
            sys_code: selectedOption.value,
            sys_name: selectedOption.value,
            userCode: currentUser.usr_id,
          },
          config
        );
        setOriginPort({
          value: selectedOption.value,
          label: selectedOption.value,
        });
        // set the destination port as well so that the option appears in the list
        setDestPort({
          value: selectedOption.value,
          label: selectedOption.value,
        });
        showToast(`${selectedOption.value} added to the list!`, "success");
      } else {
        setOriginPort(selectedOption);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectedDestPort = async (selectedOption) => {
    try {
      if (selectedOption && selectedOption.__isNew__) {
        const res = await newRequest.post(
          "sysCode/add",
          {
            sys_org_code: currentUser.usr_org_code,
            sys_type: "Ports",
            sys_code: selectedOption.value,
            sys_name: selectedOption.value,
            userCode: currentUser.usr_id,
          },
          config
        );
        setDestPort({
          value: selectedOption.value,
          label: selectedOption.value,
        });
        showToast(`${selectedOption.value} added to the list!`, "success");
      } else {
        setDestPort(selectedOption);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // end of marine certificate details

  const [occupations, setOccupations] = useState([]);
  const [prMemberNo, setPrMemberNo] = useState(null);
  const [prMemberFm, setPrMemberFm] = useState(null);
  const [prMemberTo, setPrMemberTo] = useState(null);
  const [prMemberStatus, setPrMemberStatus] = useState(null);
  const [prRiskDescription, setPrRiskDescription] = useState("");
  const [prClaimBasis, setPrClaimBasis] = useState([]);
  const [prRiskCategory, setPrRiskCategory] = useState("");
  const [prInspected, setInspected] = useState("N");
  const [prStatus, setPrStatus] = useState("On Cover");
  const [prFmDate, setPrFmDate] = useState(formattedFmDate);
  const [prToDate, setPrToDate] = useState(formattedToDate);
  const [daysDifference, setDaysDifference] = useState(0);
  const [prVehicleRegn, setPrVehicleRegn] = useState("");
  const [prVehicleEngine, setPrVehicleEngine] = useState("");
  const [prVehicleChassis, setPrVehicleChassis] = useState("");
  const [prVehicleYom, setPrVehicleYom] = useState(0);
  const [prVehicleColor, setPrVehicleColor] = useState(null);
  const [vehicleColor, setVehicleColor] = useState([]);

  const fetchOccupations = async () => {
    try {
      const res = await newRequest.post(
        "/sysCode/findAll",
        {
          user_lang: "en_US",
          sys_org_code: currentUser.usr_org_code,
          sys_type: "Occupations",
        },
        config
      );
      const options = res.data.map((codes) => ({
        value: codes.sys_code,
        label: codes.sys_name,
      }));

      setOccupations(options);
    } catch (error) {
      console.error("Error fetching Occupations :", error);
    }
  };

  const fetchVehicleColor = async () => {
    try {
      const res = await newRequest.post(
        "/sysCode/findAll",
        {
          user_lang: "en_US",
          sys_org_code: currentUser.usr_org_code,
          sys_type: "Color",
        },
        config
      );

      const options = res.data.map((codes) => ({
        vehicle_color: codes.sys_code,
        value: codes.sys_code,
        label: codes.sys_name,
      }));

      setVehicleColor(options);
      //set default values
      if (selectedPolicyRisk.pr_vehicle_color) {
        const initialSelectedColor = {
          vehicle_color: selectedPolicyRisk.pr_vehicle_color,
          value: selectedPolicyRisk.pr_vehicle_color,
          label: selectedPolicyRisk.vehicle_color_xx,
        };
        setPrVehicleColor(initialSelectedColor);
      }
    } catch (error) {
      console.error("Error fetching Color :", error);
    }
  };

  const handleSelectedVehicleColor = async (selectedOption) => {
    try {
      if (selectedOption && selectedOption.__isNew__) {
        const res = await newRequest.post(
          "sysCode/add",
          {
            sys_org_code: currentUser.usr_org_code,
            sys_type: "Color",
            sys_code: selectedOption.value,
            sys_name: selectedOption.value,
            sys_grouping: "",
            userCode: currentUser.usr_id,
          },
          config
        );
        setPrVehicleColor({
          vehicle_color: selectedOption.value,
          value: selectedOption.value,
          label: selectedOption.value,
        });
        showToast(`${selectedOption.value} added to the list!`, "success");
      } else {
        setPrVehicleColor(selectedOption);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [memberStatus, setMemberStatus] = useState([]);
  // fetch member status
  const fetchMemberStatus = async () => {
    try {
      const res = await newRequest.post(
        "/sysCode/findGrouped",
        {
          user_lang: "en_US",
          sys_org_code: currentUser.usr_org_code,
          sys_type: "Member Status",
        },
        config
      );
      const options = res.data.map((make) => ({
        value: make.sys_code,
        label: make.sys_name,
      }));

      setMemberStatus(options);
    } catch (error) {
      console.error("Error fetching car member status :", error);
    }
  };

  // vehicle make and model
  const [prVehicleMake, setPrVehicleMake] = useState(null);
  const [prVehicleModel, setPrVehicleModel] = useState(null);
  const [vehicleModels, setVehicleModels] = useState([]);

  const fetchVehicleModels = async () => {
    try {
      const res = await newRequest.post(
        "/sysCode/findGrouped",
        {
          user_lang: "en_US",
          sys_org_code: currentUser.usr_org_code,
          sys_type: "Car Make Model",
        },
        config
      );

      const options = res.data.map((make) => ({
        vehicle_make: make.sys_grouping,
        vehicle_model: make.sys_code,
        value: make.sys_code,
        label: make.sys_name,
      }));

      setVehicleModels(options);
      //set default values
      if (selectedPolicyRisk) {
        const initialSelectedMakeModel = {
          vehicle_make: selectedPolicyRisk.pr_vehicle_make,
          vehicle_model: selectedPolicyRisk.pr_vehicle_model,
          value: selectedPolicyRisk.pr_vehicle_model,
          label: selectedPolicyRisk.vehicle_model_xx,
        };
        setPrVehicleModel(initialSelectedMakeModel);
      }
    } catch (error) {
      console.error("Error fetching car models :", error);
    }
  };

  const handleSelectedVehicleModel = async (selectedOption) => {
    try {
      if (selectedOption && selectedOption.__isNew__) {
        const resModel = await newRequest.post(
          "sysCode/add",
          {
            sys_org_code: currentUser.usr_org_code,
            sys_type: "Car Make Model",
            sys_code: selectedOption.value,
            sys_name: selectedOption.value,
            sys_grouping: "Make " + selectedOption.value,
            userCode: currentUser.usr_id,
          },
          config
        );

        setPrVehicleModel({
          vehicle_make: "Make " + selectedOption.value,
          vehicle_model: selectedOption.value,
          value: selectedOption.value,
          label: selectedOption.value,
        });

        showToast(`${selectedOption.value} added to the list!`, "success");
      } else {
        setPrVehicleModel(selectedOption);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [prVehicleCc, setPrVehicleCc] = useState("");
  const [prVehiclePassangers, setPrVehiclePassangers] = useState(0);
  const [prVehicleTonnage, setPrVehicleTonnage] = useState(0);
  const [prVehicleUse, setPrVehicleUse] = useState(null);
  const [vehicleUse, setVehicleUse] = useState([]);
  const fetchVehicleUse = async () => {
    try {
      const res = await newRequest.post(
        "/sysCode/findAll",
        {
          user_lang: "en_US",
          sys_org_code: currentUser.usr_org_code,
          sys_type: "Vehicle Use",
        },
        config
      );
      const options = res.data.map((codes) => ({
        vehicle_use: codes.sys_code,
        value: codes.sys_code,
        label: codes.sys_name,
      }));

      setVehicleUse(options);
      //set default values
      if (selectedPolicyRisk) {
        const initialSelectedUse = {
          vehicle_use: selectedPolicyRisk.pr_vehicle_use,
          value: selectedPolicyRisk.pr_vehicle_use,
          label: selectedPolicyRisk.vehicle_use_xx,
        };
        setPrVehicleUse(initialSelectedUse);
      }
    } catch (error) {
      console.error("Error fetching car models :", error);
    }
  };

  const [prRemarks, setPrRemarks] = useState("");
  const [prPolicyDocid, setPolicyDocid] = useState("");

  const fetchPolicyRisks = async (policyDocid, subclassDocid) => {
    try {

      setSelectedPolicyRisk({});
      setSelectedPolicyRiskCover({});
      fetchSelectedCoverSmi(null, null);

      const res = await newRequest.post(
        "/uwPolicyRisk/findById",
        {
          pr_org_code: currentUser.usr_org_code,
          pr_policy_docid: policyDocid,
          pr_subclass_docid: subclassDocid,
        },
        config
      );

      setPolicyRisks(res.data);

      // select first risk
      if (res.data.length > 0) {
        setSelectedPolicyRisk(res.data[0]);
        setPrRiskNoteNo(res.data[0].pr_risk_note_no);
        setPrRiskCode(res.data[0].pr_risk_code);
        setPrOrderNo(res.data[0].pr_order_no);
        setPrRiskAddress(res.data[0].pr_risk_address);

        setPrRiskFunction({
          risk_function: res.data[0].pr_risk_function,
          value: res.data[0].pr_risk_function,
          label: res.data[0].risk_function_xx,
        });

        setPrRiskArea({
          risk_area: res.data[0].pr_risk_area,
          value: res.data[0].pr_risk_area,
          label: res.data[0].risk_area_xx,
        });

        setPrRiskDescription(res.data[0].pr_risk_description);
        setPrClaimBasis(res.data[0].pr_claim_basis);
        setPrRiskCategory(res.data[0].pr_risk_category);
        setInspected(res.data[0].pr_inspected);
        setPrStatus(res.data[0].pr_status);
        setPrVehicleRegn(res.data[0].pr_vehicle_regn);
        setPrVehicleEngine(res.data[0].pr_vehicle_engine);
        setPrVehicleChassis(res.data[0].pr_vehicle_chassis);
        setPrVehicleYom(res.data[0].pr_vehicle_yom);
        setPrVehiclePassangers(res.data[0].pr_vehicle_passangers);
        setPrVehicleCc(res.data[0].pr_vehicle_cc);
        setPrRemarks(res.data[0].pr_remarks);
        setPrVehicleTonnage(res.data[0].pr_vehicle_tonnage);

        setPrVehicleColor({
          vehicle_color: res.data[0].pr_vehicle_color,
          value: res.data[0].pr_vehicle_color,
          label: res.data[0].vehicle_color_xx,
        });
        setPrVehicleUse({
          vehicle_use: res.data[0].pr_vehicle_use,
          value: res.data[0].pr_vehicle_use,
          label: res.data[0].vehicle_use_xx,
        });
        setPrVehicleModel({
          vehicle_make: res.data[0].pr_vehicle_make,
          vehicle_model: res.data[0].pr_vehicle_model,
          value: res.data[0].pr_vehicle_model,
          label: res.data[0].vehicle_model_xx,
        });

        if (res.data[0].pr_fm_date) {
          const formattedFmDate = moment(res.data[0].pr_fm_date).format(
            "YYYY-MM-DD"
          );
          setPrFmDate(formattedFmDate);
        }

        if (res.data[0].pr_to_date) {
          const formattedToDate = moment(res.data[0].pr_to_date).format(
            "YYYY-MM-DD"
          );
          setPrToDate(formattedToDate);
        }

        if (res.data[0].pr_fm_date && res.data[0].pr_to_date) {
          calculateDateDifference();
        }

        if (res.data[0].pr_other_attributes) {
          setPrOtherAttributes(res.data[0].pr_other_attributes);
        }

        if (res.data[0].pr_full_name) {
          setPrFullName(res.data[0].pr_full_name);
        }

        if (res.data[0].pr_gender) {
          setPrGender({
            value: res.data[0].pr_gender,
            label: res.data[0].person_gender_xx,
          });
        }

        if (res.data[0].pr_person_dob) {
          const formattedDate = moment(res.data[0].pr_person_dob).format(
            "YYYY-MM-DD"
          );
          setPrPersonDob(formattedDate);
        }

        if (res.data[0].person_age_xx) {
          setPersonAgeXx(res.data[0].person_age_xx);
        }

        if (res.data[0].pr_person_id) {
          setPrPersonId(res.data[0].pr_person_id);
        }

        if (res.data[0].pr_member_no) {
          setPrMemberNo(res.data[0].pr_member_no);
        }

        if (res.data[0].pr_member_fm) {
          const formattedDate = moment(res.data[0].pr_member_fm).format(
            "YYYY-MM-DD"
          );
          setPrMemberFm(formattedDate);
        }

        if (res.data[0].pr_member_to) {
          const formattedDate = moment(res.data[0].pr_member_to).format(
            "YYYY-MM-DD"
          );
          setPrMemberTo(formattedDate);
        }

        if (res.data[0].pr_member_status) {
          setPrMemberStatus({
            value: res.data[0].pr_member_status,
            label: res.data[0].member_status_xx,
          });
        }

        if (res.data[0].pr_person_occupation) {
          setPrPersonOccupation({
            value: res.data[0].pr_person_occupation,
            label: res.data[0].person_occupation_xx,
          });
        }

        if (res.data[0].pr_person_email) {
          setPrPersonEmail(res.data[0].pr_person_email);
        }

        if (res.data[0].pr_person_phone) {
          setPrPersonPhone(res.data[0].pr_person_phone);
        }

        // set marine details

        if (res.data[0].marine_cert_details_xx) {
          setMarineCertRow(res.data[0].marine_cert_details_xx);
          if (res.data[0].marine_cert_details_xx.cert_no) {
            setCertNo(res.data[0].marine_cert_details_xx.cert_no);
          }
          if (res.data[0].marine_cert_details_xx.shipping_mode) {
            setShippingMode({
              value: res.data[0].marine_cert_details_xx.shipping_mode,
              label: res.data[0].marine_cert_details_xx.shipping_mode,
            });
          }
          if (res.data[0].marine_cert_details_xx.origin_address) {
            setOriginAddress(res.data[0].marine_cert_details_xx.origin_address);
          }
          if (res.data[0].marine_cert_details_xx.dest_address) {
            setDestAddress(res.data[0].marine_cert_details_xx.dest_address);
          }
          if (res.data[0].marine_cert_details_xx.origin_port) {
            setOriginPort({
              value: res.data[0].marine_cert_details_xx.origin_port,
              label: res.data[0].marine_cert_details_xx.origin_port_xx,
            });
          }
          if (res.data[0].marine_cert_details_xx.dest_port) {
            setDestPort({
              value: res.data[0].marine_cert_details_xx.dest_port,
              label: res.data[0].marine_cert_details_xx.dest_port_xx,
            });
          }

          if (res.data[0].marine_cert_details_xx.vessel_no) {
            setVesselNo(res.data[0].marine_cert_details_xx.vessel_no);
          }

          if (res.data[0].marine_cert_details_xx.flight_no) {
            setFlightNo(res.data[0].marine_cert_details_xx.flight_no);
          }
          if (res.data[0].marine_cert_details_xx.bill_of_lading_no) {
            setBillOfLadingNo(
              res.data[0].marine_cert_details_xx.bill_of_lading_no
            );
          }

          if (res.data[0].marine_cert_details_xx.bill_of_lading_date) {
            const formattedDate = moment(
              res.data[0].marine_cert_details_xx.bill_of_lading_date
            ).format("YYYY-MM-DD");
            setBillOfLadingDate(formattedDate);
          }

          if (res.data[0].marine_cert_details_xx.any_one_loss_limit) {
            const formattedValue =
              res.data[0].marine_cert_details_xx.any_one_loss_limit
                .toFixed(2)
                .toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
            setAnyOneLossLimit(formattedValue);
          }
          if (res.data[0].marine_cert_details_xx.single_transit_limit) {
            const formattedValue =
              res.data[0].marine_cert_details_xx.single_transit_limit
                .toFixed(2)
                .toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
            setSingleTransitLimit(formattedValue);
          }
          if (res.data[0].marine_cert_details_xx.annual_carry_limit) {
            const formattedValue =
              res.data[0].marine_cert_details_xx.annual_carry_limit
                .toFixed(2)
                .toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
            setAnnualCarryLimit(formattedValue);
          }

          if (res.data[0].marine_cert_details_xx.est_depature_date) {
            const formattedDate = moment(
              res.data[0].marine_cert_details_xx.est_depature_date
            ).format("YYYY-MM-DD");
            setEstDepatureDate(formattedDate);
          }

          if (res.data[0].marine_cert_details_xx.act_depature_date) {
            const formattedDate = moment(
              res.data[0].marine_cert_details_xx.act_depature_date
            ).format("YYYY-MM-DD");
            setActDepatureDate(formattedDate);
          }

          if (res.data[0].marine_cert_details_xx.est_arrival_date) {
            const formattedDate = moment(
              res.data[0].marine_cert_details_xx.est_arrival_date
            ).format("YYYY-MM-DD");
            setEstArrivalDate(formattedDate);
          }

          if (res.data[0].marine_cert_details_xx.act_arrival_date) {
            const formattedDate = moment(
              res.data[0].marine_cert_details_xx.act_arrival_date
            ).format("YYYY-MM-DD");
            setActArrivalDate(formattedDate);
          }
          if (res.data[0].marine_cert_details_xx.invoice_no) {
            setInvoiceNo(res.data[0].marine_cert_details_xx.invoice_no);
          }

          if (res.data[0].marine_cert_details_xx.tranship_at) {
            setTranshipAt(res.data[0].marine_cert_details_xx.tranship_at);
          }

          if (res.data[0].marine_cert_details_xx.no_of_packages) {
            setNoOfPackages(res.data[0].marine_cert_details_xx.no_of_packages);
          }

          if (res.data[0].marine_cert_details_xx.marks_and_number) {
            setMarksAndNumber(
              res.data[0].marine_cert_details_xx.marks_and_number
            );
          }

          if (res.data[0].marine_cert_details_xx.idf_number) {
            setIdfNumber(res.data[0].marine_cert_details_xx.idf_number);
          }

          if (res.data[0].marine_cert_details_xx.supplier) {
            setSupplier(res.data[0].marine_cert_details_xx.supplier);
          }

          if (res.data[0].marine_cert_details_xx.clearing_agent) {
            setClearingAgent(res.data[0].marine_cert_details_xx.clearing_agent);
          }

          if (res.data[0].marine_cert_details_xx.goods_desc) {
            setGoodsDesc(res.data[0].marine_cert_details_xx.goods_desc);
          }
        }

        // if (res.data.length === 1) {
        //   setShowView("single");
        // } else {
        //   setShowView("multiple");
        // }

        // end of marine details

        //get risk covers
        fetchCoversForSelectedRisk(res.data[0]._id);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchCoversForSelectedRisk = async (riskDocId) => {
    try {
      const res = await newRequest.post(
        "/uwPolicyRiskCover/findById",
        {
          rc_org_code: currentUser.usr_org_code,
          rc_policy_docid: quoteData._id,
          rc_risk_docid: riskDocId,
        },
        config
      );

      setPolicyRiskCovers(res.data);
      // console.log(`setPolicyRiskCovers  ${JSON.stringify(res.data, null, 2)}`);
      if (res.data.length > 0) {
        // set selected risk cover
        setSelectedPolicyRiskCover(res.data[0]);
        if (res.data[0].rc_risk_note_no) {
          // fetch smi for the selected cover
          fetchSelectedCoverSmi(res.data[0].rc_risk_note_no, res.data[0]._id);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchSelectedCoverSmi = async (riskNoteNo, coverDocId) => {
    try {
      const res = await newRequest.post(
        "/uwPolicyRiskSmi/findById",
        {
          smi_org_code: currentUser.usr_org_code,
          smi_risk_note_no: riskNoteNo,
          smi_cover_docid: coverDocId,
        },
        config
      );
      const formattedData = res.data.map(item => ({
        ...item,
        smi_si_fc: formatNumber(item.smi_si_fc),
        smi_si_lc: formatNumber(item.smi_si_lc),
        smi_rate: formatNumber(item.smi_rate, 4),
        smi_rate_per: formatNumber(item.smi_rate_per),
        smi_premium_fc: formatNumber(item.smi_premium_fc),
        smi_premium_lc: formatNumber(item.smi_premium_lc),
        smi_limit_fc: formatNumber(item.smi_limit_fc),
        smi_limit_lc: formatNumber(item.smi_limit_lc),
        smi_fcl_value_fc: formatNumber(item.smi_fcl_value_fc),
        smi_fcl_value_lc: formatNumber(item.smi_fcl_value_lc),
      }));
      setPolicyRiskCoverSmi(formattedData);
      // setPolicyRiskCoverSmi(res.data);
      if (res.data.length > 0) {
        // set selected risk cover
        setSelectedPolicyRiskCoverSmi(res.data[0]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  //for multi risks
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleClearSearch = () => {
    setSearchTerm("");
  };
  useEffect(() => {
    const filteredResults = policyRisks.filter(
      (item) =>
        item.pr_risk_description
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        item.pr_vehicle_regn
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        item.pr_vehicle_engine
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        item.pr_vehicle_chassis
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        item.vehicle_make_xx
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        item.vehicle_model_xx
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        item.vehicle_use_xx?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filteredResults);
  }, [policyRisks, searchTerm]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const slicedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  useEffect(() => {
    if (quoteData._id !== "new") {
      fetchPolicyRisks(quoteData?._id, selectedSubClass?._id);
    }
    fetchVehicleModels();
    fetchVehicleUse();
    fetchVehicleColor();
    fetchRiskFunctions();
    fetchRiskAreas();
    fetchGender();
    fetchMemberStatus();
    fetchOccupations();
  }, [quoteData?._id, selectedSubClass?._id]);

  const selectRisk = (risk) => {
    try {
      // reset selected risk cover and smi
      setSelectedPolicyRisk({});
      setSelectedPolicyRiskCover({});
      fetchSelectedCoverSmi(null, null);
      setSelectedPolicyRisk(risk);
      setPrRiskNoteNo(risk.pr_risk_note_no);
      setPrRiskCode(risk.pr_risk_code);
      setPrOrderNo(risk.pr_order_no);
      setPrRiskAddress(risk.pr_risk_address);
      setPrRiskFunction(risk.pr_risk_function);
      setPrRiskDescription(risk.pr_risk_description);
      setPrClaimBasis(risk.pr_claim_basis);
      setPrRiskCategory(risk.pr_risk_category);
      setInspected(risk.pr_inspected);
      setPrStatus(risk.pr_status);
      setPrVehicleRegn(risk.pr_vehicle_regn);
      setPrVehicleEngine(risk.pr_vehicle_engine);
      setPrVehicleChassis(risk.pr_vehicle_chassis);
      setPrVehicleYom(risk.pr_vehicle_yom);
      setPrVehiclePassangers(risk.pr_vehicle_passangers);
      setPrVehicleCc(risk.pr_vehicle_cc);
      setPrRemarks(risk.pr_remarks);
      setPrVehicleTonnage(risk.pr_vehicle_tonnage);
      setPrVehicleColor({
        vehicle_color: risk.pr_vehicle_color,
        value: risk.pr_vehicle_color,
        label: risk.vehicle_color_xx,
      });
      setPrVehicleUse({
        vehicle_use: risk.pr_vehicle_use,
        value: risk.pr_vehicle_use,
        label: risk.vehicle_use_xx,
      });

      setPrVehicleModel({
        vehicle_make: risk.pr_vehicle_make,
        vehicle_model: risk.pr_vehicle_model,
        value: risk.pr_vehicle_model,
        label: risk.vehicle_model_xx,
      });

      setPrRiskFunction({
        risk_function: risk.pr_risk_function,
        value: risk.pr_risk_function,
        label: risk.risk_function_xx,
      });

      setPrRiskArea({
        risk_area: risk.pr_risk_area,
        value: risk.pr_risk_area,
        label: risk.risk_area_xx,
      });

      if (risk?.pr_other_attributes) {
        setPrOtherAttributes(risk?.pr_other_attributes);
      }

      if (risk?.pr_full_name) {
        setPrFullName(risk?.pr_full_name);
      }

      if (risk?.pr_gender) {
        setPrGender({
          value: risk?.pr_gender,
          label: risk?.person_gender_xx,
        });
      }

      if (risk?.pr_person_dob) {
        const formattedDate = moment(risk?.pr_person_dob).format("YYYY-MM-DD");
        setPrPersonDob(formattedDate);
      }

      if (risk?.person_age_xx) {
        setPersonAgeXx(risk?.person_age_xx);
      }

      if (risk?.pr_person_id) {
        setPrPersonId(risk?.pr_person_id);
      }

      if (risk?.pr_member_no) {
        setPrMemberNo(risk?.pr_member_no);
      }

      if (risk?.pr_member_fm) {
        const formattedDate = moment(risk?.pr_member_fm).format("YYYY-MM-DD");
        setPrMemberFm(formattedDate);
      }

      if (risk?.pr_member_to) {
        const formattedDate = moment(risk?.pr_member_to).format("YYYY-MM-DD");
        setPrMemberTo(formattedDate);
      }

      if (risk?.pr_member_status) {
        setPrMemberStatus({
          value: risk?.pr_member_status,
          label: risk?.member_status_xx,
        });
      }

      if (risk?.pr_person_occupation) {
        setPrPersonOccupation({
          value: risk?.pr_person_occupation,
          label: risk?.person_occupation_xx,
        });
      }

      if (risk?.pr_person_email) {
        setPrPersonEmail(risk?.pr_person_email);
      }

      if (risk?.pr_person_phone) {
        setPrPersonPhone(risk?.pr_person_phone);
      }

      // fetch covers associated with the risk
      fetchCoversForSelectedRisk(risk._id);

      setShowView("single");
    } catch (error) {
      console.log(error);
    }
  };
  const addNewRisk = () => {
    try {
      setSelectedPolicyRisk({});
      setSelectedPolicyRiskCover({});
      // refreshing smi
      fetchSelectedCoverSmi(null, null);
      setPrRiskNoteNo("");
      setPrRiskCode("");
      setPrOrderNo("");
      setPrRiskAddress("");
      setPrRiskFunction("");
      setPrRiskDescription("");
      setPrClaimBasis([]);
      setPrRiskCategory("");
      setInspected("N");
      setPrStatus("On Cover");
      setPrVehicleRegn("");
      setPrVehicleEngine("");
      setPrVehicleChassis("");
      setPrVehicleYom();
      setPrVehiclePassangers(0);
      setPrVehicleCc(0);
      setPrRemarks("");
      setPrVehicleTonnage(0);

      setPrVehicleColor({
        vehicle_color: null,
        value: null,
        label: null,
      });
      setPrVehicleUse({
        vehicle_use: null,
        value: null,
        label: null,
      });
      setPrVehicleModel({
        vehicle_make: null,
        vehicle_model: null,
        value: null,
        label: null,
      });
      setPrRiskFunction({
        risk_function: null,
        value: null,
        label: null,
      });
      setPrRiskArea({
        risk_area: null,
        value: null,
        label: null,
      });

      setPrOtherAttributes([]);
      setPrFullName("");
      setPrGender({
        value: "New",
        label: "New",
      });
      setPrMemberStatus({
        value: null,
        label: null,
      });
      setPrPersonOccupation({
        value: null,
        label: null,
      });

      setPrPersonEmail("");
      setPrPersonPhone("");
      setPrPersonDob("");
      setPersonAgeXx("");
      setPrPersonId("");
      setPrMemberNo("");
      setPrMemberFm("");
      setPrMemberTo("");

      setShowView("single");
    } catch (error) {
      console.log(error);
    }
  };

  const saveRisk = async (show = "Y") => {
    // e.preventDefault();
    try {
      // console.log("saveRisk called...")

      const changedCovers = policyRiskCovers?.filter(
        (obj) => obj.changed_xx === "Y"
      );
      for (let i = 0; i < changedCovers.length; i++) {
        const res = await newRequest.post(
          `/uwPolicyRiskCover/upd/${changedCovers[i]._id}`,
          {
            rc_comm_perc: changedCovers[i].rc_comm_perc,
            rc_comm_tax_perc: changedCovers[i].rc_comm_tax_perc,
            userCode: currentUser.usr_id,
          },
          config
        );
      }


      //prepare risk data
      const uwPolicyRisk = {
        pr_org_code: currentUser.usr_org_code,
        pr_risk_note_no: quoteData.pl_risk_note_no,
        pr_risk_code: prVehicleRegn || Date.now().toString(),
        pr_order_no: 1,
        pr_risk_address: prRiskAddress,
        pr_risk_function: prRiskFunction?.risk_function,
        pr_risk_area: prRiskArea?.risk_area,
        pr_risk_description: prRiskDescription,
        pr_claim_basis: [],
        pr_risk_category: "",
        pr_status: prStatus,
        pr_fm_date: prFmDate,
        pr_to_date: prToDate,
        pr_vehicle_regn: prVehicleRegn,
        pr_vehicle_engine: prVehicleEngine,
        pr_vehicle_chassis: prVehicleChassis,
        pr_vehicle_yom: prVehicleYom,
        pr_vehicle_color: prVehicleColor?.vehicle_color,
        pr_vehicle_make: prVehicleModel?.vehicle_make,
        pr_vehicle_model: prVehicleModel?.vehicle_model,
        pr_vehicle_cc: prVehicleCc || 0,
        pr_vehicle_passangers: prVehiclePassangers || 0,
        pr_vehicle_tonnage: 0,
        pr_vehicle_use: prVehicleUse?.vehicle_use,
        pr_remarks: prRemarks,
        pr_policy_docid: quoteData._id,
        pr_subclass_docid: selectedSubClass._id,
        pr_end_no: quoteData.pl_end_no,
        pr_full_name: prFullName,
        pr_gender: prGender?.value,
        pr_person_dob: prPersonDob,
        pr_person_id: prPersonId,
        pr_member_no: prMemberNo,
        pr_member_fm: prMemberFm || prFmDate,
        pr_member_to: prMemberTo || prToDate,
        pr_member_status: prMemberStatus?.value || "New",
        pr_other_attributes: prOtherAttributes,
        pr_person_email: prPersonEmail,
        pr_person_phone: prPersonPhone,
        pr_person_occupation: prPersonOccupation?.value,
      };

      const isIdEmptyOrNull = !selectedPolicyRisk._id;

      if (isIdEmptyOrNull) {
        // add risk
        // console.log(`risk not saved....adding`);
        const updatedPolicyRisk = {
          ...uwPolicyRisk,
          createdBy: currentUser.usr_id,
        };

        const riskResponse = await newRequest.post(
          "/uwPolicyRisk/add",
          updatedPolicyRisk,
          config
        );

        fetchPolicyRisks(quoteData._id, selectedSubClass._id);
        selectRisk(riskResponse);
        if (show === "Y") {
          showToast("Risk Added!", "success");
        }
      } else {
        // update risk
        const updatedPolicyRisk = {
          ...uwPolicyRisk,
          updatedBy: currentUser.usr_id,
        };

        const riskResponse = await newRequest.put(
          `/uwPolicyRisk/upd/${selectedPolicyRisk._id}`,
          updatedPolicyRisk,
          config
        );

        if (["marine_cert"].includes(selectedSubClass.sc_view_xx)) {
          // prepare marine data if theres a certificate
          const marineCertificateDetails = {
            risk_docid: selectedPolicyRisk._id,
            cert_no: certNo,
            shipping_mode: shippingMode?.value,
            origin_address: originAddress,
            dest_address: destAddress,
            origin_port: originPort?.value,
            dest_port: destPort?.value,
            vessel_no: vesselNo,
            flight_no: flightNo,
            bill_of_lading_no: billOfLadingNo,
            bill_of_lading_date: billOfLadingDate,
            any_one_loss_limit: anyOneLossLimit,
            single_transit_limit: singleTransitLimit,
            annual_carry_limit: annualCarryLimit,
            est_depature_date: estDepatureDate,
            act_depature_date: actDepatureDate,
            est_arrival_date: estArrivalDate,
            act_arrival_date: actArrivalDate,
            invoice_no: invoiceNo,
            tranship_at: transhipAt,
            no_of_packages: noOfPackages,
            marks_and_number: marksAndNumber,
            idf_number: idfNumber,
            supplier: supplier,
            clearing_agent: clearingAgent,
            goods_desc: goodsDesc,
          };
          const marinRes = await newRequest.post(
            "/uwPolicyRisk/updMarineCert",
            marineCertificateDetails,
            config
          );
          // console.log(`marinRes ${JSON.stringify(marinRes.data, null, 2)}`);
        }

        await saveSmis();
        if (show === "Y") {
          showToast("Risk Updated!", "success");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  //deal with dates
  const handleFromDateChange = (e) => {
    try {
      if (e.target.value) {
        const formattedFromDate = moment(e.target.value).format("YYYY-MM-DD");
        setPrFmDate(formattedFromDate);
        calculateDateDifference();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleToDateChange = (e) => {
    try {
      if (e.target.value) {
        const formattedToDate = moment(e.target.value).format("YYYY-MM-DD");
        setPrToDate(formattedToDate);
        calculateDateDifference();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const calculateDateDifference = () => {
    if (prFmDate && prToDate) {
      const fromDate = moment(prFmDate, "YYYY-MM-DD");
      const toDate = moment(prToDate, "YYYY-MM-DD");
      const differenceInDays = toDate.diff(fromDate, "days");
      //console.log(`Difference in days: ${differenceInDays}`);
      setDaysDifference(differenceInDays + 1);
      // Now you can use the differenceInDays variable as needed.
    }
  };

  const [smiPage, setSmiPage] = useState(0);
  const [smiRowsPerPage, setSmiRowsPerPage] = useState(5);
  const handleSmiChangePage = (event, newPage) => {
    setSmiPage(newPage);
  };

  const handleSmiRowsPerPage = (event) => {
    try {
      setSmiRowsPerPage(parseInt(event.target.value, 5));
      setSmiPage(0);
    } catch (error) {
      console.log(error)
    }
  };

  const handleSmiInputChange = (e, id, field) => {
    try {
      const newSmiData = [...policyRiskCoverSmi];
      const index = newSmiData.findIndex(smi => smi._id === id);
      if (index !== -1) {
        newSmiData[index][field] = e.target.value;
        setPolicyRiskCoverSmi(newSmiData);
      }
    } catch (error) {
      console.log(error);
    }
  };


  const handleCoverInputChange = (e, id, field) => {
    try {
      const newCoverData = [...policyRiskCovers];
      const index = newCoverData.findIndex(cover => cover._id === id);
      if (index !== -1) {
        newCoverData[index][field] = e.target.value;
        newCoverData[index]["changed_xx"] = "Y";

        if (field === "rc_comm_perc" && formatNumber(e.target.value) === 0) {
          newCoverData[index]["rc_comm_tax_perc"] = 0;
        }

        setPolicyRiskCovers(newCoverData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCoverSetCommRate = (rowId, commRate, commTaxRate) => {
    try {
      const newCoverData = [...policyRiskCovers];
      const index = newCoverData.findIndex(cover => cover._id === rowId);
      if (index !== -1) {
        newCoverData[index]["rc_comm_perc"] = commRate;
        newCoverData[index]["rc_comm_tax_perc"] = commTaxRate;
        newCoverData[index]["changed_xx"] = "Y";
        setPolicyRiskCovers(newCoverData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCoverBlur = (id, field) => {
    try {
      const updatedData = [...policyRiskCovers];
      const index = updatedData.findIndex(cover => cover._id === id);
      if (index !== -1) {
        const value = updatedData[index][field];
        const numberFields = [
          "rc_comm_perc", "rc_comm_tax_perc"
        ];

        if (numberFields.includes(field)) {
          updatedData[index][field] = formatNumber(value);
        } else {
          updatedData[index][field] = value;
        }

        setPolicyRiskCovers(updatedData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckboxChange = (e, id) => {
    try {
      const newSmiData = [...policyRiskCoverSmi];
      const index = newSmiData.findIndex(smi => smi._id === id);
      if (index !== -1) {
        newSmiData[index].smi_add_to_si = e.target.checked ? 'Y' : 'N';
        setPolicyRiskCoverSmi(newSmiData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSmiBlur = (id, field) => {
    try {
      const updatedData = [...policyRiskCoverSmi];
      const index = updatedData.findIndex(smi => smi._id === id);
      if (index !== -1) {
        const value = updatedData[index][field];
        const numberFields = [
          "smi_rate",
          "smi_rate_per",
          "smi_premium_fc",
          "smi_premium_lc",
          "smi_si_fc",
          "smi_si_lc",
          "smi_fcl_value_fc",
          "smi_fcl_value_lc",
          "smi_qty",
          "smi_annual_wage_fc",
          "smi_annual_wage_lc",
          "smi_wiba_wage_fc",
          "smi_wiba_wage_lc",
          "smi_limit_fc",
          "smi_limit_lc"
        ];

        if (numberFields.includes(field)) {
          // set the number of decimals for rate 
          updatedData[index][field] = formatNumber(value, ["smi_rate"].includes(field) ? 4 : 2);
        } else {
          updatedData[index][field] = value;
        }
        setPolicyRiskCoverSmi(updatedData);
      }
    } catch (error) {
      console.log(error);
    }
  };


  const addNewSmiRow = () => {
    try {
      const smiCode = generateUniqueCode();
      const defaultSmiRow = {
        sr_no: '',
        smi_org_code: currentUser.usr_org_code,
        smi_risk_note_no: quoteData.pl_risk_note_no,
        smi_pr_code: quoteData.pl_product_code,
        smi_mc_code: selectedPolicyRiskCover.rc_mc_code,
        smi_sc_code: selectedPolicyRiskCover.rc_sc_code,
        smi_cc_code: selectedPolicyRiskCover.rc_cc_code,
        smi_policy_docid: selectedPolicyRiskCover.rc_policy_docid,
        smi_risk_docid: selectedPolicyRiskCover.rc_risk_docid,
        smi_cover_docid: selectedPolicyRiskCover._id,
        smi_code: smiCode,
        smi_desc: '',
        smi_rate: 0,
        smi_rate_per: 100,
        smi_premium_fc: 0,
        smi_premium_lc: 0,
        smi_si_fc: 0,
        smi_si_lc: 0,
        smi_fcl_value_fc: 0,
        smi_fcl_value_lc: 0,
        smi_qty: 0,
        smi_annual_wage_fc: 0,
        smi_annual_wage_lc: 0,
        smi_wiba_wage_fc: 0,
        smi_wiba_wage_lc: 0,
        smi_limit_fc: 0,
        smi_limit_lc: 0,
        smi_end_no: quoteData.pl_end_no,
        smi_add_to_si: "Y",
        calc_premium_fc: 0,
        calc_premium_lc: 0,
        calc_si_fc: 0,
        calc_si_lc: 0,
        flag_xx: "new",
        createdBy: currentUser.usr_id,
      };
      setPolicyRiskCoverSmi([...policyRiskCoverSmi, defaultSmiRow]);
    } catch (error) {
      console.log(error)
    }
  };


  const handleNoteClick = (text) => {
    console.log(`${text} clicked`);
    // Add your function logic here
  };

  const renderNote = (note) => {
    const parts = note.split(/(SUM INSURED|RATE|PREMIUM|RATE|FREE COVER LIMIT)/);
    return parts.map((part, index) => {
      if (["SUM INSURED", "RATE", "PREMIUM", "RATE", "FREE COVER LIMIT"].includes(part)) {
        return (
          <a href="#" key={index} onClick={() => handleNoteClick(part)} style={{ cursor: 'pointer', textDecoration: "none", color: "inherit" }}>
            {part}
          </a>
        );
      }
      return part;
    });
  };

  const notes = [
    `The SUM INSURED entered should be in ${quoteData?.pl_cur_code}`,
    'When RATE is entered, premium field is overridden. Premium will be calculated based on SUM INSURED and RATE',
    'If you enter both RATE & PREMIUM, premium will be calculated based on SUM INSURED and RATE',
    'You can directly enter PREMIUM for each item but the RATE field should be zero otherwise entered RATE will be used',
    'For items with FREE COVER LIMIT, the rate will be applied on the SUM INSURED minus FREE COVER LIMIT value'
  ];

  return (
    <div className="uw-policy-risk">

      {/* {quoteData.appl_sub_classes_count > 0 && (
        <div className="multi-sc-wrapper">
          {quoteData.policy_sub_classes.map((sc) => (
            <div key={sc._id}>
              <CustomButtonSmall
                onClick={() => {
                  try {
                    setSelectedSubClass(sc);
                    fetchPolicyRisks(quoteData._id, sc._id);
                  } catch (error) {
                    console.log(error);
                  }
                }}
                currentButton={
                  selectedSubClass.ps_mc_code === sc.ps_mc_code &&
                    selectedSubClass.ps_sc_code === sc.ps_sc_code
                    ? true
                    : false
                }
              >
                {sc.sc_name_xx} {sc.sc_view_xx}
              </CustomButtonSmall>
            </div>
          ))}

          {quoteData.document_enabled_xx === "Y" && (
            <CustomButtonSmall
              onClick={addNewRisk}
              startIcon={<AddIcon />}
              currentButton={false}
            >
              {`Add Section View ${selectedSubClass.sc_view_xx}`}
            </CustomButtonSmall>
          )}
          <span>{selectedSubClass?._id}</span>
        </div>
      )} */}
      {/* display for motor policies where risk is more than one */}
      {showView === "multiple" && selectedSubClass && (
        <motion.div
          className="motor-multiple"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <div className="navbar">
            <div className="navbar-left">
              <h1 className='main-title'>PARTICULARS</h1>
              {/* Show search only if there are more than 5 risks */}
              {policyRisks?.length > 5 &&
                <div className="search-input-wrapper">
                  <SearchIcon className="search-input-icon" />
                  <input
                    className="search-input"
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  {searchTerm.length > 0 && (
                    <CloseIcon
                      onClick={handleClearSearch}
                      className="search-input-icon"
                    />
                  )}
                </div>}
            </div>
            <div className="navbar-right">
              <div className="inline-buttons-wrapper">
                <CustomButtonSmall
                  startIcon={<RefreshIcon size={20} />}
                  onClick={() => {
                    try {
                      fetchPolicyRisks(quoteData._id, selectedSubClass._id);
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                >
                  Refresh
                </CustomButtonSmall>
                {quoteData.document_enabled_xx === "Y" && <CustomButtonSmall
                  startIcon={<GetAppIcon />}
                  onClick={() => {
                    try {
                      const subClassToPass = {
                        ...selectedSubClass,
                        ps_gl_date: quoteData.pl_gl_date,
                        ps_fm_date: quoteData.pl_fm_date,
                        ps_to_date: quoteData.pl_to_date,
                        pr_code: quoteData.pl_product_code,
                      };
                      navigate(`/vehicleImport`, {
                        state: subClassToPass,
                      });
                    } catch (error) {
                      console.log(error)
                    }
                  }}
                >
                  Import
                </CustomButtonSmall>}
                {quoteData.document_enabled_xx === "Y" && (
                  <CustomButtonSmall
                    startIcon={<AddIcon />}
                    onClick={addNewRisk}
                    currentButton={false}
                  >
                    Add
                  </CustomButtonSmall>
                )}
                {quoteData.document_enabled_xx === "Y" && (
                  <CustomButtonSmall
                    startIcon={<ClearIcon />}
                    onClick={() => {
                      try {
                        let action_text = `Delete all risks?`;
                        setObjectToAction({
                          object_id: quoteData._id,
                          action_text: action_text,
                          new_doc_status: "deleteAllRisks",
                        });
                        setConfirmDialogAction("deleteAllRisks");
                        setIsConfirmDialogOpen(true);
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                    currentButton={false}
                  >
                    Delete All
                  </CustomButtonSmall>
                )}
              </div>
            </div>
          </div>
          <div className="table-content">
            <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none' }}
              className="table-container">
              <Table sx={{ width: "max-content", border: 'none' }}>
                <TableHead className="tablehead">
                  <TableRow
                    className="tablerow"
                  >
                    <TableCell
                      className="table-cell"
                      style={{
                        display: "flex",
                        maxWidth: "50px",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: "bold",
                      }}
                    >
                      <span>#</span>
                    </TableCell>
                    <TableCell
                      className="table-cell"
                      style={{
                        fontWeight: "500",
                        padding: "8px",
                        maxWidth: "200px",
                        display: ["motor"].includes(selectedSubClass?.sc_view_xx)
                          ? "table-cell"
                          : "none",
                      }}
                    >
                      <span>REGN NO</span>
                    </TableCell>
                    <TableCell
                      className="table-cell"
                      style={{
                        fontWeight: "500",
                        padding: "8px",
                        display: ["motor"].includes(selectedSubClass?.sc_view_xx)
                          ? "table-cell"
                          : "none",
                      }}
                    >
                      <span>MODEL</span>
                    </TableCell>
                    <TableCell
                      className="table-cell"
                      style={{
                        fontWeight: "500",
                        padding: "8px",
                        display: ["motor"].includes(selectedSubClass?.sc_view_xx)
                          ? "table-cell"
                          : "none",
                      }}
                    >
                      <span>USE</span>
                    </TableCell>
                    <TableCell
                      className="table-cell"
                      style={{
                        fontWeight: "500",
                        padding: "8px",
                        display: ["motor"].includes(selectedSubClass?.sc_view_xx)
                          ? "table-cell"
                          : "none",
                      }}
                    >
                      <span>ENGINE</span>
                    </TableCell>
                    <TableCell
                      className="table-cell"
                      style={{
                        fontWeight: "500",
                        padding: "8px",
                        display: ["motor"].includes(selectedSubClass?.sc_view_xx)
                          ? "table-cell"
                          : "none",
                      }}
                    >
                      <span>CHASSIS</span>
                    </TableCell>
                    <TableCell
                      className="table-cell"
                      style={{
                        fontWeight: "500",
                        padding: "8px",
                        display: ["motor"].includes(selectedSubClass?.sc_view_xx)
                          ? "table-cell"
                          : "none",
                      }}
                    >
                      <span>YOM</span>
                    </TableCell>
                    <TableCell
                      className="table-cell"
                      style={{
                        fontWeight: "500",
                        padding: "8px",
                        display: ["motor"].includes(selectedSubClass?.sc_view_xx)
                          ? "table-cell"
                          : "none",
                      }}
                    >
                      <span>PASSANGERS</span>
                    </TableCell>
                    <TableCell
                      className="table-cell"
                      style={{
                        fontWeight: "500",
                        padding: "8px",
                        display: ["fire", "engineering", "default"].includes(
                          selectedSubClass?.sc_view_xx
                        )
                          ? "table-cell"
                          : "none",
                      }}
                    >
                      <span>DESCRIPTION</span>
                    </TableCell>
                    <TableCell
                      className="table-cell"
                      style={{
                        fontWeight: "500",
                        padding: "8px",
                        display: ["fire", "engineering", "default"].includes(
                          selectedSubClass?.sc_view_xx
                        )
                          ? "table-cell"
                          : "none",
                      }}
                    >
                      <span>ADDRESS</span>
                    </TableCell>
                    {/* <TableCell
                      className="table-cell"
                      style={{
                        fontWeight: "500",
                        padding: "8px",
                        display: ["fire", "engineering", "default"].includes(
                          selectedSubClass?.sc_view_xx
                        )
                          ? "table-cell"
                          : "none",
                      }}
                    >
                      <span>FUNCTION</span>
                    </TableCell>
                    <TableCell
                      className="table-cell"
                      style={{
                        fontWeight: "500",
                        padding: "8px",
                        display: ["fire", "engineering", "default"].includes(
                          selectedSubClass?.sc_view_xx
                        )
                          ? "table-cell"
                          : "none",
                      }}
                    >
                      <span>CATEGORY</span>
                    </TableCell> */}
                    <TableCell
                      className="table-cell"
                      style={{
                        fontWeight: "500",
                        padding: "8px",
                        display: ["people", "medical"].includes(
                          selectedSubClass?.sc_view_xx
                        )
                          ? "table-cell"
                          : "none",
                      }}
                    >
                      <span>FULL NAME</span>
                    </TableCell>
                    <TableCell
                      className="table-cell"
                      style={{
                        fontWeight: "500",
                        padding: "8px",
                        display: ["people", "medical"].includes(
                          selectedSubClass?.sc_view_xx
                        )
                          ? "table-cell"
                          : "none",
                      }}
                    >
                      <span>GENDER</span>
                    </TableCell>
                    <TableCell
                      className="table-cell"
                      style={{
                        fontWeight: "500",
                        padding: "8px",
                        display: ["people", "medical"].includes(
                          selectedSubClass?.sc_view_xx
                        )
                          ? "table-cell"
                          : "none",
                      }}
                    >
                      <span>AGE</span>
                    </TableCell>
                    <TableCell
                      className="table-cell"
                      style={{
                        fontWeight: "500",
                        padding: "8px",
                        display: ["people", "medical"].includes(
                          selectedSubClass?.sc_view_xx
                        )
                          ? "table-cell"
                          : "none",
                      }}
                    >
                      <span>OCCUPATION</span>
                    </TableCell>
                    <TableCell
                      className="table-cell"
                      style={{
                        fontWeight: "500",
                        padding: "8px",
                        display: ["medical"].includes(
                          selectedSubClass?.sc_view_xx
                        )
                          ? "table-cell"
                          : "none",
                      }}
                    >
                      <span>STATUS</span>
                    </TableCell>
                    <TableCell
                      className="table-cell"
                      style={{
                        fontWeight: "500",
                        padding: "8px",
                        display: ["marine_cert"].includes(
                          selectedSubClass?.sc_view_xx
                        )
                          ? "table-cell"
                          : "none",
                      }}
                    >
                      <span>TRIP DETAILS</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="tablebody">
                  {slicedData.map((risk, index) => (
                    <TableRow
                      key={risk._id}
                      className={`${selectedPolicyRisk._id === risk._id ? "tablerow current" : "tablerow"}`}
                      onClick={() => {
                        selectRisk(risk);
                      }}
                    >
                      <TableCell
                        className="table-cell"
                        style={{
                          display: "flex",
                          maxWidth: "50px",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: "bold",
                        }}
                      >
                        {risk.sr_no}
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        style={{
                          fontWeight: "500",
                          padding: "8px",
                          display: ["motor"].includes(selectedSubClass.sc_view_xx)
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          <span>{risk.pr_vehicle_regn}</span>
                          {risk.opened_claims_xx > 0 && <span style={{
                            fontWeight: "bold",
                            fontSize: "10px",
                            color: "#f44336"
                          }}>{risk.opened_claims_xx === 1 ? `Has ${risk.opened_claims_xx} open claim` : `Has ${risk.opened_claims_xx} open claims`}</span>}
                        </div>
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        style={{
                          fontWeight: "500",
                          padding: "8px",
                          display: ["motor"].includes(selectedSubClass.sc_view_xx)
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        {risk.vehicle_model_xx}
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        style={{
                          fontWeight: "500",
                          padding: "8px",
                          display: ["motor"].includes(selectedSubClass.sc_view_xx)
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        {risk.vehicle_use_xx}
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        style={{
                          fontWeight: "500",
                          padding: "8px",
                          display: ["motor"].includes(selectedSubClass.sc_view_xx)
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        {risk.pr_vehicle_engine}
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        style={{
                          fontWeight: "500",
                          padding: "8px",
                          display: ["motor"].includes(selectedSubClass.sc_view_xx)
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        {risk.pr_vehicle_chassis}
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        style={{
                          fontWeight: "500",
                          padding: "8px",
                          display: ["motor"].includes(selectedSubClass.sc_view_xx)
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        {risk.pr_vehicle_yom}
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        style={{
                          fontWeight: "500",
                          padding: "8px",
                          display: ["motor"].includes(selectedSubClass.sc_view_xx)
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        {risk.pr_vehicle_passangers}
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        style={{
                          fontWeight: "500",
                          padding: "8px",
                          display: ["fire", "engineering", "default"].includes(
                            selectedSubClass.sc_view_xx
                          )
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        {risk.pr_risk_description}
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        style={{
                          fontWeight: "500",
                          padding: "8px",
                          display: ["fire", "engineering", "default"].includes(
                            selectedSubClass.sc_view_xx
                          )
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        {risk.pr_risk_address}
                      </TableCell>
                      {/* <TableCell
                        className="table-cell"
                        style={{
                          fontWeight: "500",
                          padding: "8px",
                          display: ["fire", "engineering", "default"].includes(
                            selectedSubClass.sc_view_xx
                          )
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        {risk.pr_risk_function}
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        style={{
                          fontWeight: "500",
                          padding: "8px",
                          display: ["fire", "engineering", "default"].includes(
                            selectedSubClass.sc_view_xx
                          )
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        {risk.pr_risk_category}
                      </TableCell> */}
                      <TableCell
                        className="table-cell"
                        style={{
                          fontWeight: "500",
                          padding: "8px",
                          display: ["people", "medical"].includes(
                            selectedSubClass.sc_view_xx
                          )
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        {risk.pr_full_name}
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        style={{
                          fontWeight: "500",
                          padding: "8px",
                          display: ["people", "medical"].includes(
                            selectedSubClass.sc_view_xx
                          )
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        {risk.person_gender_xx}
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        style={{
                          fontWeight: "500",
                          padding: "8px",
                          display: ["people", "medical"].includes(
                            selectedSubClass.sc_view_xx
                          )
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        {risk.person_age_xx}
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        style={{
                          fontWeight: "500",
                          padding: "8px",
                          display: ["people", "medical"].includes(
                            selectedSubClass.sc_view_xx
                          )
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        {risk.person_occupation_xx}
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        style={{
                          fontWeight: "500",
                          padding: "8px",
                          display: ["medical"].includes(
                            selectedSubClass.sc_view_xx
                          )
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        {risk.pr_member_status}
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        style={{
                          fontWeight: "500",
                          padding: "8px",
                          display: ["marine_cert"].includes(
                            selectedSubClass.sc_view_xx
                          )
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        {`Trip from ${risk.marine_cert_details_xx?.origin_address ||
                          "Unspecified"
                          } to ${risk.marine_cert_details_xx?.dest_address ||
                          "Unspecified"
                          }`}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5]}
                component="div"
                count={policyRisks.length}
                rowsPerPage={5}
                rows={filteredData}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </div>
        </motion.div>
      )}
      {/* display for motor policy where risk its just one */}
      {showView === "single" && (
        <motion.div className="motor-single"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 1 } }}>
          <div className="navbar">
            <div className="navbar-left">
              <h1 className='main-title'>PARTICULARS</h1>
            </div>
            <div className="navbar-right">
              {/* <span>{selectedSubClass.sc_icon_xx}</span>
              <span>{selectedSubClass.sc_view_xx}</span>
              <span>{selectedPolicyRisk._id}</span> */}
              <div className="inline-buttons-wrapper">
                {quoteData.document_enabled_xx === "Y" &&
                  !["marine_cert"].includes(selectedSubClass.sc_view_xx) && (
                    <CustomLoadingButton onClick={addNewRisk} startIcon={<AddIcon />}>
                      Add
                    </CustomLoadingButton>
                  )}
                {quoteData.document_enabled_xx === "Y" && (
                  <CustomLoadingButton startIcon={<SaveIcon />} onClick={() => saveRisk("Y")}>
                    Save
                  </CustomLoadingButton>
                )}
                <CustomLoadingButton
                  startIcon={<CancelIcon />}
                  style={{ width: "200px" }}
                  onClick={() => {
                    try {
                      // clear cover array
                      setSelectedPolicyRiskCover([]);
                      // clear smi array
                      setPolicyRiskCoverSmi([]);
                      setShowView("multiple");
                      // refetch the risks
                      // fetchPolicyRisks(quoteData._id, selectedSubClass._id);
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                >
                  Close
                </CustomLoadingButton>
              </div>
            </div>
          </div>
          <div
            className="row-one"
            style={{
              display: ["motor"].includes(selectedSubClass.sc_view_xx)
                ? "row-one"
                : "none",
            }}
          >
            {/* Registration No */}
            <div className="input-item-with-label">
              <label
                htmlFor="pr_vehicle_regn"
                className="form-dropdown-label"
                style={{ width: "130px" }}
              >
                REGN NO
              </label>
              <input
                id="pr_vehicle_regn"
                name="pr_vehicle_regn"
                type="text"
                placeholder="REGN NO"
                value={prVehicleRegn}
                className="form-input-item"
                style={{ width: "180px" }}
                onChange={(e) => setPrVehicleRegn(e.target.value.toUpperCase())}
                disabled={quoteData.document_enabled_xx === "N"}
                onBlur={(e) => {
                  try {
                    if (!validateMotorVehicleRegn(e.target.value.toUpperCase())) {
                      setPrVehicleRegn("")
                      setNotifyDialogTitle("Note!");
                      setNotifyDialogText(`Invalid registration number <strong>${e.target.value.toUpperCase()}</strong><br><br>Ensure you use pattern like AAA000A. E.g KDM100Q`);
                      setIsNotifyDialogOpen(true);
                      return;
                    }
                  } catch (error) {
                    console.log(error)
                  }
                }}
              />
            </div>
            {/* Chassis No */}
            <div className="input-item-with-label">
              <label
                htmlFor="pr_vehicle_chassis"
                className="form-dropdown-label"
                style={{ width: "130px" }}
              >
                CHASSIS NO
              </label>
              <input
                id="pr_vehicle_chassis"
                name="pr_vehicle_chassis"
                type="text"
                placeholder="CHASSIS NO"
                value={prVehicleChassis}
                className="form-input-item"
                style={{ width: "180px" }}
                onChange={(e) => setPrVehicleChassis(e.target.value)}
                disabled={quoteData.document_enabled_xx === "N"}
              />
            </div>
            {/* Engine No */}
            <div className="input-item-with-label">
              <label
                htmlFor="pr_vehicle_engine"
                className="form-dropdown-label"
                style={{ width: "130px" }}
              >
                ENGINE NO
              </label>
              <input
                id="pr_vehicle_engine"
                name="pr_vehicle_engine"
                type="text"
                placeholder="ENGINE NO"
                value={prVehicleEngine}
                className="form-input-item"
                style={{ width: "180px" }}
                onChange={(e) => setPrVehicleEngine(e.target.value)}
                disabled={quoteData.document_enabled_xx === "N"}
              />
            </div>
          </div>
          <div
            className="row-two"
            style={{
              display: ["motor"].includes(selectedSubClass.sc_view_xx)
                ? "row-two"
                : "none",
            }}
          >
            {/* vehicle make and model */}
            <div className="input-item-with-label">
              <label
                htmlFor="pr_vehicle_model"
                className="form-dropdown-label"
                style={{ width: "130px" }}
              >
                MODEL
              </label>
              <CreatableSelect
                id="pr_vehicle_model"
                name="pr_vehicle_model"
                styles={customSelectStyles}
                width="180px"
                value={prVehicleModel}
                onChange={handleSelectedVehicleModel}
                options={vehicleModels}
                isSearchable={true}
                isDisabled={quoteData.document_enabled_xx === "N"}
                placeholder="MODEL"
              />
            </div>
            {/* vehicle use */}
            <div className="input-item-with-label">
              <label
                htmlFor="pr_vehicle_use"
                className="form-dropdown-label"
                style={{ width: "130px" }}
              >
                USE
              </label>
              <Select
                id="pr_vehicle_use"
                name="pr_vehicle_use"
                styles={customSelectStyles}
                width="180px"
                value={prVehicleUse}
                onChange={(selectedOption) => {
                  try {
                    setPrVehicleUse(selectedOption);
                  } catch (error) {
                    console.log(error);
                  }
                }}
                options={vehicleUse}
                isSearchable={true}
                isDisabled={quoteData.document_enabled_xx === "N"}
                placeholder="USE"
              />
            </div>
            {/* vehicle color */}
            <div className="input-item-with-label">
              <label
                htmlFor="pr_vehicle_color"
                className="form-dropdown-label"
                style={{ width: "130px" }}
              >
                COLOR
              </label>
              <CreatableSelect
                id="pr_vehicle_color"
                name="pr_vehicle_color"
                styles={customSelectStyles}
                width="180px"
                value={prVehicleColor}
                onChange={handleSelectedVehicleColor}
                options={vehicleColor}
                isSearchable={true}
                isDisabled={quoteData.document_enabled_xx === "N"}
                placeholder="COLOR"
              />
            </div>
          </div>
          <div
            className="row-three"
            style={{
              display: ["motor"].includes(selectedSubClass.sc_view_xx)
                ? "row-three"
                : "none",
            }}
          >
            {/* year of manufacture */}
            <div className="input-item-with-label">
              <label
                htmlFor="pr_vehicle_yom"
                className="form-dropdown-label"
                style={{ width: "130px" }}
              >
                YOM
              </label>
              <input
                id="pr_vehicle_yom"
                name="pr_vehicle_yom"
                type="text"
                placeholder="YOM"
                value={prVehicleYom}
                className="form-input-item"
                style={{ width: "180px" }}
                onChange={(e) => setPrVehicleYom(e.target.value)}
                disabled={quoteData.document_enabled_xx === "N"}
              />
            </div>
            {/* vehicle cc */}
            <div className="input-item-with-label">
              <label
                htmlFor="pr_vehicle_cc"
                className="form-dropdown-label"
                style={{ width: "130px" }}
              >
                CC
              </label>
              <input
                id="pr_vehicle_cc"
                name="pr_vehicle_cc"
                type="text"
                placeholder="CC"
                value={prVehicleCc}
                className="form-input-item"
                style={{ width: "180px" }}
                onChange={(e) => setPrVehicleCc(e.target.value)}
                disabled={quoteData.document_enabled_xx === "N"}
              />
            </div>
            {/* no of passengers */}
            <div className="input-item-with-label">
              <label
                htmlFor="pr_vehicle_passangers"
                className="form-dropdown-label"
                style={{ width: "130px" }}
              >
                PASSENGERS
              </label>
              <input
                id="pr_vehicle_passangers"
                name="pr_vehicle_passangers"
                type="text"
                placeholder="0"
                value={prVehiclePassangers}
                className="form-input-item"
                style={{ width: "180px" }}
                onChange={(e) => setPrVehiclePassangers(e.target.value)}
                disabled={quoteData.document_enabled_xx === "N"}
              />
            </div>
          </div>
          <div
            className="row-four"
            style={{
              display: ["motor"].includes(selectedSubClass.sc_view_xx)
                ? "row-four"
                : "none",
            }}
          >
            <div className="input-item-with-label">
              <label
                htmlFor="pr_fm_date"
                className="form-dropdown-label"
                style={{ width: "130px" }}
              >
                FROM DATE
              </label>
              <input
                id="pr_fm_date"
                name="pr_fm_date"
                type="date"
                placeholder="FROM"
                value={prFmDate}
                className="form-input-item"
                style={{ width: "180px" }}
                onChange={handleFromDateChange}
                onBlur={calculateDateDifference}
                disabled={quoteData.document_enabled_xx === "N"}
              />
            </div>
            <div className="input-item-with-label">
              <label
                htmlFor="pr_to_date"
                className="form-dropdown-label"
                style={{ width: "130px" }}
              >
                TO DATE
              </label>
              <input
                id="pr_to_date"
                name="pr_to_date"
                type="date"
                placeholder="TO"
                value={prToDate}
                className="form-input-item"
                style={{ width: "180px" }}
                onChange={handleToDateChange}
                onBlur={calculateDateDifference}
                disabled={quoteData.document_enabled_xx === "N"}
              />
            </div>
            {/* <span>{selectedPolicyRisk.pr_subclass_docid}</span> */}
          </div>
          <div
            className="row-five"
            style={{
              display: ["fire", "engineering", "default"].includes(
                selectedSubClass.sc_view_xx
              )
                ? "row-four"
                : "none",
            }}
          >
            <div className="input-item-with-label">
              <label
                htmlFor="pr_risk_description"
                className="form-dropdown-label"
                style={{ width: "150px", alignSelf: "flex-start" }}
              >
                DESCRIPTION
              </label>
              <textarea
                id="pr_risk_description"
                name="pr_risk_description"
                type="text"
                placeholder="DESCRIPTION"
                value={prRiskDescription}
                rows={5}
                className="form-input-item"
                style={{ width: "100%" }}
                onChange={(e) => setPrRiskDescription(e.target.value)}
                disabled={quoteData.document_enabled_xx === "N"}
              />
            </div>
          </div>
          <div
            className="row-six"
            style={{
              display: ["fire", "engineering", "default"].includes(
                selectedSubClass.sc_view_xx
              )
                ? "row-four"
                : "none",
            }}
          >
            <div className="input-item-with-label">
              <label
                htmlFor="pr_risk_address"
                className="form-dropdown-label"
                style={{ width: "150px" }}
              >
                ADDRESS
              </label>
              <input
                id="pr_risk_address"
                name="pr_risk_address"
                type="text"
                placeholder="ADDRESS"
                value={prRiskAddress}
                className="form-input-item"
                style={{ width: "100%" }}
                onChange={(e) => setPrRiskAddress(e.target.value)}
                disabled={quoteData.document_enabled_xx === "N"}
              />
            </div>
          </div>
          <div
            className="row-seven"
            style={{
              display: ["fire", "engineering", "default"].includes(
                selectedSubClass.sc_view_xx
              )
                ? "row-four"
                : "none",
            }}
          >
            <div className="input-item-with-label" style={{ flex: "1" }}>
              <label
                htmlFor="pr_risk_function"
                className="form-dropdown-label"
                style={{ width: "130px" }}
              >
                FUNCTION
              </label>
              <Select
                id="pr_risk_function"
                name="pr_risk_function"
                styles={customSelectStyles}
                width="300px"
                value={prRiskFunction}
                onChange={(selectedOption) => {
                  try {
                    setPrRiskFunction(selectedOption);
                  } catch (error) {
                    console.log(error);
                  }
                }}
                options={riskFunctions}
                isSearchable={true}
                isDisabled={quoteData.document_enabled_xx === "N"}
                placeholder="FUNCTION"
              />
            </div>

            <div
              className="input-item-with-label"
              style={{
                justifyContent: "flex-end",
                flex: "1",
              }}
            >
              <label
                htmlFor="pr_risk_area"
                className="form-dropdown-label"
                style={{ width: "130px" }}
              >
                AREA
              </label>
              <CreatableSelect
                id="pr_risk_area"
                name="pr_risk_area"
                styles={customSelectStyles}
                width="300px"
                value={prRiskArea}
                onChange={handleSelectedRiskArea}
                options={riskAreas}
                isSearchable={true}
                isDisabled={quoteData.document_enabled_xx === "N"}
                placeholder="AREA"
              />
            </div>
          </div>
          {/* person fields */}
          <div
            className="row-six"
            style={{
              display: ["people", "medical"].includes(
                selectedSubClass.sc_view_xx
              )
                ? "row-four"
                : "none",
            }}
          >
            <div className="input-item-with-label">
              <label
                htmlFor="pr_full_name"
                className="form-dropdown-label"
                style={{ width: "130px" }}
              >
                FULL NAME
              </label>
              <input
                id="pr_full_name"
                name="pr_full_name"
                type="text"
                placeholder="FULL NAME"
                value={prFullName}
                className="form-input-item"
                style={{ width: "350px" }}
                onChange={(e) => setPrFullName(e.target.value)}
                disabled={quoteData.document_enabled_xx === "N"}
              />
            </div>
            <div
              className="input-item-with-label"
              style={{
                justifyContent: "flex-end",
                flex: "1",
              }}
            >
              <label
                htmlFor="pr_person_dob"
                className="form-dropdown-label"
                style={{ width: "130px" }}
              >
                D.O.B
              </label>
              <input
                id="pr_person_dob"
                name="pr_person_dob"
                type="date"
                placeholder="D.O.B"
                value={prPersonDob}
                className="form-input-item"
                style={{ width: "200px", borderTopRightRadius: "0px", borderBottomRightRadius: "0px" }}
                onChange={handlePersonDob}
                disabled={quoteData.document_enabled_xx === "N"}
              />
            </div>
            <input
              id="person_age_xx"
              name="person_age_xx"
              type="text"
              placeholder="AGE"
              value={personAgeXx}
              className="form-input-item"
              style={{
                width: "100px",
                fontSize: "16px",
                fontWeight: "500",
                textAlign: "center",
                borderTopLeftRadius: "0px",
                borderBottomLeftRadius: "0px",
                borderLeft: "none"
              }}
              disabled
            />
          </div>
          {/* email and phone number */}
          <div
            className="row-seven"
            style={{
              display: ["people", "medical"].includes(
                selectedSubClass.sc_view_xx
              )
                ? "row-four"
                : "none",
            }}
          >
            <div className="input-item-with-label" style={{ flex: "1" }}>
              <label
                htmlFor="pr_person_email"
                className="form-dropdown-label"
                style={{ width: "130px" }}
              >
                EMAIL
              </label>
              <input
                id="pr_person_email"
                name="pr_person_email"
                type="email"
                placeholder="EMAIL"
                value={prPersonEmail}
                className="form-input-item"
                style={{ width: "350px" }}
                onChange={(e) => setPrPersonEmail(e.target.value)}
                disabled={quoteData.document_enabled_xx === "N"}
              />
            </div>
            <div
              className="input-item-with-label"
              style={{
                justifyContent: "flex-end",
                flex: "1",
              }}
            >
              <label
                htmlFor="pr_person_phone"
                className="form-dropdown-label"
                style={{ width: "130px" }}
              >
                PHONE NO
              </label>
              <input
                id="pr_person_phone"
                name="pr_person_phone"
                type="text"
                placeholder="PHONE NO"
                value={prPersonPhone}
                className="form-input-item"
                style={{ width: "300px" }}
                onChange={(e) => setPrPersonPhone(e.target.value)}
                disabled={quoteData.document_enabled_xx === "N"}
              />
            </div>
          </div>
          {/* gender and id */}
          <div
            className="row-seven"
            style={{
              display: ["people", "medical"].includes(
                selectedSubClass.sc_view_xx
              )
                ? "row-four"
                : "none",
            }}
          >
            <div className="input-item-with-label">
              <label
                htmlFor="pr_person_address"
                className="form-dropdown-label"
                style={{ width: "130px" }}
              >
                ADDRESS
              </label>
              <input
                id="pr_person_address"
                name="pr_person_address"
                type="text"
                placeholder="ADDRESS"
                value={prRiskAddress}
                className="form-input-item"
                style={{ width: "350px" }}
                onChange={(e) => setPrRiskAddress(e.target.value)}
                disabled={quoteData.document_enabled_xx === "N"}
              />
            </div>

            <div className="input-item-with-label" style={{ flex: "1" }}>
              <label
                htmlFor="pr_gender"
                className="form-dropdown-label"
                style={{ width: "130px" }}
              >
                GENDER
              </label>
              <Select
                id="pr_gender"
                name="pr_gender"
                styles={customSelectStyles}
                width="300px"
                value={prGender}
                onChange={(selectedOption) => {
                  try {
                    setPrGender(selectedOption);
                  } catch (error) {
                    console.log(error);
                  }
                }}
                options={genders}
                isSearchable={true}
                isDisabled={quoteData.document_enabled_xx === "N"}
                placeholder="GENDER"
              />
            </div>
          </div>
          {/* occupation */}
          <div
            className="row-seven"
            style={{
              display: ["people", "medical"].includes(
                selectedSubClass.sc_view_xx
              )
                ? "row-four"
                : "none",
            }}
          >
            <div className="input-item-with-label" style={{ flex: "1" }}>
              <label
                htmlFor="pr_person_occupation"
                className="form-dropdown-label"
                style={{ width: "130px" }}
              >
                OCCUPATION
              </label>
              <CreatableSelect
                id="pr_person_occupation"
                name="pr_person_occupation"
                styles={customSelectStyles}
                width="350px"
                value={prPersonOccupation}
                onChange={handlePersonOccupation}
                options={occupations}
                isSearchable={true}
                isDisabled={quoteData.document_enabled_xx === "N"}
                placeholder="OCCUPATION"
              />
            </div>
            <div
              className="input-item-with-label"
              style={{
                justifyContent: "flex-end",
                flex: "1",
              }}
            >
              <label
                htmlFor="pr_person_id"
                className="form-dropdown-label"
                style={{ width: "130px" }}
              >
                ID NUMBER
              </label>
              <input
                id="pr_person_id"
                name="pr_person_id"
                type="text"
                placeholder="ID Number"
                value={prPersonId}
                className="form-input-item"
                style={{ width: "300px" }}
                onChange={(e) => setPrPersonId(e.target.value)}
                disabled={quoteData.document_enabled_xx === "N"}
              />
            </div>
          </div>

          {/* member no and member from date */}
          <div
            className="row-seven"
            style={{
              display: ["medical"].includes(selectedSubClass.sc_view_xx)
                ? "row-four"
                : "none",
            }}
          >
            <div className="input-item-with-label" style={{ flex: "1" }}>
              <label
                htmlFor="pr_member_no"
                className="form-dropdown-label"
                style={{ width: "130px" }}
              >
                MEMBER NO
              </label>
              <input
                id="pr_member_no"
                name="pr_member_no"
                type="text"
                placeholder="Member No"
                value={prMemberNo}
                className="form-input-item"
                style={{ width: "300px" }}
                onChange={(e) => setPrMemberNo(e.target.value)}
                disabled={quoteData.document_enabled_xx === "N"}
              />
            </div>
            <div
              className="input-item-with-label"
              style={{
                justifyContent: "flex-end",
                flex: "1",
              }}
            >
              <label
                htmlFor="pr_member_fm"
                className="form-dropdown-label"
                style={{ width: "130px" }}
              >
                MEMBER FROM
              </label>
              <input
                id="pr_member_fm"
                name="pr_member_fm"
                type="date"
                placeholder="Member From"
                value={prMemberFm}
                className="form-input-item"
                style={{ width: "300px" }}
                onChange={(e) => setPrMemberFm(e.target.value)}
                disabled={quoteData.document_enabled_xx === "N"}
              />
            </div>
          </div>
          {/* member status and to date */}
          <div
            className="row-seven"
            style={{
              display: ["medical"].includes(selectedSubClass.sc_view_xx)
                ? "row-four"
                : "none",
            }}
          >
            <div className="input-item-with-label" style={{ flex: "1" }}>
              <label
                htmlFor="pr_member_status"
                className="form-dropdown-label"
                style={{ width: "130px" }}
              >
                STATUS
              </label>
              <Select
                id="pr_member_status"
                name="pr_member_status"
                styles={customSelectStyles}
                width="300px"
                value={prMemberStatus}
                onChange={(selectedOption) => {
                  try {
                    setPrMemberStatus(selectedOption);
                  } catch (error) {
                    console.log(error);
                  }
                }}
                options={memberStatus}
                isSearchable={true}
                isDisabled={quoteData.document_enabled_xx === "N"}
                placeholder="STATUS"
              />
            </div>
            <div
              className="input-item-with-label"
              style={{
                justifyContent: "flex-end",
                flex: "1",
              }}
            >
              <label
                htmlFor="pr_member_to"
                className="form-dropdown-label"
                style={{ width: "130px" }}
              >
                MEMBER TO
              </label>
              <input
                id="pr_member_to"
                name="pr_member_to"
                type="date"
                placeholder="Member To"
                value={prMemberTo}
                className="form-input-item"
                style={{ width: "300px" }}
                onChange={(e) => setPrMemberTo(e.target.value)}
                disabled={quoteData.document_enabled_xx === "N"}
              />
            </div>
          </div>

          {selectedSubClass?.attributes_xx !== "default" && (
            <div className="person-attributes-wrapper">
              <div className="person-attributes-title">
                <h1>{`${prFullName} Attributes`}</h1>
                <Tooltip
                  title={`Add Attributes to ${prFullName}`}
                  placement="right"
                >
                  <AddIcon
                    size={50}
                    style={{
                      borderRadius: "5px",
                      padding: "3px",
                      alignSelf: "center",
                      cursor: "pointer",
                      backgroundColor: "rgba(0, 128, 0, 0.5)",
                    }}
                    onClick={async () => {
                      try {
                        await fetchSysCode(selectedSubClass?.attributes_xx);
                        setShowAddSysCodes(true);
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  />
                </Tooltip>
              </div>
              <div className="person-attributes-content">
                {prOtherAttributes.map((attr, index) => (
                  <div key={attr._id}>
                    {/* <span>{attr.attribute_desc}</span> */}
                    <ToggleSwitch
                      key={attr._id}
                      id={attr._id}
                      value={attr.attribute_yn}
                      onChange={(newValue) =>
                        handleOtherAttributeChange(attr._id, newValue)
                      }
                      label={`${index + 1}. ${attr.attribute_desc}`}
                      labelPosition="start"
                      labelWidth="180px"
                      labelStyle={{
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                      disabled={false}
                      border="Y"
                      style={{
                        width: "250px",
                      }}
                      delete_yn={true}
                      onClickClear={async () => {
                        try {
                          const res = await newRequest.post(
                            "/uwPolicyRisk/delOtherAttr",
                            {
                              risk_docid: selectedPolicyRisk._id,
                              other_attribute_docid: attr._id,
                            },
                            config
                          );
                          fetchOtherAttributes();
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* end of person fields */}
          {/* start of marine fields */}
          {["marine_cert"].includes(selectedSubClass.sc_view_xx) && (
            <div className="marine-details-wrapper">
              <div className="marine-details-row">
                <div className="marine-details-left">
                  <div className="input-item-with-label">
                    <label
                      htmlFor="cert_no"
                      className="form-dropdown-label"
                      style={{ width: "130px" }}
                    >
                      CERT NO
                    </label>
                    <input
                      id="cert_no"
                      name="cert_no"
                      type="text"
                      placeholder="CERT NO"
                      value={certNo}
                      className="form-input-item"
                      style={{ width: "300px" }}
                      onChange={(e) => setCertNo(e.target.value)}
                      disabled={quoteData.document_enabled_xx === "N"}
                    />
                  </div>
                </div>
                <div className="marine-details-right">
                  <div className="input-item-with-label">
                    <label
                      htmlFor="shipping_mode"
                      className="form-dropdown-label"
                      style={{ width: "130px" }}
                    >
                      SHIPPING BY
                    </label>

                    <Select
                      id="shipping_mode"
                      name="shipping_mode"
                      styles={customSelectStyles}
                      width="300px"
                      value={shippingMode}
                      options={shippingModes}
                      onChange={(selectedOption) => {
                        try {
                          setShippingMode(selectedOption);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                      isSearchable={true}
                      isDisabled={quoteData.document_enabled_xx === "N"}
                      placeholder="SHIPPING BY"
                    />
                  </div>
                </div>
              </div>
              <hr style={{ margin: "5px 0px" }} />
              <div className="marine-details-row">
                <div className="marine-details-left">
                  <h1 style={{ alignSelf: "center" }}>Origin</h1>
                  <div className="input-item-with-label">
                    <label
                      htmlFor="origin_address"
                      className="form-dropdown-label"
                      style={{ width: "max-content" }}
                    >
                      ORIGIN ADDRESS
                    </label>
                    <input
                      id="origin_address"
                      name="origin_address"
                      type="text"
                      placeholder="ORIGIN ADDRESS"
                      value={originAddress}
                      className="form-input-item"
                      style={{ width: "300px" }}
                      onChange={(e) => setOriginAddress(e.target.value)}
                      disabled={quoteData.document_enabled_xx === "N"}
                    />
                  </div>
                  <div className="input-item-with-label">
                    <label
                      htmlFor="origin_port"
                      className="form-dropdown-label"
                      style={{ width: "max-content" }}
                    >
                      ORIGIN PORT
                    </label>
                    <CreatableSelect
                      id="origin_port"
                      name="origin_port"
                      styles={customSelectStyles}
                      width="300px"
                      value={originPort}
                      onChange={handleSelectedOriginPort}
                      options={ports}
                      isSearchable={true}
                      isDisabled={quoteData.document_enabled_xx === "N"}
                      placeholder="PORT"
                    />
                  </div>
                  <div className="input-item-with-label">
                    <label
                      htmlFor="est_depature_date"
                      className="form-dropdown-label"
                      style={{ width: "max-content" }}
                    >
                      EST DEPATURE DATE
                    </label>
                    <input
                      id="est_depature_date"
                      name="est_depature_date"
                      type="date"
                      placeholder="EST DEPATURE DATE"
                      value={estDepatureDate}
                      className="form-input-item"
                      style={{ width: "200px" }}
                      onChange={(e) => setEstDepatureDate(e.target.value)}
                      disabled={quoteData.document_enabled_xx === "N"}
                    />
                  </div>
                  <div className="input-item-with-label">
                    <label
                      htmlFor="act_depature_date"
                      className="form-dropdown-label"
                      style={{ width: "max-content" }}
                    >
                      ACT DEPATURE DATE
                    </label>
                    <input
                      id="act_depature_date"
                      name="act_depature_date"
                      type="date"
                      placeholder="ACT DEPATURE DATE"
                      value={actDepatureDate}
                      className="form-input-item"
                      style={{ width: "200px" }}
                      onChange={(e) => setActDepatureDate(e.target.value)}
                      disabled={quoteData.document_enabled_xx === "N"}
                    />
                  </div>
                </div>
                <div className="marine-details-right">
                  <h1 style={{ alignSelf: "center" }}>Destination</h1>
                  <div className="input-item-with-label">
                    <label
                      htmlFor="dest_address"
                      className="form-dropdown-label"
                      style={{ width: "max-content" }}
                    >
                      DEST ADDRESS
                    </label>
                    <input
                      id="dest_address"
                      name="dest_address"
                      type="text"
                      placeholder="DEST ADDRESS"
                      value={destAddress}
                      className="form-input-item"
                      style={{ width: "300px" }}
                      onChange={(e) => setDestAddress(e.target.value)}
                      disabled={quoteData.document_enabled_xx === "N"}
                    />
                  </div>
                  <div className="input-item-with-label">
                    <label
                      htmlFor="dest_port"
                      className="form-dropdown-label"
                      style={{ width: "max-content" }}
                    >
                      DEST PORT
                    </label>

                    <CreatableSelect
                      id="dest_port"
                      name="dest_port"
                      styles={customSelectStyles}
                      width="300px"
                      value={destPort}
                      onChange={handleSelectedDestPort}
                      options={ports}
                      isSearchable={true}
                      isDisabled={quoteData.document_enabled_xx === "N"}
                      placeholder="PORT"
                    />
                  </div>
                  <div className="input-item-with-label">
                    <label
                      htmlFor="est_arrival_date"
                      className="form-dropdown-label"
                      style={{ width: "max-content" }}
                    >
                      EST ARRIVAL DATE
                    </label>
                    <input
                      id="est_arrival_date"
                      name="est_arrival_date"
                      type="date"
                      placeholder="DEPATURE DATE"
                      value={estArrivalDate}
                      className="form-input-item"
                      style={{ width: "200px" }}
                      onChange={(e) => setEstArrivalDate(e.target.value)}
                      disabled={quoteData.document_enabled_xx === "N"}
                    />
                  </div>

                  <div className="input-item-with-label">
                    <label
                      htmlFor="act_arrival_date"
                      className="form-dropdown-label"
                      style={{ width: "max-content" }}
                    >
                      ACT ARRIVAL DATE
                    </label>
                    <input
                      id="act_arrival_date"
                      name="act_arrival_date"
                      type="date"
                      placeholder="ACT ARRIVAL DATE"
                      value={actArrivalDate}
                      className="form-input-item"
                      style={{ width: "200px" }}
                      onChange={(e) => setActArrivalDate(e.target.value)}
                      disabled={quoteData.document_enabled_xx === "N"}
                    />
                  </div>
                </div>
              </div>
              <hr style={{ margin: "5px 0px" }} />
              <div className="marine-details-row">
                <div className="input-item-with-label">
                  <label
                    htmlFor="vessel_no"
                    className="form-dropdown-label"
                    style={{ width: "130px" }}
                  >
                    VESSEL NO
                  </label>
                  <input
                    id="vessel_no"
                    name="vessel_no"
                    type="text"
                    placeholder="VESSEL NO"
                    value={vesselNo}
                    className="form-input-item"
                    style={{ width: "200px" }}
                    onChange={(e) => setVesselNo(e.target.value)}
                    disabled={quoteData.document_enabled_xx === "N"}
                  />
                </div>
                <div className="input-item-with-label">
                  <label
                    htmlFor="flight_no"
                    className="form-dropdown-label"
                    style={{ width: "130px" }}
                  >
                    FLIGHT NO
                  </label>
                  <input
                    id="flight_no"
                    name="flight_no"
                    type="text"
                    placeholder="FLIGHT NO"
                    value={flightNo}
                    className="form-input-item"
                    style={{ width: "200px" }}
                    onChange={(e) => setFlightNo(e.target.value)}
                    disabled={quoteData.document_enabled_xx === "N"}
                  />
                </div>

                <div className="input-item-with-label">
                  <label
                    htmlFor="marks_and_number"
                    className="form-dropdown-label"
                    style={{ width: "130px" }}
                  >
                    MARKS NO
                  </label>
                  <input
                    id="marks_and_number"
                    name="marks_and_number"
                    type="text"
                    placeholder="MARKS AND NO"
                    value={marksAndNumber}
                    className="form-input-item"
                    style={{ width: "200px" }}
                    onChange={(e) => setMarksAndNumber(e.target.value)}
                    disabled={quoteData.document_enabled_xx === "N"}
                  />
                </div>
              </div>
              <div className="marine-details-row">
                <div className="input-item-with-label">
                  <label
                    htmlFor="invoice_no"
                    className="form-dropdown-label"
                    style={{ width: "130px" }}
                  >
                    INVOICE NO
                  </label>
                  <input
                    id="invoice_no"
                    name="invoice_no"
                    type="text"
                    placeholder="INVOICE NO"
                    value={invoiceNo}
                    className="form-input-item"
                    style={{ width: "200px" }}
                    onChange={(e) => setInvoiceNo(e.target.value)}
                    disabled={quoteData.document_enabled_xx === "N"}
                  />
                </div>

                <div className="input-item-with-label">
                  <label
                    htmlFor="idf_number"
                    className="form-dropdown-label"
                    style={{ width: "130px" }}
                  >
                    IDF NO
                  </label>
                  <input
                    id="idf_number"
                    name="idf_number"
                    type="text"
                    placeholder="IDF NO"
                    value={idfNumber}
                    className="form-input-item"
                    style={{ width: "200px" }}
                    onChange={(e) => setIdfNumber(e.target.value)}
                    disabled={quoteData.document_enabled_xx === "N"}
                  />
                </div>
                <div className="input-item-with-label">
                  <label
                    htmlFor="no_of_packages"
                    className="form-dropdown-label"
                    style={{ width: "130px" }}
                  >
                    NO OF PKGS
                  </label>
                  <input
                    id="no_of_packages"
                    name="no_of_packages"
                    type="text"
                    placeholder="NO OF PACKAGES"
                    value={noOfPackages}
                    className="form-input-item"
                    style={{ width: "200px" }}
                    onChange={(e) => setNoOfPackages(e.target.value)}
                    disabled={quoteData.document_enabled_xx === "N"}
                  />
                </div>
              </div>
              <hr style={{ margin: "5px 0px" }} />
              <h1>{`Limits ${quoteData.pl_cur_code}`}</h1>
              <div className="marine-details-row">
                <div className="input-item-with-label">
                  <label
                    htmlFor="any_one_loss_limit"
                    className="form-dropdown-label"
                    style={{ width: "130px" }}
                  >
                    SINGLE LOSS
                  </label>
                  <input
                    id="any_one_loss_limit"
                    name="any_one_loss_limit"
                    type="text"
                    placeholder="0.00"
                    value={anyOneLossLimit}
                    className="form-input-item"
                    style={{ width: "200px", textAlign: "right" }}
                    onChange={(e) => setAnyOneLossLimit(e.target.value)}
                    disabled={quoteData.document_enabled_xx === "N"}
                    onBlur={handleMarineLimitsBlur}
                  />
                </div>
                <div className="input-item-with-label">
                  <label
                    htmlFor="single_transit_limit"
                    className="form-dropdown-label"
                    style={{ width: "130px" }}
                  >
                    SINGLE TRANSIT
                  </label>
                  <input
                    id="single_transit_limit"
                    name="single_transit_limit"
                    type="text"
                    placeholder="0.00"
                    value={singleTransitLimit}
                    className="form-input-item"
                    style={{ width: "200px", textAlign: "right" }}
                    onChange={(e) => setSingleTransitLimit(e.target.value)}
                    disabled={quoteData.document_enabled_xx === "N"}
                    onBlur={handleMarineLimitsBlur}
                  />
                </div>

                <div className="input-item-with-label">
                  <label
                    htmlFor="annual_carry_limit"
                    className="form-dropdown-label"
                    style={{ width: "130px" }}
                  >
                    ANNUAL CARRY
                  </label>
                  <input
                    id="annual_carry_limit"
                    name="annual_carry_limit"
                    type="number"
                    placeholder="0.00"
                    value={annualCarryLimit}
                    className="form-input-item"
                    style={{ width: "200px", textAlign: "right" }}
                    onChange={(e) => setAnnualCarryLimit(e.target.value)}
                    disabled={quoteData.document_enabled_xx === "N"}
                    onBlur={handleMarineLimitsBlur}
                  />
                </div>
              </div>
              <hr style={{ margin: "5px 0px" }} />
              <div className="marine-details-row">
                <div className="input-item-with-label">
                  <label
                    htmlFor="bill_of_lading_no"
                    className="form-dropdown-label"
                    style={{ width: "130px" }}
                  >
                    LADING NO
                  </label>
                  <input
                    id="bill_of_lading_no"
                    name="bill_of_lading_no"
                    type="text"
                    placeholder="BILL OF LADING NO"
                    value={billOfLadingNo}
                    className="form-input-item"
                    style={{ width: "200px" }}
                    onChange={(e) => setBillOfLadingNo(e.target.value)}
                    disabled={quoteData.document_enabled_xx === "N"}
                  />
                </div>
                <div className="input-item-with-label">
                  <label
                    htmlFor="bill_of_lading_date"
                    className="form-dropdown-label"
                    style={{ width: "130px" }}
                  >
                    LADING DATE
                  </label>
                  <input
                    id="bill_of_lading_date"
                    name="bill_of_lading_date"
                    type="date"
                    placeholder="LADING DATE"
                    value={billOfLadingDate}
                    className="form-input-item"
                    style={{ width: "200px" }}
                    onChange={(e) => setBillOfLadingDate(e.target.value)}
                    disabled={quoteData.document_enabled_xx === "N"}
                  />
                </div>
                <div className="input-item-with-label">
                  <label
                    htmlFor="tranship_at"
                    className="form-dropdown-label"
                    style={{ width: "130px" }}
                  >
                    TRANSHIP AT
                  </label>
                  <input
                    id="tranship_at"
                    name="tranship_at"
                    type="text"
                    placeholder="TRANSHIP AT"
                    value={transhipAt}
                    className="form-input-item"
                    style={{ width: "200px" }}
                    onChange={(e) => setTranshipAt(e.target.value)}
                    disabled={quoteData.document_enabled_xx === "N"}
                  />
                </div>
              </div>
              <div className="marine-details-row">
                <div className="input-item-with-label">
                  <label
                    htmlFor="supplier"
                    className="form-dropdown-label"
                    style={{ width: "130px" }}
                  >
                    SUPPLIER
                  </label>
                  <input
                    id="supplier"
                    name="supplier"
                    type="text"
                    placeholder="SUPPLIER"
                    value={supplier}
                    className="form-input-item"
                    style={{ width: "300px" }}
                    onChange={(e) => setSupplier(e.target.value)}
                    disabled={quoteData.document_enabled_xx === "N"}
                  />
                </div>
                <div className="input-item-with-label">
                  <label
                    htmlFor="clearing_agent"
                    className="form-dropdown-label"
                    style={{ width: "max-content" }}
                  >
                    CLEARING AGENT
                  </label>
                  <input
                    id="clearing_agent"
                    name="clearing_agent"
                    type="text"
                    placeholder="CLEARING AGENT"
                    value={clearingAgent}
                    className="form-input-item"
                    style={{ width: "300px" }}
                    onChange={(e) => setClearingAgent(e.target.value)}
                    disabled={quoteData.document_enabled_xx === "N"}
                  />
                </div>
              </div>
              <div className="input-item-with-label">
                <label
                  htmlFor="goods_desc"
                  className="form-dropdown-label"
                  style={{ width: "150px", alignSelf: "flex-start" }}
                >
                  DESCRIPTION
                </label>
                <textarea
                  id="goods_desc"
                  name="goods_desc"
                  type="text"
                  placeholder="GOODS DESCRIPTION"
                  value={goodsDesc}
                  rows={3}
                  className="form-input-item"
                  style={{ width: "100%" }}
                  onChange={(e) => setGoodsDesc(e.target.value)}
                  disabled={quoteData.document_enabled_xx === "N"}
                />
              </div>
            </div>
          )}
          {/* end of marine fields */}
          <hr style={{ margin: "10px 0px" }} />
          <div className="applicable-covers">
            {Object.keys(selectedPolicyRisk).length > 0 &&
              quoteData.document_enabled_xx === "Y" && (
                <div className="inline-buttons-wrapper">
                  <Select
                    id="cover"
                    name="cover"
                    styles={customSelectStyles}
                    width="315px"
                    value={selectedSubClassCover}
                    onChange={(selectedOption) => {
                      try {
                        setSelectedSubClassCover(selectedOption);
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                    options={subClassCovers}
                    isSearchable={true}
                    placeholder="COVER"
                  />
                  <CustomLoadingButton
                    startIcon={<AddIcon />}
                    onClick={handleAddCover}
                  >
                    Add Cover
                  </CustomLoadingButton>
                  {quoteData.document_enabled_xx === "Y" && policyRiskCovers?.length > 0 &&
                    <CustomLoadingButton
                      startIcon={<CalculateIcon />}
                      onClick={calculatePremium}
                    >
                      Calculate Premium
                    </CustomLoadingButton>
                  }
                </div>
              )}
            {Object.keys(selectedPolicyRisk).length > 0 && (
              <div className="applicable-covers-wrapper">
                {policyRiskCovers.map((cover) => (
                  <div
                    key={cover._id}
                    className={`${cover._id === selectedPolicyRiskCover._id
                      ? "single-cover active"
                      : "single-cover"
                      }`}
                    onClick={async () => {
                      try {
                        await saveSmis();
                        setSelectedPolicyRiskCover(cover);
                        // refreshing smi
                        fetchSelectedCoverSmi(cover.rc_risk_note_no, cover._id);
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  >
                    <div className="single-cover-title" style={{ display: "flex", marginBottom: "10px" }}>
                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                        <span>{cover.cover_name_xx}</span>
                        <span>{cover.rc_cc_code}</span>
                        {quoteData.document_enabled_xx === "Y" && (
                          <span>
                            <Tooltip
                              title={`Edit ${cover.cover_name_xx}`}
                              placement="right"
                            >
                              <EditIcon
                                size={50}
                                style={{
                                  borderRadius: "5px",
                                  padding: "3px",
                                  alignSelf: "center",
                                  cursor: "pointer",
                                  color: "rgba(0, 128, 0 )",
                                  backgroundColor: "rgba(0, 128, 0, 0.5)",
                                }}
                                onClick={() => {
                                  try {
                                    setSelectedPolicyRiskCover(cover);
                                    setShowCoverForEditing(true);
                                  } catch (error) {
                                    console.log(error);
                                  }
                                }}
                              />
                            </Tooltip>
                          </span>
                        )}
                      </div>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <div className="input-item-with-label">
                          <label
                            htmlFor="rc_comm_perc"
                            style={{ width: "max-content" }}
                          >
                            COMMISSION %
                          </label>
                          <div className="tooltip-container">
                            <input
                              id="rc_comm_perc"
                              name="rc_comm_perc"
                              type="text"
                              value={cover.rc_comm_perc}
                              onChange={(e) => handleCoverInputChange(e, cover._id, 'rc_comm_perc')}
                              className="form-input-item"
                              style={{ width: "70px", textAlign: "right" }}
                              onBlur={() => {
                                handleCoverBlur(cover._id, "rc_comm_perc");
                              }}
                              disabled={(quoteData.document_enabled_xx === "N" || getLimitValue("UW1008") === "N" || currentUser.user_type_xx !== "superadmin")}
                            />
                            {(quoteData.document_enabled_xx === "N" || getLimitValue("UW1008") === "N" || currentUser.user_type_xx !== "superadmin") && (
                              <span className="tooltip-text">{`UW1008 - You are not allowed to amend COMMISSION RATE for ${quoteData.product_name_xx}`}</span>
                            )}
                          </div>
                        </div>
                        <div className="input-item-with-label">
                          <label
                            htmlFor="rc_comm_tax_perc"
                            style={{ width: "max-content" }}
                          >
                            TAX %
                          </label>
                          <div className="tooltip-container">
                            <input
                              id="rc_comm_tax_perc"
                              name="rc_comm_tax_perc"
                              type="text"
                              value={cover.rc_comm_tax_perc}
                              onChange={(e) => handleCoverInputChange(e, cover._id, 'rc_comm_tax_perc')}
                              className="form-input-item"
                              style={{ width: "70px", textAlign: "right" }}
                              onBlur={() => {
                                handleCoverBlur(cover._id, "rc_comm_tax_perc");
                              }}
                              disabled={quoteData.document_enabled_xx === "N" || getLimitValue("UW1008") === "N" || parseFloat(cover.rc_comm_perc) === 0}
                            />
                            {(quoteData.document_enabled_xx === "N" || getLimitValue("UW1008") === "N") && (
                              <span className="tooltip-text">{`UW1008 - You are not allowed to amend COMMISSION TAX RATE for ${quoteData.product_name_xx}`}</span>
                            )}
                          </div>
                        </div>
                        {/* set commission as default */}
                        {parseFloat(cover.rc_comm_perc) > 0 &&
                          quoteData.document_enabled_xx === "Y" &&
                          getLimitValue("UW1024") === "Y" ?
                          <CustomButton
                            onClick={() => {
                              try {
                                if (!cover.rc_comm_perc) {
                                  setNotifyDialogTitle("Note!");
                                  setNotifyDialogText(`Enter commission rate`);
                                  setIsNotifyDialogOpen(true);
                                  return;
                                }
                                const formattedRate = parseFloat(cover.rc_comm_perc);
                                const formattedTax = parseFloat(cover.rc_comm_tax_perc);
                                console.log(`formattedRate ${formattedRate}`)
                                let action_text = `When you click YES, system will set COMMISSION RATE ${cover.rc_comm_perc}% and TAX ${cover.rc_comm_tax_perc}% as <strong>DEFAULT</strong> for ${quoteData.carrier_name_xx} for cover ${cover.cover_name_xx}. This means next <strong>${cover.cover_name_xx} you underwrite for this carrier, these values will be defaulted</strong>. Do you want to proceed?`;
                                setObjectToAction({
                                  object_id: cover._id,
                                  action_text: action_text,
                                  new_doc_status: "setDefaultCommRate",
                                  param1: formattedRate,
                                  param2: formattedTax,
                                  param3: cover.rc_mc_code,
                                  param4: cover.rc_sc_code,
                                  param5: cover.rc_cc_code
                                });
                                setConfirmDialogAction("setDefaultCommRate");
                                setIsConfirmDialogOpen(true);
                              } catch (error) {
                                console.log(error)
                              }
                            }}>
                            SET DEFAULT
                          </CustomButton> : <span style={{ display: "flex", width: "50px" }}></span>
                        }
                      </span>
                    </div>
                    <div className={`${cover._id === selectedPolicyRiskCover._id
                      ? "single-cover-desc active"
                      : "single-cover-desc"
                      }`}>
                      {cover.rc_desc} [{cover._id}]
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div className="table-content">
              <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none' }} className="table-container">
                <Table sx={{ width: "max-content", border: 'none' }}>
                  <TableHead className="tablehead">
                    <TableRow className="tablerow" style={{ fontWeight: "bold", }}>
                      <TableCell className="table-cell" style={{ display: "flex", maxWidth: "50px", justifyContent: "center" }}>
                        <span>#</span>
                      </TableCell>
                      <TableCell className="table-cell" style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", width: "auto", flexDirection: "row" }}>
                        <span>SMI</span>
                        {quoteData.document_enabled_xx === "Y" && policyRiskCovers?.length > 0 && <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                          <CustomButtonSmallDarkBg
                            onClick={addNewSmiRow}
                            bgColor={"#fff"}
                            ftColor={"#263238"}
                            startIcon={<AddIcon />}
                            currentButton={true}
                          >
                            ADD SMI
                          </CustomButtonSmallDarkBg>
                          <CustomButtonSmallDarkBg
                            // onClick={addNewRisk}
                            bgColor={"#fff"}
                            ftColor={"#263238"}
                            startIcon={<LibraryAddIcon />}
                            currentButton={true}
                          >
                            Pick SMI
                          </CustomButtonSmallDarkBg>
                        </div>}
                      </TableCell>
                      {selectedSubClass?.show_qty_xx === "Y" &&
                        <TableCell className="table-cell" style={{ maxWidth: "70px", display: 'flex', justifyContent: 'flex-end' }}>
                          <span>QTY</span>
                        </TableCell>}
                      <TableCell className="table-cell" style={{ maxWidth: "180px", display: 'flex', justifyContent: 'flex-end' }}>
                        <span>{`SUM INSURED [${quoteData.pl_cur_code}]`}</span>
                      </TableCell>
                      <TableCell className="table-cell" style={{ maxWidth: "150px", display: 'flex', justifyContent: 'flex-end' }}>
                        <span>FCL LIMIT</span>
                      </TableCell>
                      <TableCell className="table-cell" style={{ maxWidth: "100px", display: 'flex', justifyContent: 'flex-end' }}>
                        <span>RATE</span>
                      </TableCell>
                      <TableCell className="table-cell" style={{ alignItems: "center", maxWidth: "100px", display: 'flex', justifyContent: 'flex-end' }}>
                        <span>PER</span>
                      </TableCell>
                      <TableCell className="table-cell" style={{ alignItems: "center", maxWidth: "180px", display: 'flex', justifyContent: 'flex-end' }}>
                        <span>{`PREMIUM [${quoteData.pl_cur_code}]`}</span>
                      </TableCell>
                      {quoteData.document_enabled_xx === "Y" &&
                        <TableCell className="table-cell" style={{ maxWidth: "80px", alignItems: "center", textAlign: "center" }}>
                          <span>ACTION</span>
                        </TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody className="tablebody">
                    {policyRiskCoverSmi?.map((smi, index) => (
                      <TableRow
                        key={smi._id}
                        className={`${selectedPolicyRiskCoverSmi?._id === smi._id ? "tablerow current" : "tablerow"}`}
                        onClick={() => {
                          try {
                            setSelectedPolicyRiskCoverSmi(smi);
                          } catch (error) {
                            console.log(error);
                          }
                        }}
                      >
                        <TableCell className="table-cell" style={{ maxWidth: "50px", fontWeight: "bold", alignItems: "center", justifyContent: "center" }}>
                          <span>{index + 1}</span>
                        </TableCell>
                        <TableCell className="table-cell" style={{ width: "auto", gap: "5px" }}>
                          <input
                            id="smi_desc"
                            value={smi.smi_desc}
                            onChange={(e) => handleSmiInputChange(e, smi._id, 'smi_desc')}
                            style={{ width: "100%" }}
                            className="form-input-item"
                            onBlur={() => {
                              handleSmiBlur(smi._id, "smi_desc");
                            }}
                            disabled={quoteData.document_enabled_xx === "N"}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={smi.smi_add_to_si === 'Y'}
                                onChange={(e) => handleCheckboxChange(e, smi._id)}
                                disabled={quoteData.document_enabled_xx === "N"}
                                sx={{
                                  color: '#9c27b0',
                                  '&.Mui-checked': {
                                    color: '#9c27b0',
                                  },
                                  padding: 0,
                                  margin: 0
                                }}
                              />
                            }
                            label="Add to Sum Insured"
                            style={{ marginLeft: 0 }}
                          />
                        </TableCell>
                        {selectedSubClass?.show_qty_xx === "Y" &&
                          <TableCell className="table-cell" style={{ fontWeight: "500", maxWidth: "70px" }}>
                            <input
                              id="smi_qty"
                              type="number"
                              value={smi.smi_qty}
                              onChange={(e) => handleSmiInputChange(e, smi._id, 'smi_qty')}
                              className="form-input-item"
                              style={{ width: "100%", textAlign: "right" }}
                              onBlur={() => {
                                handleSmiBlur(smi._id, "smi_qty");
                              }}
                              disabled={quoteData.document_enabled_xx === "N"}
                            />
                            <span style={{ height: "28px", color: "transparent" }}>1</span>
                          </TableCell>}
                        <TableCell className="table-cell" style={{ maxWidth: "180px", }}>
                          <input
                            id="smi_si_fc"
                            type="text"
                            value={smi.smi_si_fc}
                            onChange={(e) => handleSmiInputChange(e, smi._id, 'smi_si_fc')}
                            className="form-input-item"
                            style={{ width: "100%", textAlign: "right" }}
                            onBlur={() => {
                              handleSmiBlur(smi._id, "smi_si_fc");
                            }}
                            disabled={quoteData.document_enabled_xx === "N"}
                          />
                          <span style={{ height: "28px", color: "transparent" }}>1</span>
                        </TableCell>
                        <TableCell className="table-cell" style={{ maxWidth: "150px" }}>
                          <input
                            id="smi_fcl_value_fc"
                            type="text"
                            value={smi.smi_fcl_value_fc}
                            onChange={(e) => handleSmiInputChange(e, smi._id, 'smi_fcl_value_fc')}
                            className="form-input-item"
                            style={{ width: "100%", textAlign: "right" }}
                            onBlur={() => {
                              handleSmiBlur(smi._id, "smi_fcl_value_fc");
                            }}
                            disabled={quoteData.document_enabled_xx === "N"}
                          />
                          <span style={{ height: "28px", color: "transparent" }}>1</span>
                        </TableCell>
                        <TableCell className="table-cell" style={{ maxWidth: "100px" }}>
                          <input
                            id="smi_rate"
                            type="text"
                            value={smi.smi_rate}
                            onChange={(e) => handleSmiInputChange(e, smi._id, 'smi_rate')}
                            className="form-input-item"
                            style={{ width: "100%", textAlign: "right" }}
                            onBlur={() => {
                              handleSmiBlur(smi._id, "smi_rate");
                            }}
                            disabled={quoteData.document_enabled_xx === "N"}
                          />
                          <span style={{ height: "28px", color: "transparent" }}>1</span>
                        </TableCell>
                        <TableCell className="table-cell" style={{ maxWidth: "100px" }}>
                          <input
                            id="smi_rate_per"
                            type="text"
                            value={smi.smi_rate_per}
                            onChange={(e) => handleSmiInputChange(e, smi._id, 'smi_rate_per')}
                            className="form-input-item"
                            style={{ width: "100%", textAlign: "right" }}
                            onBlur={() => {
                              handleSmiBlur(smi._id, "smi_rate_per");
                            }}
                            disabled={quoteData.document_enabled_xx === "N"}
                          />
                          <span style={{ height: "28px", color: "transparent" }}>1</span>
                        </TableCell>
                        <TableCell className="table-cell" style={{ maxWidth: "180px" }}>
                          <input
                            id="smi_premium_fc"
                            type="text"
                            value={smi.smi_premium_fc}
                            onChange={(e) => handleSmiInputChange(e, smi._id, 'smi_premium_fc')}
                            className="form-input-item"
                            style={{ width: "100%", textAlign: "right" }}
                            onBlur={() => {
                              handleSmiBlur(smi._id, "smi_premium_fc");
                            }}
                            disabled={quoteData.document_enabled_xx === "N"}
                          />
                          <span style={{ fontWeight: "500", height: "28px", fontSize: "10px", color: "transparent" }}>1</span>
                        </TableCell>
                        {quoteData.document_enabled_xx === "Y" &&
                          <TableCell className="table-cell" style={{ maxWidth: "80px", marginBottom: "28px", flexDirection: "row", gap: "10px" }}>
                            <Tooltip
                              title={`Edit ${smi.smi_name_xx} and/or add more details`}
                              placement="right"
                            >
                              <EditIcon
                                size={50}
                                style={{
                                  borderRadius: "5px",
                                  padding: "3px",
                                  alignSelf: "center",
                                  cursor: "pointer",
                                  color: "rgba(0, 128, 0 )",
                                  backgroundColor: "rgba(0, 128, 0, 0.5)",
                                }}
                                onClick={() => {
                                  try {
                                    setSelectedSmiForEdit(smi);
                                  } catch (error) {
                                    console.log(error);
                                  }
                                }}
                              />
                            </Tooltip>
                            <Tooltip
                              title={`Remove ${smi.smi_name_xx}?`}
                              placement="right"
                            >
                              <DeleteIcon
                                size={50}
                                style={{
                                  borderRadius: "5px",
                                  padding: "3px",
                                  alignSelf: "center",
                                  cursor: "pointer",
                                  color: "rgba(244, 67, 54)",
                                  backgroundColor: "rgba(244, 67, 54, 0.5)",
                                }}
                                onClick={() => {
                                  try {
                                    let action_text = `Remove ${smi.smi_name_xx}?`;
                                    setObjectToAction({
                                      object_id: smi._id,
                                      action_text: action_text,
                                      new_doc_status: "deleteSmi",
                                    });

                                    setConfirmDialogAction("deleteSmi");
                                    setIsConfirmDialogOpen(true);
                                  } catch (error) {
                                    console.log(error);
                                  }
                                }}
                              />
                            </Tooltip>
                          </TableCell>}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  {/* Toggle switch component */}
                  {policyRiskCoverSmi?.length > 0 &&
                    <ToggleSwitch
                      id="show_help"
                      value={showHelp}
                      onChange={setShowHelp}
                      label={"SHOW PREMIUM CALCULATION NOTES"}
                      labelPosition="end"
                      labelWidth="max-content"
                      disabled={false}
                      border="Y"
                      style={{
                        width: "max-content",
                      }}
                    />}
                  {/* This is a placeholder span to prevent pagination from aligning to left when no SMI has been created */}
                  {policyRiskCoverSmi?.length === 0 && <span>{" "}</span>}
                  {/* Pagination controls */}
                  <div>
                    <TablePagination
                      rowsPerPageOptions={[5]}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleSmiRowsPerPage}
                      component="div"
                      count={policyRiskCoverSmi?.length}
                      rows={policyRiskCoverSmi}
                      page={page}
                      onPageChange={handleSmiChangePage}
                    />
                  </div>
                </div>
              </TableContainer>
            </div>
            {policyRiskCovers?.length > 0 && showHelp === "Y" && <hr />}
            {policyRiskCovers?.length > 0 && showHelp === "Y" &&
              <motion.div className="notes-content"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 1 } }}
                exit={{ opacity: 0, transition: { duration: 1 } }}
              >
                <div className="notes-title">
                  <h1>PLEASE NOTE</h1>
                </div>
                {/* <PleaseNote /> */}
                <div className="notes-list">
                  {notes.map((note, index) => (
                    <span key={index}>
                      {index + 1}. {renderNote(note)}
                    </span>
                  ))}
                </div>
              </motion.div>}
          </div>
        </motion.div>
      )}

      {selectedSmiForEdit && (
        <div className="edit-smi-dialog">
          <div className="edit-smi-dialog-wrapper">
            <div className="edit-smi-dialog-navbar">
              <span>{`Edit ${selectedSmiForEdit.smi_name_xx}`}</span>
              <span>
                <CancelIcon
                  onClick={handleSmiEditCancel}
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    borderRadius: "50%",
                  }}
                />
              </span>
            </div>
            <div className="edit-smi-dialog-content">
              <div className="row1">
                <div
                  className="input-item-with-label"
                  style={{ width: "100%" }}
                >
                  <label htmlFor="smi_name_xx" style={{ width: "100px" }}>
                    SMI
                  </label>
                  <input
                    id="smi_name_xx"
                    name="smi_name_xx"
                    type="text"
                    placeholder="SMI"
                    value={selectedSmiForEdit.smi_name_xx}
                    className="form-input-item"
                    onChange={handleSmiEditChange}
                    style={{ width: "450px" }}
                  />
                </div>
              </div>
              <div className="row2">
                <div className="column1">
                  <div
                    className="input-item-with-label"
                    style={{ width: "100%" }}
                  >
                    <label
                      htmlFor="smi_fcl_value_fc"
                      style={{ width: "100px" }}
                    >
                      FCL
                    </label>
                    <input
                      id="smi_fcl_value_fc"
                      name="smi_fcl_value_fc"
                      type="text"
                      placeholder="Free Cover Limit"
                      value={selectedSmiForEdit.smi_fcl_value_fc}
                      className="form-input-item"
                      onChange={handleSmiEditChange}
                      onBlur={handleSmiForBlur}
                      style={{ width: "180px", textAlign: "right" }}
                    />
                  </div>
                </div>
                <div className="column2">
                  <div
                    className="input-item-with-label"
                    style={{ width: "100%" }}
                  >
                    <label htmlFor="smi_qty" style={{ width: "100px" }}>
                      QTY
                    </label>
                    <input
                      id="smi_qty"
                      name="smi_qty"
                      type="text"
                      placeholder="QTY"
                      value={selectedSmiForEdit.smi_qty}
                      className="form-input-item"
                      onChange={handleSmiEditChange}
                      onBlur={handleSmiForBlur}
                      style={{ width: "100px", textAlign: "right" }}
                    />
                  </div>
                </div>
              </div>
              <div className="row3">
                <div className="column1">
                  <div
                    className="input-item-with-label"
                    style={{ width: "100%" }}
                  >
                    <label htmlFor="smi_si_fc" style={{ width: "100px" }}>
                      SI
                    </label>
                    <input
                      id="smi_si_fc"
                      name="smi_si_fc"
                      type="text"
                      placeholder="SUM INSURED"
                      value={selectedSmiForEdit.smi_si_fc}
                      className="form-input-item"
                      onChange={handleSmiEditChange}
                      onBlur={handleSmiForBlur}
                      style={{ width: "180px", textAlign: "right" }}
                    />
                  </div>
                </div>
                <div className="column2">
                  <div
                    className="input-item-with-label"
                    style={{ width: "100%" }}
                  >
                    <label htmlFor="smi_rate" style={{ width: "100px" }}>
                      RATE
                    </label>
                    <input
                      id="smi_rate"
                      name="smi_rate"
                      type="text"
                      placeholder="RATE"
                      value={selectedSmiForEdit.smi_rate}
                      className="form-input-item"
                      onChange={handleSmiEditChange}
                      onBlur={handleSmiForBlur}
                      style={{ width: "100px", textAlign: "right" }}
                    />
                  </div>
                </div>
              </div>
              <div className="row4">
                <div className="column1">
                  <div
                    className="input-item-with-label"
                    style={{ width: "100%" }}
                  >
                    <label htmlFor="smi_premium_fc" style={{ width: "100px" }}>
                      PREMIUM
                    </label>
                    <input
                      id="smi_premium_fc"
                      name="smi_premium_fc"
                      type="text"
                      placeholder="PREMIUM"
                      value={selectedSmiForEdit.smi_premium_fc}
                      className="form-input-item"
                      onChange={handleSmiEditChange}
                      onBlur={handleSmiForBlur}
                      style={{ width: "180px", textAlign: "right" }}
                    />
                  </div>
                </div>
                <div className="column2">
                  <div
                    className="input-item-with-label"
                    style={{ width: "100%" }}
                  >
                    <label htmlFor="smi_rate_per" style={{ width: "100px" }}>
                      RATE PER
                    </label>
                    <input
                      id="smi_rate_per"
                      name="smi_rate_per"
                      type="text"
                      placeholder="PER"
                      value={selectedSmiForEdit.smi_rate_per}
                      className="form-input-item"
                      onChange={handleSmiEditChange}
                      onBlur={handleSmiForBlur}
                      style={{ width: "100px", textAlign: "right" }}
                    />
                  </div>
                </div>
              </div>
              <div className="row5">
                <div className="input-item-with-label">
                  <label htmlFor="smi_desc" style={{ width: "100px" }}>
                    DESC
                  </label>
                  <textarea
                    id="smi_desc"
                    name="smi_desc"
                    type="text"
                    placeholder="DESCRIPTION"
                    value={selectedSmiForEdit.smi_desc}
                    rows={6}
                    className="form-input-item"
                    onChange={handleSmiEditChange}
                    onBlur={handleSmiForBlur}
                    style={{ width: "450px" }}
                  />
                </div>
              </div>
            </div>
            <div>{`DocId ${selectedSmiForEdit._id} and prev ${selectedSmiForEdit.smi_prev_policy_docid}`}</div>
            <div className="edit-smi-dialog-action">
              <CustomButton onClick={handleSmiEditCancel}>Cancel</CustomButton>
              <CustomButton onClick={handleSmiEditUpdate}>Update</CustomButton>
            </div>
          </div>
        </div>
      )}
      {showCoverForEditing && (
        <div className="edit-smi-dialog">
          <div className="edit-smi-dialog-wrapper">
            <div className="edit-smi-dialog-navbar">
              <span>{`Edit ${selectedPolicyRiskCover?.cover_name_xx}`}</span>
              <span>
                <CancelIcon
                  onClick={handleCoverEditCancel}
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    borderRadius: "50%",
                  }}
                />
              </span>
            </div>
            <div className="edit-smi-dialog-content">
              <textarea
                id="edit_cover_desc"
                name="edit_cover_desc"
                type="text"
                placeholder="DESCRIPTION"
                value={selectedPolicyRiskCover?.rc_desc}
                rows={10}
                style={{
                  width: "95%",
                  height: "max-content",
                  padding: "8px 12px",
                  margin: "0px 20px",
                  border: "0.5px solid #ccc",
                  borderRadius: "5px",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
                onChange={(e) => {
                  try {
                    setSelectedPolicyRiskCover({
                      ...selectedPolicyRiskCover,
                      rc_desc: e.target.value,
                    });
                  } catch (error) {
                    console.log(error);
                  }
                }}
              />
            </div>
            <div className="edit-smi-dialog-action">
              <CustomButton onClick={handleCoverEditCancel}>
                Cancel
              </CustomButton>
              <CustomButton onClick={handleCoverEditUpdate}>
                Update
              </CustomButton>
            </div>
          </div>
        </div>
      )}
      {showAddSysCodes && (
        <motion.div
          className="syscode-dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.5 } }}
          exit={{ opacity: 0, transition: { duration: 0.5 } }}
        >
          <div className="syscode-wrapper">
            <div className="syscode-navbar">
              <div className="syscode-navbar-left">
                <div className="search-input-wrapper">
                  <SearchIcon className="search-input-icon" />
                  <input
                    className="search-input"
                    type="text"
                    placeholder="Search..."
                    value={sysCodeSearchTerm}
                    onChange={(e) => setSysCodeSearchTerm(e.target.value)}
                  />
                  {sysCodeSearchTerm.length > 0 && (
                    <IoClose
                      onClick={handleClearSysCodeSearchTerm}
                      className="search-input-icon"
                    />
                  )}
                </div>
              </div>
              <div className="syscode-navbar-right">
                <span onClick={() => setShowAddSysCodes(false)}>
                  <IoClose
                    style={{
                      width: "30px",
                      height: "30px",
                      cursor: "pointer",
                      borderRadius: "50%",
                    }}
                  />
                </span>
              </div>
            </div>

            <div className="syscode-content">
              <TableContainer
                component={Paper}
                className="table-container"
                sx={{ minWidth: 300, minHeight: 300 }}
              >
                <Table>
                  <TableHead className="tablehead">
                    <TableRow>
                      <TableCell
                        className="table-cell"
                        align="right"
                        style={{
                          fontWeight: "bold",
                          width: "50px",
                          textAlign: "center",
                          padding: "3px",
                        }}
                      >
                        #
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        style={{
                          fontWeight: "bold",
                          width: "auto",
                          padding: "3px",
                        }}
                      >
                        Attribute
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        style={{
                          fontWeight: "bold",
                          width: "50px",
                          alignItems: "center",
                          textAlign: "center",
                          padding: "3px",
                          color: "#146cbe",
                        }}
                      >
                        Add
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {slicedSysCodes.map((sc, index) => (
                      <TableRow
                        key={sc._id}
                        className="tablerow"
                        style={{ padding: "12px" }}
                      >
                        <TableCell
                          className="table-cell"
                          align="right"
                          style={{
                            fontWeight: "bold",
                            width: "50px",
                            textAlign: "center",
                            padding: "3px",
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          className="table-cell"
                          style={{
                            fontWeight: "bold",
                            width: "auto",
                            padding: "3px",
                          }}
                        >
                          {sc.sys_name}
                        </TableCell>

                        <TableCell
                          className="table-cell"
                          style={{
                            alignItems: "center",
                            textAlign: "center",
                            padding: "3px",
                          }}
                        >
                          {/* Action buttons */}
                          <Tooltip title="Add" placement="left">
                            <QueueIcon
                              style={{
                                height: "30px",
                                width: "30px",
                                alignItems: "center",
                                textAlign: "center",
                                padding: "3px",
                                cursor: "pointer",
                                color: "#146cbe",
                              }}
                              onClick={async (e) => {
                                try {
                                  const res = await newRequest.post(
                                    "/uwPolicyRisk/addOtherAttr",
                                    {
                                      risk_docid: selectedPolicyRisk._id,
                                      attribute_code: sc.sys_code,
                                      attribute_desc: sc?.sys_name,
                                      attribute_yn: "N",
                                      attribute_remarks: sc?.sys_desc,
                                      createdBy: currentUser.usr_id,
                                    },
                                    config
                                  );

                                  if (res.data === "exists") {
                                    showToast(
                                      `Attribute ${sc?.sys_name} already attached`,
                                      "error"
                                    );
                                  } else {
                                    showToast(
                                      `Attribute ${sc?.sys_name} added`,
                                      "success"
                                    );
                                  }
                                  fetchOtherAttributes();
                                } catch (error) {
                                  console.log(error);
                                }
                              }}
                            />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5]}
                  rowsPerPage={rowsPerPageSysCode}
                  onRowsPerPageChange={handleSysCodeRowsPerPage}
                  component="div"
                  count={filteredSysCodesData.length}
                  rows={filteredSysCodesData}
                  page={sysCodePage}
                  onPageChange={handleSysCodePageChange}
                />
              </TableContainer>
            </div>
            {/* <div className="syscode-action">
              <CustomButton>proceed</CustomButton>
            </div> */}
          </div>
        </motion.div>
      )}
      {/* Confirm Dialog */}
      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        dialogTitle="Confirmation"
        dialogContent={<p dangerouslySetInnerHTML={{ __html: objectToAction.action_text }} />}
      />
      {/* Dialog to notify user about changes being saved */}
      < Dialog open={isNotifyDialogOpen} onClose={handleCloseNotifyDialog} >
        <DialogTitle>{notifyDialogTitle}</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: notifyDialogText }} />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseNotifyDialog}>Close</CustomButton>
        </DialogActions>
      </Dialog >
    </div>
  );
});

export default UwPolicyRisk;
