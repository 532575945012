import React, { useEffect, useState } from 'react';
import './EmailComponent.scss';
import { AttachFile } from '@mui/icons-material';
import { CustomButton } from '../ui/CustomButton';
import newRequest from '../../utils/newRequest';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import app from "../../utils/firebase.js";
import { showToast } from "../../toastService.js";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    LinearProgress,
    CircularProgress,
    Chip
} from "@mui/material";
import { useSelector } from 'react-redux';
import Select from "react-select";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill's CSS
// Import Quill's font color and indent modules
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.bubble.css';
import { useNavigate } from 'react-router-dom';
import { customSelectStyles } from '../ui/uiStyleElements.jsx';
import { replacePlaceholders } from '../../utils/genericFunctions.js';

const EmailComponent = ({ setEmailDialogOpen, emailData, fetchEmails }) => {
    const { currentUser } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const [to, setTo] = useState(emailData?.to);
    const [cc, setCc] = useState(emailData?.cc);
    const [bcc, setBcc] = useState(emailData?.bcc);
    const [subject, setSubject] = useState(emailData?.subject);
    const [content, setContent] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [showCcBcc, setShowCcBcc] = useState(emailData?.cc ? true : false);
    const [showAttach, setShowAttach] = useState(emailData?.show_attach === "N" ? false : true);
    const [callFirebaseUpload, setCallFirebaseUpload] = useState(emailData?.show_attach === "N" ? false : true);
    const filesLimit = 5;
    const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [currentFileName, setCurrentFileName] = useState('');
    const [currentFileIndex, setCurrentFileIndex] = useState(0);
    const [totalFiles, setTotalFiles] = useState(0);
    const [uploadButtonTitle, setUploadButtonTitle] = useState("Upload");
    const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
    const [notifyDialogTitle, setNotifyDialogTitle] = useState("Confirm");
    const [notifyDialogText, setNotifyDialogText] = useState("Text");

    // console.log(`EmailComponent mount3d...  ${JSON.stringify(emailData, null, 2)}`);

    // Extend Quill with font color and indent modules
    const modules = {
        toolbar: [
            // [{ 'font': [] }],
            [{ 'size': ['small', 'medium', 'large', 'huge'] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            ['link'],
            // ['link', 'image'],
            ['clean']
        ],
    };

    const handleRemoveFile = (id) => {
        setSelectedFiles(selectedFiles.filter(attachment => attachment.id !== id));
    };

    const handleUploadCloseDialog = () => {
        setIsUploadDialogOpen(false);
    };

    // attache documents if passed
    useEffect(() => {
        if (emailData?.attached_urls?.length > 0) {
            setSelectedFiles(emailData?.attached_urls);
            setTotalFiles(emailData?.attached_urls?.length);
        }
    }, [emailData?.attached_urls]);

    useEffect(() => {
        setTotalFiles(selectedFiles.length);
    }, [selectedFiles]);


    const saveFileToDb = async (
        fl_name,
        fl_internal_name,
        fl_type,
        fl_path,
        fl_category,
        fl_size_kb,
        fl_parent_doc_id
    ) => {
        try {
            await newRequest.post("/files/add", {
                fl_org_code: currentUser.usr_org_code,
                fl_name,
                fl_internal_name,
                fl_type,
                fl_path,
                fl_category,
                fl_doc_type: "email",
                fl_doc_no: fl_parent_doc_id,
                fl_doc_index: "",
                fl_doc_sub_index: "",
                fl_ent_code: "",
                fl_aent_code: "",
                fl_user_id: "",
                fl_size_kb,
                fl_parent_doc_id,
                userCode: currentUser.usr_id,
            });
            // add uploaded files to array
            const fl = {
                doc_id: "",
                doc_name: fl_name,
                doc_type: fl_type,
                doc_url: fl_path,
                doc_size_kb: fl_size_kb,
            }
            uploadedFiles.push(fl);

        } catch (error) {
            console.error("Error saving file to DB:", error);
        }
    };

    // close notify dialog
    const handleCloseNotifyDialog = () => {
        setIsNotifyDialogOpen(false);
    };

    const handleSelectedFiles = (e) => {
        try {
            const files = Array.from(e.target.files);
            const newFiles = files.map((file, index) => ({
                id: selectedFiles.length + index,
                name: file.name,
                file,
            }));
            if (selectedFiles.length + newFiles.length <= filesLimit) {
                setSelectedFiles([...selectedFiles, ...newFiles]);
            } else {
                setNotifyDialogTitle("Note!");
                setNotifyDialogText(`Please select a maximum of <strong>${filesLimit} files</strong> at a time.`);
                setIsNotifyDialogOpen(true);
            }
        } catch (error) {
            console.log(error)
        }
    };

    const uploadToFirebase = async (email_docid, file, fileFolder) => {
        const storage = getStorage(app);
        const fileName = new Date().getTime() + file.name;
        const fileExtension = file.name.split(".").pop();
        const fileType = file.type;
        const fileSizeKB = file.size / 1024;
        const storageRef = ref(
            storage,
            `qc_${currentUser.usr_org_code}/${fileFolder}/${fileName}`
        );
        const uploadTask = uploadBytesResumable(storageRef, file);

        return new Promise((resolve, reject) => {
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const fileProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    // Calculate overall progress based on the current file index and the file progress
                    const progressPerFile = 100 / totalFiles;
                    // const overallProgress = ((currentFileIndex - 1) * progressPerFile) + (fileProgress / totalFiles);
                    // setUploadProgress(Math.round(overallProgress));
                    const progress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadProgress(Math.round(progress));
                    switch (snapshot.state) {
                        case "paused":
                            console.log("Upload is paused");
                            break;
                        case "running":
                            console.log("Upload is running");
                            break;
                        default:
                            break;
                    }
                },
                (error) => {
                    setIsUploadDialogOpen(false);
                    console.log(error);
                    reject(error);
                },
                async () => {
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                    await saveFileToDb(
                        file.name,
                        fileName,
                        fileExtension,
                        downloadURL,
                        "email",
                        fileSizeKB,
                        email_docid
                    );
                    resolve();
                }
            );
        });
    };

    const uploadAllFiles = async (email_docid) => {
        try {
            setIsUploadDialogOpen(true);
            setUploadButtonTitle("Please Wait...")
            for (let i = 0; i < selectedFiles.length; i++) {
                const attachment = selectedFiles[i];
                setCurrentFileName(attachment.name);
                setCurrentFileIndex(i + 1);
                await uploadToFirebase(email_docid, attachment.file, "email");
            }
            const noOfFiles = selectedFiles?.length;
            let fileName = "";

            if (selectedFiles?.length === 1) {
                fileName = selectedFiles[0].name;
            }

            setSelectedFiles([]); // Clear the selected files array
            setIsUploadDialogOpen(false); // Close the dialog
            // showToast(`${noOfFiles === 1 ? `${fileName} successfully uploaded!` : `${noOfFiles} files successfully uploaded!`}`, "success");
        } catch (error) {
            console.log(error);
        } finally {
            setUploadButtonTitle("Upload");
        }
    };

    const handleSubmit = async () => {
        try {
            // TODO
            // Save Email to database
            const correspondence = await newRequest.post(
                "/correspondence/addEmail",
                {
                    em_doc_no: emailData?.doc_no,
                    em_doc_type: emailData?.doc_type,
                    em_doc_id: emailData?.doc_id,
                    em_entity_codes: emailData?.entity_codes,
                    em_to: to,
                    em_cc: cc,
                    em_bcc: bcc,
                    em_subject: subject,
                    em_content: content,
                    em_status: "sent",
                    em_fb_urls: uploadedFiles,
                    em_binary_docs: emailData?.binary_docs,
                    em_search_string: emailData?.search_string,
                }
            );
            // console.log(`corresponce  ${JSON.stringify(corresponce.data, null, 2)}`);
            // console.log(`callFirebaseUpload is ${callFirebaseUpload}`)
            if (correspondence.data) {
                // Use its ID to for upload reference
                const emailId = correspondence.data._id;
                if (selectedFiles?.length > 0 && callFirebaseUpload) {
                    await uploadAllFiles(emailId);
                    // update the email em_fb_urls
                    // console.log(`/correspondence/upd/${emailId}  ${JSON.stringify(uploadedFiles, null, 2)}`);
                    await newRequest.post("/correspondence/updUrls", { correspondence_docid: emailId, em_fb_urls: uploadedFiles });
                }
                setNotifyDialogTitle("Sending email!");
                setNotifyDialogText(`Please wait....`);
                setIsNotifyDialogOpen(true);
                // call send email
                const res = await newRequest.post(
                    "/email/send",
                    {
                        to,
                        subject,
                        message: content,
                        doc_id: emailId,
                        cc,
                        bcc,
                        attached_urls: uploadedFiles,
                        binary_docs: emailData?.binary_docs
                    }
                );

                setIsNotifyDialogOpen(false);
                setTo('');
                setCc('');
                setBcc('');
                setSubject('');
                setContent('');
                setSelectedFiles([]);
                // Close the email dialog in the parent component
                setEmailDialogOpen(false);
                fetchEmails();
                showToast(res.data, "success");
            } else {
                showToast("error sending email", "error");
            }

        } catch (error) {
            console.log(error)
        } finally {
            setIsNotifyDialogOpen(false);
        }
    };

    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [templates, setTemplates] = useState([]);
    const fetchTemplates = async () => {
        try {
            const res = await newRequest.post(
                "/emailTemplate/findAll",
                { tpt_module: "", result_limit: 100, tpt_category: emailData?.tpt_category }
            );
            const options = res.data.map((template) => ({
                value: template.tpt_ref,
                label: template.tpt_name,
                email_subject: template.tpt_subject,
                email_content: template.tpt_email_content
            }));

            setTemplates(options);
            // console.log(`fetchTemplates  ${JSON.stringify(options, null, 2)}`);
            // if a template reference is passed , assign it
            if (emailData?.template_ref) {
                const passedTemplate = res.data.find(l => l.tpt_ref === emailData?.template_ref);
                if (passedTemplate) {
                    // console.log(`selectedTemplate  ${JSON.stringify(passedTemplate, null, 2)}`);
                    setSelectedTemplate({
                        value: passedTemplate.tpt_ref,
                        label: passedTemplate.tpt_name,
                        email_content: passedTemplate.tpt_email_content
                    })
                    const placeholders = {
                        ...emailData,
                        ourCompanyName: currentUser?.usr_org_name,
                        ourContactEmail: currentUser?.org_email,
                        ourContactPhone: currentUser?.org_phone,
                        ourPhysicalAddress: currentUser?.org_address1,
                        ourPostalAddress: currentUser?.org_address2,
                        ourWebsite: currentUser?.org_website,
                        policyCurrency: currentUser?.currency_name_xx,
                    }
                    const filledTemplateSubject = replacePlaceholders(passedTemplate.tpt_subject, placeholders);
                    const filledTemplateContent = replacePlaceholders(passedTemplate.tpt_email_content, placeholders);
                    setSubject(filledTemplateSubject);
                    setContent(filledTemplateContent);

                }
            }
        } catch (error) {
            console.log(error)
        } finally {

        }
    };

    useEffect(() => {
        fetchTemplates();
    }, []);

    return (
        <div className="email">
            <div className="show-wrapper">
                <Select
                    id="tpt_category"
                    name="tpt_category"
                    styles={customSelectStyles}
                    style={{ width: "90%" }}
                    value={selectedTemplate}
                    onChange={(selectedOption) => {
                        try {
                            const placeholders = {
                                ...emailData,
                                ourCompanyName: currentUser?.usr_org_name,
                                ourContactEmail: currentUser?.org_email,
                                ourContactPhone: currentUser?.org_phone,
                                ourPhysicalAddress: currentUser?.org_address1,
                                ourPostalAddress: currentUser?.org_address2,
                                ourWebsite: currentUser?.org_website,
                                policyCurrency: currentUser?.currency_name_xx,
                            }
                            const filledTemplateSubject = replacePlaceholders(selectedOption?.email_subject, placeholders);
                            const filledTemplateContent = replacePlaceholders(selectedOption?.email_content, placeholders);
                            setSubject(filledTemplateSubject);
                            setContent(filledTemplateContent);
                            setSelectedTemplate(selectedOption)
                        } catch (error) {
                            console.log(error)
                        }
                    }}
                    options={templates}
                    isSearchable={true}
                    placeholder="SELECT TEMPLATE"
                    noOptionsMessage={() => "NO TEMPLATE SELECTED"}
                />
            </div>
            <div className="show-wrapper">
                <input
                    id="to"
                    name="to"
                    type="text"
                    placeholder="TO"
                    value={to}
                    className="form-input-item"
                    style={{ width: "90%" }}
                    onChange={(e) => setTo(e.target.value)}
                />
                <span className='show-ccbcc' onClick={() => setShowCcBcc(!showCcBcc)}>
                    {showCcBcc ? "CC" : "CC"}
                </span>
            </div>
            {showCcBcc &&
                <input
                    id="cc"
                    name="cc"
                    type="text"
                    placeholder="CC"
                    value={cc}
                    className="form-input-item"
                    style={{ width: "100%" }}
                    onChange={(e) => setCc(e.target.value)}
                />}
            {showCcBcc &&
                <input
                    id="bcc"
                    name="bcc"
                    type="text"
                    placeholder="BCC"
                    value={bcc}
                    className="form-input-item"
                    style={{ width: "100%" }}
                    onChange={(e) => setBcc(e.target.value)}
                />}
            <input
                id="subject"
                name="subject"
                type="text"
                placeholder="Subject"
                value={subject}
                className="form-input-item"
                style={{ width: "100%" }}
                onChange={(e) => setSubject(e.target.value)}
            />
            <ReactQuill
                value={content}
                onChange={setContent}
                modules={modules}
                className="rich-text-editor"
            />
            <div className="email-footer">
                <div className="email-footer-left">
                    {/* <span>{showAttach}</span> */}
                    <div className="attachment-upload">
                        <input
                            type="file"
                            multiple
                            id="file-upload"
                            style={{ display: 'none' }}
                            onChange={handleSelectedFiles}
                        />
                        {showAttach && <label htmlFor="file-upload">
                            <AttachFile className='attachment-btn' />
                        </label>}
                        <div className="attachments">
                            {selectedFiles.map((attachment) => (
                                <Chip
                                    key={attachment.id}
                                    label={attachment.name}
                                    onDelete={() => handleRemoveFile(attachment.id)}
                                    className="attachment-chip"
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div className="email-footer-right">
                    <CustomButton onClick={handleSubmit}
                        disabled={!to}
                    >
                        Send Email
                    </CustomButton>
                </div>
            </div>
            <UploadDialog
                open={isUploadDialogOpen}
                handleClose={handleUploadCloseDialog}
                uploadProgress={uploadProgress}
                currentFileName={currentFileName}
                currentFileIndex={currentFileIndex}
                totalFiles={totalFiles}
            />
            {/* Dialog to user about changes being saved */}
            < Dialog open={isNotifyDialogOpen} onClose={handleCloseNotifyDialog} >
                <DialogTitle>{notifyDialogTitle}</DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                        <span dangerouslySetInnerHTML={{ __html: notifyDialogText }} />
                        {notifyDialogText === "Please wait...." && <CircularProgress size={24} color="inherit" />}
                    </div>
                </DialogContent>
            </Dialog >
        </div>
    );
};


const UploadDialog = ({ open, handleClose, uploadProgress, currentFileName, currentFileIndex, totalFiles }) => {
    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle style={{
                margin: "5px 5px",
                marginLeft: "25px",
                padding: "1px 5px",
                borderRadius: "5px",
                width: "max-content",
                fontWeight: "400",
                backgroundColor: "rgba(244, 67, 54, 0.3)",
                color: "rgba(244, 67, 54, 1)",
            }}>
                {`Uploading ${currentFileIndex} of ${totalFiles}`}
            </DialogTitle>
            <DialogContent>
                <div style={{ width: '100%', overflowWrap: 'break-word' }}>
                    <p style={{ fontWeight: "400", fontSize: "18px" }}>{currentFileName}</p>
                    <div style={{ margin: "10px 0px" }}>
                        <LinearProgress variant="determinate" value={uploadProgress} />
                    </div>
                    {uploadProgress && uploadProgress === 0 ? "Starting..." : <p>{uploadProgress}% Complete</p>}
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default EmailComponent;
